import React, { useEffect, useState } from "react";
import { BreadCrumb, ReturnButton } from "../../../Components";
import { IoAddCircleOutline } from "react-icons/io5";
import { Domaines, Marque } from "../../../Constants/dummy";
import {
  validatePostalCodeInput,
  validateTelephoneInput,
} from "../../../Components/FormValidation";
import ReCAPTCHA from "react-google-recaptcha";
import Autocomplete from "react-google-autocomplete";
import pdfImage from "../../../Assets/pdf.png";

const NewProjectPro = () => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedSubTypes, setSelectedSubTypes] = useState([]);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [postale, setPostale] = useState("");
  const [ville, setVille] = useState("");
  const [telephone, setTelephone] = useState("");
  const [postaleError, setPostaleError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [prod_marque, setMarque] = useState("");
  const [prod_numeroSerie, setNumeroSerie] = useState("");
  const [prod_puissance, setPuissance] = useState("");
  const [prod_reference, setReference] = useState("");
  const [prod_quantite, setQuantite] = useState(1);
  const [prod_moisInstallation, setMoisInstallation] = useState("");
  const [prod_dateInstallation, setDateInstallation] = useState("");
  const [prod_entretenu, setEntretenu] = useState(false);
  const [prod_probleme, setProbleme] = useState("");
  const [prod_photoDuProduit, setPhotoDuProduit] = useState(null);
  const [error, setError] = useState("");
  const [prod_autreMarque, setAutreMarque] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [contract, setContract] = useState("Non communiqué");
  const [typeDePrestation, setTypeDePrestation] = useState("");
  const [autresValue, setAutresValue] = useState("");
  const isAutresSelected = typeDePrestation === "Autres";
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedSubdomain, setSelectedSubdomain] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [domaineError, setDomaineError] = useState("no error")
  const apiUrl = 'https://lebontechnicien.net'

  const Mois = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const handleAutreMarque = (event) => {
    setAutreMarque(event.target.value);
  };

  const handleTypeDePrestation = (event) => {
    setTypeDePrestation(event.target.value);
  };

  const handleAddMorePictures = (event) => {
    event.preventDefault();
    const newFiles = event.target.files;
    const existingFiles = prod_photoDuProduit
      ? Array.from(prod_photoDuProduit)
      : [];

    // Combine existing files with new files
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
    console.log("setPhotoDuProduithandleAddMorePictures", prod_photoDuProduit);
  };

  const handleRemovePicture = (index) => {
    console.log(
      "Before removing picture - prod_photoDuProduit:",
      prod_photoDuProduit
    );

    // Ensure prod_photoDuProduit is always treated as an array
    const updatedFiles = Array.isArray(prod_photoDuProduit)
      ? prod_photoDuProduit.filter((_, i) => i !== index)
      : [];

    setPhotoDuProduit(updatedFiles);

    console.log("After removing picture - prod_photoDuProduit:", updatedFiles);
  };

  /*const handleTypeSelection = (type) => {
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };*/

  const handleTypeSelection = (domain, subdomain) => {
    setSelectedDomain(domain);
    setSelectedSubdomain(subdomain);
  };

  const handlePostaleChange = (event) => {
    const value = event.target.value;
    setPostale(value);
    setPostaleError(validatePostalCodeInput(value));
  };

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handleAutresValueChange = (e) => {
    setAutresValue(e.target.value);
  };

  const handleTelephoneChange = (event) => {
    const value = event.target.value;
    setTelephone(value);
    setTelephoneError(validateTelephoneInput(value));
  };

  const handlePhotoDuProduit = (event) => {
    setPhotoDuProduit(event.target.files);
  };

  const handleInformation = (event) => {
    setProbleme(event.target.value);
  };

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    const requestData = new FormData();
    if (prod_photoDuProduit && prod_photoDuProduit.length) {
      for (let i = 0; i < prod_photoDuProduit.length; i++) {
        requestData.append(`photoDuProduit[${i}]`, prod_photoDuProduit[i]);
      }
    }
    if (prod_photoDuProduit && prod_photoDuProduit.length) {
      for (let i = 1; i < prod_photoDuProduit.length; i++) {
        requestData.append(`photoDuProduit[${i}]`, prod_photoDuProduit[i]);
      }
    }
    requestData.append("nom", nom);
    requestData.append("prenom", prenom);
    requestData.append("adresse", adresse);
    requestData.append("placeId", placeId);
    requestData.append("postale", postale);
    requestData.append("ville", ville);
    requestData.append("telephone", telephone);
    requestData.append("typeDeProjet", selectedSubdomain);
    requestData.append("typeDePrestation", typeDePrestation);
    requestData.append("autreMarque", prod_autreMarque);
    //requestData.append("postaleError", postaleError);
    requestData.append("marque", prod_marque);
    requestData.append("numeroSerie", prod_numeroSerie);
    requestData.append("puissance", prod_puissance);
    requestData.append("reference", prod_reference);
    requestData.append("quantite", prod_quantite);
    requestData.append("moisInstallation", prod_moisInstallation);
    requestData.append("dateInstallation", prod_dateInstallation);
    requestData.append("entretenu", prod_entretenu);
    requestData.append("probleme", prod_probleme);
    requestData.append("photoDuProduit", prod_photoDuProduit);
    requestData.append("recaptchaValue", recaptchaValue);
    requestData.append("clientNumber", sessionStorage.getItem("phone"));
    requestData.append("clientEmail", sessionStorage.getItem("email"));
    requestData.append("zone", sessionStorage.getItem("zone"));
    requestData.append("adresse", sessionStorage.getItem("adresse"));
    requestData.append("email", sessionStorage.getItem("email"));
    requestData.append(
      "raisonSocialClient",
      sessionStorage.getItem("raisonSocial")
    );
    requestData.append("type", "Demande d'intervention");
    requestData.append("contrat", contract);
    requestData.append("client", "Pro");
    requestData.append("count", "0");

    let isValid = true;
    const requiredFields = [
      "nom",
      "prenom",
      //"adresse",
      "postale",
      "ville",
      "telephone",
      "marque",
      //"numeroSerie",
      "quantite",
      "puissance",
      //"moisInstallation",
      //"dateInstallation",
      "probleme",
      //"entretenu",
      "adresse",
      //"typeDeProjet"
    ];

    console.log("selectedSubdomain", selectedSubdomain)

    if (selectedSubdomain === null) {
      setDomaineError("Veuillez remplir ce champ");
      isValid = false;
    } else {
      setDomaineError("No error");
    }

    for (const field of requiredFields) {
      if (!requestData.get(field)) {
        isValid = false;
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "red";
        const errorText = document.createElement("p");
        errorText.innerText = `Veuillez remplir ce champ.`;
        errorText.style.color = "red";
        input.parentNode.insertBefore(errorText, input);
      } else {
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "initial";
        const errorText = input.previousElementSibling;
        if (
          errorText &&
          errorText.innerText.startsWith("Veuillez remplir ce champ")
        ) {
          errorText.remove();
        }
      }
    }

    // Check radio input
    /*const radioInput = document.querySelector('[name="entretenu"]:checked');
    if (!radioInput) {
      isValid = false;
      const errorText = document.createElement("p");
      errorText.innerText = `Veuillez remplir ce champ.`;
      errorText.style.color = "red";
      const radioContainer =
        document.querySelector('[name="entretenu"]').parentNode.parentNode;
      radioContainer.parentNode.insertBefore(
        errorText,
        radioContainer.nextSibling
      );
    } else {
      const errorText =
        document.querySelector('[name="entretenu"]').parentNode.parentNode
          .nextSibling;
      if (
        errorText &&
        errorText.innerText.startsWith("Veuillez remplir ce champ")
      ) {
        errorText.remove();
      }
    }*/

    if (!isValid) {
      return;
    }
    setConfirmationModal(true);
    fetch(`${apiUrl}/request/create_request`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestData,
    })
      .then((res) => {
        if (res.status === 413) {
          setConfirmationModal(false);
          setError(
            "La taille des fichiers image excède les limites autorisées. Veuillez compresser vos images pour une soumission de requête réussie. "
          );
        } else if (res.status === 401) {
          setError("Accès non autorisé : Unauthorized access.");
        } else if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while processing the request.");
        }
      })
      .then((data) => {
        if (data) {
          console.log("success");
          setConfirmationModal(false);
          window.location.replace("/user-history");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setConfirmationModal(false);
        setError("Une erreur s'est produite lors de l'enregistrement ");
        console.error(error);
      });
  };

  return (
    <>
      <BreadCrumb
        From={"Compte"}
        To={"Nouveau Projet"}
        hrefFrom={"/tableau-de-bord"}
      />

<ReturnButton/>

      <div className="bg-white  mx-auto max-w-screen-xl  p-4 rounded-md">
        <h1 className="text-2xl font-bold mb-4 px-4">Nouveau Projet</h1>
        <form className=" p-4 rounded-md">
          <div className="mb-4">
            <h2 className="text-lg font-bold mb-2 px-4">Type d'appareil</h2>
            {domaineError === "Veuillez remplir ce champ" && (
             <p className="text-red-500 ml-2">{domaineError}</p>
            )}
            <div className="flex flex-wrap">
              {Domaines.map((domaine) => (
                <div
                  className="rounded-md cursor-pointer w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 px-4 py-2"
                  key={domaine.id}
                >
                  <div
                    className="flex"
                    onClick={() => handleTypeSelection(domaine.nom, null)}
                  >
                    <div className="flex">
                      {domaine.icon}
                      {domaine.nom}
                    </div>
                  </div>
                  {domaine.hasType &&
                    selectedDomain === domaine.nom &&
                    domaine.type.map((type) => (
                      <div key={type} className="p-2">
                        <label>
                          <input
                            type="radio"
                            checked={selectedSubdomain === type}
                            onChange={() =>
                              handleTypeSelection(domaine.nom, type)
                            }
                          />
                          {type}
                        </label>
                      </div>
                    ))}
                  {!domaine.hasType && selectedDomain === domaine.nom && (
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedSubdomain === domaine.nom}
                          onChange={() =>
                            handleTypeSelection(domaine.nom, domaine.nom)
                          }
                        />
                        {domaine.nom}
                      </label>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="mb-8">
            <h2 className="text-lg font-bold mb-2 px-4 mt-10">
              Type de prestation souhaité{" "}
              <span className="text-red-500"> * </span>
            </h2>
            <div className="flex gap-8">
              <div className="w-[96%] ml-auto mr-auto ">
                <label className="">
                  <input
                    type="radio"
                    id="typeDuPrestation"
                    name="typeDePrestation"
                    value="Installation"
                    className="leading-tight mr-3"
                    checked={typeDePrestation === "Installation"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Installation</span>
                </label>
                <label className="">
                  <input
                    type="radio"
                    id="typeDuPrestation"
                    name="typeDePrestation"
                    value="Réparation"
                    className="leading-tight mr-3"
                    checked={typeDePrestation === "Réparation"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Réparation</span>
                </label>
                <label className="">
                  <input
                    id="typeDuPrestation"
                    type="radio"
                    name="typeDePrestation"
                    value="Remplacement"
                    className="leading-tight mr-3"
                    checked={typeDePrestation === "Remplacement"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Remplacement</span>
                </label>
                <label className="">
                  <input
                    id="typeDuPrestation"
                    type="radio"
                    name="typeDePrestation"
                    value="Maintenance"
                    className="leading-tight mr-3"
                    checked={typeDePrestation === "Maintenance"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Maintenance</span>
                </label>
                <label className="">
                  <input
                    id="typeDuPrestation"
                    type="radio"
                    name="typeDePrestation"
                    value="Autres"
                    className="leading-tight mr-3 "
                    checked={typeDePrestation === "Autres"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Remise en conformité</span>
                </label>
              </div>
            </div>
            {/*{isAutresSelected && (
              <div className="w-[96%] ml-auto mr-auto mt-4">
                <label className="" htmlFor="autres-value">
                  <span className="mr-2">De quoi avez-vous besoin</span>
                  <textarea
                    id="autres-value"
                    name="autres-value"
                    value={autresValue}
                    onChange={handleAutresValueChange}
                    required
                    className="leading-tight mr-3 w-[80%] p-4 border-1 border-gray-300 rounded-md"
                  />
                </label>
              </div>
            )}*/}
          </div>
          <div className="mb-4">
            <h2 className="text-lg font-bold mb-2 px-4">
              Information du client
            </h2>
            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="nom"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    value={nom}
                    onChange={(event) => setNom(event.target.value)}
                    name="nom"
                    id="nom"
                    required
                    autoComplete="given-name"
                    className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="prenom"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Prénom <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    name="prenom"
                    value={prenom}
                    onChange={(event) => setPrenom(event.target.value)}
                    required
                    id="prenom"
                    autoComplete="prenom"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="telephone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Téléphone: <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    name="telephone"
                    value={telephone}
                    onChange={handleTelephoneChange}
                    required
                    id="telephone"
                    autoComplete="telephone"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                  {"telephoneError" && (
                    <div className="text-red-500">{telephoneError}</div>
                  )}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="adresse"
                    className="block text-sm font-medium text-gray-700"
                  >
                    N° et Nom de rue <span className="text-red-500"> * </span>
                  </label>
                  <Autocomplete
                    className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                    apiKey="AIzaSyDK4L8SN7bnmjDstRZxqkqitqyQufbopLs"
                    name="adresse"
                    id="adresse"
                    options={{
                      types: ["geocode", "establishment"],
                    }}
                    onPlaceSelected={(place) => {
                      console.log(place);
                      const address = place.formatted_address;
                      console.log("Formatted:", place.formatted_address);
                      console.log("address:", address);
                      console.log("Selected address:", place);

                      // Find the postal code and city from address components
                      const postalCode =
                        place.address_components.find((component) =>
                          component.types.includes("postal_code")
                        )?.long_name || "";

                      const city =
                        place.address_components.find((component) =>
                          component.types.includes("locality")
                        )?.long_name || "";

                      const placeIds = place.place_id;

                      // Update the state variables
                      setPostale(postalCode);
                      setVille(city);
                      setAdresse(address);
                      setPlaceId(placeIds);
                    }}
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="ville"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Ville <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    value={ville}
                    onChange={(event) => setVille(event.target.value)}
                    name="ville"
                    required
                    id="ville"
                    autoComplete="ville"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="postale"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Code Postale: <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    name="postale"
                    value={postale}
                    onChange={handlePostaleChange}
                    required
                    id="postale"
                    autoComplete="postale"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                  {postaleError && (
                    <p style={{ color: "red" }}>{postaleError}</p>
                  )}
                </div>
              </div>
            </div>

            {typeDePrestation !== "Installation" && (
              <div className="bg-white px-4 py-5 sm:p-6">
                <legend className="contents text-sm font-medium text-gray-600 mt-4">
                  Client Sous Contrat ?<span className="text-red-500"> * </span>
                </legend>
                <div className="mt-2 gap-4 flex">
                  <div className="flex items-center">
                    <input
                      id="oui"
                      name="contract"
                      type="radio"
                      value="oui"
                      onChange={(e) => setContract(e.target.value)}
                      checked={contract === "oui"}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="oui"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Oui
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="non"
                      name="contract"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      value="non"
                      onChange={(e) => setContract(e.target.value)}
                      checked={contract === "non"}
                    />
                    <label
                      htmlFor="non"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Non
                    </label>
                  </div>
                </div>
              </div>
            )}

            {/*<div className="bg-white px-4 py-5 sm:p-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2 mt-2">
                <label
                  htmlFor="adresse"
                  className="block text-sm font-medium text-gray-700"
                >
                  N° et Nom de rue <span className="text-red-500"> * </span>
                </label>

                <Autocomplete
                  className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  apiKey="AIzaSyDK4L8SN7bnmjDstRZxqkqitqyQufbopLs"
                  name="adresse"
                  id="adresse"
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  onPlaceSelected={(place) => {
                    console.log(place);
                    const address = place.formatted_address;
                    console.log("Selected address:", place);
                    const latitude = place.geometry.location.lat();
                    const longitude = place.geometry.location.lng();
                    const placeIds = place.place_id;
                    setAdresse(address);
                    setPlaceId(placeIds);
                  }}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-4">
                <label
                  htmlFor="ville"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ville <span className="text-red-500"> * </span>
                </label>
                <input
                  type="text"
                  value={ville}
                  onChange={(event) => setVille(event.target.value)}
                  name="ville"
                  required
                  id="ville"
                  autoComplete="ville"
                  className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-4">
                <label
                  htmlFor="postale"
                  className="block text-sm font-medium text-gray-700"
                >
                  Code Postale: <span className="text-red-500"> * </span>
                </label>
                <input
                  type="text"
                  name="postale"
                  value={postale}
                  onChange={handlePostaleChange}
                  required
                  id="postale"
                  autoComplete="postale"
                  className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
                {postaleError && <p style={{ color: "red" }}>{postaleError}</p>}
              </div>
            </div>*/}
          </div>

          <div className="mb-4">
            <h2 className="text-lg font-bold mb-2 px-4">Ajouter un appareil</h2>

            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-6 gap-6 mb-4">
                <div className="col-span-6 sm:col-span-3">
                  <div>
                    <label
                      htmlFor="marque"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Marque du produit{" "}
                      <span className="text-red-500"> * </span>
                    </label>
                    <select
                      id="marque"
                      name="marque"
                      autoComplete="marque"
                      value={prod_marque}
                      onChange={(event) => setMarque(event.target.value)}
                      required
                      className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                    >
                      <option value="">Sélectionner une marque</option>
                      {Marque.map((marqueOption) => (
                        <option key={marqueOption} value={marqueOption}>
                          {marqueOption}
                        </option>
                      ))}
                      <option value="autre">Autre</option>
                    </select>
                    {prod_marque === "autre" && (
                      <div>
                        <label htmlFor="autre-marque" className="margin-top">
                          Autre marque <span className="text-red-500"> * </span>
                        </label>
                        <input
                          id="autre-marque"
                          name="autre-marque"
                          type="text"
                          autoComplete="off"
                          value={prod_autreMarque}
                          onChange={handleAutreMarque}
                          required
                          style={{ marginTop: "16px" }}
                          className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Marque du produit"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="numeroSerie"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Numéro de série
                  </label>
                  <input
                    type="text"
                    name="numeroSerie"
                    value={prod_numeroSerie}
                    onChange={(event) => setNumeroSerie(event.target.value)}
                    required
                    id="numeroSerie"
                    autoComplete="numeroSerie"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="puissance"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Puissance: <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    name="puissance"
                    value={prod_puissance}
                    onChange={(event) => setPuissance(event.target.value)}
                    required
                    id="puissance"
                    autoComplete="puissance"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="reference"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Reference :
                  </label>
                  <input
                    type="text"
                    name="reference"
                    value={prod_reference}
                    onChange={(event) => setReference(event.target.value)}
                    required
                    id="reference"
                    autoComplete="reference"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="quantite"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Quantité:
                  </label>
                  <input
                    type="number"
                    name="quantite"
                    value={prod_quantite}
                    onChange={(event) => setQuantite(event.target.value)}
                    required
                    id="quantite"
                    autoComplete="quantite"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                {typeDePrestation !== "Installation" && (
                  <div className="flex justify-between w-full col-span-6 sm:col-span-3 gap-1 lg:gap-2">
                    <div className="w-1/2">
                      {" "}
                      {/* Add the w-1/2 class here */}
                      <label
                        htmlFor="moisInstallation"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Mois d'installation:{" "}
                      </label>
                      <select
                        id="moisInstallation"
                        name="moisInstallation"
                        value={prod_moisInstallation}
                        onChange={(e) => setMoisInstallation(e.target.value)}
                        className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      >
                        {Mois.map((mois) => (
                          <option key={mois} value={mois}>
                            {mois}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-1/2">
                      {" "}
                      {/* Add the w-1/2 class here */}
                      <label
                        htmlFor="dateInstallation"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Année d'installation:{" "}
                      </label>
                      <select
                        id="annéeInstallation"
                        name="annéeInstallation"
                        value={prod_dateInstallation}
                        onChange={(e) => setDateInstallation(e.target.value)}
                        className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      >
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              {typeDePrestation !== "Installation" && (
                <div>
                  <legend className="contents text-sm font-medium text-gray-600 mt-4">
                    Votre appareil est-il entretenu ?
                    <span className="text-red-500"> * </span>
                  </legend>
                  <div className="mt-2 gap-4 flex">
                    <div className="flex items-center">
                      <input
                        id="oui"
                        name="entretenu"
                        type="radio"
                        value="oui"
                        onChange={(e) => setEntretenu(e.target.value)}
                        checked={prod_entretenu === "oui"}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor="oui"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Oui
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="non"
                        name="entretenu"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        value="non"
                        onChange={(e) => setEntretenu(e.target.value)}
                        checked={prod_entretenu === "non"}
                      />
                      <label
                        htmlFor="non"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Non
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div>
                  <label
                    className="block text-sm font-medium text-gray-700 rounded-md"
                    style={{ marginTop: "16px" }}
                    id="photoDuProduitid"
                  >
                    Photo du produit
                  </label>


                  <div>
                              {prod_photoDuProduit && (
                                <div className="mt-8 flex flex-between w-full gap-8">
                                  <div className="flex items-center flex-wrap w-[80%] gap-4">
                                    {Array.from(prod_photoDuProduit).map(
                                      (file, index) => (
                                        <div key={index} className="w-[150px]">
                                          <div className="flex justify-center">
                                            {file.name
                                              .toLowerCase()
                                              .endsWith(".pdf") ? (
                                              <>
                                                <img
                                                  src={pdfImage}
                                                  alt={`PDF Preview-${index}`}
                                                  className="h-16 w-16 object-cover rounded"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={URL.createObjectURL(
                                                    file
                                                  )}
                                                  alt={`Preview-${index}`}
                                                  className="h-16 w-16 object-cover rounded"
                                                />
                                              </>
                                            )}
                                            <button
                                              className="bg-gray-100 p-1 rounded-sm text-[10px] mb-10 mr-[-4px] font-bold text-red-500"
                                              onClick={() =>
                                                handleRemovePicture(index)
                                              }
                                            >
                                              X
                                            </button>
                                          </div>
                                          <div className="w-[150px] h-[100px] mt-4 flex justify-center">
                                            <p className="text-sm text-center">
                                              {file.name}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className="flex gap-2">
                                    {/*<button
                                      className="mt-[10px] bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                      onClick={() => setPhotoDuProduit([])}
                                    >
                                      Change
                                          </button>*/}
                                    <div className="mt-4">
                                      <label
                                        htmlFor="photoDuProduit"
                                        className=""
                                      >
                                        {/*<IoAddCircleOutline className="h-10 w-10 text-green-500" />*/}
                                        <svg
                                          className="mx-auto h-12 w-12 text-gray-400 pointer"
                                          stroke="currentColor"
                                          fill="none"
                                          viewBox="0 0 48 48"
                                          aria-hidden="true"
                                        >
                                          <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        <p className="text-sm">Ajouter un fichier </p>
                                        <input
                                          id="photoDuProduit"
                                          name="photoDuProduit"
                                          type="file"
                                          multiple
                                          className="sr-only"
                                          onChange={handleAddMorePictures}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!prod_photoDuProduit && (
                                <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                  <div className="space-y-1 text-center">
                                    <svg
                                      className="mx-auto h-12 w-12 text-gray-400"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 48 48"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>

                                    <div className="flex text-sm text-gray-600 justify-center">
                                      <label
                                        htmlFor="photoDuProduit"
                                        className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                      >
                                        <span>Ajouter un fichier </span>
                                        <input
                                          id="photoDuProduit"
                                          name="photoDuProduit"
                                          type="file"
                                          multiple
                                          className="sr-only"
                                          value=""
                                          onChange={handlePhotoDuProduit}
                                        />
                                      </label>
                                      <p className="pl-1">
                                        ou faites glisser et déposez
                                      </p>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                      PNG, JPG, PDF
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                            {confirmationModal ? (
          <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3">
                    <div className="mt-2 text-center text-center">
                      <h4 className="text-lg font-medium text-gray-800">
                        Votre demande est en cours de traitement
                      </h4>
                      {/*<div className="flex justify-center">
                        <Link to="/tableau-de-bord">
                          <button className=" mt-4 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2">
                            Mon compte
                          </button>
                        </Link>
                      </div>*/}
                      <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                        Votre demande est en cours de traitement. Nous mettons
                        tout en œuvre pour y répondre rapidement. Nous vous
                        remercions de votre patience et nous nous excusons pour
                        tout éventuel retard. Pour toute question, n'hésitez pas
                        à nous contacter.
                      </p>
                      <div className="items-center gap-2 mt-3 sm:flex"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700 rounded-md"
                  style={{ marginTop: "16px" }}
                  id="photoDuProduitid"
                >
                  {typeDePrestation === "Installation" ? "Informations supplémentaires concernant l'installation" : "Décrire votre problème"} {" "}

                  <span className="text-red-500"> * </span>
                </label>
                <div className="mt-1">
                  <textarea
                    id="probleme"
                    name="probleme"
                    rows={3}
                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4  ml-auto mr-auto"
                    placeholder="lorem ipsum"
                    defaultValue={""}
                    value={prod_probleme}
                    onChange={handleInformation}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={handleRecaptcha}
          />
        </form>
        {error && <p className="text-red-700">{error}</p>}
        <div className="mt-4 flex justify-end items-center mr-[60px] gap-5">
          <button
            onClick={handleSubmit}
            className="submit-button text-base rounded-md  text-white bg-blue-500"
          >
            Valider la demande
          </button>
        </div>
      </div>
    </>
  );
};

export default NewProjectPro;