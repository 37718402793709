import React, { useEffect, useState } from "react";
import { BreadCrumb, CRUDTable } from "../../../Components";
import {
  IoEyeOutline,
  IoPencil,
  IoTrashOutline,
  IoAddCircleOutline,
} from "react-icons/io5";
import { DataTable } from "../../../Constants/dummy";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

//TODO: Reload + Check Product & Intervention
const UserClaims = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [history, setHistory] = useState([]);
  const [Pid, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedrequest, setSelectedRequest] = useState("");
  const [requestId, setRequestId] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [typeDePrestation, setTypeDePrestation] = useState("");
  const [dateDeCreation, setDateCreation] = useState("");
  const [departements, setDepartement] = useState("");
  const [status, setStatut] = useState("");
  const [typeDeProjet, setTypeDeProjet] = useState("");
  const [selectedID, setSelectedId] = useState("");
  const [selectedUserID, setSelectedUserId] = useState("");
  const [techs, setTechs] = useState([]);
  const [pending, setPending] = useState([]);
  const [clientAccept, setClientAccept] = useState("");
  const [claime, setClaime] = useState("");
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("");
  const entreprise = sessionStorage.getItem("entreprise");
  const [maintenance, setMaintenance] = useState("");
  const [client, setClient] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  const apiUrl = 'https://lebontechnicien.net'

  const handleModalToggle = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [data, setData] = useState(DataTable);
  const handleDataChange = (newData) => {
    setData(newData);
  };

  const handleClaime = (event) => {
    setClaime(event.target.value);
  };

  const userId = sessionStorage.getItem("id");
  console.log("selectedUserID: " + selectedUserID);
  console.log("selectedUserID Clent: " + client);

  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type }),
        });
        const data = await response.json();

        const pendingLength = data.filter(
          (item) => item.creator === sessionStorage.getItem("id")
        ).length;
        const pendingData = data.filter(
          (item) => item.creator === sessionStorage.getItem("id")
        );

        switch (type) {
          case "under_pro":
            setTechs(data.reverse());
            setPending(pendingData);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData("under_pro"); // Call fetchData with the desired type
  }, []);

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(
          `${apiUrl}/prods/user_product`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              email: `${sessionStorage.getItem("email")}`,
            }),
          }
        );

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => setHistory(data))
      .catch((error) => console.error(error));
  }, []);

  console.log("historyall: " + history);

  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const monthName = monthNames[monthIndex];
    return `${day} ${monthName} ${year}`;
  };

  const info =
    history.length > 0
      ? history
          .filter(
            (item) =>
              item.type === "Demande de Réclamation" &&
              item.created_by === sessionStorage.getItem("id")
          )
          .filter((item) => {
            if (!selectedYear && !selectedMonth && !selectedDay) {
              return true;
            }
            const date = new Date(item.created_at);
            return (
              (selectedYear ? date.getFullYear() === selectedYear : true) &&
              (selectedMonth ? date.getMonth() === selectedMonth - 1 : true) &&
              (selectedDay ? date.getDate() === selectedDay : true)
            );
          })
          .map((item) => {
            return {
              created_at: item.created_at,
              Message: item.claime,
              Intervention: item.selectedrequest ? (
                <Link to={`/user-intervention-details/${item.Request}`}>
                  {item.selectedrequest}
                </Link>
              ) : (
                item.selectedrequest
              ),
              Statut: item.status,
              id: item._id,
            };
          })
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort in descending order
          .map((item) => {
            return {
              Création: formatDate(
                item.created_at.split(" ").slice(1, 4).join(" ")
              ),
              Message: item.Message,
              //Intervention: item.Intervention,
              Statut: item.Statut,
              id: item.id,
            };
          })
      : [];

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    /*const requestData = {
      Pid: productId,
      msg: claime,
      type: "Demande de Réclamation",
      email: sessionStorage.getItem("email"),
    };*/

    const requestData = new FormData();
    requestData.append("Pid", Pid);
    requestData.append("selectedrequest", selectedrequest);
    requestData.append("selectedID", selectedID);
    requestData.append("typeDePrestation", typeDePrestation);
    requestData.append("typeDeProjet", typeDeProjet);
    requestData.append("statutRequest", status);
    requestData.append("departementRequest", departements);
    requestData.append("dateDeCreation", dateDeCreation);
    requestData.append("typeDeProjet", typeDeProjet);
    requestData.append("clientAccept", clientAccept);
    requestData.append("claime", claime);
    requestData.append("client", client);
    requestData.append("type", "Demande de Réclamation");
    requestData.append("zone", sessionStorage.getItem("zone"));
    requestData.append("email", sessionStorage.getItem("email"));
    requestData.append("nom", sessionStorage.getItem("nom"));
    requestData.append("prenom", sessionStorage.getItem("prenom"));
    requestData.append("phone", sessionStorage.getItem("phone"));
    requestData.append("adresse", sessionStorage.getItem("adresse"));
    requestData.append("zipcode", sessionStorage.getItem("zipcode"));
    requestData.append("userId", sessionStorage.getItem("id"));

    let isValid = true;
    const requiredFields = ["claime"];
    for (const field of requiredFields) {
      if (!requestData.get(field)) {
        isValid = false;
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "red";
        const errorText = document.createElement("p");
        errorText.innerText = `Veuillez remplir ce champ.`;
        errorText.style.color = "red";
        input.parentNode.parentNode.insertBefore(
          errorText,
          input.parentNode.nextSibling
        );
      } else {
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "initial";
        const errorText = input.parentNode.nextSibling;
        if (
          errorText &&
          errorText.innerText.startsWith("Veuillez remplir ce champ")
        ) {
          errorText.remove();
        }
      }
    }

    if (!isValid) {
      return;
    }
    setConfirmationModal(false);
    fetch(`${apiUrl}/request/create_request`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setConfirmationModal(false);
          console.log("success");
          window.location.reload();
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
    setIsModalVisible(false);
    //window.location.reload()
  };

  return (
    <>
      <BreadCrumb From={""} To={"Réclamations"} hrefFrom={"/tableau-de-bord"} />
      <div className="mt-10 sm:mt-0 max-w-screen-xl  ml-auto mr-auto">
        <div className="md:grid md:grid-cols-1 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <div className="flex justify-between w-full mt-10">
                <h3 className="text-lg font-medium leading-6 text-gray-900 title-font-h3">
                  Liste des réclamations
                </h3>
                <div>
                  <button
                    onClick={handleModalToggle}
                    className=" userProfile-btn inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Envoyer
                  </button>
                </div>
              </div>
              {isModalVisible ? (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => setIsModalVisible(false)}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Nouvelle Réclamations
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez remplir toutes les informations requises
                              dans le formulaire pour ajouter une nouvelle
                              réclamations.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Décrire votre problème avec le plus de
                                    détails possibles:
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={claime}
                                    onChange={handleClaime}
                                  />
                                </div>
                              </form>
                            </div>
                            {entreprise === "non" && (
                              <>
                                <div>
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full] mt-4"
                                  >
                                    Pour quel appareil
                                  </label>
                                  <select
                                    id="productId"
                                    name="Pid"
                                    value={Pid}
                                    onChange={(e) => {
                                      setProductId(e.target.value);
                                      setProductName(
                                        pendingList.find(
                                          (item) => item._id === e.target.value
                                        ).produits
                                      );
                                      setSelectedProductId(e.target.value); // update selected product id
                                    }}
                                    className=" w-[93%] mt-1 block rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                  >
                                    <option value="">
                                      sélectionner votre produit
                                    </option>
                                    {pendingList.map((item) => (
                                      <option
                                        key={item._id}
                                        value={item._id}
                                        className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                                      >
                                        <div className="flex gap-4">
                                          <div></div>
                                          <div>
                                            <p className="text-sm ml-2 bg-teal-500 text-white px-2 rounded">
                                              {item.typeDuBien}
                                            </p>
                                          </div>
                                        </div>
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div>
                                  <label
                                    htmlFor="selectedrequest"
                                    className="block text-[14px] font-medium text-gray-700 w-[full] mt-4"
                                  >
                                    Pour quelle intervention
                                  </label>
                                  <select
                                    id="selectedrequest"
                                    name="selectedrequest"
                                    value={selectedrequest}
                                    required
                                    onChange={(e) => {
                                      const selectedValue = e.target.value;

                                      const [
                                        selectedId,
                                        typePrestation,
                                        typeProjet,
                                      ] = selectedValue.split("|");
                                      setSelectedRequest(selectedValue);
                                      setTypeDePrestation(typePrestation);
                                      setTypeDeProjet(typeProjet);
                                      setSelectedId(selectedId);
                                    }}
                                    className="w-[93%] mt-1 block rounded-md border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                  >
                                    <option value="">
                                      sélectionner votre intervention
                                    </option>
                                    {history
                                      .filter(
                                        (item) =>
                                          item.created_by === userId &&
                                          item.productId ===
                                            selectedProductId &&
                                          item.status !== "closed"
                                      )
                                      .map((item) => (
                                        <option
                                          key={item._id}
                                          value={`${item._id}|${item.typeDePrestation}|${item.typeDeProjet}`}
                                          className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                                        >
                                          {item.typeDePrestation} -{" "}
                                          {item.typeDeProjet}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </>
                            )}
                            {entreprise === "oui" && (
                              <>
                                <div className="w-[90%] ml-auto mr-auto mt-8 font-medium text-gray-700 mb-2">
                                  <legend className="contents text-sm font-medium text-gray-600 mt-4">
                                    Y a-t-il déjà un projet ajouté avec le
                                    client ?
                                    <span className="text-red-500"> * </span>
                                  </legend>
                                  <div className="mt-2 gap-4 flex">
                                    <div className="flex items-center">
                                      <input
                                        id="oui"
                                        name="entretenu"
                                        type="radio"
                                        value="oui"
                                        onChange={(e) =>
                                          setMaintenance(e.target.value)
                                        }
                                        checked={maintenance === "oui"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor="oui"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        Oui
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="non"
                                        name="entretenu"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        value="non"
                                        onChange={(e) =>
                                          setMaintenance(e.target.value)
                                        }
                                        checked={maintenance === "non"}
                                      />
                                      <label
                                        htmlFor="non"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        Non
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {maintenance === "oui" && (
                                  <>
                                    <div>
                                      <label
                                        htmlFor="selectedrequest"
                                        className="block text-[14px] font-medium text-gray-700 w-[full] mt-4"
                                      >
                                        Pour quelle intervention
                                      </label>
                                      <select
                                        id="selectedrequest"
                                        name="selectedrequest"
                                        value={selectedrequest}
                                        required
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;

                                          const [
                                            selectedId,
                                            typePrestation,
                                            typeProjet,
                                          ] = selectedValue.split("|");
                                          setSelectedRequest(selectedValue);
                                          setTypeDePrestation(typePrestation);
                                          setTypeDeProjet(typeProjet);
                                          setSelectedId(selectedId);
                                        }}
                                        className="w-[93%] mt-1 block rounded-md border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                      >
                                        <option value="">
                                          sélectionner votre intervention
                                        </option>
                                        {history
                                          .filter(
                                            (item) =>
                                              item.created_by === userId &&
                                              item.status !== "closed"
                                          )
                                          .map((item) => (
                                            <option
                                              key={item._id}
                                              value={`${item._id}|${item.typeDePrestation}|${item.typeDeProjet}`}
                                              className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                                            >
                                              {item.typeDePrestation} -{" "}
                                              {item.typeDeProjet} - {item.nom}{" "}
                                              {item.prenom}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </>
                                )}
                                {maintenance === "non" && (
                                  <>
                                    <select
                                      id="client"
                                      name="client"
                                      value={client}
                                      onChange={(e) =>
                                        setClient(e.target.value)
                                      }
                                      className="w-[93%] mt-1 block rounded-md border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    >
                                      <option value="">
                                        sélectionner Votre Client
                                      </option>
                                      {pending.map((item) => (
                                        <option
                                          key={item.nom}
                                          value={item._id}
                                          onChange={(e) => {
                                            setSelectedUserId(e.target.value);
                                          }}
                                          className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                                        >
                                          <div className="flex gap-4">
                                            <div></div>
                                            <div>
                                              <p className="text-sm ml-2 bg-green-500 text-white px-2 rounded">
                                                {item.nom} - {item.prenom}
                                              </p>
                                            </div>
                                          </div>
                                        </option>
                                      ))}
                                    </select>
                                    <div>
                                      <label
                                        htmlFor="selectedrequest"
                                        className="block text-[14px] font-medium text-gray-700 w-[full] mt-4"
                                      >
                                        Pour quelle intervention
                                      </label>
                                      <select
                                        id="selectedrequest"
                                        name="selectedrequest"
                                        value={selectedrequest}
                                        required
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;

                                          const [
                                            selectedId,
                                            typePrestation,
                                            typeProjet,
                                          ] = selectedValue.split("|");
                                          setSelectedRequest(selectedValue);
                                          setTypeDePrestation(typePrestation);
                                          setTypeDeProjet(typeProjet);
                                          setSelectedId(selectedId);
                                        }}
                                        className="w-[93%] mt-1 block rounded-md border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                      >
                                        <option value="">
                                          sélectionner votre intervention
                                        </option>
                                        {history
                                          .filter(
                                            (item) =>
                                              item.created_by === userId &&
                                              item.status !== "closed" &&
                                              item._id === client
                                          )
                                          .map((item) => (
                                            <option
                                              key={item._id}
                                              value={`${item._id}|${item.typeDePrestation}|${item.typeDeProjet}`}
                                              className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                                            >
                                              {item.typeDePrestation} -{" "}
                                              {item.typeDeProjet} - {item.nom}{" "}
                                              {item.prenom}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </>
                                )}
                                {confirmationModal ? (
                                  <>
                                    <div className="fixed inset-0 z-10 overflow-y-auto">
                                      <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
                                      <div className="flex items-center min-h-screen px-4 py-8">
                                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                          <div className="mt-3">
                                            <div className="mt-2 text-center text-center">
                                              <h4 className="text-lg font-medium text-gray-800">
                                                Votre demande est en cours de
                                                traitement
                                              </h4>
                                              {/*<div className="flex justify-center">
                        <Link to="/tableau-de-bord">
                          <button className=" mt-4 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2">
                            Mon compte
                          </button>
                        </Link>
                      </div>*/}
                                              <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                                                Votre demande est en cours de
                                                traitement. Nous mettons tout en
                                                œuvre pour y répondre
                                                rapidement. Nous vous remercions
                                                de votre patience et nous nous
                                                excusons pour tout éventuel
                                                retard. Pour toute question,
                                                n'hésitez pas à nous contacter.
                                              </p>
                                              <div className="items-center gap-2 mt-3 sm:flex"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </>
                            )}
                            <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                onClick={handleSubmit}
                              >
                                Ajouter
                              </button>
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={() => setIsModalVisible(false)}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className="flex justify-end gap-4 w-[100%] ml-auto mr-auto">
            <select
              className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50"
              value={
                selectedYear == null || isNaN(selectedYear)
                  ? ""
                  : selectedYear.toString()
              }
              onChange={(e) =>
                setSelectedYear(
                  e.target.value === "" ? null : parseInt(e.target.value)
                )
              }
            >
              <option value="">Année</option>
              {[
                ...new Set(
                  info.map((item) => new Date(item.Création).getFullYear())
                ),
              ].map((year) => (
                <option key={year} value={year}>
                  {isNaN(year) ? null : year}
                </option>
              ))}
            </select>

            <select
              className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50"
              value={selectedMonth ?? ""}
              onChange={(e) =>
                setSelectedMonth(
                  e.target.value === "" ? null : parseInt(e.target.value)
                )
              }
            >
              <option value="">Mois</option>
              {monthNames.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>

            {/*<select
              className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50"
              value={selectedDay ?? ""}
              onChange={(e) =>
                setSelectedDay(
                  e.target.value === "" ? null : parseInt(e.target.value)
                )
              }
            >
              <option value="">Jour</option>
              {[
                ...new Set(
                  info.map((item) => new Date(item.Création).getDate())
                ),
              ].map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
              </select>*/}
            <select
              value={selectedStatus}
              onChange={(event) => setSelectedStatus(event.target.value)}
              className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50"
            >
              <option value="">Par statut</option>
              {Array.from(new Set(info.map((item) => item.Statut))).map(
                (status) => (
                  <option key={status} value={status}>
                    {status === "Pending"
                      ? "en cours"
                      : status === "closed"
                      ? "cloturé"
                      : status === "closed_tech"
                      ? "cloturé par technicien"
                      : status === "claim"
                      ? "réclamation"
                      : status === "Denied_payment"
                      ? "Devis non accepté"
                      : status === "Accepted_payment"
                      ? "Devis accepté"
                      : status === "Accepted"
                      ? "accepté par client"
                      : status === "solved"
                      ? "résolu"
                      : status === "unsolved"
                      ? "non résolu"
                      : status}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="mt-5 md:col-span-2 md:mt-0">
            <CRUDTable
              data={info.filter((item) => {
                if (!selectedValue && !selectedStatus) {
                  return true;
                }

                return (
                  (selectedValue ? item.Création === selectedValue : true) &&
                  (selectedStatus ? item.Statut === selectedStatus : true)
                );
              })}
              link={`/user-claim-details/`}
              eyeIcon={
                <IoEyeOutline
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "#f8c408",
                  }}
                />
              }
              onDataChange={handleDataChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserClaims;
