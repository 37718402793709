import React, { useEffect, useState } from "react";
import { BreadCrumb, ReturnButton } from "../../../Components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  validatePostalCodeInput,
  validateTelephoneInput,
  validateEmail,
} from "../../../Components/FormValidation";
import Autocomplete from "react-google-autocomplete";
import { Domaines, Marque } from "../../../Constants/dummy";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  IbanElement,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe("YOUR_STRIPE_PUBLISHABLE_KEY");

const NewContract = () => {
  const today = new Date();
  const minDate = new Date();
  minDate.setDate(today.getDate() + 15);

  const [selectedDate, setSelectedDate] = useState(minDate);
  const [productId, setProductId] = useState("");
  const [client, setClient] = useState("");
  const [requestID, setRequestID] = useState("");
  const [productID, setProductID] = useState("");
  const [clientID, setClientID] = useState("");
  const [error, setError] = useState("");
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [history, setHistory] = useState([]);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [techs, setTechs] = useState([]);
  const [pending, setPending] = useState([]);
  const [maintenance, setMaintenance] = useState("");
  const [productFetched, setProductFetched] = useState("");
  const [contractType, setContractType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [postale, setPostale] = useState("");
  const [email, setEmail] = useState("");
  const [ville, setVille] = useState("");
  const [telephone, setTelephone] = useState("");
  const [postaleError, setPostaleError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [prod_marque, setMarque] = useState("");
  const [prod_numeroSerie, setNumeroSerie] = useState("");
  const [prod_puissance, setPuissance] = useState("");
  const [prod_reference, setReference] = useState("");
  const [prod_quantite, setQuantite] = useState(1);
  const [prod_moisInstallation, setMoisInstallation] = useState("");
  const [prod_dateInstallation, setDateInstallation] = useState("");
  const [prod_entretenu, setEntretenu] = useState(false);
  const [prod_probleme, setProbleme] = useState("");
  const [prod_photoDuProduit, setPhotoDuProduit] = useState(null);
  const [prod_autreMarque, setAutreMarque] = useState("");
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedSubdomain, setSelectedSubdomain] = useState(null);
  const [typeDePrestation, setTypeDePrestation] = useState("");
  const [iban, setIban] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [settedResult, setResult] = useState("");
  const [ribModal, setRibModal] = useState(false);
  const [id, setID] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [setup, setSetup] = useState("");
  const [payment_method, setPayement_Methode] = useState("");
  const [id_pro, setidpro] = useState("");
  const [countDate, setCountDate] = useState(0);

  const stripe = useStripe();
  const elements = useElements();

  const handleAutreMarque = (event) => {
    setAutreMarque(event.target.value);
  };

  const apiUrl = 'https://lebontechnicien.net'

  const handleSubmitStripe = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIban(elements.getElement(IbanElement));

    const result = await stripe.confirmSepaDebitSetup(clientSecret, {
      payment_method: {
        type: "sepa_debit",
        sepa_debit: iban,
        billing_details: {
          name: nom,
          email: email,
          address: {
            city: ville,
            line1: adresse,
            postal_code: postale,
            //state: sessionStorage.getItem("adresse"),
            country: "FR",
          },
          phone: telephone,
        },
      },
    });

    if (result.error) {
      // Show error to your customer.
      setResult(result.error);
      setResultMessage(
        <span style={{ color: "red" }}>{result.error.message}</span>
      );
      setSetup("Error");
    } else {
      // Show a confirmation message to your customer.
      // The SetupIntent is in the 'succeeded' state.
      console.log("sucess", result);
      setResult(result);

      setResultMessage(
        <span style={{ color: "green" }}>
          La configuration du paiement a réussi.
        </span>
      );
      setSetup("Working");
    }
  };

  const IBAN_STYLE = {
    base: {
      color: "#32325d",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      ":-webkit-autofill": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
      ":-webkit-autofill": {
        color: "#fa755a",
      },
    },
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: "FR",
    style: IBAN_STYLE,
  };

  const handleResult = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const requestData = {
      payment_method: settedResult,
      email: id_pro,
      rid: id,
    };

    fetch(`${apiUrl}/wallet/result`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("success");
          window.location.replace("/tableau-de-bord");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
  };

  const handlePostaleChange = (event) => {
    const value = event.target.value;
    setPostale(value);
    setPostaleError(validatePostalCodeInput(value));
  };

  const handleTypeSelection = (domain, subdomain) => {
    setSelectedDomain(domain);
    setSelectedSubdomain(subdomain);
  };

  const Mois = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const handleTelephoneChange = (event) => {
    const value = event.target.value;
    setTelephone(value);
    setTelephoneError(validateTelephoneInput(value));
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailError(validateEmail(value));
  };

  const handleRecaptcha = (value) => {
    console.log("reCAPTCHA value:", value);
    setRecaptchaValue(value);
  };

  const entreprise = sessionStorage.getItem("entreprise");

  const handleDateChange = (date) => {
    setCountDate(1);
    setSelectedDate(date);
  };

  // Fetches the needed Data
  useEffect(() => {
    fetch(`${apiUrl}/request/history`)
      .then((res) => res.json())
      .then((data) => {
        const filteredData = data.filter(
          (item) => item.created_by === sessionStorage.getItem("id")
        );
        setHistory(filteredData);
      })
      .catch((error) => console.error(error));
  }, [sessionStorage.getItem("id")]);

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            email: `${sessionStorage.getItem("email")}`,
          }),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  useEffect(() => {
    console.log("requestID", requestID);
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: requestID }),
        });

        if (!response.ok) {
          // Handle any error here, e.g., show an error message.
          // You can use setState to manage an error state if needed.
          return;
        }

        const data = await response.json();
        setProductFetched(data);
      } catch (error) {
        // Handle any unexpected errors here.
        console.error("Error fetching product:", error);
      }
    };

    // Call the fetchProduct function when the component mounts
    fetchProduct();
  }, [requestID]);

  // Now, you can use productFetched here, safely.
  console.log("Product fetched", productFetched);

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    // Initialize an array to store missing field names
    const missingFields = [];

    // Check if each required field is empty and add its name to the array
    if (!selectedDate) {
      missingFields.push("Date");
    }

    if (entreprise === "oui") {
      if (!nom) {
        missingFields.push("nom");
      }
      if (!prenom) {
        missingFields.push("prenom");
      }
      if (!telephone) {
        missingFields.push("telephone");
      }
      if (!adresse) {
        missingFields.push("addresse");
      }
      if (!ville) {
        missingFields.push("ville");
      }
      if (!postale) {
        missingFields.push("postale");
      }
      if (!prod_marque) {
        missingFields.push("marque");
      }
      if (!prod_puissance) {
        missingFields.push("puissance");
      }
      if (!prod_quantite) {
        missingFields.push("quantite");
      }
    }

    // Check if there are missing fields
    if (missingFields.length > 0) {
      // Create the error message by joining the missing field names
      const errorMessage = `Veuillez remplir les champs suivants : ${missingFields.join(
        ", "
      )}`;

      setErrorMessage(errorMessage);
      return; // Block the request
    }

    const requestData = new FormData();
    requestData.append("selectedDate", selectedDate);
    requestData.append("Pid", productId);
    requestData.append("productID", productID);
    requestData.append("requestID", requestID);
    requestData.append("type", "Demande de Maintenance");
    requestData.append("email", sessionStorage.getItem("email"));
    requestData.append("email_Pro", email);
    requestData.append("uid", sessionStorage.getItem("id"));
    requestData.append("maintenance", maintenance);
    requestData.append("recaptchaValue", recaptchaValue);
    requestData.append("Contrat", "1");
    requestData.append("contractType", contractType);
    entreprise === "oui"
      ? requestData.append("nom_Pro", nom)
      : requestData.append("nom_Pro", sessionStorage.getItem("prenom"));
    entreprise === "oui"
      ? requestData.append("prenom_Pro", prenom)
      : requestData.append("prenom_Pro", sessionStorage.getItem("nom"));
    requestData.append("demandeur", sessionStorage.getItem("raisonSocial"));
    entreprise === "oui"
      ? requestData.append("telephone", sessionStorage.getItem("telephone"))
      : requestData.append("telephone", telephone);
    entreprise === "oui"
      ? requestData.append("adresse", sessionStorage.getItem("adresse"))
      : requestData.append("adresse", adresse);
    sessionStorage.getItem("adresse");
    requestData.append("ville", ville);
    requestData.append("postale", postale);
    requestData.append("marque", prod_marque);
    requestData.append("puissance", prod_puissance);
    requestData.append("quantite", prod_quantite);
    requestData.append("dateInstallation", prod_dateInstallation);
    requestData.append("numeroSerie", prod_numeroSerie);
    requestData.append("reference", prod_reference);
    requestData.append("moisInstallation", prod_moisInstallation);
    requestData.append("typeDePrestation", selectedDomain);
    requestData.append("typeDeProjet", selectedDomain);
    requestData.append("selectedDomain", selectedDomain);
    requestData.append("selectedSubdomain", selectedSubdomain);
    requestData.append("AutreMarque", prod_autreMarque);
    requestData.append("adresse_pro", adresse);
    requestData.append("telephone_pro", telephone);
    entreprise === "non"
      ? requestData.append("contractType", "Normal Client")
      : requestData.append("contractType", "Pro Client");
    entreprise === "oui"
      ? requestData.append("Paiement", "Paiement en attente")
      : requestData.append("Paiement", "Paiement effectué");

    setConfirmationModal(true);
    setErrorMessage(null);
    fetch(`${apiUrl}/request/create_request`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          //Data Checking
          
          setidpro(data?.id_pro);
          if (data.error) {
            setErrorMessage(
              "Adresse e-mail déjà associé à un contrat. Veuillez saisir une nouvelle adresse e-mail."
            );
            setConfirmationModal(false);
          }

          //Comparison
          if (entreprise === "oui") {
            setID(data?.request._id);
            setClientSecret(data?.stripe?.client_secret);
            setRibModal(true);
            
            if (data.error) {
              setErrorMessage(
                "Adresse e-mail déjà associé à un contrat. Veuillez saisir une nouvelle adresse e-mail."
              );
            }
          } else {
            window.location.replace("/contrat");
          }
          
        } else {
          setError(data.message);
        }
        setConfirmationModal(false);
      })
      .catch((error) => {
        setError("Une erreur s'est produite lors de la création de la demande.");
        console.error(error);
      });
  };

  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type }),
        });
        const data = await response.json();

        const pendingLength = data.filter(
          (item) => item.creator === sessionStorage.getItem("id")
        ).length;
        const pendingData = data.filter(
          (item) => item.creator === sessionStorage.getItem("id")
        );

        switch (type) {
          case "under_pro":
            setTechs(data.reverse());
            setPending(pendingData);
            console.log("Pending data: " + pending);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData("under_pro"); // Call fetchData with the desired type
  }, []);

  //Update Status after RIB
  const handleClickUpdatestatus = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("rid", id);
      formData.append("Paiement", "Paiement effectué");

      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        // Only call handleResult if the response status is 200
        await handleResult();
      }

      const data = await response.json();
      //window.location.replace("/contrat");
      // Handle the response data here
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  return (
    <>
      <BreadCrumb
        From={"Compte"}
        To={"Contrat de maintenance"}
        hrefFrom={"/tableau-de-bord"}
      />
      <ReturnButton />
      <div className="mt-10 sm:mt-0 max-w-screen-xl  ml-12 ">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <div className="w-full mt-10 mb-40">
                <h3 className="text-lg font-medium mb-4  w-[90%] ml-auto mr-auto">
                  Demande d'un Contrat d’entretien
                </h3>
                <div className="w-[90%] ml-auto mr-auto mt-8 font-medium text-gray-700">
                  <p className="ml-4 mb-4">Date de début de contrat souhaité</p>
                </div>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  locale={fr}
                  minDate={minDate}
                  placeholderText={
                    countDate === 0
                      ? minDate.toLocaleDateString("fr-FR")
                      : selectedDate.toLocaleDateString("fr-FR")
                  }
                  className="w-[90%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md flex justify-between items-center px-20"
                />

                {entreprise === "non" && (
                  <div>
                    <div className="w-[90%] ml-auto mr-auto mt-8 font-medium text-gray-700">
                      <p className="ml-4 mb-4">Appareil</p>
                    </div>
                    <select
                      id="productId"
                      name="productId"
                      value={productId}
                      onChange={(e) => setProductId(e.target.value)}
                      className="w-[90%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md  flex justify-between items-center px-20"
                    >
                      <option value="">
                        sélectionner l'Id de votre produit
                      </option>
                      {pendingList.map((item) => (
                        <option
                          key={item._id}
                          value={item._id}
                          className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                        >
                          <div className="flex gap-4">
                            <div>
                              {/*<h1 className="text-red">
                                {item.produits} 
                              </h1>*/}
                            </div>
                            <div>
                              <p className="text-sm ml-2 bg-green-500 text-white px-2 rounded">
                                {item.produits} - {item.marque}
                              </p>
                            </div>
                          </div>
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {entreprise === "oui" && (
                  <>
                    <div className="w-[90%] ml-auto mr-auto mt-8 ">
                      <h2 className=" text-gray-700 ml-4 mb-4 text-[16px]">
                        Informations du client
                      </h2>
                      <div className="bg-white  py-5 sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="nom"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Nom <span className="text-red-500"> * </span>
                            </label>
                            <input
                              type="text"
                              value={nom}
                              onChange={(event) => setNom(event.target.value)}
                              name="nom"
                              id="nom"
                              required
                              autoComplete="given-name"
                              className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="prenom"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Prénom <span className="text-red-500"> * </span>
                            </label>
                            <input
                              type="text"
                              name="prenom"
                              value={prenom}
                              onChange={(event) =>
                                setPrenom(event.target.value)
                              }
                              required
                              id="prenom"
                              autoComplete="prenom"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="telephone"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Téléphone:{" "}
                              <span className="text-red-500"> * </span>
                            </label>
                            <input
                              type="text"
                              name="telephone"
                              value={telephone}
                              onChange={handleTelephoneChange}
                              required
                              id="telephone"
                              autoComplete="telephone"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                            {"telephoneError" && (
                              <div className="text-red-500">
                                {telephoneError}
                              </div>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email: <span className="text-red-500"> * </span>
                            </label>
                            <input
                              type="text"
                              name="email"
                              value={email}
                              onChange={handleEmailChange}
                              required
                              id="email"
                              autoComplete="telephone"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                            {"emailError" && (
                              <div className="text-red-500">{emailError}</div>
                            )}
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="adresse"
                              className="block text-sm font-medium text-gray-700"
                            >
                              N° et Nom de rue{" "}
                              <span className="text-red-500"> * </span>
                            </label>
                            <Autocomplete
                              className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                              apiKey="AIzaSyDK4L8SN7bnmjDstRZxqkqitqyQufbopLs"
                              name="adresse"
                              id="adresse"
                              options={{
                                types: ["geocode", "establishment"],
                              }}
                              onPlaceSelected={(place) => {
                                console.log(place);
                                const address = place.formatted_address;
                                console.log(
                                  "Formatted:",
                                  place.formatted_address
                                );
                                console.log("address:", address);
                                console.log("Selected address:", place);

                                // Find the postal code and city from address components
                                const postalCode =
                                  place.address_components.find((component) =>
                                    component.types.includes("postal_code")
                                  )?.long_name || "";

                                const city =
                                  place.address_components.find((component) =>
                                    component.types.includes("locality")
                                  )?.long_name || "";

                                const placeIds = place.place_id;

                                // Update the state variables
                                setPostale(postalCode);
                                setVille(city);
                                setAdresse(address);
                                setPlaceId(placeIds);
                              }}
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="ville"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Ville <span className="text-red-500"> * </span>
                            </label>
                            <input
                              type="text"
                              value={ville}
                              onChange={(event) => setVille(event.target.value)}
                              name="ville"
                              required
                              id="ville"
                              autoComplete="ville"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="postale"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Code Postale:{" "}
                              <span className="text-red-500"> * </span>
                            </label>
                            <input
                              type="text"
                              name="postale"
                              value={postale}
                              onChange={handlePostaleChange}
                              required
                              id="postale"
                              autoComplete="postale"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                            {postaleError && (
                              <p style={{ color: "red" }}>{postaleError}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-[90%] ml-auto mr-auto mt-8 ">
                      <h2 className="text-gray-700 ml-4 mb-4 text-[16px]">
                        Informations de l'appareil
                      </h2>

                      <div className="bg-white px-4 py-5 sm:p-6">
                        <div className="mb-4">
                          <h2 className="text-sm font-medium text-gray-700 mb-2 px-4">
                            Type d'appareil
                          </h2>
                          <div className="flex flex-wrap">
                            {Domaines.map((domaine) => (
                              <div
                                className="rounded-md cursor-pointer w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 px-4 py-2"
                                key={domaine.id}
                              >
                                <div
                                  className="flex"
                                  onClick={() =>
                                    handleTypeSelection(domaine.nom, null)
                                  }
                                >
                                  <div className="flex">
                                    {domaine.icon}
                                    {domaine.nom}
                                  </div>
                                </div>
                                {domaine.hasType &&
                                  selectedDomain === domaine.nom &&
                                  domaine.type.map((type) => (
                                    <div key={type} className="p-2">
                                      <label>
                                        <input
                                          type="radio"
                                          checked={selectedSubdomain === type}
                                          onChange={() =>
                                            handleTypeSelection(
                                              domaine.nom,
                                              type
                                            )
                                          }
                                        />
                                        {type}
                                      </label>
                                    </div>
                                  ))}
                                {!domaine.hasType &&
                                  selectedDomain === domaine.nom && (
                                    <div>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={
                                            selectedSubdomain === domaine.nom
                                          }
                                          onChange={() =>
                                            handleTypeSelection(
                                              domaine.nom,
                                              domaine.nom
                                            )
                                          }
                                        />
                                        {domaine.nom}
                                      </label>
                                    </div>
                                  )}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-6 mb-4">
                          <div className="col-span-6 sm:col-span-3">
                            <div>
                              <label
                                htmlFor="marque"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Marque du produit{" "}
                                <span className="text-red-500"> * </span>
                              </label>
                              <select
                                id="marque"
                                name="marque"
                                autoComplete="marque"
                                value={prod_marque}
                                onChange={(event) =>
                                  setMarque(event.target.value)
                                }
                                required
                                className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                              >
                                <option value="">
                                  Sélectionner une marque
                                </option>
                                {Marque.map((marqueOption) => (
                                  <option
                                    key={marqueOption}
                                    value={marqueOption}
                                  >
                                    {marqueOption}
                                  </option>
                                ))}
                                <option value="autre">Autre</option>
                              </select>
                              {prod_marque === "autre" && (
                                <div>
                                  <label
                                    htmlFor="autre-marque"
                                    className="margin-top"
                                  >
                                    Autre marque{" "}
                                    <span className="text-red-500"> * </span>
                                  </label>
                                  <input
                                    id="autre-marque"
                                    name="autre-marque"
                                    type="text"
                                    autoComplete="off"
                                    value={prod_autreMarque}
                                    onChange={handleAutreMarque}
                                    required
                                    style={{ marginTop: "16px" }}
                                    className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Marque du produit"
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="numeroSerie"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Numéro de série
                            </label>
                            <input
                              type="text"
                              name="numeroSerie"
                              value={prod_numeroSerie}
                              onChange={(event) =>
                                setNumeroSerie(event.target.value)
                              }
                              required
                              id="numeroSerie"
                              autoComplete="numeroSerie"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="puissance"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Puissance:{" "}
                              <span className="text-red-500"> * </span>
                            </label>
                            <input
                              type="text"
                              name="puissance"
                              value={prod_puissance}
                              onChange={(event) =>
                                setPuissance(event.target.value)
                              }
                              required
                              id="puissance"
                              autoComplete="puissance"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="reference"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Reference :
                            </label>
                            <input
                              type="text"
                              name="reference"
                              value={prod_reference}
                              onChange={(event) =>
                                setReference(event.target.value)
                              }
                              required
                              id="reference"
                              autoComplete="reference"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="quantite"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Quantité:
                            </label>
                            <input
                              type="number"
                              name="quantite"
                              value={prod_quantite}
                              onChange={(event) =>
                                setQuantite(event.target.value)
                              }
                              required
                              id="quantite"
                              autoComplete="quantite"
                              className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            />
                          </div>
                          <div className="flex justify-between w-full col-span-6 sm:col-span-3 gap-1 lg:gap-2">
                            <div className="w-1/2">
                              {" "}
                              {/* Add the w-1/2 class here */}
                              <label
                                htmlFor="moisInstallation"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Mois d'installation:{" "}
                              </label>
                              <select
                                id="moisInstallation"
                                name="moisInstallation"
                                value={prod_moisInstallation}
                                onChange={(e) =>
                                  setMoisInstallation(e.target.value)
                                }
                                className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                              >
                                {Mois.map((mois) => (
                                  <option key={mois} value={mois}>
                                    {mois}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="w-1/2">
                              {" "}
                              {/* Add the w-1/2 class here */}
                              <label
                                htmlFor="dateInstallation"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Année d'installation:{" "}
                              </label>
                              <select
                                id="annéeInstallation"
                                name="annéeInstallation"
                                value={prod_dateInstallation}
                                onChange={(e) =>
                                  setDateInstallation(e.target.value)
                                }
                                className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                              >
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                  /*<>
                    <div>
                      <div className="w-[90%] ml-auto mr-auto mt-8 font-medium text-gray-700">
                        <p className="ml-4 mb-4">Client</p>
                      </div>

                      <div className="w-[90%] ml-auto mr-auto mt-8 font-medium text-gray-700 mb-2">
                        <legend className="contents text-sm font-medium text-gray-600 mt-4">
                          Y a-t-il déjà un projet ajouté avec le client ?
                          <span className="text-red-500"> * </span>
                        </legend>
                        <div className="mt-2 gap-4 flex">
                          <div className="flex items-center">
                            <input
                              id="oui"
                              name="entretenu"
                              type="radio"
                              value="oui"
                              onChange={(e) => setMaintenance(e.target.value)}
                              checked={maintenance === "oui"}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="oui"
                              className="ml-3 block text-sm font-medium text-gray-700"
                            >
                              Oui
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="non"
                              name="entretenu"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              value="non"
                              onChange={(e) => setMaintenance(e.target.value)}
                              checked={maintenance === "non"}
                            />
                            <label
                              htmlFor="non"
                              className="ml-3 block text-sm font-medium text-gray-700"
                            >
                              Non
                            </label>
                          </div>
                        </div>
                      </div>

                      {maintenance === "oui" && (
                        <>
                          <select
                            id="client"
                            name="client"
                            value={client}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              const [
                                clientName,
                                clientPrenom,
                                requestID,
                                ProductID,
                                ClientID,
                              ] = selectedValue.split("|");
                              setClient(clientName);
                              setPrenom(clientPrenom);
                              setRequestID(requestID);
                              setProductID(ProductID);
                              setClientID(ClientID);
                              setContractType("request");
                            }}
                            className="w-[90%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md  flex justify-between items-center px-20"
                          >
                            <option value="">
                              {client
                                ? `${client} ${prenom}`
                                : "sélectionner Votre Client"}
                            </option>
                            {history.map((item) => (
                              <option
                                key={item.nom}
                                value={`${item.nom}|${item.prenom}|${item._id}|${item.created_for}|${item.created_by}`}
                                className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                              >
                                <div className="flex gap-4">
                                  <div>
                                    <p className="text-sm ml-2 bg-green-500 text-white px-2 rounded">
                                      {item.nom} - {item.prenom}
                                    </p>
                                  </div>
                                </div>
                              </option>
                            ))}
                          </select>
                          <div>
                            <div className="w-[90%] ml-auto mr-auto mt-8 font-medium text-gray-700">
                              <p className="ml-4 mb-4">Appareil</p>
                            </div>
                            <select
                              id="productId"
                              name="productId"
                              value={productId}
                              onChange={(e) => setProductId(e.target.value)}
                              className="w-[90%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md  flex justify-between items-center px-20"
                            >
                              <option value="">
                                sélectionner Votre appareil
                              </option>
                              {history
                                .filter(
                                  (item) =>
                                    item.nom === client &&
                                    item.prenom === prenom
                                )
                                .map((item) => (
                                  <option
                                    key={item.marque}
                                    value={item.marque}
                                    className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                                  >
                                    <div className="flex gap-4">
                                      <div>
                                      </div>
                                      <div>
                                        <p className="text-sm ml-2 bg-green-500 text-white px-2 rounded">
                                          {item.marque}
                                        </p>
                                      </div>
                                    </div>
                                  </option>
                                ))}
                            </select>
                          </div>
                        </>
                      )}
                      {maintenance === "non" && (
                        <>
                          <select
                            id="client"
                            name="client"
                            value={client}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              const [
                                clientName,
                                clientPrenom,
                                requestID,
                                ProductID,
                              ] = selectedValue.split("|");
                              setClient(clientName);
                              setPrenom(clientPrenom);
                              setRequestID(requestID);
                              setProductID(ProductID);
                              setContractType("user");
                            }}
                            className="w-[90%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md  flex justify-between items-center px-20"
                          >
                            <option value="">
                              {client
                                ? `${client} ${prenom}`
                                : "sélectionner Votre Client"}
                            </option>
                            {pending.map((item) => (
                              <option
                                key={item.nom}
                                value={`${item.nom}|${item.prenom}|${item._id}|${item.created_for}`}
                                className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                              >
                                <div className="flex gap-4">
                                  <div>
                                  </div>
                                  <div>
                                    <p className="text-sm ml-2 bg-green-500 text-white px-2 rounded">
                                      {item.nom} - {item.prenom}
                                    </p>
                                  </div>
                                </div>
                              </option>
                            ))}
                          </select>
                          <div>
                            <div className="w-[90%] ml-auto mr-auto mt-8 font-medium text-gray-700">
                              <p className="ml-4 mb-4">Appareil</p>
                            </div>
                            <select
                              id="productId"
                              name="productId"
                              value={productId}
                              onChange={(e) => {
                                setProductId(e.target.value);
                                setProductID(e.target.value);
                              }}
                              className="w-[90%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md  flex justify-between items-center px-20"
                            >
                              <option value="">
                                Sélectionner votre appareil
                              </option>
                              {productFetched.length > 0 && (
                                <>
                                  {productFetched.map((item) => (
                                    <option
                                      key={item._id}
                                      value={item._id} 
                                      className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                                    >
                                      <div className="flex gap-4">
                                        <div></div>
                                        <div>
                                          <p className="text-sm ml-2 bg-green-500 text-white px-2 rounded">
                                            {item.marque}
                                          </p>
                                        </div>
                                      </div>
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </>
                      )}
                    </div>
                  </>*/
                )}

                {errorMessage && (
                  <div className="mt-4 ml-[4rem]" style={{ color: "red" }}>
                    {errorMessage}
                  </div>
                )}
                                {error && (
                  <div className="mt-4 ml-[4rem]" style={{ color: "red" }}>
                    {error}
                  </div>
                )}

                <div className="mt-4 flex justify-end items-center mr-[60px] gap-5">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={handleRecaptcha}
                  />
                  <button
                    onClick={handleSubmit}
                    className="submit-button px-3 py-2 text-lg rounded-md w-[20%] text-white bg-blue-500"
                  >
                    Envoyé
                  </button>
                </div>
              </div>

              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {confirmationModal ? (
          <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3">
                    <div className="mt-2 text-center text-center">
                      <h4 className="text-lg font-medium text-gray-800">
                        Votre demande est en cours de traitement
                      </h4>
                      {/*<div className="flex justify-center">
                        <Link to="/tableau-de-bord">
                          <button className=" mt-4 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2">
                            Mon compte
                          </button>
                        </Link>
                      </div>*/}
                      <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                        Votre demande est en cours de traitement. Nous mettons
                        tout en œuvre pour y répondre rapidement. Nous vous
                        remercions de votre patience et nous nous excusons pour
                        tout éventuel retard. Pour toute question, n'hésitez pas
                        à nous contacter.
                      </p>
                      <div className="items-center gap-2 mt-3 sm:flex"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {ribModal ? (
          <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3">
                    <div className="mt-2 ">
                      <h4 className="text-lg font-medium text-gray-800 text-center">
                        Confirmation de RIB
                      </h4>
                      {/*<div className="flex justify-center">
                        <Link to="/tableau-de-bord">
                          <button className=" mt-4 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2">
                            Mon compte
                          </button>
                        </Link>
                      </div>*/}
                      <p className="mt-2 text-[15px] leading-relaxed text-black text-start">
                        Pour valider votre contrat d'entretien, veuillez
                        renseigner votre Relevé d'Identité Bancaire (RIB).
                        <span className="text-red-500 font-[800]">
                          {" "}
                          Il est essentiel d'appuyer deux fois sur 'Confirmer le
                          RIB'{" "}
                        </span>
                        afin de confirmer vos informations bancaires. Ensuite,
                        cliquez sur 'Valider' pour compléter votre demande.
                      </p>
                      <div className="items-center gap-2 mt-3 sm:flex"></div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="iban"
                          className="block text-sm font-medium text-gray-700"
                        >
                          IBAN: <span className="text-red-500"> * </span>
                          <IbanElement
                            name="iban"
                            required
                            className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                            options={IBAN_ELEMENT_OPTIONS}
                          />
                        </label>
                      </div>
                      <div>
                        <div className="mandate-acceptance  mb-4 text-[12px] mt-4 text-start">
                          <span className="text-red-500"> * </span> En
                          fournissant vos informations de paiement et en
                          confirmant ce paiement, vous autorisez (A) Rocket
                          Rides et Stripe, notre prestataire de services de
                          paiement et/ou PPRO, son prestataire de services
                          local, à envoyer des instructions à votre banque pour
                          débiter votre compte et (B) votre banque à débiter
                          votre compte conformément à ces instructions. Vous
                          avez, entre autres, le droit de vous faire rembourser
                          par votre banque selon les modalités et conditions du
                          contrat conclu avec votre banque. La demande de
                          remboursement doit être soumise dans un délai de 8
                          semaines à compter de la date à laquelle votre compte
                          a été débité. Vos droits sont expliqués dans une
                          déclaration disponible auprès de votre banque. Vous
                          acceptez de recevoir des notifications des débits à
                          venir dans les 2 jours précédant leur réalisation.
                        </div>
                        <div className="flex justify-start gap-4">
                          {(setup === "" || setup === "error") && (
                            <>
                              <button
                                onClick={handleSubmitStripe}
                                className=" userProfile-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Confirmer votre IBAN
                              </button>
                            </>
                          )}
                          {setup === "Working" && (
                            <>
                              <button className=" bg-gray-100 inline-flex justify-center rounded-md border  ml-2 border-transparent  py-2 px-4 text-sm font-medium text-black pointer-events-none shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                Confirmer votre IBAN
                              </button>
                            </>
                          )}

                          {setup === "Working" && (
                            <button
                              onClick={handleResult}
                              className=" userProfile-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Valider
                            </button>
                          )}
                          {(setup === "" || setup === "error") && (
                            <button className=" bg-gray-100 pointer-events-none inline-flex justify-center rounded-md border  ml-2 border-transparent  py-2 px-4 text-sm font-medium text-black shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              Valider
                            </button>
                          )}
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          {resultMessage ? (
                            resultMessage
                          ) : (
                            <span
                              className="text-[13px]"
                              style={{ color: "red" }}
                            >
                              veuillez confirmer votre IBAN
                            </span>
                          )}
                        </div>
                      </div>

                      <p className="text-[12px] mt-4 text-red-500 text-start">
                        <span className="text-red-500"> * </span> Champ
                        Obligatoire{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default NewContract;

const DirectDebit = () => {
  return (
    <Elements stripe={stripePromise}>
      <NewContract />
    </Elements>
  );
};