import React from "react";
import { Banner, CardSystem, FormationComponent } from "../../Components";
import { Helmet } from "react-helmet";
import partenariat_deditriche from "../../Assets/media/partenariat_banner_lg.png";
import imgSrc1 from "../../Assets/media/partenariat_lg_formation_PAC.png";
import imgSrc2 from "../../Assets/media/partenariat_lg_formation_PAC2.png";
import { Formation_Data, Formation_Data_LG } from "../../Constants/dummy";
import LeBonTechnicienLG from "../../Components/LandingPage/FormationComponent";

const LG = () => {
  return (
    <>
      <Helmet>
        <title>Formations LG Academy - LeBonTechnicien.fr</title>
        <meta name="keywords" content="Formations LG Academy" />
        <meta
          name="description"
          content="Nous sommes fiers d'annoncer un partenariat solide avec LG Academy. Cette collaboration apporte des formations spécialisées aux techniciens inscrits sur LeBontechnicien.fr"
        />
        <link rel="canonical" href="https://lebontechnicien.net/" />
      </Helmet>

      <div style={{ marginBottom: "20px" }}>
        <Banner style={{ marginBottom: "20px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
                <div className="banner-info">
                  <h1 className="leBonTech-h1 item-main-title">
                    Formations LG Academy : Renforcez votre Expertise
                  </h1>
                  <p className="leBonTech-p">
                    Nous sommes fiers d'annoncer un partenariat solide avec LG
                    Academy. Des formations spécialisées aux techniciens
                    reconnus pour leur expertise en installation et réparation
                    d'équipements d'énergie renouvelable. Nous vous invitons à
                    vous inscrire à ces formations pour approfondir vos
                    compétences. Profitez de ces formations uniques pour rester à la pointe des
                    évolutions technologiques et continuer à proposer des
                    services de qualité supérieure.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 order-xl-2 d-flex align-items-center">
                <div className="item-figure">
                  <img src={partenariat_deditriche} alt="partenariat_LG" />
                </div>
              </div>
            </div>
          </div>
        </Banner>
      </div>

      <div style={{ height: "50px" }}></div>

      <LeBonTechnicienLG
        formationData={Formation_Data_LG.slice(0, 1)}
        imgSrc={imgSrc1}
      />

      <LeBonTechnicienLG
        formationData={Formation_Data_LG.slice(1)}
        imgSrc={imgSrc2}
      />
    </>
  );
};

export default LG;
