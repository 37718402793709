import React, { useEffect, useState } from "react";
import "../../Styles/Slider.css";
import { FiCheckCircle, FiUser, FiStar } from "react-icons/fi";
import appstore from "../../Assets/app-store.png";
import googleplay from "../../Assets/google-play.png";
import UserAppDownload from "../../Constants/UserAppDownload";
import telechargerActive from "../../Assets/telecharger-icon.png";
import telecharger from "../../Assets/telecharger-icon-incactive.png";
import phoneActive from "../../Assets/phone-icon.png";
import phone from "../../Assets/phone-icon-inactive.png";
import diamondActive from "../../Assets/diamond-icon.png";
import diamond from "../../Assets/diamond-icon-inactive.png";
import DownloadButtons from "./DownloadButtons";
import phoneMoc from "../../Assets/Phone-moc.png";

const UserSlider = () => {
  const [currentImage, setCurrentImage] = useState(0);

  /*const handleImageChange = (direction) => {
    let newIndex = currentImage;
    if (direction === "prev") {
      newIndex = (currentImage - 1 + images.length) % images.length;
    } else if (direction === "next") {
      newIndex = (currentImage + 1) % images.length;
    }
    setCurrentImage(newIndex);
  };*/

  useEffect(() => {
    // Use setInterval to change the image every 5 seconds (5000 milliseconds)
    const intervalId = setInterval(() => {
      // Calculate the next image index
      const nextImage = (currentImage + 1) % UserAppDownload.length;
      setCurrentImage(nextImage);
    }, 3000); // 5000 milliseconds = 5 seconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [currentImage]);

  return (
    <div className="big-container container">
      <div className="how-app-work-slider-wrapper">
        <div
          className="carousel-container"
          style={{
            backgroundImage: `url(${phoneMoc})`,
          }}
        />
        <div className="image-container-slider">
          {UserAppDownload.map((content, index) => (
            <div
              key={index}
              className={`carousel-image ${
                index === currentImage ? "active" : ""
              }`}
              style={{
                backgroundImage: `url(${content.image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          ))}
        </div>
      </div>
      <div className="button-container">
        <div className="buttons-div">
          {UserAppDownload.map((_, index) => (
            <div
              style={{ display: "flex", gap: "15px", alignItems: "flex-end" }}
            >
              <div
                className={`circle-icon ${
                  currentImage === index ? "active" : ""
                }`}
                key={index}
                onClick={() => setCurrentImage(index)}
              >
                {index === 0 && (
                  <img
                    src={
                      currentImage === index ? telechargerActive : telecharger
                    }
                    style={{ width: "30px", height: "30px" }}
                    alt="Telecharger"
                  />
                )}
                {index === 1 && (
                  <img
                    src={currentImage === index ? phoneActive : phone}
                    style={{ width: "30px", height: "30px" }}
                    alt="Phone"
                  />
                )}
                {index === 2 && (
                  <img
                    src={currentImage === index ? diamondActive : diamond}
                    style={{ width: "30px", height: "30px" }}
                    alt="Diamond"
                  />
                )}
              </div>
              <div className="info-text-div">
                <div className="info-text">
                  <h2>{UserAppDownload[index].title}</h2>
                  <p>{UserAppDownload[index].text}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="button-container">
            <DownloadButtons
              appStoreLink="https://apps.apple.com/fr/app/lebontech/id6450009896 "
              googlePlayLink="https://play.google.com/store/apps/details?id=com.devinov.LeBonTechnicienUser"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSlider;
