import React, { useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";

const NotificationTable = () => {
  const [logs, setLogs] = useState([]);
  const apiUrl = 'https://lebontechnicien.net'

  useEffect(() => {
    fetch(`${apiUrl}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
    })
      .then((response) => response.json())
      .then((data) => {
        const logs = [];
        console.log("data.log", data.log);
        for (const date in data.log) {
          for (const time in data.log[date]) {
            logs.push({
              date,
              time,
              message: data.log[date][time],
            });
          }
        }
        
        logs.sort((a, b) => {
          const dateA = new Date(
            `${a.date.split('/').reverse().join('-')}T${a.time}`
          );
          const dateB = new Date(
            `${b.date.split('/').reverse().join('-')}T${b.time}`
          );
          return dateB - dateA; // Sort in descending order to show the latest notification first
        });
        setLogs(logs);
        console.log(logs.length);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="flex-1 bg-[#f8f8f8] rounded-lg  mt-4 p-8 overflow-y-auto h-[400px]">
      <h4 className="text-xl text-gray-900 font-bold">Notifications</h4>
      {logs.length === 0 ? (
        <p>
          Actuellement, aucune notification n'a été trouvée. Veuillez revenir
          plus tard.
        </p>
      ) : (
        <div className="px-4">
          <div className="h-full border border-dashed border-opacity-20 border-secondary"></div>
          {logs.map((log, index) => (
            <div
              className="z-10 flex items-center w-full my-6 -ml-1.5 gap-2"
              key={index}
            >
              <div className="w-1/12 ">
                <div className="w-3.5 h-3.5 bg-green-600 rounded-full"></div>
              </div>
              <div className="w-11/12">
                <p className="text-sm">{log.message}</p>
                <p className="text-xs text-gray-500">
                  {log.date} at {log.time}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationTable;
