import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import {
  CharteQualite,
  ConfirmLayout,
  DashboardLayout,
  NavbarLayout,
  NoNavbarLayout,
} from "./Components";

import {
  AboutPage,
  AccountConfirmation,
  AccountValidation,
  AddUser,
  AdminContractDetails,
  AdminContractList,
  AdminMailing,
  AdminMailingText,
  AdminNewDash,
  AdminNotification,
  Cgu,
  Cgv,
  Compte,
  Contact,
  ContractList,
  Dedietrich,
  DocumentationPage,
  Download,
  GestionCookies,
  Guides,
  HomePage,
  HowItWorks,
  InterventionDetail,
  InterventionDetailPro,
  LG,
  Litige,
  NewClientPro,
  NewContract,
  NewProject,
  NewProjectPro,
  PolitiqueConfidentialite,
  Reclamation,
  SearchGrid,
  SuperUser,
  TechnicianDash,
  TechnicianProfile,
  TechnicianProjects,
  TechnicianRegister,
  UserClaims,
  UserDash,
  UserDetail,
  UserHistory,
  UserInfo,
  UserListPro,
  UserLogin,
  UserNewDevice,
  UserNewProductDetail,
  UserNotification,
  UserPending,
  UserProfile,
  UserProjectDetails,
  UserProProductList,
  UserProRequestPerProduct,
  UserRecovery,
  UserRedirectLMsg,
  UserRegister,
  UsersList,
  UserUpdatePassword,
} from "./Pages";
import "./Styles/LandingPage.css";
import "./Styles/Documentation.css";

import "./Styles/Authentification.css";
import "./Styles/Dashboard.css";
import "./App.css";
import "react-tooltip/dist/react-tooltip.css";
import LoginPick from "./Pages/Authentification/LoginPick";
import AdminRegister from "./Pages/Authentification/Admin/AdminRegister";
import UserClaimsDetail from "./Pages/Dashboard/User/UserClaimsDetail";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStateContext } from "./contexts/ContextProvider";
import UserDevis from "./Pages/Dashboard/User/UserDevis";
import UserFactures from "./Pages/Dashboard/User/UserFactures";
import ContractDetails from "./Pages/Dashboard/User/ContractDetails";
import DocumentationPagePro from "./Pages/LandingPage/DocumentationPagePro";
import TechCompte from "./Pages/Dashboard/User/TechCompte";
import TechProTeam from "./Pages/Dashboard/User/TechProTeam";
import TechProTeamDetails from "./Pages/Dashboard/User/TechProTeamDetails";
import planningDetails from "./Pages/Dashboard/User/planningDetails";
import TechProPlanningDetail from "./Pages/Dashboard/User/TechProPlanningDetail";
import TechProProjectSearch from "./Pages/Dashboard/User/TechProProjectSearch";
import CompteTech from "./Pages/Dashboard/User/CompteTech";
import TechTeamProCompte from "./Pages/Dashboard/User/TechTeamProCompte";
import ProTeamPlannings from "./Pages/Dashboard/User/ProTeamPlannings";

const stripePromise = loadStripe(
  "pk_test_51MYsX7DRnu0Qq0e6QFDnOYawy4AdzClCF3fyFSth8OoNK6NmqjwNvGaVoByL3WnIsFhNWqHRuf642GSu2EOXmbAn00gJGRZd8N"
);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenChecked, setTokenChecked] = useState(false);
  const [profile, setProfile] = useState(false);
  //const { profile } = useStateContext();
  //const profile = sessionStorage.getItem("profile");
  console.log("Profile", profile);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const profile = sessionStorage.getItem("profile");
    setIsLoggedIn(token ? true : false);
    setTokenChecked(true);
    setProfile(profile);
  }, []);

  return (
    <Router>
      {tokenChecked ? (
        <Routes>
          <Route element={<NavbarLayout />}>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/activites" exact element={<AboutPage />} />
            <Route path="/comment-ca-marche" exact element={<HowItWorks />} />
            <Route path="/contact" exact element={<Contact />} />
            <Route
              path="/dedietrich-thermique"
              exact
              element={<Dedietrich />}
            />
            <Route path="/lg" exact element={<LG />} />
            <Route
              path="/guides/client"
              exact
              element={<DocumentationPage />}
            />
            <Route
              path="/guides/client-pro"
              exact
              element={<DocumentationPagePro />}
            />
            <Route path="/telechargement" exact element={<Download />} />
            <Route path="/guides" exact element={<Guides />} />
            <Route
              path="/politique-confidentialite"
              exact
              element={<PolitiqueConfidentialite />}
            />
            <Route path="/charte-qualite" exact element={<CharteQualite />} />
            <Route path="/cgv" exact element={<Cgv />} />
            <Route path="/cgu" exact element={<Cgu />} />
            <Route path="/gestion-cookies" exact element={<GestionCookies />} />
          </Route>
          <Route element={<NoNavbarLayout />}>
            <Route path="/inscription" exact element={<LoginPick />} />
            <Route path="/connexion" exact element={<UserLogin />} />
            <Route path="/récupérer" exact element={<UserRecovery />} />
            <Route
              path="/inscription-client"
              exact
              element={<UserRegister />}
            />
            <Route
              path="/inscription-technicien"
              exact
              element={<TechnicianRegister />}
            />
            <Route path="/register-admin" exact element={<AdminRegister />} />
          </Route>
          <Route element={<ConfirmLayout />}>
            <Route
              path="/confirmation"
              exact
              element={<AccountConfirmation />}
            />
            <Route path="/nonconnecte" exact element={<UserRedirectLMsg />} />
            <Route
              path="/validation/:id/:confirm"
              exact
              element={<AccountValidation />}
            />
            <Route
              path="/recuperation-mdp/:id/:conf"
              exact
              element={<UserUpdatePassword />}
            />
          </Route>
          {isLoggedIn === true ? (
            <>
              <>
                <Route element={<ConfirmLayout />}>
                  <Route path="/encours" exact element={<UserPending />} />
                  <Route
                    path="/information"
                    exact
                    element={
                      <Elements stripe={stripePromise}>
                        <UserInfo />
                      </Elements>
                    }
                  />
                </Route>

                <Route element={<DashboardLayout />}>
                  {profile === "Finished" ? (
                    <Route
                      path="/mes-appareils"
                      exact
                      element={<UserNewDevice />}
                    />
                  ) : (
                    <Route
                      path="/mes-appareils"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route path="/devis" exact element={<UserDevis />} />
                  ) : (
                    <Route
                      path="/devis"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route path="/factures" exact element={<UserFactures />} />
                  ) : (
                    <Route
                      path="/factures"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route path="/new-project" exact element={<NewProject />} />
                  ) : (
                    <Route
                      path="/new-project"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/nouveau-projet-pro"
                      exact
                      element={<NewProjectPro />}
                    />
                  ) : (
                    <Route
                      path="/nouveau-projet-pro"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/client-appareils/:id"
                      exact
                      element={<UserProProductList />}
                    />
                  ) : (
                    <Route
                      path="/client-appareils/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/client-appareils-historique/:id"
                      exact
                      element={<UserProRequestPerProduct />}
                    />
                  ) : (
                    <Route
                      path="/client-appareils-historique/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/nouveau-client-pro"
                      exact
                      element={<NewClientPro />}
                    />
                  ) : (
                    <Route
                      path="/nouveau-client-pro"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/demande-contrat"
                      exact
                      element={
                        <Elements stripe={stripePromise}>
                          <NewContract />
                        </Elements>
                      }
                    />
                  ) : (
                    <Route
                      path="/demande-contrat"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/tableau-de-bord-superviseur"
                      exact
                      element={<SuperUser />}
                    />
                  ) : (
                    <Route
                      path="/tableau-de-bord-superviseur"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/tableau-de-bord-admin"
                      exact
                      element={<AdminNewDash />}
                    />
                  ) : (
                    <Route
                      path="/tableau-de-bord-admin"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/tableau-de-bord-tech"
                      exact
                      element={<CompteTech />}
                    />
                  ) : (
                    <Route
                      path="/tableau-de-bord-tech"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route path="/contrat" exact element={<ContractList />} />
                  ) : (
                    <Route
                      path="/contrat"
                      element={<Navigate to="/information" replace />}
                    />
                  )}

                  {profile === "Finished" ? (
                    <Route
                      path="/contrats"
                      exact
                      element={<AdminContractList />}
                    />
                  ) : (
                    <Route
                      path="/contrats"
                      element={<Navigate to="/information" replace />}
                    />
                  )}

                  {profile === "Finished" ? (
                    <Route
                      path="/contrat/:id"
                      exact
                      element={<ContractDetails />}
                    />
                  ) : (
                    <Route
                      path="/contrat/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}

                  {profile === "Finished" ? (
                    <Route
                      path="/contrats/:id"
                      exact
                      element={<AdminContractDetails />}
                    />
                  ) : (
                    <Route
                      path="/contrats/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/tableau-de-bord-technicien"
                      exact
                      element={<UserDash />}
                    />
                  ) : (
                    <Route
                      path="/tableau-de-bord-technicien"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route path="/mon-profil" exact element={<UserProfile />} />
                  ) : (
                    <Route
                      path="/mon-profil"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/user-history"
                      exact
                      element={<UserHistory />}
                    />
                  ) : (
                    <Route
                      path="/recherche-pro"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/recherche-pro"
                      exact
                      element={<TechProProjectSearch />}
                    />
                  ) : (
                    <Route
                      path="/user-history"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route path="/tableau-de-bord" exact element={<Compte />} />
                  ) : (
                    <Route
                      path="/tableau-de-bord"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/mes-equipes"
                      exact
                      element={<TechProTeam />}
                    />
                  ) : (
                    <Route
                      path="/mes-equipes"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/planning/:id/:team"
                      exact
                      element={<TechProPlanningDetail />}
                    />
                  ) : (
                    <Route
                      path="/planning/:id/:team"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/mes-equipes/:id"
                      exact
                      element={<TechProTeamDetails />}
                    />
                  ) : (
                    <Route
                      path="/mes-equipes/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/mon-compte-tech"
                      exact
                      element={<TechCompte />}
                    />
                  ) : (
                    <Route
                      path="/mon-compte-tech"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/mon-compte-team-pro"
                      exact
                      element={<TechTeamProCompte />}
                    />
                  ) : (
                    <Route
                      path="/mon-compte-team-pro"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/pro-team-plannings"
                      exact
                      element={<ProTeamPlannings />}
                    />
                  ) : (
                    <Route
                      path="/pro-team-plannings"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/user-notification"
                      exact
                      element={<UserNotification />}
                    />
                  ) : (
                    <Route
                      path="/user-notification"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/user-projets-details/:id"
                      exact
                      element={<UserProjectDetails />}
                    />
                  ) : (
                    <Route
                      path="/user-projets-details/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/user-intervention-details/:id"
                      exact
                      element={<InterventionDetail />}
                    />
                  ) : (
                    <Route
                      path="/user-intervention-details/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/user-intervention-details-pro/:id"
                      exact
                      element={<InterventionDetailPro />}
                    />
                  ) : (
                    <Route
                      path="/user-intervention-details-pro/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/user-details/:id"
                      exact
                      element={<UserDetail />}
                    />
                  ) : (
                    <Route
                      path="/user-details/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/user-claim-details/:id"
                      exact
                      element={<UserClaimsDetail />}
                    />
                  ) : (
                    <Route
                      path="/user-claim-details/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/reclamations"
                      exact
                      element={<UserClaims />}
                    />
                  ) : (
                    <Route
                      path="/reclamations"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route path="/recherche" exact element={<SearchGrid />} />
                  ) : (
                    <Route
                      path="/recherche"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/technician-dash"
                      exact
                      element={<TechnicianDash />}
                    />
                  ) : (
                    <Route
                      path="/technician-dash"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  <Route
                    path="/technician-profile"
                    exact
                    element={<TechnicianProfile />}
                  />
                  <Route
                    path="/technician-projets"
                    exact
                    element={<TechnicianProjects />}
                  />
                  {profile === "Finished" ? (
                    <Route
                      path="/technician-history"
                      exact
                      element={<UserHistory />}
                    />
                  ) : (
                    <Route
                      path="/technician-history"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/technician-projets-details/:id"
                      exact
                      element={<UserProjectDetails />}
                    />
                  ) : (
                    <Route
                      path="/technician-history"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/product/:id"
                      exact
                      element={<UserNewProductDetail />}
                    />
                  ) : (
                    <Route
                      path="/product/:id"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/notifications"
                      exact
                      element={<AdminNotification />}
                    />
                  ) : (
                    <Route
                      path="/notifications"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/liste-de-diffusion"
                      exact
                      element={<AdminMailing />}
                    />
                  ) : (
                    <Route
                      path="/liste-de-diffusion"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/mailing-text"
                      exact
                      element={<AdminMailingText />}
                    />
                  ) : (
                    <Route
                      path="/mailing-text"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/ajouter-un-utilisateur"
                      exact
                      element={<AddUser />}
                    />
                  ) : (
                    <Route
                      path="/ajouter-un-utilisateur"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route path="/litiges" exact element={<Litige />} />
                  ) : (
                    <Route
                      path="/litiges"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/réclamations"
                      exact
                      element={<Reclamation />}
                    />
                  ) : (
                    <Route
                      path="/réclamations"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/liste-des-utilisateurs"
                      exact
                      element={<UsersList />}
                    />
                  ) : (
                    <Route
                      path="/liste-des-utilisateurs"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                  {profile === "Finished" ? (
                    <Route
                      path="/liste-clients"
                      exact
                      element={<UserListPro />}
                    />
                  ) : (
                    <Route
                      path="/liste-clients"
                      element={<Navigate to="/information" replace />}
                    />
                  )}
                </Route>
                <Route element={<NoNavbarLayout />}>
                  <Route
                    path="/inscription"
                    element={<Navigate to="/navigation" />}
                  />
                </Route>
              </>
            </>
          ) : isLoggedIn === false ? (
            <>
              <Route path="*" element={<Navigate to="/connexion" replace />} />
            </>
          ) : null}
        </Routes>
      ) : null}
    </Router>
  );
};

export default App;
