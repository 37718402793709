import React from 'react'
import { Outlet } from 'react-router';
import Navbar from '../LandingPage/Navbar';
import Header from '../LandingPage/Header';
import Footer from '../LandingPage/Footer';


const NavbarLayout = () => {
  return (
    <>   
        <Header />    
        <Outlet />
        <Footer/>
    </> 
  )
}

export default NavbarLayout