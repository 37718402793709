import React from "react";
import {
  Banner,
  CenterComponent,
  Footer,
  LeBonTechnicien2grid,
  LeBonTechnicienWorks,
} from "../../Components";
import stepone from "../../Assets/1.png";
import steptwo from "../../Assets/2.png";
import stepthree from "../../Assets/3.png";
import fonctionnement from "../../Assets/media/etapes-fonctionnement.png";
import Phone1 from "../../Assets/media/faire-demande.png";
import Phone2 from "../../Assets/media/app-tec-repond.png";
import Phone3 from "../../Assets/media/app-tec-choix.png";
import { LebonTechnicien_data, Equipements_data } from "../../Constants/dummy";
import { Helmet } from "react-helmet";

const HowItWorks = () => {
  return (
    <>
      <Helmet>
        <title>Comment ça amrche - LeBonTechnicien.fr</title>
        <meta name="keywords" content="LeBonTechnicien.fr" />
        <meta
          name="description"
          content="Découvrez LeBonTechnicien.fr, la plateforme simple et intuitive pour trouver le technicien idéal en seulement trois étapes, sans engagement et gratuitement."
        />
        <link rel="canonical" href="https://lebontechnicien.net/" />
      </Helmet>

      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">
                  Les techniciens d'élite pour tous vos besoins en équipements
                  d'énergie renouvelable
                </h1>
                <p className="leBonTech-p">
                  Découvrez LeBonTechnicien.fr, la plateforme simple et
                  intuitive pour trouver le technicien idéal en seulement trois
                  étapes, sans engagement et gratuitement. Profitez d'une
                  expérience utilisateur inégalée et d'un accès aux domaines
                  d'expertise variés de nos techniciens d'élite. Ces
                  professionnels se spécialisent notamment dans l'installation
                  et la réparation d'équipements d'énergie renouvelable, vous
                  assurant des solutions écologiques et performantes pour vos
                  besoins énergétiques. LeBonTechnicien.fr facilite votre
                  recherche de spécialistes compétents, vous faisant gagner du
                  temps et vous offrant la tranquillité d'esprit.
                </p>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img src={fonctionnement} alt="Comment ça marche" />
              </div>
            </div>
          </div>
        </div>
      </Banner>

      <LeBonTechnicienWorks
        title={LebonTechnicien_data.section3.title}
        subtitle={LebonTechnicien_data.section3.subtitle}
        paragraph={LebonTechnicien_data.section3.paragraph}
        imgSrc={Phone1}
      />

      <LeBonTechnicienWorks
        title={LebonTechnicien_data.section4.title}
        subtitle={LebonTechnicien_data.section4.subtitle}
        paragraph={LebonTechnicien_data.section4.paragraph}
        imgSrc={Phone2}
      />

      <LeBonTechnicienWorks
        title={LebonTechnicien_data.section5.title}
        subtitle={LebonTechnicien_data.section5.subtitle}
        paragraph={LebonTechnicien_data.section5.paragraph}
        imgSrc={Phone3}
      />
    </>
  );
};

export default HowItWorks;
