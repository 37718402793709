import React from 'react'

const UserRedirectLMsg = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-transparent w-[70%] ml-auto mr-auto">
      <p className="mt-4 text-lg text-gray-700 text-center">
      Afin de pouvoir visualiser la demande d’intervention vous devez être connecté.
Bouton “Se Connecter”

      </p>

      <button
        className=" mt-12 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2"
        onClick={window.location.href = "/connexion"}
      >
        Se connecter 
      </button>
    </div>
  )
}

export default UserRedirectLMsg