import React, { useEffect, useState } from "react";
import LogoBig from "../../../Assets/LogoBig.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import emailPic from '../../../Assets/Mar-Business_18.jpg'

const AccountConfirmation = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const navigate = useNavigate();
  const apiUrl = 'https://lebontechnicien.net'

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${apiUrl}/logout`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.msg);
      }
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("requests");
      setIsLoading(false);
      console.log(data.msg);
      setIsLoggedOut(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err.message);
    }
    window.location.href = "/connexion";
  };

  const email = sessionStorage.getItem('email')


  return (
    <div className="flex flex-col items-center justify-center h-screen bg-transparent w-[70%] ml-auto mr-auto">
      {/*<div className="flex justify-between items-center">
        <Link
          to="/"
          className="items-center gap-4 ml-3 mt-4 tracking-tight"
        >
          <img
            className="mx-auto h-14 w-auto mb-[20px]"
            src={LogoBig}
            alt="Logo"
          />
        </Link>
  </div>*/}
      <img className="w-full lg:w-1/2 mb-4" src={emailPic} alt="email confirm" />
      <h2 className="text-3xl font-medium text-gray-700 text-center">Confirmation de votre e-mail</h2>
      <p className="mt-4 text-lg text-gray-700 text-center">
        Nous avons envoyé un e-mail à {email} pour
        confirmer sa validité. Veuillez suivre le lien fourni dans l'e-mail pour
        valider votre inscription.
      </p>

      <button
        className=" mt-12 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2"
        onClick={handleLogout}
      >
        Se connecter 
      </button>
    </div>
  );
};

export default AccountConfirmation;
