import React, { useEffect, useState } from "react";
import { CRUDTable, Detail } from "../../../Components";
import { useParams } from "react-router";
import { AiOutlinePaperClip } from "react-icons/ai";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  IoEyeOutline,
  IoPencil,
  IoTrashOutline,
  IoAddCircleOutline,
} from "react-icons/io5";
import { Domaines, Marque } from "../../../Constants/dummy";
import ReCAPTCHA from "react-google-recaptcha";
import {
  validatePostalCodeInput,
  validateTelephoneInput,
} from "../../../Components/FormValidation";

//TODO: change Date like the one in UserClaimsDetail

const UserDetail = () => {
  const [product, setProduct] = useState("");
  const [productTwo, setProductTwo] = useState("");
  const [history, setHistory] = useState([]);
  const { userType } = useStateContext();

  const [produits, setProduits] = useState("");
  const [annéeInstallation, setAnnéeInstallation] = useState("");
  const [marque, setMarque] = useState("");
  const [référence, setRéférence] = useState("");
  const [photoDuProduit, setPhotoDuProduit] = useState(null);
  const [entretenu, setEntretenu] = useState("");
  const [moisInstallation, setMoisInstallation] = useState("");
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [adresseIntervention, setAdresseIntervention] = useState("");
  const [postaleError, setPostaleError] = useState("");
  const [adresse, setAdresse] = useState("");
  const [postale, setPostale] = useState("");
  const [ville, setVille] = useState("");
  const [errors, setErrors] = useState([]);
  const [puissance, setPuissance] = useState("");
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const email = sessionStorage.getItem("email");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [autreMarque, setAutreMarque] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleAutreMarque = (event) => {
    setAutreMarque(event.target.value);
  };

  const apiUrl = 'https://lebontechnicien.net'

  const handleAddMorePictures = (event) => {
    event.preventDefault();
    const newFiles = document.getElementById("photoDuProduit").files;
    const existingFiles = photoDuProduit ? Array.from(photoDuProduit) : [];
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
  };

    //Fetch request to delete a product
    const handleDeleteProduct = async (id) => {
      try {
        const email = sessionStorage.getItem("email");
        const res = await fetch(`${apiUrl}/prods/remove_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id, Product }),
        });
  
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }
  
        const data = await res.json();
        setPendingList(pendingList.filter((item) => item.id !== id));
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      window.location.reload();
    };

  const handleConfirmDelete = () => {
    handleDeleteProduct(selectedId);
    //handleDeleteProduct(selectedId)
    setShowModal(false);
    setSelectedId(null);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  // Handles Deelete
  const handleDeleteClick = (id) => {
    setSelectedId(id);
    console.log("deleteselected", selectedId);
    setShowModal(true);
  };

  const Mois = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const handleTypeSelection = (type) => {
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const handlePostaleChange = (event) => {
    const value = event.target.value;
    setPostale(value);
    setPostaleError(validatePostalCodeInput(value));
    console.log("postaleError", postaleError);
  };

  const handlePhotoDuProduit = (event) => {
    setPhotoDuProduit(event.target.files);
  };

  const handleRecaptcha = (value) => {
    console.log("reCAPTCHA value:", value);
    setRecaptchaValue(value);
  };

  const handleMarque = (event) => {
    setMarque(event.target.value);
  };

  const handleRéférence = (event) => {
    setRéférence(event.target.value);
  };

  const { id } = useParams();

  // Toggles Add Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModalToggle = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleGetUser = async () => {
    const response = await fetch(`${apiUrl}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id: id }),
    });
    const data = await response.json();
    setProduct(data);
    console.log("ProductUserPro", data);
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  const Product = product?.email;

  const handleGetProduct = async () => {
    console.log('testProduct1')
    if (!product) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (product) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }
    console.log('testProduct2')
    const response = await fetch(
      `${apiUrl}/prods/get_product`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id: Product }),
      }
    );
    const data = await response.json();
    setProductTwo(data);
  };

  useEffect(() => {
    handleGetProduct();
  }, [product, Product]);

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(data.filter((item) => item.client_accept === id));
      })
      .catch((error) => console.error(error));
  }, []);

  // Info is what is going to be shown in the table
  const info =
    history.length > 0
      ? history.map((item) => {
          const creationDateOther = new Date(
            item.created_at
          ).toLocaleDateString("fr-FR");
          return {
            id: item._id,
            Création: creationDateOther,
            Domaine: item.typeDeProjet,
            Client: item.nom + "" + item.prenom,
            Prix: item.to_pay ? item.to_pay : 0 + "€",
            statut: item.status,
          };
        })
      : [];

  /*function translateMonth(month) {
        const translations = {
          Jan: "Janv",
          Feb: "Fev",
          Mar: "Mar",
          Apr: "Avr",
          May: "Mai",
          Jun: "Juin",
          Jul: "Juil",
          Aug: "Août",
          Sep: "Sept",
          Oct: "Oct",
          Nov: "Nov",
          Dec: "Dec",
        };
        const uppercaseMonth = month.toUpperCase();
        return translations[uppercaseMonth];
      }
    
      const selectedHistory = product.find((h) => h._id === id);
      // Assuming the `selectedHistory.created_at` is a string representing a date
      const date = new Date(history?.created_at);
    
      // Get the day, month, and year from the date object
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();
    
      // Translate the month name
      const translatedMonth = translateMonth(month);
    
      // Format the date as desired (e.g. "16 Fev 2023")
      const formattedDate = `${day} ${month} ${year}`;      */

  const formData = {
    //photoDuProduit,
    selectedTypes,
    produits,
    marque,
    référence,
    annéeInstallation,
    entretenu,
    //selectedYearEntretenu,
    selectedYear,
    email : Product,
  };

  //Form to be sent to the handleCreateProduct request
  const formDatas = new FormData();
  if (photoDuProduit && photoDuProduit.length) {
    for (let i = 0; i < photoDuProduit.length; i++) {
      formDatas.append("photoDuProduit", photoDuProduit[i]);
    }
  }
  if (photoDuProduit && photoDuProduit.length) {
    formDatas.append("photoDuProduitName", photoDuProduit.name);
  }
  formDatas.append("typeDuBien", selectedTypes);
  formDatas.append("produits", produits);
  formDatas.append("marque", marque);
  formDatas.append("reference", référence);
  formDatas.append("anneeInstallation", annéeInstallation);
  formDatas.append("entretenu", entretenu);
  formDatas.append("puissance", puissance);
  //formDatas.append("selectedYearEntretenu", selectedYearEntretenu);
  formDatas.append("selectedYear", selectedYear);
  formDatas.append("email", product?.email);
  formDatas.append("moisInstallation", moisInstallation);
  formDatas.append("adresse", product?.adresse);
  formDatas.append("postale", postale);
  formDatas.append("ville", ville);
  formDatas.append("autreMarque", autreMarque);
  formDatas.append("recaptchaValue", recaptchaValue);

  const handleCreateProduct = async (event) => {
    event.preventDefault();
     let isValid = true;
        const requiredFields = [
          //"typeDuBien",
          //"produits",
          "marque",
          "référence",
          //"puissance",
          //"annéeInstallation",
          //"photoDuProduit",
          //"entretenu",
          //"selectedYearEntretenu",
          //"selectedYear",
    
          //"email",
        ];
    
        // Perform form validation
        for (const fieldName of requiredFields) {
          if (!formData[fieldName]) {
            isValid = false;
            const input = document.querySelector(`[name="${fieldName}"]`);
            input.style.borderColor = "red";
            if (input.type === "checkbox" || input.type === "radio") {
              const label = input.closest("label");
              if (label) {
                const legend = input.parentNode.querySelector("legend");
                if (legend) {
                  legend.style.color = "red";
                }
              }
            } else {
              const errorText = document.createElement("p");
              errorText.innerText = `Veuillez remplir ce champ.\n`;
              errorText.style.color = "red";
              input.parentNode.insertBefore(errorText, input);
              input.parentNode.insertBefore(
                document.createElement("br"),
                input.nextSibling
              );
            }
          } else {
            const input = document.querySelector(`[name="${fieldName}"]`);
            input.style.borderColor = "initial";
            if (input.type === "checkbox" || input.type === "radio") {
              const label = input.closest("label");
              if (label) {
                const legend = input.parentNode.querySelector("legend");
                if (legend) {
                  legend.style.color = "initial";
                }
              }
            } else {
              const errorText = input.previousElementSibling;
              if (
                errorText &&
                errorText.innerText.startsWith("Veuillez remplir ce champ.")
              ) {
                errorText.remove();
                input.parentNode.removeChild(input.parentNode.lastChild);
              }
            }
          }
        }
    
        // Validate photoDuProduit
        const photoDuProduitInput = document.querySelector(
          '[name="photoDuProduit"]'
        );
        /*if (photoDuProduitInput && photoDuProduitInput.files.length === 0) {
          isValid = false;
          photoDuProduitInput.style.borderColor = "red";
          const label = photoDuProduitInput.querySelector("label");
          if (label) {
            const legend = photoDuProduitInput.parentNode.querySelector("legend");
            if (legend) {
              legend.style.color = "red";
            }
          }
          const errorText = document.createElement("p");
          errorText.innerText = `Veuillez sélectionner une photo.\n`;
          errorText.style.color = "red";
          photoDuProduitInput.parentNode.insertBefore(
            errorText,
            photoDuProduitInput
          );
          photoDuProduitInput.parentNode.insertBefore(
            document.createElement("br"),
            photoDuProduitInput.nextSibling
          );
        } else if (photoDuProduitInput) {
          photoDuProduitInput.style.borderColor = "initial";
          const label = photoDuProduitInput.querySelector("label");
          if (label) {
            const legend = photoDuProduitInput.parentNode.querySelector("legend");
            if (legend) {
              legend.style.color = "initial";
            }
          }
          const errorText = photoDuProduitInput.previousElementSibling;
          if (
            errorText &&
            errorText.innerText.startsWith("Veuillez sélectionner une photo.")
          ) {
            errorText.remove();
            photoDuProduitInput.parentNode.removeChild(
              photoDuProduitInput.parentNode.lastChild
            );
          }
        }*/
    
        // If form is invalid, return early
        if (!isValid) {
          return;
        }

    // If form is valid, proceed with creating a new product
    try {
      setIsLoading(true);
      const res = await fetch(
        `${apiUrl}/prods/create_product`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: formDatas,
        }
      );
      const data = await res.json();
      if (data.success) {
        setPendingList((prevState) => [...prevState, data.product]);
        setIsModalVisible(false);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
    setIsModalVisible(false);
    window.location.reload();
  };

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      console.log(!product)
      if (!product) {
        // Wait for history to be set
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (product) {
              console.log("product is already", product)
              clearInterval(intervalId);
              resolve();
            }
          }, 100);
        });
      }
      setIsLoading(true);
      try {
        const res = await fetch(
          `${apiUrl}/prods/user_product`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify(formData, Product),
          }
        );

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, [product, Product]);

  const productInfo = pendingList.map((item) => {
    const creationDate = new Date(item.created_at);
    const frenchCreationDate = creationDate.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return {
      Création: frenchCreationDate,
      id: item._id,
      Produit: item.typeDuBien,
      Année_Installation: item.annéeInstallation
        ? item.annéeInstallation
        : item.anneeInstallation,
      Marque: item.marque,
      Référence: item.reference ? item.reference : item.référence,
      Maintenance: item.entretenu,
      //Année_Maintenance: item.selectedYearEntretenu,
      //deleteIcon: item._id,
      //creationDate: creationDate.getTime() // add a new property with the date object converted to milliseconds
    };
  });

  return (
    <>
      <>
        {userType !== "User" && (
          <>
            {product && (
              <>
                <div className="overflow-hidden bg-transparent shadow sm:rounded-lg w-[92%] ml-auto mr-auto mt-10">
                  {product.photoIdentite !== "null" &&
                  product.photoIdentite !== undefined &&
                  product.photoIdentite !== "" ? (
                    <img
                      src={`${apiUrl}/${product.photoIdentite}`}
                      alt={product.nom}
                      className="h-40 w-40 object-cover object-center mt-0 mb-14 ml-auto mr-auto overflow-hidden rounded-full"
                    />
                  ) : (
                    <div className="flex justify-center mt-8 ml-auto mr-auto">
                      <span className="h-40 w-40 object-cover object-center mt-0 mb-14 ml-auto mr-auto overflow-hidden rounded-full bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    </div>
                  )}
                  {product.type === "User" ? (
                    <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                      <dl>
                        {product.raisonSocial && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Raison Sociale:
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.raisonSocial}
                            </dd>
                          </div>
                        )}
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Nom et prénom d'utilisateur:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.nom} {product.prenom}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Email:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.email}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Telephone:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.telephone}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Addresse:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.adresse} {product.ville} {product.postal}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Département:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.zone}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {(() => {
                              switch (product.type) {
                                case "User":
                                  return product.entreprise === "oui"
                                    ? "Utilisateur Pro"
                                    : "Utilisateur particulier";
                                case "Admin":
                                  return "Admin";
                                case "Tech":
                                  return "Technicien";
                                case "Superviseur":
                                  return "Superviseur";
                                default:
                                  return product.type;
                              }
                            })()}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  ) : (
                    <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                      <dl>
                        {product.raisonSocial !== "" && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className=" text-sm font-medium text-black border-r border-solid border-black">
                              Raison Sociale:
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.raisonSocial}
                              </dd>
                            </dd>
                          </div>
                        )}
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className=" text-sm font-medium text-black border-r border-solid border-black">
                            Nom et prénom d'utilisateur:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.nom} {product.prenom}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Email:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.email}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Addresse:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.adresse} {product.postal} {product.ville}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Département:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.zone}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className=" text-sm font-medium text-black border-r border-solid border-black">
                            SIRET ou SIREN:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.siret}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className=" text-sm font-medium text-black border-r border-solid border-black">
                            N° assurance:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.nassurance}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className=" text-sm font-medium text-black border-r border-solid border-black">
                            Nom du banqur:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.banque}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className=" text-sm font-medium text-black border-r border-solid border-black">
                            Iban:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.iban}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className=" text-sm font-medium text-black border-r border-solid border-black">
                            Bic:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.bic}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.type === "Tech" ? "Technicien" : null}
                          </dd>
                        </div>
                        {product.type === "Tech" ? (
                          <>
                            {product.raison && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Raison Sociale
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.raison}
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Domaines de compétences
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.domaine}
                              </dd>
                            </div>
                          </>
                        ) : null}
                        {product.type === "User" &&
                        product.entreprise === "oui" ? (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Raison Sociale
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.raisonSocial}
                            </dd>
                          </div>
                        ) : null}
                      </dl>
                    </div>
                  )}
                </div>
              </>
            )}
            {product.type === "Tech" && (
              <div className="overflow-hidden bg-transparent w-[92%] ml-auto mr-auto mt-10">
                <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Historique des intérventions
                  </h3>
                </div>
                <CRUDTable
                  //TODO: Add a Detail Modal
                  data={info}
                  link={`/user-intervention-details/`}
                  //pencilIcon={(id) => <IoPencil style={{fontSize: '18px', cursor: 'pointer', color: 'green'}} onClick={() => handleModifyClick(id)} />}
                  eyeIcon={
                    <IoEyeOutline
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                        color: "#f8c408",
                      }}
                    />
                  }
                  //deleteIcon={(id) => <IoTrashOutline style={{fontSize: '18px', color: 'red', cursor: 'pointer'}} onClick={() => handleDeleteClick(id)} />}
                  //onDataChange={handleDataChange}
                  //handleDeleteProduct={handleDeleteProduct}
                />
              </div>
            )}
          </>
        )}
      </>
      <>
        {userType === "User" && (
          <>
            {product && (
              <>
                <div className="overflow-hidden bg-transparent shadow sm:rounded-lg w-[92%] ml-auto mr-auto mt-10">
                  {product.photoIdentite !== "null" &&
                  product.photoIdentite !== undefined &&
                  product.photoIdentite !== "" ? (
                    <img
                      src={`${apiUrl}/${product.photoIdentite}`}
                      alt={product.nom}
                      className="h-40 w-40 object-cover object-center mt-0 mb-14 ml-auto mr-auto overflow-hidden rounded-full"
                    />
                  ) : (
                    <div className="flex justify-center mt-8 ml-auto mr-auto">
                      <span className="h-40 w-40 object-cover object-center mt-0 mb-14 ml-auto mr-auto overflow-hidden rounded-full bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    </div>
                  )}
                  {product.type === "under_pro" && (
                    <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                      <dl>
                        {product.raisonSocial && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Client sous contrat?:
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.contrat}
                            </dd>
                          </div>
                        )}
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Nom et prénom d'utilisateur:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.nom} {product.prenom}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Telephone:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.telephone}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Addresse:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.adresse} {product.ville} {product.postal}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Client Sous Contrat :
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.contrat}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Ajouter un nouvel appareil:
                          </dt>
                          <button
                            onClick={handleModalToggle}
                            className=" userProfile-btn inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Ajouter
                          </button>
                        </div>
                        {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {(() => {
                              switch (product.type) {
                                case "User":
                                  return product.entreprise === "oui"
                                    ? "Utilisateur Pro"
                                    : "Utilisateur particulier";
                                case "Admin":
                                  return "Admin";
                                case "Tech":
                                  return "Technicien";
                                case "Superviseur":
                                  return "Superviseur";
                                default:
                                  return product.type;
                              }
                            })()}
                          </dd>
                          </div>*/}
                      </dl>
                    </div>
                  )}
                </div>
                <div className="w-[92%] ml-auto mr-auto mt-10 md:col-span-2 md:mt-10">
                  <CRUDTable
                    /*data={info.filter((item) => {
                if (!selectedValue && !selectedStatus) {
                  return true;
                }

                return selectedValue ? item.produit === selectedValue : true;
              })}*/
                    data={productInfo}
                    link={`/product/`}
                    /*pencilIcon={(id) => (
                <IoPencil
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "green",
                  }}
                  onClick={() => handleModifyClick(id)}
                />
              )}*/
                    eyeIcon={
                      <IoEyeOutline
                        style={{
                          fontSize: "18px",
                          cursor: "pointer",
                          color: "#f8c408",
                        }}
                      />
                    }
                    deleteIcon={(id) => (
                      <IoTrashOutline
                        style={{
                          fontSize: "18px",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteClick(id)}
                      />
                    )}
                    //onDataChange={handleDataChange}
                    //handleDeleteProduct={handleDeleteProduct}
                  />
                </div>
              </>
            )}
            {isModalVisible ? (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setIsModalVisible(false)}
                  ></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 sm:flex">
                        <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                          <IoAddCircleOutline className="text-green-600" />
                        </div>
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-bold text-green-700">
                            Ajouter un nouvel appareil
                          </h4>
                          <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                            Veuillez remplir toutes les informations requises
                            dans le formulaire pour ajouter votre nouvel
                            appareil.
                          </p>
                          <div className="modal-content mr-4">
                            <form className="mr-4 mb-4">
                              {errors.length > 0 && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-4">
                                  <p>Please fill in the following fields:</p>
                                  <ul>
                                    {errors.map((field) => (
                                      <li key={field}>{field}</li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                              <div>
                                <fieldset>
                                  <legend className="contents text-base font-medium text-gray-900 ">
                                    Type du produit{" "}
                                    <span className="text-red-500"> * </span>
                                  </legend>
                                  {/*<p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>*/}
                                  <div className="mt-4 space-y-4">
                                    {/*<div className="flex items-center">
                                        <input
                                          id="typeDuBien"
                                          name="typeDuBien"
                                          type="radio"
                                          value="Pompe à chaleur"
                                          checked={
                                            produits === "Pompe à chaleur"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-everything"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          Pompe à chaleur
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="push-email"
                                          name="push-notifications"
                                          type="radio"
                                          value="ballon thermodynamique"
                                          checked={
                                            produits ===
                                            "ballon thermodynamique"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-email"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          ballon thermodynamique
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="push-nothing"
                                          name="push-notifications"
                                          type="radio"
                                          value="ballon solaire"
                                          checked={
                                            produits === "ballon solaire"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-nothing"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          ballon solaire
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="push-nothing"
                                          name="push-notifications"
                                          type="radio"
                                          value="ballon d’eau chaude électrique"
                                          checked={
                                            produits ===
                                            "ballon d’eau chaude électrique"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-nothing"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          ballon d’eau chaude électrique
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="push-nothing"
                                          name="push-notifications"
                                          type="radio"
                                          value="panneau photovoltaïque"
                                          checked={
                                            produits ===
                                            "panneau photovoltaïque"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-nothing"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          Panneau Photovoltaïque
                                        </label>
                                        </div>*/}
                                    {Domaines.map((domaine) => (
                                      <div
                                        className=" rounded-md cursor-pointer px-4 py-2"
                                        key={domaine.id}
                                      >
                                        <div
                                          onClick={() =>
                                            handleTypeSelection(domaine.nom)
                                          }
                                        >
                                          {domaine.nom}
                                        </div>
                                        {domaine.hasType &&
                                          selectedTypes.includes(domaine.nom) &&
                                          domaine.type.map((type) => (
                                            <div key={type} className="p-2">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  checked={selectedTypes.includes(
                                                    type
                                                  )}
                                                  onChange={() =>
                                                    handleTypeSelection(type)
                                                  }
                                                />
                                                {type}
                                              </label>
                                            </div>
                                          ))}
                                        {!domaine.hasType &&
                                          selectedTypes.includes(
                                            domaine.nom
                                          ) && (
                                            <div>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  checked={true}
                                                  onChange={() => {}}
                                                  disabled
                                                />
                                                {domaine.nom}
                                              </label>
                                            </div>
                                          )}
                                      </div>
                                    ))}
                                  </div>
                                </fieldset>
                              </div>
                              <div className="flex justify-between">
                                <div className="relative mt-4">
                                  <label
                                    htmlFor="moisInstallation"
                                    className=""
                                  >
                                    Mois d’installation{" "}
                                    <span className="text-red-500"> * </span>
                                  </label>
                                  <select
                                    id="moisInstallation"
                                    name="moisInstallation"
                                    value={moisInstallation}
                                    onChange={(e) =>
                                      setMoisInstallation(e.target.value)
                                    }
                                    className="mb-2 block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                  >
                                    {Mois.map((mois) => (
                                      <option key={mois} value={mois}>
                                        {mois}
                                      </option>
                                    ))}
                                  </select>
                                  <div className=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4 mt-6"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                  {/*<input
                                      type="number"
                                      name="moisInstallation"
                                      value={moisInstallation}
                                      onChange={(event) =>
                                        setMoisInstallation(event.target.value)
                                      }
                                      required
                                      id="moisInstallation"
                                      autoComplete="moisInstallation"
                                      max={12}
                                      className="mb-2 relative p-16 block w-full appearance-none  rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />*/}
                                </div>
                                <div className="relative mt-4">
                                  <label
                                    htmlFor="annéeInstallation"
                                    className=""
                                  >
                                    Année d’installation{" "}
                                    <span className="text-red-500"> * </span>
                                  </label>

                                  <select
                                    id="annéeInstallation"
                                    name="annéeInstallation"
                                    value={annéeInstallation}
                                    onChange={(e) =>
                                      setAnnéeInstallation(e.target.value)
                                    }
                                    className="mb-2 block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                  >
                                    {years.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                  <div className=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4 mt-6"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label htmlFor="marque" className="margin-top">
                                  Marque du produit{" "}
                                  <span className="text-red-500"> * </span>
                                </label>
                                <select
                                  id="marque"
                                  name="marque"
                                  autoComplete="marque"
                                  value={marque}
                                  onChange={handleMarque}
                                  required
                                  style={{ marginTop: "16px" }}
                                  className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option value="">
                                    Sélectionner une marque
                                  </option>
                                  {Marque.map((marqueOption) => (
                                    <option
                                      key={marqueOption}
                                      value={marqueOption}
                                    >
                                      {marqueOption}
                                    </option>
                                  ))}
                                  <option value="autre">Autre</option>
                                </select>
                                {marque === "autre" && (
                                  <div>
                                    <label
                                      htmlFor="autre-marque"
                                      className="margin-top"
                                    >
                                      Autre marque{" "}
                                      <span className="text-red-500"> * </span>
                                    </label>
                                    <input
                                      id="autre-marque"
                                      name="autre-marque"
                                      type="text"
                                      autoComplete="off"
                                      value={autreMarque}
                                      onChange={handleAutreMarque}
                                      required
                                      style={{ marginTop: "16px" }}
                                      className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      placeholder="Marque du produit"
                                    />
                                  </div>
                                )}
                              </div>

                              <div>
                                <label
                                  htmlFor="référence"
                                  className="mb-[-16px]"
                                >
                                  Référence{" "}
                                  <span className="text-red-500"> * </span>
                                </label>
                                <input
                                  id="référence"
                                  name="référence"
                                  type="text"
                                  autoComplete="référence"
                                  required
                                  value={référence}
                                  onChange={handleRéférence}
                                  style={{ marginTop: "16px" }}
                                  className="relative p-16 block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Référence"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="puissance"
                                  className="margin-top"
                                >
                                  Puissance du produit{" "}
                                  <span className="text-red-500"> * </span>
                                </label>
                                <input
                                  id="puissance"
                                  name="puissance"
                                  type="text"
                                  autoComplete="puissance"
                                  value={puissance}
                                  onChange={(event) =>
                                    setPuissance(event.target.value)
                                  }
                                  required
                                  style={{ marginTop: "16px" }}
                                  className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Puissance du produit"
                                />
                              </div>
                              <div>
                                <label
                                  className="block text-sm font-medium text-gray-700"
                                  style={{ marginTop: "16px" }}
                                  id="photoDuProduitid"
                                >
                                  Photo du produit{" "}
                                </label>
                                <div>
                                  {photoDuProduit && (
                                    <div className="mt-1">
                                      {Array.from(photoDuProduit).map(
                                        (file) => (
                                          <div
                                            key={file.name}
                                            className="flex items-center"
                                          >
                                            <span className="font-medium text-green-600">
                                              {file.name}
                                            </span>
                                          </div>
                                        )
                                      )}
                                      <div className="flex gap-2">
                                        <button
                                          className=" mt-[10px] bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() =>
                                            setPhotoDuProduit(null)
                                          }
                                        >
                                          Change
                                        </button>
                                        <div className="mt-4">
                                          <label
                                            htmlFor="photoDuProduit"
                                            className=" bg-gray-200 hover:bg-gray-300 py-[10px] px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 cursor-pointer"
                                          >
                                            Ajouter plus de photos
                                            <input
                                              id="photoDuProduit"
                                              name="photoDuProduit"
                                              type="file"
                                              multiple
                                              className="sr-only"
                                              onChange={handleAddMorePictures}
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {!photoDuProduit && (
                                    <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                      <div className="space-y-1 text-center">
                                        <svg
                                          className="mx-auto h-12 w-12 text-gray-400"
                                          stroke="currentColor"
                                          fill="none"
                                          viewBox="0 0 48 48"
                                          aria-hidden="true"
                                        >
                                          <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>

                                        <div className="flex text-sm text-gray-600">
                                          <label
                                            htmlFor="photoDuProduit"
                                            className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                          >
                                            <span>Upload a file</span>
                                            <input
                                              id="photoDuProduit"
                                              name="photoDuProduit"
                                              type="file"
                                              multiple
                                              className="sr-only"
                                              value=""
                                              onChange={handlePhotoDuProduit}
                                            />
                                          </label>
                                          <p className="pl-1">
                                            or drag and drop
                                          </p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                          PNG, JPG, GIF up to 10MB
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {parseInt(annéeInstallation) !==
                                new Date().getFullYear() && (
                                <fieldset className="mb-6 margin-top">
                                  <legend className="contents text-sm font-medium text-gray-600">
                                    Votre appareil est-il entretenu ?{" "}
                                    <span className="text-red-500"> * </span>
                                  </legend>
                                  <div className="mt-4 space-y-4">
                                    <div className="flex items-center">
                                      <input
                                        id="oui"
                                        name="entretenu"
                                        type="radio"
                                        value="oui"
                                        onChange={(e) =>
                                          setEntretenu(e.target.value)
                                        }
                                        checked={entretenu === "oui"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor="oui"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        Oui
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="non"
                                        name="entretenu"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        value="non"
                                        onChange={(e) =>
                                          setEntretenu(e.target.value)
                                        }
                                        checked={entretenu === "non"}
                                      />
                                      <label
                                        htmlFor="non"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        Non
                                      </label>
                                    </div>
                                  </div>
                                  {/*{entretenu === "oui" && (
                                      <div className="relative mt-4">
                                        <label
                                          htmlFor="selectedYearEntretenu"
                                          className="sr-only"
                                        >
                                          Depuis quelle année
                                        </label>
                                        <select
                                          id="selectedYearEntretenu"
                                          name="selectedYearEntretenu"
                                          value={selectedYearEntretenu}
                                          onChange={handleSelectedYear}
                                          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                        >
                                          {years.map((year) => (
                                            <option key={year} value={year}>
                                              {year}
                                            </option>
                                          ))}
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                          <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                          </svg>
                                        </div>
                                      </div>
                                          )}*/}
                                </fieldset>
                              )}

                              {/*<fieldset className="mb-6 margin-top">
                                <legend className="contents text-sm font-medium text-gray-600">
                                  L'adresse d'intervention est-elle la vôtre ?{" "}
                                  <span className="text-red-500"> * </span>
                                </legend>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="oui"
                                      name="adresseIntervention"
                                      type="radio"
                                      value="oui"
                                      onChange={(e) =>
                                        setAdresseIntervention(e.target.value)
                                      }
                                      checked={adresseIntervention === "oui"}
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor="oui"
                                      className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                      Oui
                                    </label>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      id="non"
                                      name="adresseIntervention"
                                      type="radio"
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      value="non"
                                      onChange={(e) =>
                                        setAdresseIntervention(e.target.value)
                                      }
                                      checked={adresseIntervention === "non"}
                                    />
                                    <label
                                      htmlFor="non"
                                      className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                      Non
                                    </label>
                                  </div>
                                </div>
                                {adresseIntervention === "non" && (
                                  <div className="relative mt-4">
                                    <label
                                      htmlFor="selectedAdresseIntervention"
                                      className="sr-only"
                                    >
                                      Depuis quelle année
                                    </label>
                                    <div className="bg-white  py-5 sm:p-6">
                                      <div className="col-span-6 sm:col-span-6 lg:col-span-2 mt-2">
                                        <label
                                          htmlFor="adresse"
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          N° et Nom de rue{" "}
                                          <span className="text-red-500">
                                            {" "}
                                            *{" "}
                                          </span>
                                        </label>
                                        <input
                                          value={adresse}
                                          type="text"
                                          name="adresse"
                                          id="adresse"
                                          onChange={(event) =>
                                            setAdresse(event.target.value)
                                          }
                                          required
                                          autoComplete="adresse"
                                          className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                      </div>

                                      <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-4">
                                        <label
                                          htmlFor="ville"
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          Ville{" "}
                                          <span className="text-red-500">
                                            {" "}
                                            *{" "}
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          value={ville}
                                          onChange={(event) =>
                                            setVille(event.target.value)
                                          }
                                          name="ville"
                                          required
                                          id="ville"
                                          autoComplete="ville"
                                          className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                      </div>

                                      <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-4">
                                        <label
                                          htmlFor="postale"
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          Code Postale:{" "}
                                          <span className="text-red-500">
                                            {" "}
                                            *{" "}
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          name="postale"
                                          value={postale}
                                          onChange={handlePostaleChange}
                                          required
                                          id="postale"
                                          autoComplete="postale"
                                          className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {postaleError && (
                                          <p style={{ color: "red" }}>
                                            {postaleError}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                        </fieldset>*/}
                            </form>
                          </div>
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={handleRecaptcha}
                          />
                          <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                              onClick={handleCreateProduct}
                            >
                              Ajouter
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                              onClick={() => setIsModalVisible(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {showModal ? (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setShowModal(false)}
                  ></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 sm:flex">
                        <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-red-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-medium text-gray-800">
                            Êtes-vous sûr de vouloir supprimer?
                          </h4>
                          <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                            En cliquant sur "Supprimer", vous supprimez
                            définitivement cet appareil. Cette action est
                            irréversible. Veuillez vous assurer que vous voulez
                            bien continuer.
                          </p>
                          <div className="items-center gap-2 mt-3 sm:flex">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                              onClick={() => handleConfirmDelete()}
                            >
                              Supprimer
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                              onClick={() => handleCancelDelete()}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}
      </>
    </>
  );
};

export default UserDetail;
