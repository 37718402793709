import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import { BreadCrumb } from "../../../Components";
import emailjs from "emailjs-com";



const AdminMailingText = ({ emails }) => {
  const [text, setText] = useState("");

  const sendEmails = (event) => {
    event.preventDefault();
    if (!text) return; // if there's no text, don't send any emails
    const templateParams = {
      message: text,
    };
    emails.forEach((email) => {
      emailjs.send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", templateParams, "YOUR_USER_ID")
        .then((response) => console.log("Email sent to", email, response))
        .catch((error) => console.error("Error sending email to", email, error));
    });
    setText(""); // clear the text field after sending the emails
  };

  return (
    <>
      <BreadCrumb From={""} To={"Mailing"} hrefFrom={"#"}/>
      <div className="mt-10 w-[92%] ml-auto mr-auto">
        <form onSubmit={sendEmails}>
          <div>

          </div>
          <div className="editor">
            <CKEditor
              editor={ClassicEditor}
              data={text}
              onChange={(event, editor) => {
                const data = editor.getData();
                setText(data);
              }}
              config={{
                simpleUpload: {
                  uploadUrl:
                    "https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json",
                },
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "blockQuote",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "imageUpload",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "mediaEmbed",
                  "|",
                  "undo",
                  "redo",
                  "|",
                  "fontfamily",
                  "fontsize",
                  "fontcolor"
                ],
              }}
            />
          </div>
          <div className="mt-8">
            <h2>Content</h2>
            <p>{parse(text)}</p>
          </div>
          <button type="submit">Send Emails</button>
        </form>
      </div>
    </>
  );
};

export default AdminMailingText;
