import React from "react";
import { Helmet } from "react-helmet";

const Cgv = () => {
  return (
    <>
      <Helmet>
        <title>Conditions générales de ventes</title>
        <meta
          name="keywords"s
          content="Conditions générales de ventes LeBonTechnicien.fr"
        />
        <meta name="description" content="Nos conditions générales de ventes." />
        <link rel="canonical" href="https://lebontechnicien.net/cgv" />
      </Helmet>
      <main>
        <section className="pdc leBonTech-section">
          <h1 className="leBonTech-h1">Nos conditions générales de ventes</h1>
          <h2 className="leBonTech-h2">Préambule</h2>
          <p className="leBonTech-p">
            Les présentes Conditions Générales de Vente (CGV) s'appliquent à
            l'utilisation de l'application web et mobile LeBonTechnicien.fr, qui
            met en relation les particuliers et les professionnels experts en
            installations et réparations d'équipements d'énergie renouvelable.
            En utilisant l'application, l'utilisateur reconnaît et accepte sans
            réserve les présentes CGV.{" "}
          </p>
          <h2 className="leBonTech-h2">Article 1 - Objet</h2>
          <p className="leBonTech-p">
            Les présentes CGV ont pour objet de définir les conditions dans
            lesquelles les utilisateurs peuvent accéder et utiliser la
            plateforme de mise en relation entre particuliers et professionnels
            experts en installations et réparations d'équipements d'énergie
            renouvelable.{" "}
          </p>
          <h2 className="leBonTech-h2">
            Article 2 - Création de compte et confidentialité
          </h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">2.1.</span> Pour utiliser
                l'application, les utilisateurs doivent créer un compte en
                fournissant des informations exactes, complètes et à jour.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">2.2.</span> Les utilisateurs
                sont tenus de maintenir la confidentialité de leurs identifiants
                de connexion et ne doivent en aucun cas les partager avec des
                tiers, sous peine de suspension ou de résiliation de leur
                compte.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">2.3.</span> L'utilisateur est
                responsable de toutes les activités qui se déroulent sur son
                compte, et s'engage à informer immédiatement LeBonTechnicien.fr
                en cas de toute utilisation non autorisée de son compte.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">
            Article 3 - Utilisation de la plateforme
          </h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">3.1.</span> Les utilisateurs
                s'engagent à utiliser la plateforme de manière conforme à la
                législation en vigueur et aux présentes CGV.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">3.2.</span> Les utilisateurs
                s'engagent à ne pas publier ou transmettre sur la plateforme
                tout contenu illégal, diffamatoire, injurieux, obscène ou
                autrement inapproprié.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">3.3.</span> LeBonTechnicien.fr
                se réserve le droit de supprimer tout contenu inapproprié sans
                préavis et de suspendre ou de résilier l'accès à la plateforme
                de tout utilisateur en cas de non-respect des CGV ou de la
                législation en vigueur.{" "}
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">
            Article 4 - Prestations des professionnels
          </h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">4.1.</span> Les professionnels
                référencés sur la plateforme s'engagent à fournir des
                prestations conformes à la législation en vigueur et à la charte
                de qualité de LeBonTechnicien.fr.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">4.2.</span> Les professionnels
                sont entièrement responsables des prestations qu'ils fournissent
                et des conséquences qui en découlent, et s'engagent à respecter
                les obligations découlant de la législation en vigueur et de la
                charte de qualité de LeBonTechnicien.fr
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 5 - Devis et contrats</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">5.1.</span> Les professionnels
                s'engagent à fournir des devis clairs, détaillés et conformes à
                la législation en vigueur.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">5.2.</span> Le contrat est
                conclu entre le particulier et le professionnel lorsque le devis
                est accepté par les deux parties. LeBonTechnicien.fr n'est pas
                partie contractante et n'intervient que comme plateforme de mise
                en relation.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 6 - Responsabilité</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">6.1.</span> LeBonTechnicien.fr
                agit uniquement en tant qu'intermédiaire entre les utilisateurs
                et les professionnels et ne peut être tenu responsable des
                problèmes ou litiges qui peuvent survenir entre les parties.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">6.2.</span> La responsabilité
                de LeBonTechnicien.fr est strictement limitée aux obligations
                découlant des présentes CGV et de la charte de qualité.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 7 - Propriété intellectuelle</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">7.1.</span> Tous les éléments
                de l'application, tels que le logo, les images, les textes, et
                le code source, sont protégés par la propriété intellectuelle et
                sont la propriété exclusive de LeBonTechnicien.fr.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">7.2.</span> Il est interdit de
                reproduire, copier ou utiliser tout élément de l'application
                sans autorisation préalable de la part de LeBonTechnicien.fr.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 8 - Modification des CGV</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <p className="leBonTech-p">
                LeBonTechnicien.fr se réserve le droit de modifier les présentes
                CGV à tout moment, sans préavis. Les utilisateurs seront
                informés des modifications apportées aux CGV et devront accepter
                les nouvelles conditions pour continuer à utiliser
                l'application. En cas de désaccord avec les modifications
                apportées aux CGV, les utilisateurs devront cesser d'utiliser
                l'application.
              </p>
            </ul>
          </p>
          <h2 className="leBonTech-h2">
            Article 9 - Droit applicable et juridiction compétente
          </h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">9.1.</span> Les présentes CGV
                sont régies par le droit applicable dans le pays où se trouve
                l'entreprise exploitant l'application LeBonTechnicien.fr.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">9.2.</span> En cas de litige
                entre les utilisateurs et les professionnels, les parties
                s'engagent à rechercher une solution amiable. À défaut d'accord,
                le litige sera soumis aux tribunaux compétents du pays où se
                trouve l'entreprise exploitant l'application.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 10 - Dispositions finales</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">10.1.</span> Si une disposition
                des présentes CGV est jugée invalide ou inapplicable par un
                tribunal compétent, les autres dispositions restent en vigueur
                et continuent à s'appliquer.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">10.2.</span> Les présentes CGV
                constituent l'intégralité de l'accord entre les utilisateurs et
                LeBonTechnicien.fr concernant l'utilisation de l'application et
                remplacent tous les accords antérieurs, écrits ou oraux, entre
                les parties.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 11 - Contact</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <p className="leBonTech-p">
                Pour toute question ou demande de renseignement concernant les
                présentes CGV ou pour signaler un problème lié à l'application,
                les utilisateurs peuvent contacter LeBonTechnicien.fr via les
                coordonnées fournies sur le site web ou dans l'application.
              </p>
            </ul>
          </p>
        </section>
      </main>
    </>
  );
};

export default Cgv;
