import React from "react";
import { useNavigate } from "react-router-dom";

const Cards = ({ groupedHistory }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-start gap-4 flex-wrap w-full">
      {Object.keys(groupedHistory).map((groupKey, groupIndex) => (
        <div
          key={groupIndex}
          className="rounded-[10px] border border-[#D9D9D9] w-[24%] !h-[250px]"
        >
          {groupedHistory[groupKey].slice(0, 1).map((item, index) => (
            <div key={index}>
              <div className="bg-[#FAFAFA] p-4 rounded-t-[10px]">
                <p className="text-[15px] text-center">
                  {item.typeDeProjet ? `${item.typeDeProjet}` : "-"}
                </p>
              </div>
              <div className="p-4 text-sm">
                <p>
                  Marque :{" "}
                  {item.marque !== "Autres" ? item.marque : item.autreMarque}
                </p>
                <p>Puissance: {item.puissance} </p>
                <p>Numéro de série: {item.numeroSerie}</p>
                <p>Référence: {item.reference}</p>
                <p>
                  Date d'ajout :{" "}
                  {/^\d{2}\/\d{2}\/\d{4}$/.test(item.created_at)
                    ? item.selectedDate
                    : new Date(item.created_at).toLocaleDateString("fr-FR")}
                </p>
              </div>
              <div className="p-4 w-full">
                <button
                  onClick={() => navigate(`/client-appareils-historique/${item._id}`)}
                  className="w-full text-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 "
                >
                  Consulter l'appareil
                </button>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Cards;
