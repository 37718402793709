import React, { useState } from "react";
import guideClient from "../../ressources/guide-client.pdf";
import guideClientPro from "../../ressources/guide-client-pro.pdf";

import {
  LeBonTechnicien2grid,
  Banner,
  LeBonTechnicien3grid,
  LeBonTechnicien2gridLeft,
  PartnerBanner,
} from "../../Components";

import {
  LebonTechnicien_data,
  CommentCaMarche_data,
  Nos_prestations,
  Nos_valeur,
} from "../../Constants/dummy";

import googlePlay from "../../Assets/media/google-app.png";
import appStore from "../../Assets/media/app-store.png";
import phone from "../../Assets/les-guides-new.png";
import Phone2 from "../../Assets/guide-client-pro.png";
import Phone3 from "../../Assets/guide-client.png";
import Phone4 from "../../Assets/guide-technicien.png";
import icon1 from "../../Assets/media/client-demande.png";
import icon2 from "../../Assets/media/tech-envoi.png";
import icon3 from "../../Assets/media/choix-tech.png";
import icon4 from "../../Assets/media/installation.png";
import icon5 from "../../Assets/media/reparation.png";
import icon6 from "../../Assets/media/maintenance.png";
import { Helmet } from "react-helmet";
import GuidesButtons from "../../Components/LandingPage/GuidesButtons";

const Guides = () => {
  const [inputDeviceValue, setInputDeviceValue] = useState("Device");
  const [inputLocationValue, setInputLocationValue] = useState("Location");

  function handleChangeDevice(event) {
    setInputDeviceValue(event.target.value);
  }

  function handleChangeLocation(event) {
    setInputLocationValue(event.target.value);
  }

  return (
    <>
      <Helmet>
        <title>LeBonTechnicien.fr</title>
        <meta name="keywords" content="LeBonTechnicien.fr" />
        <meta
          name="description"
          content="Téléchargez notre appli LeBonTechnicien.fr et profitez de l'expertise de l'élite des professionnels en énergies renouvelables pour un entretien paisible de vos équipements d'énergie renouvelable !"
        />
        <link rel="canonical" href="https://lebontechnicien.net/" />
      </Helmet>

      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">
                  Guides Complets pour
                  <br /> Clients et Techniciens
                </h1>
                <p className="leBonTech-p">
                  Bienvenue dans notre section de guides complets, spécialement
                  conçus pour répondre aux besoins à la fois des utilisateurs et
                  des techniciens engagés dans le domaine des énergies
                  renouvelables. Ces guides détaillés offrent une assistance
                  étape par étape, couvrant tous les aspects de notre plateforme
                  LeBonTechnicien.fr. Que vous soyez un utilisateur souhaitant
                  créer des demandes d'intervention, suivre vos projets, ou
                  ajouter des équipements, ou un technicien visant à maximiser
                  vos opportunités et gérer vos interventions, ces guides vous
                  fourniront toutes les informations nécessaires pour une
                  expérience fluide et réussie.
                </p>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img
                  src={phone}
                  alt="Illustration application LeBonTechnicien"
                />
              </div>
            </div>
          </div>
        </div>
      </Banner>

      <h2 className="leBonTech-h2" style={{ marginTop: "50px" }}>
        Guide dédiée aux Clients !
      </h2>
      <div
        style={{
          width: "10%",
          height: "2px",
          backgroundColor: "#3e9f36",
          marginTop: "-20px",
          marginLeft: "auto",
          marginBottom: "50px",
          marginRight: "auto",
        }}
      ></div>

      <LeBonTechnicien2grid
        paragraph="Le guide client de LeBonTechnicien.fr offre une assistance détaillée pour les utilisateurs cherchant à créer des demandes d'intervention, suivre leurs projets, ajouter des appareils et gérer les contrats de maintenance. La section de 'Création de Demande' guide les clients à travers le processus de soumission, garantissant des informations précises. La page 'Suivi des Interventions' leur permet de surveiller l'évolution des projets en cours, renforçant la transparence. Ajouter des 'Appareils' et des 'Contrats de Maintenance' est simple grâce aux étapes détaillées, améliorant la gestion à long terme. Ce guide complet fournit aux clients les outils nécessaires pour maximiser l'efficacité, la visibilité et la satisfaction lors de l'engagement avec des techniciens spécialisés dans les énergies renouvelables."
        imgSrc={Phone3}
      />

      <div
        className="container guide-btn-container"
        style={{ justifyContent: "flex-start !important" }}
      >
        <GuidesButtons
          viewPdfLink="/guides/client"
          downloadPdfLink={guideClient}
        />
      </div>
      

      <h2 className="leBonTech-h2" style={{ marginTop: "50px" }}>
        Guide dédiée aux Clients Pro!
      </h2>
      <div
        style={{
          width: "10%",
          height: "2px",
          backgroundColor: "#3e9f36",
          marginTop: "-20px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "50px",
        }}
      ></div>

      <LeBonTechnicien2grid
        paragraph="Le guide destiné aux clients Pro de LeBonTechnicien.fr propose une assistance détaillée pour les utilisateurs professionnels cherchant à créer des projets, suivre leurs projet, ajouter des clients et gérer les contrats de maintenance. La section 'Création de Projet' guide les clients professionnels à travers le processus de proposition de projets, garantissant des informations précises et complètes, incluant les détails du client et de l'équipement. La page 'Suivi des projets' leur permet de surveiller l'avancement des projets en cours, renforçant la transparence. Ajouter des 'Client' et leurs 'Appareil' est simplifié grâce aux étapes détaillées, améliorant la gestion à long terme. Ce guide exhaustif fournit aux clients professionnels les outils essentiels pour optimiser leur efficacité, visibilité et satisfaction lors de leur collaboration avec des techniciens spécialisés dans les énergies renouvelables."
        imgSrc={Phone2}
      />

      <div
        className="container guide-btn-container"
        style={{ justifyContent: "flex-start !important" }}
      >
        <GuidesButtons
          viewPdfLink="/guides/client-pro"
          downloadPdfLink={guideClientPro}
        />
      </div>

      <h2 className="leBonTech-h2" style={{ marginTop: "50px" }}>
        Guide dédiée aux Techniciens !
      </h2>
      <div
        style={{
          width: "10%",
          height: "2px",
          marginBottom: "50px",
          backgroundColor: "#3e9f36",
          marginTop: "-20px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>

      <LeBonTechnicien2grid
        paragraph="Le guide d'utilisation de LeBonTechnicien.fr offre une orientation claire pour les techniciens spécialisés en énergies renouvelables. La plateforme propose des fonctionnalités puissantes pour améliorer leur activité. La section 'Profil' permet de gérer et mettre à jour les détails professionnels, favorisant la communication et la visibilité. La page des 'Statistiques' offre une vue d'ensemble des performances et des projets en cours. Pour répondre aux demandes, la section 'Interventions' guide les techniciens dans le processus, en insistant sur l'importance de proposer des dates d'intervention. Le guide, clair et concis, offre une référence essentielle pour maximiser les opportunités et le succès dans le domaine des énergies renouvelables."
        imgSrc={Phone4}
      />

      {/*<div className="container guide-btn-container" style={{marginBottom: "20px"}}>
            <GuidesButtons/>
  </div>*/}
      <div
        className="container guide-btn-container"
        style={{ marginBottom: "20px" }}
      >
        <div
          className="custom-button-container"
          style={{ justifyContent: "flex-start !important" }}
        >
          <h3 style={{marginLeft:"20px", color:"#d7000dff"}}>Disponible prochainement</h3>
        </div>
      </div>
    </>
  );
};

export default Guides;
