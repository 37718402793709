import React from "react";
import { Banner, CardSystem } from "../../Components";
import { Helmet } from "react-helmet";
import partenariat_deditriche from "../../Assets/media/partenariat_deditriche.png";
import { Formation_Data } from "../../Constants/dummy";

const Dedietrich = () => {
  return (
    <>
      <Helmet>
        <title>De Dietrich Formations - LeBontechnicien.fr</title>
        <meta
          name="keywords"
          content="Formations De Dietrich LeBontechnicien.fr"
        />
        <meta
          name="description"
          content="Un nouveau partenariat de confiance entre LeBontechnicien.fr et De Dietrich. Cette collaboration unique vise à renforcer les compétences de nos techniciens déjà experts en installation et réparation d'équipements d'énergie renouvelable. Nous proposons des formations exclusives offertes par De Dietrich."
        />
        <link rel="canonical" href="https://lebontechnicien.net/" />
      </Helmet>

      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">
                  Formations De Dietrich : Formations spécialisées en énergie
                  renouvelable.
                </h1>
                <p className="leBonTech-p">
                  De Dietrich est renommée dans le domaine de la technologie
                  énergétique, Ces formations visent à renforcer les compétences
                  de nos techniciens déjà experts en installation et réparation
                  d'équipements d'énergie renouvelable. Inscrivez-vous pour
                  améliorer vos compétences et de rester à la pointe de
                  l'évolution technologique dans le domaine des énergies
                  renouvelables.
                </p>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img
                  src={partenariat_deditriche}
                  alt="Illustration application LeBonTechnicien"
                />
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <CardSystem data={Formation_Data} />
    </>
  );
};

export default Dedietrich;
