import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components";

const ProTeamPlannings = () => {
  const [teamData, setTeamData] = useState([]);
  const [teamId, setTeamID] = useState("");
  const [calendars, setCalendars] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [filteredPlanningTasks, setFilteredPlanningTasks] = useState([]);

  const apiUrl = 'https://lebontechnicien.net'

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const userId = sessionStorage.getItem("id");

        const response = await fetch(`${apiUrl}teams/find_teams`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({}),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Filter the team data based on the current connected user's ID
        const filteredTeamData = data.filter((team) => {
          const lastTechnicienKey = `TechnicienEquipe[${
            Object.keys(team).filter((key) =>
              key.startsWith("TechnicienEquipe[")
            ).length - 1
          }]`;

          return team[lastTechnicienKey] === userId;
        });

        // Log the filtered team data after setting it to state
        setTeamData(filteredTeamData);
        setTeamID(filteredTeamData[0]._id);
        console.log("Filtered Team Data:", filteredTeamData);
        console.log("Filtered Team id:", filteredTeamData[0]._id);

        // Call getAllCalendars after setting the teamId state
        getAllCalendars(filteredTeamData[0]._id);
      } catch (error) {
        console.error("Error fetching team data:", error.message);
      }
    };

    fetchTeamData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  // Get all Plannings
  const getAllCalendars = async (id) => {
    try {
      // Make a GET request using the fetch API
      const response = await fetch(`${apiUrl}calenders/find_calender`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({}),
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // If the response is successful, you can handle the result here
        const result = await response.json();
        console.log("teamIDCalendar", id);
        const filteredCalendars = result.filter(
          (calendar) => calendar.Team === id
        );
        setCalendars(filteredCalendars);
      } else {
        // Handle other response statuses or errors here
        console.error("Error:", response.status);
      }
    } catch (error) {
      // Handle any network or fetch errors
      console.error("Fetch error:", error);
    }
  };

  //Handle Click on Month/Year Button
  const handleMonthYearClick = (monthYear) => {
    setSelectedMonthYear(monthYear);

    // Step 2: Filter tasks based on selected month and year
    const filtered = calendars.filter(
      (item) =>
        new Date(item.departureDate).toLocaleString("en-us", {
          month: "long",
          year: "numeric",
        }) === monthYear
    );

    setFilteredPlanningTasks(filtered);
  };

  //Map through data to get unique months and years
  const uniqueMonthsYears = Array.from(
    new Set(
      calendars?.map((item) =>
        new Date(item.departureDate).toLocaleString("en-us", {
          month: "long",
          year: "numeric",
        })
      )
    )
  );

  // Handle Click on Task Button
  const handleTaskClick = (calendarId) => {
    // Navigate to the specified URL
    const url = `/planning/${calendarId}/${teamId}`;
    // Use your preferred method for navigation, e.g., react-router-dom or window.location.href
    window.location.href = url;
  };

  return (
    <>
      <BreadCrumb
        From={""}
        To={"List des Plannings"}
        hrefFrom={"/mon-compte-team-pro"}
      />
      {calendars && (
        <div className="">
          {/* Step 2: Display Buttons for Each Month/Year */}
          <div className="flex justify-center">
            {uniqueMonthsYears.map((monthYear) => (
              <button
                className={`p-4 lg:m-4 text-white rounded-[10px] mt-10 w-[25%] mx-auto ${
                  selectedMonthYear === monthYear
                    ? "bg-[#3E9F36]"
                    : "bg-gray-400"
                }`}
                key={monthYear}
                onClick={() => handleMonthYearClick(monthYear)}
              >
                Planning du mois de {monthYear}
              </button>
            ))}
          </div>

          {/* Step 4: Display Task Buttons */}
          <div className="flex justify-center">
            {filteredPlanningTasks.map((task) => (
              <button
                key={task._id}
                onClick={() => handleTaskClick(task._id, task.Team)}
                className="p-4 bg-[#3E9F36] lg:m-4 text-white rounded-[10px] mt-10 w-[25%]"
              >
                {`Planning du ${new Date(task.departureDate).toLocaleDateString(
                  "fr-FR"
                )} au ${new Date(task.returnDate).toLocaleDateString("fr-FR")}`}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ProTeamPlannings;
