import React, { useState, useRef  } from "react";
import LogoBig from "../../../Assets/Logo.svg"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import {
  validateEmail,
  validatePassword,
} from "../../../Components/FormValidation";
import { MdKeyboardArrowDown } from "react-icons/md";
import ReCAPTCHA from "react-google-recaptcha";

const UserRegister = () => {
  const formArray = [1, 2];
  const [formNo, setFormNo] = useState(formArray[0]);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [postale, setPostale] = useState("");
  const [ville, setVille] = useState("");
  const [zone, setZone] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [photoIdentite, setPhotoIdentite] = useState(null);
  const [typeDuBien, setTypeDuBien] = useState([]);
  const [produits, setProduits] = useState("");
  const [annéeInstallation, setAnnéeInstallation] = useState("");
  const [marque, setMarque] = useState("");
  const [référence, setRéférence] = useState("");
  const [photoDuProduit, setPhotoDuProduit] = useState(null);
  const [entretenu, setEntretenu] = useState("");
  const [donnéesBancaires, setDonnéesBancaires] = useState("");
  const [nomBancaires, setNomBancaires] = useState("");
  const [dateexpiration, setDateexpiration] = useState("");
  const [cvc, setCvc] = useState("");
  const [parrainage, setParrainage] = useState("");
  const [politique, setPolitique] = useState("");
  const [condition, setCondition] = useState("");
  const [actualités, setactualités] = useState(false);
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordCheckError, setPasswordCheckError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [BankError, setBankError] = useState("");
  const [entreprise, setentreprise] = useState("");
  const [tva, setTVA] = useState("");
  const [siret, setSiret] = useState("");
  const [raisonSocial, setRaisonSocial] = useState("");
  const [formeJuridique, setFormeJuridique] = useState("");
  const [registerError, setRegisterError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");
  const recaptchaRef = useRef();
  
  const apiUrl = 'https://lebontechnicien.net'


  const handleFormeJuridique = (event) => {
    setFormeJuridique(event.target.value);
  };

  const handletva = (event) => {
    setTVA(event.target.value);
  };

  const handleSiret = (event) => {
    setSiret(event.target.value);
  };

  const handleRaisonSocial = (event) => {
    setRaisonSocial(event.target.value);
  };

  const handleNom = (event) => {
    setNom(event.target.value);
  };

  const handlePrénom = (event) => {
    setPrenom(event.target.value);
  };

  const handleAdresse = (event) => {
    setAdresse(event.target.value);
  };

  const handlePostale = (event) => {
    setPostale(event.target.value);
  };

  const handleVille = (event) => {
    setVille(event.target.value);
  };

  const handlePasswordCheck = (event) => {
    setPasswordCheck(event.target.value);
    if (password !== event.target.value) {
      setPasswordCheckError("Les mots de passe ne correspondent pas.");
    } else {
      setPasswordCheckError(null);
    }
  };

  const handleTelephone = (event) => {
    setTelephone(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value.toLowerCase());
    if (!validateEmail(event.target.value.toLowerCase())) {
      setEmailError("Adresse e-mail invalide");
    } else {
      setEmailError(null);
    }
  };

  const conditionNames = [
    "au moins 10 caractères",
    "au moins une lettre majuscule",
    "au moins une lettre minuscule",
    "au moins un chiffre",
    "au moins un caractère spécial",
  ];

  const handlePassword = (event) => {
    setPassword(event.target.value);
    const conditions = [
      {
        condition: event.target.value.length >= 10,
        message: "Le mot de passe doit comporter au moins 10 caractères.",
      },
      {
        condition: /[A-Z]/.test(event.target.value),
        message: "Le mot de passe doit inclure au moins une lettre majuscule.",
      },
      {
        condition: /[a-z]/.test(event.target.value),
        message: "Le mot de passe doit inclure au moins une lettre minuscule.",
      },
      {
        condition: /\d/.test(event.target.value),
        message: "Le mot de passe doit inclure au moins un chiffre.",
      },
      {
        condition: /[!@#$%^&*(),.?":{}|<>]/.test(event.target.value),
        message: "Le mot de passe doit inclure au moins un caractère spécial.",
      },
    ];

    const allConditionsMet = conditions.every(
      (condition) => condition.condition
    );

    if (!allConditionsMet) {
      const conditionMessages = conditions
        .filter((condition) => !condition.condition)
        .map((condition) => condition.message);
      setPasswordError(conditionMessages);
    } else {
      setPasswordError(null);
    }
  };

  const handlePhotoIdentite = (event) => {
    setPhotoIdentite(event.target.files[0]);
  };

  const handleTypeDuBien = (event) => {
    const { value } = event.target;
    if (typeDuBien.includes(value)) {
      setTypeDuBien(typeDuBien.filter((c) => c !== value));
    } else {
      setTypeDuBien([...typeDuBien, value]);
    }
  };

  const handlePolitique = (event) => {
    const { value } = event.target;
    if (politique.includes(value)) {
      setPolitique(politique.filter((c) => c !== value));
    } else {
      setPolitique([...politique, value]);
    }
  };

  const handleCondition = (event) => {
    const { value } = event.target;
    if (condition.includes(value)) {
      setCondition(condition.filter((c) => c !== value));
    } else {
      setCondition([...condition, value]);
    }
  };

  const handleProduits = (event) => {
    const { value } = event.target;
    if (produits.includes(value)) {
      setProduits(produits.filter((c) => c !== value));
    } else {
      setProduits([...produits, value]);
    }
  };

  const handleAnnéeInstallation = (event) => {
    setAnnéeInstallation(event.target.value);
  };

  const handleMarque = (event) => {
    setMarque(event.target.value);
  };

  const handleRéférence = (event) => {
    setRéférence(event.target.value);
  };

  const handlePhotoDuProduit = (event) => {
    setPhotoDuProduit(event.target.files[0]);
  };

  const handleEntretenu = (event) => {
    setEntretenu(event.target.value);
  };

  const handleActualité = (event) => {
    setactualités(event.target.value);
  };

  const handleEntreprise = (event) => {
    setentreprise(event.target.value);
  };

  const handleDonnéesBancaires = (event) => {
    setDonnéesBancaires(event.target.value);
  };

  const handleDepartement = (event) => {
    setZone(event.target.value);
  };

  const handleNomBancaires = (event) => {
    setNomBancaires(event.target.value);
  };

  const handleCvc = (event) => {
    setCvc(event.target.value);
  };

  const handleDateexpiration = (event) => {
    setDateexpiration(event.target.value);
  };

  const handleParrainage = (event) => {
    setParrainage(event.target.value);
  };

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    /*if (!recaptchaValue) {
      alert("Please verify that you're a human");
      return;
    }*/
    const params = new URLSearchParams();

    params.append("photoIdentite", photoIdentite);
    params.append("photoDuProduit", photoDuProduit);
    params.append("nom", nom);
    params.append("prenom", prenom);
    params.append("adresse", adresse);
    params.append("postal", postale);
    params.append("ville", ville);
    params.append("telephone", telephone);
    params.append("email", email);
    params.append("password", password);
    params.append("typeDuBien", typeDuBien);
    params.append("produits", produits);
    params.append("marque", marque);
    params.append("référence", référence);
    //params.append("passwordCheck", passwordCheck);
    params.append("entretenu", entretenu);
    params.append("donnéesBancaires", donnéesBancaires);
    params.append("nomBancaires", nomBancaires);
    params.append("dateexpiration", dateexpiration);
    params.append("cvc", cvc);
    params.append("parrainage", parrainage);
    params.append("annéeInstallation", annéeInstallation);
    params.append("politique", politique);
    params.append("condition", condition);
    params.append("actualités", actualités);
    params.append("entreprise", entreprise);
    params.append("tva", tva);
    params.append("siret", siret);
    params.append("raisonSocial", raisonSocial);
    params.append("formeJuridique", formeJuridique);
    params.append("zone", zone);
    params.append("recaptcha", recaptchaValue);
    params.append("profile", "Unfinished");


    fetch(`${apiUrl}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: params.toString(),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data !== "Email already in use") {
          console.log("success");
          window.location.reload();
          window.location.href = "/confirmation";
        } else {
          if (data === "Email already in use") {
            setRegisterError("Cette adresse e-mail est déjà utilisée");
          } else {
            setError(data);
            console.log(error);
          }
        }
      });
  };

  console.log("email && password && politique.length > 0 && condition.length > 0", email && password && politique.length > 0 && condition.length > 0)


  const finalSubmit = () => {
    let missingInputs = [];
    if (!email) {
      missingInputs.push("email");
    }
    if (!password) {
      missingInputs.push("Mot De Passe");
    }
    if (politique.length === 0) {
      missingInputs.push("politique");
    }
    if (condition.length === 0) {
      missingInputs.push("condition");
    }
    if (email && password && politique.length > 0 && condition.length > 0) {
      //toast.success("formulaire soumis avec succès");
      handleSubmit();
    } else {
      let errorMessage =
        "Veuillez remplir tous les champs de saisie: " +
        missingInputs.join(", ");
      toast.error(errorMessage);
    }
  };

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 login-wrapper">
      <ToastContainer />
        <div className="w-full max-w-md space-y-8">
          <div>
            <Link to="https://lebontechnicien.fr/">
              <img className="mx-auto h-20 w-auto" src={LogoBig} alt="Logo" />
            </Link>
            <h2 className="mt-6 text-center font-bold tracking-tight authentifciation-text ">
              Bonjour. Entrez vos informations d'identification pour créer votre
              compte
            </h2>
          </div>
          <div>
            <div>
              <input type="hidden" name="remember" defaultValue="true" />
              <div>
                <label
                  htmlFor="email"
                  className="margin-top block text-sm font-medium text-gray-700"
                >
                  Email <span className="text-red-500"> * </span>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="mb-4 relative p-16 block w-full appearance-none rounded rounded-lg border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
                  placeholder="Email"
                  style={{ marginTop: "16px" }}
                />
                {emailError && email.length > 0 && (
                  <div style={{ color: "red" }}>{emailError}</div>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="margin-top block text-sm font-medium text-gray-700"
                >
                  mot de passe <span className="text-red-500"> * </span>
                </label>
                <input
                  value={password}
                  onChange={handlePassword}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="mb-4 relative p-16 block w-full appearance-none rounded rounded-lg border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
                  placeholder="Mot de passe"
                />
                {passwordError && passwordError.length > 0 && (
                  <div className="text-red-500">
                    {passwordError.map((message, index) => (
                      <div key={index}> - {message}</div>
                    ))}
                  </div>
                )}

                {!passwordError && (
                  <div className="text-green-500">
                    Tous les critères sont respectés.
                  </div>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="margin-top block text-sm font-medium text-gray-700"
                >
                  Retaper le mot de passe{" "}
                  <span className="text-red-500"> * </span>
                </label>
                <input
                  value={passwordCheck}
                  onChange={handlePasswordCheck}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="mb-4 relative p-16 block w-full appearance-none rounded rounded-lg border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Mot de passe"
                />
                {passwordCheckError && passwordCheck.length > 0 && (
                  <div style={{ color: "red" }}>{passwordCheckError}</div>
                )}
              </div>
              <div className="-space-y-px rounded-md ">
                <div>
                  <div className="mt-8">
                    <label
                      htmlFor="parrainage"
                      className="block text-sm font-medium text-gray-600  mb-4"
                    >
                      Parrainage
                    </label>
                    <div className="mt-1">
                      <input
                        id="parrainage"
                        name="parrainage"
                        type="text"
                        className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-green-500 focus:ring-green-500 sm:text-sm p-4"
                        placeholder="John Doe"
                        value={parrainage}
                        onChange={handleParrainage}
                      />
                    </div>
                  </div>
                  <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                      <input
                        id="politique-de-confidentialité"
                        name="politique"
                        type="checkbox"
                        required="required"
                        value="Politique de confidentialité"
                        checked={politique.includes(
                          "Politique de confidentialité"
                        )}
                        onChange={handlePolitique}
                        className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                      />
                    <label
                      htmlFor="push-email"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      J'ai lu et accepté la Politique de confidentialité{" "}
                      <span className="text-red-500"> * </span>
                      <a
                        href="/politique-confidentialite"
                        className="text-gray-700"
                      >
                        (voir la politique de confidentialité)
                      </a>
                    </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="condition-générale-de-vente"
                        name="condition"
                        required="required"
                        type="checkbox"
                        value="Condition générale de vente"
                        checked={condition.includes(
                          "Condition générale de vente"
                        )}
                        onChange={handleCondition}
                        className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                      />
                    <label
                      htmlFor="push-nothing"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      J'ai lu et accepté les conditions générales de vente{" "}
                      <span className="text-red-500"> * </span>
                      <a href="/cgv" className="text-gray-700">
                        (voir les CGV)
                      </a>
                    </label>
                    </div>
                  </div>
                  <fieldset className="mb-6 margin-top">
                    <legend className="contents text-sm font-medium text-gray-600">
                      Recevoir les actualités des mise à jours de l'application
                    </legend>
                    <div className="flex gap-4">
                      <div className="flex items-center">
                        <input
                          id="oui"
                          name="actualités"
                          type="radio"
                          value="oui"
                          onChange={handleActualité}
                          checked={actualités === "oui"}
                          className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                        />
                        <label
                          htmlFor="oui"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Oui
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="non"
                          name="actualités"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                          value="non"
                          onChange={handleActualité}
                          checked={actualités === "non"}
                        />
                        <label
                          htmlFor="non"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Non
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  {registerError && registerError.length > 0 && (
                    <p style={{ color: "red" }}>{registerError}</p>
                  )}
                </div>
              </div>

              <ReCAPTCHA
                sitekey={"6Lf7zDcmAAAAACbjss7kOwFrucc3Z9ou3T8Yjk6T"}
                onChange={handleRecaptcha}
              />              
              <div className="mt-4 flex justify-center items-center">
                <button
                  onClick={finalSubmit}
                  className="submit-button px-3 py-2 text-lg rounded-md w-full text-white bg-blue-500"
                >
                  S'inscrire
                </button>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default UserRegister;
