import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { ToastContainer, toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  IbanElement,
} from "@stripe/react-stripe-js";
import {
  validateFrenchTVAInput,
  validatePostalCodeInput,
  validateSiretSirenInput,
  validateTelephoneInput,
} from "../../../Components/FormValidation";
import { Domaines, Départements } from "../../../Constants/dummy";
import { DebounceInput } from "react-debounce-input";
import Autocomplete from "react-google-autocomplete";

const stripePromise = loadStripe("YOUR_STRIPE_PUBLISHABLE_KEY");

const UserInfo = ({ history }) => {
  const { userType } = useStateContext();
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [postale, setPostale] = useState("");
  const [ville, setVille] = useState("");
  const [zone, setZone] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [photoIdentite, setPhotoIdentite] = useState(null);
  const [typeDuBien, setTypeDuBien] = useState([]);
  const [settedResult, setResult] = useState("");
  const [rib, setRib] = useState("");
  const [donnéesBancaires, setDonnéesBancaires] = useState("");
  const [nomBancaires, setNomBancaires] = useState("");
  const [dateexpiration, setDateexpiration] = useState("");
  const [cvc, setCvc] = useState("");
  const [entreprise, setentreprise] = useState("");
  const [tva, setTVA] = useState("");
  const [siret, setSiret] = useState("");
  const [raisonSocial, setRaisonSocial] = useState("");
  const [formeJuridique, setFormeJuridique] = useState("");
  const [propos, setPropos] = useState("");
  const [banque, setBanque] = useState("");
  const [bic, setBic] = useState("");
  const [iban, setIban] = useState("");
  const [domaine, setDomaine] = useState([]);
  const [qualification, setQualification] = useState(null);
  const [assuranceImg, setAssuranceImg] = useState(null);
  const [qualificationImg, setQualificationImg] = useState(null);
  const [kabisImg, setKabisImg] = useState(null);
  const [ribImg, setRibImg] = useState(null);
  const [assurance, setAssurance] = useState("");
  const [nassurance, setNassurance] = useState("");
  const [error, setError] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [telephoneError, setTelephoneError] = useState("");
  const [postaleError, setPostaleError] = useState("");
  const [siretError, setSiretError] = useState("");
  const [tvaError, setTVAError] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [resultMessage, setResultMessage] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profile, setProfile] = useState("");
  const apiUrl = 'https://lebontechnicien.net'

  const handleTypeSelection = (type) => {
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const handlePostaleChange = (event) => {
    const value = event.target.value;
    setPostale(value);
    setPostaleError(validatePostalCodeInput(value));
  };

  const handleTelephoneChange = (event) => {
    const value = event.target.value;
    setTelephone(value);
    setTelephoneError(validateTelephoneInput(value));
  };

  const handleSiretChange = (e) => {
    const value = e.target.value;
    setSiret(value);
    setSiretError(validateSiretSirenInput(value));
  };

  const handleTVAChange = (e) => {
    const value = e.target.value;
    setTVA(value);
    setTVAError(validateFrenchTVAInput(value));
  };

  const IBAN_STYLE = {
    base: {
      color: "#32325d",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      ":-webkit-autofill": {
        color: "#32325d",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
      ":-webkit-autofill": {
        color: "#fa755a",
      },
    },
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: "FR",
    style: IBAN_STYLE,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIban(elements.getElement(IbanElement));

    const result = await stripe.confirmSepaDebitSetup(
      sessionStorage.getItem("clientSecret"),
      {
        payment_method: {
          type: "sepa_debit",
          sepa_debit: iban,
          billing_details: {
            name: nom,
            email: sessionStorage.getItem("email"),
            address: {
              city: ville,
              line1: adresse,
              postal_code: postale,
              state: zone,
              country: "FR",
            },
            phone: telephone,
          },
        },
      }
    );

    if (result.error) {
      // Show error to your customer.
      setResult(result.error);
      setResultMessage(
        <span style={{ color: "red" }}>{result.error.message}</span>
      );
    } else {
      // Show a confirmation message to your customer.
      // The SetupIntent is in the 'succeeded' state.
      console.log("sucess", result);
      setResult(result);
      setResultMessage(
        <span style={{ color: "green" }}>Payment setup succeeded.</span>
      );
    }
  };

  const handleResult = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const requestData = {
      payment_method: settedResult,
      email: sessionStorage.getItem("email"),
    };

    fetch(`${apiUrl}/wallet/result`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("success");
          //window.location.replace("/tableau-de-bord");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
  };

  /*const handleRegionChange = (event) => {
    const region = event.target.value;
    if (event.target.checked) {
      setSelectedRegions([...selectedRegions, region]);
    } else {
      setSelectedRegions(selectedRegions.filter((r) => r !== region));
    }
  };*/

  const handleTypeDuBien = (event) => {
    const { value } = event.target;
    if (typeDuBien.includes(value)) {
      setTypeDuBien(typeDuBien.filter((c) => c !== value));
    } else {
      setTypeDuBien([...typeDuBien, value]);
    }
  };

  const handleDomaine = (event) => {
    const { value } = event.target;
    if (domaine.includes(value)) {
      setDomaine(domaine.filter((c) => c !== value));
    } else {
      setDomaine([...domaine, value]);
    }
  };

  const handleRegionChange = (event) => {
    const { value } = event.target;
    if (selectedRegions.includes(value)) {
      setSelectedRegions(selectedRegions.filter((c) => c !== value));
    } else {
      setSelectedRegions([...selectedRegions, value]);
    }
  };

  const handlequalification = (event) => {
    setQualification(event.target.files);
  };

  const handleAssuranceImg = (event) => {
    setAssuranceImg(event.target.files);
  };

  const handleQualificationImgs = (event) => {
    setQualificationImg(event.target.files);
  };

  const handleKabisImg = (event) => {
    setKabisImg(event.target.files);
  };

  const handleRibImgs = (event) => {
    setRibImg(event.target.files);
  };

  const handlePhotoIdentite = (event) => {
    setPhotoIdentite(event.target.files);
  };

  const handleUserDataFetch = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${apiUrl}/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: `${sessionStorage.getItem("email")}`,
        }),
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.msg);
      }

      setProfile(data?.profile);
      if (data && data.profile) {
        setProfile(data.profile);
        sessionStorage.setItem("profile", data.profile);
      }

      if (data && data.entreprise) {
        sessionStorage.setItem("entreprise", data.entreprise);
      }

      if (data && data.status) {
        sessionStorage.setItem("typetech", data.status);
      }

      if (data && data.telephone) {
        sessionStorage.setItem("phone", data.telephone);
      }

      if (data && data.adresse) {
        sessionStorage.setItem("adresse", data.adresse);
      }

      if (data && data.postale) {
        sessionStorage.setItem("zipcode", data.postale);
      }

      if (data && data.raisonsocial) {
        sessionStorage.setItem("raisonsocial", data.raisonsocial);
      }

      if (data && data.raison) {
        sessionStorage.setItem("raison", data.raison);
      }

      if (data && data.domaine) {
        sessionStorage.setItem("domaine", data.domaine);
      }

      if (data && data.siret) {
        sessionStorage.setItem("siret", data.siret);
      }
    } catch (err) {
      setError(err.message);
    }
    setIsLoading(false);
    window.location.href = "/tableau-de-bord";
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${apiUrl}/logout`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data);
      }
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("requests");
      sessionStorage.removeItem("profile");
      sessionStorage.removeItem("entreprise");
      sessionStorage.clear();
      setIsLoading(false);
      console.log(data.msg);
      setIsLoggedOut(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    window.location.href = "/connexion";
  };

  const handleClickUser = async () => {
    const formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("finish_update", "Done");
    formData.append("profile", "Finished");
    formData.append("adresse", adresse);
    formData.append("placeId", placeId);
    formData.append("postale", postale);
    formData.append("ville", ville);
    formData.append("telephone", telephone);
    formData.append("email", sessionStorage.getItem("email"));
    formData.append("entreprise", entreprise);
    formData.append("tva", tva);
    formData.append("siret", siret);
    formData.append("raisonSocial", raisonSocial);
    formData.append("formeJuridique", formeJuridique);
    formData.append("zone", zone);
    formData.append("typeDuBien", typeDuBien);
    if (entreprise === "oui") {
      formData.append("typeclient", "clientpro");
    } else {
      formData.append("typeclient", "clientparticulier");
    }
    formData.append("propos", propos);
    if (ribImg && ribImg.length) {
      formData.append("ribImg", ribImg);
      for (let i = 0; i < ribImg.length; i++) {
        formData.append("ribImg", ribImg[i]);
      }
    }
    if (ribImg && ribImg.length) {
      formData.append("ribImgName", ribImg.name);
    }
    if (photoIdentite && photoIdentite.length) {
      formData.append("photoIdentiteName", photoIdentite.name);
    }
    if (photoIdentite && photoIdentite.length) {
      formData.append("photoIdentite", photoIdentite);
      for (let i = 0; i < photoIdentite.length; i++) {
        formData.append("photoIdentite", photoIdentite[i]);
      }
    }

    console.log("formdata", formData);
    let isValid = true;
    const requiredFields = [
      "entreprise",
      "nom",
      "prenom",
      "telephone",
      "zone",
      "adresse",
      "postale",
    ];
    // Perform form validation
    for (const fieldName of requiredFields) {
      if (!formData.get(fieldName)) {
        isValid = false;
        const input = document.querySelector(`[name="${fieldName}"]`);
        input.style.borderColor = "red";
        if (input.type === "checkbox" || input.type === "radio") {
          const label = input.closest("label");
          if (label) {
            const legend = input.parentNode.querySelector("legend");
            if (legend) {
              legend.style.color = "red";
            }
          }
        } else {
          const errorText = document.createElement("p");
          errorText.innerText = `Veuillez remplir ce champ.\n`;
          errorText.style.color = "red";
          input.parentNode.insertBefore(errorText, input);
          input.parentNode.insertBefore(
            document.createElement("br"),
            input.nextSibling
          );
        }
      } else {
        const input = document.querySelector(`[name="${fieldName}"]`);
        input.style.borderColor = "initial";
        const label = input.closest("label");
        if (input.type === "checkbox" || input.type === "radio") {
          if (label) {
            label.style.color = "red";
          }
        } else {
          if (input.value === "") {
            const errorText = input.previousElementSibling;
            if (
              !errorText ||
              !errorText.innerText.startsWith("Veuillez remplir ce champ.")
            ) {
              const newErrorText = document.createElement("span");
              newErrorText.innerText = "Veuillez remplir ce champ.";
              newErrorText.style.color = "red";
              input.parentNode.insertBefore(newErrorText, input);
            }
            input.style.borderColor = "red";
          } else {
            const errorText = input.previousElementSibling;
            if (
              errorText &&
              errorText.innerText.startsWith("Veuillez remplir ce champ.")
            ) {
              errorText.remove();
              input.parentNode.removeChild(input.parentNode.lastChild);
            }
          }
        }
      }
    }

    // If form is invalid, return early
    if (!isValid) {
      return;
    }

    const response = await fetch(`${apiUrl}/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    });
    const data = await response.json();
    if (data) {
      toast.success(data);
      console.log("UserInfo Profile", data.profile);
    } else if (data.msg === "No such User") {
      toast.error(data.msg);
    }
  };

  const handleClickTech = async () => {
    sessionStorage.getItem("typetech");
    const formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("adresse", adresse);
    formData.append("placeId", placeId);
    formData.append("postale", postale);
    formData.append("ville", ville);
    formData.append("zone", selectedRegions);
    formData.append("domaine", selectedTypes);
    formData.append("telephone", telephone);
    formData.append("email", sessionStorage.getItem("email"));
    formData.append("entreprise", entreprise);
    formData.append("tva", tva);
    formData.append("siret", siret);
    formData.append("raisonSocial", raisonSocial);
    formData.append("formeJuridique", formeJuridique);
    formData.append("finish_update", "Done");
    formData.append("ProTech", "non");
    formData.append("nassurance", nassurance);
    formData.append("assurance", assurance);
    formData.append("propos", propos);
    formData.append("banque", banque);
    formData.append("iban", iban);
    formData.append("bic", bic);
    formData.append("profile", "Finished");
    formData.append("email", sessionStorage.getItem("email"));

    if (assuranceImg && assuranceImg.length > 0) {
      for (let i = 0; i < assuranceImg.length; i++) {
        formData.append("assuranceImg", assuranceImg[i]);
      }
    }

    if (qualificationImg && qualificationImg.length > 0) {
      for (let i = 0; i < qualificationImg.length; i++) {
        formData.append("qualificationImg", qualificationImg[i]);
      }
    }

    if (kabisImg && kabisImg.length > 0) {
      for (let i = 0; i < kabisImg.length; i++) {
        formData.append("kabisImg", kabisImg[i]);
      }
    }

    if (ribImg && ribImg.length > 0) {
      for (let i = 0; i < ribImg.length; i++) {
        formData.append("ribImg", ribImg[i]);
      }
    }

    if (photoIdentite && photoIdentite.length) {
      formData.append("photoIdentiteName", photoIdentite.name);
    }

    if (photoIdentite && photoIdentite.length) {
      formData.append("photoIdentite", photoIdentite);
      for (let i = 0; i < photoIdentite.length; i++) {
        formData.append("photoIdentite", photoIdentite[i]);
      }
    }

    let isValid = true;
    const requiredFields = [
      "nom",
      "prenom",
      "zone",
      "domaine",
      "adresse",
      "siret",
      "raisonSocial",
      "postale",
    ];

    // Perform form validation
    for (const fieldName of requiredFields) {
      if (!formData.get(fieldName)) {
        isValid = false;
        const input = document.querySelector(`[name="${fieldName}"]`);
        if (input) {
          input.style.borderColor = "red";
          if (input.type === "checkbox" || input.type === "radio") {
            const label = input.closest("label");
            if (label) {
              const legend = input.parentNode.querySelector("legend");
              if (legend) {
                legend.style.color = "red";
              }
            }
          } else {
            const errorText = document.createElement("p");
            errorText.innerText = `Veuillez remplir ce champ.\n`;
            errorText.style.color = "red";
            input.parentNode.insertBefore(errorText, input);
            input.parentNode.insertBefore(
              document.createElement("br"),
              input.nextSibling
            );
          }
        }
      } else {
        const input = document.querySelector(`[name="${fieldName}"]`);
        if (input) {
          input.style.borderColor = "initial";
          const label = input.closest("label");
          if (input.type === "checkbox" || input.type === "radio") {
            if (label) {
              label.style.color = "red";
            }
          } else {
            if (input.value === "") {
              const errorText = input.previousElementSibling;
              if (
                !errorText ||
                !errorText.innerText.startsWith("Veuillez remplir ce champ.")
              ) {
                const newErrorText = document.createElement("span");
                newErrorText.innerText = "Veuillez remplir ce champ.";
                newErrorText.style.color = "red";
                input.parentNode.insertBefore(newErrorText, input);
              }
              input.style.borderColor = "red";
            } else {
              const errorText = input.previousElementSibling;
              if (
                errorText &&
                errorText.innerText.startsWith("Veuillez remplir ce champ.")
              ) {
                errorText.remove();
                input.parentNode.removeChild(input.parentNode.lastChild);
              }
            }
          }
        }
      }
    }

    // If form is invalid, return early
    if (!isValid) {
      return;
    }

    //const email = sessionStorage.getItem("email");

    const response = await fetch(`${apiUrl}/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    });
    const data = await response.json();
    console.log("data.msg", data.msg);
    if (data.msg !== "No such User") {
      if (data.status === "Confirmed") {
        toast.success(data);
        console.log("UserInfo Profile", data.profile);
        window.location.href = "/recherche";
      } else if (data.status === "Pending") {
        setConfirmationModal(true);
      }
    } else if (data.msg === "No such User") {
      toast.error(data.msg);
    }
  };

  async function handleOnClick() {
    await handleClickUser();
    setTimeout(function () {
      handleUserDataFetch();
    }, 1000);
  }

  function handleModalClick() {
    setConfirmationModal(false);
    handleLogout();
  }

  return (
    <>
      <h2 className="mt-6 mb-10 text-center font-bold tracking-tight authentifciation-text max-w-screen-xl  ml-auto mr-auto">
        Bonjour. Entrez vos informations d'identification
      </h2>

      {/*<div className="w-[92%] ml-auto mr-auto p-10">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Profile
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Ces informations seront affichées publiquement, alors faites
                attention à ce que vous partagez.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div>
                  <label
                    htmlFor="propos"
                    className="block text-sm font-medium text-gray-700"
                  >
                    À propos
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="propos"
                      name="propos"
                      rows={3}
                      value={propos}
                      onChange={(event) => setPropos(event.target.value)}
                      className="p-6 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      placeholder=""
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    Brève description de votre profil.
                  </p>
                </div>
                <div>
                  <label
                    className="block text-sm font-medium text-gray-700"
                    style={{ marginTop: "16px" }}
                  >
                    Photo du d'identité / Logo
                  </label>
                  {photoIdentite && (
                    <div className="mt-1">
                      {Array.from(photoIdentite).map((file) => (
                        <div key={file.name} className="flex items-center">
                          <span className="font-medium text-green-600">
                            {file.name}
                          </span>
                        </div>
                      ))}
                      <button
                        className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        onClick={() => setPhotoIdentite(null)}
                      >
                        Change
                      </button>
                    </div>
                  )}
                  {!photoIdentite && (
                    <div className="mt-1 flex justify-center rounded-md border-1  border-gray-300 px-6 pt-5 pb-6">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="photoIdentite"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                          >
                            <span>Upload a file</span>
                            <input
                              id="photoIdentite"
                              name="photoIdentite"
                              type="file"
                              multiple
                              className="sr-only"
                              value=""
                              onChange={handlePhotoIdentite}
                            />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="hidden sm:block w-[92%] ml-auto mr-auto p-10"
        aria-hidden="true"
      >
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
                  </div>*/}

      <div className="w-[92%] ml-auto mr-auto p-10 mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Informations personnelles
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Les informations personnelles" concernent vos détails
                personnels, et en affichant votre "profil", soyez conscient de
                ce que vous partagez publiquement.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            {userType === "User" ? (
              <div className="overflow-hidden shadow sm:rounded-md">
                <fieldset className="mb-6 margin-top  p-4">
                  <legend className="contents text-sm font-medium text-gray-600">
                    Etes vous une entreprise
                    <span className="text-red-500"> * </span>
                  </legend>
                  <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                      <input
                        id="entreprise"
                        name="entreprise"
                        type="radio"
                        value="oui"
                        onChange={(event) => setentreprise(event.target.value)}
                        checked={entreprise === "oui"}
                        className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                      />
                      <label
                        htmlFor="entreprise"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Oui
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="entreprise"
                        name="entreprise"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                        value="non"
                        onChange={(event) => setentreprise(event.target.value)}
                        checked={entreprise === "non"}
                      />
                      <label
                        htmlFor="entreprise"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Non
                      </label>
                    </div>
                  </div>
                  {entreprise === "oui" && (
                    <div className="mt-5">
                      {userType === "Tech" && (
                        <div className="mb-5">
                          <label className="block text-sm font-medium text-gray-700 mb-4">
                            Forme juridique de l’entreprise
                          </label>
                          <select
                            className="p-4 mt-1 block w-full rounded-md border border-1 border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                            name="formeJuridique"
                            onChange={(event) =>
                              setFormeJuridique(event.target.value)
                            }
                          >
                            <option value="">
                              sélectionner le formulaire juridique
                            </option>
                            <option value="EI">
                              EI - Entrepreneur individuel
                            </option>
                            <option value="EURL">
                              EURL - Entreprise unipersonnelle à responsabilité
                              limitée
                            </option>
                            <option value="SARL">
                              SARL - Société à responsabilité limitée
                            </option>
                            <option value="SASU">
                              SASU - Société par actions simplifiée
                              unipersonnelle
                            </option>
                            <option value="SAS">
                              SAS - Société par actions simplifiée
                            </option>
                            <option value="SA">SA - Société anonyme</option>
                            <option value="SNC">
                              SNC - Société en nom collectif
                            </option>
                            <option value="SCS">
                              SCS - Société en commandite simple
                            </option>
                            <option value="SCA">
                              SCA - Société en commandite par actions
                            </option>
                          </select>
                        </div>
                      )}
                      <div className="mb-5">
                        <label className="block text-sm font-medium text-gray-700 mb-4">
                          Raison sociale(nom de l’entreprise){" "}
                          <span className="text-red-500"> * </span>
                        </label>
                        <input
                          type="text"
                          className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm "
                          name="raisonSocial"
                          id="raisonSocial"
                          required
                          onChange={(event) =>
                            setRaisonSocial(event.target.value)
                          }
                          value={raisonSocial}
                        />
                      </div>
                      {/*<div className="mb-5">
                        <label className="block text-sm font-medium text-gray-700 mb-4">
                          SIRET ou SIREN
                        </label>
                        <input
                          type="text"
                          className="p-4 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                          name="siret"
                          id="siret"
                          required
                          onChange={(event) => setSiret(event.target.value)}
                          value={siret}
                        />
                        </div>*/}
                      <div className="mb-5">
                        <label className="block text-sm font-medium text-gray-700 mb-4">
                          Numéro TVA
                        </label>
                        <input
                          type="text"
                          className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm "
                          name="tva"
                          required
                          id="tva"
                          onChange={handleTVAChange}
                          value={tva}
                        />
                        {tvaError && (
                          <div className="text-red-500">{tvaError}</div>
                        )}
                      </div>
                    </div>
                  )}
                </fieldset>
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="nom"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nom <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        value={nom}
                        onChange={(event) => setNom(event.target.value)}
                        name="nom"
                        id="nom"
                        required
                        autoComplete="given-name"
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="prenom"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prénom <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        name="prenom"
                        value={prenom}
                        onChange={(event) => setPrenom(event.target.value)}
                        required
                        id="prenom"
                        autoComplete="prenom"
                        className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="telephone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Téléphone: <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        name="telephone"
                        value={telephone}
                        onChange={handleTelephoneChange}
                        required
                        id="telephone"
                        autoComplete="telephone"
                        className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                      {telephoneError && (
                        <div className="text-red-500">{telephoneError}</div>
                      )}
                    </div>
                    <div className="col-span-6 sm:col-span-3">    
                    {entreprise === "non" && (
                        <div>
                          <label
                            htmlFor="iban"
                            className="block text-sm font-medium text-gray-700"
                          >
                            IBAN: <span className="text-red-500"> * </span>
                            <IbanElement
                              name="iban"
                              required
                              className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                              options={IBAN_ELEMENT_OPTIONS}
                            />
                          </label>
                      </div>
                    )}
                    </div>
                    {entreprise === "non" && (
                      <>
                        <div className="col-span-2 sm:col-span-6">
                          <div className="mandate-acceptance  mb-4 text-[12px] mt-4 ">
                            <span className="text-red-500"> * </span> En
                            fournissant vos informations de paiement et en
                            confirmant ce paiement, vous autorisez (A) Rocket
                            Rides et Stripe, notre prestataire de services de
                            paiement et/ou PPRO, son prestataire de services
                            local, à envoyer des instructions à votre banque
                            pour débiter votre compte et (B) votre banque à
                            débiter votre compte conformément à ces
                            instructions. Vous avez, entre autres, le droit de
                            vous faire rembourser par votre banque selon les
                            modalités et conditions du contrat conclu avec votre
                            banque. La demande de remboursement doit être
                            soumise dans un délai de 8 semaines à compter de la
                            date à laquelle votre compte a été débité. Vos
                            droits sont expliqués dans une déclaration
                            disponible auprès de votre banque. Vous acceptez de
                            recevoir des notifications des débits à venir dans
                            les 2 jours précédant leur réalisation.
                          </div>
                          <button
                            onClick={handleSubmit}
                            className=" userProfile-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Confirmer votre IBAN
                          </button>
                          <div style={{ marginTop: "20px" }}>
                            {resultMessage ? (
                              resultMessage
                            ) : (
                              <span
                                className="text-[13px]"
                                style={{ color: "red" }}
                              >
                                veuillez confirmer votre IBAN
                              </span>
                            )}
                          </div>
                        </div>

                        <p className="text-[12px] mt-4 text-red-500">
                          <span className="text-red-500"> * </span> Champ
                          Obligatoire{" "}
                        </p>
                      </>
                    )}

                    <div className="col-span-6">
                      <label
                        htmlFor="zone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Départements: <span className="text-red-500"> * </span>
                      </label>
                      <select
                        id="zone"
                        name="zone"
                        onChange={(event) => setZone(event.target.value)}
                        required
                        autoComplete="zone"
                        className="p-4 mt-1 border-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                      >
                        {Départements.map((département) => (
                          <option
                            value={département.value}
                            key={département.value}
                          >
                            {département.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="adresse"
                        className="block text-sm font-medium text-gray-700"
                      >
                        N° et Nom de rue{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      {/*<input
                        value={adresse}
                        type="text"
                        name="adresse"
                        id="adresse"
                        onChange={(event) => setAdresse(event.target.value)}
                        required
                        autoComplete="adresse"
                        className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                          />*/}
                      <Autocomplete
                        className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        apiKey="AIzaSyDK4L8SN7bnmjDstRZxqkqitqyQufbopLs"
                        name="adresse"
                        id="adresse"
                        options={{
                          types: ["geocode", "establishment"],
                        }}
                        onPlaceSelected={(place) => {
                          console.log(place);
                          const address = place.formatted_address;
                          console.log("Selected address:", place);
                          const latitude = place.geometry.location.lat();
                          const longitude = place.geometry.location.lng();
                          const placeIds = place.place_id;
                          setAdresse(address);
                          setPlaceId(placeIds);
                        }}
                      />
                    </div>

                    {/*<div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="ville"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Ville <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        value={ville}
                        onChange={(event) => setVille(event.target.value)}
                        name="ville"
                        required
                        id="ville"
                        autoComplete="ville"
                        className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                      </div>*/}

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postale"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Code Postale: <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        name="postale"
                        value={postale}
                        onChange={handlePostaleChange}
                        required
                        id="postale"
                        autoComplete="postale"
                        className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                      {postaleError && (
                        <p style={{ color: "red" }}>{postaleError}</p>
                      )}
                    </div>
                  </div>
                  {/*<div className="mt-4 mb-2">
                    <fieldset className="mt-6">
                      <legend className="contents text-sm font-medium text-gray-700 mt-6">
                        Type de préstation
                        <span className="text-red-500"> * </span>
                      </legend>
                      <div className="mt-4 space-y-4 mb-4">
                        <div className="flex items-center">
                          <input
                            id="typeDuBien"
                            name="typeDuBien"
                            type="checkbox"
                            value="appartement"
                            checked={typeDuBien.includes("appartement")}
                            onChange={handleTypeDuBien}
                            className="h-4 w-4 border-1 border-gray-300 text-green-600 focus:ring-green-500"
                          />
                          <label
                            htmlFor="appartement"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            appartement
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="typeDuBien"
                            name="typeDuBien"
                            type="checkbox"
                            value="maison"
                            checked={typeDuBien.includes("maison")}
                            onChange={handleTypeDuBien}
                            className="h-4 w-4 border-1 border-gray-300 text-green-600 focus:ring-green-500"
                          />
                          <label
                            htmlFor="maison"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            maison
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="typeDuBien"
                            name="typeDuBien"
                            type="checkbox"
                            value="local"
                            checked={typeDuBien.includes("local")}
                            onChange={handleTypeDuBien}
                            className="h-4 w-4 border-1 border-gray-300 text-green-600 focus:ring-green-500"
                          />
                          <label
                            htmlFor="local"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            local
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="typeDuBien"
                            name="typeDuBien"
                            type="checkbox"
                            value="autres"
                            checked={typeDuBien.includes("autres")}
                            onChange={handleTypeDuBien}
                            className="h-4 w-4 border-1 border-gray-300 text-green-600 focus:ring-green-500"
                          />
                          <label
                            htmlFor="autres"
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            autres
                          </label>
                        </div>
                      </div>
                    </fieldset>
                        </div>*/}
                </div>
                {/* Button */}
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    onClick={handleOnClick}
                    className=" userProfile-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Sauvegarder
                  </button>
                  <button
                    onClick={handleLogout}
                    className=" userProfiledelete-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Se déconnecter
                  </button>
                </div>
              </div>
            ) : (
              <div className="overflow-hidden shadow sm:rounded-md">
                <fieldset className=" margin-top  ">
                  <div className="">
                    {/*<div className="mb-5">
                      <label className="block text-sm font-medium text-gray-700 mb-4">
                        Forme juridique de l’entreprise
                      </label>
                      <select
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        name="formeJuridique"
                        onChange={(event) =>
                          setFormeJuridique(event.target.value)
                        }
                      >
                        <option value="">
                          sélectionner le formulaire juridique
                        </option>
                        <option value="EI">EI - Entrepreneur individuel</option>
                        <option value="EURL">
                          EURL - Entreprise unipersonnelle à responsabilité
                          limitée
                        </option>
                        <option value="SARL">
                          SARL - Société à responsabilité limitée
                        </option>
                        <option value="SASU">
                          SASU - Société par actions simplifiée unipersonnelle
                        </option>
                        <option value="SAS">
                          SAS - Société par actions simplifiée
                        </option>
                        <option value="SA">SA - Société anonyme</option>
                        <option value="SNC">
                          SNC - Société en nom collectif
                        </option>
                        <option value="SCS">
                          SCS - Société en commandite simple
                        </option>
                        <option value="SCA">
                          SCA - Société en commandite par actions
                        </option>
                      </select>
                    </div>
                    <div className="mb-5">
                      <label className="block text-sm font-medium text-gray-700 mb-4">
                        Raison sociale
                      </label>
                      <input
                        type="text"
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        name="raisonSocial"
                        id="raisonSocial"
                        onChange={(event) =>
                          setRaisonSocial(event.target.value)
                        }
                        value={raisonSocial}
                      />
                      </div>
                    <div className="mb-5">
                      <label className="block text-sm font-medium text-gray-700 mb-4">
                        SIRET ou SIREN
                      </label>
                      <input
                        type="text"
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        name="siret"
                        id="siret"
                        onChange={handleSiretChange}
                        value={siret}
                      />
                      {siretError && (
                        <div className="text-red-500">{siretError}</div>
                      )}
                    </div>

                    <div className="mb-5">
                      <label className="block text-sm font-medium text-gray-700 mb-4">
                        Numéro TVA
                      </label>
                      <input
                        type="text"
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        name="tva"
                        id="tva"
                        onChange={handleTVAChange}
                        value={tva}
                      />
                      {tvaError && (
                        <div className="text-red-500">{tvaError}</div>
                      )}
                    </div>*/}
                  </div>
                </fieldset>
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="nom"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nom du gérant:
                        <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        value={nom}
                        onChange={(event) => setNom(event.target.value)}
                        name="nom"
                        id="nom"
                        required
                        autoComplete="given-name"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="prenom"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prénom du gérant:{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        name="prenom"
                        value={prenom}
                        onChange={(event) => setPrenom(event.target.value)}
                        required
                        id="prenom"
                        autoComplete="prenom"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="prenom"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Raison sociale:{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        name="raisonSocial"
                        id="raisonSocial"
                        onChange={(event) =>
                          setRaisonSocial(event.target.value)
                        }
                        value={raisonSocial}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="prenom"
                        className="block text-sm font-medium text-gray-700"
                      >
                        SIRET ou SIREN:{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        name="siret"
                        id="siret"
                        onChange={handleSiretChange}
                        value={siret}
                      />
                      {siretError && (
                        <div className="text-red-500">{siretError}</div>
                      )}
                    </div>

                    <div className="col-span-6">
                      <h2>Adresse de l’entreprise:</h2>
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="adresse"
                        className="block text-sm font-medium text-gray-700"
                      >
                        N° et Nom de rue{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      {/*<input
                        value={adresse}
                        type="text"
                        name="adresse"
                        id="adresse"
                        onChange={(event) => setAdresse(event.target.value)}
                        required
                        autoComplete="adresse"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />*/}
                      <Autocomplete
                        className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        apiKey="AIzaSyDK4L8SN7bnmjDstRZxqkqitqyQufbopLs"
                        name="adresse"
                        id="adresse"
                        options={{
                          types: ["geocode", "establishment"],
                        }}
                        onPlaceSelected={(place) => {
                          console.log(place);
                          const address = place.formatted_address;
                          console.log("Selected address:", place);
                          const latitude = place.geometry.location.lat();
                          const longitude = place.geometry.location.lng();
                          const placeIds = place.place_id;
                          setAdresse(address);
                          setPlaceId(placeIds);
                        }}
                      />
                    </div>

                    {/*<div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="ville"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ville <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        value={ville}
                        onChange={(event) => setVille(event.target.value)}
                        name="ville"
                        required
                        id="ville"
                        autoComplete="ville"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                      </div>*/}

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postale"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Code Postale: <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        name="postale"
                        value={postale}
                        onChange={handlePostaleChange}
                        required
                        id="postale"
                        autoComplete="postale"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                      {postaleError && (
                        <p style={{ color: "red" }}>{postaleError}</p>
                      )}
                    </div>

                    {/*<<div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="telephone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Téléphone: <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        name="telephone"
                        value={telephone}
                        onChange={handleTelephoneChange}
                        required
                        id="telephone"
                        autoComplete="telephone"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                      {telephoneError && (
                        <div className="text-red-500">{telephoneError}</div>
                      )}
                    </div>

                    div className="col-span-6">
                      <label
                        htmlFor="zone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Département: <span className="text-red-500"> * </span>
                      </label>
                      <div className="col-span-6">
                        <div className="mt-4 mb-4 space-y-2">
                          <div>
                            <label
                              htmlFor="Auvergne-Rhône-Alpes"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Auvergne-Rhône-Alpes"
                                name="regions"
                                value="Auvergne-Rhône-Alpes"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Auvergne-Rhône-Alpes"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">
                                Auvergne-Rhône-Alpes
                              </span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Bourgogne-Franche-Comté"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Bourgogne-Franche-Comté"
                                name="regions"
                                value="Bourgogne-Franche-Comté"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Bourgogne-Franche-Comté"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">
                                Bourgogne-Franche-Comté
                              </span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Bretagne"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Bretagne"
                                name="regions"
                                value="Bretagne"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes("Bretagne")}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">Bretagne</span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Centre-Val de Loire"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Centre-Val de Loire"
                                name="regions"
                                value="Centre-Val de Loire"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Centre-Val de Loire"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">
                                Centre-Val de Loire
                              </span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Corse, Grand Est"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Corse, Grand Est"
                                name="regions"
                                value="Corse, Grand Est"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Corse, Grand Est"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">Corse, Grand Est</span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Hauts-de-France"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Hauts-de-France"
                                name="regions"
                                value="Hauts-de-France"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Hauts-de-France"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">Hauts-de-France</span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Île-De-France"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Île-De-France"
                                name="regions"
                                value="Île-De-France"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Île-De-France"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">Île-De-France</span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Normandie"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Normandie"
                                name="regions"
                                value="Normandie"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes("Normandie")}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">Normandie</span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Nouvelle-Aquitaine"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Nouvelle-Aquitaine"
                                name="regions"
                                value="Nouvelle-Aquitaine"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Nouvelle-Aquitaine"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">
                                Nouvelle-Aquitaine
                              </span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Occitanie"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Occitanie"
                                name="regions"
                                value="Occitanie"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes("Occitanie")}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">Occitanie</span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Pays de la Loire"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Pays de la Loire"
                                name="regions"
                                value="Pays de la Loire"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Pays de la Loire"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">Pays de la Loire</span>
                            </label>
                          </div>

                          <div>
                            <label
                              htmlFor="Pays de la Loire"
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id="Provence-Alpes-Côte d'Azur"
                                name="regions"
                                value="Provence-Alpes-Côte d'Azur"
                                onChange={handleRegionChange}
                                checked={selectedRegions.includes(
                                  "Provence-Alpes-Côte d'Azur"
                                )}
                                className="mr-2 border-gray-300 rounded"
                              />
                              <span className="text-sm">
                                Provence-Alpes-Côte d'Azur
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                                </div>*/}

                    <div className="col-span-6">
                      <label
                        htmlFor="zone"
                        className="block text-sm font-medium text-gray-700 mb-4"
                      >
                        Département: <span className="text-red-500"> * </span>
                      </label>
                      <div className="mt-1 grid grid-cols-1 gap-y-2 sm:grid-cols-3 sm:gap-x-6 ">
                        {Départements.map((departement) => (
                          <div
                            key={departement.value}
                            className="flex items-center"
                          >
                            <input
                              id={departement.value}
                              name="regions"
                              type="checkbox"
                              value={departement.value}
                              onChange={handleRegionChange}
                              checked={selectedRegions.includes(
                                departement.value
                              )}
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                            <label
                              htmlFor={departement.value}
                              className="ml-3 block text-sm font-medium text-gray-700"
                            >
                              {departement.label}
                            </label>
                          </div>
                        )).slice(1)}
                      </div>
                    </div>

                    {/*<div className="col-span-6">
                      <h2>Adresse de l’entreprise:</h2>
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="adresse"
                        className="block text-sm font-medium text-gray-700"
                      >
                        N° et Nom de rue{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      <input
                        value={adresse}
                        type="text"
                        name="adresse"
                        id="adresse"
                        onChange={(event) => setAdresse(event.target.value)}
                        required
                        autoComplete="adresse"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="ville"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ville <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        value={ville}
                        onChange={(event) => setVille(event.target.value)}
                        name="ville"
                        required
                        id="ville"
                        autoComplete="ville"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postale"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Code Postale: <span className="text-red-500"> * </span>
                      </label>
                      <input
                        type="text"
                        name="postale"
                        value={postale}
                        onChange={handlePostaleChange}
                        required
                        id="postale"
                        autoComplete="postale"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                      {postaleError && (
                        <p style={{ color: "red" }}>{postaleError}</p>
                      )}
                      </div>*/}
                  </div>
                  <div className="mt-4 mb-2 w-[92%]">
                    <fieldset className="mt-6">
                      <legend className="contents text-sm font-medium text-gray-700 mt-6">
                        Domaine de compétence{" "}
                        <span className="text-red-500"> * </span>
                      </legend>
                      <div className="mt-4 space-y-4 mb-4">
                        {Domaines.map((domaine) => (
                          <div
                            className=" rounded-md cursor-pointer px-4 py-2"
                            key={domaine.id}
                          >
                            <div
                              onClick={() => handleTypeSelection(domaine.nom)}
                            >
                              {domaine.nom}
                            </div>
                            {domaine.hasType &&
                              selectedTypes.includes(domaine.nom) &&
                              domaine.type.map((type) => (
                                <div key={type} className="p-2">
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={selectedTypes.includes(type)}
                                      onChange={() => handleTypeSelection(type)}
                                    />
                                    {type}
                                  </label>
                                </div>
                              ))}
                            {!domaine.hasType &&
                              selectedTypes.includes(domaine.nom) && (
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={true}
                                      onChange={() => {}}
                                      disabled
                                    />
                                    {domaine.nom}
                                  </label>
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                  {/*<div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="assurance"
                        className="margin-top block text-gray-700 font-medium mb-2"
                      >
                        Nom de l’assureur:{" "}
                        <span className="text-red-500"> * </span>
                      </label>
                      <input
                        value={assurance}
                        onChange={(event) => setAssurance(event.target.value)}
                        id="assurance"
                        name="assurance"
                        type="text"
                        autoComplete="text"
                        required
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        placeholder="Nom de l’assureur"
                      />
                            </div>
                  </div>*/}
                  <div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="assurance"
                        className="margin-top block text-gray-700 font-medium mb-2"
                      >
                        N° Assurance
                      </label>
                      <input
                        value={nassurance}
                        onChange={(event) => setNassurance(event.target.value)}
                        id="nassurance"
                        name="nassurance"
                        type="text"
                        autoComplete="text"
                        required
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        placeholder="N° Assurance"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      style={{ marginTop: "16px" }}
                    >
                      Attestation d’assurance
                    </label>
                    {assuranceImg && (
                      <div className="mt-1">
                        {Array.from(assuranceImg).map((file) => (
                          <div key={file.name} className="flex items-center">
                            <span className="font-medium text-green-600">
                              {file.name}
                            </span>
                          </div>
                        ))}
                        <button
                          className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          onClick={() => setAssuranceImg(null)}
                        >
                          Change
                        </button>
                      </div>
                    )}
                    {!assuranceImg && (
                      <div className="mt-1 flex justify-center rounded-md border-1  border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="assuranceImg"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="assuranceImg"
                                name="assuranceImg"
                                type="file"
                                multiple
                                className="sr-only"
                                value=""
                                onChange={handleAssuranceImg}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      style={{ marginTop: "16px" }}
                    >
                      Photo de qualification
                    </label>
                    {qualificationImg && (
                      <div className="mt-1">
                        {Array.from(qualificationImg).map((file) => (
                          <div key={file.name} className="flex items-center">
                            <span className="font-medium text-green-600">
                              {file.name}
                            </span>
                          </div>
                        ))}
                        <button
                          className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          onClick={() => setQualification(null)}
                        >
                          Change
                        </button>
                      </div>
                    )}
                    {!qualificationImg && (
                      <div className="mt-1 flex justify-center rounded-md border-1  border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="qualificationImg"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="qualificationImg"
                                name="qualificationImg"
                                type="file"
                                multiple
                                className="sr-only"
                                value=""
                                onChange={handleQualificationImgs}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700"
                      style={{ marginTop: "16px" }}
                    >
                      Photo du Kabis
                    </label>
                    {kabisImg && (
                      <div className="mt-1">
                        {Array.from(kabisImg).map((file) => (
                          <div key={file.name} className="flex items-center">
                            <span className="font-medium text-green-600">
                              {file.name}
                            </span>
                          </div>
                        ))}
                        <button
                          className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          onClick={() => setQualification(null)}
                        >
                          Change
                        </button>
                      </div>
                    )}
                    {!kabisImg && (
                      <div className="mt-1 flex justify-center rounded-md border-1  border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="kabisImg"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="kabisImg"
                                name="kabisImg"
                                type="file"
                                multiple
                                className="sr-only"
                                value=""
                                onChange={handleKabisImg}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/*buttons */}
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    onClick={handleClickTech}
                    className=" userProfile-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Sauvegarder
                  </button>
                  <button
                    onClick={handleLogout}
                    className=" userProfiledelete-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Se déconnecter
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*<div
        className=" w-[92%] ml-auto mr-auto p-10 hidden sm:block"
        aria-hidden="true"
      >
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
                    </div>*/}

      {/*<div className="w-[92%] ml-auto mr-auto p-10 mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Informations Bancaires
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                "Les informations bancaires" font référence aux détails de votre
                compte bancaire, tels que votre numéro de compte, votre numéro
                de carte de crédit, votre code de sécurité, etc.
              </p>
            </div>
                    </div>
          {userType === "User" ? (
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="overflow-hidden shadow sm:rounded-md">
                {/*<div className="bg-white px-4 py-5 sm:p-6">
                  <div className="">
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="iban"
                        className="mt-4 block text-sm font-medium text-gray-700"
                      >
                        IBAN: <span className="text-red-500"> * </span>

                        <IbanElement
                          name="iban"
                          required
                          className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                          options={IBAN_ELEMENT_OPTIONS}
                        />
                      </label>
                    </div>
                    <div>
                      <div className="mandate-acceptance  mb-4 text-[12px] mt-4 ">
                        <span className="text-red-500"> * </span> En fournissant
                        vos informations de paiement et en confirmant ce
                        paiement, vous autorisez (A) Rocket Rides et Stripe,
                        notre prestataire de services de paiement et/ou PPRO,
                        son prestataire de services local, à envoyer des
                        instructions à votre banque pour débiter votre compte et
                        (B) votre banque à débiter votre compte conformément à
                        ces instructions. Vous avez, entre autres, le droit de
                        vous faire rembourser par votre banque selon les
                        modalités et conditions du contrat conclu avec votre
                        banque. La demande de remboursement doit être soumise
                        dans un délai de 8 semaines à compter de la date à
                        laquelle votre compte a été débité. Vos droits sont
                        expliqués dans une déclaration disponible auprès de
                        votre banque. Vous acceptez de recevoir des
                        notifications des débits à venir dans les 2 jours
                        précédant leur réalisation.
                      </div>
                      <button
                        onClick={handleSubmit}
                        className=" userProfile-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Confirmer votre IBAN
                      </button>
                      <div style={{ marginTop: "20px" }}>
                        {resultMessage ? (
                          resultMessage
                        ) : (
                          <span
                            className="text-[13px]"
                            style={{ color: "red" }}
                          >
                            veuillez confirmer votre IBAN
                          </span>
                        )}
                      </div>
                    </div>
  
                    <p className="text-[12px] mt-4 text-red-500">
                      <span className="text-red-500"> * </span> Champ
                      Obligatoire{" "}
                    </p>
                  </div>
                        </div>

                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    onClick={handleOnClick}
                    className=" userProfile-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Sauvegarder
                  </button>
                  <button
                    onClick={handleLogout}
                    className=" userProfiledelete-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Se déconnecter
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="overflow-hidden shadow sm:rounded-md">
                {/*<div className="bg-white px-4 py-5 sm:p-6">
                  <div className="">
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="banque"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nom de banque:
                      </label>
                      <input
                        type="text"
                        name="banque"
                        required
                        id="banque"
                        value={banque}
                        onChange={(event) => setBanque(event.target.value)}
                        autoComplete="banque"
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="iban"
                        className="mt-4 block text-sm font-medium text-gray-700"
                      >
                        IBAN:
                      </label>
                      <input
                        type="text"
                        name="iban"
                        required
                        id="iban"
                        value={iban}
                        onChange={(event) => setIban(event.target.value)}
                        autoComplete="iban"
                        className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>
                    <div className="mt-4 col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="bic"
                        className="block text-sm font-medium text-gray-700"
                      >
                        BIC:
                      </label>
                      <input
                        type="text"
                        name="bic"
                        required
                        id="bic"
                        value={bic}
                        onChange={(event) => setBic(event.target.value)}
                        autoComplete="bic"
                        className="p-4 mt-1 block w-full border-1 rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700"
                        style={{ marginTop: "16px" }}
                      >
                        Photo du RIB
                      </label>
                      {ribImg && (
                        <div className="mt-1">
                          {Array.from(ribImg).map((file) => (
                            <div key={file.name} className="flex items-center">
                              <span className="font-medium text-green-600">
                                {file.name}
                              </span>
                            </div>
                          ))}
                          <button
                            className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() => setQualification(null)}
                          >
                            Change
                          </button>
                        </div>
                      )}
                      {!ribImg && (
                        <div className="mt-1 flex justify-center rounded-md border-1  border-gray-300 px-6 pt-5 pb-6">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="ribImg"
                                className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                              >
                                <span>Upload a file</span>
                                <input
                                  id="ribImg"
                                  name="ribImg"
                                  type="file"
                                  multiple
                                  className="sr-only"
                                  value=""
                                  onChange={handleRibImgs}
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    onClick={handleClickTech}
                    className=" userProfile-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Sauvegarder
                  </button>
                  <button
                    onClick={handleLogout}
                    className=" userProfiledelete-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Se déconnecter
                  </button>
                </div>

              </div>
            </div>
          )}
        </div>
      </div>*/}

      {confirmationModal === true && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="mt-3">
                <div className="mt-2 text-center">
                  <h4 className="text-lg font-medium text-gray-800">
                    Demande créé avec succès !
                  </h4>
                  <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                    Nous tenons à vous informer que vos informations ont bien
                    été enregistrées et que votre compte est actuellement en
                    cours de vérification et de validation par notre équipe.
                    Cette étape est nécessaire pour garantir la sécurité et
                    l'intégrité de notre système. Nous vous remercions de votre
                    patience et nous vous tiendrons informé(e) de l'avancement.
                  </p>
                  <button onClick={handleModalClick}> ok </button>
                  <div className="items-center gap-2 mt-3 sm:flex"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserInfo;

const DirectDebit = () => {
  return (
    <Elements stripe={stripePromise}>
      <UserInfo />
    </Elements>
  );
};