import React, { forwardRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AiOutlineMenu } from "react-icons/ai";

const NavButton = forwardRef(
  ({ title, customFunc, icon, color, dotColor, length, shown }, ref) => {
    return (
      <>
        <div>
          <p id="my-element2" data-tooltip-content={title}>
            <button
              type="button"
              onClick={customFunc}
              ref={ref}
              style={{ color }}
              className="relative text-xl rounded-full p-3 hover:bg-light-gray"
            >
              {shown === "Yes" && (
                <span
                  style={{ backgroundColor: "red", color: "white" }}
                  className="absolute inline-flex rounded-full h-4 w-4 right-2 top-2 items-center justify-center text-[10px]"
                >
                  {length}
                </span>
              )}
              {icon}
            </button>
          </p>
          <ReactTooltip anchorId="my-element2" />
        </div>
      </>
    );
  }
);

export default NavButton;
