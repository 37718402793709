const guideClientPro = [
    {
      "sections": [
        {
          "title": "Introduction",
          "items": [
            {
              "type": "paragraph",
              "content": "Nous sommes ravis de vous accueillir dans notre communauté ! Pour commencer, veuillez-vous référer aux instructions d'inscription dans ce manuel d'utilisation. Nous vous guiderons à travers le processus d’utilisation, en veillant à ce que vous soyez opérationnel en un rien de temps. Si vous avez des questions ou rencontrez des difficultés, notre équipe d'assistance est toujours prête à vous aider."
            }
          ],
          "subsections": []
        },
        {
          "title": "1. Inscription",
          "items": [
            {
              "type": "paragraph",
              "content": "Pour commencer votre parcours avec LeBonTechnicien.fr.fr, suivez ces étapes simples pour vous inscrire"
            },
            {
              "type": "list",
              "items": [
                "Rendez-vous sur le site www.LeBonTechnicien.fr",
                "Cliquez sur \"Inscription\" dans le menu en haut de la page.",
                "Cliquez sur \"S'inscrire en tant qu'utilisateur\".",
                "Remplissez le formulaire d’inscription.",
                "Vérifiez attentivement l'exactitude des informations fournies, puis cliquez sur le bouton \"S'inscrire\".",
                "Vérifiez votre boîte e-mail pour trouver un e-mail de confirmation. N’oubliez pas les spams si vous ne le trouvez pas.",
                "Ouvrez l'e-mail de confirmation et cliquez sur le lien fourni pour valider votre compte."
              ]
            }
          ],
          "subsections": []
        },
        {
          "title": "2. Connexion",
          "items": [
            {
              "type": "paragraph",
              "content": "Suivez ces étapes pour vous connecter:"
            },
            {
              "type": "list",
              "items": [
                "Pour vous connecter, rendez-vous sur le site www.LeBonTechnicien.fr",
                "Cliquez sur \"Connexion\" dans le menu en haut de la page.",
                "Saisissez votre adresse e-mail et mot de passe.",
                "Cliquez sur le bouton \"Connexion\""
              ]
            },
            
          ],
          "subsections": []
        },
        {
          "title": "3. Informations",
          "items": [
            {
              "type": "paragraph",
              "content": "Lorsque vous vous connecterez pour la première fois, vous serez automatiquement redirigé vers une page qui vous présentera un formulaire à remplir. Ce formulaire vise à compléter votre profil d'utilisateur et à vous offrir une expérience personnalisée La première étape consiste à cocher \"Oui\" dans le champ \"Etes-vous une entreprise\". Cette étape est cruciale pour adapter les fonctionnalités et les services qui vous seront proposés en fonction de vos besoins spécifiques. Une fois cette sélection effectuée, vous pourrez poursuivre le remplissage du formulaire en fournissant les informations requises pour créer un profil complet et précis."
            },
            {
                type: "paragraph",
                content: "Une fois le choix effectué et le formulaire rempli, n'oubliez pas d'appuyer sur le bouton \"Enregistrer\" dès que vous avez terminé. Après avoir enregistré vos informations, vous serez enfin connecté(e) et pourrez accéder à différentes fonctionnalités de la plateforme LeBonTechnicien.fr."
              },
            
          ],
          "subsections": []
        },
        {
          "title": "4. Menu Principal",
          "items": [
            {
              "type": "paragraph",
              "content": "Le menu principal de l'application LeBonTechnicien.fr est situé en haut de la page et offre un accès rapide et facile aux différentes fonctionnalités et sections de l'application. Il se compose de sept rubriques clés qui vous permettent de gérer efficacement vos besoins et interactions."
            },
            
            {
              "type": "paragraph",
              "content": "Ces rubriques sont les suivantes : « Mon Compte », « Liste des Clients », « Devis », « Factures », « Notifications », « Profil ». "
            },
            {
                "type": "paragraph",
                "content": "Dans les sections suivantes de ce guide d'utilisation, nous allons explorer en détail chaque rubrique du menu principal, vous fournissant des instructions claires et des informations utiles sur leur utilisation. Que vous ayez besoin d'ajouter des projets et les suivre (dans « mon compte »), de gérer vos clients, de suivre vos réclamations ou de consulter vos devis ou factures, vous trouverez tout ce dont vous avez besoin dans le menu principal de l'application."
              }
          ],
          "subsections": [
            {
                "title": "4.1 Mon compte",
                "items": [
                    {
                        "type": "paragraph",
                        "content": "Maintenant que vous êtes connecté, vous entrez dans l’application web « LeBonTechnicien ». Nous allons vous décrire toutes les fonctionnalités de l’application les unes après les autres."
                    },
                    {
                        "type": "paragraph",
                        "content": "Dès votre connexion, vous atterrirez dans la page « mon Compte » avec six rubriques : « Ajouter un projet », « Ajouter un client », « Trouver un technicien », « Suivre mes projets », « Mes contrats de maintenance » et « Mes réclamation » que nous évoquerons par la suite. "
                    }, 
                    
                ],

            },
            {
                "title": "4.2 Les fonctionnalités",
                "subsections": [
                    {
                        "title": "4.2.1	Ajouter un projet",
                        "items": [
                                   
                                    {
                                        "type": "paragraph",
                                        "content": "Afin de permettre l'intervention technique sur votre appareil d'énergie renouvelable, Vous devez formuler une Demande d'intervention. Cette requête permet d’établir un prérequis indispensable, permettant au technicien d'agir en toute efficacité sur le problème, garantissant ainsi un service adapté et un suivi professionnel. Pour ce faire, cliquez sur « Ajouter un projet », puis remplissez le formulaire du projet :"
                                    },    
                                    {
                                        "type": "list",
                                        "items": [
                                        "Dans \"Type de produit\", sélectionnez le type de votre appareil pour lequel vous faite la demande",
                                        "Sélectionnez le \"Type de prestation souhaité\" que décrit le plus votre besoin en choisissant parmi les options proposées.",
                                        "Saisissez toutes les informations de votre client sans oublier d’indiquer s'il est sous contrat ou non.",
                                        "Saisissez les informations relatives à l'appareil du client en décrivant sa panne avec le maximum de détails possible.",
                                        "Validez le Google reCAPTCHA.",
                                        "Cliquez sur le bouton \"Valider la demande\" pour soumettre votre demande d'intervention.",
                                        ]
                                    }, 
                                    {
                                        "type": "paragraph",
                                        "content": "Ainsi, votre projet sera enregistré sur notre plateforme, permettant aux techniciens de la prendre connaissance et de l'analyser. En fonction de leur disponibilité et de leur expertise, ils pourront alors prendre en charge votre demande avec professionnalisme et efficacité."
                                    },                                                             
                                ],
                    }, 
                    {
                        "title": "4.2.2	Ajouter un client",
                        "items": [
                            {
                                "type": "paragraph",
                                "content": "Cette section a pour objectif de vous guider étape par étape à travers le processus d'ajout d'un nouveau client, afin de garantir une expérience fluide et sans erreur."
                            }, 
                            {
                                "type": "paragraph",
                                "content": "Étape 1 : Accéder à la page d'ajout de client"
                            },
                            {
                                "type": "list",
                                "items": [
                                "Commencez par Cliquer sur « Mon Compte » dans le menu principal en haut de votre écran.",
                                "Dans la page « Mon Compte » qui apparaît, sélectionnez « Ajouter un client ».",
                                ]
                            },
                            {
                                "type": "paragraph",
                                "content": "Étape 2 : Remplissage du formulaire d'ajout du client"
                            },
							{
                                "type": "paragraph",
                                "content": "Une fois sur la page d'ajout, vous serez amené à remplir un formulaire contenant des champs destinés à collecter les informations de votre client. Voici une brève description de chaque champ :"
                            },
							{
                                "type": "list",
                                "items": [
                                "Nom : Saisissez le nom de famille du client.",
                                "Prénom : Indiquez-le ou les prénoms du client.",
								"Téléphone : Entrez le numéro de téléphone du client.",
                                "Email : Saisissez l'adresse e-mail du client pour toute correspondance future.",
                                "Client Sous Contrat ? (Oui/Non) : Sélectionnez si le client est lié par un contrat ou non. ",
                                "N° et Nom de rue : Indiquez le numéro et le nom de la rue de l'adresse du client.",
                                "Ville : Saisissez la ville du client.",
                                "Code Postale : Renseignez le code postal correspondant à l'adresse du client.",
                                ]
                            },
                            {
                                "type": "paragraph",
                                "content": "Veillez à vérifier toutes les informations saisies afin d'éviter toute erreur ou malentendu à l'avenir."
                            },
							{
                                "type": "paragraph",
                                "content": "Étape 3 : Sécurité du formulaire"
                            },
							{
                                "type": "paragraph",
                                "content": "Pour confirmer que vous n'êtes pas un robot et pour garantir la sécurité des informations saisies, veuillez valider le Google reCAPTCHA. Cochez simplement la case \"Je ne suis pas un robot\" et suivez les éventuelles instructions affichées."
                            },
							{
                                "type": "paragraph",
                                "content": "Étape 4 : Finaliser l'ajout du client"
                            },
                        							{
                                "type": "paragraph",
                                "content": "Après avoir dûment rempli le formulaire et validé le reCAPTCHA, dirigez-vous vers le bas de la page et cliquez sur vert intitulé « Ajouter » pour finaliser l'ajout du client."
                            },
                        							{
                                "type": "paragraph",
                                "content": "Vous recevrez une notification de confirmation à l'écran, et le client sera ajouté à votre liste."
                            },
                        ],
                    },
					{
                        "title": "4.2.3 Trouver un technicien ",
                        "items": [
                            {
                                "type": "paragraph",
                                "content": "Lorsque vous consultez la page \"Trouver un technicien\", vous avez la possibilité de parcourir une liste complète de techniciens enregistrés sur notre plateforme. Cette page vous permet de trouver des professionnels qualifiés pour répondre à vos besoins spécifiques en matière d'équipements d'énergie renouvelable"
                            }, 
                            {
                                "type": "paragraph",
                                "content": "Pour faciliter votre recherche, nous avons inclus deux filtres dans cette page :"
                            },
                            {
                                "type": "list",
                                "items": [
                                "« Domaine de compétence » : Vous pouvez affiner votre recherche en fonction des compétences des techniciens, afin de trouver celui qui convient le mieux à votre projet.",
                                "« Zone d'intervention » : Vous pouvez filtrer en fonction de leur zone d'intervention, pour savoir s'il y a un professionnel proche de chez vous",
                                ]
                            },
                            {
                                "type": "paragraph",
                                "content": "Notez que la page \"Trouver un technicien\" vous donne un aperçu des techniciens disponibles, avec leurs noms, compétences, zones d'intervention, ainsi que les notes et commentaires des clients"
                            },
                            {
                                "type": "paragraph",
                                "content": "Que vous ayez besoin d'installation, de réparation, de remplacement, de maintenance ou d'autres services liés aux équipements d'énergie renouvelable, notre réseau de techniciens expérimentés est prêt à vous aider. Nous avons sélectionné avec soin des professionnels qui s'engagent à fournir un excellent service. Si vous avez des questions ou besoin d'aide lors de votre recherche d'un technicien, n'hésitez pas à nous contacter via le formulaire de contact sur notre site web : www.lebontechnicien.fr/contact. Notre équipe d'assistance est là pour vous aider."
                            },
                        ],
                    },
                    {
                        "title": "4.2.4 Suivre mes projets",
                        "items": [
                            {
                                "type": "paragraph",
                                "content": "Au sein de notre plateforme, vous avez accès à une page spécifique où vous pouvez retrouver la liste de vos projets. Pour y accéder, il vous suffit de cliquer sur l'onglet « Suivre mes projet ». Cette page vous offre la possibilité de consulter toutes vos demandes, ainsi que les détails et le suivi de chacune d'entre elles."
                            }, 
                            {
                                "type": "paragraph",
                                "content": "La page est présentée sous forme d'un tableau avec sept colonnes qui regroupent les informations principales que vous avez fournies lors de la création de chaque demande :"
                            },
                            {
                                "type": "list",
                                "items": [
                                    "La colonne \"Création\" : affiche la date à laquelle vous avez créé la demande.",
                                    "La colonne \"Prestation\" : précise le type de prestation que vous avez demandé.",
                                    "La colonne \"Domaine\" : spécifie pour quel type d'appareil la demande a été formulée.",
                                    "La colonne \"Département\" : mentionne dans quel département l'intervention est prévue.",
                                    "La colonne \"Statut\" : vous permet de suivre l'état d'avancement de chaque intervention.",
                                    "Enfin, la colonne \"Actions\" : vous donne la possibilité d'avoir un aperçu détaillé de l'évolution de chaque demande en cliquant sur l'icône en forme d'œil.",
                                ]
                            },
                            {
                                "type": "paragraph",
                                "content": "Grâce à cette présentation claire et organisée, vous pouvez aisément visualiser toutes vos demandes et avoir une vue d'ensemble de leur progression."
                            },
                        ],
                        "subsections": [
                            {
                                "title": "Page de Détails de l'Intervention",
                                "items": [
                                    {
                                        "type": "paragraph",
                                        "content": "Cette page essentielle offre une vision détaillée de chaque demande d'intervention effectuée. Elle est structurée en plusieurs sections, fournissant toutes les informations nécessaires pour suivre l'évolution de votre demande et assurer un suivi efficace."
                                    }, 
                                    {
                                        "type": "paragraph",
                                        "content": "En haut de la page, vous trouverez un QR code généré automatiquement par la plateforme lors de l'ajout de votre appareil concerné par l'intervention. Ce QR code revêt une importance capitale dans le processus d'intervention des techniciens. Ces derniers doivent scanner ce code pour démarrer et clôturer l’intervention, garantissant ainsi un suivi précis et sécurisé de chaque action réalisée sur votre appareil."
                                    },
                                    {
                                        "type": "list",
                                        "items": [
                                            "Détails de la demande:",
                                        ]
                                    },
                                    {
                                        "type": "paragraph",
                                        "content": "Dans cette section, vous trouverez toutes les informations que vous avez renseignées dans le formulaire de demande, ainsi que le statut actuel de votre demande. Vous pouvez y suivre l'état d'avancement de votre demande en temps réel, ce qui vous permet de rester informé tout au long du processus."
                                    },
                                    {
                                        "type": "list",
                                        "items": [
                                            "Détails de l'appareil:",
                                        ]
                                    },
                                    {
                                        "type": "paragraph",
                                        "content": "Cette section répertorie toutes les informations relatives à l'appareil concerné par votre demande. Vous y retrouverez les détails que vous avez fournis lors de l'ajout de l'appareil. Ces informations comprennent le « Type d'appareil », le « Mois et année d'installation », la « Marque », la « Référence », les détails concernant la « Maintenance » et la « Puissance ». Vous permettant ainsi d'avoir une vue complète et précise de votre équipement."
                                    },
                                    {
                                        "type": "list",
                                        "items": [
                                            "Liste des techniciens qui ont répondu à vos demandes d'intervention:",
                                        ]
                                    },
                                    {
                                        "type": "paragraph",
                                        "content": "Dans cette section, vous pouvez visualiser la liste des techniciens qui ont répondu à vos demandes d'intervention. Elle est présentée sous forme d'un tableau organisé avec les colonnes suivantes :"
                                    },
                                    {
                                        "type": "list",
                                        "items": [
                                            "Colonne \"Date\" : Affiche la date à laquelle le technicien a répondu à votre demande.",
                                            "Colonne \"Entreprise\" :  Indique le nom de l'entreprise à laquelle appartient le technicien.",
                                            "Colonne \"Message du Technicien\" :  Contient le message envoyé par le technicien en réponse à votre demande, qui peut inclure une proposition de date d'intervention.",
                                            "Colonne \"Actions\" :   Permet d'accepter ou de refuser le technicien en cliquant sur un bouton vert pour accepter et rouge pour refuser.",
                                        ]
                                    }, 
                                    {
                                        "type": "paragraph",
                                        "content": "Grâce à cette présentation claire et structurée, vous pouvez facilement visualiser les différentes réponses des techniciens à votre demande d'intervention. Vous avez ainsi la possibilité de choisir le technicien le plus approprié pour votre projet en fonction de leurs propositions et de leurs compétences."
                                    },   
                                    {
                                        "type": "list",
                                        "items": [
                                            "Confirmation de fin d'intervention ou litige",
                                        ]
                                    },
                                    {
                                        "type": "paragraph",
                                        "content": "Dans cette section, vous pouvez confirmer la fin de l'intervention déclarée par le technicien ou exprimer votre désaccord si vous estimez que l'intervention n'est pas encore terminée. Une fois que le technicien a effectué son travail et déclaré que l'intervention est finie, deux boutons sont affichés :"
                                    },   
                                    {
                                        "type": "list",
                                        "items": [
                                            "Bouton vert « Confirmer la fin de l'intervention » : Si vous êtes satisfait du travail effectué par le technicien et que tout est conforme à vos attentes, vous pouvez simplement cliquer sur ce bouton pour confirmer la fin de l'intervention. Une fois confirmée, le statut de l'intervention passe automatiquement à \"Clôturé\". Cela vous permet de clôturer officiellement la demande et d'indiquer que vous êtes pleinement satisfait du service rendu.",
                                            "Bouton rouge « Intervention toujours ouverte » : Si, en revanche, vous n'êtes pas d'accord avec la déclaration du technicien selon laquelle l'intervention est terminée, vous pouvez cliquer sur le bouton. Un pop-up s'affiche alors pour vous permettre de fournir des détails sur les raisons pour lesquelles vous estimez que l'intervention n'est pas encore achevée. Votre demande passe alors en \"litige\", et notre équipe de LeBonTechnicien.fr intervient pour résoudre la situation.",
                                        ]
                                    },
                                    {
                                        "type": "paragraph",
                                        "content": "Lorsqu'un litige est signalé, notre équipe contacte à la fois le client et le technicien pour comprendre les problèmes rencontrés et trouver une solution mutuellement satisfaisante. Nous nous engageons à assurer la résolution efficace de tout litige, garantissant votre satisfaction et la qualité de notre service."
                                    },                          
                                ],
                            }
                        ]
                    },
                    {
                        "title": "4.2.5 Contrat de maintenance", 
                        "items": [
                            {
                                "type": "paragraph",
                                "content": "Pour souscrire à un contrat de maintenance, il vous suffit de cliquer sur « Mes contrats de maintenance » puis sur le grand bouton vert « Ajouter » qui est situé en haut de la page. Vous serez ensuite redirigé vers un formulaire de demande de contrat de maintenance, simple et intuitif, qui ne comporte que trois champs à remplir :"
                            },
                            {
                                "type": "list",
                                "items": [
                                    "\"Date de contrat\" : Sélectionnez la date souhaitée pour le début du contrat.",
                                    "\"Client\" : Commencez par choisir si vous avez déjà créé un projet pour ce client. Ensuite, sélectionnez le client concerné à partir de la liste déroulante pour établir le contrat.",
                                    "\"Appareil\" : Choisissez l'appareil pour lequel vous souhaitez souscrire au contrat de maintenance.",
                                    "Enfin, validez le Google reCAPTCHA pour assurer la sécurité de votre demande, puis cliquez sur le bouton \"Envoyer\" pour soumettre votre demande.",
                                ]
                            },
                            {
                                "type": "paragraph",
                                "content": "Veuillez noter qu'il faut compter un délai de 10 jours ouvrés pour l'étude de votre demande et la préparation du contrat. Lors de ce délai, notre équipe traitera votre demande, prendra contact avec vous pour peaufiner tous les détails et procédera à la signature. Par défaut, tous les contrats de maintenance ont une durée d'un an, renouvelable automatiquement, à moins que vous demandiez leur résiliation 30 jours avant la date anniversaire. Tous ces détails seront mentionnés dans le contrat."
                            },
                            {
                                "type": "paragraph",
                                "content": "Lorsque vous accédez à la page \"Mes contrats de maintenance\", vous pouvez voir la liste de tous vos contrats. Cette liste présente les informations suivantes :"
                            },
                            {
                                "type": "list",
                                "items": [
                                    "\"Appareil\" : Le nom de l'appareil concerné par le contrat.",
                                    "\"Date de début\" : La date de début du contrat.",
                                    "\"Statut\" : Le statut du contrat, qui peut être \"En attente de validation\" ou \"Validé\"",
                                    "\"Date de fin\" : La date de fin du contrat.",
                                    "\"Consulter le contrat\" : Ce bouton vous permet d'accéder à la page de détail du contrat.",
                                ]
                            },
                        ],
                        "subsections": [
                            {
                                "title": "Détails du Contrat de Maintenance", 
                                "items": [
                                    {
                                        "type": "paragraph",
                                        "content": "Lorsque vous cliquez sur le bouton \"Consulter le contrat\", vous accédez à la page de détail du contrat de maintenance. Cette page comporte deux sections importantes. La première section présente les détails de l'appareil associé au contrat, y compris son QR code unique. Ce QR code est essentiel dans le processus d'intervention des techniciens et vous le retrouverez également sur la page de suivi des interventions"
                                    }, 
                                    {
                                        "type": "paragraph",
                                        "content": "Une fois sur la page, vous remarquerez deux onglets principaux :"
                                    }, 
                                    {
                                        "type": "paragraph",
                                        "content": "Nous vous encourageons à consulter cette page de détail pour avoir une vision complète de votre contrat de maintenance et accéder à toutes les informations nécessaires pour assurer une gestion efficace de vos appareils. "
                                    },                                     
                                ]
                            }
                        ]   
                    },
                    {
                        "title": "4.2.6 Réclamations", 
                        "items": [
                            {
                                "type": "paragraph",
                                "content": "Si vous rencontrez des problèmes ou des incohérences lors de l'utilisation de notre application LeBonTechnicien.fr, nous avons mis en place une fonctionnalité de réclamation pour faciliter la communication et accélérer la résolution de vos problèmes."
                            },
                            {
                                "type": "paragraph",
                                "content": "Lorsque vous cliquez sur l'option « Mes réclamations » dans la page « Mon compte », vous serez redirigé vers la page des réclamations contenant en haut un bouton vert \"Envoyer\". Lorsque vous cliquez sur ce bouton, un pop-up s’affiche avec un formulaire spécialement conçu pour être à la fois simple et intuitif. Ce formulaire est dynamique et adaptatif, offrant des champs différents selon les informations que vous fournissez."
                            },                            {
                                "type": "paragraph",
                                "content": "Voici les champs que vous pourrez rencontrer lors de cette étape :"
                            },
                            {
								"type": "list",
								"items": [
									"Premier champ : \"Décrire votre problème avec le plus de détails possibles\" : Dans ce champ, nous vous encourageons à fournir une description aussi complète que possible de votre réclamation. N'hésitez pas à inclure tous les éléments pertinents tels que les dates, les noms ou les codes d'erreur, si applicable.",
									"Second champ : « Y a-t-il déjà un projet ajouté avec le client ? » : Ici, vous avez la possibilité de sélectionner si la réclamation concerne un projet déjà en cours ou non.",
									"Si \"Oui\" est sélectionné :",
									"Troisième champ : \"Pour quelle intervention\" : Une liste déroulante vous permettra de choisir l'intervention spécifique liée à votre réclamation. Cette liste sera automatiquement peuplée en fonction de l'appareil que vous aurez sélectionné précédemment.",
									"Si \"Non\" est sélectionné :",
									"Troisième champ : Liste des clients sans projet associé : Vous aurez ici une liste des clients qui n'ont pas de projet en cours. Sélectionnez le client pour lequel vous souhaitez faire une réclamation.",
									"Quatrième champ : \"Pour quelle intervention\" : Dans ce cas, un nouveau champ apparaîtra vous permettant de préciser quel appareil ou contrat est concerné par la réclamation.",
									"Bouton \"Envoyer\" : Une fois tous les champs remplis, cliquez sur ce bouton pour soumettre votre réclamation. Un accusé de réception vous sera envoyé et notre équipe se chargera de traiter votre demande dans les plus brefs délais."
								]
							},
                            {
                                "type": "paragraph",
                                "content": "Sur cette page, vous pouvez également consulter la liste de toutes vos réclamations, présentée sous forme d'un tableau avec quatre colonnes contenant les informations principales que vous avez fournies lors de la création de chaque réclamation :"
                            },
                            {
                                "type": "list",
                                "items": [
                                    "Colonne \"Création\" : Affiche la date de création de la réclamation.",
                                    "Colonne \"Message\" : Affiche le détail de la réclamation que vous avez rempli dans le formulaire.",
                                    "Colonne \"Statut\" : Indique l'état d'avancement de la réclamation.",
                                    "Colonne \"Actions\" : Permet d'accéder à la page de détail de la réclamation en cliquant sur l'icône en forme d'œil.",
                                ]
                            },
                            {
                                "type": "paragraph",
                                "content": "Pour améliorer votre expérience de recherche, nous avons inclus un champ de recherche ainsi que plusieurs filtres en haut de la page, tels que \"année\", \"mois\" et \"statut\". Ces filtres vous permettent de visualiser uniquement les réclamations qui vous intéressent"
                            },
                            {
                                "type": "paragraph",
                                "content": "Notre équipe dévouée de LeBonTechnicien.fr est là pour vous accompagner tout au long du processus de gestion des réclamations. Une fois que vous avez soumis votre réclamation, notre équipe prendra en charge la situation et nous nous efforçons de résoudre les problèmes rapidement et efficacement, en travaillant en étroite collaboration avec vous et les techniciens impliqués. Notre équipe met tout en œuvre pour garantir une résolution adéquate de votre réclamation."
                            },
                        ],
                        "subsections": [
                            {
                                "title": "Détails de la Réclamation",
                                "items": [
                                    {
                                        "type": "paragraph",
                                        "content": "Lorsque vous cliquez sur l'icône en forme d'œil dans la colonne \"Actions\", vous accédez à la page de détail de la réclamation. Cette page se compose de trois sections :"
                                    },
                                    {
                                        "type": "list",
                                        "items": [
                                            " La première section affiche les détails de l'appareil concerné, y compris son QR code unique. Ce QR code revêt une importance capitale dans le processus de gestion des réclamations et est également accessible depuis la page de suivi des interventions.",
                                            " La deuxième section présente les détails spécifiques à la réclamation que vous avez créée, fournissant ainsi un aperçu clair et précis de votre problème.",
                                            " La troisième section affiche les détails de l'intervention liée à la réclamation, notamment le nom du technicien qui l'a réalisée. Cela vous permet d'avoir une vue d'ensemble complète de la situation et de faciliter la communication avec notre équipe technique.",
                                        ]
                                    }, 
                                ]
                            }
                        ]
 
                    },                                        
                ]
            },
            {
                "title": "4.3 Liste des clients",
                "items": [
                    {
                        "type": "paragraph",
                        "content": "Pour accéder à la section \"Liste des Clients\", naviguez vers le menu principal de l'application et cliquez sur l'élément intitulé \"Liste des Clients\"."
                    }, 
                    {
                        "type": "list",
                        "items": [
                            "Liste des Clients avec intervention",
                            "Liste des Clients sans intervention",
                        ]
                    }, 
                    {
                        "title": "paragraph",
                        "content": "Liste des Clients avec intervention:"
                    },
                    {
                        "type": "paragraph",
                        "content": "Cet onglet présente la liste des clients que vous avez ajoutés lors de la création de projets. Chaque client listé dans cette section est forcément associé à un projet et possède un appareil qui a été enregistré lors de la création du projet correspondant. Les informations sur les clients sont affichées dans un tableau composé de cinq colonnes :"
                    },
                    {
                        "type": "list",
                        "items": [
                            "Client : Affiche le nom du client.",
                            "Adresse : Affiche l'adresse physique du client.",
                            "Appareil : Affiche le type ou le modèle d'appareil associé au client.",
                            "Téléphone : Affiche le numéro de téléphone du client.",
                            "Actions : Donne accès à la page de détail du client. Grâce à l’icône en forme d'œil vous serez redirigé vers une page contenant toutes les informations relatives au client.",
                        ]
                    },

                    {
                        "title": "paragraph",
                        "content": "Liste des Clients sans intervention"
                    },
                    {
                        "type": "paragraph",
                        "content": "Cet onglet présente la liste des clients que vous avez ajoutés indépendamment de tout projet. Ces clients ont été enregistrés via le formulaire d'ajout de client décrit dans la section \"4.2.2 Ajouter un client\" de ce guide. Les informations sont affichées dans un tableau composé de quatre colonnes :"
                    },
                    {
                        "type": "list",
                        "items": [
                            "Client : Affiche le nom du client.",
                            "Adresse : Affiche l'adresse physique du client.",
                            "Téléphone : Affiche le numéro de téléphone du client.",
                            "Actions : Donne accès à la page de détail du client. Grâce à l’icône en forme d'œil vous serez redirigé vers une page contenant toutes les informations relatives au client.",
                        ]
                    },
                    {
                        "type": "paragraph",
                        "content": "Note Importante : Contrairement à l'onglet \"Liste des Clients avec intervention\", les informations sur l'appareil associé au client ne sont visibles que sur la page de détail du client."
                    },
                ],

                "subsections": [
                    {
                        "title": "4.3.1	Page de Détail pour les Clients avec Intervention",
                        "items": [
                                   
                                    {
                                        "type": "title",
                                        "content": "Comment Y Accéder:"
                                    },    
                                    {
                                        "type": "list",
                                        "items": [
                                        "1.	Cliquez sur l'onglet \"Liste des Clients avec intervention\" dans le menu principal.",
                                        "2.	Repérez le client concerné dans le tableau et cliquez sur l'icône en forme d'œil dans la colonne \"Actions\".",
                                        ]
                                    }, 
                                    {
                                        "type": "paragraph",
                                        "content": "Contenu de la Page"
                                    },  
                                    {
                                        "type": "paragraph",
                                        "content": "En haut de la page, vous trouverez le QR Code de l'appareil du client."
                                    }, 
                                    {
                                        "type": "paragraph",
                                        "content": "En dessous du QR Code il y a la liste suivante des informations :"
                                    }, 
                                    {
                                        "type": "list",
                                        "items": [
                                        "•	Date de Création : Affiche la date de création du dossier client.",
                                        "•	Nom du Client : Indique le nom et le prénom du client.",
                                        "•	Numéro de Téléphone : Affiche le numéro de téléphone du client.",
                                        "•	Adresse : Révèle l'adresse physique du client.",
                                        "•	Appareil Installé : Présente le type de l'appareil installé.",
                                        "•	Numéro de Série de l’Appareil : Affiche le numéro de série de l'appareil.",
                                        "•	Puissance de l’Appareil : Indique la puissance du dispositif.",
                                        "•	Date d’Installation : Révèle la date d'installation de l'appareil.",
                                        "•	Quantité : Mentionne le nombre d'appareils installés chez le client.",
                                        "•	Client Sous Contrat ? : Indique si le client est actuellement sous contrat ou non.",
                                        ]
                                    },                                                           
                                ],
                    }, 
                    {
                        "title": "4.3.2	Page de Détail pour les Clients sans Intervention",
                        "items": [
                                   
                                    {
                                        "type": "title",
                                        "content": "Comment Y Accéder:"
                                    },    
                                    {
                                        "type": "list",
                                        "items": [
                                        "1.	Cliquez sur l'onglet \"Liste des Clients sans intervention\" dans le menu principal.",
                                        "2.	Repérez le client concerné dans le tableau et cliquez sur l'icône en forme d'œil dans la colonne \"Actions\".",
                                        ]
                                    }, 
                                    {
                                        "type": "paragraph",
                                        "content": "Contenu de la Page"
                                    },  
                                    {
                                        "type": "paragraph",
                                        "content": "Liste des Informations du Client "
                                    }, 
                                    {
                                        "type": "list",
                                        "items": [
                                        "•	Nom et Prénom du Client : Indique le nom et le prénom du client.",
                                        "•	Téléphone : Affiche le numéro de téléphone du client.",
                                        "•	Adresse : Révèle l'adresse physique du client.",
                                        "•	Ajouter un Nouvel Appareil : Cliquez sur le bouton \"Ajouter un Nouvel Appareil\" pour ouvrir un formulaire pop-up.",
                                        ]
                                    },    
                                    {
                                        "type": "paragraph",
                                        "content": "Formulaire d'Ajout d'Appareil"
                                    },    
                                    {
                                        "type": "paragraph",
                                        "content": "Le formulaire contient les champs suivants :"
                                    }, 
                                    {
                                        "type": "list",
                                        "items": [
                                        "•	Type de Produit : Sélectionnez le type d'appareil.",
                                        "•	Mois et Année d'Installation : Précisez le mois et l'année.",
                                        "•	Marque du Produit : Choisissez la marque.",
                                        "•	Référence : Saisissez la référence de l'appareil.",
                                        "•	Puissance du Produit : Indiquez la puissance.",
                                        "•	Photo du Produit : Option pour ajouter une photo.",
                                        "•	Votre Appareil est-il Entretenu ? : Oui/Non.",
                                        "•	L'Adresse d'Intervention est-elle la Vôtre ? : Oui/Non.",
                                        "•	Validez le Google reCAPTCHA et cliquez sur \"Ajouter\".",
                                        ]
                                    },  
                                    {
                                        "type": "paragraph",
                                        "content": "Liste des Appareils du Client:"
                                    },   
                                    {
                                        "type": "paragraph",
                                        "content": "Une fois que vous avez ajouté un appareil, la page affiche également une section avec la liste de tous les appareils du client.  Cette liste est présentée sous forme d'un tableau organisé en sept colonnes :"
                                    },   
                                    {
                                        "type": "list",
                                        "items": [
                                        "•	Création : Date d'ajout.",
                                        "•	Produit : Type d'appareil.",
                                        "•	Année d'Installation : Année d'installation.",
                                        "•	Marque : Marque de l'appareil.",
                                        "•	Référence : Référence de l'appareil.",
                                        "•	Maintenance : Entretien régulier ou non.",
                                        "•	Actions : Options pour supprimer ou voir les détails de l'appareil.",

                                        ]
                                    },                                                  
                                ],
                    }, 
                ]

            },
            {
                "title": "4.4 Devis",
                "items": [
                    {
                        "type": "paragraph",
                        "content": "La page des devis de l'application LeBonTechnicien.fr vous permet de consulter la liste complète de tous les devis qui ont été émis et acceptés lors de vos interventions. Pour y accéder, il vous suffit de cliquer sur l'onglet \"Devis\" dans le menu principal."
                    }, 
                    {
                        "type": "paragraph",
                        "content": "Chaque devis est présenté de manière claire et organisée, vous permettant d'avoir un aperçu rapide des informations essentielles. Vous pouvez visualiser les détails de chaque devis et les télécharger au format PDF."
                    }, 
                    {
                        "type": "paragraph",
                        "content": "Grâce à la page des devis, vous bénéficiez d'un contrôle total sur le processus d'intervention et de la possibilité de visualiser tous les devis en un seul endroit pratique. Vous pouvez revenir sur cette page à tout moment pour consulter vos devis antérieurs, télécharger les documents nécessaires et suivre le statut de chaque devis accepté"
                    },
                    {
                        "type": "paragraph",
                        "content": "L'interface conviviale et intuitive de la page des devis vous permet de naviguer facilement à travers les informations. Toutes les informations fournies dans cette section sont claires, faciles à comprendre pour vous offrir la meilleure expérience utilisateur possible"
                    },
                ],

            },
            {
                "title": "4.5 Factures",
                "items": [
                    {
                        "type": "paragraph",
                        "content": "La page des factures de l'application LeBonTechnicien.fr vous permet d'accéder facilement à la liste complète de toutes les factures associées à vos interventions. Pour y accéder, il vous suffit de cliquer sur l'onglet \"Factures\" dans le menu principal."
                    }, 
                    {
                        "type": "paragraph",
                        "content": "Chaque facture est présentée de manière claire et organisée, vous permettant d'avoir un aperçu rapide des informations essentielles telles que la date, le numéro de facture, le montant et les détails de l'intervention correspondante. Vous pouvez consulter et télécharger chaque facture au format PDF."
                    }, 
                    {
                        "type": "paragraph",
                        "content": "La page des factures constitue un point de référence central pour toutes vos transactions financières avec les techniciens. Vous pouvez consulter les factures correspondant à chaque intervention, vérifier les montants payés, suivre les paiements en attente et garder une trace précise de vos transactions."
                    },
                    {
                        "type": "paragraph",
                        "content": "Grâce à l'interface conviviale de la page des factures, vous pouvez facilement naviguer entre les différentes factures et accéder rapidement aux informations pertinentes. "
                    },
                    {
                        "type": "paragraph",
                        "content": "L'objectif de cette page est de vous offrir une expérience utilisateur optimale en vous permettant de visualiser, télécharger et gérer facilement toutes vos factures en un seul endroit. Toutes les informations fournies dans cette section sont claires, faciles à comprendre pour vous offrir une expérience fluide et agréable."
                    },
                ],

            },
            {
                "title": "4.6 Notifications",
                "items": [
                    {
                        "type": "paragraph",
                        "content": "La page des notifications de l'application LeBonTechnicien.fr vous permet de rester informé de tous les événements importants se déroulant dans l'application. Vous pouvez y accéder en cliquant sur l'onglet \"Notifications\" dans le menu principal ou en cliquant sur l'icône en forme de cloche."
                    }, 
                    {
                        "type": "paragraph",
                        "content": "Cette page vous présente une liste complète des notifications correspondant à diverses actions et événements dans l'application. Chaque notification est accompagnée d'une brève description qui vous permet de comprendre rapidement ce qui s'est passé. Que ce soit des mises à jour sur l'état de vos demandes d'intervention, des réponses des techniciens ou d'autres informations pertinentes, vous trouverez toutes les notifications regroupées ici."
                    }, 
                    {
                        "type": "paragraph",
                        "content": "La première section de la page des notifications affiche la liste complète des notifications. Chaque notification est formulée de manière concise pour vous fournir une compréhension claire de l'événement en question. Vous pourrez ainsi rester informé des développements importants et prendre les mesures nécessaires en conséquence."
                    },
                    {
                        "type": "paragraph",
                        "content": "La deuxième section de la page des notifications est dédiée aux techniciens qui ont répondu à vos demandes d'intervention. Vous y trouverez une liste des techniciens avec lesquels vous avez une interaction en cours. Vous aurez la possibilité d'accepter ou de refuser leurs réponses, vous permettant ainsi de gérer efficacement les propositions et de choisir le technicien qui convient le mieux à vos besoins."
                    },
                    {
                        "type": "paragraph",
                        "content": "L'objectif de la page des notifications est de vous offrir une vue d'ensemble claire et concise de tous les événements et interactions qui se déroulent dans l'application LeBonTechnicien.fr. Vous pourrez ainsi prendre des décisions éclairées, suivre l'état de vos demandes et interagir avec les techniciens de manière pratique et efficace."
                    },
                    {
                        "type": "paragraph",
                        "content": "Le contenu rédigé dans cette section est conçu pour être facilement compréhensible, dynamique et pertinent."
                    },
                ],

            },
            {
                "title": "4.7 Profile",
                "items": [
                    {
                        "type": "paragraph",
                        "content": "La page du profil de l'application LeBonTechnicien.fr vous offre un espace dédié pour consulter et mettre à jour vos informations personnelles. Vous pouvez accéder à cette page en cliquant sur l'onglet \"Profil\" dans le menu principal ou en cliquant sur votre photo à droite de l'icône en forme de cloche."
                    }, 
                    {
                        "type": "paragraph",
                        "content": "Lorsque vous arrivez sur la page du profil, vous trouverez une liste complète de toutes les informations personnelles que vous avez fournies lors de votre première connexion dans le formulaire de la page \"Informations\". Ces informations sont essentielles pour assurer une communication efficace et un suivi précis de vos demandes et interventions."
                    }, 
                    {
                        "type": "paragraph",
                        "content": "Dans cette page, vous avez la possibilité de consulter et de modifier certaines de vos informations personnelles. "
                    },
                    {
                        "type": "list",
                        "items": [
                            " Nom : Cette information est non modifiable et affiche votre nom tel que vous l'avez enregistré lors de votre inscription.",
                            " Prénom : De même, votre prénom est non modifiable et apparaît tel que vous l'avez indiqué lors de votre inscription.",
                            " Email : Vous pouvez modifier votre adresse e-mail en cas de besoin. Assurez-vous de saisir une adresse valide pour recevoir les communications importantes de la plateforme",
                            "Mot de passe : Vous avez la possibilité de modifier votre mot de passe pour garantir la sécurité de votre compte. Veillez à choisir un mot de passe fort et confidentiel.",
                            "Adresse : Vous pouvez mettre à jour votre adresse postale si elle a changé depuis votre inscription. Assurez-vous de fournir une adresse correcte pour faciliter les communications et les interventions.",
                            "Téléphone : Si votre numéro de téléphone a changé, vous pouvez le mettre à jour dans cette section. Assurez-vous de fournir un numéro de téléphone valide pour une communication fluide.",
                            "Département : Si vous souhaitez modifier le département dans lequel vous êtes situé, vous pouvez le faire ici. Cela permettra de mieux organiser les interventions et de vous mettre en relation avec les techniciens de votre région",
                        ]
                    },
                    {
                        "type": "paragraph",
                        "content": "La page du profil vous offre ainsi un moyen pratique et sécurisé de gérer vos informations personnelles. Vous pouvez consulter et mettre à jour ces détails selon vos besoins. Assurez-vous de maintenir vos informations à jour pour une expérience optimale sur l'application LeBonTechnicien.fr."
                    },

                ],

            },
          ],

        }
      ]
    }
];

export default guideClientPro;
  