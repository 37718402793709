import React, { useEffect, useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  BreadCrumb,
  DashCards,
  DashLayout,
  LineChart,
  PieChart,
  StatCards,
  TableDash,
} from "../../../Components";
import "chartjs-plugin-datalabels";
import { useStateContext } from "../../../contexts/ContextProvider";
import PieChartUser from "../../../Components/Dashboard/Charts/PieChartUser";
import SparkLineUsers from "../../../Components/Dashboard/Charts/SparkLineUsers";
import IncomeTable from "../../../Components/Dashboard/IncomeTable";

const UserDash = () => {
  const [history, setHistory] = useState([]);
  const [techs, setTechs] = useState([]);
  const [users, setUsers] = useState([]);
  const [superviseur, setSuperViseur] = useState([]);
  const { userType } = useStateContext();
  const apiUrl = 'https://lebontechnicien.net'

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(
          userType === "Tech"
            ? data.filter(
                (item) =>
                  item.client_accept === sessionStorage.getItem("id") &&
                  item.type === "Demande d'intervention"
              )
            : data.filter((item) => item.type === "Demande d'intervention")
        );
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type }),
        });
        const users = await response.json();
        switch (type) {
          case "Tech":
            setTechs(users);
            break;
          case "Superviseur":
            setSuperViseur(users);
            break;
          case "User":
            setUsers(users);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData("Tech");
    fetchData("Superviseur");
    fetchData("User");
  }, []);


  return (
    <>
    {userType === "Tech" ? (
        <BreadCrumb
          From={""}
          To={"Statistiques"}
          hrefFrom={""}
        />
      ) : (
        <BreadCrumb
          From={""}
          To={"Profile"}
          hrefFrom={"/recherche"}
        />
      )}
    <div
      id="main-content"
      class="h-full w-full bg-gray-50 relative overflow-y-auto pb-10"
    >
      <main>
        <div class="pt-6 px-4 w-[94%] ml-auto mr-auto">
          <h3> Statistiques filtré par période (jour, semaine, mois ou année)</h3>
          <DashCards />
          <TableDash />
          <IncomeTable/>
          <div class="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
            <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  ">
              <div class="flex items-center justify-between mb-20">
                <div class="flex-shrink-0">
                  <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                    {history.length}
                  </span>
                  <h3 class="text-base font-normal text-gray-500">Interventions</h3>
                </div>
                {/*<div class="flex items-center justify-end flex-1 text-green-500 text-base font-bold">
                  12.5%
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
  </div>*/}
              </div>
              <div class="mb-4 flex items-center center mt-4">
                <PieChart />
              </div>             
            </div>

            <div class="bg-white shadow rounded-lg mb-4 p-4 sm:p-6 h-full center">
              <LineChart />
            </div>        
          </div>
          {userType === 'Admin' && 
            <div class="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 gap-4 mt-4 mb-8">
              <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
                <div class="flex items-center justify-between mb-20">
                  <div class="flex-shrink-0">
                    <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                      {users.length + techs.length}
                    </span>
                    <h3 class="text-base font-normal text-gray-500">Utilisateurs</h3>
                  </div>
                  {/*<div class="flex items-center justify-end flex-1 text-green-500 text-base font-bold">
                    12.5%
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
    </div>*/}
                </div>
                <div class="mb-4 flex items-center center mt-4">
                  <PieChartUser />
                </div>             
              </div>

              <div class="bg-white shadow rounded-lg mb-4 p-4 sm:p-6 h-full center">
                <SparkLineUsers />
              </div>        
            </div>  
          }        
        </div>
      </main>
    </div>
    </>
  );
};

export default UserDash;
