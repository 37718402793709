import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components";
import reclamation from "../../../Assets/Icons/liste-reclamations.png";
import litige from "../../../Assets/Icons/Litiges.png";
import listeTech from "../../../Assets/Icons/liste-tech.png";

const SuperUser = () => {
  const [history, setHistory] = useState([]);
  const [litiges, setLitige] = useState([]);
  const [litigeFix, setLitigeFix] = useState([]);
  const [claim, setClaim] = useState([]);
  const [claimFix, setClaimFix] = useState([]);
  const [techs, setTechs] = useState([]);
  const [pending, setPending] = useState([]);
  const [accepted, setAccepted] = useState([]);
  const [denied, setDenied] = useState([]);  
  const apiUrl = 'https://lebontechnicien.net'

  //fetches Claims and Disputes
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        const litigeLength = data.filter(
          (request) =>
            request.status === "litige" &&
            request.type === "Demande d'intervention"
        ).length;
        setLitige(litigeLength)
        
        const claimLength = data.filter(
          (request) =>
            request.status === "Pending" &&
            request.type === "Demande de Réclamation"
        ).length;
        setClaim(claimLength)
        
        const litigeFixLength = data.filter(
          (request) =>
            request.status === "litige_fixed" &&
            request.type === "Demande d'intervention"
        ).length;
        setLitigeFix(litigeFixLength)
        
        const claimFixLength = data.filter(
          (request) =>
            request.status === "claim_fix" &&
            request.type === "Demande de Réclamation"
        ).length;
        setClaimFix(claimFixLength)
  
        // Use the obtained lengths as needed
        console.log("Litige Length:", litigeLength);
        console.log("Claim Length:", claimLength);
        console.log("Litige Fix Length:", litigeFixLength);
        console.log("Claim Fix Length:", claimFixLength);
  
        setHistory(data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type }),
        });
        const data = await response.json();
  
        const pendingLength = data.filter((item) => item.status === "Pending").length;
        const acceptedLength = data.filter((item) => item.status === "Confirmed").length;
        const deniedLength = data.filter((item) => item.status === "Denied").length;
  
        switch (type) {
          case "Tech":
            setTechs(data.reverse());
            setPending(pendingLength);
            setAccepted(acceptedLength);
            setDenied(deniedLength);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData("Tech"); // Call fetchData with the desired type
  
  }, []); // Empty dependency array to run the effect only once
  
  

  
  return (
    <div className="bg-white">
      <BreadCrumb From={""} To={"Tableau de bord"} hrefFrom={"#"} />
      <div className="max-w-screen-xl  ml-auto mr-auto border border-gray-200 rounded-lg mt-10 mb-50 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 p-10">
        <div className="flex justify-center  p-5  h-[200px]">
          <div className="m-auto cursor-pointer">
            <a href="/liste-des-utilisateurs">
              <img
                src={listeTech}
                alt="Liste Des Techniciens"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />
              <h3 className="text-center text-[16px] font-bold">
                Liste Des Techniciens
              </h3>
              <p className="text-center">
                Téchniciens en attente de vérification:{" "}
                <span className="font-bold text-yellow-600"> {pending} </span>
              </p>
              <p className="text-center">
                Téchniciens validés:{" "}
                <span className="font-bold text-green-600"> {accepted} </span>
              </p>
              <p className="text-center">
                Téchniciens refusés:{" "}
                <span className="font-bold text-red-600"> {denied} </span>
              </p>
            </a>
          </div>
        </div>

        <div className="flex justify-center  p-5  h-[200px] border-x border-l border-r border-[#d9d9d9]!important ">
          <div className="m-auto cursor-pointer  ">
            <a href="/litiges">
              <img
                src={litige}
                alt="Trouver un technicien"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />
              <h3 className="text-center text-[16px] font-bold text-center">
                Liste Des Litiges
              </h3>
              <p className="text-center">
                Litiges en cours:{" "}
                <span className="font-bold text-red-600"> {litiges} </span>
              </p>
              <p className="text-center">
                Litiges résolu:{" "}
                <span className="font-bold text-green-600"> {litigeFix} </span>
              </p>
            </a>
          </div>
        </div>

        <div className="flex justify-center  p-5  h-[200px]">
          <div className="m-auto cursor-pointer ">
            <a href="/réclamations">
              <img
                src={reclamation}
                alt=" Liste Des Réclamations"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />

              <h3 className="text-center text-[16px] font-bold">
                Liste Des Réclamations
              </h3>
              <p className="text-center">
                Réclamation en cours:{" "}
                <span className="font-bold text-red-600"> {claim} </span>
              </p>
              <p className="text-center">
                Réclamation résolu:{" "}
                <span className="font-bold text-green-600"> {claimFix} </span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperUser;
