export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
  
export const validatePhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(phone);
};

export const validatePassword = (password) => {
    // Minimum 8 characters
    const MIN_LENGTH = 10;
  
    // Check for minimum length
    if (password.length < MIN_LENGTH) {
      return false;
    }
  
    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }
  
    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }
  
    // Check for at least one digit
    if (!/\d/.test(password)) {
      return false;
    }
  
    // Check for at least one special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return false;
    }
  
    // Password is considered strong
    return true;
};

export const validateCreditCard = (creditCard) => {
    // Check if the credit card number is between 13 and 16 digits long
    if (!/^\d{13,16}$/.test(creditCard)) {
      return false;
    }
  
    // Luhn Algorithm to validate the credit card number
    let sum = 0;
    let digit;
    let shouldDouble;
    for (let i = creditCard.length - 1; i >= 0; i--) {
      digit = creditCard.substring(i, (i + 1));
      if (shouldDouble) {
        if ((digit *= 2) > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }
  
    // If the sum is divisible by 10, the credit card number is valid
    if (sum % 10 === 0) {
      return true;
    }

    return false;
};

export const validateTextInput = (text) => {
  // Check if the text input only contains letters and spaces
  if (!/^[a-zA-Z\s]+$/.test(text)) {
    return false;
  }
  const bannedWords = ["un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf", "dix"];
  // Check if the input text is not one of the banned words
  if (bannedWords.includes(text.toLowerCase().trim())) {
    return false;
  }
  return true;
};

export const validateTelephoneInput = (text) => {
  if (!/^\d{10}$/.test(text)) {
    return 'Le numéro de téléphone doit contenir 10 chiffres.';
  }
  return '';
};

export const validatePostalCodeInput = (text) => {
  if (!/^\d{5}$/.test(text)) {
    return 'Le code postal doit contenir 5 chiffres.';
  }
  return '';
};

export const validateSiretSirenInput = (text) => {
  if (!/^\d{9,14}$/.test(text)) {
    return 'Le numéro Siret ou Siren doit contenir entre 9 et 14 chiffres.';
  }
  return '';
};

export const validateFrenchTVAInput = (text) => {
  if (!/^FR\d{11}$/.test(text)) {
    return 'Le numéro de TVA doit commencer par FR et contenir 11 chiffres sans espaces.';
  }
  return '';
};
