import dashboard from "../Assets/dashboard.png";
import stats from "../Assets/stats.png";
import Suivie from "../Assets/suivi-projet.png";
import notifications from "../Assets/notifications.png";
import profile from "../Assets/liste-tech.png";

const TechFonc = [
    {
        id: 1,
        title: "Tableau De Bord",
        img: dashboard     
    },
    {
        id: 2,
        title: "stats",
        img: stats,        
    },
    {
        id: 3,
        title: "Suivre mes interventions ",
        img: Suivie,     
    },
    {
        id: 4,
        title: "Notifications",
        img: notifications,  
    },
    {
        id: 5,
        title: "Profil",
        img: profile,        
    },
 
];
export default TechFonc;