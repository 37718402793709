import React, { useEffect, useState } from "react";
import { BreadCrumb, CRUDTable, PdfViewer } from "../../../Components";
import {
  IoEyeOutline,
  IoPencil,
  IoTrashOutline,
  IoCloudDownloadOutline,
  IoCheckmark,
  IoCloseOutline,
} from "react-icons/io5";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const UserDevis = () => {
  const [userData, setUserData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const apiUrl = 'https://lebontechnicien.net'

  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        // Access the "files" property and filter based on file path
        const filteredUserData = data.files.filter((item) =>
          item.file_path.startsWith("static/Devi-") && item.file_path.endsWith(".pdf")
        );
        setUserData(filteredUserData);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  return (
    <div className="">
      <BreadCrumb From={""} To={"Devis"} hrefFrom={"/tableau-de-bord"} />
      <div className="mt-10 mb-10 max-w-screen-xl  ml-auto mr-auto">
        <h3 className="text-lg font-medium leading-6 text-gray-900 title-font-h3 mb-10">
          Liste des devis
        </h3>
        {userData?.length > 0 ? (
          <>
            {/*<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-6 sm:px-6 mb-2 rounded-md ml-auto mr-auto">

                  <p> Devis</p>
                  <p> Intervention Id</p>
                  <p> Action</p>

          </div>*/}
            {userData?.map((file, index) => (
              <>
                <div
                  key={index}
                  className="bg-[#f8f8f8]  px-4 py-5 flex justify-between  sm:px-6 mb-2 rounded-md ml-auto mr-auto "
                >
                  <p>{file.file_path.split("/").pop()}</p>
                  {/*<p> {file.req_id}</p>*/}

                  <>
                    <div style={{ display: "flex", height: "100%" }}>
                      <div className="flex">
                        {/*<button
                        className="inline-flex justify-center rounded-md border border-green-600 bg-transparent py-2 px-4 text-sm font-medium text-green-600 hover:text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mt-4"
                        onClick={openModal}
                      >
                        Ouvrir le devis
          </button>*/}

                        <a
                          href={`${apiUrl}${file.file_path}`}
                          download
                          onClick={(e) => e.stopPropagation()}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="flex gap-2  p-[6px] ">
                            <IoCloudDownloadOutline className="mt-[4px]" />
                            Ouvrir le document
                          </div>
                        </a>
                        {/*<div className="flex gap-2  p-[6px] ">
                        <IoCheckmark className="mt-[4px]"/>
                      </div>
                      <div className="flex gap-2  p-[6px] ">
                        <IoCloseOutline className="mt-[4px]"/>
        </div> */}
                      </div>
                    </div>
                    {showModal && (
                      <>
                        <div className="fixed inset-0 z-10 overflow-y-auto">
                          <div
                            className="fixed inset-0 w-full h-full bg-black opacity-50"
                            onClick={closeModal}
                          ></div>
                          <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-transparent rounded-md">
                              <div className="mt-3">
                                <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                  <Document
                                    file={`${apiUrl}static/${file.file_path}`}
                                    onLoadSuccess={onDocumentLoad}
                                  >
                                    {Array.from(
                                      new Array(numPages),
                                      (el, index) => (
                                        <Page
                                          key={`page_${index + 1}`}
                                          pageNumber={index + 1}
                                        />
                                      )
                                    )}
                                  </Document>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </>
            ))}
          </>
        ) : (
          <div className="h-[30vh]">
            <div className="mt-[20vh]">
              <h1 className="text-center">
                {" "}
                Il semble que vous n'ayez pas de devis pour le moment{" "}
              </h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDevis;
