import React from "react";
import { parse, format, compareAsc } from "date-fns";

function formatFrenchDate(dateString) {
  const [day, month, year] = dateString.split("/");
  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const monthIndex = parseInt(month) - 1;
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;
  return formattedDate;
}

const Cardes = ({ formation, date, address, contact }) => {
  return (
    <div className="card">
      <h3
        style={{
          fontWeight: 700,
          fontSize: "25px",
          lineHeight: "140%",
          color: "#3E9F36",
          textAlign: "center",
          /*height: "80px"*/
        }}
      >
        Formation {formation}
      </h3>
      <p
        style={{
          fontWeight: 700,
          fontSize: "13px",
          lineHeight: "20px",
          textAlign: "center",
          /*height: "40px"*/
        }}
      >
        Du {formatFrenchDate(date.Du)} Au {formatFrenchDate(date.Au)}
      </p>
      <p
        style={{
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "21px",
          textAlign: "center",
          color: "#A9A9A9",
          marginTop: "15px",
          marginBottom: "15px",
          /*height: "60px"*/
        }}
      >
        {address["Complément d'adresse"]}, {address["1 route de Strasbourg"]},{" "}
        {address["Code postal"]}, {address.Ville}
      </p>
      <p
        style={{
          fontWeight: 700,
          fontSize: "13px",
          lineHeight: "19.5px",
          textAlign: "center",
        }}
      >
        Contact :
      </p>
      <p
        style={{
          fontWeight: 700,
          fontSize: "13px",
          lineHeight: "19.5px",
          textAlign: "center",
        }}
      >
        {contact["Email contact"]}
      </p>
      <p
        style={{
          fontWeight: 700,
          fontSize: "13px",
          lineHeight: "19.5px",
          textAlign: "center",
        }}
      >
        {contact["Numéro téléphone contact"]}
      </p>
    </div>
  );
};

const CardSystem = ({ data }) => {
  const today = new Date();
  const sortedCards = data
    .filter((card) => {
      const [day, month, year] = card["Du"].split("/");
      const cardDate = new Date(`${year}-${month}-${day}`);
      return !isNaN(cardDate) && cardDate >= today;
    })
    .sort((a, b) => {
      const [dayA, monthA, yearA] = a["Du"].split("/");
      const [dayB, monthB, yearB] = b["Du"].split("/");
      const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
      const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
      return dateA - dateB;
    });

  return (
    <section className="aventage-client">
      <div className="card-system">
        {sortedCards.map((card, index) => (
          <Cardes
            key={index}
            formation={card["Formation"]}
            date={{ Du: card["Du"], Au: card["Au"] }}
            address={{
              "Complément d'adresse": card["Complément d'adresse"],
              "1 route de Strasbourg": card["Adresse"],
              "Code postal": card["Code postal"],
              Ville: card["Ville"],
            }}
            contact={{
              "Email contact": card["Email contact"],
              "Numéro téléphone contact": card["Numéro téléphone contact"],
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default CardSystem;
