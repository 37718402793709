import React, { useEffect, useState } from "react";
import avatar from "../../../Assets/DummyData/avatar.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BreadCrumb } from "../../../Components";
import { useStateContext } from "../../../contexts/ContextProvider";
import { IoPencil } from "react-icons/io5";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Départements } from "../../../Constants/dummy";
import Autocomplete from "react-google-autocomplete";

const UserProfile = () => {
  const [file, setFile] = useState(null);
  const imageUrl = avatar;
  const { userType } = useStateContext();
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [raisonSocial, setRaisonSocial] = useState("");
  const [recheckEmail, setReCheckEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [adresse, setAdresse] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [ville, setVille] = useState("");
  const [postal, setPostal] = useState("");
  const [zone, setZone] = useState("");
  const [telephone, setTelephone] = useState("");
  const [assurance, setAssurance] = useState("");
  const [nassurance, setNassurance] = useState("");
  const [rib, setRib] = useState("");
  const [siret, setSiret] = useState("");
  const [typeDuBien, setTypeDuBien] = useState([]);
  const [type, setType] = useState("");
  const [confirm, setConfirm] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [userData, setUserData] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [newData, setNewData] = useState(userData.adresse);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showRegionModal, setShowRegionModal] = useState(false);
  const [showAssureurModal, setShowAssureurModal] = useState(false);
  const [showNuméroAssuranceModal, setShowNuméroAssuranceModal] =
    useState(false);
  const [showAttestationAssurance, setShowAttestationAssurance] =
    useState(false);

  const [showProfileImg, setShowProfileImg] = useState(false);
  const [showQualification, setShowQualification] = useState(false);

  const [showRIBModal, setShowRIBModal] = useState(false);
  const [passwordCheckError, setPasswordCheckError] = useState("");
  const [emailCheckError, setEmailCheckError] = useState("");
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [photoIdentite, setPhotoIdentite] = useState("");
  const [assuranceImg, setAssuranceImg] = useState(null);
  const [assuranceImg_mobil, setAssuranceImgMbile] = useState(null);
  
  const [qualificationImg, setQualificationImg] = useState(null);
  const [newAssuranceImg, setNewAssuranceImg] = useState(null);
  const [newPhotoIdentite, setNewPhotoIdentite] = useState(null);
  const [newQualificationImg, setNewQualificationImg] = useState(null);
  const [entreprise, setEntreprise] = useState("non");

  const apiUrl = 'https://lebontechnicien.net'

  useEffect(() => {
    setEntreprise(sessionStorage.getItem("entreprise"));
  }, []);

  console.log("assuranceImg", newAssuranceImg);

  const [propos, setPropos] = useState("");
  const navigate = useNavigate();

  const handleRegionChange = (event) => {
    const region = event.target.value;
    if (event.target.checked) {
      setSelectedRegions([...selectedRegions, region]);
    } else {
      setSelectedRegions(selectedRegions.filter((r) => r !== region));
    }
  };

  /* const handleAssuranceImg = (event) => {
    setAssuranceImg(event.target.files);
    setQualificationImg(prevQualificationImg => prevQualificationImg);
    console.log("assuranceImg", assuranceImg);
  };
  
  const handleQualificationImgs = (event) => {
    setQualificationImg(event.target.files);
    setAssuranceImg(prevAssuranceImg => prevAssuranceImg);
    console.log("qualificationImg", qualificationImg);
  }; */

  const handleAssuranceImg = (event) => {
    setNewAssuranceImg(event.target.files);
  };

  const handlePhotoIdentite = (event) => {
    setNewPhotoIdentite(event.target.files);
    console.log("newPhotoIdentite", newPhotoIdentite);
  };

  const handleQualificationImgs = (event) => {
    setNewQualificationImg(event.target.files);
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${apiUrl}/logout`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data.msg);
      }
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("requests");
      setIsLoading(false);
      alert(data.msg);
      setIsLoggedOut(true);
    } catch (err) {
      setIsLoading(false);
      alert(err.message);
    }
  };

  useEffect(() => {
    if (isLoggedOut) {
      navigate("/");
    }
  }, [isLoggedOut]);

  const handleEmailConfirm = async () => {
    const response = await fetch(
      `${apiUrl}/tech/confirm/${id}/${confirm}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    console.log(data);
    setShowModal(false);
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (type === "Tech" && status === "Email Pending") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [type, status]);

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }

        setUserData(data);
        setNom(data.nom);
        setPrenom(data.prenom);
        setEmail(data.email);
        setPassword(data.password);
        setAdresse(data.adresse);
        setTelephone(data.telephone);
        setTypeDuBien(data.typeDuBien);
        setZone(data.zone);
        setConfirm(data.confirmation_id);
        setType(data.type);
        setStatus(data.status);
        setId(data._id);
        setAssuranceImg(data.assuranceImg);
        setAssuranceImgMbile(data.assuranceImg_mobil);
        setPhotoIdentite(data.photoIdentite);
        setRaisonSocial(data.raisonSocial);
        setPropos(data.propos);
        setVille(data.ville);
        setPostal(data.postale);
        setQualificationImg(data.qualificationImg);
        setAssurance(data.assurance);
        setNassurance(data.nassurance);
        setRib(data.iban);
        setSiret(data.siret);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
  };

  const handleOpenEmailModal = () => {
    setShowEmailModal(true);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleOpenPasswordModal = () => {
    setShowPasswordModal(true);
  };

  const handleToggleAddressModal = () => {
    setShowAddressModal(!showAddressModal);
  };

  const handleRegionModal = () => {
    setShowRegionModal(!showRegionModal);
  };

  const handleTogglePhonesModal = () => {
    setShowPhoneModal(!showPhoneModal);
  };

  const handleToggleAssuranceModal = () => {
    setShowAssureurModal(!showAssureurModal);
  };

  const handleToggleAssuranceImageModal = () => {
    setShowAttestationAssurance(!showAttestationAssurance);
  };

  const handleToggleProfileImg = () => {
    setShowProfileImg(!showProfileImg);
  };

  const handleQualificationImageModal = () => {
    setShowQualification(!showQualification);
  };

  const handleToggleNassuranceModal = () => {
    setShowNuméroAssuranceModal(!showNuméroAssuranceModal);
  };

  const handleToggleRibModal = () => {
    setShowRIBModal(!showRIBModal);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNom = (event) => {
    setNom(event.target.value);
  };

  const handlePrénom = (event) => {
    setPrenom(event.target.value);
  };

  const handleAdresse = (event) => {
    setAdresse(event.target.value);
  };

  const handlePostal = (event) => {
    setPostal(event.target.value);
  };

  const handleVille = (event) => {
    setVille(event.target.value);
  };

  const handleTelephone = (event) => {
    setTelephone(event.target.value);
  };

  const handleAssurance = (event) => {
    setAssurance(event.target.value);
  };

  const handleNassurance = (event) => {
    setNassurance(event.target.value);
  };

  const handleRib = (event) => {
    setRib(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setEmail(email);
  };

  const handleCheckPassword = (event) => {
    setCheckPassword(event.target.value);
    setEmail(email);
    if (password !== event.target.value) {
      setPasswordCheckError("Les mots de passe ne correspondent pas.");
    } else {
      setPasswordCheckError(null);
    }
  };

  const handlecheckEmail = (event) => {
    setReCheckEmail(event.target.value);
    if (email !== event.target.value) {
      setEmailCheckError("L'Email ne correspondent pas.");
    } else {
      setEmailCheckError(null);
    }
  };

  const handleTypeDuBien = (e) => {
    if (e.target.checked) {
      newTypeDuBien.push(e.target.name);
    } else {
      newTypeDuBien.splice(newTypeDuBien.indexOf(e.target.name), 1);
    }
    setUserData({ ...userData, typeDuBien: newTypeDuBien });
    console.log("the new type du bien", userData);
  };

  const [newTypeDuBien, setNewTypeDuBien] = useState([userData.typeDuBien]);

  const handleClick = async () => {
    const formData = new FormData();
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("adresse", adresse);
    formData.append("zone", selectedRegions);
    formData.append("telephone", telephone);
    formData.append("placeId", placeId);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("propos", propos);
    formData.append("typeDuBien", newTypeDuBien);
    formData.append("ville", ville);
    formData.append("postal", postal);
    formData.append("assurance", assurance);
    formData.append("nassurance", nassurance);
    formData.append("iban", rib);

    /*if (assuranceImg && assuranceImg.length > 0) {
      for (let i = 0; i < assuranceImg.length; i++) {
        formData.append("assuranceImg", assuranceImg[i]);
      }
    }    

    if (qualificationImg && qualificationImg.length > 0) {
      for (let i = 0; i < qualificationImg.length; i++) {
        formData.append("qualificationImg", qualificationImg[i]);
      }
    }*/

    if (newPhotoIdentite !== photoIdentite) {
      if (newPhotoIdentite && newPhotoIdentite.length > 0) {
        for (let i = 0; i < newPhotoIdentite.length; i++) {
          formData.append("photoIdentite", newPhotoIdentite[i]);
        }
      }
    }

    if (newAssuranceImg !== assuranceImg) {
      if (newAssuranceImg && newAssuranceImg.length > 0) {
        for (let i = 0; i < newAssuranceImg.length; i++) {
          formData.append("assuranceImg", newAssuranceImg[i]);
        }
      }
    }

    if (newQualificationImg !== qualificationImg) {
      if (newQualificationImg && newQualificationImg.length > 0) {
        for (let i = 0; i < newQualificationImg.length; i++) {
          formData.append("qualificationImg", newQualificationImg[i]);
        }
      }
    }

    const response = await fetch(`${apiUrl}/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    });
    const data = await response.json();

    if (data) {
      toast.success(data);
      window.location.reload();
    } else if (data.msg === "No such User") {
      toast.error(data.msg);
    }
  };

  const handleRemoveUser = async () => {
    const formData = {
      nom,
      prenom,
      email,
      password,
      adresse,
      telephone,
      typeDuBien: newTypeDuBien,
    };

    const emailStorage = sessionStorage.getItem("email");
    console.log(emailStorage);
    const response = await fetch(`${apiUrl}/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(formData, emailStorage),
    });
    const data = await response.json();
    console.log(data);
    window.location.reload();
    window.location.href = "/";
  };

  const handleEmailClick = () => {
    setShowEmailModal(true);
  };

  const handleConfirmDelete = () => {
    handleRemoveUser();
    setShowDeleteModal(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      {/*<BreadCrumb From={""} To={"Profile"} hrefFrom={"/tableau-de-bord"}/>*/}
      {userType === "User" ? (
        <BreadCrumb
          From={""}
          To={"Profil"}
          hrefFrom={"/tableau-de-bord"}

        />
      ) : (
        <BreadCrumb
          From={""}
          To={"Profil"}
          hrefFrom={""}
        />
      )}
      <div className="mt-10 mb-10 sm:mt-0 max-w-screen-xl  ml-auto mr-auto">
        <ToastContainer />
        <div className="overflow-hidden bg-transparent shadow sm:rounded-lg w-[100%] ml-auto mr-auto mt-10">
          {/*<div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto">
            <h3 className="text-lg font-medium leading-6 text-gray-900 title-font-h3">
              Profil
            </h3>
      </div>*/}
          <div className="flex justify-center mt-8 mb-8 ml-8">
            <div>
              <span className="inline-block h-32 w-32 overflow-hidden rounded-full bg-gray-100">
                {photoIdentite !== "null" &&
                photoIdentite !== undefined &&
                photoIdentite !== "" ? (
                  <img
                    onClick={handleToggleProfileImg}
                    alt="user"
                    className=" object-contain fit-image w-32 h-32 rounded-full mx-auto"
                    src={`https://lebontechnicien.net/${photoIdentite}`}
                  />
                ) : (
                  <div className="flex justify-center mt-8 ml-auto mr-auto">
                    <span className="inline-block h-20 w-20 overflow-hidden rounded-full bg-gray-100">
                      <svg
                        onClick={handleToggleProfileImg}
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                  </div>
                )}
                <svg
                  className="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
            </div>

            <div className="ml-20 w-[80%]">
              {userType === "Tech" || entreprise === "oui"? (
                <h2 className="text-[30px]">{raisonSocial}</h2>
              ) : (
                <h2 className="text-[30px]">
                  {nom} {prenom}
                </h2>
              )}
              {/*<p>{propos ?? null}</p>*/}
              <div className="w-[80%]">
                <p>{propos ?? null}</p>
              </div>
            </div>
          </div>
          <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
            <dl>
              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-black border-r border-solid border-black">Nom</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <p>{nom}</p>
                </dd>
              </div>
              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                  Prénom:
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <p>{prenom}</p>
                  </dd>
                </dd>
              </div>
              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                  Email
                  <IoPencil
                    className="ml-4 cursor-pointer"
                    onClick={handleOpenEmailModal}
                  />
                </dt>
                <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <p>{email}</p>
                  </dd>
                </dd>
              </div>
              {(userType === "Tech" ||
                (userType === "User" && entreprise === "oui")) && siret &&  (
                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                    Siret Ou Siren
                  </dt>
                  <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <p>{siret}</p>
                    </dd>
                  </dd>
                </div>
              )}
              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                  Mot De Passe
                  <IoPencil
                    className="ml-4 cursor-pointer"
                    onClick={handleOpenPasswordModal}
                  />
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <p>*********</p>
                </dd>
              </div>
              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                  Adresse
                  <IoPencil
                    className="ml-4 cursor-pointer"
                    onClick={handleToggleAddressModal}
                  />
                </dt>
                <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <p>
                      {adresse}
                    </p>
                  </dd>
                </dd>
              </div>
              {telephone && (
                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                    Telephone
                    <IoPencil
                      className="ml-4 cursor-pointer"
                      onClick={handleTogglePhonesModal}
                    />
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <p>{telephone}</p>
                  </dd>
                </div>
              )}
              {zone &&
                (Array.isArray(zone) ? (
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                    Zone d'intervention
                      <IoPencil
                        className="ml-4 cursor-pointer"
                        onClick={handleRegionModal}
                      />
                    </dt>
                    <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <p>{zone.join(", ")}</p>
                    </dd>
                  </div>
                ) : (
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                    Zone d'intervention
                      <IoPencil
                        className="ml-4 cursor-pointer"
                        onClick={handleRegionModal}
                      />
                    </dt>
                    <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <p>{zone}</p>
                    </dd>
                  </div>
                ))
              }

              {userType === "Tech" ? (
                <>
                  {assurance && (
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                        Nom d’assureur
                        <IoPencil
                          className="ml-4 cursor-pointer"
                          onClick={handleToggleAssuranceModal}
                        />
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <p>{assurance}</p>
                      </dd>
                    </div>
                  )}
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                    Numéro du contrat d’assurance
                      <IoPencil
                        className="ml-4 cursor-pointer"
                        onClick={handleToggleNassuranceModal}
                      />
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <p>{nassurance}</p>
                    </dd>
                  </div>
                  
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                        Attestation d’assurance
                        <IoPencil
                          className="ml-4 cursor-pointer"
                          onClick={handleToggleAssuranceImageModal}
                        />
                      </dt>
                      {assuranceImg_mobil !== 'null' && assuranceImg_mobil !== '' && assuranceImg !== 'null' && assuranceImg !== '' &&(
                        <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <img
                              src={`https://lebontechnicien.net/${assuranceImg}`}
                              alt="img"
                              className="h-[30%] w-[30%] object-cover  mt-0 mb-14 "
                            />
                          </dd>
                        </dd>
                      )}
                    </div>

                  {qualificationImg !== "null" && qualificationImg !== null &&(
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black flex">
                        Mes qualifications
                        <IoPencil
                          className="ml-4 cursor-pointer"
                          onClick={handleQualificationImageModal}
                        />
                      </dt>
                      <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <button
                          className=" focus:outline-none"
                          onClick={() =>
                            window.open(
                              `${apiUrl}${qualificationImg}`,
                              "_blank"
                            )
                          }
                        >
                          {qualificationImg?.substring(7)}
                        </button>
                      </dd>
                    </div>
                  )}
                </>
              ) : null}
            </dl>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              onClick={handleDeleteClick}
              className=" userProfiledelete-btn inline-flex justify-center rounded-md border  ml-2 border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Supprimer
            </button>
                  </div>
        </div>
      </div>
      {showModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-white opacity-100"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              {/*relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg*/}
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md ">
                <div className="mt-3 sm:flex justify-center">
                  {/*<div className="mt-2 text-center  sm:text-center">
                    <h4 className="text-lg font-medium text-gray-800 text-center">
                      Veuillez confirmer la demande d'enregistrement
                    </h4>
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                        onClick={() => handleEmailConfirm()}
                      >
                        Confirmer
                      </button>
                    </div>
      </div>*/}
                  <div className="flex flex-col items-center justify-center h-screen bg-transparent w-full ml-auto mr-auto">
                    <h2 className="text-3xl font-medium text-gray-700">
                      Félicitations!
                    </h2>
                    <p className="mt-4 text-lg text-gray-700 text-center">
                      Votre compte a été créé avec succès. Un e-mail de
                      confirmation a été envoyé à l'adresse e-mail associée à
                      votre compte.
                    </p>
                    <p className="mt-4 text-lg text-gray-700 text-center">
                      Veuillez vérifier votre boîte de réception, y compris les
                      courriers indésirables, pour valider votre compte.
                    </p>
                    <button
                      className=" mt-12 submit-button group relative flex w-[40%] justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2"
                      onClick={handleLogout}
                    >
                      Return to homepage
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showEmailModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre email
                    </h4>
                    <input
                      type="text"
                      name="email-address"
                      value={email}
                      onChange={handleEmail}
                      id="email-address"
                      autoComplete="email"
                      placeholder={isLoading ? "loading" : userData.email}
                      className=" margin-top mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {emailCheckError && recheckEmail.length > 0 && (
                      <div style={{ color: "red" }}>{emailCheckError}</div>
                    )}
                    <input
                      type="text"
                      name="recheckEmail"
                      value={recheckEmail}
                      onChange={handlecheckEmail}
                      id="recheckEmail"
                      autoComplete="recheckEmail"
                      placeholder={isLoading ? "loading" : userData.email}
                      className=" margin-top mt-1 block w-full p-[16px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleClick()}
                      >
                        Modifier
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                        onClick={() => handleCloseEmailModal()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showPasswordModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowPasswordModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre Mot De Passe
                    </h4>
                    <input
                      type="text"
                      name="password"
                      onChange={handlePassword}
                      id="password"
                      value={password}
                      autoComplete="password"
                      placeholder="********"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <input
                      type="text"
                      name="password"
                      onChange={handleCheckPassword}
                      id="password"
                      value={checkPassword}
                      autoComplete="password"
                      placeholder="********"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    {passwordCheckError && checkPassword.length > 0 && (
                      <div style={{ color: "red" }}>{passwordCheckError}</div>
                    )}
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleClick()}
                      >
                        Modifier
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                        onClick={() => handleClosePasswordModal()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showPhoneModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre Numéro de téléphone
                    </h4>
                    <input
                      type="text"
                      name="telephone"
                      value={telephone}
                      onChange={handleTelephone}
                      id="telephone"
                      autoComplete="telephone"
                      placeholder={isLoading ? "loading" : userData.telephone}
                      className=" margin-top mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleClick()}
                      >
                        Modifier
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                        onClick={() => handleTogglePhonesModal()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showAddressModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowPasswordModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre Adresse
                    </h4>
                    <Autocomplete
                      className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      apiKey="AIzaSyDK4L8SN7bnmjDstRZxqkqitqyQufbopLs"
                      name="adresse"
                      id="adresse"
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                      onPlaceSelected={(place) => {
                        console.log(place);
                        const address = place.formatted_address;
                        console.log("Selected address:", place);
                        const latitude = place.geometry.location.lat();
                        const longitude = place.geometry.location.lng();
                        const placeIds = place.place_id;
                        setAdresse(address);
                        setPlaceId(placeIds);
                      }}
                    />
                    {/*<input
                      type="text"
                      name="adresse"
                      onChange={handleAdresse}
                      id="adresse"
                      value={adresse}
                      autoComplete="adresse"
                      placeholder={adresse}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    <input
                      type="text"
                      name="ville"
                      onChange={handleVille}
                      id="ville"
                      value={ville}
                      autoComplete="ville"
                      placeholder={ville}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />*/}
                    <input
                      type="text"
                      name="postal"
                      onChange={handlePostal}
                      id="postal"
                      value={postal}
                      autoComplete="postal"
                      placeholder="code postal"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />

                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleClick()}
                      >
                        Modifier
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                        onClick={() => handleToggleAddressModal()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showDeleteModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 sm:flex">
                  <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-red-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Êtes-vous sûr de vouloir supprimer?
                    </h4>
                    {/*<p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                      En cliquant sur "Supprimer", vous supprimerez
                      définitivement le champ sélectionné. Cette action ne peut
                      pas être annulée. Veuillez vous assurer que vous voulez
                      continuer avant de procéder.
      </p>*/}
                    <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                      Vous êtes sur le point de supprimer votre compte. Cette action effacera toutes vos données de façon définitive. Une fois validé, il sera impossible de revenir en arrière ou de récupérer vos données.
                    </p>

                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                        onClick={() => handleConfirmDelete()}
                      >
                        Supprimer
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                        onClick={() => handleDeleteCancel()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showRegionModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowRegionModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre Zone d'intervention
                    </h4>

                    <div className="col-span-6">
                      <div className="mt-1 grid grid-cols-1 gap-y-2 sm:grid-cols-3 sm:gap-x-6 ">
                        {Départements.map((departement) => (
                          <div
                            key={departement.value}
                            className="flex items-center"
                          >
                            <input
                              id={departement.value}
                              name="regions"
                              type="checkbox"
                              value={departement.value}
                              onChange={handleRegionChange}
                              checked={selectedRegions.includes(
                                departement.value
                              )}
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                            <label
                              htmlFor={departement.value}
                              className="ml-3 block text-sm font-medium text-gray-700"
                            >
                              {departement.label}
                            </label>
                          </div>
                        )).slice(1)}
                      </div>
                    </div>

                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleClick()}
                      >
                        Modifier
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                        onClick={() => handleRegionModal()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showAssureurModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier le nom d’assureur
                    </h4>
                    <input
                      type="text"
                      name="assurance"
                      value={assurance}
                      onChange={handleAssurance}
                      id="assurance"
                      autoComplete="assurance"
                      placeholder={isLoading ? "loading" : userData.assurance}
                      className=" p-4 margin-top mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleClick()}
                      >
                        Modifier
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                        onClick={() => handleToggleAssuranceModal()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showNuméroAssuranceModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier le numéro d'assurance
                    </h4>
                    <input
                      type="text"
                      name="nassurance"
                      value={nassurance}
                      onChange={handleNassurance}
                      id="nassurance"
                      autoComplete="assurance"
                      placeholder={isLoading ? "loading" : userData.nassurance}
                      className=" p-4 margin-top mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleClick()}
                      >
                        Modifier
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                        onClick={() => handleToggleNassuranceModal()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showRIBModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre Iban
                    </h4>
                    <input
                      type="text"
                      name="iban"
                      value={rib}
                      onChange={handleRib}
                      id="iban"
                      autoComplete="assurance"
                      placeholder={isLoading ? "loading" : userData.iban}
                      className=" p-4 margin-top mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleClick()}
                      >
                        Modifier
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                        onClick={() => handleToggleRibModal()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showAttestationAssurance ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre Attestation d'assurance
                    </h4>
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700"
                        style={{ marginTop: "16px" }}
                      >
                        Attestation d’assurance
                      </label>
                      {assuranceImg && (
                        <div className="mt-1">
                          {Array.from(assuranceImg).map((file) => (
                            <div key={file.name} className="flex items-center">
                              <span className="font-medium text-green-600">
                                {file.name}
                              </span>
                            </div>
                          ))}
                          <button
                            className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() => setAssuranceImg(null)}
                          >
                            Change
                          </button>
                        </div>
                      )}
                      {!assuranceImg && (
                        <div className="mt-1 flex justify-center rounded-md border-1  border-gray-300 px-6 pt-5 pb-6">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="assuranceImg"
                                className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                              >
                                <span>Upload a file</span>
                                <input
                                  id="assuranceImg"
                                  name="assuranceImg"
                                  type="file"
                                  multiple
                                  className="sr-only"
                                  value={assuranceImg}
                                  onChange={handleAssuranceImg}
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="items-center gap-2 mt-3 sm:flex">
                    <button
                      className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                      onClick={() => handleClick()}
                    >
                      Modifier
                    </button>
                    <button
                      className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                      onClick={() => handleToggleAssuranceImageModal()}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showProfileImg ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre Photo de profil
                    </h4>
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700"
                        style={{ marginTop: "16px" }}
                      >
                        Photo de profil
                      </label>
                      {photoIdentite && (
                        <div className="mt-1">
                          {Array.from(photoIdentite).map((file) => (
                            <div key={file.name} className="flex items-center">
                              <span className="font-medium text-green-600">
                                {file.name}
                              </span>
                            </div>
                          ))}
                          <button
                            className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() => setPhotoIdentite(null)}
                          >
                            Change
                          </button>
                        </div>
                      )}
                      {!photoIdentite && (
                        <div className="mt-1 flex justify-center rounded-md border-1  border-gray-300 px-6 pt-5 pb-6">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="photoIdentite"
                                className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                              >
                                <span>Upload a file</span>
                                <input
                                  id="photoIdentite"
                                  name="photoIdentite"
                                  type="file"
                                  multiple
                                  className="sr-only"
                                  value={photoIdentite}
                                  onChange={handlePhotoIdentite}
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">
                              PNG, JPG, GIF up to 10MB
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="items-center gap-2 mt-3 sm:flex">
                    <button
                      className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                      onClick={() => handleClick()}
                    >
                      Modifier
                    </button>
                    <button
                      className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                      onClick={() => handleToggleProfileImg()}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showQualification ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 w-[100%]">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Modifier votre qualifications
                    </h4>
                    {qualificationImg && (
                      <div className="mt-1">
                        {Array.from(qualificationImg).map((file) => (
                          <div key={file.name} className="flex items-center">
                            <span className="font-medium text-green-600">
                              {file.name}
                            </span>
                          </div>
                        ))}
                        <button
                          className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                          onClick={() => setQualificationImg(null)}
                        >
                          Change
                        </button>
                      </div>
                    )}
                    {!qualificationImg && (
                      <div className="mt-1 flex justify-center rounded-md border-1  border-gray-300 px-6 pt-5 pb-6">
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="qualificationImg"
                              className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="qualificationImg"
                                name="qualificationImg"
                                type="file"
                                multiple
                                className="sr-only"
                                value={qualificationImg}
                                onChange={handleQualificationImgs}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="items-center gap-2 mt-3 sm:flex">
                    <button
                      className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                      onClick={() => handleClick()}
                    >
                      Modifier
                    </button>
                    <button
                      className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                      onClick={() => handleQualificationImageModal()}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200 w-[92%] ml-auto mr-auto" />
        </div>
      </div>
    </>
  );
};

export default UserProfile;
