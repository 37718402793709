import React, { useState } from "react";
import LogoBig from "../../../Assets/Logo.svg"
import { Link, useNavigate } from "react-router-dom";

const UserRecovery = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [recoverError, setRecoverError] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [msg, setMsg] = useState('');
  const apiUrl = 'https://lebontechnicien.net'

  

  const handleEmail = (event) => {
    setEmail(event.target.value.toLowerCase());
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${apiUrl}/pass_recover`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Failed to fetch');
        }
      })
      .then((data) => {
        if (data.msg === "email sent for new password update") {
          setRecoverError(null);
          console.log("success");
          setConfirmationModal(true);
          setTimeout(() => {
            setConfirmationModal(false);
            window.location.href = "/connexion";
          }, 4000);   
          window.location.href = "/connexion";       
        }
        else if (data.msg === "No such User") {
          setRecoverError("l'adresse mail renseignée n'est associée à aucun compte");
        } 
      })
      .catch((error) => console.error(error));
  };
  
  

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 login-wrapper">
        <div className="w-full max-w-md space-y-8">
          <div>
          <Link to="https://lebontechnicien.fr/">
              <img className="mx-auto h-20 w-auto" src={LogoBig} alt="Logo" />
            </Link>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight authentifciation-text ">
              Entrez vos informations d'identification pour réinitialiser votre mot de passe
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email" className="mb-4 ">
                Adresse e-mail
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleEmail}
                  required
                  className="margin-top relative p-16 block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Adresse e-mail"
                />
              </div>
              {recoverError && recoverError.length > 0 && <div style={{ color: 'red' }}>{recoverError}</div>}
              {msg && msg.length > 0 &&  <div style={{ color: 'green' }}>{msg}</div>}
            </div>

            <div>
              <button
                type="submit"
                className=" submit-button group relative flex w-full justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2"
              >
                {/*<span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>*/}
                Réinitialiser mon mot de passe
              </button>
            </div>
          </form>
          <div>
            <h5 className="text-center text-gray-100 register-text">
              {" "}
              Vous avez un compte ?{" "}
              <span className=" register-span">
                <Link to="/register-type">Connectez-vous à votre compte</Link>
              </span>
            </h5>
          </div>
        </div>
        {confirmationModal === true && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="mt-3">
                <div className="mt-2 text-center text-center">
                  <h4 className="text-lg font-medium text-gray-800">
                    Demande créé avec succès !
                  </h4>
                  <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                    Nous sommes heureux de vous informer que votre demande a été
                    créée avec succès. Veuillez vérifier votre e-mail pour un lien de récupération de mot de passe qui vous permettra de le mettre à jour.
                  </p>
                  <div className="items-center gap-2 mt-3 sm:flex"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}        
      </div>
    </>
  );
};

export default UserRecovery;
