import React, { useEffect, useState } from "react";
import LogoBig from "../../../Assets/LogoBig.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import {
  validateEmail,
  validatePassword,
} from "../../../Components/FormValidation";
import { MdKeyboardArrowDown } from "react-icons/md";

const UserUpdatePassword = () => {

    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordCheckError, setPasswordCheckError] = useState("");
    const [confirmed, setConfirmed] = useState("");
    const [update, setUpdate] = useState("");
    const { id, conf } = useParams();
    const apiUrl = 'https://lebontechnicien.net'

    const handlePasswordCheck = (event) => {
        setPasswordCheck(event.target.value);
        if (password !== event.target.value) {
          setPasswordCheckError("Les mots de passe ne correspondent pas.");
        } else {
          setPasswordCheckError(null);
        }
    };  
    
    const handlePassword = (event) => {
        setPassword(event.target.value);
        const conditions = [
          {
            condition: event.target.value.length >= 10,
            message: "Le mot de passe doit comporter au moins 10 caractères.",
          },
          {
            condition: /[A-Z]/.test(event.target.value),
            message: "Le mot de passe doit inclure au moins une lettre majuscule.",
          },
          {
            condition: /[a-z]/.test(event.target.value),
            message: "Le mot de passe doit inclure au moins une lettre minuscule.",
          },
          {
            condition: /\d/.test(event.target.value),
            message: "Le mot de passe doit inclure au moins un chiffre.",
          },
          {
            condition: /[!@#$%^&*(),.?":{}|<>]/.test(event.target.value),
            message: "Le mot de passe doit inclure au moins un caractère spécial.",
          },
        ];
    
        const allConditionsMet = conditions.every(
          (condition) => condition.condition
        );
    
        if (!allConditionsMet) {
          const conditionMessages = conditions
            .filter((condition) => !condition.condition)
            .map((condition) => condition.message);
          setPasswordError(conditionMessages);
        } else {
          setPasswordError(null);
        }
    }; 
    
  //Fetches all the interventions
  useEffect(() => {
    fetch(`${apiUrl}/tech/reset_pass/${id}/${conf}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setConfirmed(data);
      })
      .catch((error) => console.error(error));
  }, []);   
  
  const handleUpdate = async (event) => {
    event.preventDefault();
    const response = await fetch(`${apiUrl}/reset_pass/${id}/${conf}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`
      },
      body: JSON.stringify({
        id: id,
        conf: conf,
        password: password,
      })
    });
    const data = await response.json();
    setUpdate(data);
    window.location.href = "/connexion";
  };



  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 login-wrapper">

        <div className="w-full max-w-md space-y-8">
          {/*<div>
            <img className="mx-auto h-12 w-auto" src={LogoBig} alt="Logo" />
            <h2 className="mt-6 text-center font-bold tracking-tight authentifciation-text ">
              Bonjour. Entrez vos informations d'identification pour créer votre
              compte
            </h2>
  </div>*/}
          <div>
            <div>
              <input type="hidden" name="remember" defaultValue="true" />
              <div>
                <label
                  htmlFor="password"
                  className="margin-top block text-sm font-medium text-gray-700"
                >
                  mot de passe <span className="text-red-500"> * </span>
                </label>
                <input
                  value={password}
                  onChange={handlePassword}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="mb-4 relative p-16 block w-full appearance-none rounded rounded-lg border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm "
                  placeholder="Mot de passe"
                />
                {passwordError && passwordError.length > 0 && (
                  <div className="text-red-500">
                    {passwordError.map((message, index) => (
                      <div key={index}> - {message}</div>
                    ))}
                  </div>
                )}

                {!passwordError && (
                  <div className="text-green-500">
                    Tous les critères sont respectés.
                  </div>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="margin-top block text-sm font-medium text-gray-700"
                >
                  Retaper le mot de passe{" "}
                  <span className="text-red-500"> * </span>
                </label>
                <input
                  value={passwordCheck}
                  onChange={handlePasswordCheck}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="mb-4 relative p-16 block w-full appearance-none rounded rounded-lg border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Mot de passe"
                />
                {passwordCheckError && passwordCheck.length > 0 && (
                  <div style={{ color: "red" }}>{passwordCheckError}</div>
                )}
              </div>
              <div className="mt-4 flex justify-center items-center">
                <button
                  onClick={handleUpdate}
                  className="submit-button px-3 py-2 text-lg rounded-md w-full text-white bg-blue-500"
                >
                  Changer votre mot de passe
                </button>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};


export default UserUpdatePassword