import React from 'react'
import demandeIntervention from "../../Assets/demande-intervention.png";

const TechFonGrid = ({ dataSource }) => {
  const firstThree = dataSource.slice(0, 3);
  const lastThree = dataSource.slice(3, 6);

  return (
    <div className="container">
      <div className='FoncGrid-big-container'>
      <div className='FoncGrid-container'>
          <div className="FoncGrid-div">
            {firstThree.map((item) => (
              <div className="FoncGrid-rect" key={item.id}>
                <img className="FoncGrid-rect-img" src={item.img} alt={item.title} />
                <p className="FoncGrid-rect-p">{item.title}</p>
              </div>
            ))}
          </div>
          <div className="FoncGrid-div">
            {lastThree.map((item) => (
              <div className="FoncGrid-rect" key={item.id}>
                <img className="FoncGrid-rect-img" src={item.img} alt={item.title} />
                <p className="FoncGrid-rect-p">{item.title}</p>
              </div>
            ))}
          </div>
        </div>        
        <div className='FoncGrid-container-text'>
          <h2 className='FoncGrid-big-container-header align' >Les principales fonctionnalités de l'application</h2>
          <div style={{ width: "60%", height: "2px", backgroundColor: "#000000", marginTop: "0px",marginLeft: "200px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default TechFonGrid