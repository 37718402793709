import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Teams } from "../../../Constants/dummy";
import { BreadCrumb, CRUDTable } from "../../../Components";
import ReCAPTCHA from "react-google-recaptcha";
import { IoAddCircleOutline } from "react-icons/io5";
import Select, { StylesConfig } from "react-select";
import chroma from "chroma-js";
import { Link } from "react-router-dom";
import {
  IoCheckmarkOutline,
  IoEyeOutline,
  IoPencil,
  IoCloseOutline,
  IoTrashOutline,
} from "react-icons/io5";

const TechProTeamList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleTechnician, setIsModalVisibleTechnician] =
    useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [nomEquipe, setNomEquipe] = useState("");
  const [nomTechnicien, setNomTechnicien] = useState("");
  const [prenomTechnicien, setPrenomTechnicien] = useState("");
  const [numéroTechnicien, setnuméroTechnicien] = useState("");
  const [couleurEquipe, setCouleurEquipe] = useState("");
  const [techEquipe, setTechEquipe] = useState("");
  const [plaqueEquipe, setPlaqueEquipe] = useState("");
  const [registerError, setRegisterError] = useState("");
  const [techs, setTechs] = useState([]);
  const [pending, setPending] = useState([]);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState("");
  const [selectedTechs, setSelectedTechs] = useState([]);
  const [creatingTeamError, setCreatingTeamError] = useState("");
  const [teamData, setTeamData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [testId, setTestId] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [createdTech, setCreatedTech] = useState(null);

  console.log("selectedTechsselectedTechs", selectedTechs);

  const apiUrl = 'https://lebontechnicien.net'

  const handleColorChange = (selectedOption) => {
    setCouleurEquipe(selectedOption.color);
  };

  const handleTechChange = (selectedOption) => {
    setTechEquipe(selectedOption.color);
  };

  const colorOptions = [
    { value: "Noir", label: "Noir", color: "#000000" },
    { value: "Rouge", label: "Rouge", color: "#d90429" },
    { value: "Orange", label: "Orange", color: "#e85d04" },
    { value: "Jaune", label: "Jaune", color: "#ffba08" },
    { value: "Vert", label: "Vert", color: "#15490a" },
    { value: "Bleu", label: "Bleu", color: "#023e8a" },
    { value: "Violet", label: "Violet", color: "#800080" },
    { value: "Rose", label: "Rose", color: "#FFC0CB" },
    { value: "Marron", label: "Marron", color: "#964B00" },
    { value: "Bordeaux", label: "Bordeaux", color: "#800020" },
  ];

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const handleRecaptcha = (value) => {
    console.log("reCAPTCHA value:", value);
    setRecaptchaValue(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalTechnician = () => {
    setIsModalVisibleTechnician(true);
  };

  //Create Technicien
  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    const requestData = new FormData();
    requestData.append("nom", nomTechnicien);
    requestData.append("prenom", prenomTechnicien);
    requestData.append("password", "");
    requestData.append("telephone", numéroTechnicien);
    requestData.append("profile", "Finished");
    requestData.append("zone", "");
    requestData.append(
      "email",
      `${nomTechnicien}.${prenomTechnicien}@mafatec.com`
    );
    requestData.append("type", "under_tech_pro");
    requestData.append("creator", sessionStorage.getItem("id"));
    requestData.append("ProTeam", "non");
    requestData.append("ProTech", "non");

    let isValid = true;
    const requiredFields = ["nom", "prenom", "telephone"];
    for (const field of requiredFields) {
      if (!requestData.get(field)) {
        isValid = false;
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "red";
        const errorText = document.createElement("p");
        errorText.innerText = `Veuillez remplir ce champ.`;
        errorText.style.color = "red";
        input.parentNode.insertBefore(errorText, input);
      } else {
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "initial";
        const errorText = input.previousElementSibling;
        if (
          errorText &&
          errorText.innerText.startsWith("Veuillez remplir ce champ")
        ) {
          errorText.remove();
        }
      }
    }

    if (!isValid) {
      return;
    }

    fetch(`${apiUrl}/tech/register`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data !== "Email already in use") {
          console.log("success");
          window.location.replace("/mes-equipes");
        } else {
          if (data === "Email already in use") {
            setRegisterError("Cette adresse e-mail est déjà utilisée");
          } else {
            setError(data);
            console.log(error);
          }
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
  };

  //Create Automtic TechTeam

  const handleTechProTeam = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    const requestData = new FormData();
    requestData.append("nom", nomEquipe);
    requestData.append("prenom", "ProTeam");
    requestData.append("password", `${nomEquipe}2024.`);
    requestData.append("telephone", "");
    requestData.append("profile", "Finished");
    requestData.append("ProTeam", "oui");
    requestData.append("zone", "");
    requestData.append("status", "Confirmed");
    requestData.append("ProTech", "non");
    requestData.append("email", `${nomEquipe}@lebontechnicien.fr`);
    requestData.append("type", "under_tech_pro");
    requestData.append("creator", sessionStorage.getItem("id"));

    try {
      const response = await fetch(`${apiUrl}/tech/register`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: requestData,
      });

      if (response.status === 200) {
        const data = await response.json();
        setCreatedTech(data);
        console.log("Tech creation success:", data);
        return data; // Return the created tech data
      } else {
        console.error("Tech creation failed:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Tech creation error:", error);
      return null;
    }
  };

  const handleCreatingTeam = async (e) => {
    e.preventDefault();

    if (selectedTechs.length < 2) {
      setCreatingTeamError(
        "Vous devez sélectionner au moins 2 techniciens pour créer l'équipe."
      );
      return;
    }

    // Automatically create tech team before proceeding
    const createdTechData = await handleTechProTeam(e);

    if (!createdTechData) {
      console.error("Tech creation failed");
      return;
    }

    console.log(`Tech Info: {
      _id: ${createdTechData._id}, 
        nom: ${createdTechData.nom}, 
        prenom: ${createdTechData.prenom} 
    }`);

    // Add the created tech to the list of selectedTechs
    setSelectedTechs([
      ...selectedTechs,
      {
        _id: createdTechData._id,
        nom: createdTechData.nom,
        prenom: createdTechData.prenom,
      },
    ]);

    console.log("createdTechData PRe", selectedTechs);

    const formData = new FormData();
    formData.append("nomEquipe", nomEquipe);
    formData.append("couleurEquipe", couleurEquipe);
    formData.append("plaqueEquipe", plaqueEquipe);
    // Append the created tech as the last one
    formData.append(
      `TechnicienEquipe[${selectedTechs.length}]`,
      createdTechData._id
    );
    formData.append(
      `TechnicienEquipeNom[${selectedTechs.length}]`,
      createdTechData.nom + " " + createdTechData.prenom
    );

    selectedTechs.forEach((tech, index) => {
      formData.append(`TechnicienEquipe[${index}]`, tech._id);
      formData.append(
        `TechnicienEquipeNom[${index}]`,
        tech.nom + " " + tech.prenom
      );
    });
    formData.append("id", sessionStorage.getItem("id"));
    formData.append("email", sessionStorage.getItem("email"));

    try {
      const response = await fetch(`${apiUrl}/teams/create_team`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        setIsModalVisible(false);
        window.location.reload();
      } else {
        console.error("Error:", response.status);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  //Finds Techniciens under this Pro Tech
  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ type }), // Pass an object with the type property
        });
        const data = await response.json();
        console.log("Techspre", data);
        const pendingData = data.filter(
          (item) => item.creator === sessionStorage.getItem("id") && item.ProTeam !== 'oui'
        );
        setTechs(pendingData.reverse());
        setPending(pendingData);
        console.log("pending", pendingData); // Fix the log to display the correct variable
        console.log("techs", techs); // Fix the log to display the correct variable
      } catch (error) {
        console.error(error);
      }
    };

    fetchData("Tech"); // Call fetchData with the desired type
  }, []);

  //Finds the teams
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/teams/find_teams`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({}),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTeamData(data);
        console.log("Team Data:", teamData);
      } catch (error) {
        console.error("Error fetching team data:", error.message);
      }
    };

    fetchData();
  }, []);

  const getInitials = (name) => {
    console.log("name", name);
    // Check if the name is defined
    if (!name) {
      return ""; // or any default value
    }

    const names = name.split(" ");
    if (names.length === 1) {
      return name.slice(0, 2).toUpperCase();
    }
    return (names[0][0] + names[1][0]).toUpperCase();
  };

  const info = techs.map((item) => ({
    id: item._id,
    nom: item.nom,
    prenom: item.prenom,
    telephone: item.telephone,
    Technician_id: item._id,
  }));

  const handleRemoveUser = async (id) => {
    const formData = {
      id: id,
    };

    const response = await fetch(`${apiUrl}/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    console.log(data);
    window.location.reload();
  };

  // Handles Deelete
  const handleDeleteClick = (id) => {
    console.log("thedeleteid", id);
    setSelectedId(id);
    setTestId(id);
    setShowModalDelete(true);
  };

  const handleConfirmDelete = () => {
    handleRemoveUser(selectedId);
    //handleDeleteProduct(selectedId)
    setShowModalDelete(false);
    setSelectedId(null);
  };

  const handleCancelDelete = () => {
    setShowModalDelete(false);
    setSelectedId(null);
  };

  const CheckboxOption = ({ innerProps, label, isSelected, setValue }) => (
    <div {...innerProps} className="p-2">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => setValue(label)}
        className="h-4 w-4"
      />
      <label style={{ marginLeft: "8px" }}>{label}</label>
    </div>
  );

  // Define custom styles for the Select component
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "400px", // Set your desired width here
    }),
  };

  const handleTechCheckboxChange = (techId, nom, prenom) => {
    // Convert techId to a string
    techId = techId.toString();

    // Use the callback form of setSelectedTechs to ensure you are working with the latest state
    setSelectedTechs((prevSelectedTechs) => {
      // Check if the techId is already in selectedTechs
      const isSelected = prevSelectedTechs
        .map((tech) => tech._id)
        .includes(techId);

      // If it's selected, remove it; otherwise, add it
      if (isSelected) {
        return prevSelectedTechs.filter((tech) => tech._id !== techId);
      } else {
        return [...prevSelectedTechs, { _id: techId, nom, prenom }];
      }
    });

    // Log the updated state
    console.log("selectedTechs handleTechCheckboxChange", selectedTechs);
    console.log("techId handleTechCheckboxChange", techId);
  };

  return (
    <>
      <BreadCrumb
        From={"Compte"}
        To={"Mes équipes"}
        hrefFrom={"/mon-compte-tech"}
      />
      <div className="mt-10 sm:mt-0 max-w-screen-xl ml-auto mr-auto">
        <div className="flex justify-between w-full mb-10 flex-wrap">
          <h3 className="text-lg font-medium leading-6 text-gray-900 title-font-h3">
            Liste Des Equipes & Techniciens
          </h3>
          <div className="flex justify-center gap-2">
            <button
              onClick={showModal}
              className="userProfile-btn inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Ajouter une équipe
            </button>
            <button
              onClick={showModalTechnician}
              className="userProfile-btn inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Ajouter un technicien
            </button>
          </div>
        </div>
        <div className="mt-10 gap-6 flex justify-start flex-wrap">
          {teamData.map((team, index) => (
            <Link to={`/mes-equipes/${team._id}`}>
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 1] }}
                drag={false}
                dragElastic={1}
                dragConstraints={{ top: 1, bottom: 1, right: 1, left: 1 }}
                className="bg-white text-white h-[180] rounded-lg shadow-md w-[300px]"
                key={index}
              >
                <div>
                  <div className="flex justify-center mt-8 ml-auto mr-auto ">
                    <div
                      className="inline-block h-20 w-20 overflow-hidden rounded-full"
                      style={{ backgroundColor: team.couleurEquipe }}
                    >
                      <span className="h-full w-full text-white text-xl font-bold flex items-center justify-center">
                        {getInitials(team.nomEquipe)}
                      </span>
                    </div>
                  </div>
                </div>

                <figcaption className="text-start mt-0 p-4">
                  <p className="text-gray-700 font-semibold text-lg mb-2 text-center">
                    {team.nomEquipe}
                  </p>
                  <p className="text-gray-600 font-normal text-sm mb-2 text-center">
                    {/*{teamData.TechnicienEquipe.length} membres*/}
                  </p>
                </figcaption>
              </motion.button>
            </Link>
          ))}
        </div>
        <h3 className="text-xl font-medium  text-gray-900 mt-10 ">
          {" "}
          Liste des Techniciens:
        </h3>
        <div className="mt-10 w-[100%] ml-auto mr-auto">
          <CRUDTable
            data={info}
            link={`/user-details/`}
            /*eyeIcon={
              <IoEyeOutline
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  color: "#3e9f36",
                }}
              />
            }*/
            deleteIcon={(id) => (
              <IoTrashOutline
                style={{
                  fontSize: "18px",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteClick(id)}
              />
            )}
          />
        </div>
        ,
        {isModalVisible ? (
          <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setIsModalVisible(false)}
              ></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-[1000px] p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3 sm:flex">
                    <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                      <IoAddCircleOutline className="text-green-600" />
                    </div>
                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                      <h4 className="text-lg font-bold text-green-700">
                        Ajouter une nouvelle équipe
                      </h4>
                      <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                        Veuillez remplir toutes les informations requises dans
                        le formulaire pour ajouter votre nouvelle équipe.
                      </p>
                      <div className="modal-content mr-4">
                        <form className="mr-4 mb-4">
                          {errors.length > 0 && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-4">
                              <p>Veuillez remplir les champs suivants :</p>
                              <ul>
                                {errors.map((field) => (
                                  <li key={field}>{field}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <div>
                            <fieldset>
                              <legend className="contents text-base font-medium text-gray-900 ">
                                Nom de l'équipe{" "}
                                <span className="text-red-500"> * </span>
                              </legend>

                              <div className="mt-2 space-y-4">
                                <input
                                  id="nomEquipe"
                                  name="nomEquipe"
                                  type="text"
                                  value={nomEquipe}
                                  onChange={(e) => setNomEquipe(e.target.value)}
                                  placeholder="Nom de l'équipe"
                                  required
                                  style={{ marginTop: "16px" }}
                                  className="mb-2 relative p-16 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </fieldset>
                          </div>
                          <div className="flex justify-between">
                            <div className="relative mt-4">
                              <legend className="contents text-base font-medium text-gray-900 mb-2">
                                Couleur de l'équipe{" "}
                                <span className="text-red-500"> * </span>
                              </legend>

                              <Select
                                value={colorOptions.find(
                                  (option) => option.color === couleurEquipe
                                )}
                                options={colorOptions}
                                styles={colourStyles}
                                onChange={handleColorChange}
                                className="mt-2"
                              />
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <div className="relative mt-4">
                              <legend className="contents text-base font-medium text-gray-900 mb-2">
                                Techniciens{" "}
                                <span className="text-red-500"> * </span>
                              </legend>

                              {/*<Select
                                className="basic-multi-select"
                                isMulti
                                value={selectedTechs}
                                onChange={(selectedOptions) => {
                                  if (
                                    selectedOptions.some(
                                      (option) => option.value === "all_techs"
                                    )
                                  ) {
                                    // If "Tous les techs" is selected, reset the tech filter
                                    setSelectedTechs([]);
                                  } else {
                                    // Update selected techs
                                    setSelectedTechs(selectedOptions);
                                  }
                                }}
                                options={[
                                  // Add the "Tous les techs" option
                                  {
                                    value: "all_techs",
                                    label: "Tous les techs",
                                  },
                                  ...techs.map((tech) => ({
                                    value: tech._id,
                                    label: tech.nom,
                                    prenom: tech.prenom,
                                    telephone: tech.telephone,
                                  })),
                                ]}
                                components={{
                                  Option: CheckboxOption,
                                }}
                                styles={customStyles}
                              />*/}
                              <div className="flex justify-start gap-4 flex-wrap mt-4">
                                {techs.map((tech) => {
                                  const isChecked = selectedTechs.some(
                                    (selectedTech) =>
                                      selectedTech._id === tech._id
                                  );
                                  console.log("isChecked: ", isChecked);
                                  return (
                                    <label
                                      key={tech._id}
                                      className="checkbox-label"
                                    >
                                      <input
                                        className="w-4 h-4 mr-2"
                                        type="checkbox"
                                        value={tech._id}
                                        checked={isChecked}
                                        onChange={() =>
                                          handleTechCheckboxChange(
                                            tech._id,
                                            tech.nom,
                                            tech.prenom
                                          )
                                        }
                                      />
                                      {tech.nom} {tech.prenom}
                                    </label>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="relative mt-4">
                            <label htmlFor="plaqueEquipe">
                              Plaque d'immatriculation du véhicule{" "}
                              <span className="text-red-500"> * </span>
                            </label>
                            <input
                              id="plaqueEquipe"
                              name="plaqueEquipe"
                              type="text"
                              autoComplete="off"
                              value={plaqueEquipe}
                              onChange={(e) => setPlaqueEquipe(e.target.value)}
                              required
                              style={{ marginTop: "16px" }}
                              className="mb-2 relative p-16 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              placeholder="Plaque d'immatriculation du véhicule"
                            />
                          </div>
                        </form>

                        <p className="text-red-700 text-sm">
                          {creatingTeamError}
                        </p>
                      </div>
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={handleRecaptcha}
                      />
                      <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                          onClick={handleCreatingTeam}
                        >
                          Ajouter
                        </button>
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                          onClick={() => setIsModalVisible(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {isModalVisibleTechnician ? (
          <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setIsModalVisibleTechnician(false)}
              ></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-[1000px] p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3 sm:flex">
                    <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                      <IoAddCircleOutline className="text-green-600" />
                    </div>
                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                      <h4 className="text-lg font-bold text-green-700">
                        Ajouter un technicien
                      </h4>
                      <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                        Veuillez remplir toutes les informations requises dans
                        le formulaire pour ajouter votre technicien.
                      </p>
                      <div className="modal-content mr-4">
                        <form className="mr-4 mb-4">
                          {errors.length > 0 && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-4">
                              <p>Veuillez remplir les champs suivants :</p>
                              <ul>
                                {errors.map((field) => (
                                  <li key={field}>{field}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <div>
                            <fieldset>
                              <legend className="contents text-base font-medium text-gray-900 ">
                                Nom <span className="text-red-500"> * </span>
                              </legend>

                              <div className="mt-2 space-y-4">
                                <input
                                  id="nom"
                                  name="nom"
                                  type="text"
                                  value={nomTechnicien}
                                  onChange={(e) =>
                                    setNomTechnicien(e.target.value)
                                  }
                                  placeholder="Nom de technicien"
                                  required
                                  style={{ marginTop: "16px" }}
                                  className="mb-2 relative p-16 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </fieldset>
                          </div>
                          <div>
                            <fieldset>
                              <legend className="contents text-base font-medium text-gray-900 ">
                                Prénom <span className="text-red-500"> * </span>
                              </legend>

                              <div className="mt-2 space-y-4">
                                <input
                                  id="prenom"
                                  name="prenom"
                                  type="text"
                                  value={prenomTechnicien}
                                  onChange={(e) =>
                                    setPrenomTechnicien(e.target.value)
                                  }
                                  placeholder="Prénom de technicien"
                                  required
                                  style={{ marginTop: "16px" }}
                                  className="mb-2 relative p-16 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </fieldset>
                          </div>
                          <div>
                            <fieldset>
                              <legend className="contents text-base font-medium text-gray-900 ">
                                Numéro de telephone{" "}
                                <span className="text-red-500"> * </span>
                              </legend>

                              <div className="mt-2 space-y-4">
                                <input
                                  id="telephone"
                                  name="telephone"
                                  type="number"
                                  value={numéroTechnicien}
                                  onChange={(e) =>
                                    setnuméroTechnicien(e.target.value)
                                  }
                                  placeholder="Numéro de technicien"
                                  required
                                  style={{ marginTop: "16px" }}
                                  className="mb-2 relative p-16 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </fieldset>
                          </div>
                        </form>
                      </div>
                      {registerError && (
                        <p style={{ color: "red" }}>{registerError}</p>
                      )}
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={handleRecaptcha}
                      />
                      <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                          onClick={handleSubmit}
                        >
                          Ajouter
                        </button>
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                          onClick={() => setIsModalVisibleTechnician(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showModalDelete ? (
          <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setShowModalDelete(false)}
              ></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3 sm:flex">
                    <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-red-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                      <h4 className="text-lg font-medium text-gray-800">
                        Êtes-vous sûr de vouloir supprimer?
                      </h4>
                      <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                        En cliquant sur "Supprimer", vous supprimez
                        définitivement cet appareil. Cette action est
                        irréversible. Veuillez vous assurer que vous voulez bien
                        continuer.
                      </p>
                      <div className="items-center gap-2 mt-3 sm:flex">
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                          onClick={() => handleConfirmDelete()}
                        >
                          Supprimer
                        </button>
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                          onClick={() => handleCancelDelete()}
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default TechProTeamList;