import React from "react";
import { useNavigate } from "react-router-dom";

const ContractCard = ({ history, userType }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-start gap-4 flex-wrap w-full">
      {history.map((item, index) => (
        <div
          key={index}
          className="rounded-[10px] border border-[#D9D9D9] w-[24%] !h-[250px]"
        >
          <div>
            <div className="bg-[#FAFAFA] p-4">
              <p className="text-[15px] text-center">
                {item.nom_Pro && item.prenom_Pro
                  ? `${item.nom_Pro} ${item.prenom_Pro}`
                  : "-"}
              </p>
            </div>
            {userType === "User" && (
              <>
                <div className="p-4 text-sm ">
                  <p>Demandeur : {item.demandeur ? item.demandeur : "-"}</p>
                  <p>
                    Date de la Demande :{" "}
                    {/^\d{2}\/\d{2}\/\d{4}$/.test(item.created_at)
                      ? item.selectedDate
                      : new Date(item.created_at).toLocaleDateString("fr-FR")}
                  </p>
                  {(item.status === "admin_accept" || item.status === "Contrat signé") && (
                    <p>
                      Date de début :{" "}
                      {/^\d{2}\/\d{2}\/\d{4}$/.test(item.selectedDate)
                        ? item.selectedDate
                        : new Date(item.selectedDate).toLocaleDateString(
                            "fr-FR"
                          )}
                    </p>
                  )}

                  <p>
                    Statut :{" "}
                    {(() => {
                      switch (item.status) {
                        case "Pending":
                          return "En attente de Validation";
                        case "closed":
                          return "Validé";
                        case "closed_tech":
                          return "Cloturé par technicien";
                        case "claim":
                          return "Réclamation";
                        case "Denied_payment":
                          return "Devis non accepté";
                        case "Accepted_payment":
                          return "Devis accepté";
                        case "Paymen_User":
                          return "Devis";
                        case "Accepted":
                          return "Accepté par client";
                        case "solved":
                          return "Résolu";
                        case "unsolved":
                          return "Non résolu";
                        case "Full":
                          return "Complète";
                        case "claime":
                          return "Réclamation résolu";
                        case "litige_fixed":
                          return "Litige résolu";
                        case "annulled":
                          return "Annulée";
                        case "admin_accept":
                          return "Contrat en attente de signature";
                        case "Tech_onhold":
                          return "En attente";
                        case "admin_denie":
                          return "Contrat refusé";
                        default:
                          return item.status;
                      }
                    })()}
                  </p>
                </div>
                <div className="p-4 w-full">
                  <button
                    onClick={() => navigate(`/contrat/${item._id}`)}
                    className="w-full text-center  rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 "
                  >
                    Consulter le contrat
                  </button>
                </div>
              </>
            )}
            {(userType === "Admin" || userType === "Superviseur") && (
              <>
                <div className="p-4 text-sm !h-[120px]">
                  <p>Demandeur : {item.demandeur ? item.demandeur : "-"}</p>
                  <p>
                    Date de la Demande :{" "}
                    {/^\d{2}\/\d{2}\/\d{4}$/.test(item.created_at)
                      ? item.selectedDate
                      : new Date(item.created_at).toLocaleDateString("fr-FR")}
                  </p>
                  {(item.status === "admin_accept" || item.status === "Contrat signé") && (
                    <p>
                      Date de début :{" "}
                      {/^\d{2}\/\d{2}\/\d{4}$/.test(item.selectedDate)
                        ? item.selectedDate
                        : new Date(item.selectedDate).toLocaleDateString(
                            "fr-FR"
                          )}
                    </p>
                  )}
                  <p>
                    Statut :{" "}
                    {(() => {
                      switch (item.status) {
                        case "Pending":
                          return "En attente de Validation";
                        case "closed":
                          return "Validé";
                        case "closed_tech":
                          return "Cloturé par technicien";
                        case "claim":
                          return "Réclamation";
                        case "Denied_payment":
                          return "Devis non accepté";
                        case "Accepted_payment":
                          return "Devis accepté";
                        case "Paymen_User":
                          return "Devis";
                        case "Accepted":
                          return "Accepté par client";
                        case "solved":
                          return "Résolu";
                        case "unsolved":
                          return "Non résolu";
                        case "Full":
                          return "Complète";
                        case "claime":
                          return "Réclamation résolu";
                        case "litige_fixed":
                          return "Litige résolu";
                        case "annulled":
                          return "Annulée";
                        case "admin_accept":
                          return "Contrat en attente de signature";
                        case "admin_denie":
                          return "Contrat refusée";
                        default:
                          return item.status;
                      }
                    })()}
                  </p>
                </div>
                <div className="p-4 w-full">
                  <button
                    onClick={() => navigate(`/contrats/${item._id}`)}
                    //onClick={handletechCloseModal}
                    className=" 
                    w-full text-center  rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 
                        "
                  >
                    Consulter le contrat
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContractCard;
