import React, { useEffect, useState } from "react";
import { CRUDTable, Detail, ReturnButton } from "../../../Components";
import { useParams } from "react-router";
import { AiOutlinePaperClip } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
//import QR from '../../../Assets/QR.svg'
//import {Qr} from ''

const UserNewProductDetail = () => {
  const [product, setProduct] = useState("");
  const [marque, setMarque] = useState("");
  const [annéeInstallation, setAnnéeInstallation] = useState("");
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingList, setPendingList] = useState("");
  const [error, setError] = useState("");
  const apiUrl = 'https://lebontechnicien.net'

  const { id } = useParams();

  const handleGetProduct = async () => {
    const response = await fetch(`${apiUrl}/prods/get_product`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id: id }),
    });
    const data = await response.json();
    setProduct(data);
  };

  console.log("product.entretenu", product.entretenu);
  console.log("product.entretenu", product.photoDuProduit);

  useEffect(() => {
    handleGetProduct();
  }, []);

  {
    /*useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}temp/get_image${product.QR}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        });
  
        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }
  
        const data = await res.json();
        setPendingList(data);
        console.log('pendingList', pen)
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);  */
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${apiUrl}/prods/get_history`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: product._id }),
        }
      );

      const data = await response.json();
      setHistory(data);
    };

    fetchData();
  }, [product._id]);

  // Info is what is going to be shown in the table
  const info =
  history.length > 0
  ? history
      .slice() // Create a copy of the array to avoid modifying the original
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort in descending order by creation date
      .map((item) => {
        const creationDate = new Date(item.created_at);
        const creationDateOther = creationDate.toLocaleDateString("fr-FR");
        const frenchCreationDate = creationDate.toLocaleDateString("fr-FR", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        return {
          id: item._id,
          Création: creationDateOther,
          Type: item.type,
          Prestation: item.typeDePrestation,
          Domaine: item.typeDeProjet,
          Zone: item.zone,
          //deleteIcon: item._id,
        };
      })
  : [];


  console.log(product.photoDuProduit);
  return (
    <>
      <ReturnButton/>
      {product && (
        <>
          <div className="overflow-hidden bg-transparent shadow sm:rounded-lg max-w-screen-xl  ml-auto mr-auto mt-10">
            <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Détails de l'appareil
              </h3>
            </div>
            <div className="flex justify-around mt-6">
              {product.photoDuProduit && (product.photoDuProduit !== 'null') &&(
                <img
                  src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                  alt={product._id}
                  className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                />
              )}
              <img
                src={`https://lebontechnicien.net/${product.QR}`}
                alt={product._id}
                className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
              />
            </div>
            <div className="border-t border-gray-200 w-[92%] ml-auto mr-auto mb-10">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Type d'appareil
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {product.typeDuBien}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="  text-sm font-medium text-gray-500">
                    Mois et année d'installation:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {product.moisInstallation} / {product.annéeInstallation ? product.annéeInstallation : product.anneeInstallation}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Marque:</dt>

                  <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {product.marque !== "autre" ? product.marque : product.autreMarque}
                    </dd>
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Référence
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {product.reference ? product.reference : product.référence}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Maintenu
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {product.entretenu}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Puissance
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {product.puissance}
                  </dd>
                </div>
                {/*<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                <div className="flex w-0 flex-1 items-center">
                                    <AiOutlinePaperClip className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <span className="ml-2 w-0 flex-1 truncate">Produit1.pdf</span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Download
                                    </a>
                                </div>
                                </li>
                                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                <div className="flex w-0 flex-1 items-center">
                                    <AiOutlinePaperClip className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <span className="ml-2 w-0 flex-1 truncate">Produit1.pdf</span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Download
                                    </a>
                                </div>
                                </li>
                            </ul>
                            </dd>
                        </div>*/}
              </dl>
            </div>
          </div>

          <div className="overflow-hidden bg-transparent w-[92%] ml-auto mr-auto mt-10">
            <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Historique des interventions
              </h3>
            </div>
            <CRUDTable
              //TODO: Add a Detail Modal
              data={info}
              link={`/user-intervention-details/`}
              //pencilIcon={(id) => <IoPencil style={{fontSize: '18px', cursor: 'pointer', color: 'green'}} onClick={() => handleModifyClick(id)} />}
              eyeIcon={
                <IoEyeOutline
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "#f8c408",
                  }}
                />
              }
              //deleteIcon={(id) => <IoTrashOutline style={{fontSize: '18px', color: 'red', cursor: 'pointer'}} onClick={() => handleDeleteClick(id)} />}
              //onDataChange={handleDataChange}
              //handleDeleteProduct={handleDeleteProduct}
            />
          </div>
        </>
      )}
    </>
  );
};

export default UserNewProductDetail;
