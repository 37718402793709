import React, { useEffect, useState } from "react";
import { UserNotificationData } from "../../../Constants/dummy";
import {
  IoCheckmarkOutline,
  IoCloseOutline,
  IoEyeOutline,
} from "react-icons/io5";
import { BreadCrumb, CRUDTable, Notfication } from "../../../Components";
import { useStateContext } from "../../../contexts/ContextProvider";
import intlFormat from "date-fns/esm/intlFormat";
import NotificationTable from "../../../Components/Dashboard/NotificationTable";

//TODO: Add Filter to Raison

const UserNotification = () => {
  const [data, setData] = useState(UserNotificationData);
  //const { activeMenu, setActiveMenu, screenSize, id } = useStateContext();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [techAcceptResponse, setTechAcceptResponse] = useState([]);
  const { userType } = useStateContext();
  const [history, setHistory] = useState([]);
  const [TechnicianPicked_id, setTechnicianPicked_id] = useState("");
  const [Intervention_id, setIntervention_id] = useState("");
  const apiUrl = 'https://lebontechnicien.net'

  const handleTechnicianID = (id, technician) => {
    console.log("TechnicianPicked_id", technician);
    sessionStorage.setItem("technicienID", technician);
    setTechnicianPicked_id(technician);
    console.log("Intervention_id", id);
    setIntervention_id(id);
    sessionStorage.setItem("notification", "oui");
  };

  const handleDataChange = (newData) => {
    setData(newData);
  };

  const id = sessionStorage.getItem("id");


  useEffect(() => {
    const emailStorage = sessionStorage.getItem("email");
    const fetchData = async () => {
      const response = await fetch(
        `${apiUrl}/request/user_request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            email: emailStorage,
          }),
        }
      );
      const data = await response.json();
      const pendingRequests = data.filter(
        (request) => request.status === "Full"
      );
      setRequests(pendingRequests);

    };
    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(
          userType === "Tech"
            ? data.filter(
                (item) => item.client_accept === sessionStorage.getItem("id")
              )
            : data.filter(
                (item) =>
                  item.created_by === sessionStorage.getItem("id") &&
                  item.status === "Pending" &&
                  item.type === "Demande d'intervention"
              )
        );
      })
      .catch((error) => console.error(error));
  }, []);

  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const monthName = monthNames[monthIndex];
    return `${day} ${monthName} ${year}`;
  };

  // Store the data in local storage
  sessionStorage.setItem("requests", JSON.stringify(requests));

  // Retrieve the data from local storage
  const storedRequests = JSON.parse(sessionStorage.getItem("requests"));

const info = Object.values(history)
  .filter((request) => request.accepted_by.length > 0)
  .flatMap((request) => {
    return request.accepted_by.map((techId) => {
      let techInfo = request?.tech_info?.find((info) => info.id === techId);
      const technicianId = techInfo ? techInfo.id : "";
      return {
        id: request._id,
        Date: formatDate(request.created_at.split(" ").slice(0, 4).join(" ")),
        Intervention: request.typeDePrestation,
        Type: request.typeDeProjet,
        Type_demande: request.type,
        Technician_id: techId,
        Entreprise: techInfo ? techInfo.tech_raison : "",
        "Dates de disponibilités":  " entre "  + new Date(request.techStartDate).toLocaleDateString("fr-FR") + " et " + new Date(request.techEndDate).toLocaleDateString("fr-FR"),
        checkIconAccept: request._id,
      };
    });
  })
  .sort((a, b) => new Date(b.Date) - new Date(a.Date));

  const handleAccept = async (infoId, techid) => {
    const selectedRequest = info.filter(
      (i) => i.id === infoId && i.Technician_id === techid
    )[0];
    console.log("techid", techid);
    const email = sessionStorage.getItem("email");
    const response = await fetch(
      `${apiUrl}/request/user_accept`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email,
          rid: infoId,
          tid: techid,
        }),
      }
    );
    const data = await response.json();
    setTechAcceptResponse(data);
    window.location.reload();
  };

  const handleDeny = async (infoId, techid) => {
    const selectedRequest = info.filter(
      (i) => i.id === infoId && i.Technician_id === techid
    )[0];
    const email = sessionStorage.getItem("email");
    const response = await fetch(`${apiUrl}/request/user_denie`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        email,
        rid: infoId,
        tid: techid,
      }),
    });
    const data = await response.json();
    setTechAcceptResponse(data);
    window.location.reload();
  };

  return (
    <>


    {userType === "Tech" ? (
        <BreadCrumb
        From={""} To={"Notifications"} hrefFrom={"/recherche"}
        />
      ) : (
        <BreadCrumb
          From={""} To={"Notifications"} hrefFrom={"/tableau-de-bord"}
        />
      )}

      <div className="mt-10 w-[92%] ml-auto mr-auto">
        <NotificationTable/>
      </div>
      {userType !== "Tech" ? (
        <div className="mt-10 w-[92%] ml-auto mr-auto mb-10">
          <h4 className="text-xl text-gray-900 font-bold mb-2">Liste des techniciens qui ont répondu à vos demandes d'intervention</h4>
          <CRUDTable
            data={info}
            link={`/user-intervention-details/`}
            /*checkIcon={<IoCheckmarkOutline style={{fontSize: '20px', cursor: 'pointer', color: 'green'}} onClick={() => console.log(info.id)} />}
            checkIconaccept={(id, Technician_id) => (
              <IoCheckmarkOutline
                style={{ fontSize: "20px", cursor: "pointer", color: "green" }}
                onClick={() => handleAccept(id, Technician_id)}
              />
            )}*/
            eyeIcon={(id, tech_info) => (
              <IoEyeOutline
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  color: "#f8c40s8",
                }}
                onClick={() => handleTechnicianID(id, tech_info)}
              />
            )}
            /*deleteIcon={(id, technicianId) => (
              <IoCloseOutline
                style={{ fontSize: "20px", cursor: "pointer", color: "red" }}
                onClick={() => handleDeny(id, technicianId)}
              />
            )}*/
            onDataChange={handleDataChange}
          />
        </div>
      ) : null}
    </>
  );
};

export default UserNotification;
