import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";

const DashCards = () => {
  const [history, setHistory] = useState([]);
  const { userType } = useStateContext();
  const [users, setUsers] = useState([]);
  const [techs, setTechs] = useState([]);
  const [superviseur, setSuperViseur] = useState([]);
  const [dateFilter, setDateFilter] = useState("semaine");
  const apiUrl = 'https://lebontechnicien.net'

  // Fetching history data from the server
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(
          userType === "Tech"
            ? data.filter(
                (item) =>
                  item.client_accept === sessionStorage.getItem("id") &&
                  item.type === "Demande d'intervention"
              )
            : data.filter((item) => item.type === "Demande d'intervention")
        );
      })
      .catch((error) => console.error(error));
  }, []);

  // Fetching users, techs, and supervisor data from the server
  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type }),
        });
        const users = await response.json();
        switch (type) {
          case "Tech":
            setTechs(users);
            break;
          case "Superviseur":
            setSuperViseur(users);
            break;
          case "User":
            setUsers(users);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData("Tech");
    fetchData("Superviseur");
    fetchData("User");
  }, []);

  const combinedData = [...history, ...users, ...techs];

  const filterByDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const weekStart = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const monthStart = new Date(year, month - 1, 1);
    const yearStart = new Date(year, 0, 1);
    let filteredHistory = [];
    let filteredUsers = [];
    let filteredTechs = [];

    
    switch (dateFilter) {
      case "jour":
        // Filtering history, users, and techs data based on the selected date filter ("jour" - day)
        filteredHistory = history.filter(
          (item) =>
            new Date(item.created_at) >= today &&
            new Date(item.created_at) <= today
        );
        filteredUsers = users.filter(
          (item) =>
            new Date(item.created_at) >= today &&
            new Date(item.created_at) <= today
        );
        filteredTechs = techs.filter(
          (item) =>
            new Date(item.created_at) >= today &&
            new Date(item.created_at) <= today
        );
        break;
      case "semaine":
        // Filtering history, users, and techs data based on the selected date filter ("semaine" - week)
        filteredHistory = history.filter(
          (item) =>
            new Date(item.created_at) >= weekStart &&
            new Date(item.created_at) <= today
        );
        filteredUsers = users.filter(
          (item) =>
            new Date(item.created_at) >= weekStart &&
            new Date(item.created_at) <= today
        );
        filteredTechs = techs.filter(
          (item) =>
            new Date(item.created_at) >= weekStart &&
            new Date(item.created_at) <= today
        );
        break;
      case "mois":
        // Filtering history, users, and techs data based on the selected date filter ("mois" - month)
        filteredHistory = history.filter(
          (item) =>
            new Date(item.created_at) >= monthStart &&
            new Date(item.created_at) <= today
        );
        filteredUsers = users.filter(
          (item) =>
            new Date(item.created_at) >= monthStart &&
            new Date(item.created_at) <= today
        );
        filteredTechs = techs.filter(
          (item) =>
            new Date(item.created_at) >= monthStart &&
            new Date(item.created_at) <= today
        );
        break;
      case "année":
        // Filtering history, users, and techs data based on the selected date filter ("année" - year)
        filteredHistory = history.filter(
          (item) =>
            new Date(item.created_at) >= yearStart &&
            new Date(item.created_at) <= today
        );
        filteredUsers = users.filter(
          (item) =>
            new Date(item.created_at) >= yearStart &&
            new Date(item.created_at) <= today
        );
        filteredTechs = techs.filter(
          (item) =>
            new Date(item.created_at) >= yearStart &&
            new Date(item.created_at) <= today
        );
        break;
      default:
        filteredHistory = history;
        filteredUsers = users;
        filteredTechs = techs;
        break;
    }

    return {
      history: filteredHistory,
      users: filteredUsers,
      techs: filteredTechs,
    };
  };


  const time = ["jour, mois, semaine, année"];
  const uniqueZones = time[0].split(",").map((t) => t.trim().toLowerCase());
  const filteredHistory = filterByDate();
  const filteredUsers = filterByDate();
  const filteredTechs = filterByDate();



  return (
    <>
      <div className="flex justify-end  ml-auto mr-auto">
        <select
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          className="ml-2 mt-6  p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer"
        >
          {uniqueZones.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </select>
      </div>
      <div class="mt-10 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mb-10 sm:mt-14 md:mt-4">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                {filteredHistory.history.length}
              </span>
              <h3 class="text-[14px] font-normal text-gray-500">
                Nombre total de projets
              </h3>
            </div>
            {/*<div class="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-base font-bold">
            14.6%
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
  </div>*/}
          </div>
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                {Array.isArray(filteredHistory.history) &&
                  filteredHistory.history.filter(
                    (project) => project.status === "closed"
                  ).length}
              </span>
              <h3 class="text-[14px] font-normal text-gray-500">
                projets clôturés
              </h3>
            </div>
            {/*<div class="ml-5 w-0 flex items-center justify-end flex-1 text-green-500 text-base font-bold">
            32.9%
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
</div>*/}
          </div>
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                {Array.isArray(filteredHistory.history) &&
                  filteredHistory.history.filter(
                    (project) =>
                      project.status === "Pending" ||
                      project.status === "Accepted" ||
                      project.status === "closed_tech" ||
                      project.status === "claim"
                  ).length}
              </span>
              <h3 class="text-[14px] font-normal text-gray-500">
                projets ouverts
              </h3>
            </div>
            {/*<div class="ml-5 w-0 flex items-center justify-end flex-1 text-red-500 text-base font-bold">
            -2.7%
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
</div>*/}
          </div>
        </div>
        {userType === 'Admin' &&
        <>
          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                  {Array.isArray(filteredHistory.history) &&
                    filteredHistory.users.filter(
                      (project) => project.entreprise === "non"
                    ).length}
                </span>
                <h3 class="text-[14px] font-normal text-gray-500">
                  Nombre De clients particuliers
                </h3>
              </div>
              {/*<div class="ml-5 w-0 flex items-center justify-end flex-1 text-red-500 text-base font-bold">
              -2.7%
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
  </div>*/}
            </div>
          </div>
          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                  {Array.isArray(filteredHistory.history) &&
                    filteredHistory.users.filter(
                      (project) => project.entreprise === "oui"
                    ).length}
                </span>
                <h3 class="text-[14px] font-normal text-gray-500">
                  Nombre De clients pro
                </h3>
              </div>
              {/*<div class="ml-5 w-0 flex items-center justify-end flex-1 text-red-500 text-base font-bold">
              -2.7%
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
  </div>*/}
            </div>
          </div>
          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                  {filteredHistory.techs.length}
                </span>
                <h3 class="text-[14px] font-normal text-gray-500">
                  Nombre De Techniciens
                </h3>
              </div>
              {/*<div class="ml-5 w-0 flex items-center justify-end flex-1 text-red-500 text-base font-bold">
              -2.7%
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
  </div>*/}
            </div>
          </div>  
          </>
        }      
      </div>
    </>
  );
};

export default DashCards;
