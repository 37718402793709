import React from "react";
//import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock } from 'react-icons/ai';
import {
  FiBarChart,
  FiCreditCard,
  FiStar,
  FiShoppingCart,
  FiUsers,
} from "react-icons/fi";
import {
  BsBoxSeam,
  BsCurrencyDollar,
  BsShield,
  BsChatLeft,
} from "react-icons/bs";

import { ImNotification } from "react-icons/im";
import {
  IoBarChartOutline,
  IoAddOutline,
  IoNotificationsOutline,
  IoLogOutOutline,
  IoListOutline,
  IoDocumentOutline,
  IoAlbumsOutline,
  IoTvOutline,
  IoHomeOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { RiContactsLine } from "react-icons/ri";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { HiOutlineRefresh } from "react-icons/hi";
import { TiTick } from "react-icons/ti";
import { GrLocation } from "react-icons/gr";
import avatar from "../Assets/DummyData/avatar.jpg";
import avatar2 from "../Assets/DummyData/avatar2.jpg";
import avatar3 from "../Assets/DummyData/avatar3.png";
import avatar4 from "../Assets/DummyData/avatar4.jpg";
import product4 from "../Assets/DummyData/product4.jpg";
import product5 from "../Assets/DummyData/product5.jpg";
import product6 from "../Assets/DummyData/product6.jpg";
import product7 from "../Assets/DummyData/product7.jpg";
import BoisImg from "../Assets/Bois.svg";
import FuelImg from "../Assets/Fuel.svg";
import GazeImg from "../Assets/Gaze.svg";
import IRVEImg from "../Assets/IRVE.svg";
import PACImg from "../Assets/PAC.svg";
import SolaireImg from "../Assets/Solaire.svg";
import VMCImg from "../Assets/VMC.svg";
import equipments from "../Assets/media/PAC.png";
import Bois_equipments from "../Assets/media/Bois.png";
import Solaire_equipments from "../Assets/media/Solaire.png";
import VMC_equipments from "../Assets/media/VMC.png";
import IRVE_equipments from "../Assets/media/IRVE.png";
import Gaze_equipments from "../Assets/media/Gaze.png";
import Fuel_equipments from "../Assets/media/Fuel.png";

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
);

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
);

export const kanbanGrid = [
  { headerText: "To Do", keyField: "Open", allowToggle: true },

  { headerText: "In Progress", keyField: "InProgress", allowToggle: true },

  {
    headerText: "Testing",
    keyField: "Testing",
    allowToggle: true,
    isExpanded: false,
  },

  { headerText: "Done", keyField: "Close", allowToggle: true },
];
const gridEmployeeProfile = (props) => (
  <div className="flex items-center gap-2">
    <img
      className="rounded-full w-10 h-10"
      src={props.EmployeeImage}
      alt="employee"
    />
    <p>{props.Name}</p>
  </div>
);

const gridEmployeeCountry = (props) => (
  <div className="flex items-center justify-center gap-2">
    <GrLocation />
    <span>{props.Country}</span>
  </div>
);
export const EditorData = () => (
  <div>
    <h3>
      Try React React has been designed from the start for gradual adoption, and
      you can use as little or as much React as you need. Whether you want to
      get a taste of React, add some interactivity to a simple HTML page, or
      start a complex React-powered app, the links in this section will help you
      get started. Online Playgrounds If you’re interested in playing around
      with React, you can use an online code playground. Try a Hello World
      template on CodePen, CodeSandbox, or Stackblitz. If you prefer to use your
      own text editor, you can also download this HTML file, edit it, and open
      it from the local filesystem in your browser. It does a slow runtime code
      transformation, so we’d only recommend using this for simple demos. Add
      React to a Website You can add React to an HTML page in one minute. You
      can then either gradually expand its presence, or keep it contained to a
      few dynamic widgets. Create a New React App When starting a React project,
      a simple HTML page with script tags might still be the best option. It
      only takes a minute to set up! As your application grows, you might want
      to consider a more integrated setup. There are several JavaScript
      toolchains we recommend for larger applications. Each of them can work
      with little to no configuration and lets you take full advantage of the
      rich React ecosystem. Learn how. Learn React People come to React from
      different backgrounds and with different learning styles. Whether you
      prefer a more theoretical or a practical approach, we hope you’ll find
      this section helpful. If you prefer to learn by doing, start with our
      practical tutorial. If you prefer to learn concepts step by step, start
      with our guide to main concepts. Like any unfamiliar technology, React
      does have a learning curve. With practice and some patience, you will get
      the hang of it. First Examples The React homepage contains a few small
      React examples with a live editor. Even if you don’t know anything about
      React yet, try changing their code and see how it affects the result.
      React for Beginners If you feel that the React documentation goes at a
      faster pace than you’re comfortable with, check out this overview of React
      by Tania Rascia. It introduces the most important React concepts in a
      detailed, beginner-friendly way. Once you’re done, give the documentation
      another try! React for Designers If you’re coming from a design
      background, these resources are a great place to get started. JavaScript
      Resources The React documentation assumes some familiarity with
      programming in the JavaScript language. You don’t have to be an expert,
      but it’s harder to learn both React and JavaScript at the same time. We
      recommend going through this JavaScript overview to check your knowledge
      level. It will take you between 30 minutes and an hour but you will feel
      more confident learning React.
    </h3>
  </div>
);
const customerGridImage = (props) => (
  <div className="image flex gap-4">
    <img
      className="rounded-full w-10 h-10"
      src={props.CustomerImage}
      alt="employee"
    />
    <div>
      <p>{props.CustomerName}</p>
      <p>{props.CustomerEmail}</p>
    </div>
  </div>
);

export const Domaines = [
  {
    id: 1,
    nom: "PAC",
    type: [
      "PAC AIR-AIR",
      "PAC AIR-EAU",
      "PAC EAU-EAU",
      "PAC Ballon Thermodynamique",
    ],
    icon: <img className="h-6 w-6 mr-2" src={PACImg} alt="PAC" />,
    hasType: true,
  },
  {
    id: 2,
    nom: "BOIS",
    type: ["BOIS Poêle à Granulés", "BOIS Chaudière à Granulés"],
    icon: <img className="h-6 w-6 mr-2" src={BoisImg} alt="Bois" />,
    hasType: true,
  },
  {
    id: 3,
    nom: "Solaire",
    type: [
      "Solaire CESI",
      "Solaire SSC",
      "Solaire Chauffe eau solaire individuelle (Cesi)",
      "Solaire Panneaux photovoltaique",
    ],
    icon: <img className="h-6 w-6 mr-2" src={SolaireImg} alt="Solaire" />,
    hasType: true,
  },
  {
    id: 4,
    nom: "Gaz",
    type: [],
    icon: <img className="h-6 w-6 mr-2" src={GazeImg} alt="Gaz" />,
    hasType: false,
  },
  {
    id: 5,
    nom: "Fuel",
    type: [],
    icon: <img className="h-6 w-6 mr-2" src={FuelImg} alt="Fuel" />,
    hasType: false,
  },
  {
    id: 6,
    nom: "VMC",
    type: [],
    icon: <img className="h-6 w-6 mr-2" src={VMCImg} alt="VMC" />,
    hasType: false,
  },
  {
    id: 7,
    nom: "IRVE",
    type: [],
    icon: <img className="h-6 w-6 mr-2" src={IRVEImg} alt="IRVE" />,
    hasType: false,
  },
];

const customerGridStatus = (props) => (
  <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
    <p
      style={{ background: props.StatusBg }}
      className="rounded-full h-3 w-3"
    />
    <p>{props.Status}</p>
  </div>
);
export const areaPrimaryXAxis = {
  valueType: "DateTime",
  labelFormat: "y",
  majorGridLines: { width: 0 },
  intervalType: "Years",
  edgeLabelPlacement: "Shift",
  labelStyle: { color: "gray" },
};

export const areaPrimaryYAxis = {
  labelFormat: "{value}%",
  lineStyle: { width: 0 },
  maximum: 4,
  interval: 1,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelStyle: { color: "gray" },
};
export const barPrimaryXAxis = {
  valueType: "Category",
  interval: 1,
  majorGridLines: { width: 0 },
};
export const barPrimaryYAxis = {
  majorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  lineStyle: { width: 0 },
  labelStyle: { color: "transparent" },
};
const areaChartData = [
  [
    { x: new Date(2002, 0, 1), y: 2.2 },
    { x: new Date(2003, 0, 1), y: 3.4 },
    { x: new Date(2004, 0, 1), y: 2.8 },
    { x: new Date(2005, 0, 1), y: 1.6 },
    { x: new Date(2006, 0, 1), y: 2.3 },
    { x: new Date(2007, 0, 1), y: 2.5 },
    { x: new Date(2008, 0, 1), y: 2.9 },
    { x: new Date(2009, 0, 1), y: 3.8 },
    { x: new Date(2010, 0, 1), y: 1.4 },
    { x: new Date(2011, 0, 1), y: 3.1 },
  ],
  [
    { x: new Date(2002, 0, 1), y: 2 },
    { x: new Date(2003, 0, 1), y: 1.7 },
    { x: new Date(2004, 0, 1), y: 1.8 },
    { x: new Date(2005, 0, 1), y: 2.1 },
    { x: new Date(2006, 0, 1), y: 2.3 },
    { x: new Date(2007, 0, 1), y: 1.7 },
    { x: new Date(2008, 0, 1), y: 1.5 },
    { x: new Date(2009, 0, 1), y: 2.8 },
    { x: new Date(2010, 0, 1), y: 1.5 },
    { x: new Date(2011, 0, 1), y: 2.3 },
  ],
  [
    { x: new Date(2002, 0, 1), y: 0.8 },
    { x: new Date(2003, 0, 1), y: 1.3 },
    { x: new Date(2004, 0, 1), y: 1.1 },
    { x: new Date(2005, 0, 1), y: 1.6 },
    { x: new Date(2006, 0, 1), y: 2 },
    { x: new Date(2007, 0, 1), y: 1.7 },
    { x: new Date(2008, 0, 1), y: 2.3 },
    { x: new Date(2009, 0, 1), y: 2.7 },
    { x: new Date(2010, 0, 1), y: 1.1 },
    { x: new Date(2011, 0, 1), y: 2.3 },
  ],
];

export const areaCustomSeries = [
  {
    dataSource: areaChartData[0],
    xName: "x",
    yName: "y",
    name: "USA",
    opacity: "0.8",
    type: "SplineArea",
    width: "2",
  },
  {
    dataSource: areaChartData[1],
    xName: "x",
    yName: "y",
    name: "France",
    opacity: "0.8",
    type: "SplineArea",
    width: "2",
  },
  {
    dataSource: areaChartData[2],
    xName: "x",
    yName: "y",
    name: "Germany",
    opacity: "0.8",
    type: "SplineArea",
    width: "2",
  },
];

export const barChartData = [
  [
    { x: "USA", y: 46 },
    { x: "GBR", y: 27 },
    { x: "CHN", y: 26 },
  ],
  [
    { x: "USA", y: 37 },
    { x: "GBR", y: 23 },
    { x: "CHN", y: 18 },
  ],
  [
    { x: "USA", y: 38 },
    { x: "GBR", y: 17 },
    { x: "CHN", y: 26 },
  ],
];

export const barCustomSeries = [
  {
    dataSource: barChartData[0],
    xName: "x",
    yName: "y",
    name: "Gold",
    type: "Column",
    marker: {
      dataLabel: {
        visible: true,
        position: "Top",
        font: { fontWeight: "600", color: "#ffffff" },
      },
    },
  },
  {
    dataSource: barChartData[1],
    xName: "x",
    yName: "y",
    name: "Silver",
    type: "Column",
    marker: {
      dataLabel: {
        visible: true,
        position: "Top",
        font: { fontWeight: "600", color: "#ffffff" },
      },
    },
  },
  {
    dataSource: barChartData[2],
    xName: "x",
    yName: "y",
    name: "Bronze",
    type: "Column",
    marker: {
      dataLabel: {
        visible: true,
        position: "Top",
        font: { fontWeight: "600", color: "#ffffff" },
      },
    },
  },
];
export const colorMappingData = [
  [
    { x: "Jan", y: 6.96 },
    { x: "Feb", y: 8.9 },
    { x: "Mar", y: 12 },
    { x: "Apr", y: 17.5 },
    { x: "May", y: 22.1 },
    { x: "June", y: 25 },
    { x: "July", y: 29.4 },
    { x: "Aug", y: 29.6 },
    { x: "Sep", y: 25.8 },
    { x: "Oct", y: 21.1 },
    { x: "Nov", y: 15.5 },
    { x: "Dec", y: 9.9 },
  ],
  ["#FFFF99"],
  ["#FFA500"],
  ["#FF4040"],
];

export const rangeColorMapping = [
  { label: "1°C to 10°C", start: "1", end: "10", colors: colorMappingData[1] },

  {
    label: "11°C to 20°C",
    start: "11",
    end: "20",
    colors: colorMappingData[2],
  },

  {
    label: "21°C to 30°C",
    start: "21",
    end: "30",
    colors: colorMappingData[3],
  },
];

export const ColorMappingPrimaryXAxis = {
  valueType: "Category",
  majorGridLines: { width: 0 },
  title: "Months",
};

export const ColorMappingPrimaryYAxis = {
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelFormat: "{value}°C",
  title: "Temperature",
};

export const FinancialPrimaryXAxis = {
  valueType: "DateTime",
  minimum: new Date("2016, 12, 31"),
  maximum: new Date("2017, 9, 30"),
  crosshairTooltip: { enable: true },
  majorGridLines: { width: 0 },
};

export const FinancialPrimaryYAxis = {
  title: "Price",
  minimum: 100,
  maximum: 180,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
};

export const LinePrimaryXAxis = {
  valueType: "DateTime",
  labelFormat: "y",
  intervalType: "Years",
  edgeLabelPlacement: "Shift",
  majorGridLines: { width: 0 },
  background: "white",
};

export const LinePrimaryYAxis = {
  labelFormat: "{value}%",
  rangePadding: "None",
  minimum: 0,
  maximum: 100,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const customersGrid = [
  { type: "checkbox", width: "50" },
  {
    headerText: "Name",
    width: "150",
    template: customerGridImage,
    textAlign: "Center",
  },
  {
    field: "ProjectName",
    headerText: "Project Name",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "Status",
    headerText: "Status",
    width: "130",
    format: "yMd",
    textAlign: "Center",
    template: customerGridStatus,
  },
  {
    field: "Weeks",
    headerText: "Weeks",
    width: "100",
    format: "C2",
    textAlign: "Center",
  },
  {
    field: "Budget",
    headerText: "Budget",
    width: "100",
    format: "yMd",
    textAlign: "Center",
  },

  {
    field: "Location",
    headerText: "Location",
    width: "150",
    textAlign: "Center",
  },

  {
    field: "CustomerID",
    headerText: "Customer ID",
    width: "120",
    textAlign: "Center",
    isPrimaryKey: true,
  },
];

export const employeesGrid = [
  {
    headerText: "Employee",
    width: "150",
    template: gridEmployeeProfile,
    textAlign: "Center",
  },
  { field: "Name", headerText: "", width: "0", textAlign: "Center" },
  {
    field: "Title",
    headerText: "Designation",
    width: "170",
    textAlign: "Center",
  },
  {
    headerText: "Country",
    width: "120",
    textAlign: "Center",
    template: gridEmployeeCountry,
  },

  {
    field: "HireDate",
    headerText: "Hire Date",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },

  {
    field: "ReportsTo",
    headerText: "Reports To",
    width: "120",
    textAlign: "Center",
  },
  {
    field: "EmployeeID",
    headerText: "Employee ID",
    width: "125",
    textAlign: "Center",
  },
];

export const TypeProjets = [
  {
    id: "1",
    type: "Pompe à chaleur",
  },
  {
    id: "2",
    type: "ballon thermodynamique",
  },
  {
    id: "3",
    type: "ballon solaire",
  },
];

export const TypedePrestation = [
  {
    id: "1",
    type: "Installation",
  },
  {
    id: "2",
    type: "Reparation",
  },
  {
    id: "3",
    type: "Remplacement",
  },
  {
    id: "4",
    type: "Maintenance",
  },
  {
    id: "5",
    type: "Autres",
  },
];

export const TypeduBien = [
  {
    id: "1",
    type: "Maison",
  },
  {
    id: "2",
    type: "appartement",
  },
  {
    id: "3",
    type: "Local",
  },
];

export const links = [
  {
    title: "Demandes",
    links: [
      {
        name: "Mon Compte",
        icon: <IoHomeOutline />,
        link: "tableau-de-bord",
      },
      {
        name: "Mes Appareils",
        icon: <IoTvOutline />,
        link: "mes-appareils",
      },
      {
        name: "Devis",
        icon: <IoDocumentOutline />,
        link: "devis",
      },
      {
        name: "Factures",
        icon: <IoAlbumsOutline />,
        link: "factures",
      },
      /*{
        name: "Demande D'intervention",
        icon: <IoAddOutline />,
        link: "new-project",
      },
      {
        name: "Contrat de maintenance",
        icon: <IoAddOutline />,
        link: "demande-contrat",
      },*/
      /*{
        name: 'calendar',
        icon: <IoCalendarNumberOutline />,
      },*/
    ],
  },
  /*{
    title: 'Dashboard',
    links: [
      {
        name: 'Dashboard',
        icon: <IoBarChartOutline />,
        link: 'user-dash'
      },
    ],
  },*/

  {
    title: "Pages",
    links: [
      /*{
        name: "Mes Réclamations",
        icon: <IoHomeOutline />,
        link: "reclamations",
      },*/
      {
        name: "Notifications",
        icon: <IoNotificationsOutline />,
        link: "user-notification",
      },
      /*{
        name: "Profil",
        icon: <IoPersonOutline />,
        link: "mon-profil",
      },*/
      /*{
        name: "rechercher un technicien",
        icon: <IoListOutline />,
        link: "browse",
      },
      {
        name: "Suivies des interventions",
        icon: <IoListOutline />,
        link: "user-history",
      },*/
    ],
  },

  {
    title: "Others",
    links: [
      {
        name: "Déconnexion",
        icon: <IoLogOutOutline />,
        link: "connexion",
      },
    ],
  },
  /*{
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
      },
    ],
  },*/
];

export const clientProlinks = [
  {
    title: "Demandes",
    links: [
      {
        name: "Mon Compte",
        icon: <IoHomeOutline />,
        link: "tableau-de-bord",
      },
      {
        name: "Liste Des Clients",
        icon: <IoListOutline />,
        link: "liste-clients",
      },
      {
        name: "Devis",
        icon: <IoDocumentOutline />,
        link: "devis",
      },
      {
        name: "Factures",
        icon: <IoAlbumsOutline />,
        link: "factures",
      },
      /*{
        name: "Demande D'intervention",
        icon: <IoAddOutline />,
        link: "new-project",
      },
      {
        name: "Contrat de maintenance",
        icon: <IoAddOutline />,
        link: "demande-contrat",
      },*/
      /*{
        name: 'calendar',
        icon: <IoCalendarNumberOutline />,
      },*/
    ],
  },
  /*{
    title: 'Dashboard',
    links: [
      {
        name: 'Dashboard',
        icon: <IoBarChartOutline />,
        link: 'user-dash'
      },
    ],
  },*/

  {
    title: "Pages",
    links: [
      /*{
        name: "Reclamations",
        icon: <ImNotification />,
        link: "reclamations",
      },*/
      {
        name: "Notifications",
        icon: <IoNotificationsOutline />,
        link: "user-notification",
      },
      /*{
        name: "Profil",
        icon: <IoPersonOutline />,
        link: "mon-profil",
      },*/
      /*{
        name: "rechercher un technicien",
        icon: <IoListOutline />,
        link: "browse",
      },
      {
        name: "Suivies des interventions",
        icon: <IoListOutline />,
        link: "user-history",
      },*/
    ],
  },

  {
    title: "Others",
    links: [
      {
        name: "Déconnexion",
        icon: <IoLogOutOutline />,
        link: "connexion",
      },
    ],
  },
  /*{
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
      },
    ],
  },*/
];

export const Technicianlinks = [
  /*{{
    title: 'Apps',
    links: [
      
        name: 'Nouveau projet',
        icon: <IoAddOutline />,
      },    
      {
        name: 'calendar',
        icon: <IoCalendarNumberOutline />,
        link: 'calendar'
      },
    ],
  },  */

  {
    title: "Statistiques",
    links: [
      {
        name: "Mon compte",
        icon: <IoListOutline />,
        link: "tableau-de-bord-tech",
      },
      {
        name: "Statistiques",
        icon: <IoBarChartOutline />,
        link: "tableau-de-bord-technicien",
      },
    ],
  },

  {
    title: "Pages",
    links: [
      {
        name: "Suivie des interventions",
        icon: <IoListOutline />,
        link: "user-history",
      },
      {
        name: "Notifications",
        icon: <IoNotificationsOutline />,
        link: "user-notification",
      },
      /*{
        name: "Profil",
        icon: <RiContactsLine />,
        link: "mon-profil",
      },*/
    ],
  },

  {
    title: "Others",
    links: [
      {
        name: "Déconnexion",
        icon: <IoLogOutOutline />,
        link: "login",
      },
    ],
  },

  /*{
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
      },
    ],
  },*/
];

export const ProTechnicianlinks = [
  /*{{
    title: 'Apps',
    links: [
      
        name: 'Nouveau projet',
        icon: <IoAddOutline />,
      },    
      {
        name: 'calendar',
        icon: <IoCalendarNumberOutline />,
        link: 'calendar'
      },
    ],
  },  */

  {
    title: "Statistiques",
    links: [
      {
        name: "Mon Compte",
        icon: <IoListOutline />,
        link: "mon-compte-tech",
      },
      /*{
        name: "Tableau de bord",
        icon: <IoListOutline />,
        link: "recherche",
      },
      {
        name: "Statistiques",
        icon: <IoBarChartOutline />,
        link: "tableau-de-bord-technicien",
      },*/
    ],
  },

  {
    title: "Pages",
    links: [
      /*{
        name: "Suivie des interventions",
        icon: <IoListOutline />,
        link: "user-history",
      },*/
      {
        name: "Notifications",
        icon: <IoNotificationsOutline />,
        link: "user-notification",
      },
      /*{
        name: "Profil",
        icon: <RiContactsLine />,
        link: "mon-profil",
      },*/
    ],
  },

  {
    title: "Others",
    links: [
      {
        name: "Déconnexion",
        icon: <IoLogOutOutline />,
        link: "login",
      },
    ],
  },

  /*{
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
      },
    ],
  },*/
];

export const ProTeamlinks = [
  {
    title: "Statistiques",
    links: [
      {
        name: "Mon Compte",
        icon: <IoListOutline />,
        link: "mon-compte-team-pro",
      },
      {
        name: "Projets en cours",
        icon: <IoListOutline />,
        link: "user-history",
      },
      {
        name: "Planning",
        icon: <IoListOutline />,
        link: "pro-team-plannings",
      },
    ],
  },

  {
    title: "Pages",
    links: [
      /*{
        name: "Suivie des interventions",
        icon: <IoListOutline />,
        link: "user-history",
      },*/
      {
        name: "Notifications",
        icon: <IoNotificationsOutline />,
        link: "user-notification",
      },
      /*{
        name: "Profil",
        icon: <RiContactsLine />,
        link: "mon-profil",
      },*/
    ],
  },

  {
    title: "Others",
    links: [
      {
        name: "Déconnexion",
        icon: <IoLogOutOutline />,
        link: "login",
      },
    ],
  },
];

export const Adminlinks = [
  {
    title: "Demandes",
    links: [
      {
        name: "Mon Compte",
        icon: <IoHomeOutline />,
        link: "tableau-de-bord-admin",
      },
      {
        name: "Liste des interventions",
        icon: <IoHomeOutline />,
        link: "user-history",
      },
      /*{
        name: "Ajouter un utilisateur",
        icon: <IoAddOutline />,
        link: "ajouter-un-utilisateur",
      },
      {
        name: 'calendar',
        icon: <IoCalendarNumberOutline />,
      },*/
    ],
  },
  {
    title: "Rapports",
    links: [
      /*{
        name: "Rapports",
        icon: <IoBarChartOutline />,
        link: "tableau-de-bord-technicien",
      },*/
    ],
  },

  {
    title: "Pages",
    links: [
      /*{
        name: "Profil",
        icon: <RiContactsLine />,
        link: "mon-profil",
      },*/
      /*{
        name: 'Nouveaux projets',
        icon: <IoListOutline />,
        link: 'browse-projects'
      },              
      {
        name: 'Suivie des interventions',
        icon: <IoListOutline />,
        link: 'user-history'
      },    */
      {
        name: "Notifications",
        icon: <IoNotificationsOutline />,
        link: "notifications",
      },
      /*{
        name: "Utilisateurs",
        icon: <FiUsers />,
        link: "liste-des-utilisateurs",
      },
      {
        name: "Liste de diffusion",
        icon: <FiUsers />,
        link: "liste-de-diffusion",
      },
      {
        name: "Litiges",
        icon: <IoDocumentOutline  />,
        link: "litiges",
      },
      {
        name: "Réclamations",
        icon: <ImNotification />,
        link: "réclamations",
      },
      {
        name: "Contrats",
        icon: <FiUsers />,
        link: "contrats",
      },*/
    ],
  },

  {
    title: "Others",
    links: [
      {
        name: "Déconnexion",
        icon: <IoLogOutOutline />,
        link: "login",
      },
    ],
  },
  /*{
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
      },
    ],
  },*/
];

export const Superviseurlinks = [
  {
    title: "Demandes",
    links: [
      {
        name: "Tableau de bord",
        icon: <IoHomeOutline />,
        link: "tableau-de-bord-superviseur",
      },

      {
        name: "Liste des interventions",
        icon: <IoHomeOutline />,
        link: "user-history",
      },
      /*{
        name: 'calendar',
        icon: <IoCalendarNumberOutline />,
      },*/
    ],
  },
  /*{
    title: "Rapports",
    links: [
      {
        name: "Rapports",
        icon: <IoBarChartOutline />,
        link: "user-dash",
      },
    ],
  },*/

  {
    title: "Pages",
    links: [
      /*{
        name: "Profil",
        icon: <RiContactsLine />,
        link: "mon-profil",
      },
      {
        name: 'Nouveaux projets',
        icon: <IoListOutline />,
        link: 'browse-projects'
      },              
      {
        name: 'Suivie des interventions',
        icon: <IoListOutline />,
        link: 'user-history'
      },    
      {
        name: "Notifications",
        icon: <IoNotificationsOutline />,
        link: "notifications",
      },*/
      {
        name: "Techniciens",
        icon: <FiUsers />,
        link: "liste-des-utilisateurs",
      },
      /*{
        name: "Liste de diffusion",
        icon: <FiUsers />,
        link: "mailing",
      },   */
      {
        name: "Litiges",
        icon: <IoDocumentOutline />,
        link: "litiges",
      },
      {
        name: "Réclamations",
        icon: <ImNotification />,
        link: "réclamations",
      },
    ],
  },

  {
    title: "Others",
    links: [
      {
        name: "Déconnexion",
        icon: <IoLogOutOutline />,
        link: "login",
      },
    ],
  },
  /*{
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
      },
    ],
  },*/
];

export const TechniciansGrid = [
  {
    id: 1,
    nom: "John",
    prénom: "Doe",
    email: "johndoe@gmail.com",
    adresse: "123 main street, los angeles, california, 90001, USA",
    téléphone: "+1 2326 5659",
    type: ["Pompe à chaleur"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "50 km",
  },
  {
    id: 2,
    nom: "James",
    prénom: "Dean",
    email: "jamesdean@gmail.com",
    adresse: "123 main street, los angeles, california, 90001, USA",
    téléphone: "+1 2326 5659",
    type: ["Pompe à chaleur, ballon thermodynamique"],
    image:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "25 km",
  },
  {
    id: 3,
    nom: "Jane",
    prénom: "Doe",
    email: "jamesdean@gmail.com",
    adresse: "123 main street, los angeles, california, 90001, USA",
    téléphone: "+1 2326 5659",
    type: ["ballon thermodynamique"],
    image:
      "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "100 km",
  },
  {
    id: 4,
    nom: "Michael",
    prénom: "Smith",
    email: "michaelsmith@gmail.com",
    adresse: "456 Park Avenue, New York, New York, 10022, USA",
    téléphone: "+1 5555 5555",
    type: [
      "ballon solaire, ballon thermodynamique, ballon d’eau chaude électrique",
    ],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "150 km",
  },
  {
    id: 5,
    nom: "Emily",
    prénom: "Johnson",
    email: "emilyjohnson@gmail.com",
    adresse: "789 Elm Street, Chicago, Illinois, 60601, USA",
    téléphone: "+1 7777 7777",
    type: "ballon solaire",
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "50 km",
  },
  {
    id: 6,
    nom: "Matthew",
    prénom: "Williams",
    email: "matthewwilliams@gmail.com",
    adresse: "246 Park Lane, Houston, Texas, 77002, USA",
    téléphone: "+1 9999 9999",
    type: ["ballon d’eau chaude électrique"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "200 km",
  },
  {
    id: 7,
    nom: "Daniel",
    prénom: "Jones",
    email: "danieljones@gmail.com",
    adresse: "135 Market Street, Philadelphia, Pennsylvania, 19106, USA",
    téléphone: "+1 1111 1111",
    type: ["ballon d’eau chaude électrique"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "75 km",
  },
  {
    id: 8,
    nom: "Nicholas",
    prénom: "Brown",
    email: "nicholasbrown@gmail.com",
    adresse: "369 Main Street, San Francisco, California, 94105, USA",
    téléphone: "+1 2222 2222",
    type: ["ballon d’eau chaude électrique, ballon solaire"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "125 km",
  },
  {
    id: 9,
    nom: "Jessica",
    prénom: "Miller",
    email: "jessicamiller@gmail.com",
    adresse: "159 Oak Street, Denver, Colorado, 80202, USA",
    téléphone: "+1 3333 3333",
    type: ["ballon solaire"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "50 km",
  },

  {
    id: 10,
    nom: "Sarah",
    prénom: "Davis",
    email: "sarahdavis@gmail.com",
    adresse: "753 Fifth Avenue, Miami, Florida, 33139, USA",
    téléphone: "+1 4444 4444",
    type: ["ballon thermodynamique"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "175 km",
  },
];

export const UsersGrid = [
  {
    id: 1,
    nom: "Adam",
    prénom: "Doe",
    email: "johndoe@gmail.com",
    adresse: "123 main street, los angeles, california, 90001, USA",
    téléphone: "+1 2326 5659",
    type: ["Pompe à chaleur"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "50 km",
  },
  {
    id: 2,
    nom: "Luka",
    prénom: "Dean",
    email: "jamesdean@gmail.com",
    adresse: "123 main street, los angeles, california, 90001, USA",
    téléphone: "+1 2326 5659",
    type: ["ballon thermodynamique"],
    image:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "25 km",
  },
  {
    id: 3,
    nom: "Jane",
    prénom: "Doe",
    email: "jamesdean@gmail.com",
    adresse: "123 main street, los angeles, california, 90001, USA",
    téléphone: "+1 2326 5659",
    type: ["ballon thermodynamique"],
    image:
      "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    location: "100 km",
  },
  {
    id: 4,
    nom: "Michael",
    prénom: "Smith",
    email: "michaelsmith@gmail.com",
    adresse: "456 Park Avenue, New York, New York, 10022, USA",
    téléphone: "+1 5555 5555",
    type: ["ballon solaire"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "150 km",
  },
  {
    id: 5,
    nom: "Emily",
    prénom: "Johnson",
    email: "emilyjohnson@gmail.com",
    adresse: "789 Elm Street, Chicago, Illinois, 60601, USA",
    téléphone: "+1 7777 7777",
    type: "ballon solaire",
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "50 km",
  },
  {
    id: 6,
    nom: "Matthew",
    prénom: "Williams",
    email: "matthewwilliams@gmail.com",
    adresse: "246 Park Lane, Houston, Texas, 77002, USA",
    téléphone: "+1 9999 9999",
    type: ["ballon d’eau chaude électrique"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "200 km",
  },
  {
    id: 7,
    nom: "Daniel",
    prénom: "Jones",
    email: "danieljones@gmail.com",
    adresse: "135 Market Street, Philadelphia, Pennsylvania, 19106, USA",
    téléphone: "+1 1111 1111",
    type: ["ballon d’eau chaude électrique"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "75 km",
  },
  {
    id: 8,
    nom: "Nicholas",
    prénom: "Brown",
    email: "nicholasbrown@gmail.com",
    adresse: "369 Main Street, San Francisco, California, 94105, USA",
    téléphone: "+1 2222 2222",
    type: ["ballon d’eau chaude électrique"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "125 km",
  },
  {
    id: 9,
    nom: "Jessica",
    prénom: "Miller",
    email: "jessicamiller@gmail.com",
    adresse: "159 Oak Street, Denver, Colorado, 80202, USA",
    téléphone: "+1 3333 3333",
    type: ["ballon solaire"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "50 km",
  },

  {
    id: 10,
    nom: "Sarah",
    prénom: "Davis",
    email: "sarahdavis@gmail.com",
    adresse: "753 Fifth Avenue, Miami, Florida, 33139, USA",
    téléphone: "+1 4444 4444",
    type: ["ballon thermodynamique"],
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    location: "175 km",
  },
];

export const Teams = [
  {
    id: 1,
    name: "Development Team",
    members: ["Alice Smith", "Bob Johnson", "Charlie Brown"],
    color: "#000000",
  },
  {
    id: 2,
    name: "Marketing Team",
    members: ["David Wilson", "Eve Davis", "Frank Miller"],
    color: "#FF0000",
  },
  {
    id: 3,
    name: "Design Team",
    members: ["Grace Turner", "Hank Parker", "Ivy Robinson"],
    color: "#FFA500",
  },

  {
    id: 4,
    name: "Sales Team",
    members: ["Grace Turner", "Hank Parker", "Ivy Robinson"],
    color: "#FFFF00",
  },

  {
    id: 5,
    name: "Finance Team",
    members: ["Grace Turner", "Hank Parker", "Ivy Robinson"],
    color: "#008000",
  },
];

export const cartData = [
  {
    image: product5,
    name: "butterscotch ice-cream",
    category: "Milk product",
    price: "$250",
  },
  {
    image: product6,
    name: "Supreme fresh tomato",
    category: "Vegetable Item",
    price: "$450",
  },
  {
    image: product7,
    name: "Red color candy",
    category: "Food Item",
    price: "$190",
  },
];

export const DataTable = [
  {
    id: 1,
    type: "Test1",
    année: "1992",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Oui",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 2,
    type: "Test3",
    année: "2020",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Oui",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 3,
    type: "Test2",
    année: "1994",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Non",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 4,
    type: "Test1",
    année: "1992",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Oui",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 5,
    type: "Test3",
    année: "2020",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Oui",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 6,
    type: "Test2",
    année: "1994",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Non",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 7,
    type: "Test1",
    année: "1992",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Oui",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 8,
    type: "Test3",
    année: "2020",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Oui",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 9,
    type: "Test2",
    année: "1994",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Non",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 10,
    type: "Test1",
    année: "1992",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Oui",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 11,
    type: "Test3",
    année: "2020",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Oui",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
  {
    id: 12,
    type: "Test2",
    année: "1994",
    marque: "Daikin",
    référence: "#12121",
    maintenu: "Non",
    image: "https://i.ibb.co/4VGfNc5/Rectangle-15.png",
  },
];

export const HistoryUser = [
  {
    id: 1,
    appareil: "Ordinateur",
    date: "2022-01-01",
    technicien: "John Doe",
    statut: "Terminé",
  },
  {
    id: 2,
    appareil: "Imprimante",
    date: "2022-02-01",
    technicien: "Jane Smith",
    statut: "En cours",
  },
  {
    id: 3,
    appareil: "Tablette",
    date: "2022-03-01",
    technicien: "Bob Johnson",
    statut: "Terminé",
  },
  {
    id: 4,
    appareil: "Smartphone",
    date: "2022-04-01",
    technicien: "Mike Williams",
    statut: "Terminé",
  },
  {
    id: 5,
    appareil: "Ordinateur portable",
    date: "2022-05-01",
    technicien: "Sarah Davis",
    statut: "En cours",
  },
  {
    id: 6,
    appareil: "Projecteur",
    date: "2022-06-01",
    technicien: "David Brown",
    statut: "Terminé",
  },
  {
    id: 7,
    appareil: "Rétroprojecteur",
    date: "2022-07-01",
    technicien: "Ashley Miller",
    statut: "En cours",
  },
  {
    id: 8,
    appareil: "Enceinte Bluetooth",
    date: "2022-08-01",
    technicien: "Matthew Taylor",
    statut: "Terminé",
  },
  {
    id: 9,
    appareil: "Téléviseur",
    date: "2022-09-01",
    technicien: "Joshua Rodriguez",
    statut: "Terminé",
  },
  {
    id: 10,
    appareil: "Chaîne Hi-Fi",
    date: "2022-10-01",
    technicien: "Jacob Garcia",
    statut: "En cours",
  },
];

export const UserData = [
  {
    id: 1,
    nom: "John",
    prénom: "Doe",
    email: "johndoe@gmail.com",
    motdepasse: "test123",
    adresse: "123 main street, los angeles, california, 90001, USA",
    téléphone: "+1 2326 5659",
    type: "Appartement, Local",
    image: "https://i.ibb.co/7kpvf7d/avatar.jpg",
    userType: "user",
  },

  {
    id: 2,
    nom: "Harold",
    prénom: "Styles",
    email: "harrystyles@gmail.com",
    motdepasse: "test123",
    adresse: "456 main street, los angeles, california, 90001, USA",
    téléphone: "+1 5659 2326 ",
    type: "Appartement, Local",
    image: "https://i.ibb.co/48t2SBK/avatar2.jpg",
    userType: "technician",
  },

  {
    id: 3,
    nom: "Madison",
    prénom: "Beer",
    email: "maddybeer@gmail.com",
    motdepasse: "test123",
    adresse: "897 main street, los angeles, california, 90001, USA",
    téléphone: "+1 2869 7569 ",
    image: "https://i.ibb.co/8KMmPL9/avatar3.png",
    userType: "admin",
  },
];

export const UserNotificationData = [
  {
    id: 1,
    Technicien: "Harold Styles",
    Domaine: "Pompe à chaleur",
    email: "haroldstyles@gmail.com",
    téléphone: "+1 2326 5659",
    prix: "120 EUR",
  },

  {
    id: 2,
    Technicien: "James Dean",
    Domaine: "Pompe à chaleur",
    email: "jamesdean@gmail.com",
    téléphone: "+1 2326 5659",
    prix: "60 EUR",
  },

  {
    id: 3,
    Technicien: "Edward Collins",
    Domaine: "Pompe à chaleur",
    email: "edwardcollins@gmail.com",
    téléphone: "+1 2326 5659",
    prix: "20 EUR",
  },
];

export const earningData = [
  {
    icon: <MdOutlineSupervisorAccount />,
    amount: "39,354",
    percentage: "-4%",
    title: "Customers",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "red-600",
  },
  {
    icon: <BsBoxSeam />,
    amount: "4,396",
    percentage: "+23%",
    title: "Products",
    iconColor: "rgb(255, 244, 229)",
    iconBg: "rgb(254, 201, 15)",
    pcColor: "green-600",
  },
  {
    icon: <FiBarChart />,
    amount: "423,39",
    percentage: "+38%",
    title: "Sales",
    iconColor: "rgb(228, 106, 118)",
    iconBg: "rgb(255, 244, 229)",

    pcColor: "green-600",
  },
  {
    icon: <HiOutlineRefresh />,
    amount: "39,354",
    percentage: "-12%",
    title: "Refunds",
    iconColor: "rgb(0, 194, 146)",
    iconBg: "rgb(235, 250, 242)",
    pcColor: "red-600",
  },
];

export const recentTransactions = [
  {
    icon: <BsCurrencyDollar />,
    amount: "+$350",
    title: "Paypal Transfer",
    desc: "Money Added",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
  },
  {
    icon: <BsShield />,
    amount: "-$560",
    desc: "Bill Payment",
    title: "Wallet",
    iconColor: "rgb(0, 194, 146)",
    iconBg: "rgb(235, 250, 242)",
    pcColor: "red-600",
  },
  {
    icon: <FiCreditCard />,
    amount: "+$350",
    title: "Credit Card",
    desc: "Money reversed",
    iconColor: "rgb(255, 244, 229)",
    iconBg: "rgb(254, 201, 15)",

    pcColor: "green-600",
  },
  {
    icon: <TiTick />,
    amount: "+$350",
    title: "Bank Transfer",
    desc: "Money Added",

    iconColor: "rgb(228, 106, 118)",
    iconBg: "rgb(255, 244, 229)",
    pcColor: "green-600",
  },
  {
    icon: <BsCurrencyDollar />,
    amount: "-$50",
    percentage: "+38%",
    title: "Refund",
    desc: "Payment Sent",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "red-600",
  },
];

export const weeklyStats = [
  {
    icon: <FiShoppingCart />,
    amount: "-$560",
    title: "Top Sales",
    desc: "Johnathan Doe",
    iconBg: "#FB9678",
    pcColor: "red-600",
  },
  {
    icon: <FiStar />,
    amount: "-$560",
    title: "Best Seller",
    desc: "MaterialPro Admin",
    iconBg: "rgb(254, 201, 15)",
    pcColor: "red-600",
  },
  {
    icon: <BsChatLeft />,
    amount: "+$560",
    title: "Most Commented",
    desc: "Ample Admin",
    iconBg: "#00C292",
    pcColor: "green-600",
  },
];

export const productsPerformance = [
  {
    image: product5,
    title: "Is it good butterscotch ice-cream?",
    desc: "Ice-Cream, Milk, Powder",
    rating: "Good",
    itemSold: "65%",
    earningAmount: "$546,000",
  },
  {
    image: product6,
    title: "Supreme fresh tomato available",
    desc: "Market, Mall",
    rating: "Excellent",
    itemSold: "98%",
    earningAmount: "$780,000",
  },
  {
    image: product7,
    title: "Red color candy from Gucci",
    desc: "Chocolate, Yummy",
    rating: "Average",
    itemSold: "46%",
    earningAmount: "$457,000",
  },
  {
    image: product4,
    title: "Stylish night lamp for night",
    desc: "Electric, Wire, Current",
    rating: "Poor",
    itemSold: "23%",
    earningAmount: "$123,000",
  },
];

export const medicalproBranding = {
  data: [
    {
      title: "Due Date",
      desc: "Oct 23, 2021",
    },
    {
      title: "Budget",
      desc: "$98,500",
    },
    {
      title: "Expense",
      desc: "$63,000",
    },
  ],
  teams: [
    {
      name: "Bootstrap",
      color: "orange",
    },
    {
      name: "Angular",
      color: "#FB9678",
    },
  ],
  leaders: [
    {
      image: avatar2,
    },
    {
      image: avatar3,
    },
    {
      image: avatar2,
    },
    {
      image: avatar4,
    },
    {
      image: avatar,
    },
  ],
};

export const themeColors = [
  {
    name: "blue-theme",
    color: "#1A97F5",
  },
  {
    name: "green-theme",
    color: "#03C9D7",
  },
  {
    name: "purple-theme",
    color: "#7352FF",
  },
  {
    name: "red-theme",
    color: "#FF5C8E",
  },
  {
    name: "indigo-theme",
    color: "#1E4DB7",
  },
  {
    color: "#FB9678",
    name: "orange-theme",
  },
];

export const scheduleData = [
  {
    Id: 1,
    Subject: "Explosion of Betelgeuse Star",
    Location: "Space Center USA",
    StartTime: "2021-01-10T04:00:00.000Z",
    EndTime: "2021-01-10T05:30:00.000Z",
    CategoryColor: "#1aaa55",
  },
  {
    Id: 2,
    Subject: "Thule Air Crash Report",
    Location: "Newyork City",
    StartTime: "2021-01-11T06:30:00.000Z",
    EndTime: "2021-01-11T08:30:00.000Z",
    CategoryColor: "#357cd2",
  },
  {
    Id: 3,
    Subject: "Blue Moon Eclipse",
    Location: "Space Center USA",
    StartTime: "2021-01-12T04:00:00.000Z",
    EndTime: "2021-01-12T05:30:00.000Z",
    CategoryColor: "#7fa900",
  },
  {
    Id: 4,
    Subject: "Meteor Showers in 2021",
    Location: "Space Center USA",
    StartTime: "2021-01-13T07:30:00.000Z",
    EndTime: "2021-01-13T09:00:00.000Z",
    CategoryColor: "#ea7a57",
  },
  {
    Id: 5,
    Subject: "Milky Way as Melting pot",
    Location: "Space Center USA",
    StartTime: "2021-01-14T06:30:00.000Z",
    EndTime: "2021-01-14T08:30:00.000Z",
    CategoryColor: "#00bdae",
  },
  {
    Id: 6,
    Subject: "Mysteries of Bermuda Triangle",
    Location: "Bermuda",
    StartTime: "2021-01-14T04:00:00.000Z",
    EndTime: "2021-01-14T05:30:00.000Z",
    CategoryColor: "#f57f17",
  },
  {
    Id: 7,
    Subject: "Glaciers and Snowflakes",
    Location: "Himalayas",
    StartTime: "2021-01-15T05:30:00.000Z",
    EndTime: "2021-01-15T07:00:00.000Z",
    CategoryColor: "#1aaa55",
  },
  {
    Id: 8,
    Subject: "Life on Mars",
    Location: "Space Center USA",
    StartTime: "2021-01-16T03:30:00.000Z",
    EndTime: "2021-01-16T04:30:00.000Z",
    CategoryColor: "#357cd2",
  },
  {
    Id: 9,
    Subject: "Alien Civilization",
    Location: "Space Center USA",
    StartTime: "2021-01-18T05:30:00.000Z",
    EndTime: "2021-01-18T07:30:00.000Z",
    CategoryColor: "#7fa900",
  },
  {
    Id: 10,
    Subject: "Wildlife Galleries",
    Location: "Africa",
    StartTime: "2021-01-20T05:30:00.000Z",
    EndTime: "2021-01-20T07:30:00.000Z",
    CategoryColor: "#ea7a57",
  },
  {
    Id: 11,
    Subject: "Best Photography 2021",
    Location: "London",
    StartTime: "2021-01-21T04:00:00.000Z",
    EndTime: "2021-01-21T05:30:00.000Z",
    CategoryColor: "#00bdae",
  },
  {
    Id: 12,
    Subject: "Smarter Puppies",
    Location: "Sweden",
    StartTime: "2021-01-08T04:30:00.000Z",
    EndTime: "2021-01-08T06:00:00.000Z",
    CategoryColor: "#f57f17",
  },
  {
    Id: 13,
    Subject: "Myths of Andromeda Galaxy",
    Location: "Space Center USA",
    StartTime: "2021-01-06T05:00:00.000Z",
    EndTime: "2021-01-06T07:00:00.000Z",
    CategoryColor: "#1aaa55",
  },
  {
    Id: 14,
    Subject: "Aliens vs Humans",
    Location: "Research Center of USA",
    StartTime: "2021-01-05T04:30:00.000Z",
    EndTime: "2021-01-05T06:00:00.000Z",
    CategoryColor: "#357cd2",
  },
  {
    Id: 15,
    Subject: "Facts of Humming Birds",
    Location: "California",
    StartTime: "2021-01-19T04:00:00.000Z",
    EndTime: "2021-01-19T05:30:00.000Z",
    CategoryColor: "#7fa900",
  },
  {
    Id: 16,
    Subject: "Sky Gazers",
    Location: "Alaska",
    StartTime: "2021-01-22T05:30:00.000Z",
    EndTime: "2021-01-22T07:30:00.000Z",
    CategoryColor: "#ea7a57",
  },
  {
    Id: 17,
    Subject: "The Cycle of Seasons",
    Location: "Research Center of USA",
    StartTime: "2021-01-11T00:00:00.000Z",
    EndTime: "2021-01-11T02:00:00.000Z",
    CategoryColor: "#00bdae",
  },
  {
    Id: 18,
    Subject: "Space Galaxies and Planets",
    Location: "Space Center USA",
    StartTime: "2021-01-11T11:30:00.000Z",
    EndTime: "2021-01-11T13:00:00.000Z",
    CategoryColor: "#f57f17",
  },
  {
    Id: 19,
    Subject: "Lifecycle of Bumblebee",
    Location: "San Fransisco",
    StartTime: "2021-01-14T00:30:00.000Z",
    EndTime: "2021-01-14T02:00:00.000Z",
    CategoryColor: "#7fa900",
  },
  {
    Id: 20,
    Subject: "Alien Civilization",
    Location: "Space Center USA",
    StartTime: "2021-01-14T10:30:00.000Z",
    EndTime: "2021-01-14T12:30:00.000Z",
    CategoryColor: "#ea7a57",
  },
  {
    Id: 21,
    Subject: "Alien Civilization",
    Location: "Space Center USA",
    StartTime: "2021-01-10T08:30:00.000Z",
    EndTime: "2021-01-10T10:30:00.000Z",
    CategoryColor: "#ea7a57",
  },
  {
    Id: 22,
    Subject: "The Cycle of Seasons",
    Location: "Research Center of USA",
    StartTime: "2021-01-12T09:00:00.000Z",
    EndTime: "2021-01-12T10:30:00.000Z",
    CategoryColor: "#00bdae",
  },
  {
    Id: 23,
    Subject: "Sky Gazers",
    Location: "Greenland",
    StartTime: "2021-01-15T09:00:00.000Z",
    EndTime: "2021-01-15T10:30:00.000Z",
    CategoryColor: "#ea7a57",
  },
  {
    Id: 24,
    Subject: "Facts of Humming Birds",
    Location: "California",
    StartTime: "2021-01-16T07:00:00.000Z",
    EndTime: "2021-01-16T09:00:00.000Z",
    CategoryColor: "#7fa900",
  },
];

export const lineChartData = [
  [
    { x: new Date(2005, 0, 1), y: 21 },
    { x: new Date(2006, 0, 1), y: 24 },
    { x: new Date(2007, 0, 1), y: 36 },
    { x: new Date(2008, 0, 1), y: 38 },
    { x: new Date(2009, 0, 1), y: 54 },
    { x: new Date(2010, 0, 1), y: 57 },
    { x: new Date(2011, 0, 1), y: 70 },
  ],
  [
    { x: new Date(2005, 0, 1), y: 28 },
    { x: new Date(2006, 0, 1), y: 44 },
    { x: new Date(2007, 0, 1), y: 48 },
    { x: new Date(2008, 0, 1), y: 50 },
    { x: new Date(2009, 0, 1), y: 66 },
    { x: new Date(2010, 0, 1), y: 78 },
    { x: new Date(2011, 0, 1), y: 84 },
  ],

  [
    { x: new Date(2005, 0, 1), y: 10 },
    { x: new Date(2006, 0, 1), y: 20 },
    { x: new Date(2007, 0, 1), y: 30 },
    { x: new Date(2008, 0, 1), y: 39 },
    { x: new Date(2009, 0, 1), y: 50 },
    { x: new Date(2010, 0, 1), y: 70 },
    { x: new Date(2011, 0, 1), y: 100 },
  ],
];
export const dropdownData = [
  {
    Id: "1",
    Time: "March 2021",
  },
  {
    Id: "2",
    Time: "April 2021",
  },
  {
    Id: "3",
    Time: "May 2021",
  },
];
export const SparklineAreaData = [
  { x: 1, yval: 2 },
  { x: 2, yval: 6 },
  { x: 3, yval: 8 },
  { x: 4, yval: 5 },
  { x: 5, yval: 10 },
];

export const lineCustomSeries = [
  {
    dataSource: lineChartData[0],
    xName: "x",
    yName: "y",
    name: "Germany",
    width: "2",
    marker: { visible: true, width: 10, height: 10 },
    type: "Line",
  },

  {
    dataSource: lineChartData[1],
    xName: "x",
    yName: "y",
    name: "England",
    width: "2",
    marker: { visible: true, width: 10, height: 10 },
    type: "Line",
  },

  {
    dataSource: lineChartData[2],
    xName: "x",
    yName: "y",
    name: "India",
    width: "2",
    marker: { visible: true, width: 10, height: 10 },
    type: "Line",
  },
];

export const pieChartData = [
  { x: "Labour", y: 18, text: "18%" },
  { x: "Legal", y: 8, text: "8%" },
  { x: "Production", y: 15, text: "15%" },
  { x: "License", y: 11, text: "11%" },
  { x: "Facilities", y: 18, text: "18%" },
  { x: "Taxes", y: 14, text: "14%" },
  { x: "Insurance", y: 16, text: "16%" },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const ecomPieChartData = [
  { x: "2018", y: 18, text: "35%" },
  { x: "2019", y: 18, text: "15%" },
  { x: "2020", y: 18, text: "25%" },
  { x: "2021", y: 18, text: "25%" },
];

export const stackedChartData = [
  [
    { x: "Jan", y: 111.1 },
    { x: "Feb", y: 127.3 },
    { x: "Mar", y: 143.4 },
    { x: "Apr", y: 159.9 },
    { x: "May", y: 159.9 },
    { x: "Jun", y: 159.9 },
    { x: "July", y: 159.9 },
  ],
  [
    { x: "Jan", y: 111.1 },
    { x: "Feb", y: 127.3 },
    { x: "Mar", y: 143.4 },
    { x: "Apr", y: 159.9 },
    { x: "May", y: 159.9 },
    { x: "Jun", y: 159.9 },
    { x: "July", y: 159.9 },
  ],
];

export const stackedCustomSeries = [
  {
    dataSource: stackedChartData[0],
    xName: "x",
    yName: "y",
    name: "Budget",
    type: "StackingColumn",
    background: "blue",
  },

  {
    dataSource: stackedChartData[1],
    xName: "x",
    yName: "y",
    name: "Expense",
    type: "StackingColumn",
    background: "red",
  },
];

export const stackedPrimaryXAxis = {
  majorGridLines: { width: 0 },
  minorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  interval: 1,
  lineStyle: { width: 0 },
  labelIntersectAction: "Rotate45",
  valueType: "Category",
};

export const stackedPrimaryYAxis = {
  lineStyle: { width: 0 },
  minimum: 100,
  maximum: 400,
  interval: 100,
  majorTickLines: { width: 0 },
  majorGridLines: { width: 1 },
  minorGridLines: { width: 1 },
  minorTickLines: { width: 0 },
  labelFormat: "{value}",
};

export const kanbanData = [
  {
    Id: "Task 1",
    Title: "Task - 29001",
    Status: "Open",
    Summary: "Analyze the new requirements gathered from the customer.",
    Type: "Story",
    Priority: "Low",
    Tags: "Analyze,Customer",
    Estimate: 3.5,
    Assignee: "Nancy Davloio",
    RankId: 1,
    Color: "#02897B",
    ClassName: "e-story, e-low, e-nancy-davloio",
  },
  {
    Id: "Task 2",
    Title: "Task - 29002",
    Status: "InProgress",
    Summary: "Improve application performance",
    Type: "Improvement",
    Priority: "Normal",
    Tags: "Improvement",
    Estimate: 6,
    Assignee: "Andrew Fuller",
    RankId: 1,
    Color: "#673AB8",
    ClassName: "e-improvement, e-normal, e-andrew-fuller",
  },
  {
    Id: "Task 3",
    Title: "Task - 29003",
    Status: "Open",
    Summary: "Arrange a web meeting with the customer to get new requirements.",
    Type: "Others",
    Priority: "Critical",
    Tags: "Meeting",
    Estimate: 5.5,
    Assignee: "Janet Leverling",
    RankId: 2,
    Color: "#1F88E5",
    ClassName: "e-others, e-critical, e-janet-leverling",
  },
  {
    Id: "Task 4",
    Title: "Task - 29004",
    Status: "InProgress",
    Summary: "Fix the issues reported in the IE browser.",
    Type: "Bug",
    Priority: "Critical",
    Tags: "IE",
    Estimate: 2.5,
    Assignee: "Janet Leverling",
    RankId: 2,
    Color: "#E64A19",
    ClassName: "e-bug, e-release, e-janet-leverling",
  },
  {
    Id: "Task 5",
    Title: "Task - 29005",
    Status: "Review",
    Summary: "Fix the issues reported by the customer.",
    Type: "Bug",
    Priority: "Low",
    Tags: "Customer",
    Estimate: "3.5",
    Assignee: "Steven walker",
    RankId: 1,
    Color: "#E64A19",
    ClassName: "e-bug, e-low, e-steven-walker",
  },
  {
    Id: "Task 6",
    Title: "Task - 29007",
    Status: "Validate",
    Summary: "Validate new requirements",
    Type: "Improvement",
    Priority: "Low",
    Tags: "Validation",
    Estimate: 1.5,
    Assignee: "Robert King",
    RankId: 1,
    Color: "#673AB8",
    ClassName: "e-improvement, e-low, e-robert-king",
  },
  {
    Id: "Task 7",
    Title: "Task - 29009",
    Status: "Review",
    Summary: "Fix the issues reported in Safari browser.",
    Type: "Bug",
    Priority: "Critical",
    Tags: "Fix,Safari",
    Estimate: 1.5,
    Assignee: "Nancy Davloio",
    RankId: 2,
    Color: "#E64A19",
    ClassName: "e-bug, e-release, e-nancy-davloio",
  },
  {
    Id: "Task 8",
    Title: "Task - 29010",
    Status: "Close",
    Summary: "Test the application in the IE browser.",
    Type: "Story",
    Priority: "Low",
    Tags: "Review,IE",
    Estimate: 5.5,
    Assignee: "Margaret hamilt",
    RankId: 3,
    Color: "#02897B",
    ClassName: "e-story, e-low, e-margaret-hamilt",
  },
  {
    Id: "Task 9",
    Title: "Task - 29011",
    Status: "Validate",
    Summary: "Validate the issues reported by the customer.",
    Type: "Story",
    Priority: "High",
    Tags: "Validation,Fix",
    Estimate: 1,
    Assignee: "Steven walker",
    RankId: 1,
    Color: "#02897B",
    ClassName: "e-story, e-high, e-steven-walker",
  },
  {
    Id: "Task 10",
    Title: "Task - 29015",
    Status: "Open",
    Summary: "Show the retrieved data from the server in grid control.",
    Type: "Story",
    Priority: "High",
    Tags: "Database,SQL",
    Estimate: 5.5,
    Assignee: "Margaret hamilt",
    RankId: 4,
    Color: "#02897B",
    ClassName: "e-story, e-high, e-margaret-hamilt",
  },
  {
    Id: "Task 11",
    Title: "Task - 29016",
    Status: "InProgress",
    Summary: "Fix cannot open user’s default database SQL error.",
    Priority: "Critical",
    Type: "Bug",
    Tags: "Database,Sql2008",
    Estimate: 2.5,
    Assignee: "Janet Leverling",
    RankId: 4,
    Color: "#E64A19",
    ClassName: "e-bug, e-critical, e-janet-leverling",
  },
  {
    Id: "Task 12",
    Title: "Task - 29017",
    Status: "Review",
    Summary: "Fix the issues reported in data binding.",
    Type: "Story",
    Priority: "Normal",
    Tags: "Databinding",
    Estimate: "3.5",
    Assignee: "Janet Leverling",
    RankId: 4,
    Color: "#02897B",
    ClassName: "e-story, e-normal, e-janet-leverling",
  },
  {
    Id: "Task 13",
    Title: "Task - 29018",
    Status: "Close",
    Summary: "Analyze SQL server 2008 connection.",
    Type: "Story",
    Priority: "Critical",
    Tags: "Grid,Sql",
    Estimate: 2,
    Assignee: "Andrew Fuller",
    RankId: 4,
    Color: "#02897B",
    ClassName: "e-story, e-release, e-andrew-fuller",
  },
  {
    Id: "Task 14",
    Title: "Task - 29019",
    Status: "Validate",
    Summary: "Validate databinding issues.",
    Type: "Story",
    Priority: "Low",
    Tags: "Validation",
    Estimate: 1.5,
    Assignee: "Margaret hamilt",
    RankId: 1,
    Color: "#02897B",
    ClassName: "e-story, e-low, e-margaret-hamilt",
  },
  {
    Id: "Task 15",
    Title: "Task - 29020",
    Status: "Close",
    Summary: "Analyze grid control.",
    Type: "Story",
    Priority: "High",
    Tags: "Analyze",
    Estimate: 2.5,
    Assignee: "Margaret hamilt",
    RankId: 5,
    Color: "#02897B",
    ClassName: "e-story, e-high, e-margaret-hamilt",
  },
  {
    Id: "Task 16",
    Title: "Task - 29021",
    Status: "Close",
    Summary: "Stored procedure for initial data binding of the grid.",
    Type: "Others",
    Priority: "Critical",
    Tags: "Databinding",
    Estimate: 1.5,
    Assignee: "Steven walker",
    RankId: 6,
    Color: "#1F88E5",
    ClassName: "e-others, e-release, e-steven-walker",
  },
  {
    Id: "Task 17",
    Title: "Task - 29022",
    Status: "Close",
    Summary: "Analyze stored procedures.",
    Type: "Story",
    Priority: "Critical",
    Tags: "Procedures",
    Estimate: 5.5,
    Assignee: "Janet Leverling",
    RankId: 7,
    Color: "#02897B",
    ClassName: "e-story, e-release, e-janet-leverling",
  },
  {
    Id: "Task 18",
    Title: "Task - 29023",
    Status: "Validate",
    Summary: "Validate editing issues.",
    Type: "Story",
    Priority: "Critical",
    Tags: "Editing",
    Estimate: 1,
    Assignee: "Nancy Davloio",
    RankId: 1,
    Color: "#02897B",
    ClassName: "e-story, e-critical, e-nancy-davloio",
  },
  {
    Id: "Task 19",
    Title: "Task - 29024",
    Status: "Review",
    Summary: "Test editing functionality.",
    Type: "Story",
    Priority: "Normal",
    Tags: "Editing,Test",
    Estimate: 0.5,
    Assignee: "Nancy Davloio",
    RankId: 5,
    Color: "#02897B",
    ClassName: "e-story, e-normal, e-nancy-davloio",
  },
  {
    Id: "Task 20",
    Title: "Task - 29025",
    Status: "Open",
    Summary: "Enhance editing functionality.",
    Type: "Improvement",
    Priority: "Low",
    Tags: "Editing",
    Estimate: 3.5,
    Assignee: "Andrew Fuller",
    RankId: 5,
    Color: "#673AB8",
    ClassName: "e-improvement, e-low, e-andrew-fuller",
  },
  {
    Id: "Task 21",
    Title: "Task - 29026",
    Status: "InProgress",
    Summary: "Improve the performance of the editing functionality.",
    Type: "Epic",
    Priority: "High",
    Tags: "Performance",
    Estimate: 6,
    Assignee: "Nancy Davloio",
    RankId: 5,
    Color: "#e91e64",
    ClassName: "e-epic, e-high, e-nancy-davloio",
  },
  {
    Id: "Task 22",
    Title: "Task - 29027",
    Status: "Open",
    Summary: "Arrange web meeting with the customer to show editing demo.",
    Type: "Others",
    Priority: "High",
    Tags: "Meeting,Editing",
    Estimate: 5.5,
    Assignee: "Steven walker",
    RankId: 6,
    Color: "#1F88E5",
    ClassName: "e-others, e-high, e-steven-walker",
  },
  {
    Id: "Task 23",
    Title: "Task - 29029",
    Status: "Review",
    Summary: "Fix the editing issues reported by the customer.",
    Type: "Bug",
    Priority: "Low",
    Tags: "Editing,Fix",
    Estimate: "3.5",
    Assignee: "Janet Leverling",
    RankId: 6,
    Color: "#E64A19",
    ClassName: "e-bug, e-low, e-janet-leverling",
  },
  {
    Id: "Task 24",
    Title: "Task - 29030",
    Status: "Testing",
    Summary: "Fix the issues reported by the customer.",
    Type: "Bug",
    Priority: "Critical",
    Tags: "Customer",
    Estimate: "3.5",
    Assignee: "Steven walker",
    RankId: 1,
    Color: "#E64A19",
    ClassName: "e-bug, e-critical, e-steven-walker",
  },
  {
    Id: "Task 25",
    Title: "Task - 29031",
    Status: "Testing",
    Summary: "Fix the issues reported in Safari browser.",
    Type: "Bug",
    Priority: "Critical",
    Tags: "Fix,Safari",
    Estimate: 1.5,
    Assignee: "Nancy Davloio",
    RankId: 2,
    Color: "#E64A19",
    ClassName: "e-bug, e-release, e-nancy-davloio",
  },
];

export const financialChartData = [
  {
    x: new Date("2012-04-02"),
    open: 85.9757,
    high: 90.6657,
    low: 85.7685,
    close: 90.5257,
    volume: 660187068,
  },
  {
    x: new Date("2012-04-09"),
    open: 89.4471,
    high: 92,
    low: 86.2157,
    close: 86.4614,
    volume: 912634864,
  },
  {
    x: new Date("2012-04-16"),
    open: 87.1514,
    high: 88.6071,
    low: 81.4885,
    close: 81.8543,
    volume: 1221746066,
  },
  {
    x: new Date("2012-04-23"),
    open: 81.5157,
    high: 88.2857,
    low: 79.2857,
    close: 86.1428,
    volume: 965935749,
  },
  {
    x: new Date("2012-04-30"),
    open: 85.4,
    high: 85.4857,
    low: 80.7385,
    close: 80.75,
    volume: 615249365,
  },
  {
    x: new Date("2012-05-07"),
    open: 80.2143,
    high: 82.2685,
    low: 79.8185,
    close: 80.9585,
    volume: 541742692,
  },
  {
    x: new Date("2012-05-14"),
    open: 80.3671,
    high: 81.0728,
    low: 74.5971,
    close: 75.7685,
    volume: 708126233,
  },
  {
    x: new Date("2012-05-21"),
    open: 76.3571,
    high: 82.3571,
    low: 76.2928,
    close: 80.3271,
    volume: 682076215,
  },
  {
    x: new Date("2012-05-28"),
    open: 81.5571,
    high: 83.0714,
    low: 80.0743,
    close: 80.1414,
    volume: 480059584,
  },
  {
    x: new Date("2012-06-04"),
    open: 80.2143,
    high: 82.9405,
    low: 78.3571,
    close: 82.9028,
    volume: 517577005,
  },
  {
    x: new Date("2012-06-11"),
    open: 83.96,
    high: 84.0714,
    low: 80.9571,
    close: 82.0185,
    volume: 499693120,
  },
  {
    x: new Date("2012-06-18"),
    open: 81.5657,
    high: 84.2857,
    low: 81.4814,
    close: 83.1571,
    volume: 442172142,
  },
  {
    x: new Date("2012-06-25"),
    open: 82.4714,
    high: 83.4285,
    low: 80.8014,
    close: 83.4285,
    volume: 371529102,
  },
  {
    x: new Date("2012-07-02"),
    open: 83.5328,
    high: 87.7628,
    low: 83.3714,
    close: 86.5543,
    volume: 385906790,
  },
  {
    x: new Date("2012-07-09"),
    open: 86.4714,
    high: 88.5528,
    low: 84.6685,
    close: 86.4243,
    volume: 524235196,
  },
  {
    x: new Date("2012-07-16"),
    open: 86.4457,
    high: 87.9071,
    low: 86.1643,
    close: 86.3285,
    volume: 419537217,
  },
  {
    x: new Date("2012-07-23"),
    open: 84.9143,
    high: 87.0971,
    low: 81.4285,
    close: 83.5943,
    volume: 680773023,
  },
  {
    x: new Date("2012-07-30"),
    open: 84.4171,
    high: 88.2828,
    low: 83.9743,
    close: 87.9571,
    volume: 475109323,
  },
  {
    x: new Date("2012-08-06"),
    open: 88.1843,
    high: 89.2857,
    low: 87.8943,
    close: 88.8143,
    volume: 312826308,
  },
  {
    x: new Date("2012-08-13"),
    open: 89.0557,
    high: 92.5985,
    low: 89.0357,
    close: 92.5871,
    volume: 392867193,
  },
  {
    x: new Date("2012-08-20"),
    open: 92.8585,
    high: 96.4114,
    low: 92.5871,
    close: 94.746,
    volume: 708614692,
  },
  {
    x: new Date("2012-08-27"),
    open: 97.1414,
    high: 97.2671,
    low: 93.8928,
    close: 95.0343,
    volume: 383807217,
  },
  {
    x: new Date("2012-09-03"),
    open: 95.1085,
    high: 97.4971,
    low: 94.9285,
    close: 97.2057,
    volume: 355722047,
  },
  {
    x: new Date("2012-09-10"),
    open: 97.2071,
    high: 99.5685,
    low: 93.7143,
    close: 98.7543,
    volume: 724042207,
  },
  {
    x: new Date("2012-09-17"),
    open: 99.9071,
    high: 100.7243,
    low: 99.0885,
    close: 100.0135,
    volume: 500166040,
  },
  {
    x: new Date("2012-09-24"),
    open: 98.1228,
    high: 99.3028,
    low: 94.3357,
    close: 95.3007,
    volume: 714507994,
  },
  {
    x: new Date("2012-10-01"),
    open: 95.88,
    high: 96.6785,
    low: 92.95,
    close: 93.2271,
    volume: 638543622,
  },
  {
    x: new Date("2012-10-08"),
    open: 92.4114,
    high: 92.5085,
    low: 89.0785,
    close: 89.9591,
    volume: 747127724,
  },
  {
    x: new Date("2012-10-15"),
    open: 90.3357,
    high: 93.2557,
    low: 87.0885,
    close: 87.12,
    volume: 646996264,
  },
  {
    x: new Date("2012-10-22"),
    open: 87.4885,
    high: 90.7685,
    low: 84.4285,
    close: 86.2857,
    volume: 866040680,
  },
  {
    x: new Date("2012-10-29"),
    open: 84.9828,
    high: 86.1428,
    low: 82.1071,
    close: 82.4,
    volume: 367371310,
  },
  {
    x: new Date("2012-11-05"),
    open: 83.3593,
    high: 84.3914,
    low: 76.2457,
    close: 78.1514,
    volume: 919719846,
  },
  {
    x: new Date("2012-11-12"),
    open: 79.1643,
    high: 79.2143,
    low: 72.25,
    close: 75.3825,
    volume: 894382149,
  },
  {
    x: new Date("2012-11-19"),
    open: 77.2443,
    high: 81.7143,
    low: 77.1257,
    close: 81.6428,
    volume: 527416747,
  },
  {
    x: new Date("2012-11-26"),
    open: 82.2714,
    high: 84.8928,
    low: 81.7514,
    close: 83.6114,
    volume: 646467974,
  },
  {
    x: new Date("2012-12-03"),
    open: 84.8071,
    high: 84.9414,
    low: 74.09,
    close: 76.1785,
    volume: 980096264,
  },
  {
    x: new Date("2012-12-10"),
    open: 75,
    high: 78.5085,
    low: 72.2257,
    close: 72.8277,
    volume: 835016110,
  },
  {
    x: new Date("2012-12-17"),
    open: 72.7043,
    high: 76.4143,
    low: 71.6043,
    close: 74.19,
    volume: 726150329,
  },
  {
    x: new Date("2012-12-24"),
    open: 74.3357,
    high: 74.8928,
    low: 72.0943,
    close: 72.7984,
    volume: 321104733,
  },
  {
    x: new Date("2012-12-31"),
    open: 72.9328,
    high: 79.2857,
    low: 72.7143,
    close: 75.2857,
    volume: 540854882,
  },
  {
    x: new Date("2013-01-07"),
    open: 74.5714,
    high: 75.9843,
    low: 73.6,
    close: 74.3285,
    volume: 574594262,
  },
  {
    x: new Date("2013-01-14"),
    open: 71.8114,
    high: 72.9643,
    low: 69.0543,
    close: 71.4285,
    volume: 803105621,
  },
  {
    x: new Date("2013-01-21"),
    open: 72.08,
    high: 73.57,
    low: 62.1428,
    close: 62.84,
    volume: 971912560,
  },
  {
    x: new Date("2013-01-28"),
    open: 62.5464,
    high: 66.0857,
    low: 62.2657,
    close: 64.8028,
    volume: 656549587,
  },
  {
    x: new Date("2013-02-04"),
    open: 64.8443,
    high: 68.4014,
    low: 63.1428,
    close: 67.8543,
    volume: 743778993,
  },
  {
    x: new Date("2013-02-11"),
    open: 68.0714,
    high: 69.2771,
    low: 65.7028,
    close: 65.7371,
    volume: 585292366,
  },
  {
    x: new Date("2013-02-18"),
    open: 65.8714,
    high: 66.1043,
    low: 63.26,
    close: 64.4014,
    volume: 421766997,
  },
  {
    x: new Date("2013-02-25"),
    open: 64.8357,
    high: 65.0171,
    low: 61.4257,
    close: 61.4957,
    volume: 582741215,
  },
  {
    x: new Date("2013-03-04"),
    open: 61.1143,
    high: 62.2043,
    low: 59.8571,
    close: 61.6743,
    volume: 632856539,
  },
  {
    x: new Date("2013-03-11"),
    open: 61.3928,
    high: 63.4614,
    low: 60.7343,
    close: 63.38,
    volume: 572066981,
  },
  {
    x: new Date("2013-03-18"),
    open: 63.0643,
    high: 66.0143,
    low: 63.0286,
    close: 65.9871,
    volume: 552156035,
  },
  {
    x: new Date("2013-03-25"),
    open: 66.3843,
    high: 67.1357,
    low: 63.0886,
    close: 63.2371,
    volume: 390762517,
  },
  {
    x: new Date("2013-04-01"),
    open: 63.1286,
    high: 63.3854,
    low: 59.9543,
    close: 60.4571,
    volume: 505273732,
  },
  {
    x: new Date("2013-04-08"),
    open: 60.6928,
    high: 62.57,
    low: 60.3557,
    close: 61.4,
    volume: 387323550,
  },
  {
    x: new Date("2013-04-15"),
    open: 61,
    high: 61.1271,
    low: 55.0143,
    close: 55.79,
    volume: 709945604,
  },
  {
    x: new Date("2013-04-22"),
    open: 56.0914,
    high: 59.8241,
    low: 55.8964,
    close: 59.6007,
    volume: 787007506,
  },
  {
    x: new Date("2013-04-29"),
    open: 60.0643,
    high: 64.7471,
    low: 60,
    close: 64.2828,
    volume: 655020017,
  },
  {
    x: new Date("2013-05-06"),
    open: 65.1014,
    high: 66.5357,
    low: 64.3543,
    close: 64.71,
    volume: 545488533,
  },
  {
    x: new Date("2013-05-13"),
    open: 64.5014,
    high: 65.4143,
    low: 59.8428,
    close: 61.8943,
    volume: 633706550,
  },
  {
    x: new Date("2013-05-20"),
    open: 61.7014,
    high: 64.05,
    low: 61.4428,
    close: 63.5928,
    volume: 494379068,
  },
  {
    x: new Date("2013-05-27"),
    open: 64.2714,
    high: 65.3,
    low: 62.7714,
    close: 64.2478,
    volume: 362907830,
  },
  {
    x: new Date("2013-06-03"),
    open: 64.39,
    high: 64.9186,
    low: 61.8243,
    close: 63.1158,
    volume: 443249793,
  },
  {
    x: new Date("2013-06-10"),
    open: 63.5328,
    high: 64.1541,
    low: 61.2143,
    close: 61.4357,
    volume: 389680092,
  },
  {
    x: new Date("2013-06-17"),
    open: 61.6343,
    high: 62.2428,
    low: 58.3,
    close: 59.0714,
    volume: 400384818,
  },
  {
    x: new Date("2013-06-24"),
    open: 58.2,
    high: 58.38,
    low: 55.5528,
    close: 56.6471,
    volume: 519314826,
  },
  {
    x: new Date("2013-07-01"),
    open: 57.5271,
    high: 60.47,
    low: 57.3171,
    close: 59.6314,
    volume: 343878841,
  },
  {
    x: new Date("2013-07-08"),
    open: 60.0157,
    high: 61.3986,
    low: 58.6257,
    close: 60.93,
    volume: 384106977,
  },
  {
    x: new Date("2013-07-15"),
    open: 60.7157,
    high: 62.1243,
    low: 60.5957,
    close: 60.7071,
    volume: 286035513,
  },
  {
    x: new Date("2013-07-22"),
    open: 61.3514,
    high: 63.5128,
    low: 59.8157,
    close: 62.9986,
    volume: 395816827,
  },
  {
    x: new Date("2013-07-29"),
    open: 62.9714,
    high: 66.1214,
    low: 62.8857,
    close: 66.0771,
    volume: 339668858,
  },
  {
    x: new Date("2013-08-05"),
    open: 66.3843,
    high: 67.4128,
    low: 64.8071,
    close: 64.9214,
    volume: 368486781,
  },
  {
    x: new Date("2013-08-12"),
    open: 65.2657,
    high: 72.0357,
    low: 65.2328,
    close: 71.7614,
    volume: 711563584,
  },
  {
    x: new Date("2013-08-19"),
    open: 72.0485,
    high: 73.3914,
    low: 71.1714,
    close: 71.5743,
    volume: 417119660,
  },
  {
    x: new Date("2013-08-26"),
    open: 71.5357,
    high: 72.8857,
    low: 69.4286,
    close: 69.6023,
    volume: 392805888,
  },
  {
    x: new Date("2013-09-02"),
    open: 70.4428,
    high: 71.7485,
    low: 69.6214,
    close: 71.1743,
    volume: 317244380,
  },
  {
    x: new Date("2013-09-09"),
    open: 72.1428,
    high: 72.56,
    low: 66.3857,
    close: 66.4143,
    volume: 669376320,
  },
  {
    x: new Date("2013-09-16"),
    open: 65.8571,
    high: 68.3643,
    low: 63.8886,
    close: 66.7728,
    volume: 625142677,
  },
  {
    x: new Date("2013-09-23"),
    open: 70.8714,
    high: 70.9871,
    low: 68.6743,
    close: 68.9643,
    volume: 475274537,
  },
  {
    x: new Date("2013-09-30"),
    open: 68.1786,
    high: 70.3357,
    low: 67.773,
    close: 69.0043,
    volume: 368198906,
  },
  {
    x: new Date("2013-10-07"),
    open: 69.5086,
    high: 70.5486,
    low: 68.3257,
    close: 70.4017,
    volume: 361437661,
  },
  {
    x: new Date("2013-10-14"),
    open: 69.9757,
    high: 72.7514,
    low: 69.9071,
    close: 72.6985,
    volume: 342694379,
  },
  {
    x: new Date("2013-10-21"),
    open: 73.11,
    high: 76.1757,
    low: 72.5757,
    close: 75.1368,
    volume: 490458997,
  },
  {
    x: new Date("2013-10-28"),
    open: 75.5771,
    high: 77.0357,
    low: 73.5057,
    close: 74.29,
    volume: 508130174,
  },
  {
    x: new Date("2013-11-04"),
    open: 74.4428,
    high: 75.555,
    low: 73.1971,
    close: 74.3657,
    volume: 318132218,
  },
  {
    x: new Date("2013-11-11"),
    open: 74.2843,
    high: 75.6114,
    low: 73.4871,
    close: 74.9987,
    volume: 306711021,
  },
  {
    x: new Date("2013-11-18"),
    open: 74.9985,
    high: 75.3128,
    low: 73.3814,
    close: 74.2571,
    volume: 282778778,
  },
  {
    x: new Date("2013-11-25"),
    open: 74.4314,
    high: 79.7614,
    low: 74.4285,
    close: 79.4385,
    volume: 327405302,
  },
  {
    x: new Date("2013-12-02"),
    open: 79.7143,
    high: 82.1622,
    low: 78.6885,
    close: 80.0028,
    volume: 522055676,
  },
  {
    x: new Date("2013-12-09"),
    open: 80.1286,
    high: 81.5671,
    low: 79.0957,
    close: 79.2043,
    volume: 387271099,
  },
  {
    x: new Date("2013-12-16"),
    open: 79.2885,
    high: 80.377,
    low: 76.9714,
    close: 78.4314,
    volume: 457580848,
  },
  {
    x: new Date("2013-12-23"),
    open: 81.1428,
    high: 81.6971,
    low: 79.9285,
    close: 80.0128,
    volume: 274253503,
  },
  {
    x: new Date("2013-12-30"),
    open: 79.6371,
    high: 80.1828,
    low: 77.2043,
    close: 77.2828,
    volume: 275734934,
  },
  {
    x: new Date("2014-01-06"),
    open: 76.7785,
    high: 78.1228,
    low: 75.8728,
    close: 76.1343,
    volume: 393462075,
  },
  {
    x: new Date("2014-01-13"),
    open: 75.7014,
    high: 80.0285,
    low: 75.6971,
    close: 77.2385,
    volume: 439557459,
  },
  {
    x: new Date("2014-01-20"),
    open: 77.2843,
    high: 79.6128,
    low: 77.2028,
    close: 78.01,
    volume: 385585525,
  },
  {
    x: new Date("2014-01-27"),
    open: 78.5814,
    high: 79.2571,
    low: 70.5071,
    close: 71.5143,
    volume: 813702575,
  },
  {
    x: new Date("2014-02-03"),
    open: 71.8014,
    high: 74.7042,
    low: 71.3286,
    close: 74.24,
    volume: 434447570,
  },
  {
    x: new Date("2014-02-10"),
    open: 74.0943,
    high: 77.9971,
    low: 74,
    close: 77.7128,
    volume: 379011880,
  },
  {
    x: new Date("2014-02-17"),
    open: 78,
    high: 78.7414,
    low: 74.9428,
    close: 75.0357,
    volume: 289609443,
  },
  {
    x: new Date("2014-02-24"),
    open: 74.7357,
    high: 76.1071,
    low: 73.6571,
    close: 75.1771,
    volume: 367569649,
  },
  {
    x: new Date("2014-03-03"),
    open: 74.7743,
    high: 76.3928,
    low: 74.6871,
    close: 75.7771,
    volume: 275972640,
  },
  {
    x: new Date("2014-03-10"),
    open: 75.48,
    high: 77.0943,
    low: 74.7143,
    close: 74.9557,
    volume: 287729528,
  },
  {
    x: new Date("2014-03-17"),
    open: 75.3857,
    high: 76.6057,
    low: 75.0286,
    close: 76.1243,
    volume: 303531061,
  },
  {
    x: new Date("2014-03-24"),
    open: 76.9171,
    high: 78.4285,
    low: 76.3214,
    close: 76.6943,
    volume: 338387221,
  },
  {
    x: new Date("2014-03-31"),
    open: 77.0328,
    high: 77.64,
    low: 75.7971,
    close: 75.9743,
    volume: 245749459,
  },
  {
    x: new Date("2014-04-07"),
    open: 75.4314,
    high: 76.0343,
    low: 73.8771,
    close: 74.23,
    volume: 312008139,
  },
  {
    x: new Date("2014-04-14"),
    open: 74.5571,
    high: 75.3943,
    low: 73.0471,
    close: 74.9914,
    volume: 241209047,
  },
  {
    x: new Date("2014-04-21"),
    open: 75.0485,
    high: 81.7128,
    low: 74.8514,
    close: 81.7057,
    volume: 476651465,
  },
  {
    x: new Date("2014-04-28"),
    open: 81.8285,
    high: 85.6328,
    low: 81.7928,
    close: 84.6543,
    volume: 473712709,
  },
  {
    x: new Date("2014-05-05"),
    open: 84.3057,
    high: 86.3442,
    low: 82.9043,
    close: 83.6489,
    volume: 365535013,
  },
  {
    x: new Date("2014-05-12"),
    open: 83.9271,
    high: 85.3614,
    low: 83.6285,
    close: 85.3585,
    volume: 260931637,
  },
  {
    x: new Date("2014-05-19"),
    open: 85.4071,
    high: 87.8184,
    low: 85.3328,
    close: 87.7328,
    volume: 294298274,
  },
  {
    x: new Date("2014-05-26"),
    open: 87.9828,
    high: 92.0243,
    low: 87.9471,
    close: 90.4285,
    volume: 400232120,
  },
  {
    x: new Date("2014-06-02"),
    open: 90.5657,
    high: 93.0371,
    low: 88.9285,
    close: 92.2243,
    volume: 412158024,
  },
  {
    x: new Date("2014-06-09"),
    open: 92.7,
    high: 95.05,
    low: 90.88,
    close: 91.28,
    volume: 292422550,
  },
  {
    x: new Date("2014-06-16"),
    open: 91.51,
    high: 92.75,
    low: 90.9,
    close: 90.91,
    volume: 234325480,
  },
  {
    x: new Date("2014-06-23"),
    open: 91.32,
    high: 92,
    low: 89.65,
    close: 91.98,
    volume: 213712160,
  },
  {
    x: new Date("2014-06-30"),
    open: 92.1,
    high: 94.1,
    low: 92.09,
    close: 94.03,
    volume: 138936570,
  },
  {
    x: new Date("2014-07-07"),
    open: 94.14,
    high: 96.8,
    low: 93.52,
    close: 95.22,
    volume: 229782440,
  },
  {
    x: new Date("2014-07-14"),
    open: 95.86,
    high: 97.1,
    low: 92.57,
    close: 94.43,
    volume: 248104390,
  },
  {
    x: new Date("2014-07-21"),
    open: 94.99,
    high: 97.88,
    low: 93.72,
    close: 97.671,
    volume: 273021350,
  },
  {
    x: new Date("2014-07-28"),
    open: 97.82,
    high: 99.44,
    low: 94.81,
    close: 96.13,
    volume: 235868530,
  },
  {
    x: new Date("2014-08-04"),
    open: 96.37,
    high: 96.58,
    low: 93.28,
    close: 94.74,
    volume: 222522120,
  },
  {
    x: new Date("2014-08-11"),
    open: 95.27,
    high: 98.19,
    low: 94.8355,
    close: 97.98,
    volume: 178806580,
  },
  {
    x: new Date("2014-08-18"),
    open: 98.49,
    high: 101.47,
    low: 97.98,
    close: 101.32,
    volume: 246446280,
  },
  {
    x: new Date("2014-08-25"),
    open: 101.79,
    high: 102.9,
    low: 100.7,
    close: 102.5,
    volume: 232858250,
  },
  {
    x: new Date("2014-09-01"),
    open: 103.06,
    high: 103.74,
    low: 97.79,
    close: 98.97,
    volume: 322045910,
  },
  {
    x: new Date("2014-09-08"),
    open: 99.3,
    high: 103.08,
    low: 96.14,
    close: 101.66,
    volume: 460851840,
  },
  {
    x: new Date("2014-09-15"),
    open: 102.81,
    high: 103.05,
    low: 98.89,
    close: 100.96,
    volume: 296425730,
  },
  {
    x: new Date("2014-09-22"),
    open: 101.8,
    high: 102.94,
    low: 97.72,
    close: 100.75,
    volume: 337617850,
  },
  {
    x: new Date("2014-09-29"),
    open: 98.65,
    high: 101.54,
    low: 98.04,
    close: 99.62,
    volume: 246900230,
  },
  {
    x: new Date("2014-10-06"),
    open: 99.95,
    high: 102.38,
    low: 98.31,
    close: 100.73,
    volume: 279646740,
  },
  {
    x: new Date("2014-10-13"),
    open: 101.33,
    high: 101.78,
    low: 95.18,
    close: 97.67,
    volume: 356408760,
  },
  {
    x: new Date("2014-10-20"),
    open: 98.315,
    high: 105.49,
    low: 98.22,
    close: 105.22,
    volume: 355329760,
  },
  {
    x: new Date("2014-10-27"),
    open: 104.85,
    high: 108.04,
    low: 104.7,
    close: 108,
    volume: 219443560,
  },
  {
    x: new Date("2014-11-03"),
    open: 108.22,
    high: 110.3,
    low: 107.72,
    close: 109.01,
    volume: 199332700,
  },
  {
    x: new Date("2014-11-10"),
    open: 109.02,
    high: 114.19,
    low: 108.4,
    close: 114.18,
    volume: 203976340,
  },
  {
    x: new Date("2014-11-17"),
    open: 114.27,
    high: 117.57,
    low: 113.3,
    close: 116.47,
    volume: 232574480,
  },
  {
    x: new Date("2014-11-24"),
    open: 116.85,
    high: 119.75,
    low: 116.62,
    close: 118.93,
    volume: 181158620,
  },
  {
    x: new Date("2014-12-01"),
    open: 118.81,
    high: 119.25,
    low: 111.27,
    close: 115,
    volume: 266118290,
  },
  {
    x: new Date("2014-12-08"),
    open: 114.1,
    high: 114.85,
    low: 109.35,
    close: 109.73,
    volume: 259311140,
  },
  {
    x: new Date("2014-12-15"),
    open: 110.7,
    high: 113.24,
    low: 106.26,
    close: 111.78,
    volume: 326382400,
  },
  {
    x: new Date("2014-12-22"),
    open: 112.16,
    high: 114.52,
    low: 111.97,
    close: 113.99,
    volume: 119248900,
  },
  {
    x: new Date("2014-12-29"),
    open: 113.79,
    high: 114.77,
    low: 107.35,
    close: 109.33,
    volume: 151780640,
  },
  {
    x: new Date("2015-01-05"),
    open: 108.29,
    high: 113.25,
    low: 104.63,
    close: 112.01,
    volume: 282690970,
  },
  {
    x: new Date("2015-01-12"),
    open: 112.6,
    high: 112.8,
    low: 105.2,
    close: 105.99,
    volume: 303531140,
  },
  {
    x: new Date("2015-01-19"),
    open: 107.84,
    high: 113.75,
    low: 106.5,
    close: 112.98,
    volume: 198362640,
  },
  {
    x: new Date("2015-01-26"),
    open: 113.74,
    high: 120,
    low: 109.03,
    close: 117.16,
    volume: 461747290,
  },
  {
    x: new Date("2015-02-02"),
    open: 118.05,
    high: 120.51,
    low: 116.08,
    close: 118.93,
    volume: 270190470,
  },
  {
    x: new Date("2015-02-09"),
    open: 118.55,
    high: 127.48,
    low: 118.43,
    close: 127.08,
    volume: 301354470,
  },
  {
    x: new Date("2015-02-16"),
    open: 127.49,
    high: 129.5,
    low: 126.92,
    close: 129.495,
    volume: 193883960,
  },
  {
    x: new Date("2015-02-23"),
    open: 130.02,
    high: 133.6,
    low: 126.61,
    close: 128.46,
    volume: 369856960,
  },
  {
    x: new Date("2015-03-02"),
    open: 129.25,
    high: 130.28,
    low: 125.76,
    close: 126.6,
    volume: 246472020,
  },
  {
    x: new Date("2015-03-09"),
    open: 127.96,
    high: 129.57,
    low: 121.63,
    close: 123.59,
    volume: 325921230,
  },
  {
    x: new Date("2015-03-16"),
    open: 123.88,
    high: 129.2451,
    low: 122.87,
    close: 125.9,
    volume: 266016400,
  },
  {
    x: new Date("2015-03-23"),
    open: 127.12,
    high: 128.04,
    low: 122.6,
    close: 123.25,
    volume: 208731730,
  },
  {
    x: new Date("2015-03-30"),
    open: 124.05,
    high: 126.49,
    low: 123.1,
    close: 125.32,
    volume: 161628950,
  },
  {
    x: new Date("2015-04-06"),
    open: 124.47,
    high: 128.1218,
    low: 124.33,
    close: 127.1,
    volume: 181454510,
  },
  {
    x: new Date("2015-04-13"),
    open: 128.37,
    high: 128.57,
    low: 124.46,
    close: 124.75,
    volume: 170090870,
  },
  {
    x: new Date("2015-04-20"),
    open: 125.57,
    high: 130.63,
    low: 125.17,
    close: 130.28,
    volume: 206698310,
  },
  {
    x: new Date("2015-04-27"),
    open: 132.31,
    high: 134.54,
    low: 124.58,
    close: 128.95,
    volume: 417115180,
  },
  {
    x: new Date("2015-05-04"),
    open: 129.5,
    high: 130.57,
    low: 123.36,
    close: 127.62,
    volume: 270197900,
  },
  {
    x: new Date("2015-05-11"),
    open: 127.39,
    high: 129.49,
    low: 124.82,
    close: 128.77,
    volume: 207858180,
  },
  {
    x: new Date("2015-05-18"),
    open: 128.38,
    high: 132.97,
    low: 128.36,
    close: 132.54,
    volume: 216438970,
  },
  {
    x: new Date("2015-05-25"),
    open: 132.6,
    high: 132.91,
    low: 129.12,
    close: 130.28,
    volume: 197468800,
  },
  {
    x: new Date("2015-06-01"),
    open: 131.2,
    high: 131.39,
    low: 128.36,
    close: 128.65,
    volume: 170465550,
  },
  {
    x: new Date("2015-06-08"),
    open: 128.9,
    high: 130.18,
    low: 125.62,
    close: 127.17,
    volume: 219812710,
  },
  {
    x: new Date("2015-06-15"),
    open: 126.1,
    high: 128.31,
    low: 125.71,
    close: 126.6,
    volume: 197925030,
  },
  {
    x: new Date("2015-06-22"),
    open: 127.49,
    high: 129.8,
    low: 126.51,
    close: 126.75,
    volume: 195104520,
  },
  {
    x: new Date("2015-06-29"),
    open: 125.46,
    high: 126.94,
    low: 124.48,
    close: 126.44,
    volume: 150747530,
  },
  {
    x: new Date("2015-07-06"),
    open: 124.94,
    high: 126.23,
    low: 119.22,
    close: 123.28,
    volume: 274904180,
  },
  {
    x: new Date("2015-07-13"),
    open: 125.03,
    high: 129.62,
    low: 124.32,
    close: 129.62,
    volume: 188579340,
  },
  {
    x: new Date("2015-07-20"),
    open: 130.97,
    high: 132.97,
    low: 121.99,
    close: 124.5,
    volume: 336423370,
  },
  {
    x: new Date("2015-07-27"),
    open: 123.09,
    high: 123.91,
    low: 120.91,
    close: 121.3,
    volume: 191087840,
  },
  {
    x: new Date("2015-08-03"),
    open: 121.5,
    high: 122.57,
    low: 112.1,
    close: 115.52,
    volume: 383883210,
  },
  {
    x: new Date("2015-08-10"),
    open: 116.53,
    high: 119.99,
    low: 109.63,
    close: 115.96,
    volume: 344549090,
  },
  {
    x: new Date("2015-08-17"),
    open: 116.04,
    high: 117.65,
    low: 105.645,
    close: 105.76,
    volume: 318855760,
  },
  {
    x: new Date("2015-08-24"),
    open: 110.87,
    high: 113.31,
    low: 102.6,
    close: 113.29,
    volume: 498047270,
  },
  {
    x: new Date("2015-08-31"),
    open: 112.03,
    high: 114.53,
    low: 107.36,
    close: 109.27,
    volume: 297402060,
  },
  {
    x: new Date("2015-09-07"),
    open: 111.65,
    high: 114.21,
    low: 109.77,
    close: 114.21,
    volume: 251859600,
  },
  {
    x: new Date("2015-09-14"),
    open: 116.58,
    high: 116.89,
    low: 111.87,
    close: 113.45,
    volume: 276281980,
  },
  {
    x: new Date("2015-09-21"),
    open: 113.67,
    high: 116.69,
    low: 112.37,
    close: 114.71,
    volume: 238617740,
  },
  {
    x: new Date("2015-09-28"),
    open: 113.85,
    high: 114.57,
    low: 107.31,
    close: 110.38,
    volume: 313017610,
  },
  {
    x: new Date("2015-10-05"),
    open: 109.88,
    high: 112.28,
    low: 108.21,
    close: 112.12,
    volume: 261920950,
  },
  {
    x: new Date("2015-10-12"),
    open: 112.73,
    high: 112.75,
    low: 109.56,
    close: 111.04,
    volume: 184208970,
  },
  {
    x: new Date("2015-10-19"),
    open: 110.8,
    high: 119.228,
    low: 110.11,
    close: 119.08,
    volume: 221612230,
  },
  {
    x: new Date("2015-10-26"),
    open: 118.08,
    high: 121.22,
    low: 113.99,
    close: 119.5,
    volume: 319660750,
  },
  {
    x: new Date("2015-11-02"),
    open: 119.87,
    high: 123.82,
    low: 119.61,
    close: 121.06,
    volume: 194953700,
  },
  {
    x: new Date("2015-11-09"),
    open: 120.96,
    high: 121.81,
    low: 112.27,
    close: 112.34,
    volume: 216054740,
  },
  {
    x: new Date("2015-11-16"),
    open: 111.38,
    high: 119.92,
    low: 111,
    close: 119.3,
    volume: 189492500,
  },
  {
    x: new Date("2015-11-23"),
    open: 119.27,
    high: 119.73,
    low: 117.12,
    close: 117.81,
    volume: 109582040,
  },
  {
    x: new Date("2015-11-30"),
    open: 117.99,
    high: 119.41,
    low: 114.22,
    close: 119.03,
    volume: 205415620,
  },
  {
    x: new Date("2015-12-07"),
    open: 118.98,
    high: 119.86,
    low: 112.851,
    close: 113.18,
    volume: 188609110,
  },
  {
    x: new Date("2015-12-14"),
    open: 112.18,
    high: 112.8,
    low: 105.81,
    close: 106.03,
    volume: 314856190,
  },
  {
    x: new Date("2015-12-21"),
    open: 107.28,
    high: 109,
    low: 105.57,
    close: 108.03,
    volume: 126450510,
  },
  {
    x: new Date("2015-12-28"),
    open: 107.59,
    high: 109.43,
    low: 104.82,
    close: 105.26,
    volume: 123621760,
  },
  {
    x: new Date("2016-01-04"),
    open: 102.61,
    high: 105.85,
    low: 96.43,
    close: 96.96,
    volume: 343000960,
  },
  {
    x: new Date("2016-01-11"),
    open: 98.97,
    high: 101.19,
    low: 95.36,
    close: 97.13,
    volume: 303375940,
  },
  {
    x: new Date("2016-01-18"),
    open: 98.41,
    high: 101.46,
    low: 93.42,
    close: 101.42,
    volume: 242982970,
  },
  {
    x: new Date("2016-01-25"),
    open: 101.52,
    high: 101.53,
    low: 92.39,
    close: 97.34,
    volume: 376481100,
  },
  {
    x: new Date("2016-02-01"),
    open: 96.47,
    high: 97.33,
    low: 93.69,
    close: 94.02,
    volume: 216608840,
  },
  {
    x: new Date("2016-02-08"),
    open: 93.13,
    high: 96.35,
    low: 92.59,
    close: 93.99,
    volume: 230794620,
  },
  {
    x: new Date("2016-02-15"),
    open: 95.02,
    high: 98.89,
    low: 94.61,
    close: 96.04,
    volume: 167001070,
  },
  {
    x: new Date("2016-02-22"),
    open: 96.31,
    high: 98.0237,
    low: 93.32,
    close: 96.91,
    volume: 158759600,
  },
  {
    x: new Date("2016-02-29"),
    open: 96.86,
    high: 103.75,
    low: 96.65,
    close: 103.01,
    volume: 201482180,
  },
  {
    x: new Date("2016-03-07"),
    open: 102.39,
    high: 102.83,
    low: 100.15,
    close: 102.26,
    volume: 155437450,
  },
  {
    x: new Date("2016-03-14"),
    open: 101.91,
    high: 106.5,
    low: 101.78,
    close: 105.92,
    volume: 181323210,
  },
  {
    x: new Date("2016-03-21"),
    open: 105.93,
    high: 107.65,
    low: 104.89,
    close: 105.67,
    volume: 119054360,
  },
  {
    x: new Date("2016-03-28"),
    open: 106,
    high: 110.42,
    low: 104.88,
    close: 109.99,
    volume: 147641240,
  },
  {
    x: new Date("2016-04-04"),
    open: 110.42,
    high: 112.19,
    low: 108.121,
    close: 108.66,
    volume: 145351790,
  },
  {
    x: new Date("2016-04-11"),
    open: 108.97,
    high: 112.39,
    low: 108.66,
    close: 109.85,
    volume: 161518860,
  },
  {
    x: new Date("2016-04-18"),
    open: 108.89,
    high: 108.95,
    low: 104.62,
    close: 105.68,
    volume: 188775240,
  },
  {
    x: new Date("2016-04-25"),
    open: 105,
    high: 105.65,
    low: 92.51,
    close: 93.74,
    volume: 345910030,
  },
  {
    x: new Date("2016-05-02"),
    open: 93.965,
    high: 95.9,
    low: 91.85,
    close: 92.72,
    volume: 225114110,
  },
  {
    x: new Date("2016-05-09"),
    open: 93,
    high: 93.77,
    low: 89.47,
    close: 90.52,
    volume: 215596350,
  },
  {
    x: new Date("2016-05-16"),
    open: 92.39,
    high: 95.43,
    low: 91.65,
    close: 95.22,
    volume: 212312980,
  },
  {
    x: new Date("2016-05-23"),
    open: 95.87,
    high: 100.73,
    low: 95.67,
    close: 100.35,
    volume: 203902650,
  },
  {
    x: new Date("2016-05-30"),
    open: 99.6,
    high: 100.4,
    low: 96.63,
    close: 97.92,
    volume: 140064910,
  },
  {
    x: new Date("2016-06-06"),
    open: 97.99,
    high: 101.89,
    low: 97.55,
    close: 98.83,
    volume: 124731320,
  },
  {
    x: new Date("2016-06-13"),
    open: 98.69,
    high: 99.12,
    low: 95.3,
    close: 95.33,
    volume: 191017280,
  },
  {
    x: new Date("2016-06-20"),
    open: 96,
    high: 96.89,
    low: 92.65,
    close: 93.4,
    volume: 206149160,
  },
  {
    x: new Date("2016-06-27"),
    open: 93,
    high: 96.465,
    low: 91.5,
    close: 95.89,
    volume: 184254460,
  },
  {
    x: new Date("2016-07-04"),
    open: 95.39,
    high: 96.89,
    low: 94.37,
    close: 96.68,
    volume: 111769640,
  },
  {
    x: new Date("2016-07-11"),
    open: 96.75,
    high: 99.3,
    low: 96.73,
    close: 98.78,
    volume: 142244590,
  },
  {
    x: new Date("2016-07-18"),
    open: 98.7,
    high: 101,
    low: 98.31,
    close: 98.66,
    volume: 147358320,
  },
  {
    x: new Date("2016-07-25"),
    open: 98.25,
    high: 104.55,
    low: 96.42,
    close: 104.21,
    volume: 252358930,
  },
  {
    x: new Date("2016-08-01"),
    open: 104.41,
    high: 107.65,
    low: 104,
    close: 107.48,
    volume: 168265830,
  },
  {
    x: new Date("2016-08-08"),
    open: 107.52,
    high: 108.94,
    low: 107.16,
    close: 108.18,
    volume: 124255340,
  },
  {
    x: new Date("2016-08-15"),
    open: 108.14,
    high: 110.23,
    low: 108.08,
    close: 109.36,
    volume: 131814920,
  },
  {
    x: new Date("2016-08-22"),
    open: 108.86,
    high: 109.32,
    low: 106.31,
    close: 106.94,
    volume: 123373540,
  },
  {
    x: new Date("2016-08-29"),
    open: 106.62,
    high: 108,
    low: 105.5,
    close: 107.73,
    volume: 134426100,
  },
  {
    x: new Date("2016-09-05"),
    open: 107.9,
    high: 108.76,
    low: 103.13,
    close: 103.13,
    volume: 168312530,
  },
  {
    x: new Date("2016-09-12"),
    open: 102.65,
    high: 116.13,
    low: 102.53,
    close: 114.92,
    volume: 388543710,
  },
  {
    x: new Date("2016-09-19"),
    open: 115.19,
    high: 116.18,
    low: 111.55,
    close: 112.71,
    volume: 200842480,
  },
  {
    x: new Date("2016-09-26"),
    open: 111.64,
    high: 114.64,
    low: 111.55,
    close: 113.05,
    volume: 156186800,
  },
  {
    x: new Date("2016-10-03"),
    open: 112.71,
    high: 114.56,
    low: 112.28,
    close: 114.06,
    volume: 125587350,
  },
  {
    x: new Date("2016-10-10"),
    open: 115.02,
    high: 118.69,
    low: 114.72,
    close: 117.63,
    volume: 208231690,
  },
  {
    x: new Date("2016-10-17"),
    open: 117.33,
    high: 118.21,
    low: 113.8,
    close: 116.6,
    volume: 114497020,
  },
  {
    x: new Date("2016-10-24"),
    open: 117.1,
    high: 118.36,
    low: 113.31,
    close: 113.72,
    volume: 204530120,
  },
  {
    x: new Date("2016-10-31"),
    open: 113.65,
    high: 114.23,
    low: 108.11,
    close: 108.84,
    volume: 155287280,
  },
  {
    x: new Date("2016-11-07"),
    open: 110.08,
    high: 111.72,
    low: 105.83,
    close: 108.43,
    volume: 206825070,
  },
  {
    x: new Date("2016-11-14"),
    open: 107.71,
    high: 110.54,
    low: 104.08,
    close: 110.06,
    volume: 197790040,
  },
  {
    x: new Date("2016-11-21"),
    open: 110.12,
    high: 112.42,
    low: 110.01,
    close: 111.79,
    volume: 93992370,
  },
  {
    x: new Date("2016-11-28"),
    open: 111.43,
    high: 112.465,
    low: 108.85,
    close: 109.9,
    volume: 155229390,
  },
  {
    x: new Date("2016-12-05"),
    open: 110,
    high: 114.7,
    low: 108.25,
    close: 113.95,
    volume: 151624650,
  },
  {
    x: new Date("2016-12-12"),
    open: 113.29,
    high: 116.73,
    low: 112.49,
    close: 115.97,
    volume: 194003220,
  },
  {
    x: new Date("2016-12-19"),
    open: 115.8,
    high: 117.5,
    low: 115.59,
    close: 116.52,
    volume: 113106370,
  },
  {
    x: new Date("2016-12-26"),
    open: 116.52,
    high: 118.0166,
    low: 115.43,
    close: 115.82,
    volume: 84354060,
  },
  {
    x: new Date("2017-01-02"),
    open: 115.8,
    high: 118.16,
    low: 114.76,
    close: 117.91,
    volume: 103680760,
  },
  {
    x: new Date("2017-01-09"),
    open: 117.95,
    high: 119.93,
    low: 117.94,
    close: 119.04,
    volume: 138446660,
  },
  {
    x: new Date("2017-01-16"),
    open: 118.34,
    high: 120.5,
    low: 118.22,
    close: 120,
    volume: 113576380,
  },
  {
    x: new Date("2017-01-23"),
    open: 120,
    high: 122.44,
    low: 119.5,
    close: 121.95,
    volume: 124406640,
  },
  {
    x: new Date("2017-01-30"),
    open: 120.93,
    high: 130.49,
    low: 120.62,
    close: 129.08,
    volume: 248063580,
  },
  {
    x: new Date("2017-02-06"),
    open: 129.13,
    high: 132.94,
    low: 128.9,
    close: 132.12,
    volume: 136252280,
  },
  {
    x: new Date("2017-02-13"),
    open: 133.08,
    high: 136.27,
    low: 132.75,
    close: 135.72,
    volume: 136326260,
  },
  {
    x: new Date("2017-02-20"),
    open: 136.23,
    high: 137.48,
    low: 135.28,
    close: 136.66,
    volume: 87773190,
  },
  {
    x: new Date("2017-02-27"),
    open: 137.14,
    high: 140.2786,
    low: 136.28,
    close: 139.78,
    volume: 127757050,
  },
  {
    x: new Date("2017-03-06"),
    open: 139.365,
    high: 139.98,
    low: 137.05,
    close: 139.14,
    volume: 99061270,
  },
  {
    x: new Date("2017-03-13"),
    open: 138.85,
    high: 141.02,
    low: 138.82,
    close: 139.99,
    volume: 120881720,
  },
  {
    x: new Date("2017-03-20"),
    open: 140.4,
    high: 142.8,
    low: 139.73,
    close: 140.64,
    volume: 129178500,
  },
  {
    x: new Date("2017-03-27"),
    open: 139.39,
    high: 144.5,
    low: 138.62,
    close: 143.66,
    volume: 126819590,
  },
  {
    x: new Date("2017-04-03"),
    open: 143.71,
    high: 145.46,
    low: 143.05,
    close: 143.34,
    volume: 105274540,
  },
  {
    x: new Date("2017-04-10"),
    open: 143.6,
    high: 143.8792,
    low: 140.06,
    close: 141.05,
    volume: 87342130,
  },
  {
    x: new Date("2017-04-17"),
    open: 141.48,
    high: 142.92,
    low: 140.45,
    close: 142.27,
    volume: 89092650,
  },
  {
    x: new Date("2017-04-24"),
    open: 143.5,
    high: 144.9,
    low: 143.18,
    close: 143.65,
    volume: 90423600,
  },
  {
    x: new Date("2017-05-01"),
    open: 145.1,
    high: 148.98,
    low: 144.27,
    close: 148.96,
    volume: 173861760,
  },
  {
    x: new Date("2017-05-08"),
    open: 149.03,
    high: 156.42,
    low: 149.03,
    close: 156.1,
    volume: 173087500,
  },
  {
    x: new Date("2017-05-15"),
    open: 156.01,
    high: 156.65,
    low: 149.71,
    close: 153.06,
    volume: 156993820,
  },
  {
    x: new Date("2017-05-22"),
    open: 154,
    high: 154.9,
    low: 152.67,
    close: 153.61,
    volume: 103151450,
  },
  {
    x: new Date("2017-05-29"),
    open: 153.42,
    high: 155.45,
    low: 152.22,
    close: 155.45,
    volume: 88670120,
  },
  {
    x: new Date("2017-06-05"),
    open: 154.34,
    high: 155.98,
    low: 146.02,
    close: 148.98,
    volume: 158814040,
  },
  {
    x: new Date("2017-06-12"),
    open: 145.74,
    high: 147.5,
    low: 142.2,
    close: 142.27,
    volume: 219638930,
  },
  {
    x: new Date("2017-06-19"),
    open: 143.66,
    high: 147.16,
    low: 143.66,
    close: 146.28,
    volume: 132832660,
  },
  {
    x: new Date("2017-06-26"),
    open: 147.17,
    high: 148.28,
    low: 142.28,
    close: 144.02,
    volume: 126890110,
  },
  {
    x: new Date("2017-07-03"),
    open: 144.88,
    high: 145.3001,
    low: 142.41,
    close: 144.18,
    volume: 78465450,
  },
  {
    x: new Date("2017-07-10"),
    open: 144.11,
    high: 149.33,
    low: 143.37,
    close: 149.04,
    volume: 109759170,
  },
  {
    x: new Date("2017-07-17"),
    open: 148.82,
    high: 151.74,
    low: 148.57,
    close: 150.27,
    volume: 104744470,
  },
  {
    x: new Date("2017-07-24"),
    open: 150.58,
    high: 153.99,
    low: 147.3,
    close: 149.5,
    volume: 105536280,
  },
  {
    x: new Date("2017-07-31"),
    open: 149.9,
    high: 159.75,
    low: 148.13,
    close: 156.39,
    volume: 170204830,
  },
  {
    x: new Date("2017-08-07"),
    open: 157.06,
    high: 161.83,
    low: 154.63,
    close: 157.48,
    volume: 149860480,
  },
  {
    x: new Date("2017-08-14"),
    open: 159.32,
    high: 162.51,
    low: 156.72,
    close: 157.5,
    volume: 133829670,
  },
  {
    x: new Date("2017-08-21"),
    open: 157.5,
    high: 160.74,
    low: 155.1101,
    close: 159.86,
    volume: 112238670,
  },
  {
    x: new Date("2017-08-28"),
    open: 160.14,
    high: 164.94,
    low: 159.93,
    close: 164.05,
    volume: 125610990,
  },
  {
    x: new Date("2017-09-04"),
    open: 163.75,
    high: 164.25,
    low: 158.53,
    close: 158.63,
    volume: 101419110,
  },
  {
    x: new Date("2017-09-11"),
    open: 160.5,
    high: 163.96,
    low: 157.91,
    close: 159.88,
    volume: 220431100,
  },
  {
    x: new Date("2017-09-18"),
    open: 160.11,
    high: 160.5,
    low: 157.995,
    close: 158.67,
    volume: 27939544,
  },
];
export const PyramidData = [
  { x: "Sweet Treats", y: 120, text: "120 cal" },
  { x: "Milk, Youghnut, Cheese", y: 435, text: "435 cal" },
  { x: "Vegetables", y: 470, text: "470 cal" },
  { x: "Meat, Poultry, Fish", y: 475, text: "475 cal" },
  { x: "Fruits", y: 520, text: "520 cal" },
  { x: "Bread, Rice, Pasta", y: 930, text: "930 cal" },
];

export const Départements = [
  { value: "", label: "Sélectionner votre département" },
  {
    value: "01 - Ain",
    label: "01 - Ain",
  },
  {
    value: "02 - Aisne",
    label: "02 - Aisne",
  },
  { value: "03 - Allier", label: "03 - Allier" },
  {
    value: "04 - Alpes-de-Haute-Provence",
    label: "04 - Alpes-de-Haute-Provence",
  },
  {
    value: "05 - Hautes-alpes",
    label: "05 - Hautes-alpes",
  },
  {
    value: "06 - Alpes-maritimes",
    label: "06 - Alpes-maritimes",
  },
  { value: "07 - Ardèche", label: "07 - Ardèche" },
  { value: "08 - Ardennes", label: "08 - Ardennes" },
  {
    value: "09 - Ariège",
    label: "09 - Ariège",
  },
  { value: "10 - Aube", label: "10 - Aube" },
  {
    value: "11 - Aude",
    label: "11 - Aude",
  },
  {
    value: "12 - Aveyron",
    label: "12 - Aveyron",
  },
  { value: "13 - Bouches-du-Rhône", label: "13 - Bouches-du-Rhône" },
  {
    value: "14 - Calvados",
    label: "14 - Calvados",
  },
  {
    value: "15 - Cantal",
    label: "15 - Cantal",
  },
  { value: "16 - Charente", label: "16 - Charente" },
  {
    value: "17 - Charente-maritime",
    label: "17 - Charente-maritime",
  },
  {
    value: "18 - Cher",
    label: "18 - Cher",
  },
  {
    value: "19 - Corrèze",
    label: "19 - Corrèze",
  },
  { value: "2A - Corse-du-sud", label: "2A - Corse-du-sud" },
  { value: "2B - Haute-Corse", label: "2B - Haute-Corse" },
  {
    value: "21 - Côte-d'Or",
    label: "21 - Côte-d'Or",
  },
  { value: "22 - Côtes-d'Armor", label: "22 - Côtes-d'Armor" },
  {
    value: "23 - Creuse",
    label: "23 - Creuse",
  },
  {
    value: "24 - Dordogne",
    label: "24 - Dordogne",
  },
  {
    value: "25 - Doubs",
    label: "25 - Doubs",
  },
  { value: "26 - Drôme", label: "26 - Drôme" },
  {
    value: "27 - Eure",
    label: "27 - Eure",
  },
  {
    value: "28 - Eure-et-loir",
    label: "28 - Eure-et-loir",
  },
  {
    value: "29 - Finistère",
    label: "29 - Finistère",
  },
  { value: "30 - Gard", label: "30 - Gard" },
  { value: "31 - Haute-garonne", label: "31 - Haute-garonne" },
  {
    value: "32 - Gers",
    label: "32 - Gers",
  },
  { value: "33 - Gironde", label: "33 - Gironde" },
  {
    value: "34 - Hérault",
    label: "34 - Hérault",
  },
  {
    value: "35 - Ille-et-vilaine",
    label: "35 - Ille-et-vilaine",
  },
  { value: "36 - Indre", label: "36 - Indre" },
  {
    value: "37 - Indre-et-loire",
    label: "37 - Indre-et-loire",
  },
  {
    value: "38 - Isère",
    label: "38 - Isère",
  },
  { value: "39 - Jura", label: "39 - Jura" },
  {
    value: "40 - Landes",
    label: "40 - Landes",
  },
  {
    value: "41 - Loir-et-cher",
    label: "41 - Loir-et-cher",
  },
  {
    value: "42 - Loire",
    label: "42 - Loire",
  },
  { value: "43 - Haute-loire", label: "43 - Haute-loire" },
  { value: "44 - Loire-atlantique", label: "44 - Loire-atlantique" },
  {
    value: "45 - Loiret",
    label: "45 - Loiret",
  },
  { value: "46 - Lot", label: "46 - Lot" },
  {
    value: "47 - Lot-et-garonne",
    label: "47 - Lot-et-garonne",
  },
  {
    value: "48 - Lozère",
    label: "48 - Lozère",
  },
  { value: "49 - Maine-et-loire", label: "49 - Maine-et-loire" },
  {
    value: "50 - Manche",
    label: "50 - Manche",
  },
  {
    value: "51 - Marne",
    label: "51 - Marne",
  },
  { value: "52 - Haute-marne", label: "52 - Haute-marne" },
  {
    value: "53 - Mayenne",
    label: "53 - Mayenne",
  },
  {
    value: "54 - Meurthe-et-moselle",
    label: "54 - Meurthe-et-moselle",
  },
  {
    value: "55 - Meuse",
    label: "55 - Meuse",
  },
  { value: "56 - Morbihan", label: "56 - Morbihan" },
  { value: "57 - Moselle", label: "57 - Moselle" },
  {
    value: "58 - Nièvre",
    label: "58 - Nièvre",
  },
  { value: "59 - Nord", label: "59 - Nord" },
  {
    value: "60 - Oise",
    label: "60 - Oise",
  },
  { value: "61 - Orne", label: "61 - Orne" },
  { value: "62 - Pas-de-calais", label: "62 - Pas-de-calais" },
  { value: "63 - Puy-de-dôme", label: "63 - Puy-de-dôme" },
  { value: "64 - Pyrénées-atlantiques", label: "64 - Pyrénées-atlantiques" },
  {
    value: "65 - Hautes-Pyrénées",
    label: "65 - Hautes-Pyrénées",
  },
  { value: "66 - Pyrénées-orientales", label: "66 - Pyrénées-orientales" },
  { value: "67 - Bas-rhin", label: "67 - Bas-rhin" },
  {
    value: "68 - Haut-rhin",
    label: "68 - Haut-rhin",
  },
  { value: "69 - Rhône", label: "69 - Rhône" },
  { value: "70 - Haute-saône", label: "70 - Haute-saône" },
  { value: "71 - Saône-et-loire", label: "71 - Saône-et-loire" },
  { value: "72 - Sarthe", label: "72 - Sarthe" },
  { value: "73 - Savoie", label: "73 - Savoie" },
  { value: "74 - Haute-savoie", label: "74 - Haute-savoie" },
  { value: "75 - Paris", label: "75 - Paris" },
  { value: "76 - Seine-maritime", label: "76 - Seine-maritime" },
  { value: "77 - Seine-et-marne", label: "77 - Seine-et-marne" },
  { value: "78 - Yvelines", label: "78 - Yvelines" },
  { value: "79 - Deux-sèvres", label: "79 - Deux-sèvres" },
  { value: "80 - Somme", label: "80 - Somme" },
  { value: "81 - Tarn", label: "81 - Tarn" },
  { value: "82 - Tarn-et-Garonne", label: "82 - Tarn-et-Garonne" },
  { value: "83 - Var", label: "83 - Var" },
  { value: "84 - Vaucluse", label: "84 - Vaucluse" },
  { value: "85 - Vendée", label: "85 - Vendée" },
  { value: "86 - Vienne", label: "86 - Vienne" },
  { value: "87 - Haute-vienne", label: "87 - Haute-vienne" },
  { value: "88 - Vosges", label: "88 - Vosges" },
  { value: "89 - Yonne", label: "89 - Yonne" },
  { value: "90 - Territoire de belfort", label: "90 - Territoire de belfort" },
  { value: "91 - Essonne", label: "91 - Essonne" },
  { value: "92 - Hauts-de-seine", label: "92 - Hauts-de-seine" },
  { value: "93 - Seine-Saint-Denis", label: "93 - Seine-Saint-Denis" },
  { value: "94 - Val-de-marne", label: "94 - Val-de-marne" },
  { value: "95 - Val-d'Oise", label: "95 - Val-d'Oise" },
];

/*export const Marque = [
  "ACOMAT INTERNATIONAL",
  "ADEE ELECTRONIC",
  "ALDES",
  "ARISTON",
  "ATELF FRANCE",
  "ATLANTIC CLIM & VENT",
  "ATLANTIC PAC ET CHAU",
  "ATLANTIC SCGA",
  "AUBER PRO",
  "AXELAIR",
  "BARBERI SRL",
  "BDR",
  "BRONPI CALEFACCION S",
  "CABLERIE DAUMESNIL",
  "CALEGARI",
  "CASTEL ENGENEERING",
  "CT0001243",
  "DAIKIN",
  "DANFOSS",
  "DIGITAL ELECTRIC",
  "DINAK FRANCE",
  "DINDISTRI",
  "DOMOS INDUSTRIES",
  "DOMUSA TEKNIK",
  "DUCO",
  "EDF",
  "EDILTECO",
  "ENERGIEPAC",
  "ESE SOLAR",
  "ETEX",
  "EXTRAFLAME S.P.A",
  "FEMAT",
  "FERROLI FRANCE",
  "FRIGICOLL",
  "GUANGZHOU JINSHENG",
  "HIRSCH ISOLATION",
  "ISOCLIMA SPA",
  "ISODIS",
  "ISOMO",
  "J&M BAT",
  "JINNENG",
  "JOKERTRANSLOG",
  "K2 SYSTEMS GMBH",
  "KESKLIMA",
  "KLIMA RODACLIM",
  "LASIAN SL",
  "LEROY LOGISTIQUE",
  "MESATRANS",
  "MICROSOFT",
  "MORETTI DESIGN",
  "NATHER",
  "NESPRESSO FRANCE",
  "OBJECTIF ECONOMIE",
  "PANOL DISTRIBUTION",
  "PB TUB",
  "QN ENERGY",
  "RODIGAS SRL",
  "ROLESCO",
  "SAMSUNG",
  "SASU STOVE INDUSTRY",
  "SAUNIER DUVAL",
  "SERVELITE",
  "SFERACO",
  "SIDER",
  "SOGETUB",
  "SOLER & PALAU",
  "SOLIMPEKS",
  "SOPARCO",
  "STG",
  "STOVE ITALIA FRANCE",
  "STOVEITALIA",
  "TEBA THERM",
  "TECNOCAMINI SRL",
  "THERMADOR",
  "TRANSPORT26",
  "VANITEX",
  "WATTS",
  "WURTH",
];*/

export const Marque = [
  "DE DIETRICH",
  "ATLANTIC",
  "SAMSUNG",
  "ARISTON/ CHAFFAUTEAUX",
  "DAIKIN",
  "PANASONIC",
  "HITACHI",
  "LG",
  "STIEBEL ELTRON",
  "S&P",
  "ECOYA",
  "STOVE",
  "MORETTI DESIGN",
  "BRONPI",
  "DE DIETRICH",
  "DELBA",
  "SOLAIRE HYDRO",
  "DE DIETRICH",
  "ECOYA",
  "THERMADOR",
  "PV",
  "ECOYA",
  "ENPHASE",
  "IRVE",
  "ECOYA",
  "SHEINDER",
];

export const LebonTechnicien_data = {
  section1: {
    title: "Votre satisfaction est notre priorité",
    subtitle:
      "Profitez de l'expertise de l'élite des professionnels en énergies renouvelables pour un entretien paisible de vos équipements !",
    paragraph:
      "Notre application vous offre une expérience unique en vous mettant en relation avec l'élite des professionnels engagés dans l'installation et la réparation  des équipements d'énergies renouvelables. Découvrez une plateforme simple d'utilisation, rapide et fiable qui vous garantit des techniciens compétents et rigoureusement sélectionnés selon leurs compétences et les notes attribuées par les clients. Inscrivez-vous dès maintenant et profitez d'un service haut de gamme parfaitement adaptée à vos besoins, respectueux de notre charte de qualité, pour une satisfaction totale et une tranquillité d'esprit inégalée.",
    imgSrc: "../../Assets/media/aventages-client.png",
  },
  section2: {
    title: "Boostez votre business en énergies renouvelables !",
    subtitle:
      "Ne manquez plus d'opportunités ! Rejoignez la révolution verte et développez votre activité !",
    paragraph:
      "Vous êtes un professionnel de l'installation et de la réparation des équipements d'énergies renouvelables ? Notre plateforme innovante vous offre une visibilité accrue auprès des particuliers en quête de techniciens compétents. Profitez de la simplicité d'utilisation, de la rapidité et de la fiabilité offertes par notre application pour développer votre activité et satisfaire encore plus de clients. Inscrivez-vous dès maintenant et faites partie de l'élite des professionnels engagés pour un avenir durable !",
    imgSrc: "../../Assets/media/aventages-technicien.png",
  },
  section3: {
    title: "Créer une nouvelle demande",
    subtitle: "",
    paragraph:
      "LeBonTechnicien.fr simplifie la réparation de vos équipements d'énergie renouvelable. Notre service en ligne vous permet de créer aisément des demandes d'intervention en quelques clics. Nous avons préalablement effectué les recherches pour identifier les meilleurs experts certifiés et expérimentés, vous évitant la recherche fastidieuse du technicien idéal. Notre plateforme met l'accent sur la simplicité et l'efficacité, vous offrant un accès rapide aux spécialistes de confiance, et vous permettant de planifier l’entretien de votre équipement d'énergie renouvelable en toute simplicité et expertise.",
    imgSrc: "../../Assets/media/aventages-technicien.png",
  },
  section4: {
    title: "Établir le contact facilement",
    subtitle: "",
    paragraph:
      "Grâce à la plateforme LeBonTechnicien.fr établir le contact avec des techniciens experts devient un jeu d'enfant. En soumettant votre demande, vous recevez rapidement les réponses de professionnels qualifiés, sans effectuer de recherches laborieuses. Notre plateforme innovante analyse votre requête, détermine les compétences requises et vous met en relation avec les techniciens appropriés. Conçue pour être conviviale et accessible, elle facilite la communication transparente entre clients et techniciens.",
    imgSrc: "../../Assets/media/aventages-technicien.png",
  },
  section5: {
    title: "Choisir l'expert idéal",
    subtitle: "",
    paragraph:
      "Lorsque vous recherchez un spécialiste pour vous accompagner dans la réparation de votre équipement d'énergie renouvelable, notre plateforme facilite la consultation des profils de chaque expert, vous permettant de choisir celui qui répondra le mieux à vos attentes. Les fiches individuelles de nos techniciens regorgent d'informations pertinentes, incluant leurs compétences, leur expérience et leurs domaines de prédilection. Pour vous aider davantage dans votre sélection, chaque profil présente également une évaluation moyenne basée sur les retours et commentaires de clients précédents. Ainsi, vous pouvez être assuré de faire le choix le plus éclairé pour votre projet.",
    imgSrc: "../../Assets/media/aventages-technicien.png",
  },
};

export const CommentCaMarche_data = {
  title: "Comment ça marche ?",
  subTitle:
    "Trouvez les meilleurs experts pour vos installations d'énergies renouvelables.",
  items: [
    {
      icon: "media/client-demande.png",
      heading: "Publiez votre demande d'intervention",
      description:
        "Décrivez brièvement votre besoin et soumettez votre demande d'intervention en quelques clics seulement.",
    },
    {
      icon: "media/tech-envoi.png",
      heading: "Les Techniciens vous répondent",
      description:
        "Recevez rapidement des réponses de techniciens expert, prêts à intervenir chez vous.",
    },
    {
      icon: "media/choix-tech.png",
      heading: "Choisissez votre Technicien idéal",
      description:
        "Consultez les avis des clients et sélectionnez le technicien qui répond parfaitement à vos exigences.",
    },
  ],
};

export const Nos_prestations = {
  title: "Nos prestations",
  subTitle: "Des solutions écologiques pour tous vos besoins",
  items: [
    {
      icon: "media/client-demande.png",
      heading: "Installation éco-responsable",
      description:
        "Adoptez l'énergie renouvelable avec nos installations expertes pour un futur durable et responsable.",
    },
    {
      icon: "media/tech-envoi.png",
      heading: "Réparation éco-fiable",
      description:
        "Réparations performantes et durables pour vos équipements d'énergie renouvelable, satisfaction garantie.",
    },
    {
      icon: "media/choix-tech.png",
      heading: "Entretien éco-pérenne",
      description:
        "Préservez l'efficacité de vos équipements renouvelables grâce à notre maintenance experte et personnalisée.",
    },
  ],
};

export const Nos_valeur = {
  title: "Nos valeurs",
  subTitle: "Des engagements pour un service de qualité",
  items: [
    {
      icon: "",
      heading: "Expertise reconnue",
      description: "Qualité, fiabilité, techniciens certifiés.",
    },
    {
      icon: "",
      heading: "Éco-responsabilité",
      description: "Préservation environnement, solutions durables.",
    },
    {
      icon: "",
      heading: "Service irréprochable",
      description: "Ecoute, réactivité, accompagnement personnalisé.",
    },
  ],
};

export const Equipements_data = {
  equipments: [
    {
      imageSrc: equipments,
      imageAlt: "Pompe à Chaleur Air-Air",
      title: "Pompe à Chaleur Air-Air",
      description: "Confort thermique et économies d'énergie",
    },
    {
      imageSrc: equipments,
      imageAlt: "Pompe à Chaleur Eau-Eau",
      title: "Pompe à Chaleur Eau-Eau",
      description: "Chauffage écologique et économies garanties",
    },
    {
      imageSrc: equipments,
      imageAlt: "Pompe à Chaleur Air-Eau",
      title: "Pompe à Chaleur Air-Eau",
      description: "Économies d'énergie et chauffage performant",
    },
    {
      imageSrc: equipments,
      imageAlt: "Ballon Thermodynamique",
      title: "Ballon Thermodynamique",
      description: "Production d'eau chaude sanitaire économique",
    },
    {
      imageSrc: Bois_equipments,
      imageAlt: "Poêle à Granulés",
      title: "Poêle à Granulés",
      description: "Chauffage confortable et respectueux de l'environnement",
    },
    {
      imageSrc: Bois_equipments,
      imageAlt: "Chaudière à Granulés",
      title: "Chaudière à Granulés",
      description: "Chauffage central écologique et économique",
    },
    {
      imageSrc: Solaire_equipments,
      imageAlt: "SSC",
      title: "Système Solaire Combiné (SSC)",
      description: "Chauffage et eau chaude sanitaire solaire",
    },
    {
      imageSrc: Solaire_equipments,
      imageAlt: "SST",
      title: "Système Solaire Thermique (SST)",
      description: "Production d'eau chaude sanitaire écologique",
    },
    {
      imageSrc: Solaire_equipments,
      imageAlt: "Panneaux Photovoltaïques",
      title: "Panneaux Photovoltaïques",
      description: "Production d'électricité verte et rentable",
    },
    {
      imageSrc: Solaire_equipments,
      imageAlt: "Capteurs Solaires Thermiques",
      title: "Capteurs Solaires Thermiques",
      description: "Chauffage et eau chaude sanitaire écologique",
    },
    {
      imageSrc: VMC_equipments,
      imageAlt: "VMC",
      title: "Ventilation Mécanique Contrôlée Performante (VMC)",
      description: "Confort, économies d'énergie et qualité d'air",
    },
    {
      imageSrc: IRVE_equipments,
      imageAlt: "IRVE",
      title: "Infrastructure de Recharge Électrique Fiable (IRVE)",
      description: "Rechargez rapidement et en toute sécurité",
    },
    {
      imageSrc: Gaze_equipments,
      imageAlt: "Gaze",
      title: "Chaudières à Gaz",
      description: "Performance énergétique et réduction des coûts",
    },
    {
      imageSrc: Fuel_equipments,
      imageAlt: "Chaudières à fioul",
      title: "Chaudières à fioul",
      description: "Haute performance et respect de l'environnement",
    },
  ],
};

export const Experts_data = {
  equipments: [
    {
      title: "Expertise en pompe à chaleur Air-Air : notre métier",
      description: "Installation, réparation et maintenance assurées",
    },
    {
      title: "Spécialiste pompe à chaleur Eau-Eau : votre confort assuré",
      description: "Experts en installation, réparation et maintenance",
    },
    {
      title: "Expertise en pompe à chaleur Air-Eau pour votre confort",
      description: "Installation, réparation et maintenance sur mesure",
    },
    {
      title: "Spécialiste des systèmes solaires combinés",
      description: "Profitez des avantages du chauffage solaire",
    },
    {
      title: "Expert en systèmes solaires thermiques",
      description: "Optez pour une eau chaude sanitaire écologique",
    },
    {
      title: "Technicien qualifié en panneaux photovoltaïques",
      description: "Expérience et savoir-faire pour une énergie verte",
    },
    {
      title: "Expert en capteurs solaires thermiques",
      description:
        "Des solutions durables pour le chauffage et l'eau chaude sanitaire",
    },
    {
      title: "Spécialiste des ventilations mécaniques contrôlées performantes",
      description: "Confort et qualité d'air au service de votre bien-être",
    },
    {
      title: "Professionnel des infrastructures de recharge électrique fiable",
      description:
        "Sécurité et rapidité pour la recharge de vos véhicules électriques",
    },
    {
      title: "Technicien compétent en chaudières à gaz et à fioul",
      description: "Performance énergétique et respect de l'environnement",
    },
  ],
};

export const charte_data = {
  "charte-qualite": [
    {
      title: "1. Sélection rigoureuse des professionnels",
      paragraph:
        "Afin d'assurer un service de haute qualité, nous procédons à une sélection rigoureuse des professionnels inscrits sur notre plateforme. Ils doivent justifier de leur compétence, leur expérience et leur conformité aux normes et réglementations en vigueur. De plus, ils s'engagent à suivre une formation continue pour maintenir et développer leurs compétences.",
    },
    {
      title: "2. Transparence et honnêteté",
      paragraph:
        "La transparence et l'honnêteté sont des valeurs essentielles pour LeBonTechnicien.fr. Les professionnels s'engagent à fournir des devis clairs, détaillés et justes, sans coûts cachés. Les utilisateurs peuvent ainsi comparer les offres et choisir en toute confiance le professionnel qui répondra au mieux à leurs besoins. ",
    },
    {
      title: "3. Communication et accompagnement",
      paragraph:
        "Nous nous engageons à assurer un accompagnement personnalisé et une communication fluide entre les particuliers et les professionnels. Les utilisateurs ont accès à un espace dédié pour échanger avec les professionnels et suivre l'avancement de leurs demandes. De plus, notre service client est à l'écoute pour répondre aux questions et résoudre les éventuels problèmes. ",
    },
    {
      title: "4. Respect des délais et des engagements",
      paragraph:
        "Les professionnels référencés sur LeBonTechnicien.fr s'engagent à respecter les délais et les engagements pris lors de l'acceptation d'un devis. Ils doivent fournir des prestations conformes aux attentes des clients et intervenir dans les délais impartis, en tenant compte des contraintes éventuelles liées à la météo ou aux spécificités du chantier. ",
    },
    {
      title: "5. Qualité des interventions et des équipements",
      paragraph:
        "Les professionnels s'engagent à réaliser des installations et des réparations conformes aux normes en vigueur et aux exigences de qualité de LeBonTechnicien.fr. Ils doivent utiliser des équipements performants, durables et respectueux de l'environnement, et veiller à la sécurité des installations. ",
    },
    {
      title: "6. Satisfaction client et suivi des prestations",
      paragraph:
        "Les professionnels doivent veiller à la satisfaction de leurs clients en assurant un suivi rigoureux des prestations réalisées. Les utilisateurs ont la possibilité d'évaluer les professionnels après chaque intervention, afin de garantir la qualité des prestations et d'identifier les domaines d'amélioration. ",
    },
    {
      title: "7. Service après-vente et garanties",
      paragraph:
        "Les professionnels s'engagent à fournir un service après-vente réactif et efficace, en répondant rapidement aux demandes des clients et en assurant les garanties légales et contractuelles. En cas de problème, ils doivent proposer des solutions adaptées pour résoudre les éventuels dysfonctionnements. ",
    },
    {
      title: "8. Engagement",
      paragraph:
        "Les professionnels référencés sur LeBonTechnicien.fr sont engagés dans une démarche environnementale et s'efforcent de minimiser l'impact écologique de leurs activités. Ils sont tenus de respecter les réglementations en matière de protection de l'environnement, de gestion des déchets et d'économie d'énergie.",
    },
    {
      title: "9. Protection des données personnelles",
      paragraph:
        "Nous accordons une grande importance à la protection des données personnelles de nos utilisateurs. Les informations recueillies sont traitées conformément à la législation en vigueur et notre politique de confidentialité. Les professionnels s'engagent également à respecter la confidentialité des informations échangées avec les clients.",
    },
    {
      title: "10. Amélioration continue",
      paragraph:
        "LeBonTechnicien.fr s'engage à maintenir un haut niveau de qualité et à rechercher constamment des améliorations pour offrir la meilleure expérience possible à ses utilisateurs. Nous restons à l'écoute des suggestions et des retours d'expérience de nos clients et partenaires, et mettons tout en œuvre pour nous adapter aux évolutions du marché et aux besoins des utilisateurs. En adhérant à cette charte de qualité, les professionnels s'engagent à respecter ces principes et à œuvrer pour la satisfaction de leurs clients et la réussite des projets d'énergie renouvelable. De notre côté, nous mettons tout en œuvre pour soutenir les professionnels dans leur démarche et garantir la qualité de notre plateforme LeBonTechnicien.fr.",
    },
  ],
};

export const partners = [
  {
    image:
      "https://cdn.discordapp.com/attachments/1074706785832816764/1108352794693468220/LG-electronics-france.jpg",
    website: "https://www.lg.com/fr",
    page: "/lg",
  },
  {
    image:
      "https://cdn.discordapp.com/attachments/1074706785832816764/1108352794949337120/de-dietrich.png",
    website: "https://www.dedietrich-thermique.fr/",
    page: "/dedietrich-thermique",
  },
];

export const Formation_Data = [
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "02/10/2023",
    Au: "06/10/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 route de Strasbourg",
    "Complément d'adresse": "Centre REICHSHOFFEN",
    "Code postal": "67110",
    Ville: "REICHSHOFFEN",
    "Repas du midi": "OUI",
    "Email contact": "cfi.reichshoffen@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "04/09/2023",
    Au: "08/09/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "Actimart II 1140 rue André Ampère",
    "Complément d'adresse": "Centre Aix-en-Provence",
    "Code postal": "13851",
    Ville: "AIX-EN-PROVENCE",
    "Repas du midi": "OUI",
    "Email contact": "cfr.aix@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "20/11/2023",
    Au: "24/11/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "Actimart II 1140 rue André Ampère",
    "Complément d'adresse": "Centre Aix-en-Provence",
    "Code postal": "13851",
    Ville: "AIX-EN-PROVENCE",
    "Repas du midi": "OUI",
    "Email contact": "cfr.aix@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "03/07/2023",
    Au: "07/07/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 rue de la Procession - Porte 4",
    "Complément d'adresse": "Centre SAINT-DENIS",
    "Code postal": "93200",
    Ville: "SAINT-DENIS",
    "Repas du midi": "OUI",
    "Email contact": "cfr.saint-denis@bdrthermea.fr",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "09/10/2023",
    Au: "13/10/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 rue de la Procession - Porte 4",
    "Complément d'adresse": "Centre SAINT-DENIS",
    "Code postal": "93200",
    Ville: "SAINT-DENIS",
    "Repas du midi": "OUI",
    "Email contact": "cfr.saint-denis@bdrthermea.fr",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "09/10/2023",
    Au: "13/10/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "Parc Industriel de la Vertonne 18 bis avenue de la Vertonne",
    "Complément d'adresse": "Centre VERTOU",
    "Code postal": "44120",
    Ville: "VERTOU",
    "Repas du midi": "OUI",
    "Email contact": "cfr.vertou@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "20/11/2023",
    Au: "24/11/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "Parc Industriel de la Vertonne 18 bis avenue de la Vertonne",
    "Complément d'adresse": "Centre VERTOU",
    "Code postal": "44120",
    Ville: "VERTOU",
    "Repas du midi": "OUI",
    "Email contact": "cfr.vertou@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "11/09/2023",
    Au: "15/09/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "ZAC UNEXPO Avenue de l’Épinette Rond-point n°4",
    "Complément d'adresse": "Centre SECLIN",
    "Code postal": "59113",
    Ville: "SECLIN",
    "Repas du midi": "OUI",
    "Email contact": "cfr.seclin@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPAC",
    "Nombre de jours": "5 jours",
    Du: "11/12/2023",
    Au: "15/12/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "ZAC UNEXPO Avenue de l’Épinette Rond-point n°4",
    "Complément d'adresse": "Centre SECLIN",
    "Code postal": "59113",
    Ville: "SECLIN",
    "Repas du midi": "OUI",
    "Email contact": "cfr.seclin@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiBOIS EAU",
    "Nombre de jours": "3 jours",
    Du: "07/11/2023",
    Au: "09/11/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 route de Strasbourg",
    "Complément d'adresse": "Centre REICHSHOFFEN",
    "Code postal": "67110",
    Ville: "REICHSHOFFEN",
    "Repas du midi": "OUI",
    "Email contact": "cfi.reichshoffen@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPV ELEC",
    "Nombre de jours": "3 jours",
    Du: "17/10/2023",
    Au: "19/10/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 route de Strasbourg",
    "Complément d'adresse": "Centre REICHSHOFFEN",
    "Code postal": "67110",
    Ville: "REICHSHOFFEN",
    "Repas du midi": "OUI",
    "Email contact": "cfi.reichshoffen@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiPV ELEC",
    "Nombre de jours": "3 jours",
    Du: "21/10/2023",
    Au: "23/10/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "Actimart II 1140 rue André Ampère",
    "Complément d'adresse": "Centre TOULOUSE",
    "Code postal": "31200",
    Ville: "TOULOUSE",
    "Repas du midi": "OUI",
    "Email contact": "cfr.toulouse@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiSOL CESI",
    "Nombre de jours": "3 jours",
    Du: "19/09/2023",
    Au: "21/09/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 route de Strasbourg",
    "Complément d'adresse": "Centre REICHSHOFFEN",
    "Code postal": "67110",
    Ville: "REICHSHOFFEN",
    "Repas du midi": "OUI",
    "Email contact": "cfi.reichshoffen@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiSOL CESI",
    "Nombre de jours": "3 jours",
    Du: "24/10/2023",
    Au: "26/10/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 route de Strasbourg",
    "Complément d'adresse": "Centre REICHSHOFFEN",
    "Code postal": "67110",
    Ville: "REICHSHOFFEN",
    "Repas du midi": "OUI",
    "Email contact": "cfi.reichshoffen@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiSOL CESI",
    "Nombre de jours": "3 jours",
    Du: "03/10/2023",
    Au: "05/10/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "18 rue Maurice Caunes ZA de Montblanc",
    "Complément d'adresse": "Centre TOULOUSE",
    "Code postal": "31200",
    Ville: "TOULOUSE",
    "Repas du midi": "OUI",
    "Email contact": "cfr.toulouse@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiSOL CESI",
    "Nombre de jours": "3 jours",
    Du: "12/09/2023",
    Au: "15/09/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "18 rue Maurice Caunes ZA de Montblanc",
    "Complément d'adresse": "Centre Aix-en-Provence",
    "Code postal": "13851",
    Ville: "AIX-EN-PROVENCE",
    "Repas du midi": "OUI",
    "Email contact": "cfr.aix@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiSOL",
    "Nombre de jours": "3 jours",
    Du: "28/11/2023",
    Au: "30/11/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "Actimart II 1140 rue André Ampère",
    "Complément d'adresse": "Centre Aix-en-Provence",
    "Code postal": "13851",
    Ville: "AIX-EN-PROVENCE",
    "Repas du midi": "OUI",
    "Email contact": "cfr.aix@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiSOL",
    "Nombre de jours": "3 jours",
    Du: "18/07/2023",
    Au: "20/07/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 route de Strasbourg",
    "Complément d'adresse": "Centre REICHSHOFFEN",
    "Code postal": "67110",
    Ville: "REICHSHOFFEN",
    "Repas du midi": "OUI",
    "Email contact": "cfi.reichshoffen@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiSOL",
    "Nombre de jours": "3 jours",
    Du: "17/10/2023",
    Au: "19/10/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "1 route de Strasbourg",
    "Complément d'adresse": "Centre REICHSHOFFEN",
    "Code postal": "67110",
    Ville: "REICHSHOFFEN",
    "Repas du midi": "OUI",
    "Email contact": "cfi.reichshoffen@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
  {
    Formation: "QualiSOL",
    "Nombre de jours": "3 jours",
    Du: "28/11/2023",
    Au: "30/11/2023",
    "Tarif HT": "",
    "Tarif TTC": "",
    Adresse: "18 rue Maurice Caunes ZA de Montblanc",
    "Complément d'adresse": "Centre TOULOUSE",
    "Code postal": "31200",
    Ville: "TOULOUSE",
    "Repas du midi": "OUI",
    "Email contact": "cfr.toulouse@dedietrichthermique.com",
    "Numéro téléphone contact": "09 70 84 51 39",
  },
];

export const Formation_Data_LG = [
  {
    Formation: "Pompes a chaleur Air/Eau Therma V",
    "Nombre de jours": "1 jour",
    Date: [
      "07/06/2023",
      "09/06/2023",
      "14/06/2023",
      "16/06/2023",
      "05/07/2023",
      "07/07/2023",
      "19/07/2023",
      "21/07/2023",
      "26/07/2023",
      "28/07/2023",
      "06/09/2023",
      "08/09/2023",
      "13/09/2023",
      "15/09/2023",
      "04/10/2023",
      "06/10/2023",
      "11/10/2023",
      "13/10/2023",
      "03/11/2023",
      "04/11/2023",
      "15/11/2023",
      "17/11/2023",
      "06/12/2023",
      "08/12/2023",
      "13/12/2023",
      "15/12/2023",
    ],
    Adresse: "Formation en présentiel & l'académie LG",
    contact: "https://bit.ly/3xgxrTA",
  },
  {
    Formation: "DRV systémes de pompe a chaleur et récupération de chaleur",
    "Nombre de jours": "2 jour",
    Date: [
      "31/05/2023",
      "23/06/2023",
      "30/06/2023",
      "22/09/2023",
      "29/09/2023",
      "20/10/2023",
      "27/10/2023",
      "24/11/2023",
      "01/12/2023",
    ],
    Adresse: "Formation en présentiel & l'académie LG",
    contact: "https://bit.ly/3xgxrTA",
  },
];
