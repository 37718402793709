import React from 'react'
import { Outlet } from 'react-router';

const ConfirmLayout = () => {
  return (
    <>
        <Outlet />
    </>
  )
}

export default ConfirmLayout