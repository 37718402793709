import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useStateContext } from "../../../contexts/ContextProvider";

const PieChartUser = () => {
    const [techs, setTechs] = useState([]);
    const [users, setUsers] = useState([]);
    const [superviseur, setSuperViseur] = useState([]);
    const { userType } = useStateContext();
    const apiUrl = 'https://lebontechnicien.net'
  
    // Fetching user data from API and setting the state
    useEffect(() => {
        const fetchData = async (type) => {
          try {
            const response = await fetch(`${apiUrl}/find`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ type }),
            });
            const users = await response.json();
            switch (type) {
              case "Tech":
                setTechs(users);
                break;
              case "Superviseur":
                setSuperViseur(users);
                break;
              case "User":
                setUsers(users);
                break;
              default:
                break;
            }
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData("Tech");
        fetchData("Superviseur");
        fetchData("User");
      }, []);
  
    // Counting the occurrences of different user types
    const intervention = users.filter(project => project.entreprise === "non").length;
    const Réclamation = users.filter(project => project.entreprise === "oui").length;
    const Contrat = techs.length;

    // Counting the occurrences of different project statuses
    const Accepted = users.filter(project => project.entreprise === "non").length;
    const claim = users.filter(project => project.entreprise === "oui").length;
    const closed = techs.length; 

    // Chart options for displaying user types
    const options = { 
      labels: ["Clients Particuliers", "Clients Pro", "Techniciens"],
      legend: {position: 'bottom'} 
    };
    const series = [Accepted, claim, closed];
  

    // Chart options for displaying user types
    const name = {
      labels: ["Clients Particuliers", "Clients Pro", "Techniciens"],
      legend: {position: 'bottom'} 
    }
    const values = [intervention , Réclamation, Contrat]
  
    return (
      <div className="donut ml-auto mr-auto w-[92%]">
        {userType === "Tech" ? (
        <Chart options={options} series={series} type="pie" width="380" />
        ) : 
        (
          <Chart options={name} series={values} type="pie" width="380"/>
        )}
      </div>
    );
  };

export default PieChartUser