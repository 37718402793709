import React from 'react'

const BreadCrumb = ({From, To, hrefFrom}) => {
  return (
    <nav class="bg-white flex mt-20 sm:mt-0  text-gray-700 py-3 px-5 rounded-lg dark:bg-gray-800 dark:border-gray-500 w-[92%] ml-auto mb-10 mr-auto md:mt-0" aria-label="Breadcrumb">
      <ol class="inline-flex items-center">
        {hrefFrom !== "" && (
          <li class="inline-flex items-center">
            <a href={hrefFrom} class="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white">
                <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                {From}
            </a>
          </li>
          )}
          <li>
          <div class="flex items-center">
              <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
              <a href="#" class="text-gray-700 hover:text-gray-900 ml-1 md:ml-2 text-sm font-medium dark:text-gray-400 dark:hover:text-white">{To}</a>
          </div>
          </li>
      </ol>
    </nav>
  )
}

export default BreadCrumb