import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../../contexts/ContextProvider';

const TechnicianProjects = () => {
  const [rating, setRating] = useState(0);
  //const [userType, setUserType] = useState('Technician')
  const [showDemandeModal, setShowDemande] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [showUserDemandeModal, setShowUserDemande] = useState(false);
  const [fetchUserName, setFetchUserName] = useState("");
  const [fetchUserLastName, setFetchUserLastName] = useState("");
  const [fetchComment, setFetchComment] = useState("");
  const [fetchRatings, setFetchRatings] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [error, setError] = useState("");
  //const [userType, setUserType] = useState('Technician')
  const { userType, nom, prenom } = useStateContext();
  const [textError, setTextError] = useState("");
  const [textinput, setTextInput] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At augue eget arcu dictum varius duis. Lacus vestibulum sed arcu non odio euismod. Sodales neque sodales ut etiam sit amet nisl purus in. Blandit massa enim nec dui. Sed velit dignissim sodales ut eu sem integer vitae justo. Amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar. Odio ut sem nulla pharetra diam sit amet nisl suscipit. Urna condimentum mattis pellentesque id nibh. Nam aliquam sem et tortor consequat id porta nibh. Ante in nibh mauris cursus mattis molestie a iaculis at. At tellus at urna condimentum mattis."
  );
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [history, setHistory] = useState([]);
  const [techs, setTechs] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const apiUrl = 'https://lebontechnicien.net'

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(
          data.filter(
            (item) =>
              userType === "Tech" && item.status === "Pending" &&
              (!item.denied_by || !item.denied_by.includes(sessionStorage.getItem("id"))) &&
              (!item.accepted_by.includes(sessionStorage.getItem("id"))) &&
              (item.accepted_by.length < 3)
          )
        );
      })
      .catch((error) => console.error(error));
  }, []);


  return (
    <div>{history}</div>
  )
}

export default TechnicianProjects