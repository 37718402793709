import React, { useEffect, useState } from "react";
import { BreadCrumb, CRUDTable, Tabs } from "../../../Components";
import { HistoryUser } from "../../../Constants/dummy";
import {
  IoCheckmarkOutline,
  IoEyeOutline,
  IoPencil,
  IoTrashOutline,
} from "react-icons/io5";

const AdminMailing = () => {
  const [data, setData] = useState(HistoryUser);
  const [inputValue, setInputValue] = useState("");
  const [users, setUsers] = useState([]);
  const [techs, setTechs] = useState([]);
  const [superviseur, setSuperViseur] = useState([]);
  const [activeTab, setActiveTab] = useState("Liste des Utilisateurs");
  const [activeTabData, setActiveTabData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [testId, setTestId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [techAcceptResponse, setTechAcceptResponse] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [infor, setInfor] = useState([]);
  const [checkId, setCheckid] = useState('')
  const apiUrl = 'https://lebontechnicien.net'


  const handleDataChange = (newData) => {
    setData(newData);
  };

  const filteredTechs = techs.filter((item) => item.actualités === "oui");
  const filteredUsers = users.filter((item) => item.actualités === "oui");

  const info = filteredTechs.map((item) => ({
    
    id: item._id,
    nom: item.nom,
    prenom: item.prenom,
    email: item.email,
    Département: item.departement,
    status: item.status,
    Technician_id: item._id,
    Actualité: item.actualités,
    selected: selectedEmails.includes(item._id),
  }));

  const infoUser = users
    .filter((item) => item.entreprise === "non" && item.actualités === "oui")
    .map((item) => ({
      id: item._id,
      nom: item.nom,
      prenom: item.prenom,
      email: item.email,
      Département: item.zone,
      Actualité: item.actualités,
      //selected: selectedEmails.includes(item._id),
    }));



  const infoUserPro = users
    .filter((item) => item.entreprise === "oui" && item.actualités === "oui")
    .map((item) => ({
      id: item._id,
      nom: item.nom,
      prenom: item.prenom,
      email: item.email,
      Département: item.zone,
      Actualité: item.actualités,
      //selected: selectedRows.includes(item._id),
    }));

  const infoSuperViseur = superviseur.map((item) => ({
    id: item._id,
    nom: item.nom,
    prenom: item.prenom,
    email: item.email,
    Département: item.zone,
  }));

  /*const handleSelect = (id, email) => {
    const infoCombined = [...info, ...infoUser];
    console.log('infoCombined', infoCombined)
    const selected =
      !infoCombined.find((item) => item.id === id)?.selected || false;
      console.log('selected', infoCombined.find((item) => item.id === id)?.selected )

    const newInfo = infoCombined.map((item) => {
      if (item.id === id) {
        return { ...item, selected };
      }
      return item;
    });

    setSelectedEmails((prevSelectedEmails) => {
      if (selected) {
        return [...prevSelectedEmails, email];
      } else {
        return prevSelectedEmails.filter((item) => item !== email);
      }
    });
    setInfor(newInfo);
  };

  useEffect(() => {
    console.log("selectedEmails", selectedEmails);
  }, [selectedEmails]);*/

  const handleSelect = (id, email) => {
    setCheckid(id)

    const index = selectedEmails.findIndex((item) => item.id === id);
    if (index === -1) {
      setSelectedEmails([...selectedEmails, { id, email }]);
    } else {
      const newEmails = [...selectedEmails];
      newEmails.splice(index, 1);
      setSelectedEmails(newEmails);
    }
  };



  const handleRowSelection = (selectedRowIds) => {
    const selectedIds = Object.keys(selectedRowIds).filter(
      (id) => selectedRowIds[id]
    );
    setSelectedRows(selectedIds);
  };

  // Handles Deelete
  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setTestId(id);
    //setShowModal(true);
  };

  useEffect(() => {
    console.log("deleteselected", selectedId);
    console.log("deleteselectedTestId", testId);
  }, [selectedId, testId]);

  const handleConfirmDelete = () => {
    handleRemoveUser(selectedId);
    //handleDeleteProduct(selectedId)
    setShowModal(false);
    setSelectedId(null);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  const fetchData = async (type) => {
    try {
      const response = await fetch(`${apiUrl}/find`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type }),
      });
      const users = await response.json();
      switch (type) {
        case "Tech":
          setTechs(users);
          break;
        case "Superviseur":
          setSuperViseur(users);
          break;
        case "User":
          setUsers(users);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmTech = async (id) => {
    try {
      const res = await fetch(`${apiUrl}/admin/confirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id }),
      });

      if (!res.ok) {
        const response = await res.json();
        throw new Error(response.msg);
      }

      await res.json();
      window.location.reload(); // reload the page
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData("Tech");
  }, [inputValue]);

  useEffect(() => {
    fetchData("Superviseur");
  }, [inputValue]);

  useEffect(() => {
    fetchData("User");
  }, [inputValue]);

  useEffect(() => {
    switch (activeTab) {
      case "Liste des Utilisateurs":
        setActiveTabData(infoUser);
        break;
      case "Liste des Techniciens":
        setActiveTabData(info);
        break;
      case "Liste Des Superviseurs":
        setActiveTabData(infoSuperViseur);
        break;
      default:
        setActiveTabData([]);
        break;
    }
  }, [activeTab, users, techs, superviseur]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleRemoveUser = async (id) => {
    const formData = {
      id: id,
    };

    const response = await fetch(`${apiUrl}/remove`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();
    console.log(data);
    window.location.reload();
  };

  const handleAccept = async (infoId, techid) => {
    const selectedRequest = info.filter(
      (i) => i.id === infoId && i.Technician_id === techid
    )[0];
    const email = sessionStorage.getItem("email");
    const response = await fetch(
      `${apiUrl}/request/user_accept`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email,
          rid: infoId,
          tid: techid,
        }),
      }
    );
    const data = await response.json();
    setTechAcceptResponse(data);
    //window.location.reload();
  };

  return (
    <>
      <BreadCrumb From={""} To={"Utilisateurs"} hrefFrom={"#"}/>
      <Tabs
        tabs={[
          "Liste des Clients Particuliers",
          "Liste des Clients Pro",
          "Liste des Techniciens",
        ]}
        tabsContent={[
          <div className="mt-10 w-[92%] ml-auto mr-auto">
            <CRUDTable
              data={infoUser}
              link={`/user-details/`}
              eyeIcon={
                <IoEyeOutline
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "#f8c408",
                  }}
                />
              }
              selectButton={(id, email) => (
                <button
                  onClick={() => handleSelect(id, email)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  {infoUserPro.find((item) => item.id === id)?.selected || false
                    ? "Unselect"
                    : "Select"}
                </button>
              )}
              onDataChange={handleDataChange}
              onRowSelection={handleRowSelection}
            />
          </div>,
          <div className="mt-10 w-[92%] ml-auto mr-auto">
            <CRUDTable
              data={infoUserPro}
              link={`/user-details/`}
              eyeIcon={
                <IoEyeOutline
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "#f8c408",
                  }}
                />
              }
              selectButton={({ id, email }) => (
                <button
                  onClick={() => handleSelect(id, email)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  {infoUserPro.find((item) => item.id === id)?.selected || false
                    ? "Unselect"
                    : "Select"}
                </button>
              )}
              onDataChange={handleDataChange}
              onRowSelection={handleRowSelection}
            />
          </div>,
          <div className="mt-10 w-[92%] ml-auto mr-auto">
            <CRUDTable
              data={info}
              link={`/user-details/`}
              eyeIcon={
                <IoEyeOutline
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "#f8c408",
                  }}
                />
              }
              onDataChange={handleDataChange}
              onRowSelection={handleRowSelection}
              selectButton={({ id, email }) => (
                <button
                  onClick={() => handleSelect(id, email)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  {info.find((item) => item.id === id)?.selected || false
                    ? "Unselect"
                    : "Select"}
                </button>
              )}
              deleteIcon={(id) => (
                <IoTrashOutline
                  style={{ fontSize: "18px", color: "red", cursor: "pointer" }}
                  onClick={() => handleDeleteClick(id)}
                />
              )}
            />
          </div>,
        ]}
      />
      {showModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 sm:flex">
                  <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-red-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800">
                      Êtes-vous sûr de vouloir supprimer?
                    </h4>
                    <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                      En cliquant sur "Supprimer", vous supprimez définitivement
                      cet appareil. Cette action est irréversible. Veuillez vous
                      assurer que vous voulez bien continuer.
                    </p>
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                        onClick={() => handleConfirmDelete()}
                      >
                        Supprimer
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                        onClick={() => handleCancelDelete()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AdminMailing;
