import React from 'react';

const GuidesButtons = ({ viewPdfLink, downloadPdfLink }) => {
  return (
    <div className="custom-button-container" style={{ justifyContent: 'flex-start !important' }}>
      {/*<a href={viewPdfLink} target="_blank" rel="noopener noreferrer">
        <button className="custom-button">Consulter le guide</button>
  </a>*/}
      <a href={downloadPdfLink} download>
        <button className="custom-button">Télécharger le guide</button>
      </a>
    </div>
  );
}

export default GuidesButtons;
