import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const ReturnButton = ({ From, To, hrefFrom }) => {
  const navigate = useNavigate();

  const goToPreviousPage = () => {
    navigate(-1); 
  };

  return (
    <nav
      className="bg-white flex mt-20 sm:mt-0 text-gray-700 py-3 px-5 rounded-lg dark:bg-gray-800 dark:border-gray-500 w-[92%] ml-auto mb-10 mr-auto md:mt-0"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center">
        <li className="inline-flex items-center">
          <button
            onClick={goToPreviousPage}
            className="text-sm text-gray-700 hover:text-gray-900 inline-flex items-center dark:text-gray-400 dark:hover:text-white"
          >
            <FaArrowLeft className='pt-[2px] mr-[20px]'/>
            Retour à la page précédente
          </button>
        </li>
      </ol>
    </nav>
  );
};

export default ReturnButton;
