import React from "react";
import {
  Banner,
  CenterComponent,
  Footer,
  LeBonTechnicien2grid,
  LeBonTechnicien3grid,
  LeBonTechnicienWorks,
  EquipmentSection,
  Form,
} from "../../Components";

import {
  LebonTechnicien_data,
  CommentCaMarche_data,
  Nos_prestations,
  Nos_valeur,
  Equipements_data,
  Experts_data,
} from "../../Constants/dummy";
import { Helmet } from "react-helmet";

import fonctionnement from "../../Assets/media/contact.png";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact - LeBonTechnicien.fr </title>
        <meta name="keywords" content="Contact - LeBonTechnicien.fr " />
        <meta
          name="description"
          content="Accroche : Besoin d'aide pour utiliser notre plateforme, ou avez-vous un problème à nous signaler ? 
          L'équipe de LEBONTECHNICIEN.FR est à votre écoute pour répondre à toutes vos interrogations."
        />
        <link rel="canonical" href="http://lebontechnicien.fr/contact" />
      </Helmet>
      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">
                  Contactez-nous !
                </h1>
                <p className="leBonTech-p">
                  Besoin d'aide pour utiliser notre plateforme, ou avez-vous un
                  problème à nous signaler ? <br /> L'équipe de
                  LeBonTechnicien.fr est à votre écoute pour répondre à toutes
                  vos interrogations.
                </p>
                <Form />
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img src={fonctionnement} alt="Comment ça marche" />
              </div>
            </div>
          </div>
        </div>
      </Banner>
    </>
  );
};

export default Contact;
