import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import LogoBig from "../../Assets/Logo.svg";
import { MdOutlineCancel } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Adminlinks, links, Superviseurlinks, Technicianlinks, clientProlinks } from "../../Constants/dummy";
import { useStateContext } from "../../contexts/ContextProvider";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";

const DashSideBar = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { activeMenu, setActiveMenu, screenSize, userType, entreprise } = useStateContext();
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [expandedTitle, setExpandedTitle] = useState(null);
  const [userData, setUserData] = useState("");
  const [error, setError] = useState(null);
  const apiUrl = 'https://lebontechnicien.net'

  const handleExpand = (title) => {
    setExpandedTitle(expandedTitle === title ? null : title);
  };

  const handleCloseSideBar = () => {
    if (screenSize <= 900 && activeMenu) {
      setActiveMenu(false);
    }
  };

  //Toggles logout
  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${apiUrl}/logout`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      if (!res.ok) {
        throw new Error(data);
      }
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("requests");
      sessionStorage.clear();
      setIsLoading(false);
      console.log(data.msg);
      setIsLoggedOut(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (isLoggedOut) {
      history.push("/connexion");
    }
  }, [isLoggedOut, history]);

  const activeLink =
    " active-link flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";
  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-4 ml-3 mt-4 tracking-tight"
            >
              <img
                className="mx-auto h-8 w-auto mt-[4px]"
                src={LogoBig}
                alt="Logo"
              />
            </Link>
            <div>
              <p id="my-element1" data-tooltip-content="Menu">
                <button
                  className="text-sm rounded-full p-2 hover:bg-light-gray mt-4 block ml-8"
                  type="button"
                  onClick={() => setActiveMenu((prevActiveMenu) => !activeMenu)}
                >
                  <AiOutlineClose />
                </button>
              </p>
              <ReactTooltip anchorId="my-element1" />
            </div>
          </div>

          <div className="mt-10">
            {userType === "User" && entreprise === "non"? (
              <>
                {links
                  .filter((item) => item.title !== "Others")
                  .map((item) => (
                    <div>
                      <>
                        {item.links.map((link) => (
                          <NavLink
                            to={`/${link.link}`}
                            key={link.name}
                            onClick={handleCloseSideBar}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            {link.icon}
                            <span className="capitalize">{link.name}</span>
                          </NavLink>
                        ))}
                      </>
                    </div>
                  ))}
              </>
            ) : userType === "Tech" ? (
              <>
                {Technicianlinks.filter((item) => item.title !== "Others").map(
                  (item) => (
                    <div>
                      <>
                        {item.links.map((link) => (
                          <NavLink
                            to={`/${link.link}`}
                            key={link.name}
                            onClick={handleCloseSideBar}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            {link.icon}
                            <span className="capitalize">{link.name}</span>
                          </NavLink>
                        ))}
                      </>
                    </div>
                  )
                )}
              </>
            ) : userType === "Admin" ? (
              <>
                {Adminlinks.filter((item) => item.title !== "Others").map(
                  (item) => (
                    <div>
                      <>
                        {item.links.map((link) => (
                          <NavLink
                            to={`/${link.link}`}
                            key={link.name}
                            onClick={handleCloseSideBar}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            {link.icon}
                            <span className="capitalize">{link.name}</span>
                          </NavLink>
                        ))}
                      </>
                    </div>
                  )
                )}
              </>
            ) : userType === "Superviseur" ? (
              <>
                {Superviseurlinks.filter((item) => item.title !== "Others").map(
                  (item) => (
                    <div>
                      <>
                        {item.links.map((link) => (
                          <NavLink
                            to={`/${link.link}`}
                            key={link.name}
                            onClick={handleCloseSideBar}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            {link.icon}
                            <span className="capitalize">{link.name}</span>
                          </NavLink>
                        ))}
                      </>
                    </div>
                  )
                )}
              </>
            ) : userType === "User" && entreprise === "oui" ? (
              <>
                {clientProlinks.filter((item) => item.title !== "Others").map(
                  (item) => (
                    <div>
                      <>
                        {item.links.map((link) => (
                          <NavLink
                            to={`/${link.link}`}
                            key={link.name}
                            onClick={handleCloseSideBar}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            {link.icon}
                            <span className="capitalize">{link.name}</span>
                          </NavLink>
                        ))}
                      </>
                    </div>
                  )
                          )}
              </>
            )           
            : null}

            <div className="absolute bottom-10 left-0 right-0">
              {links
                .filter((item) => item.title === "Others")
                .map((item) => (
                  <div key={item.title}>
                    {/*<p className='text-gray-400 m-3 mt-4 uppercase'>{item.title}</p>*/}
                    {item.links.map((link) => (
                      <NavLink
                        to={`/${link.link}`}
                        key={link.name}
                        onClick={handleLogout}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        {link.icon}
                        <span className="capitalize">{link.name}</span>
                      </NavLink>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DashSideBar;
