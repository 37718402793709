import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BreadCrumb, Calendar, ReturnButton } from "../../../Components";

const TechProPlanningDetail = () => {
  const [plannedTasks, setPlannedTasks] = useState([]);
  const [phrase, setPhrase] = useState([]);
  const { id, team } = useParams();
  const [map, setMap] = useState(null);
  const [calendars, setCalendars] = useState([]);


  //e5484222-7585-44bd-87f8-3f57a09d1e45

  const apiUrl = 'https://lebontechnicien.net'

      console.log("teamIdTechProPlanning", team);
      console.log("TemIdPlanningid", id);
  // Fetches Planned Requests
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, { method: "GET" })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const fetchedPlanned = data.filter(
          (task) =>
            task.temp_status === "Planned" &&
            task.TeamID === team && task.planningID === id
        );

        // Convert date strings to Date objects
        fetchedPlanned.forEach((task) => {
          task.date_intervention = new Date(task.date_intervention);
        });

        // Sort by day and then by time
        fetchedPlanned.sort((a, b) => {
          if (
            a.date_intervention.toDateString() ===
            b.date_intervention.toDateString()
          ) {
            // If the days are the same, compare by time
            return a.date_intervention - b.date_intervention;
          }
          return a.date_intervention - b.date_intervention;
        });

        setPlannedTasks(fetchedPlanned);
        console.log("Planned tasks", fetchedPlanned);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  }, []);


  /*const getPoints = async () => {
    try {
      // Make a GET request using the fetch API
      const response = await fetch(`${apiUrl}calenders/get_calender`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id: id }),
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // If the response is successful, you can handle the result here
        const result = await response.json();
        const fetchedPlanned = result.filter(
          (task) =>
            task._id === team
        );
        setPlannedTasks(fetchedPlanned);
        console.log("Calendars:", calendars);
      } else {
        // Handle other response statuses or errors here
        console.error("Error:", response.status);
      }
    } catch (error) {
      // Handle any network or fetch errors
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    getPoints()
  }, [])*/

  useEffect(() => {
    const mapOptions = {
      center: { lat: 46.603354, lng: 1.888334 },
      zoom: 6,
    };

    if (document.getElementById("map2")) {
      const mapInstance = new window.google.maps.Map(
        document.getElementById("map2"),
        mapOptions
      );
      setMap(mapInstance);

      const markerPositions = [];
      const markerCards = [];
      const waypointDistanceElement2 =
        document.getElementById("waypointDistance2");

      const geocoder = new window.google.maps.Geocoder();
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(mapInstance);

      plannedTasks.forEach((task, index) => {
        const address = task.adresse;

        geocoder.geocode({ address: address }, (results, status) => {
          if (status === "OK" && results[0].geometry) {
            const location = results[0].geometry.location;
            console.log("location", location); // Extracting location

            // Using String.fromCharCode to convert index to corresponding character
            // Using charCodeAt to get the numeric value
            const label = (index + 1).toString();
            const marker = new window.google.maps.Marker({
              position: location,
              map: mapInstance,
              label: {
                text: label,
                color: "white", // Set label color to white
                fontWeight: "bold", // Set label to be bold
              },
            });
            markerPositions.push(location);

            const card = `<div>
                  ${
                    task.nom
                      ? `<strong>Nom:</strong> ${task.nom} ${task.prenom}`
                      : ""
                  } 
                  ${
                    task.telephone
                      ? `<br><strong>Téléphone:</strong> ${task.telephone}`
                      : ""
                  } <br>
                  <strong>Prestation:</strong> ${task.typeDePrestation} ${
              task.typeDeProjet
            } ${task.marque}
                  <br>
                  <strong>Date Intervention</strong>: ${task.date_intervention.toDateString()}<br>
                  <strong>Adresse:</strong> ${address}<br>
                  </div>`;
            markerCards.push(card);

            const infoWindow = new window.google.maps.InfoWindow();
            marker.addListener("click", () => {
              infoWindow.setContent(card);
              infoWindow.open(mapInstance, marker);
            });

            if (markerPositions.length === plannedTasks.length) {
              // All markers are placed, proceed with directions
              const origin = markerPositions[0];
              console.log("origin: " + origin);
              const waypoints = markerPositions.slice(1);
              console.log("waypoints: " + waypoints);

              const request = {
                origin,
                destination: waypoints[waypoints.length - 1],
                waypoints: waypoints.map((waypoint) => ({
                  location: waypoint,
                  stopover: true,
                })),
                travelMode: "DRIVING",
              };

              directionsService.route(request, (result, status) => {
                if (status === "OK") {
                  const route = result.routes[0];
                  const legs = route.legs;

                  const newPhrases = legs.map((leg, i) => {
                    const distance = leg.distance.text;
                    const duration = leg.duration.text;
                    const phraseIndex = i + 1;

                    return `<strong style="color: red">Trajet ${phraseIndex}</strong>: Entre client N°<strong>${phraseIndex}</strong> et client N°<strong>${
                      phraseIndex + 1
                    }</br></strong> Distance : <strong>${distance}</strong> </br> Temps du trajet : <strong>${duration}</strong> </br> Client : <strong>${
                      plannedTasks[i].nom
                    }</strong>`;
                  });

                  // Use Set to remove duplicates
                  setPhrase((prevPhrases) =>
                    Array.from(new Set([...prevPhrases, ...newPhrases]))
                  );
                  console.log(
                    `Trajet </strong>: Entre client N°<strong>${address}</strong> et client N°<strong>`
                  );

                  if (waypointDistanceElement2) {
                    waypointDistanceElement2.innerHTML = phrase
                      .map(
                        (phrase, index) =>
                          `<div key=${index}><strong style="color: red">Trajet ${
                            index + 1
                          }</strong>: ${phrase}</div>`
                      )
                      .join("");
                  }
                }
              });
            }
          }
        });
      });
    }
  }, [plannedTasks]);

  const getCalendars = async () => {
    try {
      // Make a GET request using the fetch API
      const response = await fetch(`${apiUrl}/calenders/get_calender`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id: id }),
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // If the response is successful, you can handle the result here
        const result = await response.json();
        setCalendars(result);
        console.log("Calendars:", calendars);
      } else {
        // Handle other response statuses or errors here
        console.error("Error:", response.status);
      }
    } catch (error) {
      // Handle any network or fetch errors
      console.error("Fetch error:", error);
    }
  };

  // Call the function to send the GET request
  useEffect(() => {
    getCalendars();
  }, []);

  const data = calendars;
  const plannedTasksArray = [];

  for (const key in data) {
    if (key.startsWith("plannedTasks[")) {
      const index = key.match(/\d+/)[0]; // Extract the index from the key
      const field = key.replace(`[${index}]`, ""); // Remove the index from the key
      const value = data[key];

      if (!plannedTasksArray[index]) {
        plannedTasksArray[index] = {};
      }

      plannedTasksArray[index][field] = value;
    }
  }

  const exportPlanning = async () => {
    try {
      const response = await fetch(`${apiUrl}/calenders/export_planing`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id: id }),
      });
  
      const result = await response.json();
  
      // Open the exported planning in a new window
      window.open(`${apiUrl}/${result}`, "_blank");
    } catch (error) {
      console.error("Error while exporting", error);
    }
  };

  console.log(plannedTasksArray);

  return (
    <>
      <ReturnButton/>
      <div className="w-full m-auto rounded-md mb-4 p-4">
        <>
          <div className="flex justify-start flex-wrap mb-4 p-4">

              <div className="w-full m-auto rounded-md mb-4 p-4">
                <div className="flex justify-between flex-wrap mb-4 p-4 ">
                  <h2 className="text-4xl font-bold text-black">
                    Parcour de l'équipe
                  </h2>
                </div>
                <div className="lg:flex lg:justify-start lg:flex-wrap">
                  {plannedTasksArray?.map((task, index) => (
                    <>
                      <div
                        key={index}
                        className="m-4  bg-white rounded-md lg:w-[30%] sm:w-full text-start border-black border "
                      >
                        <div className="bg-gray-100 w-full mb-4 p-2 ">
                          <p className="text-center text-sm text-black font-[400]">
                            Date d’intervention :
                          </p>
                          <p className="text-center text-sm text-black font-bold">
                            {" "}
                            {new Date(
                              task["plannedTasks[date_intervention]"]
                            ).toLocaleDateString()}{" "}
                          </p>
                          <p className="text-center text-sm text-black font-[400]">
                            Heure d’arrivé : Entre{" "}
                            {new Date(
                              task["plannedTasks[date_intervention]"]
                            ).toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}{" "}
                            et{" "}
                            {new Date(
                              new Date(
                                task["plannedTasks[date_intervention]"]
                              ).getTime() +
                                2 * 60 * 60 * 1000
                            ).toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </p>
                        </div>
                        <div className="h-[250px] p-4">
                          <span className="font-bold text-black">
                            {" "}
                            Client Pro :{" "}
                          </span>{" "}
                          {task["plannedTasks[raisonSocialClient]"]}
                          <br />
                          <span className="font-bold text-black">
                            {" "}
                            Nom du client {index + 1}:{" "}
                          </span>{" "}
                          {task["plannedTasks[nom]"]}{" "}
                          {task["plannedTasks[prenom]"]}
                          <br />
                          <span className="font-bold text-black">
                            {" "}
                            Téléphone:{" "}
                          </span>
                          {task["plannedTasks[telephone]"]}, <br />
                          <span className="font-bold text-black">
                            {" "}
                            Prestation:{" "}
                          </span>
                          {task["plannedTasks[typeDePrestation]"]}
                          {task["plannedTasks[typeDeProjet]"]}{" "}
                          {task["plannedTasks[marque]"]}
                          <br />
                          {task["plannedTasks[puissance]"] &&
                            task["plannedTasks[puissance]"] !== "0" &&
                            task["plannedTasks[puissance]"] !== "000" && (
                              <>
                                <span className="font-bold text-black">
                                  {" "}
                                  Puissance:{" "}
                                </span>
                                {task["plannedTasks[puissance]"]} <br />
                              </>
                            )}
                          <span className="font-bold text-black">
                            {" "}
                            Adresse:{" "}
                          </span>
                          {task["plannedTasks[adresse]"]}, <br />
                        </div>
                        {/*<div className="gap-4 flex mt-2 h-[20%]">
                        <button
                          className="bg-[#3E9F36] text-white p-2 rounded-md"
                          onClick={() =>
                            handleClickUpdateStatusDate(task._id)
                          }
                        >
                          Valider l'intervention
                        </button>
                        <button
                          className="bg-[#3E9F36] text-white p-2 rounded-md"
                          onClick={() =>
                            openModificationModal(task._id)
                          }
                        >
                          Modifier l'intervention
                        </button>
                        </div>*/}
                      </div>
                    </>
                  ))}
                </div>

                <div className="w-[98%] m-auto rounded-md mb-4">
                  <div className="w-[98%] m-auto rounded-md mb-4">
                    <div
                      className="mt-4"
                      id="map2"
                      style={{ width: "100%", height: "400px" }}
                    ></div>
                  </div>
                </div>


                  <div className="justify-end gap-2 flex mt-2 h-[20%] p-4">
                    <button
                      className="bg-[#3E9F36] text-white p-2 rounded-md text-md"
                      onClick={exportPlanning}
                    >
                      Exporter le planning
                    </button>
                  </div>

              </div>


            {phrase && (
              <>
                {/*<h2 className="text-lg font-bold">Trajets:</h2>*/}
                <div className="flex flex-wrap justify-start w-[100%]">
                  {phrase.slice(0, -1).map((phrase, index) => (
                    <div
                      className="p-4  m-4 rounded-md w-full border-black border w-[30%]"
                      key={index}
                    >
                      <span dangerouslySetInnerHTML={{ __html: phrase }} />
                    </div>
                  ))}
                </div>
              </>
            )}

          </div>
        </>
      </div>
    </>
  );
};

export default TechProPlanningDetail;
