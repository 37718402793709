import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Cards from "../../../Components/Dashboard/Cards";
import HistoryCards from "../../../Components/Dashboard/HistoryCards";

const UserProRequestPerProduct = () => {
  const [groupedHistory, setGroupedHistory] = useState([]);
  const [userNom, setUserNom] = useState("");
  const [userPrenom, setUserPrenom] = useState("");

  const { id } = useParams();
  const apiUrl = "https://lebontechnicien.net";

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const foundIntervention = data.find(
          (intervention) => intervention._id === id
        );
        setUserNom(foundIntervention?.nom || "");
        setUserPrenom(foundIntervention?.prenom || "");
        fetchHistory(
          foundIntervention?.nom,
          foundIntervention?.prenom,
          foundIntervention?.typeDeProjet,
          foundIntervention?.marque,
          foundIntervention?.puissance
        );
      })
      .catch((error) => console.error(error));
  }, [id]);

  const fetchHistory = (nom, prenom, typeDeProjet, marque, puissance) => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const fetchedInterventions = data.filter(
          (intervention) =>
            intervention.nom === nom &&
            intervention.prenom === prenom &&
            intervention.typeDeProjet === typeDeProjet &&
            intervention.marque === marque &&
            intervention.puissance === puissance
        );
        setGroupedHistory(fetchedInterventions);
        console.log("fetchedInterventions101010", fetchedInterventions);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className=" mt-10 sm:mt-0 max-w-screen-xl ml-auto mr-auto">
      <div className="flex justify-between w-full mb-10">
        <h3 className=" mt-10 text-lg font-medium leading-6 text-gray-900 title-font-h3">
          Historique des interventions
        </h3>
      </div>
      <HistoryCards history={groupedHistory} />
    </div>
  );
};

export default UserProRequestPerProduct;
