import React from "react";
import Banner from "./Banner";
import fonctionnement from "../../Assets/media/charte-qualite.png";
import { charte_data } from "../../Constants/dummy";

const CharteQualite = () => {
  return (
    <>
      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">
                  Charte de qualité LeBonTechnicien.fr : Excellence et confiance
                </h1>
                <p className="leBonTech-p">
                  L'application LeBonTechnicien.fr a pour ambition de fournir un
                  service de qualité supérieure à ses utilisateurs, en mettant
                  en relation les particuliers et les professionnels experts en
                  installations et réparations d'équipements d'énergie
                  renouvelable. Pour atteindre cet objectif, nous avons élaboré
                  une charte de qualité qui repose sur des engagements précis et
                  ambitieux, afin de garantir une expérience utilisateur
                  exceptionnelle et la satisfaction de nos clients.
                </p>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img
                  src={fonctionnement}
                  alt="Charte de qualité - LeBonTechnicien.fr : Excellence et confiance"
                />
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <main style={{maxWidth: '1280px',

}}>
        {charte_data["charte-qualite"].map((item, index) => (
          <section key={index} className="charte-qualite">
            <h2 className="leBonTech-h2">{item.title}</h2>
            <p className="leBonTech-p">{item.paragraph}</p>
          </section>
        ))}
      </main>
    </>
  );
};

export default CharteQualite;
