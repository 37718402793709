import React, { useEffect, useState } from "react";
import {
  BreadCrumb,
  CRUDTable,
  Detail,
  Modal,
  PdfViewer,
  Rating,
} from "../../../Components";
import { useParams } from "react-router";
import { IoEyeOutline } from "react-icons/io5";
import { useStateContext } from "../../../contexts/ContextProvider";
import { IoAddCircleOutline } from "react-icons/io5";
import PDFViewer from "pdf-viewer-reactjs";
import RatingSystem from "../../../Components/Dashboard/User/RatingSystem";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import ReCAPTCHA from "react-google-recaptcha";
import pdfImage from "../../../Assets/pdf.png";
import { MdSettingsAccessibility } from "react-icons/md";
import { FiX } from "react-icons/fi"; // Import the Feather X icon

const AdminContractDetails = () => {
  const [history, setHistory] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nomtec, setNomTec] = useState("");
  const [prenomtec, setPrenomTec] = useState("");
  const [reviewTec, setReviewTec] = useState("");
  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");
  const { id } = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [prod_photoDuProduit, setPhotoDuProduit] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [adminContract, setAdminContract] = useState(null);
  const [resiliationModal, setResiliationModal] = useState(false);
  const [resiliationModalAnnule, setResiliationModalAnnule] = useState(false);
  const [acceptationnModal, setAcceptationnModal] = useState(false);
  const [reason, setReason] = useState("");
  const [contractAmount, setContractAmount] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const apiUrl = 'https://lebontechnicien.net'

  //Normal Client Appareil:
  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            email: NormalEmail,
          }),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        const filteredPendingList = data.filter(
          (item) => item._id === Appareil
        );
        setPendingList(filteredPendingList);
        console.log("Pendy Pend", pendingList);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  // Function to handle changes in the reason input
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleAmountChange = (event) => {
    setContractAmount(event.target.value);
  };

  const downloadFile = (url) => {
    if (url.endsWith(".pdf")) {
      // If it's a PDF, open it in a new tab/window for download.
      window.open(url, "_blank");
    } else {
      // For other file types, initiate a download using the `fetch` API.
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "downloaded_file";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Download error: ", error));
    }
  };

  const handlePhotoDuProduit = (event) => {
    event.preventDefault();
    const newFiles = event.target.files;
    const existingFiles = prod_photoDuProduit
      ? Array.from(prod_photoDuProduit)
      : [];

    // Combine existing files with new files
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
    console.log("setAdminContracthandleAddMorePictures", prod_photoDuProduit);
  };

  const handleAddMorePictures = (event) => {
    event.preventDefault();
    const newFiles = event.target.files;
    const existingFiles = prod_photoDuProduit
      ? Array.from(prod_photoDuProduit)
      : [];

    // Combine existing files with new files
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
    console.log("setPhotoDuProduithandleAddMorePictures", prod_photoDuProduit);
  };

  const handleRemovePicture = (index) => {
    console.log(
      "Before removing picture - prod_photoDuProduit:",
      prod_photoDuProduit
    );

    // Ensure prod_photoDuProduit is always treated as an array
    const updatedFiles = Array.isArray(prod_photoDuProduit)
      ? prod_photoDuProduit.filter((_, i) => i !== index)
      : [];

    setPhotoDuProduit(updatedFiles);

    console.log("After removing picture - prod_photoDuProduit:", updatedFiles);
  };

  const openModalImages = (index) => {
    setSelectedImageIndex(index);
    console.log("selectedImageIndex: " + selectedImageIndex);
    setModalOpen(true);
  };

  const closeModalImages = () => {
    setModalOpen(false);
  };

  const handleRecaptcha = (value) => {
    console.log("reCAPTCHA value:", value);
    setRecaptchaValue(value);
  };

  const handleOpenModal = () => {
    setShowModal(!showModal);
  };

  const handleResiliationModalAnnule = () => {
    setResiliationModalAnnule(!resiliationModalAnnule);
  };

  const handleResiliationModal = () => {
    setResiliationModal(!resiliationModal);
  };

  const handleAcceptationModal = () => {
    setAcceptationnModal(!acceptationnModal);
  };

  // Function to open the modal and set the selected image

  // Function to close the modal
  const closeModal = () => {
    setSelectedImage("");
    setIsModalOpen(false);
  };

  //Handles the Fetching previous interventions
  const fetchData = async () => {
    return fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        const filteredData = data.filter(
          (item) => item.type === "Demande de Maintenance" && item._id === id
        );
        setHistory(filteredData.reverse());
        return filteredData;
      })
      .catch((error) => console.error(error));
  };

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const history = await fetchData();
        const createdFor = history[0].created_for;
        console.log("createdFor", createdFor);
        const res = await fetch(`${apiUrl}/prods/get_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: createdFor }),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.clone().json();
        console.log();

        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  const selectedHistory = history.find((h) => h._id === id);
  console.log("selectedHistoryAdmin", selectedHistory);
  const PickedEndDate = selectedHistory?.end_date;

  const photoKeys = selectedHistory
    ? Object.keys(selectedHistory).filter((key) =>
        key.startsWith("photoDuProduit[")
      )
    : [];

  console.log("photoKeysAdmin", photoKeys);
  const end_date = selectedHistory?.end_date
    ? new Date(selectedHistory?.end_date)
    : null;
  const [selectedDateProlonger, setSelectedDateProlonger] = useState(
    end_date || new Date()
  );

  const handleProlongerDateChange = (date) => {
    if (date >= end_date) {
      setSelectedDateProlonger(date);
      setSelectedDate(PickedEndDate);
    }
  };

  console.log("selectedHistoryStatus", selectedHistory?.status);
  const date = new Date(selectedHistory?.created_at);
  const User = selectedHistory?.created_by;
  const NormalEmail = selectedHistory?.email;
  const Appareil = selectedHistory?.created_for;

  // Get the day, month, and year from the date object
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  // Format the date as desired (e.g. "16 Fev 2023")
  const formattedDate = `${day} ${month} ${year}`;

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmitAccept = async (e) => {
    setConfirmationModal(true);
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    const requestData = new FormData();
    requestData.append("rid", id);
    requestData.append("email", sessionStorage.getItem("email"));
    requestData.append("uid", sessionStorage.getItem("id"));
    requestData.append("EndDate", selectedDate ? selectedDate : PickedEndDate);
    requestData.append("contractAmount", contractAmount);
    requestData.append(
      "DateProlonger",
      selectedDateProlonger ? selectedDateProlonger : ""
    );
    if (prod_photoDuProduit && prod_photoDuProduit.length) {
      let startingIndex = photoKeys.length >= 0 ? photoKeys.length : 0;
      for (let i = 0; i < prod_photoDuProduit.length; i++) {
        requestData.append(
          `photoDuProduit[${startingIndex}]`,
          prod_photoDuProduit[i]
        );
        startingIndex++;
      }
    }
    requestData.append("recaptchaValue", recaptchaValue);

    fetch(`${apiUrl}/admin/accept_mrequest`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("success");
          setConfirmationModal(false);
          window.location.replace("/contrats");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
  };

  console.log("rid", id);

  const handleAddPhotos = async () => {
    console.log("This is working...");
    setConfirmationModal(true);
    const email = sessionStorage.getItem("email");
    const formData = new FormData();
    formData.append("email", email);
    formData.append("rid", id);

    if (prod_photoDuProduit && prod_photoDuProduit.length) {
      let startingIndex = photoKeys.length >= 0 ? photoKeys.length : 0;
      for (let i = 0; i < prod_photoDuProduit.length; i++) {
        formData.append(
          `photoDuProduit[${startingIndex}]`,
          prod_photoDuProduit[i]
        );
        startingIndex++;
      }
    }

    const response = await fetch(`${apiUrl}/request/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    });

    const data = await response.json();
    setConfirmationModal(false);
    window.location.reload();
  };

  const handleSubmitDeny = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    const requestData = new FormData();

    requestData.append("rid", id);
    requestData.append("email", sessionStorage.getItem("email"));
    requestData.append("uid", sessionStorage.getItem("id"));
    requestData.append("recaptchaValue", recaptchaValue);
    requestData.append("EndDate", selectedDate);
    requestData.append("raison", reason);

    fetch(`${apiUrl}/admin/denie_request`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          console.log("success");
          window.location.replace("/contrats");
          //setConfirmationModal(true);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
  };

  const handleRésiliation = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("rid", id);
      formData.append("email", sessionStorage.getItem("email"));
      formData.append("uid", sessionStorage.getItem("id"));
      formData.append("status", "Contrat résilié");
      formData.append("raison", reason);

      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();
      // Handle the response data here
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      if (!history) {
        // Wait for history to be set
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (history) {
              clearInterval(intervalId);
              resolve();
            }
          }, 100);
        });
      }
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: User }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        setNomTec(data.nom);
        setPrenomTec(data.prenom);
        setReviewTec(data.Review);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, [history, User]);

  return (
    <>
      {selectedHistory && (
        <>
          <div className="overflow-hidden bg-transparent shadow sm:rounded-lg w-[92%] ml-auto mr-auto mt-10 mb-10">
            <div className="flex justify-around mt-6">
              {pendingList.photoDuProduit && (
                <img
                  src={`https://lebontechnicien.net/${pendingList.photoDuProduit}`}
                  alt={pendingList._id}
                  className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                />
              )}
              <img
                src={`https://lebontechnicien.net/${pendingList.QR}`}
                alt={pendingList._id}
                className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
              />
            </div>

            {/*Detail du client*/}
            <>
              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                Détail du client:
              </h3>
              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                <dl>
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Nom:
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.nom_Pro}
                      </dd>
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Prenom:
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.prenom_Pro}
                      </dd>
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Numéro de téléphone
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {selectedHistory.telephone_pro}
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Adresse
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {selectedHistory.adresse}
                    </dd>
                  </div>
                </dl>
              </div>
            </>

            {/*Detail de l'appareil*/}
            <>
              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                Détail de l'appareil:
              </h3>
              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                <dl>
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Type d'appareil:
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.selectedSubdomain
                          ? selectedHistory.selectedSubdomain.includes(",")
                            ? selectedHistory.selectedSubdomain.split(",")[1]
                            : selectedHistory.selectedSubdomain
                          : pendingList.typeDuBien}
                      </dd>
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Marque
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {selectedHistory.marque
                        ? selectedHistory.marque === "autre"
                          ? selectedHistory.AutreMarque
                          : selectedHistory.marque
                        : pendingList.marque}
                    </dd>
                  </div>
                  {(selectedHistory.reference ||
                    selectedHistory.reference ||
                    pendingList.reference) && (
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Référence
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.reference
                          ? selectedHistory.reference
                          : selectedHistory.référence
                          ? selectedHistory.référence
                          : pendingList.reference}
                      </dd>
                    </div>
                  )}
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Puissance
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {selectedHistory.puissance
                        ? selectedHistory.puissance
                        : pendingList.puissance}
                    </dd>
                  </div>
                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Date d'installation
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {pendingList.dateInstallation === "" ||
                      pendingList.moisInstallation === ""
                        ? "-"
                        : `${
                            selectedHistory.moisInstallation ||
                            pendingList.moisInstallation
                          } / ${
                            selectedHistory.dateInstallation ||
                            pendingList.dateInstallation
                          }`}
                    </dd>
                  </div>
                </dl>
              </div>
            </>

            {/*Detail du contrat*/}
            <>
              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                Détail du contrat:
              </h3>
              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                <dl>
                  {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Date de la demande:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {formattedDate}
                          </dd>
                        </dd>
                      </div>
                      {(selectedHistory.PickedDate ||
                        selectedHistory.date_intervention) && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de l'intervention:
                          </dt>

                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.PickedDate
                              ? /^\d{2}\/\d{2}\/\d{4}$/.test(
                                  selectedHistory.PickedDate
                                )
                                ? selectedHistory.PickedDate
                                : new Date(
                                    selectedHistory.PickedDate
                                  ).toLocaleDateString("fr-FR")
                              : new Date(
                                  selectedHistory.date_intervention
                                ).toLocaleDateString("fr-FR")}{" "}
                            entre{" "}
                            {new Date(
                              selectedHistory.date_intervention
                            ).toLocaleTimeString("fr-FR", {
                              hour: "numeric",
                              hour12: false,
                            })}{" "}
                            et
                            {new Date(
                              new Date(
                                selectedHistory.date_intervention
                              ).getTime() +
                                2 * 60 * 60 * 1000
                            ).toLocaleTimeString("fr-FR", {
                              hour: "numeric",
                              hour12: false,
                            })}
                          </dd>
                        </div>
                          )}*/}

                  {selectedHistory.demandeur && (
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Demandeur
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.demandeur}
                      </dd>
                    </div>
                  )}

                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Date de début
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {/^\d{2}\/\d{2}\/\d{4}$/.test(
                        selectedHistory.selectedDate
                      )
                        ? selectedHistory.selectedDate
                        : new Date(
                            selectedHistory.selectedDate
                          ).toLocaleDateString("fr-FR")}
                    </dd>
                  </div>
                  {selectedHistory.contractAmount && (
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Tarif du contrat
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {parseInt(selectedHistory.contractAmount, 10) * 1.2}{" "}
                        €/an TTC
                      </dd>
                    </div>
                  )}
                  {selectedHistory.reason && (
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Raison de refus / résiliation
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.reason}
                      </dd>
                    </div>
                  )}

                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Photos & documents
                    </dt>
                    <dd
                      className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                      style={{
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div className="flex flex-wrap gap-4 ">
                        {photoKeys.slice(0).map((photoKey, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center w-[200px] mb-4"
                          >
                            {selectedHistory[photoKey].endsWith(".pdf") ? (
                              <a
                                href={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={pdfImage} // PDF icon image link
                                  alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                  className="w-[100px] h-[100px] cursor-pointer"
                                />
                              </a>
                            ) : (
                              <img
                                className="w-[100px] h-[100px] cursor-pointer"
                                src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                onClick={() => openModalImages(index + 1)} // Add 1 to the index to skip the first file
                              />
                            )}
                            <div className=" mt-2">
                              <p>
                                {selectedHistory[photoKey].split("/").pop()}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </dd>
                    {modalOpen && (
                      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                        <div className="bg-black opacity-50 w-full h-full absolute"></div>
                        <div className="bg-white p-8 rounded-lg relative z-10 w-[90%] ml-auto mr-auto">
                          <span
                            className="close flex justify-end cursor"
                            onClick={closeModalImages}
                          >
                            &times;
                          </span>
                          {photoKeys[selectedImageIndex]?.endsWith(".pdf") ? (
                            <button
                              onClick={() => {
                                console.log(photoKeys[selectedImageIndex]);
                                downloadFile(
                                  `https://lebontechnicien.net/${
                                    selectedHistory[
                                      photoKeys[selectedImageIndex]
                                    ]
                                  }`
                                );
                              }}
                            >
                              Download PDF
                            </button>
                          ) : (
                            <img
                              className="w-[80px] h-[80px] cursor-pointer"
                              src={`https://lebontechnicien.net/${selectedHistory[selectedImageIndex]}`}
                              alt={`Photoduprobleme ${selectedImageIndex + 1}`}
                              onClick={() =>
                                openModal(
                                  `https://lebontechnicien.net/${selectedHistory[selectedImageIndex]}`
                                )
                              }
                            />
                          )}
                          {/*<div className="flex ml-auto mr-auto justify-center gap-4">
                                <button
                                  className="bg-green-600 text-white rounded-md p-4"
                                  onClick={prevImage}
                                >
                                  Previous
                                </button>
                                <button
                                  className={`bg-green-600 text-white rounded-md p-4 ${
                                    selectedImageIndex ===
                                    photoKeys.length - 2
                                      ? "opacity-50 cursor-not-allowed"
                                      : ""
                                  }`}
                                  onClick={nextImage}
                                >
                                  Next
                                </button>
                                </div>*/}
                        </div>
                      </div>
                    )}
                  </div>

                  {selectedHistory.Paiement && (
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        RIB
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.Paiement === "Paiement effectué"
                          ? "RIB validé"
                          : "RIB non validé"}
                      </dd>
                    </div>
                  )}

                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Statut:
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {(() => {
                        switch (selectedHistory.status) {
                          case "Pending":
                            return "En attente de Validation";
                          case "closed":
                            return "Cloturé";
                          case "closed_tech":
                            return "Cloturé par technicien";
                          case "claim":
                            return "Réclamation";
                          case "Denied_payment":
                            return "Devis refusé";
                          case "Accepted_payment":
                            return "Devis accepté";
                          case "Paymen_User":
                            return "Devis";
                          case "Accepted":
                            return "Accepté";
                          case "claim_fix":
                            return "Résolu";
                          case "Email Pending":
                            return "Email non validé";
                          case "Confirmed":
                            return "validé";
                          case "In progress":
                            return "En cours de vérification";
                          case "Denied":
                            return "Réfusé";
                          case "solved":
                            return "Résolu";
                          case "unsolved":
                            return "Non résolu";
                          case "Full":
                            return "Complète";
                          case "litige_fixed":
                            return "Litige résolu";
                          case "claime_fixed":
                            return "Réclamation résolu";
                          case "annulled":
                            return "Annulée";
                          case "admin_accept":
                            return "Contrat Validé";
                          case "admin_denie":
                            return "Contrat refusé";
                          default:
                            return selectedHistory.status;
                        }
                      })()}
                    </dd>
                  </div>
                  {selectedHistory.status === "admin_accept" && (
                    <div className="border-t border-l border-r border-gray-200 ml-auto mr-auto mb-10 p-5">
                      {selectedHistory.status !== "closed" &&
                        selectedHistory.status !== "annuled" && (
                          <>
                            <div>
                              <legend className="text-sm font-medium text-black ">
                                Ajoutez des documents
                                <span className="text-red-500"></span>
                              </legend>
                            </div>

                            <div>
                              <div>
                                {prod_photoDuProduit && (
                                  <div className="mt-8 flex flex-between w-full gap-8">
                                    <div className="flex items-center flex-wrap w-[80%] gap-4">
                                      {Array.from(prod_photoDuProduit).map(
                                        (file, index) => (
                                          <div
                                            key={index}
                                            className="w-[150px]"
                                          >
                                            <div className="flex justify-center">
                                              {file.name
                                                .toLowerCase()
                                                .endsWith(".pdf") ? (
                                                <>
                                                  <img
                                                    src={pdfImage}
                                                    alt={`PDF Preview-${index}`}
                                                    className="h-16 w-16 object-cover rounded"
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <img
                                                    src={URL.createObjectURL(
                                                      file
                                                    )}
                                                    alt={`Preview-${index}`}
                                                    className="h-16 w-16 object-cover rounded"
                                                  />
                                                </>
                                              )}
                                              <button
                                                className="bg-gray-100 p-1 rounded-sm text-[10px] mb-10 mr-[-4px] font-bold text-red-500"
                                                onClick={() =>
                                                  handleRemovePicture(index)
                                                }
                                              >
                                                X
                                              </button>
                                            </div>
                                            <div className="w-[150px] h-[100px] mt-4 flex justify-center">
                                              <p className="text-sm text-center">
                                                {file.name}
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="flex gap-2">
                                      {/*<button
                                      className="mt-[10px] bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                      onClick={() => setAdminContract([])}
                                    >
                                      Change
                                          </button>*/}
                                      <div className="mt-4">
                                        <label
                                          htmlFor="photoDuProduit"
                                          className=""
                                        >
                                          <IoAddCircleOutline className="h-10 w-10 text-green-500" />
                                          <input
                                            id="photoDuProduit"
                                            name="photoDuProduit"
                                            type="file"
                                            multiple
                                            className="sr-only"
                                            onChange={handleAddMorePictures}
                                          />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {!prod_photoDuProduit && (
                                  <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                    <div className="space-y-1 text-center">
                                      <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                      >
                                        <path
                                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>

                                      <div className="flex text-sm text-gray-600 justify-center">
                                        <label
                                          htmlFor="photoDuProduit"
                                          className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                        >
                                          <span>Ajouter un fichier </span>
                                          <input
                                            id="photoDuProduit"
                                            name="photoDuProduit"
                                            type="file"
                                            multiple
                                            className="sr-only"
                                            value=""
                                            onChange={handlePhotoDuProduit}
                                          />
                                        </label>
                                        <p className="pl-1">
                                          ou faites glisser et déposez
                                        </p>
                                      </div>
                                      <p className="text-xs text-gray-500">
                                        PNG, JPG, GIF up to 10MB
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="flex justify-end gap-4 p-10">
                                <button
                                  onClick={handleAddPhotos}
                                  className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-green-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-green-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                >
                                  Mettre à jour
                                </button>
                              </div>
                            </div>

                            <></>
                          </>
                        )}
                    </div>
                  )}
                  {/*{selectedHistory.end_date && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de fin
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {/^\d{2}\/\d{2}\/\d{4}$/.test(
                              selectedHistory.end_date
                            )
                              ? selectedHistory.end_date
                              : new Date(
                                  selectedHistory.end_date
                                ).toLocaleDateString("fr-FR")}
                          </dd>
                        </div>
                              )}*/}

                  {/*{acceptationnModal && (
                    <>
                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                          className="fixed inset-0 w-full h-full bg-black opacity-40"
                          onClick={() => setAcceptationnModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                            <div className="mt-3 w-[100%]">
                              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                <h4 className="text-lg font-medium text-gray-800">
                                  Signer le contrat
                                </h4>
                                <div className="mt-4">
                                  <label
                                    htmlFor="reason"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Identifiant de validation
                                  </label>
                                </div>

                                <div>
                                  {prod_photoDuProduit && (
                                    <div className="mt-4 flex flex-between w-full gap-8">
                                      <div className="flex items-center flex-wrap w-[80%] gap-4">
                                        {Array.from(prod_photoDuProduit).map(
                                          (file, index) => (
                                            <div
                                              key={index}
                                              className="w-[150px]"
                                            >
                                              <div className="flex justify-center">
                                                {file.name
                                                  .toLowerCase()
                                                  .endsWith(".pdf") ? (
                                                  <>
                                                    <img
                                                      src={pdfImage}
                                                      alt={`PDF Preview-${index}`}
                                                      className="h-16 w-16 object-cover rounded"
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <img
                                                      src={URL.createObjectURL(
                                                        file
                                                      )}
                                                      alt={`Preview-${index}`}
                                                      className="h-16 w-16 object-cover rounded"
                                                    />
                                                  </>
                                                )}
                                                <button
                                                  className="bg-gray-100 p-1 rounded-sm text-[10px] mb-10 mr-[-4px] font-bold text-red-500"
                                                  onClick={() =>
                                                    handleRemovePicture(index)
                                                  }
                                                >
                                                  X
                                                </button>
                                              </div>
                                              <div className="w-[150px] h-[100px] mt-4 flex justify-center">
                                                <p className="text-sm text-center">
                                                  {file.name}
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div className="flex gap-2">
                                        <div className="mt-4">
                                          <label
                                            htmlFor="photoDuProduit"
                                            className=""
                                          >
                                            <IoAddCircleOutline className="h-10 w-10 text-green-500" />
                                            <input
                                              id="photoDuProduit"
                                              name="photoDuProduit"
                                              type="file"
                                              multiple
                                              className="sr-only"
                                              onChange={handleAddMorePictures}
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {(!prod_photoDuProduit ||
                                    prod_photoDuProduit.length === 0) && (
                                    <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                      <div className="space-y-1 text-center">
                                        <svg
                                          className="mx-auto h-12 w-12 text-gray-400"
                                          stroke="currentColor"
                                          fill="none"
                                          viewBox="0 0 48 48"
                                          aria-hidden="true"
                                        >
                                          <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>

                                        <div className="flex text-sm text-gray-600 justify-center">
                                          <label
                                            htmlFor="photoDuProduit"
                                            className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                          >
                                            <span>Ajouter un fichier </span>
                                            <input
                                              id="photoDuProduit"
                                              name="photoDuProduit"
                                              type="file"
                                              multiple
                                              className="sr-only"
                                              value=""
                                              onChange={handlePhotoDuProduit}
                                            />
                                          </label>
                                          <p className="pl-1">
                                            ou faites glisser et déposez
                                          </p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                          PNG, JPG, GIF up to 10MB
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_SITE_KEY}
                                  onChange={handleRecaptcha}
                                />
                                <div className="items-center gap-2 mt-3 sm:flex">
                                  <button
                                    className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                                    onClick={"handleSubmitAccept"}
                                  >
                                    Signer
                                  </button>
                                  <button
                                    className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                                    onClick={() => handleAcceptationModal()}
                                  >
                                    Annuler
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                                    )}*/}
                </dl>
              </div>
            </>

            {selectedHistory.status === "Pending" &&
              selectedHistory.Paiement === "Paiement effectué" && (
                <>
                  {/*<div className="w-[100%] ml-auto mr-auto mt-8 font-medium text-gray-700">
                      <p className="ml-4 mb-4">Date fin du contrat</p>
                    </div>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      locale={fr}
                      className="w-[100%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md  flex justify-between items-center px-20"
               />*/}

                  <div className="mt-4 flex justify-end items-center gap-5 w-[98%] p-4">
                    <button
                      onClick={handleAcceptationModal}
                      className=" 
                    inline-flex 
                    rounded-md 
                    border 
                    border-transparent 
                    bg-green-700 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-green-600 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                  "
                    >
                      accepter
                    </button>
                    <button
                      onClick={handleResiliationModal}
                      className=" 
                    inline-flex 
                    rounded-md 
                    border 
                    border-transparent 
                    bg-red-700 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-red-600 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                    
                  "
                    >
                      refuser
                    </button>
                  </div>
                </>
              )}

            {(selectedHistory.status === "admin_accept" ||
              selectedHistory.status === "Contrat signé") && (
              <div className="mt-4 flex justify-end mb-4 w-[96%] gap-4">
                <button
                  onClick={handleOpenModal}
                  className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-700 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                >
                  Prolonger le contrat
                </button>
                <button
                  onClick={handleResiliationModalAnnule}
                  className=" 
                    inline-flex 
                    rounded-md 
                    border 
                    border-transparent 
                    bg-red-700 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-green-600 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                  "
                >
                  Résilier
                </button>
              </div>
            )}

            {showModal && (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setShowModal(false)}
                  ></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 w-[100%]">
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-medium text-gray-800">
                            Prolonger le contrat
                          </h4>
                          <DatePicker
                            selected={
                              selectedDateProlonger || selectedDate || end_date
                            }
                            onChange={handleProlongerDateChange}
                            minDate={end_date}
                            dateFormat="dd/MM/yyyy"
                            locale={fr}
                            className="w-[100%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md flex justify-between items-center px-20"
                          />
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={handleRecaptcha}
                          />
                          <div className="items-center gap-2 mt-3 sm:flex">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                              onClick={handleSubmitAccept}
                            >
                              soumettre
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                              onClick={() => handleOpenModal()}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {resiliationModal && (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setResiliationModal(false)}
                  ></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 w-[100%]">
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-medium text-gray-800">
                            Résilier le contrat
                          </h4>
                          <div className="mt-4">
                            <label
                              htmlFor="reason"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Raison du refus
                            </label>
                            <textarea
                              type="text"
                              id="reason"
                              name="reason"
                              value={reason}
                              rows={4}
                              cols={50}
                              className="mb-2 mt-1 p-2 w-full border rounded-md focus:ring-green-500 focus:border-green-500"
                              placeholder="Saisissez la raison du refus..."
                              onChange={handleReasonChange}
                            />
                          </div>

                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={handleRecaptcha}
                          />
                          <div className="items-center gap-2 mt-3 sm:flex">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                              onClick={handleSubmitDeny}
                            >
                              soumettre
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                              onClick={() => handleResiliationModal()}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {acceptationnModal && (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setAcceptationnModal(false)}
                  ></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 w-[100%]">
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-medium text-gray-800">
                            Valider le contrat
                          </h4>
                          <div className="mt-4">
                            <label
                              htmlFor="reason"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Montant du contrat
                            </label>
                            <input
                              type="text"
                              id="reason"
                              name="reason"
                              className="mb-2 mt-1 p-2 w-full border rounded-md focus:ring-green-500 focus:border-green-500"
                              placeholder="Indiquez le montant du contrat..."
                              onChange={handleAmountChange}
                            />
                          </div>

                          <label
                              htmlFor="reason"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Le PDF du contrat
                            </label>
                          <div>
                            {prod_photoDuProduit && (
                              <div className="mt-8 flex flex-between w-full gap-8">
                                <div className="flex items-center flex-wrap w-[80%] gap-4">
                                  {Array.from(prod_photoDuProduit).map(
                                    (file, index) => (
                                      <div key={index} className="w-[150px]">
                                        <div className="flex justify-center">
                                          {file.name
                                            .toLowerCase()
                                            .endsWith(".pdf") ? (
                                            <>
                                              <img
                                                src={pdfImage}
                                                alt={`PDF Preview-${index}`}
                                                className="h-16 w-16 object-cover rounded"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Preview-${index}`}
                                                className="h-16 w-16 object-cover rounded"
                                              />
                                            </>
                                          )}
                                          <button
                                            className="bg-gray-100 p-1 rounded-sm text-[10px] mb-10 mr-[-4px] font-bold text-red-500"
                                            onClick={() =>
                                              handleRemovePicture(index)
                                            }
                                          >
                                            X
                                          </button>
                                        </div>
                                        <div className="w-[150px] h-[100px] mt-4 flex justify-center">
                                          <p className="text-sm text-center">
                                            {file.name}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div className="flex gap-2">
                                  {/*<button
                                      className="mt-[10px] bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                      onClick={() => setAdminContract([])}
                                    >
                                      Change
                                          </button>*/}
                                  <div className="mt-4">
                                    <label
                                      htmlFor="photoDuProduit"
                                      className=""
                                    >
                                      <IoAddCircleOutline className="h-10 w-10 text-green-500" />
                                      <input
                                        id="photoDuProduit"
                                        name="photoDuProduit"
                                        type="file"
                                        multiple
                                        className="sr-only"
                                        onChange={handleAddMorePictures}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                            {!prod_photoDuProduit && (
                              <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                <div className="space-y-1 text-center">
                                  <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>

                                  <div className="flex text-sm text-gray-600 justify-center">
                                    <label
                                      htmlFor="photoDuProduit"
                                      className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                    >
                                      <span>Ajouter un fichier </span>
                                      <input
                                        id="photoDuProduit"
                                        name="photoDuProduit"
                                        type="file"
                                        multiple
                                        className="sr-only"
                                        value=""
                                        onChange={handlePhotoDuProduit}
                                      />
                                    </label>
                                    <p className="pl-1">
                                      ou faites glisser et déposez
                                    </p>
                                  </div>
                                  <p className="text-xs text-gray-500">
                                    PNG, JPG, GIF up to 10MB
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>

                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={handleRecaptcha}
                          />
                          <div className="items-center gap-2 mt-3 sm:flex">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                              onClick={handleSubmitAccept}
                            >
                              soumettre
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                              onClick={() => handleAcceptationModal()}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {resiliationModalAnnule && (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setResiliationModalAnnule(false)}
                  ></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 w-[100%]">
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-medium text-gray-800">
                            Résilier le contrat
                          </h4>
                          <div className="mt-4">
                            <label
                              htmlFor="reason"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Raison du résiliation
                            </label>
                            <textarea
                              type="text"
                              id="reason"
                              name="reason"
                              value={reason}
                              rows={4}
                              cols={50}
                              className="mb-2 mt-1 p-2 w-full border rounded-md focus:ring-green-500 focus:border-green-500"
                              placeholder="Saisissez la raison du refus..."
                              onChange={handleReasonChange}
                            />
                          </div>

                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={handleRecaptcha}
                          />
                          <div className="items-center gap-2 mt-3 sm:flex">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                              onClick={handleRésiliation}
                            >
                              soumettre
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                              onClick={() => handleResiliationModalAnnule()}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {confirmationModal ? (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3">
                        <div className="mt-2 text-center text-center">
                          <h4 className="text-lg font-medium text-gray-800">
                            Votre demande est en cours de traitement
                          </h4>
                          {/*<div className="flex justify-center">
                        <Link to="/tableau-de-bord">
                          <button className=" mt-4 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2">
                            Mon compte
                          </button>
                        </Link>
                      </div>*/}
                          <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                            Votre demande est en cours de traitement. Nous
                            mettons tout en œuvre pour y répondre rapidement.
                            Nous vous remercions de votre patience et nous nous
                            excusons pour tout éventuel retard. Pour toute
                            question, n'hésitez pas à nous contacter.
                          </p>
                          <div className="items-center gap-2 mt-3 sm:flex"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {isModalOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div
                  className="modal-overlay absolute inset-0 bg-black opacity-50"
                  onClick={closeModal}
                ></div>
                <div className="modal-container bg-white w-full max-w-screen-lg mx-auto rounded shadow-lg z-50 overflow-y-auto">
                  <span
                    className="modal-close absolute top-0 right-0 m-4 text-gray-400 cursor-pointer text-3xl"
                    onClick={closeModal}
                  >
                    <FiX size={24} />{" "}
                    {/* Replace the "&times;" character with the Feather X icon */}
                  </span>
                  <div className="modal-content text-left relative flex justify-center items-center h-[600px]">
                    <img
                      className="modal-image w-[50%]"
                      src={selectedImage}
                      alt="Full-Screen"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AdminContractDetails;
