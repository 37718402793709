const FormationComponent = ({ formationData }) => {
  const formatDate = (date) => {
    const [day, month, year] = date.split("/");
    const monthNames = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    const formattedDate = `${parseInt(day, 10)} ${
      monthNames[parseInt(month, 10) - 1]
    } ${year}`;
    return formattedDate;
  };

  const today = new Date();

  return (
    <>
      {formationData.map((formation, index) => (
        <div key={index}>
          <h2 className="leBonTech-h2">Formation {formation.Formation}</h2>
          <p className="leBonTech-p" style={{ fontWeight: "bold" }}>
            Durée de formation: {formation["Nombre de jours"]}
          </p>
          <p className="leBonTech-p">Date disponibilité:</p>
          <p className="leBonTech-p">
            {/*{formation.Date.map((date) => {
              const formattedDate = formatDate(date);
              const [day, month, year] = date.split("/");
              const formattedDateObj = new Date(`${year}-${month}-${day}`);
              if (formattedDateObj >= today) {
                return formattedDate;
              }
              return null;
            })
              .filter((date) => date !== null)
          .join(", ")}*/}
            <a
              href="https://e.pcloud.link/publink/show?code=D3BotalK"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Consultez le planning
            </a>
          </p>
          <p className="leBonTech-p" style={{ fontWeight: "bold" }}>
            Le lieu: {formation.Adresse}
          </p>
          <p className="leBonTech-p">
            Inscrivez-vous ici:{" "}
            <a style={{ color: "blue", textDecoration: "underline" }} href={formation.contact}>{formation.contact}</a>
          </p>
        </div>
      ))}
    </>
  );
};

const LeBonTechnicienLG = ({ formationData, imgSrc }) => {
  return (
    <section className="leBonTech-section activites-section">
      <div className="nouvelle-demande">
        <FormationComponent formationData={formationData} />
      </div>
      {imgSrc && <img className="maxName" style={{maxWidth: '40% !important'}} src={imgSrc} alt={imgSrc} />}
      <div style={{ clear: "both" }}></div>
    </section>
  );
};

export default LeBonTechnicienLG;
