import React, { useEffect, useState } from "react";
import { BreadCrumb, CRUDTable } from "../../../Components";
import { HistoryUser } from "../../../Constants/dummy";
//import { Link, NavLink} from 'react-router-dom';
import { IoEyeOutline, IoPencil, IoTrashOutline } from "react-icons/io5";
import { useStateContext } from "../../../contexts/ContextProvider";
import Select from "react-select";
import { AiOutlineConsoleSql } from "react-icons/ai";

const TechProProjectSearch = () => {
  const [data, setData] = useState(HistoryUser);
  const [history, setHistory] = useState([]);
  const { userType } = useStateContext();
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedZone, setSelectedZone] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const techPro = sessionStorage.getItem("TechPro");
  const apiUrl = 'https://lebontechnicien.net'

  const handleDataChange = (newData) => {
    setData(newData);
  };

  const handleZoneChange = (selectedOptions) => {
    setSelectedZone(selectedOptions);
  };

  const fetchData = async (type) => {
    try {
      const response = await fetch(`${apiUrl}/find`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type }),
      });
      const users = await response.json();

      // Filter users where 'entreprise' is 'oui'
      const filteredUsers = users.filter((user) => user.entreprise === "oui");

      switch (type) {
        case "User":
          setUsers(filteredUsers.reverse());
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData("User");
  }, [inputValue]);

  // Fetches all the interventions
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (userType === "Tech" && techPro === "non") {
          // Filter for userType 'Tech'
          setHistory(
            data.filter(
              (item) => item.client_accept === sessionStorage.getItem("id")
            )
          );
        } else if (userType === "User") {
          // Filter for userType 'User'
          setHistory(
            data.filter(
              (item) => item.created_by === sessionStorage.getItem("id")
            )
          );
        } else if (userType === "Admin" || userType === "Superviseur") {
          // Show everything for 'Admin' or 'Superviseur'
          setHistory(data);
        } else if (userType === "Tech" && techPro === "oui") {
          setHistory(
            data.filter(
              (item) =>
                !item.accepted_by.includes(sessionStorage.getItem("id")) && item.status === "Pending" &&
                item.client === "Pro" && !item.TeamName
            )
          );
        }
      })
      .catch((error) => console.error(error));
  }, [userType]);
  // Include userType in the dependency array

  useEffect(() => {
    // Define the URL
    const apiUrl = `${apiUrl}/find`;

    // Send a GET request to the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        setUserData(responseData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const formatDate = (dateString) => {
    console.log("dateString:", dateString);

    const date = new Date(dateString);
    console.log("parsed date:", date);

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const monthName = monthNames[monthIndex];

    return `${day} ${monthName} ${year}`;
  };

  console.log("selectedClient", selectedClient);

  const info =
    history.length > 0
      ? userType === "Tech" && techPro === "oui"
        ? history
            .filter(
              (item) =>
                item.type !== "Demande de Réclamation" &&
                item.type !== "Demande de Maintenance"
            )
            .map((item) => {
              return {
                id: item._id,
                Création: new Date(item.created_at),
                FilterDate: item.created_at,
                Prestation: item.typeDePrestation === "Autres" ? "Remise en conformité" : item.typeDePrestation,
                Client: item.nom + " " + item.prenom,
                Raison: item.raisonSocialClient,
                Produit: item.typeDeProjet,
                Departement: item.postale,
                userID: item.created_by,
                Statut: item.status,
              };
            })
            .sort((a, b) => b.Création - a.Création)
            .map((item) => {
              return {
                id: item.id,
                Création: formatDate(item.Création),
                FilterDate: item.FilterDate,
                Prestation: item.Prestation,
                "Client Pro": item.Raison,
                Client: item.Client,
                Departement: item.Departement,
                userID: item.userID,
                Statut: item.Statut,
              };
            })
        : userType === "User"
        ? history
            .filter(
              (item) =>
                item.type !== "Demande de Réclamation" &&
                item.type !== "Demande de Maintenance" &&
                item.created_by === sessionStorage.getItem("id")
            )
            .map((item) => {
              return {
                id: item._id,
                Création: new Date(item.created_at),
                userID: item.created_by,
                Type: item.type ? item.type : null,
                Prestation: item.typeDePrestation === "Autres" ? "Remise en conformité" : item.typeDePrestation,
                Domaine: item.typeDeProjet ? item.typeDeProjet : null,
                Zone: item.zone ? item.zone : null,
                Status: item.status ? item.status : null,
              };
            })
            .sort((a, b) => b.Création - a.Création)
            .map((item) => {
              return {
                id: item.id,
                userID: item.userID,
                Création: formatDate(item.Création),
                Type: item.Type,
                Prestation: item.Prestation,
                "Type d'appareil": item.Domaine,
                Département: item.Zone,
                Statut: item.Status,
              };
            })
        : history
            .filter(
              (item) =>
                item.type !== "Demande de Réclamation" &&
                item.type !== "Demande de Maintenance"
            )
            .map((item) => {
              return {
                id: item._id,
                userID: item.created_by,
                Création_Date: new Date(item.created_at),
                Prestation: item.typeDePrestation === "Autres" ? "Remise en conformité" : item.typeDePrestation,
                Client: item.nom + " " + item.prenom,
                Produit: item.typeDeProjet,
                CP: item.postale,
                Statut: item.status,
              };
            })
            .sort((a, b) => b.Création - a.Création_Date)
            .map((item) => {
              return {
                id: item.id,
                Création: formatDate(item.Création),
                userID: item.userID,
                Prestation: item.Prestation,
                Client: item.Client,
                "Type d'appareil": item.Produit,
                "Code Postal": item.CP,
                Statut: item.Statut,
              };
            })
      : [];
  return (
    <>
      {userType === "User" ? (
        <BreadCrumb
          From={"Compte"}
          To={"historique des interventions"}
          hrefFrom={"/tableau-de-bord"}
        />
      ) : userType === "Tech" && sessionStorage.getItem("TechPro") === "oui" ? (
        <BreadCrumb
          From={"Compte"}
          To={"historique des interventions"}
          hrefFrom={"/mon-compte-tech"}
        />
      ) : (
        <BreadCrumb
          From={""}
          To={"historique des interventions"}
          hrefFrom={"/recherche"}
        />
      )}

      <div className="flex gap-2 w-[90%] ml-auto mr-auto flex-wrap ">
        {(userType === "Admin" || userType === "Superviseur") && (
          <>
            <Select
              value={
                selectedClient
                  ? {
                      value: selectedClient,
                      label: users.find((user) => user._id === selectedClient)
                        ?.raisonSocial,
                    }
                  : null
              }
              onChange={(selectedOption) =>
                setSelectedClient(selectedOption ? selectedOption.value : "")
              }
              options={[
                // Add the "Toutes les entreprises" option
                { value: "", label: "Toutes les entreprises" },
                ...users.map((item) => ({
                  value: item._id,
                  label: item.raisonSocialClient,
                })),
              ]}
              placeholder="Toutes les entrerprise"
              className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50 w-60"
            />
          </>
        )}
        {userType === "Tech" && techPro === "oui" && (
          <>
            <Select
              value={
                selectedClient
                  ? {
                      value: selectedClient,
                      label: users.find((user) => user._id === selectedClient)
                        ?.raisonSocial,
                    }
                  : null
              }
              onChange={(selectedOption) =>
                setSelectedClient(selectedOption ? selectedOption.value : "")
              }
              options={[
                // Add the "Toutes les entreprises" option
                { value: "", label: "Toutes les entreprises" },
                ...users.map((item) => ({
                  value: item._id,
                  label: item.raisonSocial,
                })),
              ]}
              placeholder="Toutes les entrerprise"
              className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50 w-60"
            />

            <Select
              className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50 w-[280px]"
              isMulti
              value={selectedZone}
              onChange={(selectedOptions) => {
                if (
                  selectedOptions.some((option) => option.value === "all_zones")
                ) {
                  // If "Toutes les zones" is selected, reset the zone filter
                  setSelectedZone([]);
                } else {
                  // Update selected zones
                  setSelectedZone(selectedOptions);
                }
              }}
              options={[
                // Add the "Toutes les zones" option
                { value: "all_zones", label: "Toutes les départements" },
                ...Array.from(
                  new Set(info.map((item) => item.Departement))
                ).map((status) => ({
                  value: status,
                  label: status,
                })),
              ]}
              placeholder="Toutes les départements"
            />


          </>
        )}

        <Select
          value={
            selectedValue
              ? { label: selectedValue, value: selectedValue }
              : null
          }
          onChange={(selectedOption) =>
            setSelectedValue(selectedOption ? selectedOption.value : "")
          }
          options={[
            {
              value: "",
              label: "Toutes les dates",
            },
            ...Array.from(
              new Set(
                info.map((item) => {
                  const creationDate = item.FilterDate;
                  const year = new Date(creationDate).getFullYear();
                  const month = new Date(creationDate)
                    .toLocaleString("default", { month: "long" })
                    .toLowerCase();

                  console.log("creationDate", new Date(item.Création));
                  console.log("creationDate", item.Création);
                  console.log(`${month} ${year}`);

                  return `${month} ${year}`;
                })
              )
            ).map((date) => ({
              value: date,
              label: date,
            })),
          ]}
          className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50 w-60"
          placeholder="Toutes les dates"
        />
        {!(userType === "Tech" && techPro === "oui") && (
          <Select
            value={
              selectedStatus
                ? { label: selectedStatus, value: selectedStatus }
                : null
            }
            onChange={(selectedOption) =>
              setSelectedStatus(selectedOption ? selectedOption.value : "")
            }
            options={[
              {
                value: "",
                label: "Tous les statuts",
              },
              ...Array.from(new Set(info.map((item) => item.Statut))).map(
                (status) => ({
                  value: status,
                  label:
                    status === "Pending"
                      ? "En cours"
                      : status === "closed"
                      ? "Cloturé"
                      : status === "closed_tech"
                      ? "Cloturé par technicien"
                      : status === "claim"
                      ? "Réclamation"
                      : status === "Denied_payment"
                      ? "Devis non accepté"
                      : status === "Accepted_payment"
                      ? "Devis accepté"
                      : status === "Paymen_User"
                      ? "Devis"
                      : status === "Accepted"
                      ? "Accepté par client"
                      : status === "solved"
                      ? "Résolu"
                      : status === "unsolved"
                      ? "Non résolu"
                      : status === "Full"
                      ? "Complète"
                      : status === "claime_fixed"
                      ? "Réclamation résolu"
                      : status === "litige_fixed"
                      ? "Litige résolu"
                      : status === "annulled"
                      ? "Annulée"
                      : status === "Tech_onhold"
                      ? "En attente"
                      : status === "pending"
                      ? "En cours"
                      : status,
                })
              ),
            ]}
            className="mt-6 p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50 w-60"
            placeholder="Tous les statuts"
          />
        )}
      </div>

      <div className="mt-10 max-w-screen-xl  ml-auto mr-auto">
        <CRUDTable
          data={info.filter((item) => {
            if (
              !selectedValue &&
              !selectedStatus &&
              !selectedClient &&
              !selectedZone &&
              !selectedTeam
            ) {
              return true;
            }

            return (
              (selectedClient ? item.userID === selectedClient : true) &&
              (selectedZone.length === 0
                ? true
                : selectedZone.some(
                    (selectedItem) => item.Departement === selectedItem.value
                  )) &&
              (selectedTeam.length === 0
                ? true
                : selectedTeam.some(
                    (selectedItem) => item.Equipe === selectedItem.value
                  )) &&
              (selectedValue
                ? item.Création.toLowerCase().endsWith(selectedValue)
                : true) &&
              (selectedStatus ? item.Statut === selectedStatus : true)
            );
          })}
          link={`/user-intervention-details/`}
          eyeIcon={
            <IoEyeOutline
              style={{ fontSize: "18px", cursor: "pointer", color: "#f8c408" }}
            />
          }
          onDataChange={handleDataChange}
        />
      </div>
    </>
  );
};

export default TechProProjectSearch;
