import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoCheckmarkDoneOutline } from "react-icons/io5";

const Notification = () => {
  const [logs, setLogs] = useState([]);
  const [checkedLogs, setCheckedLogs] = useState([]);
  const [viewedLogs, setViewedLogs] = useState([]);
  const [viewed, setViewed] = useState(false); // add a state variable to track whether the logs have been viewed
  const [refresh, setRefresh] = useState(false); // add a state variable to trigger a component refresh
  const [fetchOnView, setFetchOnView] = useState(false); // add a state variable to toggle fetching on view
  const apiUrl = 'https://lebontechnicien.net'

  const handleNotificationClick = (index) => {
    console.log("You clicked on notification:", index);
  };

  useEffect(() => {
    fetch(`${apiUrl}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
    })
      .then((response) => response.json())
      .then((data) => {
        const logs = [];
        console.log("data.log", data.log);
        for (const date in data.log) {
          for (const time in data.log[date]) {
            const message = data.log[date][time];
            if (!message.includes("-viewed")) {
              logs.push({
                date,
                time,
                message,
              });
            }
          }
        }
        logs.sort((a, b) => {
          const dateA = new Date(
            `${a.date.split('/').reverse().join('-')}T${a.time}`
          );
          const dateB = new Date(
            `${b.date.split('/').reverse().join('-')}T${b.time}`
          );
          return dateB - dateA; // Sort in descending order to show the latest notification first
        });
        setLogs(logs);
      })
      .catch((error) => console.log(error));
  }, [viewed, fetchOnView]);

  useEffect(() => {
    const newCheckedLogs = logs.map((log) => log.checked);
    setCheckedLogs(newCheckedLogs);
  }, [logs]);

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    const clickedLog = uncheckedLogs[index];
    const { date, time } = clickedLog;
    const email = sessionStorage.getItem("email");
    console.log("You clicked on viewed for notification:", index);
    fetch(`${apiUrl}/viewed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        email,
        date,
        time,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setViewed(true); 
        setFetchOnView(!fetchOnView); 
        setRefresh(!refresh); 
      })
      .catch((error) => console.error(error));
  };

  const uncheckedLogs = logs
    .filter((log, index) => !checkedLogs[index])
    logs.sort((a, b) => {
      const dateA = new Date(
        `${a.date.split('/').reverse().join('-')}T${a.time}`
      );
      const dateB = new Date(
        `${b.date.split('/').reverse().join('-')}T${b.time}`
      );
      return dateB - dateA; // Sort in descending order to show the latest notification first
    });
  const storeLogs = () => {
    sessionStorage.setItem("logs", JSON.stringify(uncheckedLogs));
  };

  const handleAll = async () => {
    try {
      const email = sessionStorage.getItem("email");
      const response = await fetch(`${apiUrl}/viewed_all`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email,
        }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setFetchOnView(!fetchOnView);
      setRefresh(!refresh);
      console.log("success");
      //setTechAcceptResponse(data);
      //navigation.navigate("Intervention");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="fixed top-10 right-20 z-[9999] bg-white rounded-lg shadow-xl mt-4 p-8 overflow-y-auto h-[350px] w-[600px]">
      <div className="flex justify-between text-sm font-bold">
        <h4 className="text-xl text-gray-900 font-bold">Notifications</h4>
        <Link to="/user-notification" className="text-gray-500 hover:underline">
          Voir la page des notifications
        </Link>
      </div>
      <div className="flex justify-end">
      {uncheckedLogs.length !== 0 && (
          <button onClick={handleAll} className="bg-green-600 py-[5px] px-[5px] border-[1px] border-[transparent] rounded-[5px]">
            <p className="text-[13px] text-white">Marque tous comme Lu</p>
          </button>
        )}
        </div>
      {uncheckedLogs.length === 0 ? (
        <p>
          Aucune notification n'est actuellement répertoriée. Veuillez bien vouloir vérifier ultérieurement.
        </p>
      ) : (
        <div className="relative px-4 ">
          <div className="absolute h-full border border-dashed border-opacity-20 border-secondary"></div>
          {uncheckedLogs.map((log, index) => (
            <div
              className="z-999 flex items-center w-full my-6 -ml-1.5"
              key={index}
              onClick={() => handleNotificationClick(index)}
            >
              <div className="w-1/12 z-10">
                <div className="w-3.5 h-3.5 bg-green-600 rounded-full"></div>
              </div>
              <div className="flex justify-between w-full">
                <div className="w-full">
                  <p className="text-sm">{log.message}</p>
                  <p className="text-[12px] text-gray-500">
                    {log.date} at {log.time}
                  </p>
                </div>
                <div>
                  <div
                    className="transition-colors duration-300 rounded-full bg-red-500 h-10 w-10 flex items-center justify-center"
                    style={{ backgroundColor: "#c13a39" }}
                  >
                    <button onClick={(e) => handleSubmit(e, index)}>
                      <IoCheckmarkDoneOutline className="w-[22px] h-[22px] text-white align-middle" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notification;
