import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components";
import clients from "../../../Assets/Icons/mes-clients.png";
import user from "../../../Assets/Icons/ajouter-utilisateurs.png";
import stats from "../../../Assets/Icons/stats.png";
import litiges from "../../../Assets/Icons/Litiges.png";
import claims from "../../../Assets/Icons/liste-reclamations.png";
import contract from "../../../Assets/Icons/contrat-maintenance.png";
import project from "../../../Assets/Icons/suivi-projet.png";

const AdminNewDash = () => {
  return (
    <div className="bg-white">
      <BreadCrumb From={""} To={"Mon Compte"} hrefFrom={"#"} />
      <div className="max-w-screen-xl  ml-auto mr-auto border border-gray-200 rounded-lg mt-10 mb-[8px] grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 p-10">
        <div className="flex justify-center  p-5  h-[200px]">
          <div className="m-auto cursor-pointer">
            <a href="/liste-des-utilisateurs">
              <img
                src={clients}
                alt="Liste des Utilisateurs"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />
              <h3 className="text-center text-[16px] font-bold">
                Liste des Utilisateurs
              </h3>
            </a>
          </div>
        </div>

        <div className="flex justify-center  p-5  h-[200px] border-x border-l border-r border-[#d9d9d9]!important ">
          <div className="m-auto cursor-pointer ">
            <a href="/user-history">
              <img
                src={project}
                alt="Ajouter Un Utilisateur"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />

              <h3 className="text-center text-[16px] font-bold">
                Liste des interventions
              </h3>
            </a>
          </div>
        </div>

        <div className="flex justify-center  p-5  h-[200px]">
          <div className="m-auto cursor-pointer">
            <a href="/contrats">
              <img
                src={contract}
                alt="Liste des contrats de maintenance"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />

              <h3 className="text-center text-[16px] font-bold">
                Liste des Contrats
              </h3>
            </a>
          </div>
        </div>

        <div className="flex justify-center  p-5  h-[200px]">
          <div className="m-auto cursor-pointer  ">
            <a href="/tableau-de-bord-technicien">
              <img
                src={stats}
                alt="Rapports & Statistiques"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />
              <h3 className="text-center text-[16px] font-bold">
                Rapports & Statistiques
              </h3>
            </a>
          </div>
        </div>

        <div className="flex justify-center  p-5  h-[200px] border-x border-l border-r border-[#d9d9d9]!important ">
          <div className="m-auto cursor-pointer">
            <a href="/litiges">
              <img
                src={litiges}
                alt="Liste des Litiges"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />

              <h3 className="text-center text-[16px] font-bold">
                Liste des Litiges
              </h3>
            </a>
          </div>
        </div>

        {/*<div className="flex justify-center  p-5  h-[200px]">
          <div className="m-auto cursor-pointer">
            <a href="/réclamations">
              <img
                src={claims}
                alt="Liste des Réclamations"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />

              <h3 className="text-center text-[16px] font-bold">
                Liste des Réclamations
              </h3>
            </a>
          </div>
        </div>*/}

        <div className="flex justify-center  p-5  h-[200px]">
          <div className="m-auto cursor-pointer ">
            <a href="/ajouter-un-utilisateur">
              <img
                src={user}
                alt="Ajouter Un Utilisateur"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />

              <h3 className="text-center text-[16px] font-bold">
                Ajouter Un Utilisateur
              </h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNewDash;
