import React, { useState } from "react";
import ios from "../../Assets/app-store-apple.png";
import android from "../../Assets/app-store-google.png";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoLinkedin,
} from "react-icons/io5";
import LogoBig from "../../Assets/media/logo-lebontechnicien-fond-fonce.svg";
import appStore from "../../Assets/media/app-store-blanc.png";
import playStore from "../../Assets/media/google-app.png";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-section">
          <ul className="leBonTech-ul">
            <li className="leBonTech-li">
              <a classname="lebontech-a" href="/charte-qualite">
                Charte de qualité
              </a>
            </li>
            <li className="leBonTech-li">
              <a classname="lebontech-a" href="/cgv">
                Conditions générales de vente (CGV)
              </a>
            </li>
            <li className="leBonTech-li">
              <a classname="lebontech-a" href="/cgu">
                Conditions générales d’utilisation (CGU)
              </a>
            </li>
            <li className="leBonTech-li">
              <a classname="lebontech-a" href="/politique-confidentialite">
                Politique de confidentialité
              </a>
            </li>
            <li className="leBonTech-li">
              <a classname="lebontech-a" href="/gestion-cookies">
                Gestion des cookies
              </a>
            </li>
          </ul>
          <ul className="leBonTech-ul" style={{ marginTop: "10px" }}>
            <li className="leBonTech-li">
              <a classname="lebontech-a" href="/contact">
                Contact
              </a>
            </li>
            <li className="leBonTech-li">
              <a classname="lebontech-a" href="/comment-ca-marche">
                Comment ça marche
              </a>
            </li>
            <li className="leBonTech-li">
              <a classname="lebontech-a" href="/activites">
                activités
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="banner-btns " style={{ margin: "auto" }}>
          <a
            href="https://apps.apple.com/fr/app/lebontech/id6450009896"
            className="item-btn btn-ghost btn-dark btn-1"
            style={{
              borderColor: "#ffffff",
              color: "#ffffff",
              margin: "auto",
              marginRight: "20px",
            }}
          >
            <img src={appStore} alt="App Store" />
            App Store
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.devinov.LeBonTechnicienUser&pli=1"
            className="item-btn btn-ghost btn-dark btn-2"
            style={{
              borderColor: "#ffffff",
              color: "#ffffff",
              margin: "auto auto auto 0",
            }}
          >
            <img src={playStore} alt="Google Play" />
            Google Play
          </a>
        </div>
        <p>
          <a href="/contact">contact@lebontechnicien.fr</a>
        </p>
        <img
          className="logo-footer"
          src={LogoBig}
          alt="Logo LeBonTechnicien.fr"
        />
        <p>
          © {new Date().getFullYear()} <a href="https://devinov.fr">DEVINOV</a>.
          Tous droits réservés.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
