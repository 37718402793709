import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [recaptchaerror, setRecaptchaError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const apiUrl =
  process.env.REACT_APP_ENV === 'prod'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_PRE_PROD;

  // handle recaptcha value change
  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handlename = (event) => {
    const newName = event.target.value;
    setName(newName);
  };

  const handleEntreprise = (event) => {
    const newEntreprise = event.target.value;
    setEntreprise(newEntreprise);
  };

  const handleEmail = (event) => {
    const newemail = event.target.value;
    setEmail(newemail);
  };

  const handleSubject = (event) => {
    const newSubject = event.target.value;
    setSubject(newSubject);
  };

  const handleMessage = (event) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
  };


  const clearFormFields = () => {
    setName("");
    setEmail("");
    setEntreprise("");
    setSubject("");
    setMessage("");
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!recaptchaValue || recaptchaValue === "") {
      setRecaptchaError("Veuillez vérifier le Google reCAPTCHA");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("entreprise", entreprise);
    formData.append("subject", subject);
    formData.append("message", message);

    fetch(`${apiUrl}/hl/send_email`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setSuccessMessage("Message envoyé avec succès!"); // Show success message
          setError(""); // Clear any previous error
          clearFormFields(); // Clear form fields after successful submission
        } else {
          setError(data.message);
          setSuccessMessage(""); // Clear success message
        }
      })
      .catch((error) => {
        setError("Une erreur s'est produite lors de l'envoi de votre message");
        console.error(error);
        setSuccessMessage(""); // Clear success message
      });
  };

  return (
    <section className="leBonTech-section">
      <div className="row">
        <div className="col-lg-8 ml-[-20px]">
          {error && <p className="leBonTech-p">{error}</p>}
          {successMessage && <p className="leBonTech-p green">{successMessage}</p>}
          <form className="w-full max-w-lg">
            <div className="flex flex-wrap -mx-3">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Nom
                </label>
                <input
                  className="appearance-none block w-full bg-[#f4f7fa] text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  name="name"
                  value={name}
                  onChange={handlename}
                  required
                  id="name"
                  autoComplete="name"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="entreprise"
                >
                  Nom de l'entreprise
                </label>
                <input
                  className="appearance-none block w-full bg-[#f4f7fa] text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  name="entreprise"
                  value={entreprise}
                  onChange={handleEntreprise}
                  required
                  id="entreprise"
                  autoComplete="entreprise"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  E-mail
                </label>
                <input
                  className="appearance-none block w-full bg-[#f4f7fa] text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleEmail}
                  required
                  autoComplete="email"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Sujet
                </label>
                <input
                  className="appearance-none block w-full bg-[#f4f7fa] text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="subject"
                  type="subject"
                  name="subject"
                  value={subject}
                  onChange={handleSubject}
                  required
                  autoComplete="subject"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="no-resize appearance-none block w-full bg-[#f4f7fa] text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                  id="message"
                  type="message"
                  name="message"
                  value={message}
                  onChange={handleMessage}
                  required
                  autoComplete="message"
                ></textarea>
              </div>
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={handleRecaptcha}
              style={{marginBottom: "20px"}}
            />

            {recaptchaerror && recaptchaerror.length > 0 && (
              <div style={{ color: "red" }}>{recaptchaerror}</div>
            )}
            <div className="md:flex md:items-center">
              <div className="md:w-1/3">
                <button
                  className="shadow bg-[#117703] hover:bg-[#3e9f36] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Envoyer
                </button>
              </div>
              <div className="md:w-2/3"></div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Form;
