import React, { useEffect, useState } from "react";
import { BreadCrumb, ReturnButton } from "../../../Components";
import { ToastContainer, toast } from "react-toastify";
import { BsHouseDoor, BsBuilding } from "react-icons/bs";
import { TbBuildingArch } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Domaines } from "../../../Constants/dummy";
import ReCAPTCHA from "react-google-recaptcha";

const NewProject = () => {
  const formArray = [1, 2, 3, 4, 5, 6, 7, 8];
  const [formNo, setFormNo] = useState(formArray[0]);
  const [typeDeProjet, setTypeDeProjet] = useState("");
  const [typeDePrestation, setTypeDePrestation] = useState("");
  const [productId, setProductId] = useState("");
  const [typeDuBien, setTypeDuBien] = useState("");
  const [materiaux, setMateriaux] = useState("");
  const [fin, setFin] = useState("");
  const [photoduProblem, setPhotoduProblem] = useState(null);
  const [information, setInformation] = useState("");
  const [error, setError] = useState("");
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [zone, setZone] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [nomUser, setNomUser] = useState("");
  const [prenomUser, setPrenomUser] = useState("");
  const [nomDuBanque, setNomDuBanque] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");
  const [autresValue, setAutresValue] = useState("");
  const [installationValue, setInstallationValue] = useState("");
  const [errorForm, setErrorForm] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedDomaine, setSelectedDomaine] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedSubdomain, setSelectedSubdomain] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);


  const apiUrl = 'https://lebontechnicien.net'

  const handleDomaineSelection = (domaine) => {
    setSelectedDomaine(domaine);
    setSelectedTypes(""); // clear the previously selected type
  };

  const handleTypeSelection = (domain, subdomain) => {
    setSelectedDomain(domain);
    setSelectedSubdomain(subdomain);
  };

  const handleInstallationValueChange = (e) => {
    setInstallationValue(e.target.value);
  };

  const isAutresSelected = typeDePrestation === "Autres";

  const handleNom = (e) => {
    setNomUser(e.target.value);
  };

  const handleRecaptcha = (value) => {
    console.log("reCAPTCHA value:", value);
    setRecaptchaValue(value);
  };

  const handlePrenom = (e) => {
    setPrenomUser(e.target.value);
  };

  const handleNomDuBanque = (e) => {
    setNomDuBanque(e.target.value);
  };

  const handleIban = (e) => {
    setIban(e.target.value);
  };

  const handleBic = (e) => {
    setBic(e.target.value);
  };

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        setZone(data.zone);
        console.log("datazone:", data);
        console.log("onzone", zone);
        setNom(data.nom);
        setPrenom(data.prenom);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  const handleTypeDeProjet = (event) => {
    setTypeDeProjet(event.target.value);
  };

  const handleTypeDePrestation = (event) => {
    setTypeDePrestation(event.target.value);
  };

  const handleTypeDuBien = (event) => {
    setTypeDuBien(event.target.value);
  };

  const handleMateriaux = (event) => {
    setMateriaux(event.target.value);
  };

  const handleFin = (event) => {
    setFin(event.target.value);
  };

  const handlePhotoduProblem = (event) => {
    setPhotoduProblem(event.target.files);
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleInformation = (event) => {
    setInformation(event.target.value);
  };

  console.log("selectedSubdomain", selectedSubdomain);

  const email = sessionStorage.getItem("email");

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        setPendingList(data);
        console.log(pendingList);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    const requestData = new FormData();
    if (photoduProblem && photoduProblem.length) {
      for (let i = 0; i < photoduProblem.length; i++) {
        requestData.append(`photoDuProduit[${i}]`, photoduProblem[i]);
      }
    }
    if (photoduProblem && photoduProblem.length) {
      requestData.append("photoduProblemName", photoduProblem.name);
    }
    requestData.append("typeDeProjet", selectedSubdomain);
    requestData.append("typeDePrestation", typeDePrestation);
    requestData.append("typeDuBien", typeDuBien);
    requestData.append("materiaux", materiaux);
    requestData.append("fin", fin);
    requestData.append("information", information);
    requestData.append("Pid", productId);
    requestData.append("productId", productId);
    requestData.append("nom", nom);
    requestData.append("prenom", prenom);
    requestData.append("type", "Demande d'intervention");
    requestData.append("clientNumber", sessionStorage.getItem("phone"));
    requestData.append("clientEmail", sessionStorage.getItem("email"));
    requestData.append("zone", sessionStorage.getItem("zone"));
    requestData.append("adresse", sessionStorage.getItem("adresse"));
    requestData.append("email", sessionStorage.getItem("email"));
    requestData.append("count", "0");

    let isValid = true;
    /*const requiredFields = [
      "typeDeProjet",
      "typeDePrestation",
      "materiaux",
      typeDePrestation !== 'Installation' ?"fin": null,
      typeDePrestation !== 'Installation' ? "information": null,
      typeDePrestation !== 'Installation' ? "productId" : null,
    ];
    for (const field of requiredFields) {
      const input = document.querySelector(`[name="productId"]`);
      if (!input || !input.value) {
        isValid = false;
        if (input) {
          input.style.borderColor = "red";
          const errorText = document.createElement("p");
          errorText.innerText = `Veuillez remplir ce champ.`;
          errorText.style.color = "red";
          input.parentNode.insertBefore(errorText, input);
        }
      } else {
        input.style.borderColor = "initial";
        const errorText = input.previousElementSibling;
        if (
          errorText &&
          errorText.innerText.startsWith("Veuillez remplir ce champ")
        ) {
          errorText.remove();
        }
      }
    }*/

    console.log("requestData: " + requestData);

    // Check radio input
    const radioInput = document.querySelector(
      '[name="typeDePrestation"]:checked'
    );
    if (!radioInput) {
      isValid = false;
      const errorText = document.createElement("p");
      errorText.innerText = `Veuillez remplir ce champ.`;
      errorText.style.color = "red";
      errorText.style.width = "300px";
      const radioContainer = document.querySelector('[name="typeDePrestation"]')
        .parentNode.parentNode;
      radioContainer.parentNode.insertBefore(
        errorText,
        radioContainer.nextSibling
      );
    } else {
      const errorText = document.querySelector('[name="typeDePrestation"]')
        .parentNode.parentNode.nextSibling;
      if (
        errorText &&
        errorText.innerText.startsWith("Veuillez remplir ce champ")
      ) {
        errorText.remove();
      }
    }

    const radioInput1 = document.querySelector('[name="typeDuBien"]:checked');
    if (!radioInput1) {
      isValid = false;
      const errorText = document.createElement("p");
      errorText.innerText = `Veuillez remplir ce champ.`;
      errorText.style.color = "red";
      errorText.style.width = "300px";
      const radioContainer = document.querySelector('[name="typeDuBien"]')
        .parentNode.parentNode;
      radioContainer.parentNode.insertBefore(
        errorText,
        radioContainer.nextSibling
      );
    } else {
      const errorText = document.querySelector('[name="typeDuBien"]').parentNode
        .parentNode.nextSibling;
      if (
        errorText &&
        errorText.innerText.startsWith("Veuillez remplir ce champ")
      ) {
        errorText.remove();
      }
    }

    const radioInput2 = document.querySelector('[name="materiaux"]:checked');
    if (!radioInput2) {
      isValid = false;
      const errorText = document.createElement("p");
      errorText.innerText = `Veuillez remplir ce champ.`;
      errorText.style.color = "red";
      errorText.style.width = "300px";
      const radioContainer =
        document.querySelector('[name="materiaux"]').parentNode.parentNode;
      radioContainer.parentNode.insertBefore(
        errorText,
        radioContainer.nextSibling
      );
    } else {
      const errorText =
        document.querySelector('[name="materiaux"]').parentNode.parentNode
          .nextSibling;
      if (
        errorText &&
        errorText.innerText.startsWith("Veuillez remplir ce champ")
      ) {
        errorText.remove();
      }
    }

    /*const radioInput3 = document.querySelector('[name="fin"]:checked');
    if (!radioInput3) {
      isValid = false;
      const errorText = document.createElement("p");
      errorText.innerText = `Veuillez remplir ce champ.`;
      errorText.style.color = "red";
      errorText.style.width = "300px";
      const radioContainer =
        document.querySelector('[name="fin"]').parentNode.parentNode;
      radioContainer.parentNode.insertBefore(
        errorText,
        radioContainer.nextSibling
      );
    } else {
      const errorText =
        document.querySelector('[name="fin"]').parentNode.parentNode
          .nextSibling;
      if (
        errorText &&
        errorText.innerText.startsWith("Veuillez remplir ce champ")
      ) {
        errorText.remove();
      }
    }*/

    // Check if Autres is selected and if the textarea is filled out
    /*if (
      document.querySelector('[name="typeDePrestation"]:checked').value ===
      "Autres"
    ) {
      const autresTextarea = document.querySelector('[name="autres-value"]');
      if (!autresTextarea.value) {
        isValid = false;
        autresTextarea.style.borderColor = "red";
        const errorText = document.createElement("p");
        errorText.innerText = `Veuillez remplir ce champ.`;
        errorText.style.color = "red";
        autresTextarea.parentNode.insertBefore(errorText, autresTextarea);
      } else {
        autresTextarea.style.borderColor = "initial";
        const errorText = autresTextarea.previousElementSibling;
        if (
          errorText &&
          errorText.innerText.startsWith("Veuillez remplir ce champ")
        ) {
          errorText.remove();
        }
      }
    }*/

    if (!isValid) {
      return;
    }
    setConfirmationModal(true);
    fetch(`${apiUrl}/request/create_request`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestData,
    })
      .then((res) => {
        if (res.status === 413) {
          // Handle the 413 status code (Payload Too Large)
          setError(
            "La taille des fichiers image excède les limites autorisées. Veuillez compresser vos images pour une soumission de requête réussie."
          );
        } else if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while processing the request.");
        }
      })
      .then((data) => {
        if (data) {
          console.log("success");
          setConfirmationModal(false);
          window.location.replace("/user-history");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError(
          "Une erreur s'est produite lors de l'enregistrement : An error occurred while registering."
        );
        console.error(error);
      });
  };

  console.log(pendingList);

  return (
    <>
      <BreadCrumb
        From={"Compte"}
        To={"Nouvelle demande d’intervention"}
        hrefFrom={"/tableau-de-bord"}
      />
      <ReturnButton/>
      <div className="bg-white  mx-auto max-w-screen-xl  p-4 border border-gray-200 rounded-lg">
        <h1 className="text-2xl font-bold mb-4 px-4">Nouveau Projet</h1>
        <form className=" p-4 rounded-md">
          <div className="mb-4">
            <h2 className="text-lg font-bold mb-2 px-4">Type de l'appareil</h2>
            <div className="flex flex-wrap">
              {Domaines.map((domaine) => (
                <div
                  className="rounded-md cursor-pointer w-full md:w-1/2 lg:w-1/4 px-4 py-2"
                  key={domaine.id}
                >
                  <div
                    className="flex"
                    onClick={() => handleTypeSelection(domaine.nom, null)}
                  >
                    <div className="flex">
                      {domaine.icon}
                      {domaine.nom}
                    </div>
                  </div>
                  {domaine.hasType &&
                    selectedDomain === domaine.nom &&
                    domaine.type.map((type) => (
                      <div key={type} className="p-2">
                        <label>
                          <input
                            type="radio"
                            checked={selectedSubdomain === type}
                            onChange={() =>
                              handleTypeSelection(domaine.nom, type)
                            }
                          />
                          {type}
                        </label>
                      </div>
                    ))}
                  {!domaine.hasType && selectedDomain === domaine.nom && (
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedSubdomain === domaine.nom}
                          onChange={() =>
                            handleTypeSelection(domaine.nom, domaine.nom)
                          }
                        />
                        {domaine.nom}
                      </label>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="mb-8">
            <h2 className="text-lg font-bold mb-2 px-4">
              Type de prestation souhaité{" "}
              <span className="text-red-500"> * </span>
            </h2>
            <div className="flex gap-8">
              <div className="w-[96%] ml-auto mr-auto ">
                <label className="">
                  <input
                    type="radio"
                    id="typeDuPrestation"
                    name="typeDePrestation"
                    value="Installation"
                    className="leading-tight mr-3"
                    checked={typeDePrestation === "Installation"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Installation</span>
                </label>
                <label className="">
                  <input
                    type="radio"
                    id="typeDuPrestation"
                    name="typeDePrestation"
                    value="Réparation"
                    className="leading-tight mr-3"
                    checked={typeDePrestation === "Réparation"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Réparation</span>
                </label>
                <label className="">
                  <input
                    id="typeDuPrestation"
                    type="radio"
                    name="typeDePrestation"
                    value="Remplacement"
                    className="leading-tight mr-3"
                    checked={typeDePrestation === "Remplacement"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Remplacement</span>
                </label>
                <label className="">
                  <input
                    id="typeDuPrestation"
                    type="radio"
                    name="typeDePrestation"
                    value="Maintenance"
                    className="leading-tight mr-3"
                    checked={typeDePrestation === "Maintenance"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Maintenance</span>
                </label>
                <label className="">
                  <input
                    id="typeDuPrestation"
                    type="radio"
                    name="typeDePrestation"
                    value="Autres"
                    className="leading-tight mr-3 "
                    checked={typeDePrestation === "Autres"}
                    onChange={handleTypeDePrestation}
                  />
                  <span className="mr-2">Mise en conformité</span>
                </label>
              </div>
            </div>
          </div>
          {typeDePrestation !== "Installation" && (
            <div className="mb-8">
              <h2 className="text-lg font-bold mb-2 px-4">
                Sélectionner un appareil{" "}
                <span className="text-red-500"> * </span>
              </h2>
              <div className="">
                <div>
                  <div className="w-[96%] ml-auto mr-auto">
                    {pendingList.filter(
                      (item) =>
                        item?.typeDuBien &&
                        item?.typeDuBien
                          .split(",")
                          .map((s) => s.trim())
                          .includes(selectedSubdomain)
                    ).length > 0 ? (
                      <select
                        id="productId"
                        name="productId"
                        value={productId}
                        onChange={(e) => setProductId(e.target.value)}
                        className="w-[92%] p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                      >
                        <option value="">sélectionner votre produit</option>
                        {pendingList
                          .filter(
                            (item) =>
                              item?.typeDuBien &&
                              item?.typeDuBien
                                .split(",")
                                .map((s) => s.trim())
                                .includes(selectedSubdomain)
                          )
                          .map((item) => (
                            <option
                              key={item._id}
                              value={item._id}
                              className="w-[90%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5  rounded-lg flex justify-between items-center px-20"
                            >
                              {item.typeDuBien} - {item.marque}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <div className="w-[96%] ml-auto mr-auto flex justify-start ">
                        <h1 className="text-center mt-8">
                          vous n'avez pas de produit
                        </h1>
                        <div className="w-[70%] ml-auto mr-auto mt-4">
                          <button className="submit-button px-3 py-2 text-lg rounded-md  text-white bg-blue-500 ml-auto mr-auto">
                            <Link className="text-white" to="/mes-appareils">
                              veuillez l'ajouter
                            </Link>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {typeDePrestation === "Installation" && (
            <div className="w-[96%] ml-auto mr-auto mt-4">
              <label className="" htmlFor="autres-value">
                <span className="mr-2">
                  Dites-nous en plus sur votre installation
                </span>
                <textarea
                  id="installation-value"
                  name="installation-value"
                  value={installationValue}
                  onChange={handleInstallationValueChange}
                  required
                  className="leading-tight mr-3 w-[80%] p-4 border-1 border-gray-300 rounded-md"
                />
              </label>
            </div>
          )}
          <div className="mb-8">
            <h2 className="text-lg font-bold mb-2 px-4">
              Type de bien : <span className="text-red-500"> * </span>
            </h2>
            <div className="mx-auto w-[96%]">
              <div className="flex gap-8">
                <label className="">
                  <input
                    type="radio"
                    id="typeDuBien"
                    name="typeDuBien"
                    value="Maison"
                    className="leading-tight mr-3"
                    checked={typeDuBien === "Maison"}
                    onChange={handleTypeDuBien}
                  />
                  <span className="mr-2">Maison</span>
                </label>
                <label className="">
                  <input
                    id="typeDuBien"
                    type="radio"
                    name="typeDuBien"
                    value="Appartement"
                    className="leading-tight mr-3"
                    checked={typeDuBien === "Appartement"}
                    onChange={handleTypeDuBien}
                  />
                  <span className="mr-2">Appartement</span>
                </label>
                <label className="">
                  <input
                    id="typeDuBien"
                    type="radio"
                    name="typeDuBien"
                    value="Local"
                    className="leading-tight mr-3"
                    checked={typeDuBien === "Local"}
                    onChange={handleTypeDuBien}
                  />
                  <span className="mr-2">Local</span>
                </label>
              </div>
            </div>
          </div>
          {(typeDePrestation === "Réparation" ||
            typeDePrestation === "Maintenance" ||
            typeDePrestation === "Remplacement") && (
            <div className="mb-8">
              <h2 className="text-lg font-bold mb-2 px-4">
                Dites-nous en plus sur votre panne:{" "}
                <span className="text-red-500"> * </span>
              </h2>
              <div className="mx-auto w-[96%]">
                <div className="mt-1">
                  <textarea
                    id="Information"
                    name="Information"
                    rows={3}
                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4 w-[92%] ml-auto mr-auto"
                    placeholder="lorem ipsum"
                    value={information}
                    onChange={handleInformation}
                    required
                  />
                </div>
              </div>
            </div>
          )}
          {confirmationModal ? (
            <>
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div className="mt-3">
                      <div className="mt-2 text-center text-center">
                        <h4 className="text-lg font-medium text-gray-800">
                          Votre demande est en cours de traitement
                        </h4>
                        {/*<div className="flex justify-center">
                        <Link to="/tableau-de-bord">
                          <button className=" mt-4 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2">
                            Mon compte
                          </button>
                        </Link>
                      </div>*/}
                        <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                          Votre demande est en cours de traitement. Nous mettons
                          tout en œuvre pour y répondre rapidement. Nous vous
                          remercions de votre patience et nous nous excusons
                          pour tout éventuel retard. Pour toute question,
                          n'hésitez pas à nous contacter.
                        </p>
                        <div className="items-center gap-2 mt-3 sm:flex"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="mb-8">
            <h2 className="text-lg font-bold mb-2 px-4">
              Souhaitez-vous que le technicien fournisse les matériaux?{" "}
              <span className="text-red-500"> * </span>
            </h2>
            <div className="mx-auto w-[96%]">
              <div className="flex gap-8">
                <div className="w-full">
                  <label className="">
                    <input
                      type="radio"
                      id="materiaux"
                      name="materiaux"
                      value="Oui"
                      className="leading-tight mr-3"
                      checked={materiaux === "Oui"}
                      onChange={handleMateriaux}
                    />
                    <span className="mr-2">Oui</span>
                  </label>
                  <label className="">
                    <input
                      id="materiaux"
                      type="radio"
                      name="materiaux"
                      value="Non"
                      className="leading-tight mr-3"
                      checked={materiaux === "Non"}
                      onChange={handleMateriaux}
                    />
                    <span className="mr-2">Non</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="text-lg font-bold mb-2 px-4">
              Votre demande est-elle urgente ?{" "}
              <span className="text-red-500"> * </span>
            </h2>
            <div className="mx-auto w-[96%]">
              <div className="flex gap-8">
                <div className="w-full">
                  <label className="">
                    <input
                      type="radio"
                      id="fin"
                      name="fin"
                      value="Oui"
                      className="leading-tight mr-3"
                      checked={fin === "Oui"}
                      onChange={handleFin}
                    />
                    <span className="mr-2">Oui</span>
                  </label>
                  <label className="">
                    <input
                      id="fin"
                      type="radio"
                      name="fin"
                      value="Non"
                      className="leading-tight mr-3"
                      checked={fin === "Non"}
                      onChange={handleFin}
                    />
                    <span className="mr-2">Non</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={handleRecaptcha}
          />
        </form>
        {error && <p className="text-red-700">{error}</p>}
        <div className="mt-4 flex justify-center items-center  gap-5">
          <button
            onClick={handleSubmit}
            className="submit-button text-base rounded-md  text-white bg-blue-500"
          >
            Valider la demande
          </button>
        </div>
      </div>
    </>
  );
};

export default NewProject;
