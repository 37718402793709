import React from "react";

const LeBonTechnicien2gridLeft = ({ title, subtitle, paragraph, imgSrc }) => {
  return (
    <section className="aventage-tech">
      <div className="container">
        <div className="column">
          <div className="aventage-client-image-container">
              <img src={imgSrc} alt={subtitle} className="image" />
            </div>
        </div>
        <div className="column">
          <h2 className="leBonTech-h2">{title}</h2>
          <p className=" leBonTech-p p-accroche">{subtitle}</p>
          <p className="leBonTech-p">{paragraph}</p>
        </div>
      </div>
    </section>
  );
};

export default LeBonTechnicien2gridLeft;
