import React, { useState } from "react";
import { BreadCrumb } from "../../../Components";
import {
  validateEmail,
  validatePostalCodeInput,
  validateTelephoneInput,
} from "../../../Components/FormValidation";
import ReCAPTCHA from "react-google-recaptcha";
import Autocomplete from "react-google-autocomplete";

const NewClientPro = () => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [postale, setPostale] = useState("");
  const [ville, setVille] = useState("");
  const [telephone, setTelephone] = useState("");
  const [postaleError, setPostaleError] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [error, setError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [contract, setContract] = useState("Non communiqué");
  const [registerError, setRegisterError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [produits, setProduits] = useState("");
  const [annéeInstallation, setAnnéeInstallation] = useState("");
  const [marque, setMarque] = useState("");
  const [référence, setRéférence] = useState("");
  const [photoDuProduit, setPhotoDuProduit] = useState(null);
  const [entretenu, setEntretenu] = useState("");
  const [moisInstallation, setMoisInstallation] = useState("");
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [puissance, setPuissance] = useState("");
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const email = sessionStorage.getItem("email");
  const [emailError, setEmailError] = useState("");
  const apiUrl = 'https://lebontechnicien.net'

  const handleEmail = (event) => {
    setUserEmail(event.target.value.toLowerCase());
    if (!validateEmail(event.target.value.toLowerCase())) {
      setEmailError("Adresse e-mail invalide");
    } else {
      setEmailError(null);
    }
  };

  const handlePostaleChange = (event) => {
    const value = event.target.value;
    setPostale(value);
    setPostaleError(validatePostalCodeInput(value));
  };

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handleTelephoneChange = (event) => {
    const value = event.target.value;
    setTelephone(value);
    setTelephoneError(validateTelephoneInput(value));
  };

  // Creates a new User for the current user pro
  const [autreMarque, setAutreMarque] = useState("");
  const handleAutreMarque = (event) => {
    setAutreMarque(event.target.value);
  };

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    const requestData = new FormData();
    requestData.append("nom", nom);
    requestData.append("prenom", prenom);
    requestData.append("password", "");
    requestData.append("adresse", adresse);
    requestData.append("placeId", placeId);
    requestData.append("postale", postale);
    requestData.append("ville", ville);
    requestData.append("telephone", telephone);
    requestData.append("zone", "");
    requestData.append("contrat", contract);
    requestData.append("email", `${nom}.${prenom}@lebontech.fr`);

    //requestData.append("email", userEmail);
    requestData.append("type", "under_pro");
    requestData.append("creator", sessionStorage.getItem("id"));

    let isValid = true;
    const requiredFields = [
      "nom",
      "prenom",
      "adresse",
      "postale",
      "ville",
      "telephone",
      //"email",
    ];
    for (const field of requiredFields) {
      if (!requestData.get(field)) {
        isValid = false;
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "red";
        const errorText = document.createElement("p");
        errorText.innerText = `Veuillez remplir ce champ.`;
        errorText.style.color = "red";
        input.parentNode.insertBefore(errorText, input);
      } else {
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "initial";
        const errorText = input.previousElementSibling;
        if (
          errorText &&
          errorText.innerText.startsWith("Veuillez remplir ce champ")
        ) {
          errorText.remove();
        }
      }
    }

    if (!isValid) {
      return;
    }

    fetch(`${apiUrl}/register`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data !== "Email already in use") {
          console.log("success");
          window.location.replace("/liste-clients");
        } else {
          if (data === "Email already in use") {
            setRegisterError("Cette adresse e-mail est déjà utilisée");
          } else {
            setError(data);
            console.log(error);
          }
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
  };

  // Creates a new product for the said user
  const formData = {
    //photoDuProduit,
    selectedTypes,
    produits,
    marque,
    référence,
    annéeInstallation,
    entretenu,
    //selectedYearEntretenu,
    selectedYear,
    email,
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModalToggle = () => {
    setIsModalVisible(!isModalVisible);
  };

  //Form to be sent to the handleCreateProduct request
  const formDatas = new FormData();
  if (photoDuProduit && photoDuProduit.length) {
    for (let i = 0; i < photoDuProduit.length; i++) {
      formDatas.append("photoDuProduit", photoDuProduit[i]);
    }
  }
  if (photoDuProduit && photoDuProduit.length) {
    formDatas.append("photoDuProduitName", photoDuProduit.name);
  }
  formDatas.append("typeDuBien", selectedTypes);
  formDatas.append("produits", produits);
  formDatas.append("marque", marque);
  formDatas.append("reference", référence);
  formDatas.append("anneeInstallation", annéeInstallation);
  formDatas.append("entretenu", entretenu);
  formDatas.append("puissance", puissance);
  //formDatas.append("selectedYearEntretenu", selectedYearEntretenu);
  formDatas.append("selectedYear", selectedYear);
  formDatas.append("email", email);

  formDatas.append("moisInstallation", moisInstallation);
  formDatas.append("adresse", adresse);
  formDatas.append("postale", postale);
  formDatas.append("ville", ville);
  formDatas.append("autreMarque", autreMarque);
  formDatas.append("recaptchaValue", recaptchaValue);

  const handleCreateProduct = async (event) => {
    event.preventDefault();
    /* let isValid = true;
    const requiredFields = [
      //"typeDuBien",
      //"produits",
      "marque",
      "référence",
      //"annéeInstallation",
      "photoDuProduit",
      //"entretenu",
      //"selectedYearEntretenu",
      //"selectedYear",

      //"email",
    ];

    // Perform form validation
    for (const fieldName of requiredFields) {
      if (!formData[fieldName]) {
        isValid = false;
        const input = document.querySelector(`[name="${fieldName}"]`);
        input.style.borderColor = "red";
        if (input.type === "checkbox" || input.type === "radio") {
          const label = input.closest("label");
          if (label) {
            const legend = input.parentNode.querySelector("legend");
            if (legend) {
              legend.style.color = "red";
            }
          }
        } else {
          const errorText = document.createElement("p");
          errorText.innerText = `Veuillez remplir ce champ.\n`;
          errorText.style.color = "red";
          input.parentNode.insertBefore(errorText, input);
          input.parentNode.insertBefore(
            document.createElement("br"),
            input.nextSibling
          );
        }
      } else {
        const input = document.querySelector(`[name="${fieldName}"]`);
        input.style.borderColor = "initial";
        if (input.type === "checkbox" || input.type === "radio") {
          const label = input.closest("label");
          if (label) {
            const legend = input.parentNode.querySelector("legend");
            if (legend) {
              legend.style.color = "initial";
            }
          }
        } else {
          const errorText = input.previousElementSibling;
          if (
            errorText &&
            errorText.innerText.startsWith("Veuillez remplir ce champ.")
          ) {
            errorText.remove();
            input.parentNode.removeChild(input.parentNode.lastChild);
          }
        }
      }
    }

    // Validate photoDuProduit
    const photoDuProduitInput = document.querySelector(
      '[name="photoDuProduit"]'
    );
    if (photoDuProduitInput && photoDuProduitInput.files.length === 0) {
      isValid = false;
      photoDuProduitInput.style.borderColor = "red";
      const label = photoDuProduitInput.querySelector("label");
      if (label) {
        const legend = photoDuProduitInput.parentNode.querySelector("legend");
        if (legend) {
          legend.style.color = "red";
        }
      }
      const errorText = document.createElement("p");
      errorText.innerText = `Veuillez sélectionner une photo.\n`;
      errorText.style.color = "red";
      photoDuProduitInput.parentNode.insertBefore(
        errorText,
        photoDuProduitInput
      );
      photoDuProduitInput.parentNode.insertBefore(
        document.createElement("br"),
        photoDuProduitInput.nextSibling
      );
    } else if (photoDuProduitInput) {
      photoDuProduitInput.style.borderColor = "initial";
      const label = photoDuProduitInput.querySelector("label");
      if (label) {
        const legend = photoDuProduitInput.parentNode.querySelector("legend");
        if (legend) {
          legend.style.color = "initial";
        }
      }
      const errorText = photoDuProduitInput.previousElementSibling;
      if (
        errorText &&
        errorText.innerText.startsWith("Veuillez sélectionner une photo.")
      ) {
        errorText.remove();
        photoDuProduitInput.parentNode.removeChild(
          photoDuProduitInput.parentNode.lastChild
        );
      }
    }

    // If form is invalid, return early
    if (!isValid) {
      return;
    }*/

    // If form is valid, proceed with creating a new product
    try {
      setIsLoading(true);
      const res = await fetch(
        `${apiUrl}/prods/create_product`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: formDatas,
        }
      );
      const data = await res.json();
      if (data.success) {
        setPendingList((prevState) => [...prevState, data.product]);
        setIsModalVisible(false);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
    setIsModalVisible(false);
    window.location.reload();
  };

  return (
    <>
      <BreadCrumb
        From={"Compte"}
        To={"Nouveau Client"}
        hrefFrom={"/tableau-de-bord"}
      />

      <div className="bg-white  mx-auto max-w-screen-xl  p-4 rounded-md">
        <h1 className="text-2xl font-bold mb-4 px-4">Nouveau Client</h1>
        <form className=" p-4 rounded-md">
          <div className="mb-4">
            <h2 className="text-lg font-bold mb-2 px-4">
              Information du client
            </h2>
            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="nom"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    value={nom}
                    onChange={(event) => setNom(event.target.value)}
                    name="nom"
                    id="nom"
                    required
                    autoComplete="given-name"
                    className="p-4 mt-1 block w-full rounded-md border-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="prenom"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Prénom <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    name="prenom"
                    value={prenom}
                    onChange={(event) => setPrenom(event.target.value)}
                    required
                    id="prenom"
                    autoComplete="prenom"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="telephone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Téléphone: <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    name="telephone"
                    value={telephone}
                    onChange={handleTelephoneChange}
                    required
                    id="telephone"
                    autoComplete="telephone"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                  {"telephoneError" && (
                    <div className="text-red-500">{telephoneError}</div>
                  )}
                </div>
                {/*<div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email: <span className="text-red-500"> * </span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={userEmail}
                    onChange={handleEmail}
                    required
                    id="email"
                    autoComplete="email"
                    className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />
                  {emailError && email.length > 0 && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                  </div>*/}
              </div>
            </div>

            <div className="bg-white px-4 py-5 sm:p-6">
              <legend className="contents text-sm font-medium text-gray-600 mt-4">
                Client Sous Contrat ?<span className="text-red-500"> * </span>
              </legend>
              <div className="mt-2 gap-4 flex">
                <div className="flex items-center">
                  <input
                    id="oui"
                    name="contract"
                    type="radio"
                    value="oui"
                    onChange={(e) => setContract(e.target.value)}
                    checked={contract === "oui"}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="oui"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Oui
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="non"
                    name="contract"
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    value="non"
                    onChange={(e) => setContract(e.target.value)}
                    checked={contract === "non"}
                  />
                  <label
                    htmlFor="non"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Non
                  </label>
                </div>
              </div>
            </div>

            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="col-span-6 sm:col-span-6 lg:col-span-2 mt-2">
                <label
                  htmlFor="adresse"
                  className="block text-sm font-medium text-gray-700"
                >
                  N° et Nom de rue <span className="text-red-500"> * </span>
                </label>
                {/*<input
                  value={adresse}
                  type="text"
                  name="adresse"
                  id="adresse"
                  onChange={(event) => setAdresse(event.target.value)}
                  required
                  autoComplete="adresse"
                  className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  />*/}
                <Autocomplete
                  className="p-4 border-1 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                  apiKey="AIzaSyDK4L8SN7bnmjDstRZxqkqitqyQufbopLs"
                  name="adresse"
                  id="adresse"
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  onPlaceSelected={(place) => {
                    console.log(place);
                    const address = place.formatted_address;
                    console.log("Selected address:", place);
                    const latitude = place.geometry.location.lat();
                    const longitude = place.geometry.location.lng();
                    const placeIds = place.place_id;
                    setAdresse(address);
                    setPlaceId(placeIds);
                  }}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-4">
                <label
                  htmlFor="ville"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ville <span className="text-red-500"> * </span>
                </label>
                <input
                  type="text"
                  value={ville}
                  onChange={(event) => setVille(event.target.value)}
                  name="ville"
                  required
                  id="ville"
                  autoComplete="ville"
                  className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-4">
                <label
                  htmlFor="postale"
                  className="block text-sm font-medium text-gray-700"
                >
                  Code Postale: <span className="text-red-500"> * </span>
                </label>
                <input
                  type="text"
                  name="postale"
                  value={postale}
                  onChange={handlePostaleChange}
                  required
                  id="postale"
                  autoComplete="postale"
                  className="p-4 mt-1 border-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
                {postaleError && <p style={{ color: "red" }}>{postaleError}</p>}
              </div>
            </div>

            {registerError && <p style={{ color: "red" }}>{registerError}</p>}
          </div>

          

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={handleRecaptcha}
          />
        </form>
        <div className="mt-4 flex justify-end items-center mr-[60px] gap-5">
          <button
            onClick={handleSubmit}
            className="submit-button text-base rounded-md  text-white bg-blue-500"
          >
            Ajouter
          </button>
        </div>
      </div>
    </>
  );
};

export default NewClientPro;
