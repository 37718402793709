import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useStateContext } from "../../../contexts/ContextProvider";

const SparkLineUsers = () => {
  const [techs, setTechs] = useState([]);
  const [users, setUsers] = useState([]);
  const [superviseur, setSuperViseur] = useState([]);
  const { userType } = useStateContext();
  const apiUrl = 'https://lebontechnicien.net'

  // Fetching history data from API and setting the state
  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type }),
        });
        const users = await response.json();
        switch (type) {
          case "Tech":
            setTechs(users);
            break;
          case "Superviseur":
            setSuperViseur(users);
            break;
          case "User":
            setUsers(users);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData("Tech");
    fetchData("Superviseur");
    fetchData("User");
  }, []);

  // User Data
  const userDates = {};
  users.filter(user => user.entreprise === 'non').forEach((datum) => {
    const date = new Date(datum.created_at);
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    if (!userDates[formattedDate]) {
      userDates[formattedDate] = 0;
    }
    userDates[formattedDate] += 1;
  });

  // Pro User Data
  const userProDates = {};
  users.filter(user => user.entreprise === 'oui').forEach((datum) => {
    const date = new Date(datum.created_at);
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    if (!userDates[formattedDate]) {
      userDates[formattedDate] = 0;
    }
    userDates[formattedDate] += 1;
  });  

  //Tech Data
  const techDates = {};
  techs.forEach((datum) => {
    const date = new Date(datum.created_at);
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    if (!techDates[formattedDate]) {
      techDates[formattedDate] = 0;
    }
    techDates[formattedDate] += 1;
  });

  const categories = Object.keys(userDates);
  const userSeries = [
    {
      name: "Clients Particuliers",
      data: Object.values(userDates),
    },
  ];
  const userProSeries = [
    {
      name: "Clients Pro",
      data: Object.values(userDates),
    },
  ];  
  const techSeries = [
    {
      name: "Techniciens",
      data: Object.values(techDates),
    },
  ];
  const options = {
    xaxis: {
      categories,
    },
  };

  return (
    <>
      <Chart
        type="line"
        series={[...userSeries, ...userProSeries, ...techSeries]}
        options={options}
      />
    </>
  );
};

export default SparkLineUsers;
