import React from "react";
import { Helmet } from "react-helmet";

const GestionCookies = () => {
  return (
    <>
    <Helmet>
    <title>Politique de Gestion des Cookies</title>
    <meta
      name="keywords"
      content="Politique de Gestion des Cookies"
    />
    <meta
      name="description"
      content="Politique de Gestion des Cookies"
    />
    <link rel="canonical" href="https://lebontechnicien.net/gestion-cookies" />
  </Helmet>
    <main>
      <section className="pgc leBonTech-section">
        <h1 className="leBonTech-h1">
          Politique de Gestion des Cookies
        </h1>

        <p className="leBonTech-p">
          LeBonTechnicien.fr accorde une grande importance à la protection de la
          vie privée de ses utilisateurs. Dans le cadre de cette politique, nous
          souhaitons vous informer sur l'utilisation des cookies sur notre
          application web et mobile. Cette Politique de Gestion des Cookies
          décrit les types de cookies que nous utilisons, leur finalité et la
          manière dont vous pouvez gérer vos préférences en matière de cookies.
        </p>

        <p className="leBonTech-p">
          En utilisant notre application web et mobile, vous acceptez notre
          utilisation des cookies conformément à cette politique.
        </p>
        <h2 className="leBonTech-h2">1. Qu'est-ce qu'un cookie ?</h2>
        <p className="leBonTech-p">
          Un cookie est un petit fichier texte qui est stocké sur votre
          ordinateur, smartphone ou autre appareil lorsque vous visitez un site
          web ou utilisez une application. Les cookies sont utilisés pour
          reconnaître votre appareil lors de vos visites ultérieures et pour
          améliorer votre expérience en ligne.
        </p>
        <h2 className="leBonTech-h2">2. Types de cookies</h2>
        <p className="leBonTech-p">
          Nous utilisons les types de cookies suivants sur notre application :
        </p>
        <ul>
          <li classname="lebontech-li">
            <strong>Cookies fonctionnels :</strong> Ces cookies sont essentiels
            pour le fonctionnement de notre application et pour vous fournir les
            services que vous demandez. Ils peuvent inclure des cookies qui vous
            permettent de vous connecter à votre compte utilisateur et d'accéder
            aux fonctionnalités de l'application.
          </li>
          <li classname="lebontech-li">
            <strong>Cookies de performance et d'analyse :</strong> Ces cookies
            nous permettent d'analyser l'utilisation de notre application par
            les visiteurs et d'améliorer ses performances. Ils nous aident à
            comprendre comment les utilisateurs interagissent avec notre
            application et à identifier les améliorations possibles.
          </li>
          <li classname="lebontech-li">
            <strong>Cookies de ciblage et de publicité :</strong> Ces cookies
            sont utilisés pour personnaliser les publicités et le contenu qui
            vous sont proposés en fonction de vos intérêts et de votre
            comportement de navigation. Ils permettent également de limiter le
            nombre de fois que vous voyez une publicité et de mesurer
            l'efficacité des campagnes publicitaires.
          </li>
        </ul>
        <h2 className="leBonTech-h2">3. Gestion des cookies</h2>
        <p className="leBonTech-p">
          Vous avez la possibilité de gérer vos préférences en matière de
          cookies à tout moment en modifiant les paramètres de votre navigateur
          ou de votre appareil. Veuillez noter que la désactivation de certains
          cookies peut affecter le fonctionnement de notre application et vous
          empêcher d'accéder à certaines fonctionnalités.
        </p>
        <p className="leBonTech-p">
          Pour modifier vos paramètres de cookies, vous pouvez consulter les
          instructions fournies par le développeur de votre navigateur ou de
          votre appareil.
        </p>
        <h2 className="leBonTech-h2">4. Cookies de tiers</h2>
        <p className="leBonTech-p">
          Nous pouvons également utiliser des cookies de tiers, tels que ceux
          provenant de partenaires publicitaires ou d'outils d'analyse, pour
          améliorer votre expérience en ligne et afficher des publicités
          pertinentes. Ces cookies de tiers sont soumis à leurs propres
          politiques de confidentialité et de gestion des cookies, et
          LeBonTechnicien.fr n'est pas responsable de leur utilisation.
        </p>
        <h2 className="leBonTech-h2">
          5. Mises à jour de la Politique de Gestion des Cookies
        </h2>
        <p className="leBonTech-p">
          Nous nous réservons le droit de modifier cette Politique de Gestion
          des Cookies à tout moment. Les modifications seront publiées sur cette
          page et la date de la dernière mise à jour sera indiquée en haut de la
          Politique. Nous vous encourageons à consulter régulièrement cette page
          pour vous tenir informé de notre manière de gérer les cookies.
        </p>
        <h2 className="leBonTech-h2">6. Contactez-nous</h2>
        <p className="leBonTech-p">
          Si vous avez des questions ou des préoccupations concernant cette
          Politique de Gestion des Cookies, n'hésitez pas à nous contacter en
          utilisant les coordonnées ci-dessous :
        </p>
        <p className="leBonTech-p">
          LeBonTechnicien.fr
          <br />
          Email : contact@lebontechnicien.fr
        </p>
        <p className="leBonTech-p">
          Nous nous engageons à répondre à vos questions et à prendre en compte
          vos préoccupations concernant notre utilisation des cookies. Votre vie
          privée et la protection de vos données sont importantes pour nous, et
          nous nous efforçons de vous fournir une expérience en ligne sûre et
          transparente.
        </p>
      </section>
    </main>
    </>
  );
};

export default GestionCookies;
