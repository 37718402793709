import React, { useState } from "react";

const Tabs = ({ tabs, tabsContent }) => {
const [activeTab, setActiveTab] = useState(tabs[0]);

const handleTabChange = (tab) => {
    setActiveTab(tab);
    };
    return (
        <div className="flex">
            <div className="w-[92%] ml-auto mr-auto">
                <div className="flex border-b border-gray-200">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            className={`px-4 py-2 text-sm font-medium leading-5 text-gray-700 focus:outline-none focus:text-gray-900 ${activeTab === tab ? "bg-white border-2 border-green-500" : "hover:text-gray-900"}`}
                            onClick={() => handleTabChange(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
                <div className="py-6">
                    {tabsContent[tabs.indexOf(activeTab)]}
                </div>
            </div>
        </div>
    );
};

export default Tabs;