import React, { useState, useEffect } from "react";
import { FaAngleRight } from "react-icons/fa";
//import "../../Styles/Documentation.css";
import documentationMenueData from "../../Constants/documentationMenueData";
//import menuData from "../../Constants/menuData";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet";
import { Banner } from "../../Components";
import googlePlay from "../../Assets/media/google-app.png";
import appStore from "../../Assets/media/app-store.png";
import phone from "../../Assets/media/app-phone.png";
import { Link, animateScroll as scroll } from "react-scroll";

const DocumentationPage = () => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [activeTitle, setActiveTitle] = useState("");

  const handleScroll = () => {
    const sections = documentationMenueData
      .map((menuItem) => {
        const element = document.querySelector(
          `#${menuItem.title.toLowerCase().replace(/ /g, "-")}`
        );
        if (element) {
          return {
            id: menuItem.title.toLowerCase().replace(/ /g, "-"),
            yOffset: element.getBoundingClientRect().top,
            height: element.clientHeight,
          };
        }
        console.log("elementid", element);
        return null;
      })
      .filter((section) => section !== null);

    if (sections.length > 0) {
      const currentYOffset = window.pageYOffset;

      for (const section of sections) {
        if (
          currentYOffset >= section.yOffset &&
          currentYOffset < section.yOffset + section.height
        ) {
          setActiveSection(section.id);
          setActiveSubmenu(
            documentationMenueData.findIndex(
              (menuItem) =>
                menuItem.title.toLowerCase().replace(/ /g, "-") === section.id
            )
          );
          break; // Exit the loop after finding the active section
        }
      }
    }
  };

  const toggleSubmenu = (index,) => {
    console.log("index", index);
    setActiveSubmenu(index === activeSubmenu ? null : index);
    console.log(activeSubmenu);
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 100); // Adjust the debounce delay as needed
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
      debouncedHandleScroll.cancel(); // Cancel the debounced function on unmount
    };
  }, []);

  const renderItems = (items) => {
    return (
      <ul>
        {items.map((item, itemIndex) => (
          <div key={itemIndex}>
            {item.type === "paragraph" && <p>{item.content}</p>}
            {item.type === "list" && (
              <ul>
                {item.items.map((listItem, listItemIndex) => (
                  <li key={listItemIndex}>{listItem}</li>
                ))}
              </ul>
            )}
            {/*{item.type === "image" && (
              <img src={item.src} alt={`Example ${itemIndex}`} />
            )}*/}
          </div>
        ))}
      </ul>
    );
  };

  const renderSubsections = (subsections, headerLevel) => {
    const Header = `h${headerLevel}`;
    return subsections.map((subsection, subsectionIndex) => (
      <div key={subsectionIndex}>
        <Header
          style={{ marginBottom: "20px", marginTop: "20px" }}
          id={subsection.title.toLowerCase().replace(/ /g, "-")}
        >
          {subsection.title}
        </Header>
        {subsection.items && renderItems(subsection.items)}
        {subsection.subsections &&
          renderSubsections(subsection.subsections, headerLevel + 1)}
      </div>
    ));
  };

  return (
    <div className="App">
      <Helmet>
        <title>LeBonTechnicien.fr</title>
        <meta name="keywords" content="LeBonTechnicien.fr" />
        <meta
          name="description"
          content="Téléchargez notre appli LeBonTechnicien.fr et profitez de l'expertise de l'élite des professionnels en énergies renouvelables pour un entretien paisible de vos équipements d'énergie renouvelable !"
        />
        <link rel="canonical" href="https://lebontechnicien.net/" />
      </Helmet>

      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">
                  Investissez dans
                  <br /> un avenir durable
                </h1>
                <p className="leBonTech-p">
                  Téléchargez notre appli et profitez de l'expertise de l'élite
                  des professionnels en énergies renouvelables pour un entretien
                  paisible de vos équipements !
                </p>
                <div className="banner-btns">
                  <a
                    href="https://apps.apple.com/fr/app/lebontech/id6450009896"
                    className="leBonTech-a item-btn btn-ghost btn-dark"
                  >
                    <img src={appStore} alt="App Store" />
                    App Store
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.devinov.LeBonTechnicienUser"
                    className="leBonTech-a item-btn btn-ghost btn-dark"
                  >
                    <img src={googlePlay} alt="Google Play" />
                    Google Play
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img
                  src={phone}
                  alt="Illustration application LeBonTechnicien"
                />
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <div className="Main-Wrapper">
        <div className="Aside-Wrapper">
          <aside className="Aside">
            <div className="Aside-content">
              <ul className="Aside-content-ul">
                {documentationMenueData[0]?.sections.map((menuItem, index) => (
                  <li
                    key={index}
                    className={`Aside-content-li ${
                      activeSection ===
                      menuItem.title.toLowerCase().replace(/ /g, "-")
                        ? "active"
                        : ""
                    }`}
                  >
                    <a
                      className="Aside-content-ul-a"
                      href={`#${menuItem.title
                        .toLowerCase()
                        .replace(/ /g, "-")}`}
                      onClick={(e) => {
                        if (activeSubmenu !== index) {
                          e.preventDefault();
                          toggleSubmenu(index);
                          setActiveTitle(menuItem.title.toLowerCase().replace(/ /g, "-"));
                        }
                      }}
                    >
                      {menuItem.title}
                      {menuItem.subsections &&
                        menuItem.subsections.length > 0 && (
                          <FaAngleRight
                            className={`menuItems-icon ${
                              activeSubmenu === index ? "open" : ""
                            }`}
                            style={{
                              transform:
                                activeSubmenu === index
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                            }}
                          ></FaAngleRight>
                        )}
                    </a>
                    {menuItem.subsections && activeSubmenu === index && (
                      <div className="subItems">
                        <ul className="subItems-list">
                          {menuItem.subsections.map((subSection, subIndex) => (
                            <li key={subIndex} className="subItem">
                              <a
                                href={`#${subSection.title
                                  .toLowerCase()
                                  .replace(/ /g, "-")}`}
                                  style={{
                                    color: "black", // Default text color
                                    textDecoration: "none", // Remove underline
                                    transition: "color 0.3s", // Transition effect for smooth color change
                                  }}
                                  onMouseEnter={(e) => {
                                    e.target.style.color = "#0f8203"; // Change text color to gray on hover
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.color = "black"; // Revert text color to default on hover out
                                  }}
                              >
                                {subSection.title}
                              </a>
                              {subSection.subsections && (
                                <ul className="subItems-subsection">
                                  {subSection.subsections.map(
                                    (subSubSection, subSubIndex) => (
                                      <li
                                        key={subSubIndex}
                                        className="dropdown"
                                        style={{ marginLeft: "2px" }}
                                      >
                                        <a
                                          href={`#${subSubSection.title
                                            .toLowerCase()
                                            .replace(/ /g, "-")}`}
                                          style={{
                                            color: "black", // Default text color
                                            textDecoration: "none", // Remove underline
                                            transition: "color 0.3s", // Transition effect for smooth color change
                                          }}
                                          onMouseEnter={(e) => {
                                            e.target.style.color = "#0f8203"; // Change text color to gray on hover
                                          }}
                                          onMouseLeave={(e) => {
                                            e.target.style.color = "black"; // Revert text color to default on hover out
                                          }}
                                        >
                                          {subSubSection.title}
                                        </a>
                                        {subSubSection.subsections && (
                                          <ul className="subItems-subsection">
                                            {subSubSection.subsections.map(
                                              (
                                                subSubSubSection,
                                                subSubSubIndex
                                              ) => (
                                                <li
                                                  key={subSubSubIndex}
                                                  className="dropdown"
                                                  style={{ marginLeft: "4px" }}
                                                >
                                                  <a
                                                    href={`#${subSubSubSection.title
                                                      .toLowerCase()
                                                      .replace(/ /g, "-")}`}
                                                    style={{
                                                      color: "black", // Default text color
                                                      textDecoration: "none", // Remove underline
                                                      transition: "color 0.3s", // Transition effect for smooth color change
                                                    }}
                                                    onMouseEnter={(e) => {
                                                      e.target.style.color =
                                                        "#0f8203"; // Change text color to gray on hover
                                                    }}
                                                    onMouseLeave={(e) => {
                                                      e.target.style.color =
                                                        "black"; // Revert text color to default on hover out
                                                    }}
                                                  >
                                                    {subSubSubSection.title}
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </aside>
        </div>

        <div className="Main-Content">
          <div className="Main-Content-Scroll">
            {documentationMenueData[0]?.sections.map(
              (section, sectionIndex) => (
                <div key={sectionIndex}>
                  <h1
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                    id={section.title.toLowerCase().replace(/ /g, "-")}
                  >
                    {section.title}
                  </h1>
                  {renderItems(section.items)}
                  {renderSubsections(section.subsections, 2)}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentationPage;
