import React from 'react'
import emailPic from '../../../Assets/Hands.jpg'

const UserPending = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-transparent w-[70%] ml-auto mr-auto">
          {/*<img className="w-full lg:w-1/2 mb-4" src={emailPic} alt="email confirm" />*/}
          <h2 className="text-3xl font-medium text-gray-700 text-center">Validation de votre compte en cours par l'administrateur</h2>
          <p className="mt-4 text-lg text-gray-700 text-center">
            Votre compte n’est pas encore validé par l’admin
    
          </p>
    
          {/*<button
            className=" mt-12 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2"
            onClick={() => window.location.href='/connexion'}
          >
            Connectez-vous
    </button>*/}
        </div>
      )
}

export default UserPending