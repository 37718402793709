import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components";
import demande from "../../../Assets/Icons/demande-intervention.png";
import PAC from "../../../Assets/Icons/PAC.png";
import search from "../../../Assets/Icons/recherche-tech.png";
import reclamation from "../../../Assets/Icons/reclamation.png";
import project from "../../../Assets/Icons/suivi-projet.png";
import contract from "../../../Assets/Icons/contrat-maintenance.png";
import clients from "../../../Assets/Icons/mes-clients.png";

const TechCompte = () => {

  const techPro = sessionStorage.getItem("TechPro");



  return (
    <div className="bg-white">
      <BreadCrumb From={""} To={"Mon Compte"} hrefFrom={"#"} />

      {techPro === "oui" && (
        <div className="max-w-screen-xl  ml-auto mr-auto border border-gray-200 rounded-lg mt-10 mb-[8px] grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 p-10">

          <div className="flex justify-center  p-5  h-[200px]">
            <div className="m-auto cursor-pointer">
              <a href="/user-history">
                <img
                  src={project}
                  alt="Suivre mes demandes d'intervention"
                  className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
                />
                <h3 className="text-center text-[16px] font-bold">
                  Projets en cours
                </h3>
              </a>
            </div>
          </div>

          <div className="font-Audrey flex justify-center bg-white p-5 h-[200px] border-l-2 border-r-2 border-gray-200">
            <div className="m-auto cursor-pointer">
              <a href="/recherche-pro">
                <img
                  src={project}
                  alt="Suivre mes demandes d'intervention"
                  className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
                />
                <h3 className="text-center text-[16px] font-bold">
                  Projets non planifiés
                </h3>
              </a>
            </div>
          </div>

          <div className="flex justify-center  p-5  h-[200px]">
            <div className="m-auto cursor-pointer">
              <a href="/mes-equipes">
                <img
                  src={clients}
                  alt="Mes Equipes"
                  className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
                />
                <h3 className="text-center text-[16px] font-bold">
                  Equipes / planning
                </h3>
              </a>
            </div>
          </div>  


          {/*<div className="flex justify-center  p-5  h-[200px]">
            <div className="m-auto cursor-pointer">
              <a href="/reclamations">
                <img
                  src={contract}
                  alt="Planning"
                  className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
                />

                <h3 className="text-center text-[16px] font-bold">
                  Planning
                </h3>
              </a>
            </div>
      </div>*/}
        </div>
      )}

    </div>
  );
};

export default TechCompte;
