import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Cards from "../../../Components/Dashboard/Cards";

const UserProProductList = () => {
  const [groupedHistory, setGroupedHistory] = useState([]);
  const [userNom, setUserNom] = useState("");
  const [userPrenom, setUserPrenom] = useState("");

  const { id } = useParams();
  const apiUrl = "https://lebontechnicien.net";

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const foundIntervention = data.find(
          (intervention) => intervention._id === id
        );
        setUserNom(foundIntervention?.nom || "");
        setUserPrenom(foundIntervention?.prenom || "");
        console.log("foundIntervention", foundIntervention);
        console.log("nom", userNom);
        console.log("prenom", userPrenom);
        fetchHistory(foundIntervention?.nom, foundIntervention?.prenom);
      })
      .catch((error) => console.error(error));
  }, [id]);

  const groupBy = (array, ...keys) => {
    return array.reduce((result, currentValue) => {
      const key = keys.map(k => currentValue[k]).join('-');
      (result[key] = result[key] || []).push(currentValue);
      return result;
    }, {});
  };

  const fetchHistory = (nom, prenom) => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const fetchedInterventions = data.filter(
          (intervention) =>
            intervention.nom === nom && intervention.prenom === prenom
        );
        const groupedInterventions = groupBy(
          fetchedInterventions,
          "typeDeProjet",
          "marque",
          "puissance"
        );
        setGroupedHistory(groupedInterventions);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className=" mt-10 sm:mt-0 max-w-screen-xl ml-auto mr-auto">
      <div className="flex justify-between w-full mb-10">
        <h3 className=" mt-10 text-lg font-medium leading-6 text-gray-900 title-font-h3">
          Liste Des Appareils
        </h3>
      </div>
      <Cards groupedHistory={groupedHistory} />
    </div>
  );
};

export default UserProProductList;
