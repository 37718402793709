import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useStateContext } from "../../../contexts/ContextProvider";

const LineChart = () => {
  const [history, setHistory] = useState([]);
  const { userType } = useStateContext();
  const apiUrl = 'https://lebontechnicien.net'
    
// Fetching history data from API and setting the state
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(
          userType === "Tech"
            ? data.filter(
                (item) => item.client_accept === sessionStorage.getItem("id")
              )
            : data.filter((item) => item.type === "Demande d'intervention")
        );
      })
      .catch((error) => console.error(error));
  }, []);

  // Counting the occurrences of each date in the history data
  const dates = {};
  history.forEach((datum) => {
    const date = new Date(datum.created_at);
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    if (!dates[formattedDate]) {
      dates[formattedDate] = 0;
    }
    dates[formattedDate] += 1;
  });

  // Generating chart data
  const categories = Object.keys(dates);
  const series = [
    {
      name: "Projets",
      data: Object.values(dates),
    },
  ];
  const options = {
    xaxis: {
      categories,
    },
  };

  return <Chart type="line" series={series} options={options} />;
};

export default LineChart;
