import React, { useEffect, useState } from "react";
import { BreadCrumb, ContractCard } from "../../../Components";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";
import Select from "react-select";

const AdminContractList = () => {
  const [history, setHistory] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDataRequest, setUserDataRequest] = useState([]);
  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const { userType } = useStateContext();
  const apiUrl = 'https://lebontechnicien.net'

  //Handles the Fetching previous interventions
  const fetchData = async () => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        const filteredData = data.filter(
          (item) => item.type === "Demande de Maintenance" && item.Paiement ==="Paiement effectué"
        );
        setHistory(filteredData.reverse());
        setUserDataRequest(history.demandeur);
        setFilteredData(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        const filteredPendingList = data.filter(
          (item) => item._id === history.created_for
        );
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  // Get unique status values from history
  const getStatusOptions = () => {
    const uniqueStatusValues = [...new Set(history.map((item) => item.status))];
    return uniqueStatusValues.map((status) => ({
      value: status,
      label: getStatusLabel(status), // Customize labels as needed
    }));
  };

  // Function to get a label for each status (customize as needed)
  const getStatusLabel = (status) => {
    switch (status) {
      case "Pending":
        return "En cours de traitement";
      case "closed":
        return "Cloturé";
      case "closed_tech":
        return "Cloturé par technicien";
      case "claim":
        return "Réclamation";
      case "Denied_payment":
        return "Devis refusé";
      case "Accepted_payment":
        return "Devis accepté";
      case "Paymen_User":
        return "Devis";
      case "Accepted":
        return "Accepté";
      case "claim_fix":
        return "Résolu";
      case "Email Pending":
        return "Email non validé";
      case "Confirmed":
        return "validé";
      case "In progress":
        return "En cours de vérification";
      case "Denied":
        return "Réfusé";
      case "solved":
        return "Résolu";
      case "unsolved":
        return "Non résolu";
      case "Full":
        return "Complète";
      case "litige_fixed":
        return "Litige résolu";
      case "claime_fixed":
        return "Réclamation résolu";
      case "annulled":
        return "Annulée";
      case "admin_accept":
        return "Contrat en attente de signature";
      case "Tech_onhold":
        return "En attente";
        case "admin_denie":
          return "Contrat refusé";
      default:
        return status;
    }
  };

  

  const filterData = () => {
    let filtered = [...history]; // Make a copy of history to avoid mutating state directly

    // Step 3: Check and filter by status
    if (selectedStatus) {
      filtered = filtered.filter(
        (item) => item.status === selectedStatus.value
      );
    }

    // Check if demandeur exists in history and includes the searchInput
      if (searchInput !== "") {
        filtered = filtered.filter(
          (item) =>
          item.demandeur &&
          (item.demandeur.toLowerCase().includes(searchInput.toLowerCase()) 
          || item.nom_Pro.toLowerCase().includes(searchInput.toLowerCase()) 
          || item.prenom_Pro.toLowerCase().includes(searchInput.toLowerCase()) 
          || (item.nom_Pro.toLowerCase() + " " + item.prenom_Pro.toLowerCase()).includes(searchInput.toLowerCase()))
          
      );
    }

    return filtered;
  };

  return (
    <div className="mb-10">
      <BreadCrumb
        From={"Compte"}
        To={"Contrat de maintenance"}
        hrefFrom={"/tableau-de-bord-admin"}
      />
      <div className="mt-10 sm:mt-0 w-[92%] ml-auto mr-auto ">
        <div className="flex justify-between w-full mb-10">
          <h3 className="text-lg font-medium leading-6 text-gray-900 title-font-h3">
            Liste Des Contrats
          </h3>
        </div>
        {/*<div className="px-4 sm:px-0 ">
          {history.map((item, index) => (
            <div key={index}>
              <div className="w-full mb-5  border rounded-lg p-8 bg-white">
                <div className="flex justify-between">
                  <div>
                    {pendingList.map((product, index) => (
                      <p key={index}>
                        <span className="font-bold">Contrat pour</span>:{" "}
                        {product.typeDuBien} {product.marque}
                      </p>
                    ))}

                    <p>
                      <span className="font-bold">Raison Social client: </span>
                      {item.raisonSocialPro}
                    </p>
                    <p>
                      <span className="font-bold">Nom de client: </span>
                      {item.nom} {item.prenom}
                    </p>
                    <p>
                      <span className="font-bold">Date de début: </span>
                      {new Date(item.selectedDate).toLocaleDateString("fr-FR")}
                    </p>
                    <p>
                      <span className="font-bold">Statut:</span> {""}
                      {(() => {
                        switch (item.status) {
                          case "Pending":
                            return "En attente de Validation";
                          case "closed":
                            return "Validé";
                          case "closed_tech":
                            return "Cloturé par technicien";
                          case "claim":
                            return "Réclamation";
                          case "Denied_payment":
                            return "Devis non accepté";
                          case "Accepted_payment":
                            return "Devis accepté";
                          case "Paymen_User":
                            return "Devis";
                          case "Accepted":
                            return "Accepté par client";
                          case "solved":
                            return "Résolu";
                          case "unsolved":
                            return "Non résolu";
                          case "Full":
                            return "Complète";
                          case "claime":
                            return "Réclamation résolu";
                          case "litige_fixed":
                            return "Litige résolu";
                          case "annulled":
                            return "Annulée";
                          case "admin_accept":
                            return "Contrat validé";
                          default:
                            return item.status;
                        }
                      })()}
                    </p>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate(`/contrats/${item._id}`)}
                      //onClick={handletechCloseModal}
                      className=" 
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-green-700 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-green-600 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-green-500 
                      focus:ring-offset-2
                      mt-4
                    "
                    >
                      Consulter le contrat
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
                    </div>*/}
        <div className="flex flex-wrap mb-4 justify-between">
          <div className="!w-[74%]">
            <input
              type="text"
              placeholder="Rechercher par nom..."
              className="p-[7px] w-full rounded-md  text-gray-700  border"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <div className="w-[25%]">
            <Select
              options={getStatusOptions()}
              isClearable
              placeholder="Filtrer par statut"
              onChange={(selectedOption) => setSelectedStatus(selectedOption)}
            />
          </div>
        </div>
        <ContractCard history={filterData()} userType={userType} />
      </div>
    </div>
  );
};

export default AdminContractList;
