import React from 'react';
import appstore from "../../Assets/app-store.png";
import googleplay from "../../Assets/google-play.png";

const DownloadButtons = ({ appStoreLink, googlePlayLink }) => {
  return (
    <div className="button-container-tiny">
        <div className='button-div'>
            <a href={googlePlayLink} target="_blank" rel="noopener noreferrer" style={{display: "flex", gap: "10px"}}>
                <img src={googleplay} alt="googleplay" />
                <p className='button-text' style={{marginTop: "4px"}}>Google Play</p>
            </a>
        </div>
        <div className='button-div'>
        <a href={appStoreLink} target="_blank" rel="noopener noreferrer" style={{display: "flex", gap: "10px"}}>
                <img src={appstore} alt="appstore" />
                <p className='button-text' style={{marginTop: "4px"}}>App Store</p>
            </a>
        </div>
    </div>
  )
}

export default DownloadButtons;
