import intervention from "../Assets/demande-intervention.png";
import technicien from "../Assets/recherche-tech.png";
import Suivie from "../Assets/suivi-projet.png";
import Appareil from "../Assets/PAC.png";
import contrats from "../Assets/contrat-maintenance.png";
import réclamations from "../Assets/reclamation.png";

const UserFonc = [
    {
        id: 1,
        title: "Demander une intervention",
        img: intervention     
    },
    {
        id: 2,
        title: "Trouver un technicien ",
        img: technicien,        
    },
    {
        id: 3,
        title: "Suivre mes demandes d'intervention ",
        img: Suivie,     
    },
    {
        id: 4,
        title: "Ajouter un Appareil ",
        img: Appareil,  
    },
    {
        id: 5,
        title: "Mes contrats de maintenance",
        img: contrats,        
    },
    {
        id: 6,
        title: "Mes réclamations",
        img: réclamations,        
    },    
];
export default UserFonc;