import React, { useState, useEffect } from "react";
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";

function CRUDTable({
  data,
  link,
  pencilIcon,
  checkIcon,
  eyeIcon,
  deleteIcon,
  onDataChange,
  handleDeleteProduct,
  checkIconaccept,
  technicianid,
  checkIconacceptAdmin,
  CheckIcon,
  selectButton,
  closeIcon,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showOdifyModal, setShowOdifyModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortKey, setSortKey] = useState(null);
  const [sortDirection, setSortDirection] = useState(1);
  const [filter, setFilter] = useState("");


  // Function to sort the data
  const sortData = (key) => {
    if (sortKey === key) {
      setSortDirection(sortDirection * -1);
    } else {
      setSortKey(key);
      setSortDirection(1);
    }
  };

  // Filtering the data based on the filter value
  const filteredData = data
    ? data.filter((item) =>
        Object.values(item)
          .filter((val) => typeof val === "string")
          .join("")
          .toLowerCase()
          .includes(filter.toLowerCase())
      )
    : [];

  // Sorting the filtered data
  const sortedData = [...filteredData].sort((a, b) => {
    if (a[sortKey] < b[sortKey]) {
      return -1 * sortDirection;
    }
    if (a[sortKey] > b[sortKey]) {
      return 1 * sortDirection;
    }
    return 0;
  });

  const handleDeleteConfirmation = () => {
    const newData = data.filter((item) => item.id !== selectedId);
    setSelectedId(null);
    setShowModal(false);
    // callback or pass the new data to parent component
    handleDeleteProduct(newData);
  };

  const handleModify = (item) => {
    setSelectedItem(item);
    setShowOdifyModal(true);
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteProduct(selectedId);
    setShowModal(false);
    setSelectedId(null);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  const handleModifyConfirmation = () => {
    const newData = data.map((item) => {
      if (item.id === selectedItem.id) {
        return { ...item, ...selectedItem };
      }
      return item;
    });
    onDataChange(newData);
    setSelectedItem(null);
    setShowOdifyModal(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  //const headers = Object.keys(data);

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="overflow-hidden rounded-lg mb-10 w-full">
      <div className="mb-5">
        <input
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="w-full rounded-lg p-3 text-gray-600 bg-gray-100 border "
          placeholder="Recherche"
        />
      </div>
      <div className="overflow-x-auto border rounded-lg">
        <table className="w-full border-collapse bg-white text-left text-sm text-gray-500 border-gray-200">
          {data?.length !== 0 ? (
            <>
              <thead className="bg-[#f0fdf4]">
                <tr className="hover:bg-[#f0fdf4] border-b border-gray-300">
                  {Object.keys(data[0]).map((key, index) => {
                    if (
                      key === "deleteIcon" ||
                      key === "id" ||
                      key === "userID" ||
                      key === "Type_demande" ||
                      key === "checkIconAccept" ||
                      key === "Technician_id" ||
                      key === "FilterDate"
                    ) {
                      return;
                    }
                    return (
                      <th
                        scope="col"
                        className="px-6 py-4  text-gray-900 cursor-pointer text-cente font-bold"
                        key={index}
                        onClick={() => sortData(key)}
                      >
                        <div className="flex gap-[4px]">
                          {key}
                          {sortKey === key ? (
                            sortDirection === 1 ? (
                              <RiArrowUpSLine className="mt-[4px]" />
                            ) : (
                              <RiArrowDownSLine className="mt-[4px]" />
                            )
                          ) : null}
                        </div>
                      </th>
                    );
                  })}
                  <th
                    scope="col"
                    className="px-6 py-4 font-medium text-gray-900 text-center"
                    key={Object.keys(data).length + 1}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                {currentData === 0 ? (
                  <h2 className="hover:bg-gray-50 p-5 text-center">
                    {" "}
                    No data to show
                  </h2>
                ) : (
                  <>
                    {currentData.map((item, index) => (
                      <tr
                        className="hover:bg-[#f0fdf4] border-b border-gray-300;"
                        key={index}
                      >
                        {Object.entries(item)
                          .filter(
                            ([key, value]) =>
                              key !== "deleteIcon" &&
                              key !== "id" &&
                              key !== "userID" &&
                              key !== "Type_demande" &&
                              key !== "checkIconAccept" &&
                              key !== "Technician_id" && 
                              key !== "FilterDate"
                          )
                          .map(([key, value], index) => (
                            <td
                              className={`px-6 py-4 ${
                                value === "Pending"
                                  ? "text-yellow-500"
                                  : value === "closed"
                                  ? "text-green-600"
                                  : value === "closed_tech"
                                  ? "text-green-400"
                                  : value === "claim"
                                  ? "text-red-600"
                                  : value === "Denied_payment"
                                  ? "text-red-600"
                                  : value === "Accepted_payment"
                                  ? "text-green-400"
                                  : value === "accepted"
                                  ? "text-green-400"
                                  : value === "solved"
                                  ? "text-green-600"
                                  : value === "unsolved"
                                  ? "text-red-600"
                                  : "text-gray-600"
                              }`}
                              key={index}
                            >
                              {value === "Pending"
                                ? "En cours"
                                : value === "closed"
                                ? "Cloturé"
                                : value === "closed_tech"
                                ? "Cloturé par technicien"
                                : value === "claim"
                                ? "Réclamation"
                                : value === "Denied_payment"
                                ? "Devis refusé"
                                : value === "Accepted_payment"
                                ? "Devis accepté"
                                : value === "Paymen_User"
                                ? "Devis"
                                : value === "Accepted"
                                ? "Accepté"
                                : value === "claim_fix"
                                ? "Résolu"
                                : value === "Email Pending"
                                ? "Email non validé"
                                : value === "Confirmed"
                                ? "validé"
                                : value === "In progress"
                                ? "En cours de vérification"
                                : value === "Denied"
                                ? "Réfusé"
                                : value === "solved"
                                ? "Résolu"
                                : value === "unsolved"
                                ? "Non résolu"
                                : value === "Full"
                                ? "Complète"
                                : value === "litige_fixed"
                                ? "Litige résolu"
                                : value === "claime_fixed"
                                ? "Réclamation résolu"
                                : value === "annulled"
                                ? "Annulée"
                                : value === "Accepté par l'admin"
                                ? "admin_accept"
                                : value === "Tech_onhold"
                                ? "En attente"
                                : value}
                            </td>
                          ))}

                        <td
                          className="flex justify-center gap-4 px-6 py-4"
                          key={Object.values(data).length + 1}
                        >
                          {pencilIcon ? (
                            <>
                              {typeof pencilIcon === "function" ? (
                                <button
                                  onClick={() => handleDeleteClick(item.id)}
                                >
                                  {pencilIcon(item.id)}
                                </button>
                              ) : (
                                <button>{pencilIcon}</button>
                              )}
                            </>
                          ) : checkIcon ? (
                            <button /*onClick={() => handleModify(item)}*/>
                              {checkIcon}
                            </button>
                          ) : checkIconaccept ? (
                            <>
                              {typeof checkIconaccept === "function" ? (
                                <button>
                                  {checkIconaccept(item.id, item.Technician_id)}
                                </button>
                              ) : (
                                <button>{checkIconaccept}</button>
                              )}
                            </>
                          ) : checkIconacceptAdmin ? (
                            <>
                              {typeof checkIconacceptAdmin === "function" ? (
                                <button>{checkIconacceptAdmin(item.id)}</button>
                              ) : (
                                <button>{checkIconacceptAdmin}</button>
                              )}
                            </>
                          ) : null}
                          {typeof closeIcon === "function" ? (
                            <button onClick={() => handleDeleteClick(item.id)}>
                              {closeIcon(item.id, item.Technician_id)}
                            </button>
                          ) : (
                            <button>{closeIcon}</button>
                          )}
                          {deleteIcon && (
                            <>
                              {typeof deleteIcon === "function" ? (
                                <button
                                  onClick={() => handleDeleteClick(item.id)}
                                >
                                  {deleteIcon(item.id, item.Technician_id)}
                                </button>
                              ) : (
                                <button>{deleteIcon}</button>
                              )}
                            </>
                          )}
                          {eyeIcon ? (
                            <>
                              {typeof eyeIcon === "function" ? (
                                <button>
                                  <Link to={link + item.id}>
                                    {eyeIcon(item.id, item.Technicians)}
                                  </Link>
                                </button>
                              ) : (
                                <button
                                  onClick={() => this.handleView(item.id)}
                                >
                                  <Link to={link + item.id}>{eyeIcon}</Link>
                                </button>
                              )}
                            </>
                          ) : typeof selectButton === "function" ? (
                            <button>{selectButton(item.id, item.email)}</button>
                          ) : (
                            <button>{selectButton}</button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </>
          ) : (
            <div className="h-[50px]">
              <h1 className="text-center mt-8"> Aucune donnée à afficher </h1>
            </div>
          )}
        </table>

        {/* {showModal ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-40"
                            onClick={() => setShowModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3 sm:flex">
                                    <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-red-600"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                          Êtes-vous sûr de vouloir supprimer?
                                        </h4>
                                        <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                                          En cliquant sur "Supprimer", vous supprimerez définitivement le champ sélectionné. Cette action ne peut pas être annulée. Veuillez vous assurer que vous voulez continuer avant de procéder.
                                        </p>
                                        <div className="items-center gap-2 mt-3 sm:flex">
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                                onClick={() => handleConfirmDelete()}
                                            >
                                                Supprimer
                                            </button>
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-green-600 focus:ring-2"
                                                onClick={() => handleCancelDelete()}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
             ) : null} */}

        {showOdifyModal && (
          <div className="fixed top-0 left-22 h-full w-[75%] flex items-center justify-center gap-2 ">
            <div className="relative z-50">
              <div className="bg-white rounded-lg p-6 shadow">
                <p className="text-lg mb-10">Modifier l'élément sélectionné</p>
                <form>
                  {Object.keys(data[0]).map((key, index) => {
                    return (
                      <input
                        className=" text-sm w-22 ml-[8px]"
                        type="text"
                        value={selectedItem ? selectedItem[key] : ""}
                        onChange={(e) =>
                          setSelectedItem({
                            ...selectedItem,
                            [key]: e.target.value,
                          })
                        }
                        key={index}
                      />
                    );
                  })}
                </form>
                <div className="flex ml-auto mr-auto mt-10">
                  <button
                    className="bg-gray-300 text-black py-2 px-4 rounded-lg mr-2"
                    onClick={() => setShowOdifyModal(false)}
                  >
                    Annuler
                  </button>
                  <button
                    className="bg-green-500 text-white py-2 px-4 rounded-lg"
                    onClick={handleModifyConfirmation}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {data.length !== 0 ? (
          <div className="flex items-center justify-between border-t  bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
              <a
                href="#"
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </a>
              <a
                href="#"
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Affichage de{" "}
                  <span className="font-medium">{indexOfFirstItem + 1}</span> à{" "}
                  <span className="font-medium">{indexOfLastItem}</span> des{" "}
                  <span className="font-medium">{data.length}</span> résultats
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm gap-1"
                  aria-label="Pagination"
                >
                  {Array.from(
                    { length: Math.ceil(data.length / itemsPerPage) },
                    (_, i) => (
                      <a
                        key={i}
                        href="#"
                        title="Link"
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 leading-5 transition duration-150 ease-in-out ${
                          currentPage === i + 1
                            ? "relative  inline-flex items-center border border-green-500 bg-green-50 px-4 py-2 text-sm font-medium text-green-600 "
                            : ' bg-green-50 border-green-500 text-green-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                        }`}
                        onClick={() => handlePageChange(i + 1)}
                      >
                        {i + 1}
                      </a>
                    )
                  )}
                </nav>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CRUDTable;
