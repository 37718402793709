import React from "react";
import { Helmet } from "react-helmet";

const Cgu = () => {
  return (
    <>
      <Helmet>
        <title>Conditions générales de ventes - LeBonTechnicien.fr</title>
        <meta
          name="keywords"
          content="Conditions générales de ventes LeBonTechnicien.fr"
        />
        <meta
          name="description"
          content="Conditions générales de ventes"
        />
        <link rel="canonical" href="https://lebontechnicien.net/cgu" />
      </Helmet>
      <main>
        <section className="pdc leBonTech-section">
          <h1 className="leBonTech-h1">
            Conditions Générales d'Utilisation
          </h1>
          <p className="leBonTech-p">
            Bienvenue sur l'application LeBonTechnicien.fr, qui met en relation
            les particuliers et les professionnels experts en installations et
            réparations d'équipements d'énergie renouvelable. Veuillez lire
            attentivement les présentes Conditions Générales d'Utilisation (CGU)
            avant d'utiliser notre application web et mobile. En utilisant
            l'application LeBonTechnicien.fr, vous acceptez d'être lié par les
            présentes CGU. Si vous n'êtes pas d'accord avec tout ou partie des
            CGU, veuillez ne pas utiliser l'application.
          </p>
          <h2 className="leBonTech-h2">Article 1 - Définitions</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">1.1.</span> "Application"
                désigne l'application web et mobile LeBonTechnicien.fr.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">1.2.</span> "Utilisateur"
                désigne toute personne physique ou morale utilisant
                l'Application, qu'elle soit un particulier ou un professionnel.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">1.3.</span> "Professionnel"
                désigne un expert en installations et réparations d'équipements
                d'énergie renouvelable inscrit sur l'Application et disposant de
                toutes les qualifications, licences et autorisations nécessaires
                pour exercer son activité en conformité avec les lois et
                réglementations applicables.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">1.4.</span> "Particulier"
                désigne un utilisateur de l'Application qui recherche des
                services d'installation et de réparation d'équipements d'énergie
                renouvelable pour son usage personnel ou familial.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">
            Article 2 - Inscription et accès à l'Application
          </h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">2.1.</span> Pour utiliser
                l'Application, les Utilisateurs doivent créer un compte en
                fournissant les informations requises. Les Utilisateurs sont
                responsables de la véracité et de l'exactitude des informations
                fournies lors de l'inscription.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">2.2.</span> Les Utilisateurs
                sont responsables de la confidentialité de leurs identifiants de
                connexion et doivent prendre toutes les mesures nécessaires pour
                assurer leur sécurité. En cas de perte, de vol ou d'utilisation
                frauduleuse de leurs identifiants, les Utilisateurs doivent en
                informer LeBonTechnicien.fr sans délai.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">2.3.</span> LeBonTechnicien.fr
                se réserve le droit de suspendre ou de résilier l'accès à
                l'Application en cas de violation des présentes CGU, de fraude
                ou de comportement abusif de la part d'un Utilisateur.
                LeBonTechnicien.fr peut également suspendre ou résilier l'accès
                à tout moment pour des raisons opérationnelles, sans préavis ni
                indemnité.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">2.4.</span> Lors de
                l'inscription, les Utilisateurs garantissent que leur
                utilisation de l'Application sera conforme à toutes les lois et
                réglementations applicables, aux présentes CGU et à toutes les
                politiques et règles énoncées par LeBonTechnicien.fr.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">
            Article 3 - Services proposés par l'Application
          </h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">3.1.</span> L'Application met
                en relation les Particuliers et les Professionnels pour la
                réalisation d'installations et de réparations d'équipements
                d'énergie renouvelable.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">3.2.</span> Les Utilisateurs
                peuvent consulter les profils des Professionnels, soumettre des
                demandes d'intervention, comparer les offres, accepter ou
                refuser les devis, et évaluer les services rendus par les
                Professionnels.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">3.3.</span> LeBonTechnicien.fr
                n'est pas partie aux contrats conclus entre les Particuliers et
                les Professionnels. En conséquence, LeBonTechnicien.fr n'est pas
                responsable des litiges, réclamations ou dommages résultant des
                relations entre les Utilisateurs. Les Utilisateurs sont seuls
                responsables de la conclusion et de l'exécution des contrats
                conclus entre eux.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">3.4.</span> Les Utilisateurs
                reconnaissent que LeBonTechnicien.fr n'assume aucune
                responsabilité pour la qualité, la sécurité, la légalité ou la
                conformité des services proposés par les Professionnels inscrits
                sur l'Application. Les Utilisateurs sont invités à vérifier la
                qualité et la légalité des services proposés par les
                Professionnels avant de les accepter.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 4 - Responsabilité</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">4.1.</span> LeBonTechnicien.fr
                s'efforce de fournir un service de qualité et de garantir la
                disponibilité de l'Application. Toutefois, LeBonTechnicien.fr ne
                peut être tenu responsable des erreurs, interruptions, retards
                ou indisponibilités de l'Application, quelle qu'en soit la
                cause.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">4.2.</span> LeBonTechnicien.fr
                n'est pas responsable des dommages, pertes ou frais subis ou
                encourus par les Utilisateurs en relation avec l'utilisation de
                l'Application, y compris les dommages indirects, consécutifs,
                accessoires, spéciaux ou exemplaires, quelle que soit la cause.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">4.3.</span> L'Application peut
                contenir des liens vers des sites ou des applications tiers.
                LeBonTechnicien.fr n'est pas responsable du contenu, de la
                disponibilité ou du fonctionnement de ces sites ou applications.
                L'utilisation de ces sites ou applications tiers relève de la
                seule responsabilité des Utilisateurs, et ils sont invités à
                consulter les conditions générales d'utilisation et les
                politiques de confidentialité de ces sites ou applications.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">4.4.</span> Les Utilisateurs
                sont seuls responsables des informations, des données, des
                textes, des images, des vidéos, des documents et tout autre
                contenu qu'ils publient sur l'Application, et ils garantissent
                que ce contenu est légal, exact, véridique et ne porte pas
                atteinte aux droits de tierces parties.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 5 - Propriété intellectuelle</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">5.1.</span> Les Utilisateurs
                reconnaissent que tous les droits de propriété intellectuelle
                sur l'Application et son contenu, y compris les marques, les
                logos, les noms de domaines, les codes sources, les designs, les
                bases de données, les textes, les images, les sons et les
                vidéos, sont la propriété exclusive de LeBonTechnicien.fr ou de
                ses licenciés, et sont protégés par les lois applicables en
                matière de propriété intellectuelle.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">5.2.</span> Sauf autorisation
                expresse de LeBonTechnicien.fr, les Utilisateurs s'interdisent
                de reproduire, de copier, de vendre, de revendre, de louer, de
                distribuer, de publier, de transmettre, de stocker, de traduire,
                de modifier, de créer des œuvres dérivées, de commercialiser, de
                publier, de diffuser, de transmettre, de distribuer, de
                diffuser, de communiquer publiquement, de mettre à disposition
                du public, de faire l'objet d'un transfert ou d'une cession, ou
                d'exploiter de toute autre manière l'Application ou son contenu,
                sans autorisation expresse de LeBonTechnicien.fr.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">
            Article 6 - Protection des données personnelles
          </h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">6.1.</span> LeBonTechnicien.fr
                s'engage à protéger la confidentialité et la sécurité des
                données personnelles des Utilisateurs conformément aux lois
                applicables en matière de protection des données personnelles.
                Les Utilisateurs sont invités à consulter notre Politique de
                Confidentialité pour obtenir des informations sur la collecte,
                l'utilisation et le partage de leurs données personnelles.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">6.2.</span> Les Utilisateurs
                sont seuls responsables de la confidentialité de leurs données
                personnelles, et ils sont invités à ne pas publier sur
                l'Application des informations sensibles ou confidentielles.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 7 - Modification des CGU</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">7.1.</span> LeBonTechnicien.fr
                se réserve le droit de modifier les présentes CGU à tout moment,
                sans préavis. Les Utilisateurs sont invités à consulter les CGU
                pour prendre connaissance des modifications apportées.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">7.2.</span> L'utilisation
                continue de l'Application par les Utilisateurs après la
                modification des CGU constitue leur acceptation des
                modifications.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">7.3.</span> Les modifications
                apportées aux CGU sont réputées être entrées en vigueur à
                compter de leur publication sur l'Application. Les Utilisateurs
                sont invités à consulter régulièrement les CGU pour s'assurer de
                leur connaissance des dernières versions en vigueur.
              </li>
            </ul>
          </p>
          <h2 className="leBonTech-h2">
            Article 8 - Loi applicable et juridiction compétente
          </h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <p className="leBonTech-p">
                <span className="leBonTech-span">8.1.</span>Les présentes CGU
                sont régies et interprétées conformément à la loi applicable en
                France, et tout litige ou différend découlant de ou en relation
                avec l'utilisation de l'Application sera soumis à la compétence
                exclusive des tribunaux français.
              </p>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 9 - Contact</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <p className="leBonTech-p">
                Pour toute question ou réclamation concernant l'Application ou
                les présentes CGU, veuillez contacter LeBonTechnicien.fr à
                l'adresse suivante : contact@lebontechnicien.fr.
              </p>
            </ul>
          </p>
          <h2 className="leBonTech-h2">Article 10 - Dispositions diverses</h2>
          <p className="leBonTech-p">
            <ul className="leBonTech-ul">
              <li className="leBonTech-li">
                <span className="leBonTech-span">10.1.</span> Si une ou
                plusieurs dispositions des présentes CGU sont considérées comme
                invalides, illégales ou inapplicables par un tribunal compétent,
                les autres dispositions resteront en vigueur et ne seront pas
                affectées.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">10.2.</span> Le fait pour
                LeBonTechnicien.fr de ne pas exercer ou de ne pas faire valoir
                un droit ou une disposition des présentes CGU ne constitue pas
                une renonciation à ce droit ou à cette disposition.
              </li>
              <li className="leBonTech-li">
                <span className="leBonTech-span">10.3.</span> Les présentes CGU
                constituent l'intégralité de l'accord entre les Utilisateurs et
                LeBonTechnicien.fr concernant l'utilisation de l'Application et
                remplacent tous les accords antérieurs, qu'ils soient écrits ou
                oraux, relatifs au même objet.
              </li>
            </ul>
          </p>

          <p className="leBonTech-p">
            Nous vous remercions de votre confiance en LeBonTechnicien.fr et
            nous nous engageons à vous offrir une expérience de qualité en
            matière de mise en relation entre particuliers et professionnels
            experts en installations et réparations d'équipements d'énergie
            renouvelable. Si vous avez des questions ou des préoccupations
            concernant les présentes Conditions Générales d'Utilisation,
            n'hésitez pas à nous contacter.
          </p>
        </section>
      </main>
    </>
  );
};

export default Cgu;
