import React, { useEffect, useState } from "react";
import { BreadCrumb, CRUDTable } from "../../../Components";
import {
  IoEyeOutline,
  IoPencil,
  IoTrashOutline,
  IoAddCircleOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Domaines, Marque } from "../../../Constants/dummy";
import { validatePostalCodeInput } from "../../../Components/FormValidation";
import ReCAPTCHA from "react-google-recaptcha";

const UserNewDevice = () => {
  const [typeDuBien, setTypeDuBien] = useState([]);
  const [produits, setProduits] = useState("");
  const [annéeInstallation, setAnnéeInstallation] = useState("");
  const [marque, setMarque] = useState("");
  const [référence, setRéférence] = useState("");
  const [photoDuProduit, setPhotoDuProduit] = useState(null);
  const [entretenu, setEntretenu] = useState("");
  const [selectedYearEntretenu, setSelectedYearEntretenu] = useState("");
  const [moisInstallation, setMoisInstallation] = useState("");
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => currentYear - i);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [produitsModify, setProduitsModify] = useState("");
  const [annéeInstallationModify, setAnnéeInstallationModify] = useState("");
  const [marqueModify, setMarqueModify] = useState("");
  const [référenceModify, setRéférenceModify] = useState("");
  const [photoDuProduitModify, setPhotoDuProduitModify] = useState("");
  const [entretenuModify, setEntretenuModify] = useState("");
  const [selectedYearEntretenuModify, setSelectedYearEntretenuModify] =
    useState("");
  const [adresseIntervention, setAdresseIntervention] = useState("");
  const [postaleError, setPostaleError] = useState("");
  const [prenom, setPrenom] = useState("");
  const [adresse, setAdresse] = useState("");
  const [postale, setPostale] = useState("");
  const [ville, setVille] = useState("");
  const [typeDuBienModify, setTypeDuBienModify] = useState([]);
  const [selectedYearModify, setSelectedYearModify] = useState(currentYear);
  const [errors, setErrors] = useState([]);
  const [puissance, setPuissance] = useState("");
  const [isModifyModalVisible, setIsModifyModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const email = sessionStorage.getItem("email");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedSubdomain, setSelectedSubdomain] = useState(null);
  const apiUrl = 'https://lebontechnicien.net'

  const Mois = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const handleRecaptcha = (value) => {
    console.log("reCAPTCHA value:", value);
    setRecaptchaValue(value);
  };

  /*const handleTypeSelection = (type) => {
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };*/

  const handleTypeSelection = (domain, subdomain) => {
    setSelectedDomain(domain);
    setSelectedSubdomain(subdomain);
  };

  //Add form Handeling
  const handleTypeDuBien = (event) => {
    const { value } = event.target;
    if (typeDuBien.includes(value)) {
      setTypeDuBien(typeDuBien.filter((c) => c !== value));
    } else {
      setTypeDuBien([...typeDuBien, value]);
    }
  };

  const handleProduits = (event) => {
    setProduits(event.target.value);
  };

  const handleAnnéeInstallation = (event) => {
    setAnnéeInstallation(event.target.value);
  };

  const handleMarque = (event) => {
    setMarque(event.target.value);
  };

  const handleRéférence = (event) => {
    setRéférence(event.target.value);
  };

  const handlePhotoDuProduitModify = (event) => {
    setPhotoDuProduitModify(event.target.files);
  };

  const handlePhotoDuProduit = (event) => {
    setPhotoDuProduit(event.target.files);
  };

  const handleAddMorePictures = (event) => {
    event.preventDefault();
    const newFiles = document.getElementById("photoDuProduit").files;
    const existingFiles = photoDuProduit ? Array.from(photoDuProduit) : [];
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
  };

  const handleEntretenu = (event) => {
    setEntretenu(event.target.value);
  };

  const handleSelectedYear = (event) => {
    setSelectedYearEntretenu(event.target.value);
  };

  const handleSelectedYearModify = (event) => {
    setSelectedYearEntretenuModify(event.target.value);
  };

  const handleProduitsModify = (event) => {
    setProduitsModify(event.target.value);
  };

  const handlePostaleChange = (event) => {
    const value = event.target.value;
    setPostale(value);
    setPostaleError(validatePostalCodeInput(value));
    console.log("postaleError", postaleError);
  };

  const [autreMarque, setAutreMarque] = useState("");
  const handleAutreMarque = (event) => {
    setAutreMarque(event.target.value);
  };

  //const [newProduitsModify, setNewProduitsModify] = useState([""]);

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify(formData, email),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  const info = pendingList.map((item) => {
    const creationDate = new Date(item.created_at);
    const frenchCreationDate = creationDate.toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return {
      Création: frenchCreationDate,
      id: item._id,
      "Type d'appareil": item.typeDuBien,
      Année_Installation: item.annéeInstallation ? item.annéeInstallation : item.anneeInstallation,
      Marque: item.marque !== "autre" ? item.marque : item.autreMarque,
      Référence: item.reference ? item.reference : item.référence,
      Maintenance: item.entretenu,
      //Année_Maintenance: item.selectedYearEntretenu,
      //deleteIcon: item._id,
      //creationDate: creationDate.getTime() // add a new property with the date object converted to milliseconds
    };
  });

  // sort the array by creation date in descending order
  info.sort((a, b) => b.creationDate - a.creationDate);

  // Toggles Add Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModalToggle = () => {
    setIsModalVisible(!isModalVisible);
  };

  // Toggles Modify Modal
  const handleModifyModalToggle = () => {
    setIsModifyModalVisible(!isModifyModalVisible);
  };

  const handleModifyClick = (id) => {
    setSelectedId(id);
    const item = info.find((item) => item.id === id);
    setProduitsModify(item.produit);
    setAnnéeInstallationModify(item.installation);
    setMarqueModify(item.marque);
    setRéférenceModify(item.référence);
    setEntretenuModify(item.entretenu);
    setSelectedYearEntretenuModify(item.année);
    //setNewProduitsModify([item.produit]);
    setIsModifyModalVisible(true);
    console.log(produitsModify);
  };

  const handleConfirmModify = () => {
    handleModifyProduct(selectedId);
    //handleDeleteProduct(selectedId)
    setIsModifyModalVisible(false);
    setSelectedId(null);
  };

  const handleCancelModify = () => {
    setIsModifyModalVisible(false);
    setSelectedId(null);
  };

  // Handles Deelete
  const handleDeleteClick = (id) => {
    setSelectedId(id);
    console.log("deleteselected", selectedId);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteProduct(selectedId);
    //handleDeleteProduct(selectedId)
    setShowModal(false);
    setSelectedId(null);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  const formData = {
    //photoDuProduit,
    selectedTypes,
    produits,
    marque,
    référence,
    annéeInstallation,
    entretenu,
    //selectedYearEntretenu,
    selectedYear,
    email,
  };

  //Form to be sent to the handleCreateProduct request
  const formDatas = new FormData();
  if (photoDuProduit && photoDuProduit.length) {
    for (let i = 0; i < photoDuProduit.length; i++) {
      formDatas.append("photoDuProduit", photoDuProduit[i]);
    }
  }
  if (photoDuProduit && photoDuProduit.length) {
    formDatas.append("photoDuProduitName", photoDuProduit.name);
  }
  formDatas.append("typeDuBien", selectedSubdomain);
  formDatas.append("produits", produits);
  formDatas.append("marque", marque);
  formDatas.append("reference", référence);
  formDatas.append("anneeInstallation", annéeInstallation);
  formDatas.append("entretenu", entretenu);
  formDatas.append("puissance", puissance);
  //formDatas.append("selectedYearEntretenu", selectedYearEntretenu);
  formDatas.append("selectedYear", selectedYear);
  formDatas.append("email", email);
  formDatas.append("moisInstallation", moisInstallation);
  formDatas.append("adresse", adresse);
  formDatas.append("postale", postale);
  formDatas.append("ville", ville);
  formDatas.append("autreMarque", autreMarque);
  formDatas.append("recaptchaValue", recaptchaValue);

  const handleCreateProduct = async (event) => {
    event.preventDefault();
    /* let isValid = true;
    const requiredFields = [
      //"typeDuBien",
      //"produits",
      "marque",
      "référence",
      //"annéeInstallation",
      "photoDuProduit",
      //"entretenu",
      //"selectedYearEntretenu",
      //"selectedYear",

      //"email",
    ];

    // Perform form validation
    for (const fieldName of requiredFields) {
      if (!formData[fieldName]) {
        isValid = false;
        const input = document.querySelector(`[name="${fieldName}"]`);
        input.style.borderColor = "red";
        if (input.type === "checkbox" || input.type === "radio") {
          const label = input.closest("label");
          if (label) {
            const legend = input.parentNode.querySelector("legend");
            if (legend) {
              legend.style.color = "red";
            }
          }
        } else {
          const errorText = document.createElement("p");
          errorText.innerText = `Veuillez remplir ce champ.\n`;
          errorText.style.color = "red";
          input.parentNode.insertBefore(errorText, input);
          input.parentNode.insertBefore(
            document.createElement("br"),
            input.nextSibling
          );
        }
      } else {
        const input = document.querySelector(`[name="${fieldName}"]`);
        input.style.borderColor = "initial";
        if (input.type === "checkbox" || input.type === "radio") {
          const label = input.closest("label");
          if (label) {
            const legend = input.parentNode.querySelector("legend");
            if (legend) {
              legend.style.color = "initial";
            }
          }
        } else {
          const errorText = input.previousElementSibling;
          if (
            errorText &&
            errorText.innerText.startsWith("Veuillez remplir ce champ.")
          ) {
            errorText.remove();
            input.parentNode.removeChild(input.parentNode.lastChild);
          }
        }
      }
    }

    // Validate photoDuProduit
    const photoDuProduitInput = document.querySelector(
      '[name="photoDuProduit"]'
    );
    if (photoDuProduitInput && photoDuProduitInput.files.length === 0) {
      isValid = false;
      photoDuProduitInput.style.borderColor = "red";
      const label = photoDuProduitInput.querySelector("label");
      if (label) {
        const legend = photoDuProduitInput.parentNode.querySelector("legend");
        if (legend) {
          legend.style.color = "red";
        }
      }
      const errorText = document.createElement("p");
      errorText.innerText = `Veuillez sélectionner une photo.\n`;
      errorText.style.color = "red";
      photoDuProduitInput.parentNode.insertBefore(
        errorText,
        photoDuProduitInput
      );
      photoDuProduitInput.parentNode.insertBefore(
        document.createElement("br"),
        photoDuProduitInput.nextSibling
      );
    } else if (photoDuProduitInput) {
      photoDuProduitInput.style.borderColor = "initial";
      const label = photoDuProduitInput.querySelector("label");
      if (label) {
        const legend = photoDuProduitInput.parentNode.querySelector("legend");
        if (legend) {
          legend.style.color = "initial";
        }
      }
      const errorText = photoDuProduitInput.previousElementSibling;
      if (
        errorText &&
        errorText.innerText.startsWith("Veuillez sélectionner une photo.")
      ) {
        errorText.remove();
        photoDuProduitInput.parentNode.removeChild(
          photoDuProduitInput.parentNode.lastChild
        );
      }
    }

    // If form is invalid, return early
    if (!isValid) {
      return;
    }*/

    // If form is valid, proceed with creating a new product
    try {
      setIsLoading(true);
      const res = await fetch(`${apiUrl}/prods/create_product`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formDatas,
      });
      const data = await res.json();
      if (data.success) {
        setPendingList((prevState) => [...prevState, data.product]);
        setIsModalVisible(false);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
    setIsModalVisible(false);
    window.location.reload();
  };

  //Fetch request to delete a product
  const handleDeleteProduct = async (id) => {
    try {
      const email = sessionStorage.getItem("email");
      const res = await fetch(`${apiUrl}/prods/remove_product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id, email }),
      });

      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.msg);
      }

      const data = await res.json();
      setPendingList(pendingList.filter((item) => item.id !== id));
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    window.location.reload();
  };

  //Fetch request to modify a product
  const handleModifyProduct = async (id) => {
    //Form to be sent to the handleCreateProduct request
    const formDataModify = new FormData();
    if (produitsModify && produitsModify.length) {
      for (let i = 0; i < produitsModify.length; i++) {
        formDatas.append("photoDuProduit", produitsModify[i]);
      }
    }
    if (produitsModify && produitsModify.length) {
      formDatas.append("photoDuProduitName", produitsModify.name);
    }
    formDataModify.append("id", id);
    formDataModify.append("produits", produitsModify);
    formDataModify.append("marque", marqueModify);
    formDataModify.append("référence", référenceModify);
    formDataModify.append("annéeInstallation", annéeInstallationModify);
    formDataModify.append("entretenu", entretenuModify);
    formDataModify.append("selectedYearEntretenu", selectedYearEntretenuModify);
    formDataModify.append("selectedYear", selectedYear);
    formDataModify.append("email", email);

    const response = await fetch(
      `${apiUrl}/prods/modify_product`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formDataModify,
      }
    );
    const data = await response.json();
    console.log(data);
    window.location.reload();
  };

  return (
    <>
      <BreadCrumb
        From={"Compte"}
        To={"Liste Des Appareils"}
        hrefFrom={"/tableau-de-bord"}
      />
      <div className="mt-10 sm:mt-0 max-w-screen-xl mx-auto sm:px-6 lg:px-8">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <div className="flex justify-between w-full mt-10">
                <h3 className="text-lg font-medium leading-6 text-gray-900 title-font-h3">
                  Liste Des Appareils
                </h3>
                <button
                  onClick={handleModalToggle}
                  className=" userProfile-btn inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Ajouter
                </button>
              </div>
              {/*<div className="flex justify-end gap-4 w-[100%] ml-auto mr-auto">
                <select
                  value={selectedValue}
                  onChange={(event) => setSelectedValue(event.target.value)}
                  className=" mt-6  p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-gray-50"
                >
                  <option value="">Tous les produits</option>
                  {Array.from(new Set(info.map((item) => item.produit))).map(
                    (product) => (
                      <option key={product} value={product}>
                        {product}
                      </option>
                    )
                  )}
                </select>
                    </div>*/}
              {isModalVisible ? (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => setIsModalVisible(false)}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-bold text-green-700">
                              Ajouter un nouvel appareil
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez remplir toutes les informations requises
                              dans le formulaire pour ajouter votre nouvel
                              appareil.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                {errors.length > 0 && (
                                  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-4">
                                    <p>Please fill in the following fields:</p>
                                    <ul>
                                      {errors.map((field) => (
                                        <li key={field}>{field}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                <div>
                                  <fieldset>
                                    <legend className="contents text-base font-medium text-gray-900 ">
                                      Type de l'appareil{" "}
                                      <span className="text-red-500"> * </span>
                                    </legend>
                                    {/*<p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>*/}
                                    <div className="mt-4 space-y-4">
                                      {/*<div className="flex items-center">
                                        <input
                                          id="typeDuBien"
                                          name="typeDuBien"
                                          type="radio"
                                          value="Pompe à chaleur"
                                          checked={
                                            produits === "Pompe à chaleur"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-everything"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          Pompe à chaleur
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="push-email"
                                          name="push-notifications"
                                          type="radio"
                                          value="ballon thermodynamique"
                                          checked={
                                            produits ===
                                            "ballon thermodynamique"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-email"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          ballon thermodynamique
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="push-nothing"
                                          name="push-notifications"
                                          type="radio"
                                          value="ballon solaire"
                                          checked={
                                            produits === "ballon solaire"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-nothing"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          ballon solaire
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="push-nothing"
                                          name="push-notifications"
                                          type="radio"
                                          value="ballon d’eau chaude électrique"
                                          checked={
                                            produits ===
                                            "ballon d’eau chaude électrique"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-nothing"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          ballon d’eau chaude électrique
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="push-nothing"
                                          name="push-notifications"
                                          type="radio"
                                          value="panneau photovoltaïque"
                                          checked={
                                            produits ===
                                            "panneau photovoltaïque"
                                          }
                                          onChange={handleProduits}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="push-nothing"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          Panneau Photovoltaïque
                                        </label>
                                        </div>*/}
            {Domaines.map((domaine) => (
          <div
            className="rounded-md cursor-pointer w-full  px-4 py-2"
            key={domaine.id}
          >
            <div
              className="flex"
              onClick={() => handleTypeSelection(domaine.nom, null)} 
            >
              <div className="flex mb-2">
                {domaine.icon}
                {domaine.nom}
              </div>
            </div>
            {domaine.hasType &&
              selectedDomain === domaine.nom && (
                domaine.type.map((type) => (
                  <div key={type} className="p-2">
                    <label>
                      <input
                        type="radio"
                        checked={selectedSubdomain === type}
                        onChange={() => handleTypeSelection(domaine.nom, type)}
                      />
                      {type}
                    </label>
                  </div>
                ))
              )}
            {!domaine.hasType && selectedDomain === domaine.nom && (
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedSubdomain === domaine.nom}
                    onChange={() => handleTypeSelection(domaine.nom, domaine.nom)}
                  />
                  {domaine.nom}
                </label>
              </div>
            )}
          </div>
        ))}
                                    </div>
                                  </fieldset>
                                </div>
                                <div className="flex justify-between">
                                  <div className="relative mt-4">
                                    <label
                                      htmlFor="moisInstallation"
                                      className=""
                                    >
                                      Mois d’installation{" "}
                                      <span className="text-red-500"> * </span>
                                    </label>
                                    <select
                                      id="moisInstallation"
                                      name="moisInstallation"
                                      value={moisInstallation}
                                      onChange={(e) =>
                                        setMoisInstallation(e.target.value)
                                      }
                                      className="mb-2 block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                    >
                                      {Mois.map((mois) => (
                                        <option key={mois} value={mois}>
                                          {mois}
                                        </option>
                                      ))}
                                    </select>
                                    <div className=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                      <svg
                                        className="fill-current h-4 w-4 mt-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                      </svg>
                                    </div>
                                    {/*<input
                                      type="number"
                                      name="moisInstallation"
                                      value={moisInstallation}
                                      onChange={(event) =>
                                        setMoisInstallation(event.target.value)
                                      }
                                      required
                                      id="moisInstallation"
                                      autoComplete="moisInstallation"
                                      max={12}
                                      className="mb-2 relative p-16 block w-full appearance-none  rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />*/}
                                  </div>
                                  <div className="relative mt-4">
                                    <label
                                      htmlFor="annéeInstallation"
                                      className=""
                                    >
                                      Année d’installation{" "}
                                      <span className="text-red-500"> * </span>
                                    </label>

                                    <select
                                      id="annéeInstallation"
                                      name="annéeInstallation"
                                      value={annéeInstallation}
                                      onChange={(e) =>
                                        setAnnéeInstallation(e.target.value)
                                      }
                                      className="mb-2 block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                    >
                                      {years.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                    <div className=" pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                      <svg
                                        className="fill-current h-4 w-4 mt-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="marque"
                                    className="margin-top"
                                  >
                                    Marque de l'appareil{" "}
                                    <span className="text-red-500"> * </span>
                                  </label>
                                  <select
                                    id="marque"
                                    name="marque"
                                    autoComplete="marque"
                                    value={marque}
                                    onChange={handleMarque}
                                    required
                                    style={{ marginTop: "16px" }}
                                    className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  >
                                    <option value="">
                                      Sélectionner une marque
                                    </option>
                                    {Marque.map((marqueOption) => (
                                      <option
                                        key={marqueOption}
                                        value={marqueOption}
                                      >
                                        {marqueOption}
                                      </option>
                                    ))}
                                    <option value="autre">Autre</option>
                                  </select>
                                  {marque === "autre" && (
                                    <div>
                                      <label
                                        htmlFor="autre-marque"
                                        className="margin-top"
                                      >
                                        Autre marque{" "}
                                        <span className="text-red-500">
                                          {" "}
                                          *{" "}
                                        </span>
                                      </label>
                                      <input
                                        id="autre-marque"
                                        name="autre-marque"
                                        type="text"
                                        autoComplete="off"
                                        value={autreMarque}
                                        onChange={handleAutreMarque}
                                        required
                                        style={{ marginTop: "16px" }}
                                        className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Marque de l'appareil"
                                      />
                                    </div>
                                  )}
                                </div>

                                <div>
                                  <label
                                    htmlFor="référence"
                                    className="mb-[-16px]"
                                  >
                                    Référence{" "}
                                    <span className="text-red-500"> * </span>
                                  </label>
                                  <input
                                    id="référence"
                                    name="référence"
                                    type="text"
                                    autoComplete="référence"
                                    required
                                    value={référence}
                                    onChange={handleRéférence}
                                    style={{ marginTop: "16px" }}
                                    className="relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Référence"
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor="puissance"
                                    className="margin-top"
                                  >
                                    Puissance du produit{" "}
                                    <span className="text-red-500"> * </span>
                                  </label>
                                  <input
                                    id="puissance"
                                    name="puissance"
                                    type="text"
                                    autoComplete="puissance"
                                    value={puissance}
                                    onChange={(event) =>
                                      setPuissance(event.target.value)
                                    }
                                    required
                                    style={{ marginTop: "16px" }}
                                    className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Puissance du produit"
                                  />
                                </div>
                                <div>
                                  <label
                                    className="block text-sm font-medium text-gray-700"
                                    style={{ marginTop: "16px" }}
                                    id="photoDuProduitid"
                                  >
                                    Photo du produit{" "}
                                  </label>
                                  <div>
                                    {photoDuProduit && (
                                      <div className="mt-1">
                                        {Array.from(photoDuProduit).map(
                                          (file) => (
                                            <div
                                              key={file.name}
                                              className="flex items-center"
                                            >
                                              <span className="font-medium text-green-600">
                                                {file.name}
                                              </span>
                                            </div>
                                          )
                                        )}
                                        <div className="flex gap-2">
                                          <button
                                            className=" mt-[10px] bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                            onClick={() =>
                                              setPhotoDuProduit(null)
                                            }
                                          >
                                            Change
                                          </button>
                                          <div className="mt-4">
                                            <label
                                              htmlFor="photoDuProduit"
                                              className=" bg-gray-200 hover:bg-gray-300 py-[10px] px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 cursor-pointer"
                                            >
                                              Ajouter plus de photos
                                              <input
                                                id="photoDuProduit"
                                                name="photoDuProduit"
                                                type="file"
                                                multiple
                                                className="sr-only"
                                                onChange={handleAddMorePictures}
                                              />
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {!photoDuProduit && (
                                      <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                        <div className="space-y-1 text-center">
                                          <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>

                                          <div className="flex text-sm text-gray-600">
                                            <label
                                              htmlFor="photoDuProduit"
                                              className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                            >
                                              <span>Upload a file</span>
                                              <input
                                                id="photoDuProduit"
                                                name="photoDuProduit"
                                                type="file"
                                                multiple
                                                className="sr-only"
                                                value=""
                                                onChange={handlePhotoDuProduit}
                                              />
                                            </label>
                                            <p className="pl-1">
                                              or drag and drop
                                            </p>
                                          </div>
                                          <p className="text-xs text-gray-500">
                                            PNG, JPG, GIF up to 10MB
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {parseInt(annéeInstallation) !==
                                  new Date().getFullYear() && (
                                  <fieldset className="mb-6 margin-top">
                                    <legend className="contents text-sm font-medium text-gray-600">
                                      Votre appareil est-il entretenu ?{" "}
                                      <span className="text-red-500"> * </span>
                                    </legend>
                                    <div className="mt-4 space-y-4">
                                      <div className="flex items-center">
                                        <input
                                          id="oui"
                                          name="entretenu"
                                          type="radio"
                                          value="oui"
                                          onChange={(e) =>
                                            setEntretenu(e.target.value)
                                          }
                                          checked={entretenu === "oui"}
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor="oui"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          Oui
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id="non"
                                          name="entretenu"
                                          type="radio"
                                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                          value="non"
                                          onChange={(e) =>
                                            setEntretenu(e.target.value)
                                          }
                                          checked={entretenu === "non"}
                                        />
                                        <label
                                          htmlFor="non"
                                          className="ml-3 block text-sm font-medium text-gray-700"
                                        >
                                          Non
                                        </label>
                                      </div>
                                    </div>
                                    {/*{entretenu === "oui" && (
                                      <div className="relative mt-4">
                                        <label
                                          htmlFor="selectedYearEntretenu"
                                          className="sr-only"
                                        >
                                          Depuis quelle année
                                        </label>
                                        <select
                                          id="selectedYearEntretenu"
                                          name="selectedYearEntretenu"
                                          value={selectedYearEntretenu}
                                          onChange={handleSelectedYear}
                                          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                        >
                                          {years.map((year) => (
                                            <option key={year} value={year}>
                                              {year}
                                            </option>
                                          ))}
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                          <svg
                                            className="fill-current h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                          </svg>
                                        </div>
                                      </div>
                                          )}*/}
                                  </fieldset>
                                )}

                                <fieldset className="mb-6 margin-top">
                                  <legend className="contents text-sm font-medium text-gray-600">
                                    L'adresse d'intervention est-elle la vôtre ?{" "}
                                    <span className="text-red-500"> * </span>
                                  </legend>
                                  <div className="mt-4 space-y-4">
                                    <div className="flex items-center">
                                      <input
                                        id="oui"
                                        name="adresseIntervention"
                                        type="radio"
                                        value="oui"
                                        onChange={(e) =>
                                          setAdresseIntervention(e.target.value)
                                        }
                                        checked={adresseIntervention === "oui"}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor="oui"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        Oui
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="non"
                                        name="adresseIntervention"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        value="non"
                                        onChange={(e) =>
                                          setAdresseIntervention(e.target.value)
                                        }
                                        checked={adresseIntervention === "non"}
                                      />
                                      <label
                                        htmlFor="non"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        Non
                                      </label>
                                    </div>
                                  </div>
                                  {adresseIntervention === "non" && (
                                    <div className="relative mt-4">
                                      <label
                                        htmlFor="selectedAdresseIntervention"
                                        className="sr-only"
                                      >
                                        Depuis quelle année
                                      </label>
                                      <div className="bg-white  py-5 sm:p-6">
                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2 mt-2">
                                          <label
                                            htmlFor="adresse"
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            N° et Nom de rue{" "}
                                            <span className="text-red-500">
                                              {" "}
                                              *{" "}
                                            </span>
                                          </label>
                                          <input
                                            value={adresse}
                                            type="text"
                                            name="adresse"
                                            id="adresse"
                                            onChange={(event) =>
                                              setAdresse(event.target.value)
                                            }
                                            required
                                            autoComplete="adresse"
                                            className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                          />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-4">
                                          <label
                                            htmlFor="ville"
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            Ville{" "}
                                            <span className="text-red-500">
                                              {" "}
                                              *{" "}
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            value={ville}
                                            onChange={(event) =>
                                              setVille(event.target.value)
                                            }
                                            name="ville"
                                            required
                                            id="ville"
                                            autoComplete="ville"
                                            className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                          />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-4">
                                          <label
                                            htmlFor="postale"
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            Code Postale:{" "}
                                            <span className="text-red-500">
                                              {" "}
                                              *{" "}
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            name="postale"
                                            value={postale}
                                            onChange={handlePostaleChange}
                                            required
                                            id="postale"
                                            autoComplete="postale"
                                            className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                          />
                                          {postaleError && (
                                            <p style={{ color: "red" }}>
                                              {postaleError}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </fieldset>
                              </form>
                            </div>
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_SITE_KEY}
                              onChange={handleRecaptcha}
                            />
                            <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                onClick={handleCreateProduct}
                              >
                                Ajouter
                              </button>
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={() => setIsModalVisible(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {showModal ? (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setShowModal(false)}
                  ></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 sm:flex">
                        <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-red-600"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-medium text-gray-800">
                            Êtes-vous sûr de vouloir supprimer?
                          </h4>
                          <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                            En cliquant sur "Supprimer", vous supprimez
                            définitivement cet appareil. Cette action est
                            irréversible. Veuillez vous assurer que vous voulez
                            bien continuer.
                          </p>
                          <div className="items-center gap-2 mt-3 sm:flex">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                              onClick={() => handleConfirmDelete()}
                            >
                              Supprimer
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                              onClick={() => handleCancelDelete()}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {isModifyModalVisible ? (
              <>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setIsModalVisible(false)}
                  ></div>
                  <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                      <div className="mt-3 sm:flex">
                        <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-yellow-100 rounded-full">
                          <IoAddCircleOutline className="text-yellow-600" />
                        </div>
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <h4 className="text-lg font-medium text-gray-800">
                            Modifier votre appareil
                          </h4>
                          <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                            Veuillez remplir toutes les informations requises
                            dans le formulaire pour modifier votre appareil.
                          </p>
                          <div className="modal-content mr-4">
                            <form className="mr-4 mb-4">
                              <div>
                                <fieldset>
                                  <legend className="contents text-base font-medium text-gray-900 ">
                                    Type de l'appareil
                                  </legend>
                                  {/*<p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>*/}
                                  <div className="mt-4 space-y-4">
                                    {/*<div className="flex items-center">
                                      <input
                                        id="typeDuBien"
                                        name="typeDuBien"
                                        type="radio"
                                        value="typeDuBien"
                                        checked={
                                          produitsModify &&
                                          produitsModify.includes(
                                            "Pompe à chaleur"
                                          )
                                        }
                                        onChange={handleProduitsModify}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor="push-everything"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        Pompe à chaleur
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="push-email"
                                        name="push-notifications"
                                        type="radio"
                                        value="ballon thermodynamique"
                                        checked={
                                          produitsModify &&
                                          produitsModify.includes(
                                            "ballon thermodynamique"
                                          )
                                        }
                                        onChange={handleProduitsModify}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor="push-email"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        ballon thermodynamique
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="push-nothing"
                                        name="push-notifications"
                                        type="radio"
                                        value="ballon solaire"
                                        checked={
                                          produitsModify &&
                                          produitsModify.includes(
                                            "ballon solaire"
                                          )
                                        }
                                        onChange={handleProduitsModify}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor="push-nothing"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        ballon solaire
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="push-nothing"
                                        name="push-notifications"
                                        type="radio"
                                        value="ballon d’eau chaude électrique"
                                        checked={
                                          produitsModify &&
                                          produitsModify.includes(
                                            "ballon d’eau chaude électrique"
                                          )
                                        }
                                        onChange={handleProduitsModify}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor="push-nothing"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        ballon d’eau chaude électrique
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="push-nothing"
                                        name="push-notifications"
                                        type="radio"
                                        value="Panneau Photovoltaïque"
                                        checked={
                                          produitsModify &&
                                          produitsModify.includes(
                                            "Panneau Photovoltaïque"
                                          )
                                        }
                                        onChange={handleProduitsModify}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor="push-nothing"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        Panneau Photovoltaïque
                                      </label>
                                      </div>*/}
                                    <div className="mb-4">
                                      <h2 className="text-lg font-bold mb-2 px-4">
                                        Type de projet
                                      </h2>
                                      {Domaines.map((domaine) => (
                                        <div
                                          className=" rounded-md cursor-pointer w-[30%] px-4 py-2"
                                          key={domaine.id}
                                        >
                                          <div
                                            onClick={() =>
                                              handleTypeSelection(domaine.nom)
                                            }
                                          >
                                            {domaine.nom}
                                          </div>
                                          {domaine.hasType &&
                                            selectedTypes.includes(
                                              domaine.nom
                                            ) &&
                                            domaine.type.map((type) => (
                                              <div key={type} className="p-2">
                                                <label>
                                                  <input
                                                    type="checkbox"
                                                    checked={selectedTypes.includes(
                                                      type
                                                    )}
                                                    onChange={() =>
                                                      handleTypeSelection(type)
                                                    }
                                                  />
                                                  {type}
                                                </label>
                                              </div>
                                            ))}
                                          {!domaine.hasType &&
                                            selectedTypes.includes(
                                              domaine.nom
                                            ) && (
                                              <div>
                                                <label>
                                                  <input
                                                    type="checkbox"
                                                    checked={true}
                                                    onChange={() => {}}
                                                    disabled
                                                  />
                                                  {domaine.nom}
                                                </label>
                                              </div>
                                            )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                              <div className="relative mt-4">
                                <label
                                  htmlFor="annéeInstallation"
                                  className="contents text-base font-medium text-gray-900 mb-4"
                                >
                                  Année d’installation
                                </label>
                                <select
                                  id="annéeInstallation"
                                  name="annéeInstallation"
                                  value={annéeInstallationModify}
                                  onChange={(e) =>
                                    setAnnéeInstallationModify(e.target.value)
                                  }
                                  className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                >
                                  {years.map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                  <svg
                                    className="fill-current h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                  </svg>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="marque"
                                  className="contents text-base font-medium text-gray-900 mt-4"
                                >
                                  Marque de l'appareil
                                </label>
                                <input
                                  id="marque"
                                  name="marque"
                                  type="text"
                                  autoComplete="marque"
                                  value={marqueModify}
                                  onChange={(e) =>
                                    setMarqueModify(e.target.value)
                                  }
                                  required
                                  style={{ marginTop: "16px" }}
                                  className="relative p-16 block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Marque de l'appareil"
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="référence"
                                  className="contents text-base font-medium text-gray-900 mt-4"
                                >
                                  Référence
                                </label>
                                <input
                                  id="référence"
                                  name="aréférence"
                                  type="text"
                                  autoComplete="référence"
                                  required
                                  value={référenceModify}
                                  onChange={(e) =>
                                    setRéférenceModify(e.target.value)
                                  }
                                  style={{ marginTop: "16px" }}
                                  className="relative p-16 block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Référence"
                                />
                              </div>
                              <div>
                                <div>
                                  <label
                                    className="block text-sm font-medium text-gray-700"
                                    style={{ marginTop: "16px" }}
                                    id="photoDuProduitid"
                                  >
                                    Photo du produit{" "}
                                    <span className="text-red-500"> * </span>
                                  </label>
                                  <div>
                                    {photoDuProduitModify && (
                                      <div className="mt-1">
                                        {Array.from(photoDuProduitModify).map(
                                          (file) => (
                                            <div
                                              key={file.name}
                                              className="flex items-center"
                                            >
                                              <span className="font-medium text-green-600">
                                                {file.name}
                                              </span>
                                            </div>
                                          )
                                        )}
                                        <button
                                          className=" mt-2 bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                          onClick={() =>
                                            setPhotoDuProduitModify(null)
                                          }
                                        >
                                          Change
                                        </button>
                                      </div>
                                    )}
                                    {!photoDuProduitModify && (
                                      <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                        <div className="space-y-1 text-center">
                                          <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                          <div className="flex text-sm text-gray-600">
                                            <label
                                              htmlFor="photoDuProduitModify"
                                              className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                            >
                                              <span>Upload a file</span>
                                              <input
                                                id="photoDuProduitModify"
                                                name="photoDuProduitModify"
                                                type="file"
                                                multiple
                                                className="sr-only"
                                                value=""
                                                onChange={
                                                  handlePhotoDuProduitModify
                                                }
                                              />
                                            </label>
                                            <p className="pl-1">
                                              or drag and drop
                                            </p>
                                          </div>
                                          <p className="text-xs text-gray-500">
                                            PNG, JPG, GIF up to 10MB
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <fieldset className="mb-6 margin-top">
                                <legend className="contents text-sm font-medium text-gray-600">
                                  Votre appareil est-il entretenu ?
                                </legend>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="oui"
                                      name="entretenu"
                                      type="radio"
                                      value="oui"
                                      onChange={(e) =>
                                        setEntretenuModify(e.target.value)
                                      }
                                      checked={
                                        entretenuModify &&
                                        entretenuModify.includes("oui")
                                      }
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor="oui"
                                      className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                      Oui
                                    </label>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      id="non"
                                      name="entretenu"
                                      type="radio"
                                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      value="non"
                                      onChange={(e) =>
                                        setEntretenuModify(e.target.value)
                                      }
                                      checked={
                                        entretenuModify &&
                                        entretenuModify.includes("non")
                                      }
                                    />
                                    <label
                                      htmlFor="non"
                                      className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                      Non
                                    </label>
                                  </div>
                                  {/*{entretenuModify === "oui" && (
                                    <div className="relative mt-4">
                                      <label
                                        htmlFor="annéeInstallation"
                                        className="sr-only"
                                      >
                                        Depuis quelle année
                                      </label>
                                      <select
                                        id="annéeInstallation"
                                        name="annéeInstallation"
                                        value={selectedYearEntretenuModify}
                                        onChange={handleSelectedYearModify}
                                        className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                      >
                                        {years.map((year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        ))}
                                      </select>
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                          className="fill-current h-4 w-4"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                      </div>
                                    </div>
                                        )}*/}
                                </div>
                              </fieldset>
                              {/*{entretenu === "oui" && (
                                <div className="relative mt-4">
                                  <label
                                    htmlFor="annéeInstallation"
                                    className="sr-only"
                                  >
                                    Depuis quelle année
                                  </label>
                                  <select
                                    id="annéeInstallation"
                                    name="annéeInstallation"
                                    value={selectedYearEntretenuModify}
                                    onChange={(e) =>
                                      setSelectedYearModify(e.target.value)
                                    }
                                    className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
                                  >
                                    {years.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                  </div>
                                </div>
                                    )}*/}
                            </form>
                          </div>
                          <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                              onClick={handleConfirmModify}
                            >
                              Modifier
                            </button>
                            <button
                              className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                              onClick={handleCancelModify}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <CRUDTable
              /*data={info.filter((item) => {
                if (!selectedValue && !selectedStatus) {
                  return true;
                }

                return selectedValue ? item.produit === selectedValue : true;
              })}*/
              data={info}
              link={`/product/`}
              /*pencilIcon={(id) => (
                <IoPencil
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "green",
                  }}
                  onClick={() => handleModifyClick(id)}
                />
              )}*/
              eyeIcon={
                <IoEyeOutline
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "#f8c408",
                  }}
                />
              }
              deleteIcon={(id) => (
                <IoTrashOutline
                  style={{ fontSize: "18px", color: "red", cursor: "pointer" }}
                  onClick={() => handleDeleteClick(id)}
                />
              )}
              //onDataChange={handleDataChange}
              //handleDeleteProduct={handleDeleteProduct}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserNewDevice;
