import React, { useEffect, useState } from "react";
import { BreadCrumb, CRUDTable } from "../../../Components";
import {
  IoEyeOutline,
  IoPencil,
  IoTrashOutline,
  IoAddCircleOutline,
} from "react-icons/io5";
import { DataTable } from "../../../Constants/dummy";
import { useStateContext } from "../../../contexts/ContextProvider";

const Reclamation = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [history, setHistory] = useState([]);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const { userType } = useStateContext();
  const apiUrl = 'https://lebontechnicien.net'

  const handleModalToggle = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [data, setData] = useState(DataTable);
  const handleDataChange = (newData) => {
    setData(newData);
  };

  const [claime, setClaime] = useState("");

  const handleClaime = (event) => {
    setClaime(event.target.value);
  };

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => setHistory(data))
      .catch((error) => console.error(error));
  }, []);

  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const monthName = monthNames[monthIndex];
    return `${day} ${monthName} ${year}`;
  };
  
  // Info is what is going to be shown in the table
  const info =
    history.length > 0
      ? history
          .filter((item) => item.type === "Demande de Réclamation")
          .map((item) => {
            return {
              created_at: item.created_at,
              Client: item.nom +" " + item.prenom,
              Message: item.claime,
              Status: item.status,
              id: item._id,
            };
          })
          .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)) 
          .map((item) => {
            return {
              Création: formatDate(item.created_at.split(" ").slice(1, 4).join(" ")),
              Client: item.Client,
              Message: item.Message,
              Status: item.Status,
              id: item.id,
            };
          })
      : [];
      

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const requestData = {
      Pid: productId,
      msg: claime,
      type: "Demande de Réclamation",
      email: sessionStorage.getItem("email"),
    };

    fetch(`${apiUrl}/request/create_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log("success");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
    setIsModalVisible(false);
    window.location.reload()
  };

  return (
    <>
      {userType === "Admin" && (
        <BreadCrumb
          From={""}
          To={"Réclamations"}
          hrefFrom={"liste-des-utilisateurs"}
        />
      )}
      {userType === "Superviseur" && (
        <BreadCrumb
          From={""}
          To={"Réclamations"}
          hrefFrom={"tableau-de-bord-superviseur"}
        />
      )}  
      <div className="mt-10 sm:mt-0 w-[92%] ml-auto mr-auto">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="md:col-span-2">
            <div className="px-4 sm:px-0">
              <div className="flex justify-between w-full mt-10">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Liste des réclamations
                </h3>
              </div>
              {isModalVisible ? (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => setIsModalVisible(false)}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Nouvelle Réclamations
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez remplir toutes les informations requises
                              dans le formulaire pour ajouter une nouvelle
                              réclamations.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={claime}
                                    onChange={handleClaime}
                                  />
                                </div>
                              </form>
                            </div>
                            <select
                              id="productId"
                              name="productId"
                              value={productId}
                              onChange={(e) => {
                                setProductId(e.target.value);
                                setProductName(
                                  pendingList.find(
                                    (item) => item._id === e.target.value
                                  ).produits
                                );
                              }}
                              className=" w-[93%] mt-1 block rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                            >
                              <option value="">
                                sélectionner votre produit
                              </option>
                              {pendingList.map((item) => (
                                <option
                                  key={item._id}
                                  value={item._id}
                                  className="w-90% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                                >
                                  <div className="flex gap-4">
                                    <div>
                                      {/*<h1 className="text-red">
                              {item.produits} 
                    </h1>*/}
                                    </div>
                                    <div>
                                      <p className="text-sm ml-2 bg-teal-500 text-white px-2 rounded">
                                        {item.produits}
                                      </p>
                                    </div>
                                  </div>
                                </option>
                              ))}
                            </select>
                            <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                onClick={handleSubmit}
                              >
                                Ajouter
                              </button>
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={() => setIsModalVisible(false)}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <CRUDTable
              data={info}
              link={`/user-intervention-details/`}
              eyeIcon={
                <IoEyeOutline
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    color: "#3e9f36",
                  }}
                />
              }
              onDataChange={handleDataChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};



export default Reclamation