import React from "react";
import { partners } from "../../Constants/dummy";

const PartnerBanner = () => {
  return (
    <section className="comment-ca-marche">
      <h2 className="leBonTech-h2">Accompagnement sur-mesure pour vos projets de formation</h2>
      <div
        style={{
          width: "10%",
          height: "2px",
          backgroundColor: "#3e9f36",
          marginTop: "-20px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>
      <div className="container" style={{marginTop: "20px"}}>
        {partners.map((partner, index) => (
          <div className="column">
            <>
              <a
                href={partner.page}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="partner-item-img" src={partner.image} alt={`Partner ${index + 1}`} />
              </a>
            </>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PartnerBanner;
