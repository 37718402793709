import React, { useEffect, useState } from "react";

const PageHeader = ({ TeamData }) => {
  const [techDetails, setTechDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [teamData, setTeamData] = useState([]);

  const apiUrl = "https://lebontechnicien.net";

  // Fetches Team Request
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/teams/get_team`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: TeamData }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTeamData(data);
      } catch (error) {
        console.error("Error fetching team data:", error.message);
      }
    };

    fetchData();
  }, [TeamData]);

  useEffect(() => {
    const fetchTechDetails = async () => {
      if (
        !teamData ||
        !teamData.TechnicienEquipe ||
        Object.keys(teamData.TechnicienEquipe).length === 0
      ) {
        return;
      }
      setIsLoading(true);

      try {
        const techDetailsPromises = Object.values(
          teamData.TechnicienEquipe
        ).map(async (techId) => {
          const res = await fetch(`${apiUrl}/user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify({ technicianId: techId }),
          });

          const data = await res.json();

          if (!res.ok) {
            throw new Error(data.msg);
          }

          return {
            nom: data.nom,
            prenom: data.prenom,
            telephone: data.telephone,
          };
        });

        const techDetailsData = await Promise.all(techDetailsPromises);
        setTechDetails(techDetailsData);
      } catch (err) {
        setError(err.message);
      }

      setIsLoading(false);
    };

    fetchTechDetails();
  }, [teamData]);

  const getInitials = (name) => {
    // Check if the name is defined
    if (!name) {
      return ""; // or any default value
    }

    const names = name.split(" ");
    if (names.length === 1) {
      return name.slice(0, 2).toUpperCase();
    }
    return (names[0][0] + names[1][0]).toUpperCase();
  };

  return (
    <div className=" lg:p-4  w-full text-start rounded-md flex justify-start gap-20 flex-wrap">
      <div className="w-full lg:w-[20%]">
        <div className="flex justify-center ml-auto mr-auto mt-6 mb-2">
          <div
            className="inline-block lg:h-20 lg:w-20 xxs:w-60 xxs:h-60 overflow-hidden rounded-full"
            style={{ backgroundColor: teamData.couleurEquipe }}
          >
            <span className="h-full w-full text-white lg:text-xl xxs:text-4xl font-bold flex items-center justify-center">
              {getInitials(teamData.nomEquipe)}
            </span>
          </div>
        </div>
        <p className="text-center">{teamData.nomEquipe}</p>
      </div>
      <div className="w-full lg:w-[70%]">
        <div className="border border-gray-200 w-[92%] ml-auto mr-auto ">
          <dl>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                Membres de l'équipe
              </dt>
              <dd className="mt-1 text-sm text-gray-900 ssm:mt-0">
                {Object.keys(teamData)
                  .filter((key) => key.startsWith("TechnicienEquipeNom"))
                  .slice(0, -1)
                  .map((key, index) => (
                    <p
                      key={index}
                      className="text-black font-poppins font-normal"
                    >
                      {index % 2 === 0 ? ( // Check if it's an even index to add hyphen
                        <span>
                          {teamData[key].split("-")[0]}{" "}
                          {teamData[key].split("-")[1]}{" "}
                          <span className="font-bold text-[#3e9f36]">- </span>
                        </span>
                      ) : (
                        <span>
                          {teamData[key].split("-")[0]}{" "}
                          {teamData[key].split("-")[1]}{" "}
                        </span>
                      )}
                    </p>
                  ))}
              </dd>
            </div>

            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                Véhicule de l'équipe
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                {teamData.plaqueEquipe}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
