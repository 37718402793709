import React, {useEffect, useState} from 'react';
import {FiSettings} from 'react-icons/fi';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Outlet } from 'react-router';
import {DashFooter, DashNav, DashSideBar, Footer, ThemeSettings} from '../../Components'
import { useStateContext } from '../../contexts/ContextProvider';
import { UserData } from '../../Constants/dummy';

const DashboardLayout = () => {
    const {activeMenu, setActiveMenu} = useStateContext();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const userTypeArray = UserData.map((item) => item.userType);
          setUsers(userTypeArray);
        }
        fetchData();
      }, []);


  return (
    <div className='flex relative dark:bg-main-dark-bg bg-white'>
        {/*<div className='fixed right-4 bottom-4' style={{zIndex: '1000'}}>
            <p id="my-element" data-tooltip-content="settings">
                <button 
                    type='button'
                    className='text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white'
                    style={{background: 'blue', borderRadius: '50%'}}
                >
                    <FiSettings/>
                </button>
            </p>
            <ReactTooltip anchorId="my-element" />
        </div>*/}


            {activeMenu ?(
                <div className='w-72 fixed dark:bg-secondary-dark-bg bg-white'>
                    <DashSideBar/>
                </div>
            ) : (
                <div className='w-0 dark:bg-secondary-dark-bg'>
                    <DashSideBar/>
                </div>
            )}


        <div className={
            activeMenu? 'dark:bg-main-bg  min-h-screen md:ml-72 w-full'
            : 'dark:bg-main-bg  min-h-screen flex-2 w-full'
        }>
            <div className='fixed md:static bg-red dark:bg-main-dark-bg w-full '>
                <DashNav name={UserData[0].nom} profileImage={UserData[0].image} />
            </div>
            <div className='min-h-screen'>
              <Outlet />     
            </div>
            {/*<DashFooter/>*/}
            <Footer className='mt-10'/>
        </div>
    </div>
  )
};

export default DashboardLayout;