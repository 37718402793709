import React from 'react'
import Stars from './Stars';

const Rating = ({ rating }) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(<Stars selected={i < rating} key={i} />);
    }
    return (
      <div className="rating flex mt-1">
        {stars}
      </div>
    );
}

export default Rating