import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const onDocumentLoad = ({ numPages }) => {
    setNumPages(numPages);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const downloadPdf = () => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div style={{ display: "flex", height: "100%" }}>
        <div className="flex w-[92%] gap-5 ml-5">
          <button
            className="inline-flex justify-center rounded-md border border-green-600 bg-transparent py-2 px-4 text-sm font-medium text-green-600 hover:text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mt-4"
            onClick={openModal}
          >
            Ouvrir le devis
          </button>
          <a
            className="inline-flex justify-center rounded-md border border-green-600 bg-transparent py-2 px-4 text-sm font-medium text-green-600 hover:text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mt-4"
            href={url}
            download
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            Télécharger le devis
          </a>
        </div>
      </div>
      {showModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-50"
              onClick={closeModal}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-transparent rounded-md">
                <div className="mt-3">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <Document file={url} onLoadSuccess={onDocumentLoad}>
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ))}
                    </Document>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PdfViewer;
