import React, { createContext, useContext, useEffect, useState } from 'react'

const StateContext = createContext();
const initialState =  {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
}

export const ContextProvider = ({children}) => {
    const [activeMenu, setActiveMenu] = useState(false);
    const [isClicked, setIsClicked] = useState(initialState);
    const handleClick = (clicked) => {
        setIsClicked({ ...initialState, [clicked]: true });
    }
    const [screenSize, setScreenSize] = useState(undefined)
    const [userData, setUserData] = useState('');
    const [error, setError] = useState(null);  
    const [isLoading, setIsLoading] = useState(false)
    const [userType, setUserType] = useState('')
    const [techPro, setTechPro] = useState('')
    const [id, setId] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const [profile, setProfile] = useState('');
    const [entreprise, setEntreprise] = useState('');
    // eslint-disable-line
    const [typeDuBien, setTypeDuBien] = useState([]);    
    const apiUrl = 'https://lebontechnicien.net'

    useEffect(() => {
        const getUserData = async () => {
            setIsLoading(true);
            try {
                const res = await fetch(`${apiUrl}/user`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${sessionStorage.getItem("token")}`
                    },
                    body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` })
                });
                const data = await res.json();
                if (!res.ok) {
                    throw new Error(data.msg);
                }
                setUserData(data);
                setUserType(data.type);
                setTechPro(data.ProTech);
                setNom(data.nom);
                setPrenom(data.prenom);
                setEmail(data.email);
                setPassword(data.password);
                setAdresse(data.adresse);
                setTelephone(data.telephone);
                setTypeDuBien(data.typeDuBien);   
                setId(data._id)        
                setProfile(data?.profile)
                setEntreprise(data?.entreprise)
                sessionStorage.setItem("raisonSocial", data.raisonSocial);
                sessionStorage.setItem("id", data._id);
                sessionStorage.setItem("clientSecret", data.client_secret);      
                sessionStorage.setItem("nom", data.nom);      
                sessionStorage.setItem("prenom", data.prenom);
                //sessionStorage.setItem("entreprise", data.entreprise);  

                console.log('entreprise', entreprise)
    
                
                console.log('entreprise', sessionStorage.getItem("entreprise"))
            } catch (err) {
                setError(err.message);
            }
            setIsLoading(false);
        };
        getUserData();
    }, [sessionStorage.getItem("token")]);

    return (
        <StateContext.Provider
            value={{
                activeMenu,
                setActiveMenu,
                isClicked,
                setIsClicked, 
                handleClick,
                screenSize, 
                setScreenSize,
                userType,
                nom,
                prenom,
                id,
                adresse,
                telephone,
                profile,
                entreprise
            }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext
(StateContext);
