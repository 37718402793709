import React from "react";

const LeBonTechnicienWorks = ({ title, subtitle, paragraph, imgSrc }) => {
  return (
    <section className="leBonTech-section activites-section">
      <div className="nouvelle-demande">
        <h2 className="leBonTech-h2">{title}</h2>
        <p className="leBonTech-p">{paragraph}</p>
      </div>
      {imgSrc && <img src={imgSrc} alt={title} />}
      <div style={{ clear: "both" }}></div>
    </section>
  );
};

export default LeBonTechnicienWorks;
