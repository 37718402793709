import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useStateContext } from "../../contexts/ContextProvider";
import { validateTextInput } from "../FormValidation";
import { id } from "date-fns/locale";
import { IoAddCircleOutline } from "react-icons/io5";
import Rating from "./Rating";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import { Link, NavLink } from "react-router-dom";

const BrowseGrid = ({ contactList, setSelectedContact }) => {
  const [rating, setRating] = useState(0);
  //const [userType, setUserType] = useState('Technician')
  const [showDemandeModal, setShowDemande] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [showUserDemandeModal, setShowUserDemande] = useState(false);
  const [fetchUserName, setFetchUserName] = useState("");
  const [fetchUserLastName, setFetchUserLastName] = useState("");
  const [fetchComment, setFetchComment] = useState("");
  const [fetchRatings, setFetchRatings] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [error, setError] = useState("");
  //const [userType, setUserType] = useState('Technician')
  const { userType, nom, prenom } = useStateContext();
  const [textError, setTextError] = useState("");
  const [textinput, setTextInput] = useState(
    "Cher client, Je vous assure sérénité et fiabilité en tant qu'expert en installations et réparations d'équipements d'énergie renouvelable. Je suis disponible pour réaliser l'intervention demandée avec efficacité.Cordialement,"
  );
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [history, setHistory] = useState([]);
  const [techs, setTechs] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [techStartDate, setTechStartDate] = useState(new Date());
  const [techEndDate, setTechEndDate] = useState(new Date());
  const techPro = sessionStorage.getItem("TechPro");
  console.log("techProtechPro", techPro);
  const apiUrl = 'https://lebontechnicien.net'

  const handleshowRatingModalClose = () => {
    setShowRatingModal(!showRatingModal);
    //console.log("showRatingModal closed", showRatingModal);
  };

  const handleshowRatingModalOpen = () => {
    setShowRatingModal(true);
    console.log(showRatingModal);
    //console.log("showRatingModal closed", showRatingModal);
  };

  const handleClick = (newRating) => {
    setRating(newRating);
  };

  const handleDemandeClick = (id) => {
    setSelectedId(id);
    setShowDemande(true);
  };

  const handleDemandeUserClick = (id) => {
    setSelectedId(id);
    setShowUserDemande(true);
  };

  const handleDemandeUserDelete = () => {
    setShowUserDemande(false);
    setSelectedId(null);
  };

  const handleStartDate = (date) => {
    setTechStartDate(date);
  };

  const handleEndDate = (date) => {
    setTechEndDate(date);
  };

  const handleDemandeDelete = () => {
    setShowDemande(false);
    setSelectedId(null);
  };

  const handleText = (event) => {
    setTextInput(event.target.value);
    if (!validateTextInput(event.target.value)) {
      setTextError("le champ de saisie n'accepte que du texte");
    } else {
      setTextError(null);
    }
  };

  //Gets the id of the selected items to be deleted
  const handleConfirmDelete = () => {
    if (contactList) {
      const selectedElement = contactList.find(
        (contact) => contact._id === selectedId
      );
      handleSubmit(selectedElement);
      setShowDemande(false);
      setSelectedId(null);
    }
  };

  const handleConfirmUserDelete = () => {
    if (contactList) {
      const selectedElement = contactList.find(
        (contact) => contact.Pid === selectedId
      );
      handleSubmitUser(selectedElement);
      setShowDemande(false);
      setSelectedId(null);
    }
  };

  // Fetching user product data from API and setting the state
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            email: `${sessionStorage.getItem("email")}`,
          }),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  // Fetching user data from API and setting the state
  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        setFetchUserName(data.userName);
        setFetchUserLastName(data.userLastName);
        setFetchComment(data.comment);
        setFetchRatings(data.rating);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, [sessionStorage.getItem("token")]);

  // Request To Create a Request to a User
  const handleSubmit = async (selectedElement) => {
    if (!selectedElement) {
      return;
    }

    const tech_info = Array.isArray(selectedElement.tech_info)
      ? [
          ...selectedElement.tech_info,
          {
            id: sessionStorage.getItem("id"),
            tech_msg: textinput,
            tech_raison: sessionStorage.getItem("raisonSocial"),
          },
        ]
      : [
          {
            id: sessionStorage.getItem("id"),
            tech_msg: textinput,
            tech_raison: sessionStorage.getItem("raisonSocial"),
          },
        ];

    const requestData = {
      typeDeProjet: selectedElement.typeDeProjet,
      typeDePrestation: selectedElement.typeDePrestation,
      typeDuBien: selectedElement.typeDuBien,
      materiaux: selectedElement.materiaux,
      fin: selectedElement.fin,
      information: selectedElement.information,
      zone: selectedElement.zone,
      Pid: selectedElement.Pid,
      NomUtilisateur: selectedElement.nom,
      PrenomUtilisateur: selectedElement.prenom,
      email: sessionStorage.getItem("email"),
      Technicien: nom,
      Technicienprenom: prenom,
      msg: textinput,
      tid: sessionStorage.getItem("id"),
      uid: sessionStorage.getItem("id"),
      rid: selectedElement._id,
      ratings: selectedElement.rating,
      userName: selectedElement.userName,
      comment: selectedElement.comment,
      rating: selectedElement.rating,
      techStartDate: techStartDate,
      techEndDate: techEndDate,
      raisonSociale: sessionStorage.getItem("raisonSocial"),
      tech_info,
    };

    //Fetch request to aacept a tech
    fetch(`${apiUrl}/request/tech_accept`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data === "Reqest accepted successfuly") {
          console.log("success");
          //setConfirmationModal(true);
          setTimeout(() => {
            setConfirmationModal(false);
            window.location.href = "/recherche";
          }, 4000);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
  };

  // Fetching user data from API and setting the state
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type: "Tech" }),
        });
        const users = await response.json();
        setTechs(users);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [inputValue]);

  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) {
      return 0;
    }
    const sum = reviews.reduce((acc, curr) => acc + parseInt(curr.rating), 0);
    return Math.round(sum / reviews.length);
  };

  const tech = techs.filter((tech) => tech._id === selectedItemId)[0];
  console.log("selectedItemId", tech);
  console.log("setSelectedContact", setSelectedContact);

  const averageRating =
    tech && tech.Review ? calculateAverageRating(tech.Review) : 0;

  // Request To Create a Request to a Technician
  const handleSubmitUser = async (selectedElement) => {
    if (!selectedElement) {
      return;
    }

    const requestData = {
      typeDeProjet: selectedElement.typeDeProjet,
      typeDePrestation: selectedElement.typeDePrestation,
      typeDuBien: selectedElement.typeDuBien,
      materiaux: selectedElement.materiaux,
      fin: selectedElement.fin,
      information: selectedElement.information,
      zone: selectedElement.zone,
      Pid: selectedElement.Pid,
      NomUtilisateur: selectedElement.nom,
      PrenomUtilisateur: selectedElement.prenom,

      type: "Demande D'intervention",
      email: sessionStorage.getItem("email"),
      ui: sessionStorage.getItem("id"),
      Technicien: nom,
      Technicienprenom: prenom,
      msg: textinput,
      tid: selectedElement._id,
      rid: productId,
    };

    //Fetch request to aacept a user
    fetch(`${apiUrl}/request/user_accept`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          console.log("success");
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while registering");
        console.error(error);
      });
  };

  return (
    <>
      {contactList?.map((contact, index) => (
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1] }}
          transition={{ duration: index / 5 }}
          drag={false}
          dragElastic={1}
          dragConstraints={{ top: 1, bottom: 1, right: 1, left: 1 }}
          className="bg-white text-white h-[180]  rounded-lg shadow-md"
          key={index}
          onClick={() => setSelectedContact(contact)}
        >
          {userType === "User" ? (
            <div
              onClick={() => {
                setSelectedItemId(contact._id);
                handleshowRatingModalClose();
              }}
            >
              {contact.photoIdentite !== "null" &&
              contact.photoIdentite !== undefined &&
              contact.photoIdentite !== "" ? (
                <img
                  alt="user"
                  className="fit-image w-28 h-28 rounded-full mx-auto mt-7"
                  src={`${apiUrl}${contact.photoIdentite}`}
                  onClick={handleshowRatingModalClose}
                />
              ) : (
                <div className="flex justify-center mt-8 ml-auto mr-auto">
                  <span className="inline-block h-20 w-20 overflow-hidden rounded-full bg-gray-100">
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </div>
              )}
            </div>
          ) : null}

          <figcaption className="text-start mt-0 p-4 ">
            <p className="text-gray-700 font-semibold text-lg mb-2 text-center">
              {contact.typeDeProjet}
            </p>
            <p className="text-gray-500 mb-2 text-center font-body text-base">
              {contact.typeDePrestation}
            </p>
            {userType === "Tech" ? (
              <>
                <>
                  <h2 className="text-gray-500 mb-2 text-center font-body text-sm">
                    Demandeur: {contact.nom} {contact.prenom}
                  </h2>

                  <h2 className="text-gray-500 mb-2 text-center font-body text-xs">
                    {contact.accepted_by?.length} Demandes en attente
                  </h2>
                </>
                <>
                  <h2 className="text-gray-500 mb-2 text-center font-body text-sm">
                    {contact.created_at}
                  </h2>
                </>
              </>
            ) : (
              <>
                <>
                  <h2 className="text-gray-500 mb-2 text-center font-body text-sm">
                    {contact.nom} {contact.prenom}
                  </h2>
                  <div
                    className="text-gray-500 mb-2 text-center font-body text-sm overflow-y-auto max-h-28"
                    style={{ scrollbarWidth: "thin" }}
                  >
                    {contact.domaine &&
                      contact.domaine
                        .split(",")
                        .map((item, index) => (
                          <div key={index}>{item.trim()}</div>
                        ))}
                  </div>
                </>
              </>
            )}

            {userType === "Tech" && techPro === "oui" && (
              <div className="ml-auto mr-auto flex justify-center">
                <Link
                  to={`/user-intervention-details/${contact._id}`}
                  className="text-gray-700 font-semibold"

                >
                  Plus de détails
                </Link>
              </div>
            )}

            <div className="ml-auto mr-auto flex justify-center">
              {userType === "Tech" ? (
                <button
                  onClick={(id) => handleDemandeClick(contact._id)}
                  className=" 
                    userProfile-btn 
                    inline-flex 
                    justify-center 
                    rounded-md 
                    border 
                    border-transparent 
                    bg-green-600 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-green-700 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-green-500 
                    focus:ring-offset-2
                    ml-auto
                    mr-auto
                    mt-4
                  "
                  disabled={contact.accepted_by?.length >= 3}
                >
                  Répondre a {contact.nom}
                </button>
              ) : null}
            </div>
          </figcaption>
        </motion.button>
      ))}
      {confirmationModal === true && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="mt-3">
                <div className="mt-2 text-center text-center">
                  <h4 className="text-lg font-medium text-gray-800">
                    Demande créé avec succès !
                  </h4>
                  <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                    Nous sommes heureux de vous informer que votre demande a été
                    créée avec succès. Veuillez patienter pendant que
                    l'utilisateur examine votre demande et décide de l'accepter
                    ou non. Nous vous tiendrons informé(e) dès que nous aurons
                    une réponse.
                  </p>
                  <div className="items-center gap-2 mt-3 sm:flex"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDemandeModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowDemande(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 ">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800 mb-4">
                      Répondre à la demande d'intervention
                    </h4>
                    <div className="mb-4">
                      <div className="mt-1">
                        <textarea
                          id="Information"
                          name="Information"
                          rows={3}
                          className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-green-500 focus:ring-green-500 sm:text-sm p-4 ml-auto mr-auto h-40"
                          placeholder="lorem ipsum"
                          defaultValue={""}
                          value={textinput}
                          onChange={handleText}
                        />
                        <h4 className="text-lg font-medium text-gray-800 mb-4 mt-4">
                          Créneau durant lequel vous serez prêt à intervenir:
                        </h4>
                        <div>
                          <div className="flex justify-between mt-2 gap-1">
                            <p className="mt-2">Du</p>
                            <DatePicker
                              selected={techStartDate}
                              onChange={handleStartDate}
                              dateFormat="dd/MM/yyyy"
                              locale={fr}
                              className="w-[80%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-2 px-2 rounded-lg rounded-md  flex justify-between items-center text-center"
                            />
                          </div>
                          <div className="flex justify-between mt-2 gap-1">
                            <p className="mt-2">Au</p>
                            <DatePicker
                              selected={techEndDate}
                              onChange={handleEndDate}
                              dateFormat="dd/MM/yyyy"
                              locale={fr}
                              className="w-[80%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-2 px-2 rounded-lg rounded-md  flex justify-between items-center text-center"
                            />
                          </div>
                        </div>

                        {textError && textinput.length > 0 && (
                          <div style={{ color: "red" }}>{textError}</div>
                        )}
                      </div>
                    </div>
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                        onClick={() => handleConfirmDelete()}
                      >
                        Répondre
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleDemandeDelete()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showUserDemandeModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowDemande(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 ">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-medium text-gray-800 mb-4">
                      Envoyer une demande d'intervention
                    </h4>
                    <div className="mb-4">
                      <div className="mt-1">
                        <textarea
                          id="Information"
                          name="Information"
                          rows={3}
                          className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-green-500 focus:ring-green-500 sm:text-sm p-4 ml-auto mr-auto h-40"
                          placeholder="lorem ipsum"
                          defaultValue={""}
                          value={textinput}
                          onChange={handleText}
                        />
                        {textError && textinput.length > 0 && (
                          <div style={{ color: "red" }}>{textError}</div>
                        )}
                      </div>
                      <select
                        id="productId"
                        name="productId"
                        value={productId}
                        onChange={(e) => {
                          setProductId(e.target.value);
                          setProductName(
                            history.find((item) => item._id === e.target.value)
                              .produits
                          );
                        }}
                        className=" mt-4 w-[100%] cursor-pointer  block rounded-md  border-1 border-gray-200 focus:border-green-500 focus:ring-green-500 sm:text-sm p-4"
                      >
                        <option value="">sélectionner votre produit</option>
                        {history.map((item) => (
                          <option
                            key={item._id}
                            value={item._id}
                            className="w-98% ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 rounded-lg flex justify-between items-center px-20"
                          >
                            <div className="flex gap-4">
                              <div>
                                {/*<h1 className="text-red">
                              {item.produits} 
                    </h1>*/}
                              </div>
                              <div>
                                <p className="text-sm ml-2 bg-teal-500 text-white px-2 rounded">
                                  {item.typeDeProjet} - {item.typeDePrestation}
                                </p>
                              </div>
                            </div>
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                        onClick={() => handleConfirmUserDelete()}
                      >
                        Répondre
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-green-600 focus:ring-2"
                        onClick={() => handleDemandeUserDelete()}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {showRatingModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={handleshowRatingModalClose}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    {techs
                      ?.filter((tech) => tech._id === selectedItemId)
                      .map((tech) => {
                        return (
                          <div key={tech.id}>
                            <div className="mb-2">
                              <h4 className="text-lg font-bold text-green-700 flex items-center">
                                Domaine de compétence:
                              </h4>
                              {tech.domaine.split(",").map((d, index) => (
                                <div key={index}>
                                  {d.trim()}
                                  <br />
                                </div>
                              ))}
                              {tech.departement}
                            </div>
                            <div className="mb-2">
                              <h4 className="text-lg font-bold text-green-700 flex items-center">
                                Zone d'intervention:
                              </h4>
                              {tech.zone}
                            </div>
                          </div>
                        );
                      })}
                    <h4 className="text-lg font-bold text-green-700 flex items-center">
                      Avis des utilisateurs
                      {techs.some(
                        (tech) =>
                          tech._id === selectedItemId &&
                          tech.Review &&
                          tech.Review.length > 0
                      ) && (
                        <div className="flex items-center ml-4 gap-2">
                          <Rating rating={averageRating} />
                          <span>
                            (
                            {
                              techs.filter(
                                (tech) => tech._id === selectedItemId
                              )[0].Review.length
                            }{" "}
                            avis)
                          </span>
                        </div>
                      )}
                    </h4>

                    <>
                      {techs.some(
                        (tech) =>
                          tech._id === selectedItemId &&
                          tech.Review &&
                          tech.Review.length > 0
                      ) ? (
                        techs
                          .filter((tech) => tech._id === selectedItemId)[0]
                          .Review.map((review, index) => (
                            <div
                              key={index}
                              className="modal-content mr-4 rounded-sm border-b border-gray-200 pb-4"
                            >
                              <div className="flex justify-start gap-2 mt-4">
                                <h3>
                                  {review.userName} {review.userLastName}
                                </h3>
                                <Rating rating={review.rating} />
                              </div>
                              <div className="mt-2">
                                <p className="text-sm">{review.comment}</p>
                              </div>
                            </div>
                          ))
                      ) : (
                        <p>Aucun avis à afficher.</p>
                      )}
                    </>
                    <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                        onClick={handleshowRatingModalClose}
                      >
                        Fermer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BrowseGrid;
