import React, { useEffect, useState } from "react";
import {
  CRUDTable,
  CreationDevis,
  Detail,
  Modal,
  PdfViewer,
  Rating,
  ReturnButton,
} from "../../../Components";
import { useParams } from "react-router";
import { useStateContext } from "../../../contexts/ContextProvider";
import RatingSystem from "../../../Components/Dashboard/User/RatingSystem";
import { useNavigate } from "react-router-dom";
import {
  IoCheckmarkOutline,
  IoCloseOutline,
  IoEyeOutline,
} from "react-icons/io5";
import { UserNotificationData } from "../../../Constants/dummy";
import InterventionReportForm from "../../../Components/Dashboard/InterventionReportForm";
import { IoAddCircleOutline, IoTrash } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { FiX } from "react-icons/fi"; // Import the Feather X icon
import pdfImage from "../../../Assets/pdf.png";

const InterventionDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState(UserNotificationData);
  const [history, setHistory] = useState([]);
  const [historyTech, setHistoryTech] = useState([]);
  const [techCloseModal, setTechCloseModal] = useState(false);
  const [techCloseModalPac, setTechCloseModalPac] = useState(false);
  const { userType, nom, prenom } = useStateContext();
  const [step, setStep] = useState(1);
  const [pay, setPay] = useState("");
  const [nomtec, setNomTec] = useState("");
  const [prenomtec, setPrenomTec] = useState("");
  const [reviewTec, setReviewTec] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [finished, setFinished] = useState(false);
  const [intervention, setIntervention] = useState(null);
  const [reason, setReason] = useState(null);
  const [idea, setIdea] = useState(null);
  const [money, setMoney] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [UserNotCloseModal, setUSerNotCloseModal] = useState(false);
  const [techClaimeModal, setTechClaimeModal] = useState(false);
  const [claime, setClaime] = useState("");
  const [responses, setResponse] = useState(null);
  const [denyResponse, setDenyResponse] = useState(null);
  const [RatingModal, setRatingProblem] = useState(false);
  const [techs, setTechs] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { ids } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pendingList, setPendingList] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [product, setProduct] = useState("");
  const [user, setUser] = useState("");
  const [claimUser, setClaimUser] = useState("");
  const [createdUser, setCreatedUser] = useState("");
  const [techAcceptResponse, setTechAcceptResponse] = useState([]);
  const entreprise = sessionStorage.getItem("entreprise");
  const [TechnicianPicked_id, setTechnicianPicked_id] = useState("");
  const [Intervention_id, setIntervention_id] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleLitige, setIsModalVisibleLitige] = useState(false);
  const [adminDescriptionMsg, setAdminDescriptionMsg] = useState("");
  const [adminActionMsg, setAdminActionMsg] = useState("");
  const [creationDevisModal, setCreationDevisModal] = useState(false);
  const [creationDateModal, setCreationDateModal] = useState(false);
  const [problemType, setProblemType] = useState("");
  const [detailedDescription, setDetailedDescription] = useState("");
  const [needAnotherIntervention, setNeedAnotherIntervention] = useState("");
  const [diametreLiaisonsFrigorifiques, setDiametreLiaisonsFrigorifiques] =
    useState("");
  const [liaisonsFrigorifiques, setLiaisonsFrigorifiques] = useState("");
  const [fluideFrigorigene, setFluideFrigorigene] = useState("");
  const [CERFA, setCERFA] = useState("");
  const [hydrauliques, setHydrauliques] = useState("");
  const [hydrauliquesModule, setHydrauliquesModule] = useState("");
  const [raccordementsElectriques, setRaccordementsElectriques] = useState("");
  const [vannes, setVannes] = useState("");
  const [reglageReglage, setReglageReglage] = useState("");
  const [climatisation, setClimatisation] = useState("");
  const [appoint, setAppoint] = useState("");
  const [PacClimatisation, setPacClimatisation] = useState("");
  const [prescriptionsConstructeur, setPrescriptionsConstructeur] =
    useState("");
  const [alimentationElectrique, setAlimentationElectrique] = useState("");
  const [arret, setArret] = useState("");
  const [observations, setObservations] = useState("");
  const [messageError, setMessageError] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [pictures, setPictures] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [prod_photoDuProduit, setPhotoDuProduit] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const techPro = sessionStorage.getItem("TechPro");
  const proTeam = sessionStorage.getItem("ProTeam");

  const handlePrintQR = () => {
    // Create a new window for printing
    const printWindow = window.open("", "_blank");

    // Specify the content to be printed (in this case, the QR code image)
    printWindow.document.write(
      "<html><head><title>Print QR Code</title></head><body>"
    );

    // Set A4 dimensions (210mm x 297mm)
    printWindow.document.write(
      '<div style="width: 100mm; height: 100mm; margin: 0 auto;">'
    );

    // Adjusted image dimensions to fit A4 page
    printWindow.document.write(
      `<img src="https://lebontechnicien.net/${selectedHistory.QR}" alt="${selectedHistory._id}" style="width: 100%; height: auto; object-fit: contain;" />`
    );

    printWindow.document.write("</div></body></html>");

    // Close the document and trigger the print
    printWindow.document.close();
    printWindow.print();
  };

  const downloadFile = (url) => {
    if (url.endsWith(".pdf")) {
      // If it's a PDF, open it in a new tab/window for download.
      window.open(url, "_blank");
    } else {
      // For other file types, initiate a download using the `fetch` API.
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "downloaded_file";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Download error: ", error));
    }
  };

  const handleAddPhotos = async () => {
    setConfirmationModal(true);
    const email = sessionStorage.getItem("email");
    const formData = new FormData();
    formData.append("email", email);
    formData.append("rid", id);
    formData.append("TechMessage", message);

    if (prod_photoDuProduit && prod_photoDuProduit.length) {
      let startingIndex = photoKeys.length >= 0 ? photoKeys.length : 0;
      for (let i = 0; i < prod_photoDuProduit.length; i++) {
        formData.append(
          `photoDuProduit[${startingIndex}]`,
          prod_photoDuProduit[i]
        );
        startingIndex++;
      }
    }

    const response = await fetch(`${apiUrl}/request/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    });

    //const data = await response.json();
    setConfirmationModal(false);
    window.location.reload();
  };

  const handlePhotoDuProduit = (event) => {
    event.preventDefault();
    const newFiles = event.target.files;
    const existingFiles = prod_photoDuProduit
      ? Array.from(prod_photoDuProduit)
      : [];

    // Combine existing files with new files
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
    console.log("setPhotoDuProduithandleAddMorePictures", prod_photoDuProduit);
  };

  const handleAddMorePictures = (event) => {
    event.preventDefault();
    const newFiles = event.target.files;
    const existingFiles = prod_photoDuProduit
      ? Array.from(prod_photoDuProduit)
      : [];

    // Combine existing files with new files
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
    console.log("setPhotoDuProduithandleAddMorePictures", prod_photoDuProduit);
  };

  const handleRemovePicture = (index) => {
    console.log(
      "Before removing picture - prod_photoDuProduit:",
      prod_photoDuProduit
    );

    // Ensure prod_photoDuProduit is always treated as an array
    const updatedFiles = Array.isArray(prod_photoDuProduit)
      ? prod_photoDuProduit.filter((_, i) => i !== index)
      : [];

    setPhotoDuProduit(updatedFiles);

    console.log("After removing picture - prod_photoDuProduit:", updatedFiles);
  };

  const apiUrl = "https://lebontechnicien.net";

  // Function to open the modal and set the selected image
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedImage("");
    setIsModalOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePictureUpload = (e) => {
    const maxPictures = 5; // Maximum number of pictures allowed
    const newPictures = Array.from(e.target.files);

    // Check if the total number of pictures exceeds the limit
    if (pictures.length + newPictures.length > maxPictures) {
      // You may handle the error here or simply ignore the additional pictures
      // For example, you can show a message to the user that only 5 pictures are allowed.
      return;
    }

    // Resize the pictures and store them in the state
    Promise.all(
      newPictures.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;

            image.onload = () => {
              // Calculate the desired width and height to resize the image
              const maxWidth = 800;
              const maxHeight = 800;
              let width = image.width;
              let height = image.height;

              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }

              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;

              const ctx = canvas.getContext("2d");
              ctx.drawImage(image, 0, 0, width, height);

              canvas.toBlob(
                (blob) => {
                  resolve(new File([blob], file.name, { type: "image/jpeg" }));
                },
                "image/jpeg",
                0.9
              );
            };
          };

          reader.readAsDataURL(file);
        });
      })
    ).then((resizedPictures) => {
      setPictures([...pictures, ...resizedPictures]);
    });
  };

  const [elements, setElements] = useState([
    { title: "", description: "", price: "", quantity: "" },
  ]);

  const handleCreationDevisModal = () => {
    setCreationDevisModal(false);
  };

  const handleCreationDateModal = () => {
    setCreationDateModal(false);
  };

  const handleAddElement = () => {
    const newElement = { title: "", description: "", price: "", quantity: "" };
    setElements([...elements, newElement]);
  };

  const handleRemoveElement = (index) => {
    const newElements = [...elements];
    newElements.splice(index, 1);
    setElements(newElements);
  };

  const handleChangeElement = (index, field, value) => {
    const newElements = [...elements];
    newElements[index][field] = value;
    setElements(newElements);
  };

  const [showButtons, setShowButtons] = useState(
    sessionStorage.getItem("notification")
  );

  const handleModalToggle = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleModalToggleLitige = () => {
    setIsModalVisibleLitige(!isModalVisibleLitige);
  };

  /*if (showButtons === "oui") {
    console.log("showButtons", showButtons);
  }*/

  const techCloseRequestModalN1 = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", sessionStorage.getItem("email"));
    formData.append("id", sessionStorage.getItem("id"));
    formData.append("rid", id);
    formData.append("problemType", problemType);
    formData.append("detailedDescription", detailedDescription);
    formData.append("needAnotherIntervention", needAnotherIntervention);
    formData.append(
      "diametreLiaisonsFrigorifiques",
      diametreLiaisonsFrigorifiques
    );
    formData.append("liaisonsFrigorifiques", liaisonsFrigorifiques);
    formData.append("fluideFrigorigene", fluideFrigorigene);
    formData.append("alimentationElectrique", alimentationElectrique);
    formData.append("CERFA", CERFA);
    formData.append("hydrauliques", hydrauliques);
    formData.append("hydrauliquesModule", hydrauliquesModule);
    formData.append("raccordementsElectriques", raccordementsElectriques);
    formData.append("vannes", vannes);
    formData.append("reglageReglage", reglageReglage);
    formData.append("climatisation", climatisation);
    formData.append("appoint", appoint);
    formData.append("PacClimatisation", PacClimatisation);
    formData.append("prescriptionsConstructeur", prescriptionsConstructeur);
    formData.append("arret", arret);
    formData.append("observations", observations);

    // Append each picture to the formData
    pictures.forEach((picture, index) => {
      formData.append(`reportPictures[${index}]`, picture);
    });

    if (pictures && pictures.length) {
      formData.append("pictures", pictures.name);
    }
    if (pictures && pictures.length) {
      formData.append("pictures", pictures);
      for (let i = 0; i < pictures.length; i++) {
        formData.append("pictures", pictures[i]);
      }
    }

    try {
      let url = `${apiUrl}/temp/tech_close_request`; // Default endpoint
      if (needAnotherIntervention === "Oui") {
        //console.log("Besoin d'une autre intervention");
        url = `${apiUrl}/temp/user_onhold_request`; // Update the endpoint
        //setCreationDevisModal(true);
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();
      navigate(`/user-history`);
      // Handle the response or perform other actions
    } catch (error) {
      console.error(error);
    }
  };

  const techCloseRequest = async (e) => {
    const formData = new FormData();
    formData.append("email", sessionStorage.getItem("email"));
    formData.append("id", sessionStorage.getItem("id"));
    formData.append("rid", id);

    e.preventDefault();
    if (needAnotherIntervention === "Oui") {
      //console.log("Besoin dune autre intervention");
      setCreationDevisModal(true);
    } else {
      // Fermeture d'une demande côté technicien
      try {
        const response = await fetch(`${apiUrl}/temp/tech_close_request`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: formData,
        });

        const data = await response.json();
        navigate(`/user-history`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const techCloseRequestModalN2 = async (e) => {
    e.preventDefault();
    if (needAnotherIntervention === "Oui") {
      //console.log("Besoin dune autre intervention");
      setCreationDevisModal(true);
    } else {
      // Check for missing fields
      /*const requiredFields = [
        "typeDeProbleme",
      ];
    
      const missingFields = requiredFields.filter((field) => {
        return !Object.prototype.hasOwnProperty.call(formData, field) || !formData[field];
      });
    
      if (missingFields.length > 0) {
        // Display the error message for missing fields in French
        const missingFieldNames = missingFields.map((field) => {
          // Replace the English field names with their French equivalents
          switch (field) {
            case "typeDeProbleme":
              return "Type de problème";
            default:
              return field;
          }
        });
    
        alert(`Veuillez remplir les champs suivants : ${missingFieldNames.join(", ")}`);
        setMessageError(`Veuillez remplir les champs suivants : ${missingFieldNames.join(", ")}`)
        return; // Stop the function execution
      }*/

      // If all required fields are filled, proceed with the request
      try {
        const formData = new FormData();
        formData.append("email", sessionStorage.getItem("email"));
        formData.append("id", sessionStorage.getItem("id"));
        formData.append("rid", id);
        formData.append("problemType", problemType);
        formData.append("detailedDescription", detailedDescription);
        formData.append("needAnotherIntervention", needAnotherIntervention);
        formData.append(
          "diametreLiaisonsFrigorifiques",
          diametreLiaisonsFrigorifiques
        );
        formData.append("liaisonsFrigorifiques", liaisonsFrigorifiques);
        formData.append("fluideFrigorigene", fluideFrigorigene);
        formData.append("alimentationElectrique", alimentationElectrique);
        formData.append("CERFA", CERFA);
        formData.append("hydrauliques", hydrauliques);
        formData.append("hydrauliquesModule", hydrauliquesModule);
        formData.append("raccordementsElectriques", raccordementsElectriques);
        formData.append("vannes", vannes);
        formData.append("reglageReglage", reglageReglage);
        formData.append("climatisation", climatisation);
        formData.append("appoint", appoint);
        formData.append("PacClimatisation", PacClimatisation);
        formData.append("prescriptionsConstructeur", prescriptionsConstructeur);
        formData.append("arret", arret);
        formData.append("observations", observations);
        // Append each picture to the formData
        pictures.forEach((picture, index) => {
          formData.append(`reportPictures[${index}]`, picture);
        });

        if (pictures && pictures.length) {
          formData.append("pictures", pictures.name);
        }
        if (pictures && pictures.length) {
          formData.append("pictures", pictures);
          for (let i = 0; i < pictures.length; i++) {
            formData.append("pictures", pictures[i]);
          }
        }

        const response = await fetch(`${apiUrl}/temp/tech_close_request`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: formData,
        });

        const data = await response.json();
        // navigate(`/user-history`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Add event listener to the beforeunload event
  window.addEventListener("beforeunload", function () {
    // Remove the session storage item
    sessionStorage.removeItem("notification");
  });

  const getLocationAsync = async () => {
    try {
      const { coords } = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=AIzaSyDK4L8SN7bnmjDstRZxqkqitqyQufbopLs`
      );
      const data = await response.json();
      const placeId = data.results[0].place_id;
      setLocation({ ...coords, placeId });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLocationAsync();
  }, []);

  const handleRatingModal = () => {
    setRatingProblem(!RatingModal);
  };

  const handleClaime = (event) => {
    setClaime(event.target.value);
  };

  const handletechCloseModal = () => {
    setTechCloseModal(!techCloseModal);
  };

  const handletechCloseModalPac = () => {
    setTechCloseModalPac(!techCloseModalPac);
  };

  const handletechCClaimeModal = () => {
    setTechClaimeModal(!techClaimeModal);
  };

  const handleUserNotCloseModal = () => {
    setUSerNotCloseModal(!UserNotCloseModal);
  };

  const handleFinishedClick = () => {
    setFinished(true);
    setStep(1);
  };

  const handlePrécédentClick = (step) => {
    setFinished(true);
    setStep(step);
  };

  const handleUnfinishedClick = () => {
    setStep(2);
  };

  const handleDevisClick = () => {
    setIntervention("Devis");
    setStep(3);
  };

  const handleIHaveIdeaClick = () => {
    setIdea("I Have an Idea");
    setStep(4);
  };

  const handleCosts = (event) => {
    setPay(event.target.value);
  };

  const handleChange = (event) => {
    setSelectedFile(event.target.files);
  };

  const handleTechnicianID = (id, technician) => {
    console.log("TechnicianPicked_id", technician);
    sessionStorage.setItem("technicienID", technician);
    setTechnicianPicked_id(technician);
    console.log("Intervention_id", id);
    setIntervention_id(id);
  };

  const handleDataChange = (newData) => {
    setData(newData);
  };

  // Fetches the needed Data
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setHistory(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setHistoryTech(
          userType === "Tech"
            ? data.filter(
                (item) => item.client_accept === sessionStorage.getItem("id")
              )
            : data.filter(
                (item) =>
                  item.created_by === sessionStorage.getItem("id") &&
                  item.status === "Pending" &&
                  item.type === "Demande d'intervention" &&
                  item._id === id
              )
        );
      })
      .catch((error) => console.error(error));
    console.log("testingTechShowingComplete");
  }, []);

  /*Closes a request on Tech Side
  async function techCloseRequest() {
    try {
      const response = await fetch(
        "${apiUrl}/temp/tech_close_request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            email: sessionStorage.getItem("email"),
            id: sessionStorage.getItem("id"),
            rid: id,
          }),
        }
      );

      const data = await response.json();
      navigate(`/user-history`);
    } catch (error) {
      console.error(error);
    }
  }*/

  //Closes a request on User side
  async function UserCloseRequest() {
    try {
      const response = await fetch(`${apiUrl}/temp/user_close_request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("email"),
          id: sessionStorage.getItem("id"),
          rid: id,
        }),
      });

      const data = await response.json();
      navigate(`/user-history`);
    } catch (error) {
      console.error(error);
    }
  }

  //Claim a request on User side
  async function UserClaimRequest() {
    const requestData = {
      email: sessionStorage.getItem("email"),
      id: sessionStorage.getItem("id"),
      rid: id,
    };
    try {
      const response = await fetch(`${apiUrl}/temp/user_claim_request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("email"),
          id: sessionStorage.getItem("id"),
          rid: id,
          msg_claime: claime,
          nom_client: sessionStorage.getItem("nom"),
          prenom_client: sessionStorage.getItem("prenom"),
          adresse_client: sessionStorage.getItem("adresse"),
          phone_client: sessionStorage.getItem("phone"),
        }),
      });
      const data = await response.json();
      console.log("data", data);
      navigate(`/user-history`);
    } catch (error) {
      console.error(error);
    }
  }

  //Closes a request and litige status
  async function LitigeRequest() {
    try {
      const response = await fetch(`${apiUrl}/temp/litige`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("email"),
          id: sessionStorage.getItem("id"),
          rid: id,
          tech_name: sessionStorage.getItem("nom"),
          tech_lastname: sessionStorage.getItem("prenom"),
          tech_phone: sessionStorage.getItem("phone"),
          tech_addresse: sessionStorage.getItem("adresse"),
        }),
      });

      const data = await response.json();
      window.location.replace("/user-history");
    } catch (error) {
      console.error(error);
    }
  }

  //Closes a request and Tech Accept
  async function TechAccept() {
    try {
      const response = await fetch(`${apiUrl}/request/tech_accept`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("email"),
          uid: sessionStorage.getItem("id"),
          rid: id,
        }),
      });

      const data = await response.json();
      window.location.replace("/user-history");
    } catch (error) {
      console.error(error);
    }
  }

  //Closes a request and after claime status
  async function AcceptClaimeRequest() {
    try {
      const response = await fetch(`${apiUrl}/temp/pending`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("email"),
          id: sessionStorage.getItem("id"),
          rid: id,
        }),
      });

      const data = await response.json();
      navigate(`/user-history`);
    } catch (error) {
      console.error(error);
    }
  }

  //Closes a request and Fix Ltige
  async function ResoudreLitige() {
    try {
      const response = await fetch(`${apiUrl}/temp/fix_litige`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("email"),
          id: sessionStorage.getItem("id"),
          rid: id,
        }),
      });

      const data = await response.json();
      window.location.replace("/user-history");
    } catch (error) {
      console.error(error);
    }
  }

  //Closes a request and Fix Ltige
  async function ResoudreReclamation() {
    try {
      const response = await fetch(`${apiUrl}/temp/fix_claim`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("email"),
          id: sessionStorage.getItem("id"),
          technician_nom: sessionStorage.getItem("nom"),
          technician_prenom: sessionStorage.getItem("prenom"),
          technician_phone: sessionStorage.getItem("phone"),
          technician_raisonsocial: sessionStorage.getItem("raisonsocial"),
          technician_raison: sessionStorage.getItem("raison"),
          technician_domaine: sessionStorage.getItem("domaine"),
          technician_siret: sessionStorage.getItem("siret"),
          technician_adresse: sessionStorage.getItem("adresse"),
          rid: id,
        }),
      });

      const data = await response.json();
      window.location.replace("/user-history");
    } catch (error) {
      console.error(error);
    }
  }

  // Annulation
  async function HandleAnnulation() {
    try {
      const requestData = JSON.stringify({
        email: sessionStorage.getItem("email"),
        id: sessionStorage.getItem("id"),
        rid: id,
      });

      fetch(`${apiUrl}/request/annul_request`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: requestData,
      })
        .then((res) => res.text()) // Retrieve response as text
        .then((data) => {
          if (data === "request annulled") {
            console.log("success");
            window.location.replace("/user-history");
          } else {
            setError(data);
          }
        })
        .catch((error) => {
          setError("An error occurred while registering");
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  const handleButtonClick = () => {
    AcceptClaimeRequest();
    handleUnfinishedClick();
  };

  //Sends a Quote to the user
  async function sendQuoteRequest() {
    const elementsJSON = JSON.stringify(elements);
    let parsedElements = [];
    try {
      parsedElements = JSON.parse(elementsJSON);
    } catch (error) {
      console.error("Error parsing elements JSON:", error);
      return;
    }

    console.log("parsedElements", parsedElements);

    const totalPrice = elements.reduce((total, element) => {
      const price = parseFloat(element.price);
      const quantity = parseFloat(element.quantity);

      if (!isNaN(price) && !isNaN(quantity)) {
        return total + price * quantity;
      } else {
        return total;
      }
    }, 0);
    setTotalPrice(totalPrice);

    const formData = new FormData();
    formData.append("rid", id);
    formData.append("temail", sessionStorage.getItem("email"));
    formData.append("email", sessionStorage.getItem("email"));
    formData.append("tid", sessionStorage.getItem("id"));
    formData.append("pay", totalPrice);
    formData.append("parsedElementsLists", elementsJSON);
    /*parsedElements.forEach((element, index) => {
      formData.append(`element[${index}]`, JSON.stringify(element));
    });*/
    /*elements.forEach((element, index) => {
      formData.append(`elements[${index}][title]`, element.title);
      formData.append(`elements[${index}][description]`, element.description);
      formData.append(`elements[${index}][price]`, element.price);
      formData.append(`elements[${index}][quantity]`, element.quantity);
    });*/
    if (selectedFile && selectedFile.length) {
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("uploadedfile", selectedFile[i]);
      }
    }
    if (selectedFile && selectedFile.length) {
      formData.append("payslipfileName", selectedFile.name);
    }
    // Append each picture to the formData
    pictures.forEach((picture, index) => {
      formData.append(`reportPictures[${index}]`, picture);
    });

    if (pictures && pictures.length) {
      formData.append("pictures", pictures.name);
    }
    if (pictures && pictures.length) {
      formData.append("pictures", pictures);
      for (let i = 0; i < pictures.length; i++) {
        formData.append("pictures", pictures[i]);
      }
    }

    const url = `${apiUrl}/request/pay_slip`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    });

    const result = await response.json();
    console.log(result);
    window.location.replace("/user-history");
  }

  const MobileApplication = () => {
    console.log("MobileApplicationClicked");

    sendQuoteRequest(); // This will be called immediately

    setTimeout(() => {
      sendQuoteRequest(); // This will be called 3 seconds later
    }, 1000); // 3000 milliseconds = 3 seconds
  };

  //Accepts the Quote
  const handleAccept = async () => {
    const requestData = {
      email: sessionStorage.getItem("email"),
      rid: id,
    };

    try {
      const res = await fetch(`${apiUrl}/request/pay_accept`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(requestData),
      });
      const data = await res.json();
      setResponse(data);
      navigate(`/user-history`);
    } catch (error) {
      console.error(error);
    }
  };

  //Denies the quote
  const handleDeny = async () => {
    const requestData = {
      temail: sessionStorage.getItem("email"),
      rid: id,
    };

    try {
      const res = await fetch(`${apiUrl}/request/pay_denie`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(requestData),
      });
      const data = await res.json();
      setDenyResponse(data);
      navigate(`/user-history`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAcceptTable = async (infoId, techid) => {
    const selectedRequest = info.filter(
      (i) => i.id === infoId && i.Technician_id === techid
    )[0];

    const email = sessionStorage.getItem("email");
    const response = await fetch(`${apiUrl}/request/user_accept`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        email,
        rid: infoId,
        tid: techid,
      }),
    });
    const data = await response.json();
    setTechAcceptResponse(data);
    window.location.reload();
  };

  const executeSequentiallyTable = async (infoId, techid) => {
    await handleAcceptTable(infoId, techid); // Call the first function
    await handleClickUpdateStatusDate();
    window.location.reload(); // Call the second function
  };

  const handleDenyTable = async (infoId, techid) => {
    const selectedRequest = info.filter(
      (i) => i.id === infoId && i.Technician_id === techid
    )[0];
    const email = sessionStorage.getItem("email");
    const response = await fetch(`${apiUrl}/request/user_denie`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        email,
        rid: infoId,
        tid: techid,
      }),
    });
    const data = await response.json();
    setTechAcceptResponse(data);
    window.location.reload();
  };

  //Missing Client
  const sendLocationToBackend = async () => {
    console.log("testing1");
    try {
      const requestBody = {
        latitude: location.latitude,
        longitude: location.longitude,
        placeId: location.placeId,
        rid: id,
      };
      console.log("testing2");
      const response = await fetch(`${apiUrl}/temp/absent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.text();
      console.log(data);
      window.location.replace("/user-history");
    } catch (error) {
      console.error(error);
    }
  };

  const openModalImages = (index) => {
    setSelectedImageIndex(index);
    console.log("selectedImageIndex: " + selectedImageIndex);
    setModalOpen(true);
  };

  const closeModalImages = () => {
    setModalOpen(false);
  };

  function translateMonth(month) {
    const translations = {
      Jan: "Janv",
      Feb: "Fev",
      Mar: "Mar",
      Apr: "Avr",
      May: "Mai",
      Jun: "Juin",
      Jul: "Juil",
      Aug: "Août",
      Sep: "Sept",
      Oct: "Oct",
      Nov: "Nov",
      Dec: "Dec",
    };
    const uppercaseMonth = month.toUpperCase();
    return translations[uppercaseMonth];
  }

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            id: `${sessionStorage.getItem("technicienID")}`,
          }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        setNomTec(data.nom);
        setPrenomTec(data.prenom);
        setReviewTec(data.Review);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) {
      return 0;
    }
    const sum = reviews.reduce((acc, curr) => acc + parseInt(curr.rating), 0);
    return Math.round(sum / reviews.length);
  };

  //Accepts Technicien
  const handleAcceptUser = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }

    const email = sessionStorage.getItem("email");
    const response = await fetch(`${apiUrl}/request/user_accept`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        email,
        rid: id,
        tid: Technicien[0],
      }),
    });
    const data = await response.json();
    sessionStorage.removeItem("notification");
    setTechAcceptResponse(data);
    //window.location.reload();
  };

  const handleClickUpdateStatusDate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("status", "En attente de date d'intervention");

      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();
      // Handle the response data here
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  const executeSequentially = async () => {
    try {
      await handleAcceptUser();
      await handleClickUpdateStatusDate();
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClickUpdateDateTech = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("PickedDate", selectedDate);
      formData.append("status", "Accepted");

      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();
      window.location.reload();
      // Handle the response data here
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  //Denies Technicien
  const handleDenyUser = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }
    const email = sessionStorage.getItem("email");
    const response = await fetch(`${apiUrl}/request/user_denie`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        email,
        rid: id,
        tid: Technicien[0],
      }),
    });
    const data = await response.json();
    sessionStorage.removeItem("notification");
    setTechAcceptResponse(data);
    window.location.reload();
  };

  const averageRating = reviewTec ? calculateAverageRating(reviewTec) : 0;
  const selectedHistory = history.find((h) => h._id === id);

  // Assuming the `selectedHistory.created_at` is a string representing a date
  const date = new Date(selectedHistory?.created_at);
  const Product = selectedHistory?.created_for;
  const User = selectedHistory?.client_accept;
  const ClaimUser = selectedHistory?.clientAccept;
  const CreatedUser = selectedHistory?.created_by;

  const photoKeys = selectedHistory
    ? Object.keys(selectedHistory).filter((key) =>
        key.startsWith("photoDuProduit")
      )
    : [];

  console.log("photoKeys", photoKeys);

  const Technicien = selectedHistory?.accepted_by;
  const email = selectedHistory?.clientemail;

  const TechnicianName = selectedHistory?.Technicien;
  const TechnicienLastName = selectedHistory?.Technicienprenom;

  const techStart = new Date(selectedHistory?.techStartDate);
  const techEnd = new Date(selectedHistory?.techEndDate);

  /*const technicianMsg = selectedHistory.tech_info.filter(info => info.id === sessionStorage.getItem("technicienID"))[0].tech_msg;
  console.log('technicianMsg', technicianMsg)*/

  const handleAdminDescriptionChange = (event) => {
    setAdminDescriptionMsg(event.target.value);
  };

  const handleAdminActionMsg = (event) => {
    setAdminActionMsg(event.target.value);
  };

  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const monthName = monthNames[monthIndex];
    return `${day} ${monthName} ${year}`;
  };

  const info = Object.values(historyTech)
    .filter((request) => request.accepted_by.length > 0)
    .flatMap((request) => {
      return request.accepted_by.map((techId) => {
        let techInfo = request?.tech_info?.find((info) => info.id === techId);
        const technicianId = techInfo ? techInfo.id : "";
        return {
          id: request._id,
          Date: formatDate(request.created_at.split(" ").slice(0, 4).join(" ")),
          Type_demande: request.type,
          Technician_id: techId,
          Entreprise: techInfo ? techInfo.tech_raison : "",
          Msg: techInfo ? techInfo.tech_msg : "",
          "Dates de disponibilités":
            " entre " +
            new Date(request.techStartDate).toLocaleDateString("fr-FR") +
            " et " +
            new Date(request.techEndDate).toLocaleDateString("fr-FR"),
          checkIconAccept: request._id,
        };
      });
    })
    .sort((a, b) => new Date(b.Date) - new Date(a.Date));

  const formData = {
    email,
  };

  const handleGetProduct = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }

    const response = await fetch(`${apiUrl}/prods/get_product`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id: Product }),
    });
    const data = await response.json();
    setProduct(data);
  };

  useEffect(() => {
    handleGetProduct();
  }, [history, Product]);

  const handleGetUser = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }

    const response = await fetch(`${apiUrl}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id: User }),
    });
    const data = await response.json();
    setUser(data);
  };

  useEffect(() => {
    handleGetUser();
  }, [history, User]);

  const handleGetUserClaim = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }

    const response = await fetch(`${apiUrl}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id: ClaimUser }),
    });
    const data = await response.json();
    setClaimUser(data);
  };

  useEffect(() => {
    handleGetUserClaim();
  }, [history, ClaimUser]);

  const handleGetUserCreated = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }

    const response = await fetch(`${apiUrl}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id: CreatedUser }),
    });
    const data = await response.json();
    setCreatedUser(data);
  };

  useEffect(() => {
    handleGetUserCreated();
  }, [history, CreatedUser]);

  const handleClickRésoudreRéclamation = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("adminDescriptionMsg", adminDescriptionMsg);
      formData.append("adminActionMsg", adminActionMsg);

      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();
      // Handle the response data here
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  const handleSolveClaim = () => {
    ResoudreReclamation();
    handleClickRésoudreRéclamation();
    handleModalToggle();
    window.location.reload();
  };

  const handleClickRésoudreLitige = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("adminDescriptionMsg", adminDescriptionMsg);
      formData.append("adminActionMsg", adminActionMsg);

      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();
      // Handle the response data here
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  const handleSolveLitige = () => {
    ResoudreLitige();
    handleClickRésoudreLitige();
    handleModalToggleLitige();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  // Get the day, month, and year from the date object
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  // Translate the month name
  const translatedMonth = translateMonth(month);

  // Format the date as desired (e.g. "16 Fev 2023")
  const formattedDate = `${day} ${month} ${year}`;

  return (
    <>
      <ReturnButton />
      {/* Normal User */}
      {entreprise === "non" && (
        <>
          {selectedHistory && (
            <div className="overflow-hidden bg-transparent shadow sm:rounded-lg max-w-screen-xl  ml-auto mr-auto mt-10 mb-10">
              {product && (
                <>
                  <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto"></div>
                  <div className="flex justify-around mt-6">
                    {product.photoDuProduit &&
                      product.photoDuProduit !== "null" && (
                        <img
                          src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                          alt={product._id}
                          className="h-[30%] w-[30%] object-cover object-center mt-0 mb-0 ml-auto mr-auto"
                        />
                      )}
                      <img
                        src={`https://lebontechnicien.net/${product.QR}`}
                        alt={product._id}
                        className="h-[30%] w-[30%] object-cover object-center mt-0 mb-0 ml-auto mr-auto"
                      />
                  </div>
                  <div className="flex justify-center mb-14">
                    <button
                      className="mt-4 mr-4 px-4 py-2 bg-green-500 text-white rounded"
                      onClick={handlePrintQR}
                    >
                      Imprimer le code QR
                    </button>
                  </div>

                  {selectedHistory.status === "annulled" && (
                    <h3 className="text-[22px] leading-6 text-[#ff0000] ml-20 mb-5 font-bold text-center">
                      Intervention Annuleé
                    </h3>
                  )}
                  <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                    Détails de l'appareil:
                  </h3>
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <dl>
                      {product.typeDuBien && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Type d'appareil
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.typeDuBien}
                          </dd>
                        </div>
                      )}
                      {product.moisInstallation && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Mois et année d'installation:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.moisInstallation} /{" "}
                            {product.annéeInstallation
                              ? product.annéeInstallation
                              : product.anneeInstallation}
                          </dd>
                        </div>
                      )}
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Marque:
                        </dt>
                        {product.marque === "autre" ||
                        selectedHistory.marque === "autre" ? (
                          <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.autreMarque
                              ? product.autreMarque
                              : selectedHistory.autreMarque}
                          </dd>
                        ) : (
                          <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.marque
                              ? product.marque
                              : selectedHistory.marque}
                          </dd>
                        )}
                      </div>
                      {(product.reference || product.référence) && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Référence
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.reference
                              ? product.reference
                              : product.référence}
                          </dd>
                        </div>
                      )}
                      {product.puissance && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Puissance
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.puissance}
                          </dd>
                        </div>
                      )}
                      {product.entretenu && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Maintenu
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.entretenu}
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>
                </>
              )}
              {selectedHistory.type === "Demande d'intervention" && (
                <>
                  <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                    Détails de l'intervention:
                  </h3>
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <dl>
                      {selectedHistory.status && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Statut:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {(() => {
                              switch (selectedHistory.status) {
                                case "Pending":
                                  return "En cours";
                                case "closed":
                                  return "Cloturé";
                                case "closed_tech":
                                  return "Cloturé par technicien";
                                case "claim":
                                  return "Réclamation";
                                case "Denied_payment":
                                  return "Devis refusé";
                                case "Accepted_payment":
                                  return "Devis accepté";
                                case "Paymen_User":
                                  return "Devis";
                                case "Accepted":
                                  return "Accepté";
                                case "claim_fix":
                                  return "Résolu";
                                case "Email Pending":
                                  return "Email non validé";
                                case "Confirmed":
                                  return "validé";
                                case "In progress":
                                  return "En cours de vérification";
                                case "Denied":
                                  return "Réfusé";
                                case "solved":
                                  return "Résolu";
                                case "unsolved":
                                  return "Non résolu";
                                case "Full":
                                  return "Complète";
                                case "litige_fixed":
                                  return "Litige résolu";
                                case "claime_fixed":
                                  return "Réclamation résolu";
                                case "annulled":
                                  return "Annulée";
                                case "admin_accept":
                                  return "Contrat en attente de signature";
                                case "Tech_onhold":
                                  return "En attente";
                                default:
                                  return selectedHistory.status;
                              }
                            })()}
                          </dd>
                        </div>
                      )}
                      {formattedDate && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de la demande:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {formattedDate}
                            </dd>
                          </dd>
                        </div>
                      )}
                      {selectedHistory.PickedDate && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de l'intervention:
                          </dt>

                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                selectedHistory.PickedDate
                              )
                                ? selectedHistory.PickedDate
                                : new Date(
                                    selectedHistory.PickedDate
                                  ).toLocaleDateString("fr-FR")}
                            </dd>
                          </dd>
                        </div>
                      )}
                      {selectedHistory.typeDePrestation && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Type de préstation
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.typeDePrestation}
                          </dd>
                        </div>
                      )}
                      {selectedHistory.information && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Description de la panne
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.information}
                          </dd>
                        </div>
                      )}

                      {photoKeys &&
                        photoKeys !== null &&
                        photoKeys !== "" &&
                        photoKeys.length !== 0 &&
                        photoKeys[0] !== "photoDuProduit" && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Photo du problème
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <div className="flex gap-10">
                                <div
                                  style={{
                                    overflowX: "auto",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {photoKeys.map((photoKey, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "inline-block",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <img
                                        className="w-[80px] h-[80px] cursor-pointer"
                                        src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                        alt={`Photoduprobleme ${index + 1}`}
                                        onClick={() =>
                                          openModal(
                                            `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                          )
                                        }
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </dd>
                          </div>
                        )}

                      {user.raisonSocial && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Intervenant:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {user.raisonSocial}
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>

                  {/* Détails de Technicien client pro*/}
                  <>
                    {selectedHistory.status !== "Pending" &&
                      selectedHistory.status !== "annulled" && (
                        <>
                          <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                            Détail du Technicien:
                          </h3>
                          <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                            <dl>
                              {selectedHistory.raisonSociale && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Raison sociale
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.raisonSociale}
                                  </dd>
                                </div>
                              )}
                              {selectedHistory.Technicien && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Nom
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.Technicien}
                                  </dd>
                                </div>
                              )}
                              {selectedHistory.Technicienprenom && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Prénom
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.Technicienprenom}
                                  </dd>
                                </div>
                              )}
                            </dl>
                          </div>
                        </>
                      )}
                    {/* Rapport d'intervention: */}
                    {selectedHistory.status !== "Pending" &&
                      selectedHistory.status !== "Accepted" &&
                      selectedHistory.status !==
                        "En attente de date d'intervention" &&
                      selectedHistory.status !== "annulled" && (
                        <>
                          <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                            Rapport d'intervention:
                          </h3>
                          <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                            <dl>
                              {selectedHistory.typeDeProjet &&
                                !selectedHistory.typeDeProjet.includes(
                                  "PAC"
                                ) && (
                                  <>
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Type de problème:
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.problemType}
                                        </dd>
                                      </dd>
                                    </div>
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Description de l'intervention et du
                                        problème :
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.detailedDescription}
                                      </dd>
                                    </div>
                                  </>
                                )}

                              {selectedHistory.typeDeProjet.includes("PAC") && (
                                <>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Diametre Liaisons Frigorifiques
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {
                                        selectedHistory.diametreLiaisonsFrigorifiques
                                      }
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      liaisons Frigorifiques :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.liaisonsFrigorifiques}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      flui de Frigorigene :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.fluideFrigorigene}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      alimentation Electrique :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.alimentationElectrique}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      CERFA :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.CERFA}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      hydrauliques :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.hydrauliques}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      hydrauliques Module :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.hydrauliquesModule}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      raccordements Electriques :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.raccordementsElectriques}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      vannes :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.vannes}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Reglage :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.reglageReglage}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      climatisation :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.climatisation}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      appoint :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.appoint}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Pac Climatisation :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.PacClimatisation}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      prescriptions Constructeur :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {
                                        selectedHistory.prescriptionsConstructeur
                                      }
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      arret :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.arret}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      observations :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.observations}
                                    </dd>
                                  </div>
                                </>
                              )}
                              {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Entreprise qui a intervenu :
                              </dt>
                              <dd
                                className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900"
                                onClick={() => {
                                  window.location.href = `/product/${selectedHistory.created_for}`;
                                }}
                              >
                                {selectedHistory.typeDeProjet}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Devis :
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.zone}
                              </dd>
                            </div>*/}
                            </dl>
                          </div>
                        </>
                      )}
                  </>

                  {userType === "User" && (
                    <>
                      {nomtec && prenomtec && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Nom & Prenom Tech
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {nomtec} {prenomtec}
                          </dd>
                        </div>
                      )}
                      {reviewTec && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Avis Clients
                          </dt>
                        </div>
                      )}
                      {userType === "User" && reviewTec?.length > 0 && (
                        <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {reviewTec.map((review, index) => (
                            <div
                              key={index}
                              className="w-full mb-4 bg-white p-4 rounded-md"
                            >
                              <div className="flex gap-2 mb-2">
                                <p>
                                  {review.userName} {review.userLastName}
                                </p>
                                <Rating rating={review.rating} />
                              </div>
                              <p>{review.comment}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                  {pendingList[0]?.produits &&
                    pendingList[0]?._id === selectedHistory.created_for && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Votre Appareil
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {pendingList[0]?.produits}
                        </dd>
                      </div>
                    )}
                  {userType === "User" &&
                  selectedHistory.status === "Pending" &&
                  selectedHistory.accepted_by.length === 0 &&
                  selectedHistory.denied_by.length === 0 ? (
                    <>
                      <div className="flex justify-end  gap-4 p-8">
                        <button
                          onClick={HandleAnnulation}
                          //onClick={handletechCloseModal}
                          className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                        >
                          Annuler l’intervention
                        </button>
                      </div>
                    </>
                  ) : null}
                  {userType === "User" &&
                  selectedHistory.status === "Pending" &&
                  showButtons === "oui" &&
                  (selectedHistory.accepted_by.length > 0 ||
                    selectedHistory.denied_by.length > 0) ? (
                    <>
                      <div className="flex gap-4 justify-end p-10">
                        <button
                          onClick={executeSequentially}
                          //onClick={handletechCloseModal}
                          className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-green-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-green-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                        >
                          Accpeter
                        </button>
                        <button
                          onClick={handleDenyUser}
                          //onClick={handletechCloseModal}
                          className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-red-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-red-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                        >
                          Refuser
                        </button>
                      </div>
                    </>
                  ) : null}
                  {userType === "Tech" && selectedHistory.status === "claim" ? (
                    <div className="flex justify-end  gap-4 p-8">
                      <button
                        //onClick={techCloseRequest}
                        onClick={handletechCClaimeModal}
                        className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                      >
                        répondre à la réclamation du client
                      </button>
                    </div>
                  ) : null}
                  {userType === "User" &&
                  (selectedHistory.status === "closed" ||
                    selectedHistory.status === "Full") ? (
                    <>
                      <RatingSystem
                        userName={nom}
                        userLastName={prenom}
                        TechnicienID={selectedHistory.client_accept}
                        techName={TechnicianName}
                        techLastName={TechnicienLastName}
                      />
                    </>
                  ) : null}
                  {selectedHistory.status === "Paymen_User" ? (
                    <PdfViewer url={`${apiUrl}${selectedHistory.payslip}`} />
                  ) : null}
                  {userType === "Tech" ? (
                    <>
                      <>
                        {selectedHistory.status === "Accepted" ||
                        selectedHistory.status === "claim" ? (
                          <>
                            <div className="flex justify-end  gap-4 p-8">
                              <button
                                //onClick={techCloseRequest}
                                onClick={handletechCloseModal}
                                className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Clôturer l'intervention
                              </button>
                              {userType === "Tech" &&
                                selectedHistory.status === "Accepted" && (
                                  <button
                                    onClick={sendLocationToBackend}
                                    className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                  >
                                    Client manquant
                                  </button>
                                )}
                            </div>
                          </>
                        ) : null}
                      </>
                      <>
                        {selectedHistory.status === "Accepted_payment" ? (
                          <>
                            <div className="flex justify-end  gap-4 p-8">
                              <button
                                onClick={techCloseRequest}
                                //onClick={handletechCloseModal}
                                className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                              >
                                Clôturer l'intervention
                              </button>
                            </div>
                          </>
                        ) : null}
                      </>
                    </>
                  ) : (
                    <>
                      {selectedHistory.status === "closed_tech" ? (
                        <div className="flex gap-4 justify-end p-10">
                          <button
                            onClick={UserCloseRequest}
                            className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                          >
                            Confirmer la fin de l'intervention
                          </button>
                          <button
                            onClick={handleUserNotCloseModal}
                            className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                          >
                            Intervention toujours ouverte
                          </button>
                        </div>
                      ) : selectedHistory.status === "Paymen_User" ? (
                        <div className="flex gap-4 justify-end p-10">
                          <button
                            onClick={handleAccept}
                            className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                          >
                            Accepter le devis
                          </button>
                          <button
                            onClick={handleDeny}
                            className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                          >
                            Refuser le devis
                          </button>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}
              {selectedHistory.type === "Demande de Réclamation" && (
                <>
                  <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                    Détails de Réclamation:
                  </h3>
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <dl>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Date de la demande:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {formattedDate}
                          </dd>
                        </dd>
                      </div>
                      {selectedHistory.PickedDate && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de l'intervention:
                          </dt>

                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                selectedHistory.PickedDate
                              )
                                ? selectedHistory.PickedDate
                                : new Date(
                                    selectedHistory.PickedDate
                                  ).toLocaleDateString("fr-FR")}
                            </dd>
                          </dd>
                        </div>
                      )}
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Message
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.claime}
                        </dd>
                      </div>
                      {photoKeys && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Photo du problème
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div className="flex gap-10">
                              <div
                                style={{
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {photoKeys.map((photoKey, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <img
                                      className="w-[80px] h-[80px] cursor-pointer"
                                      src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                      alt={`Photoduprobleme ${index + 1}`}
                                      onClick={() =>
                                        openModal(
                                          `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </dd>
                        </div>
                      )}
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Statut:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {(() => {
                            switch (selectedHistory.status) {
                              case "Pending":
                                return "En cours";
                              case "closed":
                                return "Cloturé";
                              case "closed_tech":
                                return "Cloturé par technicien";
                              case "claim":
                                return "Réclamation";
                              case "Denied_payment":
                                return "Devis refusé";
                              case "Accepted_payment":
                                return "Devis accepté";
                              case "Paymen_User":
                                return "Devis";
                              case "Accepted":
                                return "Accepté";
                              case "claim_fix":
                                return "Résolu";
                              case "Email Pending":
                                return "Email non validé";
                              case "Confirmed":
                                return "validé";
                              case "In progress":
                                return "En cours de vérification";
                              case "Denied":
                                return "Réfusé";
                              case "solved":
                                return "Résolu";
                              case "unsolved":
                                return "Non résolu";
                              case "Full":
                                return "Complète";
                              case "litige_fixed":
                                return "Litige résolu";
                              case "claime_fixed":
                                return "Réclamation résolu";
                              case "annulled":
                                return "Annulée";
                              case "admin_accept":
                                return "Contrat en attente de signature";
                              case "Tech_onhold":
                                return "En attente";
                              default:
                                return selectedHistory.status;
                            }
                          })()}
                        </dd>
                      </div>
                      {selectedHistory.information && (
                        <>
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              PLus de Détails
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.information}
                            </dd>
                          </div>

                          {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Message du technicien
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.information}
                      </dd>
                </div>*/}
                        </>
                      )}
                      {userType === "User" && (
                        <>
                          {nomtec && prenomtec && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Nom & Prenom Tech
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {nomtec} {prenomtec}
                              </dd>
                            </div>
                          )}
                          {reviewTec && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Avis Clients
                              </dt>
                            </div>
                          )}
                          {userType === "User" && reviewTec?.length > 0 && (
                            <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {reviewTec.map((review, index) => (
                                <div
                                  key={index}
                                  className="w-full mb-4 bg-white p-4 rounded-md"
                                >
                                  <div className="flex gap-2 mb-2">
                                    <p>
                                      {review.userName} {review.userLastName}
                                    </p>
                                    <Rating rating={review.rating} />
                                  </div>
                                  <p>{review.comment}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}

                      {pendingList[0]?.produits &&
                        pendingList[0]?._id === selectedHistory.created_for && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Votre Appareil
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {pendingList[0]?.produits}
                            </dd>
                          </div>
                        )}
                      {userType === "User" &&
                      selectedHistory.status === "Pending" &&
                      selectedHistory.accepted_by.length === 0 &&
                      selectedHistory.denied_by.length === 0 ? (
                        <>
                          <div className="flex justify-end  gap-4">
                            <button
                              onClick={HandleAnnulation}
                              //onClick={handletechCloseModal}
                              className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                            >
                              Annuler l’intervention
                            </button>
                          </div>
                        </>
                      ) : null}
                      {userType === "User" &&
                      selectedHistory.status === "Pending" &&
                      (selectedHistory.accepted_by.length > 0 ||
                        selectedHistory.denied_by.length > 0) ? (
                        <>
                          <div className="flex gap-4 justify-end p-10">
                            <button
                              onClick={executeSequentially}
                              //onClick={handletechCloseModal}
                              className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-green-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-green-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                            >
                              Accpeter
                            </button>
                            <button
                              onClick={handleDenyUser}
                              //onClick={handletechCloseModal}
                              className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-red-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-red-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                            >
                              Refuser
                            </button>
                          </div>
                        </>
                      ) : null}
                      {userType === "Tech" &&
                      selectedHistory.status === "claim" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={handletechCClaimeModal}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            répondre à la réclamation du client
                          </button>
                        </div>
                      ) : null}
                      {(userType === "Admin" || userType === "Superviseur") &&
                      selectedHistory.status === "litige" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={ResoudreLitige}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            résoudre ce litige
                          </button>
                        </div>
                      ) : null}
                      {(userType === "Admin" || userType === "Superviseur") &&
                      selectedHistory.type === "Demande de Réclamation" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={ResoudreReclamation}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            résoudre cette réclamation
                          </button>
                        </div>
                      ) : null}
                      {userType === "User" &&
                      (selectedHistory.status === "closed" ||
                        selectedHistory.status === "Full") ? (
                        <>
                          <RatingSystem
                            userName={nom}
                            userLastName={prenom}
                            TechnicienID={selectedHistory.client_accept}
                          />
                        </>
                      ) : null}
                      {selectedHistory.status === "Paymen_User" ? (
                        <PdfViewer
                          url={`${apiUrl}${selectedHistory.payslip}`}
                        />
                      ) : null}
                      {userType === "Tech" ? (
                        <>
                          <>
                            {selectedHistory.status === "Accepted" ||
                            selectedHistory.status === "claim" ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    //onClick={techCloseRequest}
                                    onClick={handletechCloseModal}
                                    className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                  >
                                    Clôturer l'intervention
                                  </button>
                                  {userType === "Tech" &&
                                    selectedHistory.status === "Accepted" && (
                                      <button
                                        onClick={sendLocationToBackend}
                                        className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                      >
                                        Client manquant
                                      </button>
                                    )}
                                </div>
                              </>
                            ) : null}
                          </>
                          <>
                            {selectedHistory.status === "Accepted_payment" ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    onClick={techCloseRequest}
                                    //onClick={handletechCloseModal}
                                    className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                  >
                                    Clôturer l'intervention
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </>
                        </>
                      ) : (
                        <>
                          {selectedHistory.status === "closed_tech" ? (
                            <div className="flex gap-4 justify-end p-10">
                              <button
                                onClick={UserCloseRequest}
                                className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                              >
                                Confirmer la fin de l'intervention
                              </button>
                              <button
                                onClick={handleUserNotCloseModal}
                                className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Intervention toujours ouverte
                              </button>
                            </div>
                          ) : selectedHistory.status === "Paymen_User" ? (
                            <div className="flex gap-4 justify-end p-10">
                              <button
                                onClick={handleAccept}
                                className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Accepter le devis
                              </button>
                              <button
                                onClick={handleDeny}
                                className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                              >
                                Refuser le devis
                              </button>
                            </div>
                          ) : null}
                        </>
                      )}
                    </dl>
                  </div>
                </>
              )}
              {selectedHistory.type === "Demande de Maintenance" && (
                <>
                  <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                    Détails de contrat de maintenance:
                  </h3>
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <dl>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Date de la demande:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {formattedDate}
                          </dd>
                        </dd>
                      </div>
                      {selectedHistory.PickedDate && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de l'intervention:
                          </dt>

                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                selectedHistory.PickedDate
                              )
                                ? selectedHistory.PickedDate
                                : new Date(
                                    selectedHistory.PickedDate
                                  ).toLocaleDateString("fr-FR")}
                            </dd>
                          </dd>
                        </div>
                      )}

                      {photoKeys && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Photo du problème
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div className="flex gap-10">
                              <div
                                style={{
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {photoKeys.map((photoKey, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <img
                                      className="w-[80px] h-[80px] cursor-pointer"
                                      src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                      alt={`Photoduprobleme ${index + 1}`}
                                      onClick={() =>
                                        openModal(
                                          `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </dd>
                        </div>
                      )}
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Statut:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {(() => {
                            switch (selectedHistory.status) {
                              case "Pending":
                                return "En cours";
                              case "closed":
                                return "Cloturé";
                              case "closed_tech":
                                return "Cloturé par technicien";
                              case "claim":
                                return "Réclamation";
                              case "Denied_payment":
                                return "Devis refusé";
                              case "Accepted_payment":
                                return "Devis accepté";
                              case "Paymen_User":
                                return "Devis";
                              case "Accepted":
                                return "Accepté";
                              case "claim_fix":
                                return "Résolu";
                              case "Email Pending":
                                return "Email non validé";
                              case "Confirmed":
                                return "validé";
                              case "In progress":
                                return "En cours de vérification";
                              case "Denied":
                                return "Réfusé";
                              case "solved":
                                return "Résolu";
                              case "unsolved":
                                return "Non résolu";
                              case "Full":
                                return "Complète";
                              case "litige_fixed":
                                return "Litige résolu";
                              case "claime_fixed":
                                return "Réclamation résolu";
                              case "annulled":
                                return "Annulée";
                              case "admin_accept":
                                return "Contrat en attente de signature";
                              case "Tech_onhold":
                                return "En attente";
                              default:
                                return selectedHistory.status;
                            }
                          })()}
                        </dd>
                      </div>
                      {selectedHistory.information && (
                        <>
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              PLus de Détails
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.information}
                            </dd>
                          </div>

                          {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Message du technicien
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.information}
                      </dd>
                </div>*/}
                        </>
                      )}
                      {userType === "User" && (
                        <>
                          {nomtec && prenomtec && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Nom & Prenom Tech
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {nomtec} {prenomtec}
                              </dd>
                            </div>
                          )}
                          {reviewTec && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Avis Clients
                              </dt>
                            </div>
                          )}
                          {userType === "User" && reviewTec?.length > 0 && (
                            <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {reviewTec.map((review, index) => (
                                <div
                                  key={index}
                                  className="w-full mb-4 bg-white p-4 rounded-md"
                                >
                                  <div className="flex gap-2 mb-2">
                                    <p>
                                      {review.userName} {review.userLastName}
                                    </p>
                                    <Rating rating={review.rating} />
                                  </div>
                                  <p>{review.comment}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}

                      {pendingList[0]?.produits &&
                        pendingList[0]?._id === selectedHistory.created_for && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Votre Appareil
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {pendingList[0]?.produits}
                            </dd>
                          </div>
                        )}
                      {userType === "User" &&
                      selectedHistory.status === "Pending" &&
                      selectedHistory.accepted_by.length === 0 &&
                      selectedHistory.denied_by.length === 0 ? (
                        <>
                          <div className="flex justify-end  gap-4">
                            <button
                              onClick={HandleAnnulation}
                              //onClick={handletechCloseModal}
                              className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                            >
                              Annuler l’intervention
                            </button>
                          </div>
                        </>
                      ) : null}
                      {userType === "User" &&
                      selectedHistory.status === "Pending" &&
                      (selectedHistory.accepted_by.length > 0 ||
                        selectedHistory.denied_by.length > 0) ? (
                        <>
                          <div className="flex gap-4 justify-end p-10">
                            <button
                              onClick={executeSequentially}
                              //onClick={handletechCloseModal}
                              className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-green-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-green-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                            >
                              Accpeter
                            </button>
                            <button
                              onClick={handleDenyUser}
                              //onClick={handletechCloseModal}
                              className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-red-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-red-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                            >
                              Refuser
                            </button>
                          </div>
                        </>
                      ) : null}
                      {userType === "Tech" &&
                      selectedHistory.status === "claim" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={handletechCClaimeModal}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            répondre à la réclamation du client
                          </button>
                        </div>
                      ) : null}
                      {(userType === "Admin" || userType === "Superviseur") &&
                      selectedHistory.status === "litige" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={ResoudreLitige}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            résoudre ce litige
                          </button>
                        </div>
                      ) : null}
                      {(userType === "Admin" || userType === "Superviseur") &&
                      selectedHistory.type === "Demande de Réclamation" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={ResoudreReclamation}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            résoudre cette réclamation
                          </button>
                        </div>
                      ) : null}
                      {userType === "User" &&
                      (selectedHistory.status === "closed" ||
                        selectedHistory.status === "Full") ? (
                        <>
                          <RatingSystem
                            userName={nom}
                            userLastName={prenom}
                            TechnicienID={selectedHistory.client_accept}
                          />
                        </>
                      ) : null}
                      {selectedHistory.status === "Paymen_User" ? (
                        <PdfViewer
                          url={`${apiUrl}${selectedHistory.payslip}`}
                        />
                      ) : null}
                      {userType === "Tech" ? (
                        <>
                          <>
                            {selectedHistory.status === "Accepted" ||
                            selectedHistory.status === "claim" ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    //onClick={techCloseRequest}
                                    onClick={handletechCloseModal}
                                    className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                  >
                                    Clôturer l'intervention
                                  </button>
                                  {userType === "Tech" &&
                                    selectedHistory.status === "Accepted" && (
                                      <button
                                        onClick={sendLocationToBackend}
                                        className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                      >
                                        Client manquant
                                      </button>
                                    )}
                                </div>
                              </>
                            ) : null}
                          </>
                          <>
                            {selectedHistory.status === "Accepted_payment" ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    onClick={techCloseRequest}
                                    //onClick={handletechCloseModal}
                                    className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                  >
                                    Clôturer l'intervention
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </>
                        </>
                      ) : (
                        <>
                          {selectedHistory.status === "closed_tech" ? (
                            <div className="flex gap-4 justify-end p-10">
                              <button
                                onClick={UserCloseRequest}
                                className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                              >
                                Confirmer la fin de l'intervention
                              </button>
                              <button
                                onClick={handleUserNotCloseModal}
                                className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Intervention toujours ouverte
                              </button>
                            </div>
                          ) : selectedHistory.status === "Paymen_User" ? (
                            <div className="flex gap-4 justify-end p-10">
                              <button
                                onClick={handleAccept}
                                className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Accepter le devis
                              </button>
                              <button
                                onClick={handleDeny}
                                className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                              >
                                Refuser le devis
                              </button>
                            </div>
                          ) : null}
                        </>
                      )}
                    </dl>
                  </div>
                </>
              )}
              {techCloseModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Clôturer l'intervention
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Veuillez nous informer si l’intervention est
                                  clôturée ou non. Si l’intervention n’est pas
                                  clôturée choisissez: "Besoin d’un devis" pour
                                  transmettre votre devis au client ou “Besoin
                                  d’une deuxième intervention”.
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={techCloseRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                  py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Clôturer l'intervention
                                </button>
                                {/*<button
                                //onClick={techCloseRequest}
                                //onClick={handletechCloseModal}
                                className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                  py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                              >
                                Besoin d'une autre intervention
                              </button>*/}

                                <button
                                  onClick={handleUnfinishedClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                  py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Besoin d’un devis
                                </button>
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={handletechCloseModal}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Annuler
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Envoyer un devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Si vous connaissez le montant de votre devis
                                  choisissez “Je connais le montant”,Si non
                                  choisissez “Je ne connais pas le montant” pour
                                  uploader un devis en format PDF.
                                </p>
                              </div>
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                {/*<button
                              //onClick={techCloseRequest}
                              //onClick={handletechCloseModal}
                              className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                            >
                              Besoin d'une autre intervention
              </button>*/}
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Je ne connais pas le montant
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
    text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                              text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {UserNotCloseModal && (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => handleUserNotCloseModal}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Intervention est toujours ouverte
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Merci de préciser pourquoi vous pensez que
                              l'intervention est toujours ouverte.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={claime}
                                    onChange={handleClaime}
                                  />
                                </div>
                              </form>
                            </div>
                            <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                onClick={UserClaimRequest}
                              >
                                Ajouter
                              </button>
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={handleUserNotCloseModal}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {techClaimeModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Selon le client la demande est toujours
                                  ouverte
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={LitigeRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Intervention Cloturé
                                </button>

                                <button
                                  onClick={handleButtonClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Intervention toujour ouverte
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  L'intervention nécessite une autre action
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={TechAccept}
                                  //onClick={TechAccept}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Nouvelle Intervention
                                  </p>
                                </button>
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Devis
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
  text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                            text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {/* Pro User */}
      {entreprise === "oui" && (
        <>
          {selectedHistory && (
            <div className="overflow-hidden bg-transparent shadow sm:rounded-lg max-w-screen-xl  ml-auto mr-auto mt-10 mb-10">
              {product && (
                <>
                  <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto"></div>

                  <div className="flex justify-around mt-6">
                    {product.photoDuProduit &&
                      product.photoDuProduit !== "null" &&
                      product.photoDuProduit.map((photo, index) => (
                        <img
                          src={`https://lebontechnicien.net/${product.photoDuProduit[index]}`}
                          alt={product._id}
                          className="h-[30%] w-[30%] object-cover object-center mt-0 mb-0 ml-auto mr-auto"
                          key={index}
                        />
                      ))}

                    <img
                      src={`https://lebontechnicien.net/${selectedHistory.QR}`}
                      alt={selectedHistory._id}
                      className="h-[30%] w-[30%] object-cover object-center mt-0 mb-0 ml-auto mr-auto"
                    />
                  </div>

                  <div className="flex justify-center mb-14">
                    <button
                      className="mt-4 mr-4 px-4 py-2 bg-green-500 text-white rounded"
                      onClick={handlePrintQR}
                    >
                      Imprimer le code QR
                    </button>
                  </div>

                  {selectedHistory.status === "annulled" && (
                    <h3 className="text-[22px] leading-6 text-[#ff0000] ml-20 mb-5 font-bold text-center mb-14">
                      Intervention Annuleé
                    </h3>
                  )}

                  {/* Détails de l'appareil client pro */}

                  <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                    Détails de l'appareil:
                  </h3>
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <dl>
                      {selectedHistory.typeDeProjet && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Type d'appareil:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.typeDeProjet}
                          </dd>
                        </div>
                      )}

                      {selectedHistory.moisInstallation && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Mois et année d'installation:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.moisInstallation} /{" "}
                            {selectedHistory.dateInstallation
                              ? selectedHistory.dateInstallation
                              : selectedHistory.anneeInstallation}
                          </dd>
                        </div>
                      )}

                      {selectedHistory.marque && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Marque:
                          </dt>

                          {product.marque === "autre" ||
                          selectedHistory.marque === "autre" ? (
                            <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.autreMarque
                                ? product.autreMarque
                                : selectedHistory.autreMarque}
                            </dd>
                          ) : (
                            <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.marque
                                ? product.marque
                                : selectedHistory.marque}
                            </dd>
                          )}
                        </div>
                      )}

                      {selectedHistory.reference && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Référence
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.reference}
                          </dd>
                        </div>
                      )}

                      {selectedHistory.puissance && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Puissance
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.puissance}
                          </dd>
                        </div>
                      )}

                      {selectedHistory.entretenu && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Maintenu
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.entretenu}
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>
                </>
              )}
              {selectedHistory.type === "Demande d'intervention" && (
                <>
                  {/* Détails de l'intervention client pro*/}
                  <>
                    <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                      Détails de l'intervention:
                    </h3>
                    <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                      <dl>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Statut de l'intervention:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {(() => {
                              switch (selectedHistory.status) {
                                case "Pending":
                                  return "En cours";
                                case "closed":
                                  return "Cloturé";
                                case "closed_tech":
                                  return "Cloturé par technicien";
                                case "claim":
                                  return "Réclamation";
                                case "Denied_payment":
                                  return "Devis refusé";
                                case "Accepted_payment":
                                  return "Devis accepté";
                                case "Paymen_User":
                                  return "Devis";
                                case "Accepted":
                                  return "Accepté";
                                case "claim_fix":
                                  return "Résolu";
                                case "Email Pending":
                                  return "Email non validé";
                                case "Confirmed":
                                  return "validé";
                                case "In progress":
                                  return "En cours de vérification";
                                case "Denied":
                                  return "Réfusé";
                                case "solved":
                                  return "Résolu";
                                case "unsolved":
                                  return "Non résolu";
                                case "Full":
                                  return "Complète";
                                case "litige_fixed":
                                  return "Litige résolu";
                                case "claime_fixed":
                                  return "Réclamation résolu";
                                case "annulled":
                                  return "Annulée";
                                case "admin_accept":
                                  return "Contrat en attente de signature";
                                case "Tech_onhold":
                                  return "En attente";
                                default:
                                  return selectedHistory.status;
                              }
                            })()}
                          </dd>
                        </div>

                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de la demande:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {formattedDate}
                            </dd>
                          </dd>
                        </div>

                        {selectedHistory.date_intervention && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Date de l'intervention:
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                  selectedHistory.date_intervention
                                )
                                  ? selectedHistory.date_intervention
                                  : new Date(
                                      selectedHistory.date_intervention
                                    ).toLocaleDateString("fr-FR")}
                              </dd>
                            </dd>
                          </div>
                        )}

                        {selectedHistory.TechMessage && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Message du technicien:
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.TechMessage}
                              </dd>
                            </dd>
                          </div>
                        )}

                        {selectedHistory.date_intervention && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Date de l'intervention:
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {new Date(
                                selectedHistory.date_intervention
                              ).toLocaleDateString("fr-FR")}{" "}
                              entre{" "}
                              {new Date(
                                selectedHistory.date_intervention
                              ).toLocaleTimeString("fr-FR", {
                                hour: "numeric",
                                hour12: false,
                              })}{" "}
                              et{" "}
                              {new Date(
                                new Date(
                                  selectedHistory.date_intervention
                                ).getTime() +
                                  2 * 60 * 60 * 1000
                              ).toLocaleTimeString("fr-FR", {
                                hour: "numeric",
                                hour12: false,
                              })}
                            </dd>
                          </div>
                        )}

                        {selectedHistory.typeDePrestation && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Type de prestation
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.typeDePrestation}
                              </dd>
                            </dd>
                          </div>
                        )}

                        {selectedHistory.probleme && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Description de la panne
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.probleme}
                            </dd>
                          </div>
                        )}

                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Photos & documents
                          </dt>
                          <dd
                            className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                            style={{
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <div className="flex flex-wrap gap-4 ">
                              {photoKeys.slice(1).map((photoKey, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col items-center w-[200px] mb-4"
                                >
                                  {selectedHistory[photoKey].endsWith(
                                    ".pdf"
                                  ) ? (
                                    <a
                                      href={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={pdfImage} // PDF icon image link
                                        alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                        className="w-[100px] h-[100px] cursor-pointer"
                                      />
                                    </a>
                                  ) : (
                                    <img
                                      className="w-[100px] h-[100px] cursor-pointer"
                                      src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                      alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                      onClick={() => openModalImages(index + 1)} // Add 1 to the index to skip the first file
                                    />
                                  )}
                                  <div className=" mt-2">
                                    <p>
                                      {selectedHistory[photoKey]
                                        .split("/")
                                        .pop()
                                        .split("-")
                                        .pop()}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </dd>
                          {modalOpen && (
                            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                              <div className="bg-black opacity-50 w-full h-full absolute"></div>
                              <div className="bg-white p-8 rounded-lg relative z-10 w-[90%] ml-auto mr-auto">
                                <span
                                  className="close flex justify-end cursor"
                                  onClick={closeModalImages}
                                >
                                  &times;
                                </span>
                                {photoKeys[selectedImageIndex].endsWith(
                                  ".pdf"
                                ) ? (
                                  <button
                                    onClick={() => {
                                      console.log(
                                        photoKeys[selectedImageIndex]
                                      );
                                      downloadFile(
                                        `${apiUrl}${
                                          selectedHistory[
                                            photoKeys[selectedImageIndex]
                                          ]
                                        }`
                                      );
                                    }}
                                  >
                                    Download PDF
                                  </button>
                                ) : (
                                  <img
                                    className="w-[40%] flex justify-center ml-auto mr-auto"
                                    src={`https://lebontechnicien.net/${
                                      selectedHistory[
                                        photoKeys[selectedImageIndex]
                                      ]
                                    }`}
                                    alt={`Photoduprobleme ${
                                      selectedImageIndex + 1
                                    }`}
                                  />
                                )}
                                {/*<div className="flex ml-auto mr-auto justify-center gap-4">
                                  <button
                                    className="bg-green-600 text-white rounded-md p-4"
                                    onClick={prevImage}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    className={`bg-green-600 text-white rounded-md p-4 ${
                                      selectedImageIndex ===
                                      photoKeys.length - 2
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                    }`}
                                    onClick={nextImage}
                                  >
                                    Next
                                  </button>
                                  </div>*/}
                              </div>
                            </div>
                          )}
                        </div>
                      </dl>
                    </div>
                  </>

                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10 p-5">
                    {selectedHistory.status !== "closed" &&
                      selectedHistory.status !== "annuled" && (
                        <>
                          <div>
                            <legend className="contents text-sm font-medium text-gray-600 mt-4">
                              Ajoutez de nouveaux documents ou des photos
                              <span className="text-red-500"></span>
                            </legend>
                          </div>

                          <div>
                            <div>
                              {prod_photoDuProduit && (
                                <div className="mt-8 flex flex-between w-full gap-8">
                                  <div className="flex items-center flex-wrap w-[80%] gap-4">
                                    {Array.from(prod_photoDuProduit).map(
                                      (file, index) => (
                                        <div key={index} className="w-[150px]">
                                          <div className="flex justify-center">
                                            {file.name
                                              .toLowerCase()
                                              .endsWith(".pdf") ? (
                                              <>
                                                <img
                                                  src={pdfImage}
                                                  alt={`PDF Preview-${index}`}
                                                  className="h-16 w-16 object-cover rounded"
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={URL.createObjectURL(
                                                    file
                                                  )}
                                                  alt={`Preview-${index}`}
                                                  className="h-16 w-16 object-cover rounded"
                                                />
                                              </>
                                            )}
                                            <button
                                              className="bg-gray-100 p-1 rounded-sm text-[10px] mb-10 mr-[-4px] font-bold text-red-500"
                                              onClick={() =>
                                                handleRemovePicture(index)
                                              }
                                            >
                                              X
                                            </button>
                                          </div>
                                          <div className="w-[150px] h-[100px] mt-4 flex justify-center">
                                            <p className="text-sm text-center">
                                              {file.name}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className="flex gap-2">
                                    {/*<button
                                      className="mt-[10px] bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                      onClick={() => setPhotoDuProduit([])}
                                    >
                                      Change
                                          </button>*/}
                                    <div className="mt-4">
                                      <label
                                        htmlFor="photoDuProduit"
                                        className=""
                                      >
                                        {/*<IoAddCircleOutline className="h-10 w-10 text-green-500" />*/}
                                        <svg
                                          className="mx-auto h-12 w-12 text-gray-400 pointer"
                                          stroke="currentColor"
                                          fill="none"
                                          viewBox="0 0 48 48"
                                          aria-hidden="true"
                                        >
                                          <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        <p className="text-sm">
                                          Ajouter un fichier{" "}
                                        </p>
                                        <input
                                          id="photoDuProduit"
                                          name="photoDuProduit"
                                          type="file"
                                          multiple
                                          className="sr-only"
                                          onChange={handleAddMorePictures}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!prod_photoDuProduit && (
                                <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                  <div className="space-y-1 text-center">
                                    <svg
                                      className="mx-auto h-12 w-12 text-gray-400"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 48 48"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>

                                    <div className="flex text-sm text-gray-600 justify-center">
                                      <label
                                        htmlFor="photoDuProduit"
                                        className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                      >
                                        <span>Ajouter un fichier </span>
                                        <input
                                          id="photoDuProduit"
                                          name="photoDuProduit"
                                          type="file"
                                          multiple
                                          className="sr-only"
                                          value=""
                                          onChange={handlePhotoDuProduit}
                                        />
                                      </label>
                                      <p className="pl-1">
                                        ou faites glisser et déposez
                                      </p>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                      PNG, JPG, PDF
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="flex justify-end gap-4 p-10">
                              <button
                                onClick={handleAddPhotos}
                                className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-green-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-green-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Mettre à jour
                              </button>
                            </div>
                          </div>

                          <></>
                        </>
                      )}
                  </div>

                  {/* Détails de client client pro*/}
                  <>
                    <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                      Détail du Client:
                    </h3>
                    <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                      <dl>
                        {selectedHistory.nom && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Nom du client
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.nom}
                            </dd>
                          </div>
                        )}
                        {selectedHistory.prenom && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Prenom du client
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.prenom}
                            </dd>
                          </div>
                        )}
                        {selectedHistory.adresse && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Adresse du client
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.adresse}
                            </dd>
                          </div>
                        )}
                        {selectedHistory.telephone && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Numéro du client
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.telephone}
                            </dd>
                          </div>
                        )}
                        {selectedHistory.zone !== null &&
                          selectedHistory.zone !== "null" &&
                          selectedHistory.zone && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Département
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.zone}
                              </dd>
                            </div>
                          )}
                      </dl>
                    </div>
                  </>
                  {/* Détails de Technicien client pro*/}
                  <>
                    {selectedHistory.status !== "Pending" &&
                      selectedHistory.status !== "annulled" && (
                        <>
                          <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                            Détail du Technicien:
                          </h3>
                          <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                            <dl>
                              {selectedHistory.raisonSociale && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Raison sociale
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.raisonSociale}
                                  </dd>
                                </div>
                              )}
                              {selectedHistory.Technicien && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Nom
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.Technicien}
                                  </dd>
                                </div>
                              )}
                              {selectedHistory.Technicienprenom && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Prénom
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.Technicienprenom}
                                  </dd>
                                </div>
                              )}
                            </dl>
                          </div>
                        </>
                      )}
                    {selectedHistory.status !== "Pending" &&
                      selectedHistory.status !== "Accepted" &&
                      selectedHistory.status !==
                        "En attente de date d'intervention" &&
                      selectedHistory.status !== "annulled" && (
                        <>
                          <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                            Rapport d'intervention:
                          </h3>
                          <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                            <dl>
                              {selectedHistory.typeDeProjet &&
                                !selectedHistory.typeDeProjet.includes(
                                  "PAC"
                                ) && (
                                  <>
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Type de problème:
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.problemType}
                                        </dd>
                                      </dd>
                                    </div>
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Description de l'intervention et du
                                        problème :
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.detailedDescription}
                                      </dd>
                                    </div>
                                  </>
                                )}

                              {selectedHistory.typeDeProjet.includes("PAC") && (
                                <>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Diametre Liaisons Frigorifiques
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {
                                        selectedHistory.diametreLiaisonsFrigorifiques
                                      }
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      liaisons Frigorifiques :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.liaisonsFrigorifiques}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      flui de Frigorigene :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.fluideFrigorigene}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      alimentation Electrique :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.alimentationElectrique}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      CERFA :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.CERFA}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      hydrauliques :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.hydrauliques}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      hydrauliques Module :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.hydrauliquesModule}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      raccordements Electriques :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.raccordementsElectriques}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      vannes :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.vannes}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Reglage :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.reglageReglage}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      climatisation :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.climatisation}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      appoint :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.appoint}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Pac Climatisation :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.PacClimatisation}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      prescriptions Constructeur :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {
                                        selectedHistory.prescriptionsConstructeur
                                      }
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      arret :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.arret}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      observations :
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.observations}
                                    </dd>
                                  </div>
                                </>
                              )}
                              {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Entreprise qui a intervenu :
                              </dt>
                              <dd
                                className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900"
                                onClick={() => {
                                  window.location.href = `/product/${selectedHistory.created_for}`;
                                }}
                              >
                                {selectedHistory.typeDeProjet}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Devis :
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.zone}
                              </dd>
                            </div>*/}
                            </dl>
                          </div>
                        </>
                      )}
                  </>
                  {selectedHistory.information && (
                    <>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          PLus de Détails
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.information}
                        </dd>
                      </div>
                    </>
                  )}
                  {userType === "User" && (
                    <>
                      {nomtec && prenomtec && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Nom & Prenom Tech
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {nomtec} {prenomtec}
                          </dd>
                        </div>
                      )}
                      {reviewTec && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Avis Clients
                          </dt>
                        </div>
                      )}
                      {userType === "User" && reviewTec?.length > 0 && (
                        <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {reviewTec.map((review, index) => (
                            <div
                              key={index}
                              className="w-full mb-4 bg-white p-4 rounded-md"
                            >
                              <div className="flex gap-2 mb-2">
                                <p>
                                  {review.userName} {review.userLastName}
                                </p>
                                <Rating rating={review.rating} />
                              </div>
                              <p>{review.comment}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}

                  {pendingList[0]?.produits &&
                    pendingList[0]?._id === selectedHistory.created_for && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Votre Appareil
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {pendingList[0]?.produits}
                        </dd>
                      </div>
                    )}
                  {userType === "User" &&
                  selectedHistory.status === "Pending" &&
                  selectedHistory.accepted_by.length === 0 &&
                  selectedHistory.denied_by.length === 0 ? (
                    <>
                      <div className="flex justify-end gap-4 p-10">
                        <button
                          onClick={HandleAnnulation}
                          //onClick={handletechCloseModal}
                          className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                        >
                          Annuler l’intervention
                        </button>
                      </div>
                    </>
                  ) : null}
                  {userType === "User" &&
                  selectedHistory.status === "Pending" &&
                  showButtons === "oui" &&
                  (selectedHistory.accepted_by.length > 0 ||
                    selectedHistory.denied_by.length > 0) ? (
                    <>
                      <div className="flex gap-4 justify-end p-10">
                        <button
                          onClick={executeSequentially}
                          //onClick={handletechCloseModal}
                          className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-green-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-green-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                        >
                          Accpeter
                        </button>
                        <button
                          onClick={handleDenyUser}
                          //onClick={handletechCloseModal}
                          className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-red-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-red-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                        >
                          Refuser
                        </button>
                      </div>
                    </>
                  ) : null}
                  {userType === "User" &&
                  (selectedHistory.status === "closed" ||
                    selectedHistory.status === "Full") ? (
                    <>
                      <RatingSystem
                        userName={nom}
                        userLastName={prenom}
                        TechnicienID={selectedHistory.client_accept}
                      />
                    </>
                  ) : null}
                  {selectedHistory.status === "Paymen_User" ? (
                    <PdfViewer url={`${apiUrl}{selectedHistory.payslip}`} />
                  ) : null}
                  {userType === "Tech" ? (
                    <>
                      <>
                        {selectedHistory.status === "Accepted" ||
                        selectedHistory.status === "claim" ? (
                          <>
                            <div className="flex justify-end  gap-4">
                              <button
                                //onClick={techCloseRequest}
                                onClick={handletechCloseModal}
                                className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Clôturer l'intervention
                              </button>
                              {userType === "Tech" &&
                                selectedHistory.status === "Accepted" && (
                                  <button
                                    onClick={sendLocationToBackend}
                                    className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                  >
                                    Client manquant
                                  </button>
                                )}
                            </div>
                          </>
                        ) : null}
                      </>
                      <>
                        {selectedHistory.status === "Accepted_payment" ? (
                          <>
                            <div className="flex justify-end  gap-4">
                              <button
                                onClick={techCloseRequest}
                                //onClick={handletechCloseModal}
                                className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                              >
                                Clôturer l'intervention
                              </button>
                            </div>
                          </>
                        ) : null}
                      </>
                    </>
                  ) : (
                    <>
                      {selectedHistory.status === "closed_tech" ? (
                        <div className="flex gap-4 justify-end p-10">
                          <button
                            onClick={UserCloseRequest}
                            className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                          >
                            Confirmer la fin de l'intervention
                          </button>
                          <button
                            onClick={handleUserNotCloseModal}
                            className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                          >
                            Intervention toujours ouverte
                          </button>
                        </div>
                      ) : selectedHistory.status === "Paymen_User" ? (
                        <div className="flex gap-4 justify-end p-10">
                          <button
                            onClick={handleAccept}
                            className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                          >
                            Accepter le devis
                          </button>
                          <button
                            onClick={handleDeny}
                            className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                          >
                            Refuser le devis
                          </button>
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}
              {selectedHistory.type === "Demande de Réclamation" && (
                <>
                  <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                    Détails de Réclamation:
                  </h3>
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <dl>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Date de la demande:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {formattedDate}
                          </dd>
                        </dd>
                      </div>
                      {selectedHistory.date_intervention && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de l'intervention:
                          </dt>

                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                selectedHistory.date_intervention
                              )
                                ? selectedHistory.date_intervention
                                : new Date(
                                    selectedHistory.date_intervention
                                  ).toLocaleDateString("fr-FR")}
                            </dd>
                          </dd>
                        </div>
                      )}
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Message
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.claime}
                        </dd>
                      </div>
                      {photoKeys && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Photo du problème
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <div className="flex gap-10">
                              <div
                                style={{
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {photoKeys.map((photoKey, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <img
                                      className="w-[80px] h-[80px] cursor-pointer"
                                      src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                      alt={`Photoduprobleme ${index + 1}`}
                                      onClick={() =>
                                        openModal(
                                          `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </dd>
                        </div>
                      )}
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Statut:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {(() => {
                            switch (selectedHistory.status) {
                              case "Pending":
                                return "En cours";
                              case "closed":
                                return "Cloturé";
                              case "closed_tech":
                                return "Cloturé par technicien";
                              case "claim":
                                return "Réclamation";
                              case "Denied_payment":
                                return "Devis refusé";
                              case "Accepted_payment":
                                return "Devis accepté";
                              case "Paymen_User":
                                return "Devis";
                              case "Accepted":
                                return "Accepté";
                              case "claim_fix":
                                return "Résolu";
                              case "Email Pending":
                                return "Email non validé";
                              case "Confirmed":
                                return "validé";
                              case "In progress":
                                return "En cours de vérification";
                              case "Denied":
                                return "Réfusé";
                              case "solved":
                                return "Résolu";
                              case "unsolved":
                                return "Non résolu";
                              case "Full":
                                return "Complète";
                              case "litige_fixed":
                                return "Litige résolu";
                              case "claime_fixed":
                                return "Réclamation résolu";
                              case "annulled":
                                return "Annulée";
                              case "admin_accept":
                                return "Contrat en attente de signature";
                              case "Tech_onhold":
                                return "En attente";
                              default:
                                return selectedHistory.status;
                            }
                          })()}
                        </dd>
                      </div>
                      {selectedHistory.information && (
                        <>
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              PLus de Détails
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.information}
                            </dd>
                          </div>

                          {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Message du technicien
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.information}
                      </dd>
                </div>*/}
                        </>
                      )}
                      {userType === "User" && (
                        <>
                          {nomtec && prenomtec && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Nom & Prenom Tech
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {nomtec} {prenomtec}
                              </dd>
                            </div>
                          )}
                          {reviewTec && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Avis Clients
                              </dt>
                            </div>
                          )}
                          {userType === "User" && reviewTec?.length > 0 && (
                            <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {reviewTec.map((review, index) => (
                                <div
                                  key={index}
                                  className="w-full mb-4 bg-white p-4 rounded-md"
                                >
                                  <div className="flex gap-2 mb-2">
                                    <p>
                                      {review.userName} {review.userLastName}
                                    </p>
                                    <Rating rating={review.rating} />
                                  </div>
                                  <p>{review.comment}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}

                      {pendingList[0]?.produits &&
                        pendingList[0]?._id === selectedHistory.created_for && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Votre Appareil
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {pendingList[0]?.produits}
                            </dd>
                          </div>
                        )}
                      {userType === "User" &&
                      selectedHistory.status === "Pending" &&
                      selectedHistory.accepted_by.length === 0 &&
                      selectedHistory.denied_by.length === 0 ? (
                        <>
                          <div className="flex justify-end  gap-4">
                            <button
                              onClick={HandleAnnulation}
                              //onClick={handletechCloseModal}
                              className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                            >
                              Annuler l’intervention
                            </button>
                          </div>
                        </>
                      ) : null}
                      {userType === "User" &&
                      selectedHistory.status === "Pending" &&
                      (selectedHistory.accepted_by.length > 0 ||
                        selectedHistory.denied_by.length > 0) ? (
                        <>
                          <div className="flex gap-4 justify-end p-10">
                            <button
                              onClick={executeSequentially}
                              //onClick={handletechCloseModal}
                              className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-green-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-green-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                            >
                              Accpeter
                            </button>
                            <button
                              onClick={handleDenyUser}
                              //onClick={handletechCloseModal}
                              className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-red-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-red-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                            >
                              Refuser
                            </button>
                          </div>
                        </>
                      ) : null}
                      {userType === "Tech" &&
                      selectedHistory.status === "claim" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={handletechCClaimeModal}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            répondre à la réclamation du client
                          </button>
                        </div>
                      ) : null}
                      {(userType === "Admin" || userType === "Superviseur") &&
                      selectedHistory.status === "litige" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={ResoudreLitige}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            résoudre ce litige
                          </button>
                        </div>
                      ) : null}
                      {(userType === "Admin" || userType === "Superviseur") &&
                      selectedHistory.type === "Demande de Réclamation" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={ResoudreReclamation}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            résoudre cette réclamation
                          </button>
                        </div>
                      ) : null}
                      {userType === "User" &&
                      (selectedHistory.status === "closed" ||
                        selectedHistory.status === "Full") ? (
                        <>
                          <RatingSystem
                            userName={nom}
                            userLastName={prenom}
                            TechnicienID={selectedHistory.client_accept}
                          />
                        </>
                      ) : null}
                      {selectedHistory.status === "Paymen_User" ? (
                        <PdfViewer
                          url={`${apiUrl}${selectedHistory.payslip}`}
                        />
                      ) : null}
                      {userType === "Tech" ? (
                        <>
                          <>
                            {selectedHistory.status === "Accepted" ||
                            selectedHistory.status === "claim" ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    //onClick={techCloseRequest}
                                    onClick={handletechCloseModal}
                                    className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                  >
                                    Clôturer l'intervention
                                  </button>
                                  {userType === "Tech" &&
                                    selectedHistory.status === "Accepted" && (
                                      <button
                                        onClick={sendLocationToBackend}
                                        className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                      >
                                        Client manquant
                                      </button>
                                    )}
                                </div>
                              </>
                            ) : null}
                          </>
                          <>
                            {selectedHistory.status === "Accepted_payment" ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    onClick={techCloseRequest}
                                    //onClick={handletechCloseModal}
                                    className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                  >
                                    Clôturer l'intervention
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </>
                        </>
                      ) : (
                        <>
                          {selectedHistory.status === "closed_tech" ? (
                            <div className="flex gap-4 justify-end p-10">
                              <button
                                onClick={UserCloseRequest}
                                className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                              >
                                Confirmer la fin de l'intervention
                              </button>
                              <button
                                onClick={handleUserNotCloseModal}
                                className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Intervention toujours ouverte
                              </button>
                            </div>
                          ) : selectedHistory.status === "Paymen_User" ? (
                            <div className="flex gap-4 justify-end p-10">
                              <button
                                onClick={handleAccept}
                                className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Accepter le devis
                              </button>
                              <button
                                onClick={handleDeny}
                                className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                              >
                                Refuser le devis
                              </button>
                            </div>
                          ) : null}
                        </>
                      )}
                    </dl>
                  </div>
                </>
              )}
              {selectedHistory.type === "Demande de Maintenance" && (
                <>
                  <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                    Détails de contrat de maintenance:
                  </h3>
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <dl>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Date de la demande:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {formattedDate}
                          </dd>
                        </dd>
                      </div>
                      {selectedHistory.date_intervention && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de l'intervention:
                          </dt>

                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                selectedHistory.date_intervention
                              )
                                ? selectedHistory.date_intervention
                                : new Date(
                                    selectedHistory.date_intervention
                                  ).toLocaleDateString("fr-FR")}
                            </dd>
                          </dd>
                        </div>
                      )}
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Date de fin:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.end_date}
                          </dd>
                        </dd>
                      </div>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Photos & documents
                        </dt>
                        <dd
                          className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                          style={{
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <div
                            className="flex gap-4 overflow-x-auto whitespace-nowrap"
                            style={{ minWidth: "100%" }}
                          >
                            {photoKeys.slice(1).map((photoKey, index) => (
                              <>
                                <div
                                  key={index}
                                  className="inline-block w-[300px] h-[100px]"
                                >
                                  {selectedHistory[photoKey].endsWith(
                                    ".pdf"
                                  ) ? (
                                    <>
                                      <a
                                        href={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src={pdfImage} // PDF icon image link
                                          alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                          className="w-[100px] h-[100px]"
                                        />
                                      </a>
                                    </>
                                  ) : (
                                    <img
                                      className="w-[100px] h-[100px] cursor-pointer"
                                      src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                      alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                      onClick={() => openModalImages(index + 1)} // Add 1 to the index to skip the first file
                                    />
                                  )}
                                </div>
                                <div className="w-[100%]">
                                  <p>TEst</p>
                                  <p>
                                    {selectedHistory[photoKey].split("/").pop()}
                                  </p>
                                </div>
                              </>
                            ))}
                          </div>
                        </dd>
                        {modalOpen && (
                          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                            <div className="bg-black opacity-50 w-full h-full absolute"></div>
                            <div className="bg-white p-8 rounded-lg relative z-10 w-[90%] ml-auto mr-auto">
                              <span
                                className="close flex justify-end cursor"
                                onClick={closeModalImages}
                              >
                                &times;
                              </span>
                              {photoKeys[selectedImageIndex].endsWith(
                                ".pdf"
                              ) ? (
                                <button
                                  onClick={() => {
                                    console.log(photoKeys[selectedImageIndex]);
                                    downloadFile(
                                      `${apiUrl}${
                                        selectedHistory[
                                          photoKeys[selectedImageIndex]
                                        ]
                                      }`
                                    );
                                  }}
                                >
                                  Download PDF
                                </button>
                              ) : (
                                <img
                                  className="w-[40%] flex justify-center ml-auto mr-auto"
                                  src={`https://lebontechnicien.net/${
                                    selectedHistory[
                                      photoKeys[selectedImageIndex]
                                    ]
                                  }`}
                                  alt={`Photoduprobleme ${
                                    selectedImageIndex + 1
                                  }`}
                                />
                              )}
                              {/*<div className="flex ml-auto mr-auto justify-center gap-4">
                                  <button
                                    className="bg-green-600 text-white rounded-md p-4"
                                    onClick={prevImage}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    className={`bg-green-600 text-white rounded-md p-4 ${
                                      selectedImageIndex ===
                                      photoKeys.length - 2
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                    }`}
                                    onClick={nextImage}
                                  >
                                    Next
                                  </button>
                                  </div>*/}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Statut:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {(() => {
                            switch (selectedHistory.status) {
                              case "Pending":
                                return "En cours";
                              case "closed":
                                return "Cloturé";
                              case "closed_tech":
                                return "Cloturé par technicien";
                              case "claim":
                                return "Réclamation";
                              case "Denied_payment":
                                return "Devis refusé";
                              case "Accepted_payment":
                                return "Devis accepté";
                              case "Paymen_User":
                                return "Devis";
                              case "Accepted":
                                return "Accepté";
                              case "claim_fix":
                                return "Résolu";
                              case "Email Pending":
                                return "Email non validé";
                              case "Confirmed":
                                return "validé";
                              case "In progress":
                                return "En cours de vérification";
                              case "Denied":
                                return "Réfusé";
                              case "solved":
                                return "Résolu";
                              case "unsolved":
                                return "Non résolu";
                              case "Full":
                                return "Complète";
                              case "litige_fixed":
                                return "Litige résolu";
                              case "claime_fixed":
                                return "Réclamation résolu";
                              case "annulled":
                                return "Annulée";
                              case "admin_accept":
                                return "Contrat en attente de signature";
                              case "Tech_onhold":
                                return "En attente";
                              default:
                                return selectedHistory.status;
                            }
                          })()}
                        </dd>
                      </div>
                      {selectedHistory.information && (
                        <>
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              PLus de Détails
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.information}
                            </dd>
                          </div>

                          {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Message du technicien
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.information}
                      </dd>
                </div>*/}
                        </>
                      )}
                      {userType === "User" && (
                        <>
                          {nomtec && prenomtec && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Nom & Prenom Tech
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {nomtec} {prenomtec}
                              </dd>
                            </div>
                          )}
                          {reviewTec && (
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Avis Clients
                              </dt>
                            </div>
                          )}
                          {userType === "User" && reviewTec?.length > 0 && (
                            <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {reviewTec.map((review, index) => (
                                <div
                                  key={index}
                                  className="w-full mb-4 bg-white p-4 rounded-md"
                                >
                                  <div className="flex gap-2 mb-2">
                                    <p>
                                      {review.userName} {review.userLastName}
                                    </p>
                                    <Rating rating={review.rating} />
                                  </div>
                                  <p>{review.comment}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}

                      {pendingList[0]?.produits &&
                        pendingList[0]?._id === selectedHistory.created_for && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Votre Appareil
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {pendingList[0]?.produits}
                            </dd>
                          </div>
                        )}
                      {userType === "User" &&
                      selectedHistory.status === "Pending" &&
                      selectedHistory.accepted_by.length === 0 &&
                      selectedHistory.denied_by.length === 0 ? (
                        <>
                          <div className="flex justify-end  gap-4">
                            <button
                              onClick={HandleAnnulation}
                              //onClick={handletechCloseModal}
                              className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                            >
                              Annuler l’intervention
                            </button>
                          </div>
                        </>
                      ) : null}
                      {userType === "User" &&
                      selectedHistory.status === "Pending" &&
                      (selectedHistory.accepted_by.length > 0 ||
                        selectedHistory.denied_by.length > 0) ? (
                        <>
                          <div className="flex gap-4 justify-end p-10">
                            <button
                              onClick={executeSequentially}
                              //onClick={handletechCloseModal}
                              className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-green-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-green-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                            >
                              Accpeter
                            </button>
                            <button
                              onClick={handleDenyUser}
                              //onClick={handletechCloseModal}
                              className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-red-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-red-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                            >
                              Refuser
                            </button>
                          </div>
                        </>
                      ) : null}
                      {userType === "Tech" &&
                      selectedHistory.status === "claim" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={handletechCClaimeModal}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            répondre à la réclamation du client
                          </button>
                        </div>
                      ) : null}
                      {(userType === "Admin" || userType === "Superviseur") &&
                      selectedHistory.status === "litige" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={ResoudreLitige}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            résoudre ce litige
                          </button>
                        </div>
                      ) : null}
                      {(userType === "Admin" || userType === "Superviseur") &&
                      selectedHistory.type === "Demande de Réclamation" ? (
                        <div className="flex justify-end  gap-4">
                          <button
                            //onClick={techCloseRequest}
                            onClick={ResoudreReclamation}
                            className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                          >
                            résoudre cette réclamation
                          </button>
                        </div>
                      ) : null}
                      {userType === "User" &&
                      (selectedHistory.status === "closed" ||
                        selectedHistory.status === "Full") ? (
                        <>
                          <RatingSystem
                            userName={nom}
                            userLastName={prenom}
                            TechnicienID={selectedHistory.client_accept}
                          />
                        </>
                      ) : null}
                      {selectedHistory.status === "Paymen_User" ? (
                        <PdfViewer
                          url={`${apiUrl}${selectedHistory.payslip}`}
                        />
                      ) : null}
                      {userType === "Tech" ? (
                        <>
                          <>
                            {selectedHistory.status === "Accepted" ||
                            selectedHistory.status === "claim" ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    //onClick={techCloseRequest}
                                    onClick={handletechCloseModal}
                                    className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                  >
                                    Clôturer l'intervention
                                  </button>
                                  {userType === "Tech" &&
                                    selectedHistory.status === "Accepted" && (
                                      <button
                                        onClick={sendLocationToBackend}
                                        className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                      >
                                        Client manquant
                                      </button>
                                    )}
                                </div>
                              </>
                            ) : null}
                          </>
                          <>
                            {selectedHistory.status === "Accepted_payment" ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    onClick={techCloseRequest}
                                    //onClick={handletechCloseModal}
                                    className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                  >
                                    Clôturer l'intervention
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </>
                        </>
                      ) : (
                        <>
                          {selectedHistory.status === "closed_tech" ? (
                            <div className="flex gap-4 justify-end p-10">
                              <button
                                onClick={UserCloseRequest}
                                className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                              >
                                Confirmer la fin de l'intervention
                              </button>
                              <button
                                onClick={handleUserNotCloseModal}
                                className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Intervention toujours ouverte
                              </button>
                            </div>
                          ) : selectedHistory.status === "Paymen_User" ? (
                            <div className="flex gap-4 justify-end p-10">
                              <button
                                onClick={handleAccept}
                                className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                              >
                                Accepter le devis
                              </button>
                              <button
                                onClick={handleDeny}
                                className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                              >
                                Refuser le devis
                              </button>
                            </div>
                          ) : null}
                        </>
                      )}
                    </dl>
                  </div>
                </>
              )}
              {techCloseModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Clôturer l'intervention
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Veuillez nous informer si l’intervention est
                                  clôturée ou non. Si l’intervention n’est pas
                                  clôturée choisissez: "Besoin d’un devis" pour
                                  transmettre votre devis au client ou “Besoin
                                  d’une deuxième intervention”.
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={techCloseRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                  py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Clôturer l'intervention
                                </button>
                                {/*<button
                                //onClick={techCloseRequest}
                                //onClick={handletechCloseModal}
                                className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                  py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                              >
                                Besoin d'une autre intervention
                              </button>*/}

                                <button
                                  onClick={handleUnfinishedClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                  py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Besoin d’un devis
                                </button>
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={handletechCloseModal}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Annuler
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Envoyer un devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Si vous connaissez le montant de votre devis
                                  choisissez “Je connais le montant”,Si non
                                  choisissez “Je ne connais pas le montant” pour
                                  uploader un devis en format PDF.
                                </p>
                              </div>
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                {/*<button
                              //onClick={techCloseRequest}
                              //onClick={handletechCloseModal}
                              className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                            >
                              Besoin d'une autre intervention
              </button>*/}
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Je ne connais pas le montant
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
    text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                              text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {UserNotCloseModal && (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => handleUserNotCloseModal}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Intervention est toujours ouverte
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Merci de préciser pourquoi vous pensez que
                              l'intervention est toujours ouverte.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={claime}
                                    onChange={handleClaime}
                                  />
                                </div>
                              </form>
                            </div>
                            <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                onClick={UserClaimRequest}
                              >
                                Ajouter
                              </button>
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={handleUserNotCloseModal}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {techClaimeModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Selon le client la demande est toujours
                                  ouverte
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={LitigeRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Intervention Cloturé
                                </button>

                                <button
                                  onClick={handleButtonClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Intervention toujour ouverte
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  L'intervention nécessite une autre action
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={TechAccept}
                                  //onClick={TechAccept}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Nouvelle Intervention
                                  </p>
                                </button>
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Devis
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
  text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                            text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {/* Tech */}
      {userType === "Tech" && (
        <>
          {selectedHistory && (
            <div className="overflow-hidden bg-transparent shadow sm:rounded-lg max-w-screen-xl  ml-auto mr-auto mt-10 mb-10">
              {/* Détails de l'appareil: */}
              {product && (
                <>
                  <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto"></div>
                  <div className="flex justify-around mt-6">
                    {product.photoDuProduit &&
                      product.photoDuProduit !== "null" && (
                        <img
                          src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                          alt={product._id}
                          className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                        />
                      )}
                    <img
                      src={`https://lebontechnicien.net/${
                        (techPro === "oui" && proTeam === "non") ||
                        (proTeam === "oui" && techPro === "oui")
                          ? selectedHistory.QR
                          : product.Qr
                      }`}
                      alt={product._id}
                      className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                    />
                  </div>

                  {selectedHistory.status === "annulled" && (
                    <h3 className="text-[22px] leading-6 text-[#ff0000] ml-20 mb-5 font-bold text-center">
                      Intervention Annuleé
                    </h3>
                  )}
                  <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                    Détails de l'appareil:
                  </h3>
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <dl>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Type d'appareil
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {product.typeDuBien
                            ? product.typeDuBien
                            : selectedHistory.typeDeProjet}
                        </dd>
                      </div>
                      {(product.moisInstallation !== "" ||
                        selectedHistory.moisInstallation !== "" ||
                        selectedHistory.dateInstallation !== "" ||
                        selectedHistory.moisInstallation !== null ||
                        selectedHistory.dateInstallation !== null ||
                        !selectedHistory.moisInstallation ||
                        !selectedHistory.dateInstallation) && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Mois et année d'installation:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.moisInstallation &&
                            (product.annéeInstallation ||
                              product.anneeInstallation)
                              ? `${product.moisInstallation} / ${
                                  product.annéeInstallation
                                    ? product.annéeInstallation
                                    : product.anneeInstallation
                                }`
                              : `${selectedHistory.moisInstallation} / ${selectedHistory.dateInstallation}`}
                          </dd>
                        </div>
                      )}
                      {(product.marque || selectedHistory.marque) && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Marque:
                          </dt>
                          {product.marque === "autre" ||
                          selectedHistory.marque === "autre" ? (
                            <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.autreMarque
                                ? product.autreMarque
                                : selectedHistory.autreMarque}
                            </dd>
                          ) : (
                            <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {product.marque
                                ? product.marque
                                : selectedHistory.marque}
                            </dd>
                          )}
                        </div>
                      )}
                      {(product.reference ||
                        selectedHistory.référence ||
                        selectedHistory.reference) && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Référence
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.reference ||
                              selectedHistory.référence ||
                              selectedHistory.reference}
                          </dd>
                        </div>
                      )}
                      {(product.puissance || selectedHistory.puissance) && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Puissance
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.puissance
                              ? product.puissance
                              : selectedHistory.puissance}
                          </dd>
                        </div>
                      )}
                      {(product.entretenu || selectedHistory.entretenu) && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Maintenu
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {product.entretenu
                              ? product.entretenu
                              : selectedHistory.entretenu}
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>
                </>
              )}
              {/* Détails de l'intervention: */}
              <>
                <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                  Détails de l'intervention:
                </h3>
                <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                  <dl>
                    {selectedHistory.status && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Statut:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {(() => {
                            switch (selectedHistory.status) {
                              case "Pending":
                                return "En cours";
                              case "closed":
                                return "Cloturé";
                              case "closed_tech":
                                return "Cloturé par technicien";
                              case "claim":
                                return "Réclamation";
                              case "Denied_payment":
                                return "Devis refusé";
                              case "Accepted_payment":
                                return "Devis accepté";
                              case "Paymen_User":
                                return "Devis";
                              case "Accepted":
                                return "Accepté";
                              case "claim_fix":
                                return "Résolu";
                              case "Email Pending":
                                return "Email non validé";
                              case "Confirmed":
                                return "validé";
                              case "In progress":
                                return "En cours de vérification";
                              case "Denied":
                                return "Réfusé";
                              case "solved":
                                return "Résolu";
                              case "unsolved":
                                return "Non résolu";
                              case "Full":
                                return "Complète";
                              case "litige_fixed":
                                return "Litige résolu";
                              case "claime_fixed":
                                return "Réclamation résolu";
                              case "annulled":
                                return "Annulée";
                              case "admin_accept":
                                return "Contrat en attente de signature";
                              case "Tech_onhold":
                                return "En attente";
                              default:
                                return selectedHistory.status;
                            }
                          })()}
                        </dd>
                      </div>
                    )}
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Date de la demande:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {formattedDate}
                        </dd>
                      </dd>
                    </div>
                    {selectedHistory.PickedDate && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Date de l'intervention:
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {/^\d{2}\/\d{2}\/\d{4}$/.test(
                              selectedHistory.PickedDate
                            )
                              ? selectedHistory.PickedDate
                              : new Date(
                                  selectedHistory.PickedDate
                                ).toLocaleDateString("fr-FR")}
                          </dd>
                        </dd>
                      </div>
                    )}
                    {selectedHistory.date_intervention && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Date de l'intervention:
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {/^\d{2}\/\d{2}\/\d{4}$/.test(
                              selectedHistory.date_intervention
                            )
                              ? selectedHistory.date_intervention
                              : new Date(
                                  selectedHistory.date_intervention
                                ).toLocaleDateString("fr-FR")}
                          </dd>
                        </dd>
                      </div>
                    )}

                    {selectedHistory.typeDePrestation && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Type de préstation
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.typeDePrestation}
                        </dd>
                      </div>
                    )}
                    {selectedHistory.probleme && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Description de la panne
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.probleme}
                        </dd>
                      </div>
                    )}
                    {selectedHistory.TechMessage && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Message du technicien
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.TechMessage}
                        </dd>
                      </div>
                    )}
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Photos & Dcouments
                      </dt>
                      <dd
                        className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                        style={{
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <div
                          className="flex overflow-x-auto whitespace-nowrap"
                          style={{ minWidth: "100%" }}
                        >
                          {photoKeys.slice(1).map((photoKey, index) => (
                            <div key={index} className="inline-block mr-10">
                              {selectedHistory[photoKey].endsWith(".pdf") ? (
                                <a
                                  href={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={pdfImage} // PDF icon image link
                                    alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                    className="w-[100px] h-[100px]"
                                  />
                                </a>
                              ) : (
                                <img
                                  className="w-[100px] h-[100px] cursor-pointer"
                                  src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                  alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                  onClick={() => openModalImages(index + 1)} // Add 1 to the index to skip the first file
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      </dd>
                      {modalOpen && (
                        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                          <div className="bg-black opacity-50 w-full h-full absolute"></div>
                          <div className="bg-white p-8 rounded-lg relative z-10 w-[90%] ml-auto mr-auto">
                            <span
                              className="close flex justify-end cursor"
                              onClick={closeModalImages}
                            >
                              &times;
                            </span>
                            {photoKeys[selectedImageIndex].endsWith(".pdf") ? (
                              <button
                                onClick={() => {
                                  console.log(photoKeys[selectedImageIndex]);
                                  downloadFile(
                                    `${apiUrl}${
                                      selectedHistory[
                                        photoKeys[selectedImageIndex]
                                      ]
                                    }`
                                  );
                                }}
                              >
                                Download PDF
                              </button>
                            ) : (
                              <img
                                className="w-[40%] flex justify-center ml-auto mr-auto"
                                src={`https://lebontechnicien.net/${
                                  selectedHistory[photoKeys[selectedImageIndex]]
                                }`}
                                alt={`Photoduprobleme ${
                                  selectedImageIndex + 1
                                }`}
                              />
                            )}
                            {/*<div className="flex ml-auto mr-auto justify-center gap-4">
                                  <button
                                    className="bg-green-600 text-white rounded-md p-4"
                                    onClick={prevImage}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    className={`bg-green-600 text-white rounded-md p-4 ${
                                      selectedImageIndex ===
                                      photoKeys.length - 2
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                    }`}
                                    onClick={nextImage}
                                  >
                                    Next
                                  </button>
                                  </div>*/}
                          </div>
                        </div>
                      )}
                    </div>
                    {selectedHistory.zone !== null &&
                      selectedHistory.zone !== "null" && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Département
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.zone}
                          </dd>
                        </div>
                      )}

                    {selectedHistory.information && (
                      <>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            PLus de Détails
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.information}
                          </dd>
                        </div>

                        {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Message du technicien
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.information}
                        </dd>
                  </div>*/}
                      </>
                    )}

                    {pendingList[0]?.produits &&
                      pendingList[0]?._id === selectedHistory.created_for && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Votre Appareil
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {pendingList[0]?.produits}
                          </dd>
                        </div>
                      )}
                  </dl>
                </div>
              </>

              {/* Détails de client Tech*/}
              <>
                <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                  Détail du Client:
                </h3>
                <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                  <dl>
                    {selectedHistory.nom && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Nom du client
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.nom}
                        </dd>
                      </div>
                    )}
                    {selectedHistory.prenom && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Prenom du client
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.prenom}
                        </dd>
                      </div>
                    )}
                    {selectedHistory.telephone && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Numéro du client
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.telephone}
                        </dd>
                      </div>
                    )}
                    {selectedHistory.adresse && (
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Adresse du client
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.adresse}
                        </dd>
                      </div>
                    )}
                    {selectedHistory.zone !== null &&
                      selectedHistory.zone !== "null" &&
                      selectedHistory.zone && (
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Département
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.zone}
                          </dd>
                        </div>
                      )}
                  </dl>
                </div>
              </>

              {/* Rapport d'intervention: */}
              {selectedHistory.status !== "Pending" &&
                selectedHistory.status !== "Accepted" &&
                selectedHistory.status !==
                  "En attente de date d'intervention" && (
                  <>
                    <>
                      <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                        Rapport d'intervention:
                      </h3>
                      <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                        <dl>
                          {selectedHistory.typeDeProjet &&
                            !selectedHistory.typeDeProjet.includes("PAC") && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Type de problème:
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.problemType}
                                    </dd>
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Description de l'intervention et du problème
                                    :
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.detailedDescription}
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Besoin d'une autre intervention :
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.needAnotherIntervention}
                                  </dd>
                                </div>
                              </>
                            )}

                          {selectedHistory.typeDeProjet.includes("PAC") && (
                            <>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  diametre Liaisons Frigorifiques :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {
                                    selectedHistory.diametreLiaisonsFrigorifiques
                                  }
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  liaisons Frigorifiques :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.liaisonsFrigorifiques}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  flui de Frigorigene :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.fluideFrigorigene}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  alimentation Electrique :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.alimentationElectrique}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  CERFA :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.CERFA}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  hydrauliques :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.hydrauliques}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  hydrauliques Module :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.hydrauliquesModule}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  raccordements Electriques :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.raccordementsElectriques}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  vannes :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.vannes}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Reglage :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.reglageReglage}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  climatisation :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.climatisation}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  appoint :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.appoint}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Pac Climatisation :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.PacClimatisation}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  prescriptions Constructeur :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.prescriptionsConstructeur}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  arret :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.arret}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  observations :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.observations}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Besoin d'une autre intervention :
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.needAnotherIntervention}
                                </dd>
                              </div>
                            </>
                          )}
                          {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Entreprise qui a intervenu :
                        </dt>
                        <dd
                          className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900"
                          onClick={() => {
                            window.location.href = `/product/${selectedHistory.created_for}`;
                          }}
                        >
                          {selectedHistory.typeDeProjet}
                        </dd>
                      </div>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Devis :
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.zone}
                        </dd>
                      </div>*/}
                        </dl>
                      </div>
                    </>
                  </>
                )}

              {messageError > 0 && (
                <div style={{ color: "red" }}>{messageError}</div>
              )}

              <div className="mb-8 w-[92%] ml-auto mr-auto">
                {userType === "Tech" && selectedHistory.status === "claim" ? (
                  <div className="flex justify-end  gap-4">
                    <button
                      //onClick={techCloseRequest}
                      onClick={handletechCClaimeModal}
                      className=" 
                
                inline-flex 
                
                rounded-md 
                border 
                border-transparent 
                bg-orange-400 
                py-2 
                px-4 
                text-sm 
                font-medium 
                text-white 
                shadow-sm hover:bg-orange-600 
                focus:outline-none 
                focus:ring-2 
                focus:ring-indigo-500 
                focus:ring-offset-2
                mt-4
              "
                    >
                      répondre à la réclamation du client
                    </button>
                  </div>
                ) : null}
                {selectedHistory.status === "Paymen_User" ? (
                  <PdfViewer url={`${apiUrl}${selectedHistory.payslip}`} />
                ) : null}
                {userType === "Tech" ? (
                  <>
                    {/* {product.typeDuBien} */}
                    <>
                      {(selectedHistory.status === "Accepted" &&
                        !selectedHistory.typeDeProjet.includes("PAC")) ||
                      (selectedHistory.status === "claim" &&
                        !selectedHistory.typeDeProjet.includes("PAC")) ? (
                        <>
                          <div className="flex justify-end  gap-4">
                            <button
                              onClick={handletechCloseModal}
                              className=" 
                          userProfile-btn 
                          inline-flex 
                          
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                            >
                              Clôturer l'intervention
                            </button>
                            {userType === "Tech" &&
                              selectedHistory.status === "Accepted" && (
                                <button
                                  onClick={sendLocationToBackend}
                                  className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                                >
                                  Client manquant
                                </button>
                              )}
                          </div>
                        </>
                      ) : null}
                    </>
                    <>
                      {(selectedHistory.status === "Accepted" &&
                        selectedHistory.typeDeProjet.includes("PAC")) ||
                      (selectedHistory.status === "claim" &&
                        selectedHistory.typeDeProjet.includes("PAC")) ? (
                        <>
                          <div className="flex justify-end  gap-4">
                            <button
                              onClick={handletechCloseModalPac}
                              className=" 
                          userProfile-btn 
                          inline-flex 
                          
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                            >
                              Clôturer l'intervention
                            </button>
                            {userType === "Tech" &&
                              selectedHistory.status === "Accepted" && (
                                <button
                                  onClick={sendLocationToBackend}
                                  className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                                >
                                  Client manquant
                                </button>
                              )}
                          </div>
                        </>
                      ) : null}
                    </>
                    <>
                      {selectedHistory.status === "Accepted_payment" ? (
                        <>
                          <div className="flex justify-end  gap-4">
                            <button
                              onClick={techCloseRequest}
                              className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                            >
                              Clôturer l'intervention
                            </button>
                          </div>
                        </>
                      ) : null}
                    </>
                  </>
                ) : (
                  <>
                    {selectedHistory.status === "closed_tech" ? (
                      <div className="flex gap-4 justify-end p-10">
                        <button
                          onClick={UserCloseRequest}
                          className=" 
                            userProfile-btn 
                            inline-flex 
                            justify-center 
                            rounded-md 
                            border 
                            border-transparent 
                            bg-indigo-600 
                            py-2 
                            px-4 
                            text-sm 
                            font-medium 
                            text-white 
                            shadow-sm hover:bg-indigo-700 
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-indigo-500 
                            focus:ring-offset-2
                            mt-4
                          "
                        >
                          Confirmer la fin de l'intervention
                        </button>
                        <button
                          onClick={handleUserNotCloseModal}
                          className=" 
                        userProfiledelete-btn
                          inline-flex 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                        >
                          Intervention toujours ouverte
                        </button>
                      </div>
                    ) : selectedHistory.status === "Paymen_User" ? (
                      <div className="flex gap-4 justify-end p-10">
                        <button
                          onClick={handleAccept}
                          className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                        >
                          Accepter le devis
                        </button>
                        <button
                          onClick={handleDeny}
                          className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                        >
                          Refuser le devis
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
                {selectedHistory.status === "Tech_onhold" && (
                  <div className="flex justify-end  gap-4">
                    <button
                      //onClick={techCloseRequest}
                      //Louza
                      onClick={() => setCreationDevisModal(true)}
                      className=" 
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-green-400 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-orange-600 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                    >
                      Envoyer un devis
                    </button>
                  </div>
                )}

                {selectedHistory.status ===
                  "En attente de date d'intervention" && (
                  <div className="flex justify-end  gap-4">
                    <button
                      //onClick={techCloseRequest}
                      //Louza
                      onClick={() => setCreationDateModal(true)}
                      className=" 
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-green-400 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-orange-600 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                    >
                      Valider une date
                    </button>
                  </div>
                )}
              </div>

              {/*{techCloseModal && (
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    {step === 1 && (
                      <>
                        <div
                          className="fixed inset-0 w-full h-full bg-black opacity-40"
                          onClick={handletechCloseModal}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                            <div className="mt-3 sm:flex">
                              <div className="mt-2 text-center ">
                                <h4 className="text-lg text-center font-medium text-gray-800">
                                  Clôturer l'intervention
                                </h4>
                                <div className="w-[98%]">
                                  <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                    Veuillez nous informer si l’intervention est
                                    clôturée ou non. Si l’intervention n’est pas
                                    clôturée choisissez: "Besoin d’un devis" pour
                                    transmettre votre devis au client ou “Besoin
                                    d’une deuxième intervention”.
                                  </p>
                                </div>
                                <div className="flex justify-center gap-2">
                                  <button
                                    onClick={techCloseRequest}
                                    className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                  py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                  >
                                    Clôturer l'intervention
                                  </button>

                                  <button
                                    onClick={handleUnfinishedClick}
                                    className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                  py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                  >
                                    Besoin d’un devis
                                  </button>
                                  <button
                                    onClick={handletechCloseModal}
                                    className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                  >
                                    Annuler
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <div
                          className="fixed inset-0 w-full h-full bg-black opacity-40"
                          onClick={handletechCloseModal}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                            <div className="mt-3 sm:flex">
                              <div className="mt-2 text-center ">
                                <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                  Envoyer un devis
                                </h4>
                                <div className="w-[98%]">
                                  <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                    Si vous connaissez le montant de votre devis
                                    choisissez “Je connais le montant”,Si non
                                    choisissez “Je ne connais pas le montant” pour
                                    uploader un devis en format PDF.
                                  </p>
                                </div>
                                <div className="flex justify-center gap-8">
                                  <button
                                    onClick={handleFinishedClick}
                                    className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                  >
                                    <p>Précédent</p>
                                  </button>
                                  <button
                                    onClick={handleIHaveIdeaClick}
                                    className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                  >
                                    <p className="text-sm">
                                      Je connais le montant
                                    </p>
                                  </button>
                                  <button
                                    onClick={handleDevisClick}
                                    className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                  >
                                    Je ne connais pas le montant
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {step === 3 && (
                      <>
                        <div
                          className="fixed inset-0 w-full h-full bg-black opacity-40"
                          onClick={handletechCloseModal}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                            <div className="mt-3 ">
                              <div className="mt-2 text-center ">
                                <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                  Devis
                                </h4>
                                <div className="w-[98%]">
                                  <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                    Veuillez uploader votre devis en PDF (Poid max
                                    500Ko)
                                  </p>
                                </div>
                                <div className="mb-4">
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                  <h3 className="mb-2 mt-4">Montant du devis:</h3>
                                </div>
                                <input
                                  type="number"
                                  pattern="\d+(,\d{2})?"
                                  inputmode="decimal"
                                  step="0.01"
                                  required
                                  min="0"
                                  value={pay}
                                  onChange={handleCosts}
                                  className="w-[90%] block bg-gray-200 border
    text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                                />
                                <div className="flex justify-center gap-8">
                                  <button
                                    onClick={() => handlePrécédentClick(2)}
                                    className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                  >
                                    <p>Précédent</p>
                                  </button>
                                  <button
                                    onClick={MobileApplication}
                                    disabled={pay ? false : true}
                                    className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                  >
                                    Envoyé
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {step === 4 && (
                      <>
                        <div
                          className="fixed inset-0 w-full h-full bg-black opacity-40"
                          onClick={handletechCloseModal}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                            <div className="mt-3">
                              <div className="mt-2 text-center ">
                                <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                  Montant de devis
                                </h4>
                                <div className="w-[98%]">
                                  <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                    veuillez ajouter les frais pour les envoyer au
                                    client
                                  </p>
                                </div>
                                <input
                                  type="number"
                                  pattern="\d+(,\d{2})?"
                                  inputmode="decimal"
                                  step="0.01"
                                  min="0"
                                  required
                                  value={pay}
                                  onChange={handleCosts}
                                  className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                              text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                                />
                                <div className="flex justify-center gap-8">
                                  <button
                                    onClick={() => handlePrécédentClick(2)}
                                    className=" inline-flex rounded-md border border-transparent bg-gray-300 
                                py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                  >
                                    <p>Précédent</p>
                                  </button>
                                  <button
                                    onClick={MobileApplication}
                                    disabled={pay ? false : true}
                                    className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                  >
                                    Envoyé
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}*/}

              {/* Envoyer un message */}

              {techPro && (
                <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10 p-5">
                  <>
                    <>
                      <div>
                        <div>
                          <legend className="contents text-sm font-medium text-gray-600 mt-4">
                            Envoyer un message
                          </legend>
                        </div>
                        <div>
                          <textarea
                            rows="4"
                            cols="50"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="border border-gray-300 rounded-lg p-2 mt-2 w-full"
                          />
                        </div>
                      </div>
                      <div>
                        <legend className="contents text-sm font-medium text-gray-600 mt-4">
                          Ajoutez de nouveaux documents ou des photos
                          <span className="text-red-500"></span>
                        </legend>
                      </div>
                      <div>
                        <div>
                          {prod_photoDuProduit && (
                            <div className="mt-8 flex flex-between w-full gap-8">
                              <div className="flex items-center flex-wrap w-[80%] gap-4">
                                {Array.from(prod_photoDuProduit).map(
                                  (file, index) => (
                                    <div key={index} className="w-[150px]">
                                      <div className="flex justify-center">
                                        {file.name
                                          .toLowerCase()
                                          .endsWith(".pdf") ? (
                                          <>
                                            <img
                                              src={pdfImage}
                                              alt={`PDF Preview-${index}`}
                                              className="h-16 w-16 object-cover rounded"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              src={URL.createObjectURL(file)}
                                              alt={`Preview-${index}`}
                                              className="h-16 w-16 object-cover rounded"
                                            />
                                          </>
                                        )}
                                        <button
                                          className="bg-gray-100 p-1 rounded-sm text-[10px] mb-10 mr-[-4px] font-bold text-red-500"
                                          onClick={() =>
                                            handleRemovePicture(index)
                                          }
                                        >
                                          X
                                        </button>
                                      </div>
                                      <div className="w-[150px] h-[100px] mt-4 flex justify-center">
                                        <p className="text-sm text-center">
                                          {file.name}
                                        </p>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="flex gap-2">
                                {/*<button
                                      className="mt-[10px] bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded-md text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                      onClick={() => setPhotoDuProduit([])}
                                    >
                                      Change
                                          </button>*/}
                                <div className="mt-4">
                                  <label htmlFor="photoDuProduit" className="">
                                    {/*<IoAddCircleOutline className="h-10 w-10 text-green-500" />*/}
                                    <svg
                                      className="mx-auto h-12 w-12 text-gray-400"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 48 48"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    <input
                                      id="photoDuProduit"
                                      name="photoDuProduit"
                                      type="file"
                                      multiple
                                      className="sr-only"
                                      onChange={handleAddMorePictures}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                          {!prod_photoDuProduit && (
                            <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                              <div className="space-y-1 text-center">
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>

                                <div className="flex text-sm text-gray-600 justify-center">
                                  <label
                                    htmlFor="photoDuProduit"
                                    className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                  >
                                    <span>Ajouter un fichier </span>
                                    <input
                                      id="photoDuProduit"
                                      name="photoDuProduit"
                                      type="file"
                                      multiple
                                      className="sr-only"
                                      value=""
                                      onChange={handlePhotoDuProduit}
                                    />
                                  </label>
                                  <p className="pl-1">
                                    ou faites glisser et déposez
                                  </p>
                                </div>
                                <p className="text-xs text-gray-500">
                                  PNG, JPG, PDF
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex justify-end gap-4 p-10">
                          <button
                            onClick={handleAddPhotos}
                            className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-green-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-green-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                          >
                            Mettre à jour
                          </button>
                        </div>
                      </div>
                    </>
                    <></>
                  </>
                </div>
              )}

              {techCloseModalPac && (
                <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="h-[500px] bg-white w-full max-w-lg p-4 rounded-md shadow-lg">
                    <h2 className="text-lg font-bold mb-4">
                      Points de contrôle:
                    </h2>
                    <div className="h-[400px] overflow-y-auto">
                      <form onSubmit={techCloseRequestModalN1}>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Bon diamètre des liaisons frigorifiques
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="diametreLiaisonsFrigorifiques"
                                value="Oui"
                                checked={
                                  diametreLiaisonsFrigorifiques === "Oui"
                                }
                                onChange={(e) =>
                                  setDiametreLiaisonsFrigorifiques(
                                    e.target.value
                                  )
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="diametreLiaisonsFrigorifiques"
                                value="Non"
                                checked={
                                  diametreLiaisonsFrigorifiques === "Non"
                                }
                                onChange={(e) =>
                                  setDiametreLiaisonsFrigorifiques(
                                    e.target.value
                                  )
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Pas de défaut constaté sur les liaisons
                            frigorifiques (longueur, hauteur...)
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="liaisonsFrigorifiques"
                                value="Oui"
                                checked={liaisonsFrigorifiques === "Oui"}
                                onChange={(e) =>
                                  setLiaisonsFrigorifiques(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="liaisonsFrigorifiques"
                                value="Non"
                                checked={liaisonsFrigorifiques === "Non"}
                                onChange={(e) =>
                                  setLiaisonsFrigorifiques(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Contrôle de l’étanchéité fluide frigorigène suivant
                            règlementation
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="fluideFrigorigene"
                                value="Oui"
                                checked={fluideFrigorigene === "Oui"}
                                onChange={(e) =>
                                  setFluideFrigorigene(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="fluideFrigorigene"
                                value="Non"
                                checked={fluideFrigorigene === "Non"}
                                onChange={(e) =>
                                  setFluideFrigorigene(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Fourniture du CERFA règlementaire
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="CERFA"
                                value="Oui"
                                checked={CERFA === "Oui"}
                                onChange={(e) => setCERFA(e.target.value)}
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="CERFA"
                                value="Non"
                                checked={CERFA === "Non"}
                                onChange={(e) => setCERFA(e.target.value)}
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Sécurités hydrauliques présentes : vase d’expansion,
                            soupape, thermostat PC...
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="hydrauliques"
                                value="Oui"
                                checked={hydrauliques === "Oui"}
                                onChange={(e) =>
                                  setHydrauliques(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="hydrauliques"
                                value="Non"
                                checked={hydrauliques === "Non"}
                                onChange={(e) =>
                                  setHydrauliques(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Raccordement hydraulique module intérieur conforme
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="hydrauliquesModule"
                                value="Oui"
                                checked={hydrauliquesModule === "Oui"}
                                onChange={(e) =>
                                  setHydrauliquesModule(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="hydrauliquesModule"
                                value="Non"
                                checked={hydrauliquesModule === "Non"}
                                onChange={(e) =>
                                  setHydrauliquesModule(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Vérification des raccordements électriques
                            (disjoncteurs, sections de câbles...)
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="raccordementsElectriques"
                                value="Oui"
                                checked={raccordementsElectriques === "Oui"}
                                onChange={(e) =>
                                  setRaccordementsElectriques(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="raccordementsElectriques"
                                value="Non"
                                checked={raccordementsElectriques === "Non"}
                                onChange={(e) =>
                                  setRaccordementsElectriques(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Alimentation électrique correcte (contrôle P/N/T et
                            polarité)
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="alimentationElectrique"
                                value="Oui"
                                checked={alimentationElectrique === "Oui"}
                                onChange={(e) =>
                                  setAlimentationElectrique(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="alimentationElectrique"
                                value="Non"
                                checked={alimentationElectrique === "Non"}
                                onChange={(e) =>
                                  setAlimentationElectrique(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Bon fonctionnement des vannes motorisées
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="vannes"
                                value="Oui"
                                checked={vannes === "Oui"}
                                onChange={(e) => setVannes(e.target.value)}
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="vannes"
                                value="Non"
                                checked={vannes === "Non"}
                                onChange={(e) => setVannes(e.target.value)}
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Vérification et réglage de la réglage
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="reglageReglage"
                                value="Oui"
                                checked={reglageReglage === "Oui"}
                                onChange={(e) =>
                                  setReglageReglage(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="reglageReglage"
                                value="Non"
                                checked={reglageReglage === "Non"}
                                onChange={(e) =>
                                  setReglageReglage(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Bon fonctionnement en mode chauffage et/ou
                            climatisation, sanitaire si présent
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="climatisation"
                                value="Oui"
                                checked={climatisation === "Oui"}
                                onChange={(e) =>
                                  setClimatisation(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="climatisation"
                                value="Non"
                                checked={climatisation === "Non"}
                                onChange={(e) =>
                                  setClimatisation(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Contrôle de l’enclenchement de l’appoint
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="appoint"
                                value="Oui"
                                checked={appoint === "Oui"}
                                onChange={(e) => setAppoint(e.target.value)}
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="appoint"
                                value="Non"
                                checked={appoint === "Non"}
                                onChange={(e) => setAppoint(e.target.value)}
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Explication à l’utilisateur du bon usage de sa
                            PAC/climatisation
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="PacClimatisation"
                                value="Oui"
                                checked={PacClimatisation === "Oui"}
                                onChange={(e) =>
                                  setPacClimatisation(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="PacClimatisation"
                                value="Non"
                                checked={PacClimatisation === "Non"}
                                onChange={(e) =>
                                  setPacClimatisation(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Installation conforme aux prescriptions constructeur
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="prescriptionsConstructeur"
                                value="Oui"
                                checked={prescriptionsConstructeur === "Oui"}
                                onChange={(e) =>
                                  setPrescriptionsConstructeur(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="prescriptionsConstructeur"
                                value="Non"
                                checked={prescriptionsConstructeur === "Non"}
                                onChange={(e) =>
                                  setPrescriptionsConstructeur(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Installation à l’arrêt
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="arret"
                                value="Oui"
                                checked={arret === "Oui"}
                                onChange={(e) => setArret(e.target.value)}
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="arret"
                                value="Non"
                                checked={arret === "Non"}
                                onChange={(e) => setArret(e.target.value)}
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>

                        <>
                          <div>
                            <input
                              type="file"
                              id="picture-input"
                              accept="image/*"
                              multiple
                              onChange={handlePictureUpload}
                              className="hidden"
                            />
                          </div>

                          {pictures.length > 0 && (
                            <div className="mt-4">
                              <h3 className="font-bold">
                                Photos d'intervention:
                              </h3>
                              <div className="flex flex-wrap gap-2">
                                {pictures.map((picture, index) => (
                                  <img
                                    key={index}
                                    src={URL.createObjectURL(picture)}
                                    alt={`Uploaded ${index + 1}`}
                                    className="w-24 h-24 object-cover rounded-md"
                                  />
                                ))}
                              </div>
                            </div>
                          )}

                          <button
                            type="button"
                            className="bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4 mb-4"
                            onClick={() =>
                              document.getElementById("picture-input").click()
                            }
                          >
                            Ajouter des images
                          </button>
                        </>

                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Observations / Modifications à faire:
                          </label>
                          <textarea
                            id="observations"
                            value={observations}
                            onChange={(e) => setObservations(e.target.value)}
                            required
                            className="w-full border p-2"
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block font-bold mb-1">
                            Besoin d'une autre intervention:
                          </label>
                          <div>
                            <label className="mr-4">
                              <input
                                type="radio"
                                name="needAnotherIntervention"
                                value="Oui"
                                checked={needAnotherIntervention === "Oui"}
                                onChange={(e) =>
                                  setNeedAnotherIntervention(e.target.value)
                                }
                                className="mr-2"
                              />
                              Oui
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="needAnotherIntervention"
                                value="Non"
                                checked={needAnotherIntervention === "Non"}
                                onChange={(e) =>
                                  setNeedAnotherIntervention(e.target.value)
                                }
                                className="mr-2"
                              />
                              Non
                            </label>
                          </div>
                        </div>

                        <div className="flex items-start gap-2 px-4 py-8">
                          <button
                            type="submit"
                            className="bg-white text-gray-600 font-bold py-2 px-4 rounded border border-gray-600"
                            onClick={handletechCloseModalPac}
                          >
                            Annuler
                          </button>
                          <button
                            type="submit"
                            className="bg-green-600 text-white font-bold py-2 px-4 rounded"
                          >
                            {needAnotherIntervention === "Oui"
                              ? "Clôture d'intervention"
                              : "Envoyer le rapport"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              {techCloseModal && (
                <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white w-full max-w-lg p-4 rounded-md shadow-lg">
                    <h2 className="text-lg font-bold mb-4">
                      Rapport d'Intervention
                    </h2>
                    <form onSubmit={techCloseRequestModalN1}>
                      <div className="mb-4">
                        <label
                          className="block font-bold mb-1"
                          htmlFor="problemType"
                        >
                          Type de problème:
                        </label>
                        <input
                          type="text"
                          id="problemType"
                          value={problemType}
                          onChange={(e) => setProblemType(e.target.value)}
                          required
                          className="w-full border p-2"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          className="block font-bold mb-1"
                          htmlFor="detailedDescription"
                        >
                          Description détaillée de l'intervention et du
                          problème:
                        </label>
                        <textarea
                          id="detailedDescription"
                          value={detailedDescription}
                          onChange={(e) =>
                            setDetailedDescription(e.target.value)
                          }
                          required
                          className="w-full border p-2"
                        />
                      </div>

                      <>
                        <div>
                          <input
                            type="file"
                            id="picture-input"
                            accept="image/*"
                            multiple
                            onChange={handlePictureUpload}
                            className="hidden"
                          />
                        </div>

                        {pictures.length > 0 && (
                          <div className="mt-4">
                            <h3 className="font-bold">Uploaded Pictures:</h3>
                            <div className="flex flex-wrap gap-2">
                              {pictures.map((picture, index) => (
                                <img
                                  key={index}
                                  src={URL.createObjectURL(picture)}
                                  alt={`Uploaded ${index + 1}`}
                                  className="w-24 h-24 object-cover rounded-md"
                                />
                              ))}
                            </div>
                          </div>
                        )}

                        <button
                          type="button"
                          className="bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4 mb-4"
                          onClick={() =>
                            document.getElementById("picture-input").click()
                          }
                        >
                          Ajouter des images
                        </button>
                      </>

                      <div className="mb-4">
                        <label className="block font-bold mb-1">
                          Besoin d'une autre intervention:
                        </label>
                        <div>
                          <label className="mr-4">
                            <input
                              type="radio"
                              name="needIntervention"
                              value="Oui"
                              checked={needAnotherIntervention === "Oui"}
                              onChange={(e) =>
                                setNeedAnotherIntervention(e.target.value)
                              }
                              className="mr-2"
                            />
                            Oui
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="needIntervention"
                              value="Non"
                              checked={needAnotherIntervention === "Non"}
                              onChange={(e) =>
                                setNeedAnotherIntervention(e.target.value)
                              }
                              className="mr-2"
                            />
                            Non
                          </label>
                        </div>
                      </div>

                      <div className="flex items-start gap-2  px-4 py-8">
                        <button
                          type="submit"
                          className="bg-white text-gray-600 font-bold py-2 px-4 rounded border border-gray-600"
                          onClick={handletechCloseModal}
                        >
                          Annuler
                        </button>
                        <button
                          type="submit"
                          className="bg-green-600 text-white font-bold py-2 px-4 rounded"
                        >
                          {needAnotherIntervention === "Oui"
                            ? "Envoyer le rapport"
                            : "Clôture d'intervention"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {UserNotCloseModal && (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => handleUserNotCloseModal}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Intervention est toujours ouverte
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Merci de préciser pourquoi vous pensez que
                              l'intervention est toujours ouverte.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={claime}
                                    onChange={handleClaime}
                                  />
                                </div>
                              </form>
                            </div>
                            <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                onClick={UserClaimRequest}
                              >
                                Ajouter
                              </button>
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={handleUserNotCloseModal}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {techClaimeModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Selon le client la demande est toujours
                                  ouverte
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={LitigeRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Intervention Cloturé
                                </button>

                                <button
                                  onClick={handleButtonClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Intervention toujour ouverte
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  L'intervention nécessite une autre action
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={TechAccept}
                                  //onClick={TechAccept}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Nouvelle Intervention
                                  </p>
                                </button>
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Devis
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
  text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                            text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {/*{creationDevisModal &&(
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={() => handleUserNotCloseModal}
                      >
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                              <IoAddCircleOutline className="text-green-600" />
                            </div>
                            <div className="mt-2 text-center sm:ml-4 sm:text-left">
                              <h4 className="text-lg font-medium text-gray-800">
                                Création du devis
                              </h4>
                              <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                                Merci de préciser pourquoi vous pensez que
                                l'intervention est toujours ouverte.
                              </p>
                              <div className="modal-content mr-4">
                                <form className="mr-4 mb-4">
                                  <div>
                                    <label>Elément du devis</label>
                                    <div className="mt-1">
                                      <textarea
                                        id="claime"
                                        name="claime"
                                        rows={3}
                                        className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                        defaultValue={""}
                                        value={"claime"}
                                        onChange={"handleClaime"}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <label>Description</label>
                                    <div className="mt-1">
                                      <textarea
                                        id="claime"
                                        name="claime"
                                        rows={3}
                                        className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                        defaultValue={""}
                                        value={"claime"}
                                        onChange={"handleClaime"}
                                        required
                                      />
                                    </div>
                                  </div>   
                                                                 
                                </form>
                              </div>
                              <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                  onClick={UserClaimRequest}
                                >
                                  Ajouter
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={handleUserNotCloseModal}
                                >
                                  Annuler
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>                        
                      </div>
                    </div>
                )}*/}
              {creationDevisModal && (
                <div>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={handleCreationDevisModal}
                    />
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex justify-start">
                          <div className="flex items-center justify-center flex-none w-12 h-12  bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Création du devis
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez remplir toutes les informations pour
                              créer votre devis.
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          {elements.map((element, index) => (
                            <div key={index} className="border p-4 mb-4">
                              <div className="mb-4">
                                <label
                                  className="block font-bold mb-1"
                                  htmlFor={`title-${index}`}
                                >
                                  Titre de l'élément:
                                </label>
                                <input
                                  type="text"
                                  id={`title-${index}`}
                                  value={element.title}
                                  onChange={(e) =>
                                    handleChangeElement(
                                      index,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                  required
                                  className="w-full border p-2"
                                />
                              </div>

                              <div className="mb-4">
                                <label
                                  className="block font-bold mb-1"
                                  htmlFor={`description-${index}`}
                                >
                                  Description:
                                </label>
                                <textarea
                                  id={`description-${index}`}
                                  value={element.description}
                                  onChange={(e) =>
                                    handleChangeElement(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                  className="w-full border p-2"
                                />
                              </div>

                              <div className="mb-4">
                                <label
                                  className="block font-bold mb-1"
                                  htmlFor={`price-${index}`}
                                >
                                  Prix unitaire HT:
                                </label>
                                <input
                                  type="number"
                                  id={`price-${index}`}
                                  value={element.price}
                                  onChange={(e) =>
                                    handleChangeElement(
                                      index,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                  required
                                  className="w-full border p-2"
                                />
                              </div>

                              <div className="mb-4">
                                <label
                                  className="block font-bold mb-1"
                                  htmlFor={`quantity-${index}`}
                                >
                                  Quantité:
                                </label>
                                <input
                                  type="number"
                                  id={`quantity-${index}`}
                                  value={element.quantity}
                                  onChange={(e) =>
                                    handleChangeElement(
                                      index,
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  required
                                  className="w-full border p-2"
                                />
                              </div>
                              {elements.length > 1 && (
                                <button
                                  type="button"
                                  onClick={() => handleRemoveElement(index)}
                                  className="flex items-center justify-center bg-red-500 text-white rounded-full w-8 h-8 focus:outline-none"
                                >
                                  <IoTrash />
                                </button>
                              )}
                            </div>
                          ))}
                          <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                            <button
                              type="button"
                              onClick={handleAddElement}
                              className="bg-blue-500 w-full mt-2 p-2.5 flex-1 text-white rounded-md outline-none"
                            >
                              + Ajouter un élément
                            </button>

                            <button
                              type="submit"
                              onClick={MobileApplication}
                              className=" bg-green-500 w-full mt-2 p-2.5 flex-1 text-white rounded-md outline-none text-md"
                            >
                              Envoyer le devis
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {creationDateModal && (
                <div>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={handleCreationDateModal}
                    />
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex justify-start">
                          <div className="flex items-center justify-center flex-none w-12 h-12  bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Valider une date
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez Valider une date entre{" "}
                              {new Date(
                                selectedHistory?.techStartDate
                              ).toLocaleDateString("fr-FR")}{" "}
                              &{" "}
                              {new Date(
                                selectedHistory?.techEndDate
                              ).toLocaleDateString("fr-FR")}
                              .
                            </p>
                          </div>
                        </div>
                        <div className="mt-2 text-center sm:ml-4 sm:text-left">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            locale={fr}
                            minDate={new Date(selectedHistory?.techStartDate)}
                            //maxDate={new Date(selectedHistory?.techEndDate)}
                            className="w-[90%] ml-auto mr-auto bg-white mb-2 cursor-pointer text-gray-700 font-medium p-2 focus:outline-teal-500 border-2 border-solid py-5 px-5 rounded-lg rounded-md  flex justify-between items-center px-20"
                          />
                          <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                            <button
                              type="submit"
                              onClick={handleClickUpdateDateTech}
                              className=" bg-green-500 w-full mt-2 p-2.5 flex-1 text-white rounded-md outline-none text-md"
                            >
                              Valider
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {/* Admin */}
      {userType === "Admin" && (
        <>
          {selectedHistory && (
            <div className="overflow-hidden bg-transparent shadow sm:rounded-lg max-w-screen-xl  ml-auto mr-auto mt-10 mb-10">
              {product && (
                <>
                  <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto"></div>
                  <div className="flex justify-around mt-6">
                    {product.photoDuProduit &&
                      product.photoDuProduit !== "null" && (
                        <img
                          src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                          alt={product._id}
                          className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                        />
                      )}
                    <img
                      src={`https://lebontechnicien.net/${product.QR}`}
                      alt={product._id}
                      className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                    />
                  </div>

                  {selectedHistory.type === "Demande de Réclamation" && (
                    <>
                      {/* Détail de réclamation */}
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détails de Réclamation:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Date de la demande:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {formattedDate}
                                </dd>
                              </dd>
                            </div>
                            {selectedHistory.PickedDate && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.PickedDate
                                    )
                                      ? selectedHistory.PickedDate
                                      : new Date(
                                          selectedHistory.PickedDate
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}

                            {selectedHistory.date_intervention && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.date_intervention
                                    )
                                      ? selectedHistory.date_intervention
                                      : new Date(
                                          selectedHistory.date_intervention
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Nom du client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.nom}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Prenom du client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.prenom}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Email du client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.email}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Telephone du client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.telephone}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Addresse du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {createdUser.adresse}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Message client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.claime}
                              </dd>
                            </div>
                            {selectedHistory.adminDescriptionMsg && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Message d'admin
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adminDescriptionMsg}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.adminActionMsg && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Actions d'admin
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adminActionMsg}
                                </dd>
                              </div>
                            )}
                            {photoKeys && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Photo du problème
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <div className="flex gap-10">
                                    <div
                                      style={{
                                        overflowX: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {photoKeys.map((photoKey, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "inline-block",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <img
                                            className="w-[80px] h-[80px] cursor-pointer"
                                            src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                            alt={`Photoduprobleme ${index + 1}`}
                                            onClick={() =>
                                              openModal(
                                                `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                              )
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Statut:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {(() => {
                                  switch (selectedHistory.status) {
                                    case "Pending":
                                      return "En cours";
                                    case "closed":
                                      return "Cloturé";
                                    case "closed_tech":
                                      return "Cloturé par technicien";
                                    case "claim":
                                      return "Réclamation";
                                    case "Denied_payment":
                                      return "Devis refusé";
                                    case "Accepted_payment":
                                      return "Devis accepté";
                                    case "Paymen_User":
                                      return "Devis";
                                    case "Accepted":
                                      return "Accepté";
                                    case "claim_fix":
                                      return "Résolu";
                                    case "Email Pending":
                                      return "Email non validé";
                                    case "Confirmed":
                                      return "validé";
                                    case "In progress":
                                      return "En cours de vérification";
                                    case "Denied":
                                      return "Réfusé";
                                    case "solved":
                                      return "Résolu";
                                    case "unsolved":
                                      return "Non résolu";
                                    case "Full":
                                      return "Complète";
                                    case "litige_fixed":
                                      return "Litige résolu";
                                    case "claime_fixed":
                                      return "Réclamation résolu";
                                    case "annulled":
                                      return "Annulée";
                                    case "admin_accept":
                                      return "Contrat en attente de signature";
                                    case "Tech_onhold":
                                      return "En attente";

                                    default:
                                      return selectedHistory.status;
                                  }
                                })()}
                              </dd>
                            </div>

                            {selectedHistory.information && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    PLus de Détails
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.information}
                                  </dd>
                                </div>
                              </>
                            )}
                            {userType === "User" && (
                              <>
                                {nomtec && prenomtec && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Nom & Prenom Tech
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {nomtec} {prenomtec}
                                    </dd>
                                  </div>
                                )}
                                {reviewTec && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Avis Clients
                                    </dt>
                                  </div>
                                )}
                                {userType === "User" &&
                                  reviewTec?.length > 0 && (
                                    <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {reviewTec.map((review, index) => (
                                        <div
                                          key={index}
                                          className="w-full mb-4 bg-white p-4 rounded-md"
                                        >
                                          <div className="flex gap-2 mb-2">
                                            <p>
                                              {review.userName}{" "}
                                              {review.userLastName}
                                            </p>
                                            <Rating rating={review.rating} />
                                          </div>
                                          <p>{review.comment}</p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </>
                            )}

                            {pendingList[0]?.produits &&
                              pendingList[0]?._id ===
                                selectedHistory.created_for && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Votre Appareil
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {pendingList[0]?.produits}
                                  </dd>
                                </div>
                              )}
                            {userType === "User" &&
                            selectedHistory.status === "Pending" &&
                            selectedHistory.accepted_by.length === 0 &&
                            selectedHistory.denied_by.length === 0 ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    onClick={HandleAnnulation}
                                    //onClick={handletechCloseModal}
                                    className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                  >
                                    Annuler l’intervention
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {userType === "User" &&
                            selectedHistory.status === "Pending" &&
                            (selectedHistory.accepted_by.length > 0 ||
                              selectedHistory.denied_by.length > 0) ? (
                              <>
                                <div className="flex gap-4 justify-end p-10">
                                  <button
                                    onClick={executeSequentially}
                                    //onClick={handletechCloseModal}
                                    className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-green-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-green-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                                  >
                                    Accpeter
                                  </button>
                                  <button
                                    onClick={handleDenyUser}
                                    //onClick={handletechCloseModal}
                                    className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-red-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-red-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                                  >
                                    Refuser
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {userType === "Tech" &&
                            selectedHistory.status === "claim" ? (
                              <div className="flex justify-end  gap-4">
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={handletechCClaimeModal}
                                  className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                                >
                                  répondre à la réclamation du client
                                </button>
                              </div>
                            ) : null}

                            {userType === "User" &&
                            (selectedHistory.status === "closed" ||
                              selectedHistory.status === "Full") ? (
                              <>
                                <RatingSystem
                                  userName={nom}
                                  userLastName={prenom}
                                  TechnicienID={selectedHistory.client_accept}
                                />
                              </>
                            ) : null}
                            {selectedHistory.status === "Paymen_User" ? (
                              <PdfViewer
                                url={`${apiUrl}${selectedHistory.payslip}`}
                              />
                            ) : null}
                            {userType === "Tech" ? (
                              <>
                                <>
                                  {selectedHistory.status === "Accepted" ||
                                  selectedHistory.status === "claim" ? (
                                    <>
                                      <div className="flex justify-end  gap-4">
                                        <button
                                          //onClick={techCloseRequest}
                                          onClick={handletechCloseModal}
                                          className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                        >
                                          Clôturer l'intervention
                                        </button>
                                        {userType === "Tech" &&
                                          selectedHistory.status ===
                                            "Accepted" && (
                                            <button
                                              onClick={sendLocationToBackend}
                                              className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                            >
                                              Client manquant
                                            </button>
                                          )}
                                      </div>
                                    </>
                                  ) : null}
                                </>
                                <>
                                  {selectedHistory.status ===
                                  "Accepted_payment" ? (
                                    <>
                                      <div className="flex justify-end  gap-4">
                                        <button
                                          onClick={techCloseRequest}
                                          //onClick={handletechCloseModal}
                                          className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                        >
                                          Clôturer l'intervention
                                        </button>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              </>
                            ) : (
                              <>
                                {selectedHistory.status === "closed_tech" ? (
                                  <div className="flex gap-4 justify-end p-10">
                                    <button
                                      onClick={UserCloseRequest}
                                      className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                                    >
                                      Confirmer la fin de l'intervention
                                    </button>
                                    <button
                                      onClick={handleUserNotCloseModal}
                                      className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                    >
                                      Intervention toujours ouverte
                                    </button>
                                  </div>
                                ) : selectedHistory.status === "Paymen_User" ? (
                                  <div className="flex gap-4 justify-end p-10">
                                    <button
                                      onClick={handleAccept}
                                      className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                    >
                                      Accepter le devis
                                    </button>
                                    <button
                                      onClick={handleDeny}
                                      className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                    >
                                      Refuser le devis
                                    </button>
                                  </div>
                                ) : null}
                              </>
                            )}
                          </dl>
                        </div>
                      </>
                      {/* Détails de l'appareil */}
                      <>
                        <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                          Détails de l'appareil:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Type d'appareil
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.typeDuBien}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Mois et année d'installation:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.moisInstallation} /{" "}
                                {product.annéeInstallation
                                  ? product.annéeInstallation
                                  : product.anneeInstallation}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Marque:
                              </dt>

                              <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.marque === "autre" ||
                                selectedHistory.marque === "autre" ? (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.autreMarque
                                      ? product.autreMarque
                                      : selectedHistory.autreMarque}
                                  </dd>
                                ) : (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.marque
                                      ? product.marque
                                      : selectedHistory.marque}
                                  </dd>
                                )}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Référence
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.reference
                                  ? product.reference
                                  : product.référence}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Maintenu
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.entretenu}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Puissance
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.puissance}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </>
                      {/* Détails d'intervention */}
                      <>
                        <>
                          <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                            Détails d'intervention:
                          </h3>
                          <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                            <dl>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Description de la panne
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.probleme}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Type de préstation:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.typeDePrestation}
                                  </dd>
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Appareil installé:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.typeDuBien}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Département:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.departementRequest}
                                </dd>
                              </div>
                              {photoKeys && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Photo du problème
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="flex gap-10">
                                      <div
                                        style={{
                                          overflowX: "auto",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {photoKeys.map((photoKey, index) => (
                                          <div
                                            key={index}
                                            style={{
                                              display: "inline-block",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <img
                                              className="w-[80px] h-[80px] cursor-pointer"
                                              src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                              alt={`Photoduprobleme ${
                                                index + 1
                                              }`}
                                              onClick={() =>
                                                openModal(
                                                  `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                                )
                                              }
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </dd>
                                </div>
                              )}
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Statut:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {(() => {
                                    switch (selectedHistory.statutRequest) {
                                      case "Pending":
                                        return "En cours";
                                      case "closed":
                                        return "Cloturé";
                                      case "closed_tech":
                                        return "Cloturé par technicien";
                                      case "claim":
                                        return "Réclamation";
                                      case "Denied_payment":
                                        return "Devis refusé";
                                      case "Accepted_payment":
                                        return "Devis accepté";
                                      case "Paymen_User":
                                        return "Devis";
                                      case "Accepted":
                                        return "Accepté";
                                      case "claim_fix":
                                        return "Résolu";
                                      case "Email Pending":
                                        return "Email non validé";
                                      case "Confirmed":
                                        return "validé";
                                      case "In progress":
                                        return "En cours de vérification";
                                      case "Denied":
                                        return "Réfusé";
                                      case "solved":
                                        return "Résolu";
                                      case "unsolved":
                                        return "Non résolu";
                                      case "Full":
                                        return "Complète";
                                      case "litige_fixed":
                                        return "Litige résolu";
                                      case "claime_fixed":
                                        return "Réclamation résolu";
                                      case "annulled":
                                        return "Annulée";
                                      case "admin_accept":
                                        return "Contrat en attente de signature";
                                      case "Tech_onhold":
                                        return "En attente";

                                      default:
                                        return selectedHistory.status;
                                    }
                                  })()}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </>
                        {/* Détails de Technicien */}
                        <>
                          {claimUser && claimUser.msg !== "No such User" && (
                            <>
                              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                Détails de Technicien:
                              </h3>
                              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                <dl>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Intervenant:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {claimUser.nom} {claimUser.prenom}
                                      </dd>
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Raison Sociale:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {claimUser.raisonSocial}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Addresse du Technicien:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {claimUser.adresse} {""}
                                        {claimUser.postal}
                                      </dd>
                                    </dd>
                                  </div>

                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Email du Technicien:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {claimUser.email}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Telephone du Technicien:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {claimUser.telephone}
                                      </dd>
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Domaine d'expertise:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {claimUser.domaine}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      SIRET:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {claimUser.siret}
                                      </dd>
                                    </dd>
                                  </div>
                                </dl>
                              </div>
                            </>
                          )}
                        </>
                        {/* Buttons */}
                        <>
                          {(userType === "Admin" ||
                            userType === "Superviseur") &&
                          selectedHistory.status === "litige" ? (
                            <div className="flex justify-end  gap-4 p-8">
                              <button
                                //onClick={techCloseRequest}
                                onClick={ResoudreLitige}
                                className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                              >
                                résoudre ce litige
                              </button>
                            </div>
                          ) : null}
                          {(userType === "Admin" ||
                            userType === "Superviseur") &&
                          selectedHistory.type === "Demande de Réclamation" &&
                          selectedHistory.status !== "claim_fix" ? (
                            <div className="flex justify-end  gap-4 p-8">
                              <button
                                onClick={handleModalToggle}
                                className="inline-flex         
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-orange-400 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-orange-600 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
            "
                              >
                                résoudre cette réclamation
                              </button>
                            </div>
                          ) : null}
                        </>
                      </>
                    </>
                  )}
                  {/* Détails de litige */}
                  {(selectedHistory.status === "litige" ||
                    selectedHistory.status === "litige_fixed") && (
                    <>
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détails du litige:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Nom du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.NomUtilisateur}
                                </dd>
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Prénom du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.PrenomUtilisateur}
                                </dd>
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Email du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.clientemail}
                              </dd>
                            </div>
                            {user && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Telephone du client:
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {user.telephone}
                                    </dd>
                                  </dd>
                                </div>
                              </>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Message du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.msg_claime}
                                </dd>
                              </dd>
                            </div>
                            {selectedHistory.adminDescriptionMsg && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Message d'admin:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adminDescriptionMsg}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.adminActionMsg && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Actions d'admin:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adminActionMsg}
                                </dd>
                              </div>
                            )}
                          </dl>
                        </div>
                      </>
                    </>
                  )}

                  {selectedHistory.type === "Demande d'intervention" && (
                    <>
                      {/*detail de l'Appareil */}
                      <>
                        <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                          Détails de l'appareil:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Type d'appareil
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.typeDuBien
                                  ? product.typeDuBien
                                  : selectedHistory.typeDeProjet}
                              </dd>
                            </div>
                            {(product.moisInstallation !== "" ||
                              selectedHistory.moisInstallation !== "" ||
                              selectedHistory.dateInstallation !== "" ||
                              selectedHistory.moisInstallation !== " " ||
                              selectedHistory.dateInstallation !== " " ||
                              selectedHistory.moisInstallation !== null ||
                              selectedHistory.dateInstallation !== null ||
                              !selectedHistory.moisInstallation ||
                              !selectedHistory.dateInstallation) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Mois et année d'installation:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.moisInstallation &&
                                  (product.annéeInstallation ||
                                    product.anneeInstallation)
                                    ? `${product.moisInstallation} / ${
                                        product.annéeInstallation
                                          ? product.annéeInstallation
                                          : product.anneeInstallation
                                      }`
                                    : `${selectedHistory.moisInstallation} / ${selectedHistory.dateInstallation}`}
                                </dd>
                              </div>
                            )}
                            {(product.marque || selectedHistory.marque) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Marque:
                                </dt>
                                {product.marque === "autre" ||
                                selectedHistory.marque === "autre" ? (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.autreMarque
                                      ? product.autreMarque
                                      : selectedHistory.autreMarque}
                                  </dd>
                                ) : (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.marque
                                      ? product.marque
                                      : selectedHistory.marque}
                                  </dd>
                                )}
                              </div>
                            )}
                            {(product.reference ||
                              selectedHistory.référence ||
                              selectedHistory.reference) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Référence
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.reference ||
                                    selectedHistory.référence ||
                                    selectedHistory.reference}
                                </dd>
                              </div>
                            )}
                            {(product.puissance ||
                              selectedHistory.puissance) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Puissance
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.puissance
                                    ? product.puissance
                                    : selectedHistory.puissance}
                                </dd>
                              </div>
                            )}
                            {(product.entretenu ||
                              selectedHistory.entretenu) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Maintenu
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.entretenu
                                    ? product.entretenu
                                    : selectedHistory.entretenu}
                                </dd>
                              </div>
                            )}
                          </dl>
                        </div>
                      </>
                      {/* Détails de l'intervention: */}
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détails de l'intervention:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            {selectedHistory.status && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Statut:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {(() => {
                                    switch (selectedHistory.status) {
                                      case "Pending":
                                        return "En cours";
                                      case "closed":
                                        return "Cloturé";
                                      case "closed_tech":
                                        return "Cloturé par technicien";
                                      case "claim":
                                        return "Réclamation";
                                      case "Denied_payment":
                                        return "Devis refusé";
                                      case "Accepted_payment":
                                        return "Devis accepté";
                                      case "Paymen_User":
                                        return "Devis";
                                      case "Accepted":
                                        return "Accepté";
                                      case "claim_fix":
                                        return "Résolu";
                                      case "Email Pending":
                                        return "Email non validé";
                                      case "Confirmed":
                                        return "validé";
                                      case "In progress":
                                        return "En cours de vérification";
                                      case "Denied":
                                        return "Réfusé";
                                      case "solved":
                                        return "Résolu";
                                      case "unsolved":
                                        return "Non résolu";
                                      case "Full":
                                        return "Complète";
                                      case "litige_fixed":
                                        return "Litige résolu";
                                      case "claime_fixed":
                                        return "Réclamation résolu";
                                      case "annulled":
                                        return "Annulée";
                                      case "admin_accept":
                                        return "Contrat en attente de signature";
                                      case "Tech_onhold":
                                        return "En attente";
                                      default:
                                        return selectedHistory.status;
                                    }
                                  })()}
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Date de la demande:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {formattedDate}
                                </dd>
                              </dd>
                            </div>
                            {selectedHistory.PickedDate && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.PickedDate
                                    )
                                      ? selectedHistory.PickedDate
                                      : new Date(
                                          selectedHistory.PickedDate
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}

                            {selectedHistory.date_intervention && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.date_intervention
                                    )
                                      ? selectedHistory.date_intervention
                                      : new Date(
                                          selectedHistory.date_intervention
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}
                            {selectedHistory.typeDePrestation && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Type de préstation
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.typeDePrestation}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.probleme && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Description de la panne
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.probleme}
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Photos & Dcouments
                              </dt>
                              <dd
                                className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                                style={{
                                  overflowX: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div
                                  className="flex overflow-x-auto whitespace-nowrap"
                                  style={{ minWidth: "100%" }}
                                >
                                  {photoKeys.slice(1).map((photoKey, index) => (
                                    <div
                                      key={index}
                                      className="inline-block mr-10"
                                    >
                                      {selectedHistory[photoKey].endsWith(
                                        ".pdf"
                                      ) ? (
                                        <a
                                          href={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            src={pdfImage} // PDF icon image link
                                            alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                            className="w-[100px] h-[100px]"
                                          />
                                        </a>
                                      ) : (
                                        <img
                                          className="w-[100px] h-[100px] cursor-pointer"
                                          src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                          alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                          onClick={() =>
                                            openModalImages(index + 1)
                                          } // Add 1 to the index to skip the first file
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </dd>
                              {modalOpen && (
                                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                                  <div className="bg-black opacity-50 w-full h-full absolute"></div>
                                  <div className="bg-white p-8 rounded-lg relative z-10 w-[90%] ml-auto mr-auto">
                                    <span
                                      className="close flex justify-end cursor"
                                      onClick={closeModalImages}
                                    >
                                      &times;
                                    </span>
                                    {photoKeys[selectedImageIndex].endsWith(
                                      ".pdf"
                                    ) ? (
                                      <button
                                        onClick={() => {
                                          console.log(
                                            photoKeys[selectedImageIndex]
                                          );
                                          downloadFile(
                                            `${apiUrl}${
                                              selectedHistory[
                                                photoKeys[selectedImageIndex]
                                              ]
                                            }`
                                          );
                                        }}
                                      >
                                        Download PDF
                                      </button>
                                    ) : (
                                      <img
                                        className="w-[40%] flex justify-center ml-auto mr-auto"
                                        src={`https://lebontechnicien.net/${
                                          selectedHistory[
                                            photoKeys[selectedImageIndex]
                                          ]
                                        }`}
                                        alt={`Photoduprobleme ${
                                          selectedImageIndex + 1
                                        }`}
                                      />
                                    )}
                                    {/*<div className="flex ml-auto mr-auto justify-center gap-4">
                                  <button
                                    className="bg-green-600 text-white rounded-md p-4"
                                    onClick={prevImage}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    className={`bg-green-600 text-white rounded-md p-4 ${
                                      selectedImageIndex ===
                                      photoKeys.length - 2
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                    }`}
                                    onClick={nextImage}
                                  >
                                    Next
                                  </button>
                                  </div>*/}
                                  </div>
                                </div>
                              )}
                            </div>
                            {selectedHistory.adresse !== null &&
                              selectedHistory.adresse !== "null" &&
                              selectedHistory.adresse && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Adresse de l'intervention
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.adresse}
                                  </dd>
                                </div>
                              )}

                            {selectedHistory.information && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    PLus de Détails
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.information}
                                  </dd>
                                </div>

                                {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Message du technicien
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.information}
                        </dd>
                  </div>*/}
                              </>
                            )}

                            {pendingList[0]?.produits &&
                              pendingList[0]?._id ===
                                selectedHistory.created_for && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Votre Appareil
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {pendingList[0]?.produits}
                                  </dd>
                                </div>
                              )}
                          </dl>
                        </div>
                      </>

                      {/* Détails de client Tech*/}
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détail du Client:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            {selectedHistory.nom && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Nom du client
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.nom}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.prenom && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Prenom du client
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.prenom}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.postale !== null &&
                              selectedHistory.telephone !== "null" &&
                              selectedHistory.telephone && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Numéro du client
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.telephone}
                                  </dd>
                                </div>
                              )}
                            {selectedHistory.adresse !== null &&
                              selectedHistory.adresse !== "null" &&
                              selectedHistory.adresse && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Adresse du client
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.adresse}
                                  </dd>
                                </div>
                              )}
                            {selectedHistory.postale !== null &&
                              selectedHistory.postale !== "null" &&
                              selectedHistory.postale && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Code Postal
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.postale}
                                  </dd>
                                </div>
                              )}
                          </dl>
                        </div>
                      </>

                      {/* Détails de Technicien client pro*/}
                      <>
                        {selectedHistory.status !== "Pending" &&
                          selectedHistory.status !== "annulled" && (
                            <>
                              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                Détail du Technicien:
                              </h3>
                              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                <dl>
                                  {selectedHistory.raisonSociale && (
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Raison sociale
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.raisonSociale}
                                      </dd>
                                    </div>
                                  )}
                                  {selectedHistory.Technicien && (
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Nom
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.Technicien}
                                      </dd>
                                    </div>
                                  )}
                                  {selectedHistory.Technicienprenom && (
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Prénom
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.Technicienprenom}
                                      </dd>
                                    </div>
                                  )}
                                </dl>
                              </div>
                            </>
                          )}
                        {selectedHistory.status !== "Pending" &&
                          selectedHistory.status !== "Accepted" &&
                          selectedHistory.status !==
                            "En attente de date d'intervention" &&
                          selectedHistory.status !== "annulled" && (
                            <>
                              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                Rapport d'intervention:
                              </h3>
                              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                <dl>
                                  {selectedHistory.typeDeProjet &&
                                    !selectedHistory.typeDeProjet.includes(
                                      "PAC"
                                    ) && (
                                      <>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Type de problème:
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                              {selectedHistory.problemType}
                                            </dd>
                                          </dd>
                                        </div>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Description de l'intervention et du
                                            problème :
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            {
                                              selectedHistory.detailedDescription
                                            }
                                          </dd>
                                        </div>
                                      </>
                                    )}

                                  {selectedHistory.typeDeProjet.includes(
                                    "PAC"
                                  ) && (
                                    <>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Diametre Liaisons Frigorifiques
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.diametreLiaisonsFrigorifiques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          liaisons Frigorifiques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.liaisonsFrigorifiques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          flui de Frigorigene :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.fluideFrigorigene}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          alimentation Electrique :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.alimentationElectrique
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          CERFA :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.CERFA}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          hydrauliques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.hydrauliques}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          hydrauliques Module :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.hydrauliquesModule}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          raccordements Electriques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.raccordementsElectriques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          vannes :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.vannes}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Reglage :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.reglageReglage}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          climatisation :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.climatisation}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          appoint :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.appoint}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Pac Climatisation :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.PacClimatisation}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          prescriptions Constructeur :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.prescriptionsConstructeur
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          arret :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.arret}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          observations :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.observations}
                                        </dd>
                                      </div>
                                    </>
                                  )}
                                  {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Entreprise qui a intervenu :
                              </dt>
                              <dd
                                className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900"
                                onClick={() => {
                                  window.location.href = `/product/${selectedHistory.created_for}`;
                                }}
                              >
                                {selectedHistory.typeDeProjet}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Devis :
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.zone}
                              </dd>
                            </div>*/}
                                </dl>
                              </div>
                            </>
                          )}
                      </>

                      {/* Rapport d'intervention: */}
                      {selectedHistory.status !== "Pending" &&
                        selectedHistory.status !== "Accepted" &&
                        selectedHistory.status !==
                          "En attente de date d'intervention" && (
                          <>
                            <>
                              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                Rapport d'intervention:
                              </h3>
                              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                <dl>
                                  {selectedHistory.typeDeProjet &&
                                    !selectedHistory.typeDeProjet.includes(
                                      "PAC"
                                    ) && (
                                      <>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Type de problème:
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                              {selectedHistory.problemType}
                                            </dd>
                                          </dd>
                                        </div>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Description de l'intervention et du
                                            problème :
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            {
                                              selectedHistory.detailedDescription
                                            }
                                          </dd>
                                        </div>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Besoin d'une autre intervention :
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            {
                                              selectedHistory.needAnotherIntervention
                                            }
                                          </dd>
                                        </div>
                                      </>
                                    )}

                                  {selectedHistory.typeDeProjet.includes(
                                    "PAC"
                                  ) && (
                                    <>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          diametre Liaisons Frigorifiques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.diametreLiaisonsFrigorifiques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          liaisons Frigorifiques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.liaisonsFrigorifiques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          flui de Frigorigene :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.fluideFrigorigene}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          alimentation Electrique :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.alimentationElectrique
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          CERFA :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.CERFA}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          hydrauliques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.hydrauliques}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          hydrauliques Module :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.hydrauliquesModule}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          raccordements Electriques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.raccordementsElectriques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          vannes :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.vannes}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Reglage :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.reglageReglage}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          climatisation :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.climatisation}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          appoint :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.appoint}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Pac Climatisation :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.PacClimatisation}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          prescriptions Constructeur :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.prescriptionsConstructeur
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          arret :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.arret}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          observations :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.observations}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Besoin d'une autre intervention :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.needAnotherIntervention
                                          }
                                        </dd>
                                      </div>
                                    </>
                                  )}
                                  {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Entreprise qui a intervenu :
                        </dt>
                        <dd
                          className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900"
                          onClick={() => {
                            window.location.href = `/product/${selectedHistory.created_for}`;
                          }}
                        >
                          {selectedHistory.typeDeProjet}
                        </dd>
                      </div>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                          Devis :
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {selectedHistory.zone}
                        </dd>
                      </div>*/}
                                </dl>
                              </div>
                            </>
                          </>
                        )}
                    </>
                  )}

                  {selectedHistory.type === "Demande de Maintenance" && (
                    <>
                      {/*detail de l'Appareil */}
                      <>
                        <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                          Détails de l'appareil:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Type d'appareil
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.typeDuBien}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Mois et année d'installation:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.moisInstallation} /{" "}
                                {product.annéeInstallation
                                  ? product.annéeInstallation
                                  : product.anneeInstallation}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Marque:
                              </dt>

                              <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.marque === "autre" ||
                                selectedHistory.marque === "autre" ? (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.autreMarque
                                      ? product.autreMarque
                                      : selectedHistory.autreMarque}
                                  </dd>
                                ) : (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.marque
                                      ? product.marque
                                      : selectedHistory.marque}
                                  </dd>
                                )}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Référence
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.reference
                                  ? product.reference
                                  : product.référence}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Maintenu
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.entretenu}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Puissance
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.puissance}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </>
                      {/* detail de maintenance */}
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détails de contrat de maintenance:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Date de la demande:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {formattedDate}
                                </dd>
                              </dd>
                            </div>
                            {selectedHistory.PickedDate && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.PickedDate
                                    )
                                      ? selectedHistory.PickedDate
                                      : new Date(
                                          selectedHistory.PickedDate
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}
                            {photoKeys && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Photo du problème
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <div className="flex gap-10">
                                    <div
                                      style={{
                                        overflowX: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {photoKeys.map((photoKey, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "inline-block",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <img
                                            className="w-[80px] h-[80px] cursor-pointer"
                                            src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                            alt={`Photoduprobleme ${index + 1}`}
                                            onClick={() =>
                                              openModal(
                                                `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                              )
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Statut:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {(() => {
                                  switch (selectedHistory.status) {
                                    case "Pending":
                                      return "En cours";
                                    case "closed":
                                      return "Cloturé";
                                    case "closed_tech":
                                      return "Cloturé par technicien";
                                    case "claim":
                                      return "Réclamation";
                                    case "Denied_payment":
                                      return "Devis refusé";
                                    case "Accepted_payment":
                                      return "Devis accepté";
                                    case "Paymen_User":
                                      return "Devis";
                                    case "Accepted":
                                      return "Accepté";
                                    case "claim_fix":
                                      return "Résolu";
                                    case "Email Pending":
                                      return "Email non validé";
                                    case "Confirmed":
                                      return "validé";
                                    case "In progress":
                                      return "En cours de vérification";
                                    case "Denied":
                                      return "Réfusé";
                                    case "solved":
                                      return "Résolu";
                                    case "unsolved":
                                      return "Non résolu";
                                    case "Full":
                                      return "Complète";
                                    case "litige_fixed":
                                      return "Litige résolu";
                                    case "claime_fixed":
                                      return "Réclamation résolu";
                                    case "annulled":
                                      return "Annulée";
                                    case "admin_accept":
                                      return "Contrat en attente de signature";
                                    case "Tech_onhold":
                                      return "En attente";
                                    default:
                                      return selectedHistory.status;
                                  }
                                })()}
                              </dd>
                            </div>
                            {selectedHistory.information && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    PLus de Détails
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.information}
                                  </dd>
                                </div>

                                {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Message du technicien
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.information}
                      </dd>
                </div>*/}
                              </>
                            )}
                            {userType === "User" && (
                              <>
                                {nomtec && prenomtec && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Nom & Prenom Tech
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {nomtec} {prenomtec}
                                    </dd>
                                  </div>
                                )}
                                {reviewTec && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Avis Clients
                                    </dt>
                                  </div>
                                )}
                                {userType === "User" &&
                                  reviewTec?.length > 0 && (
                                    <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {reviewTec.map((review, index) => (
                                        <div
                                          key={index}
                                          className="w-full mb-4 bg-white p-4 rounded-md"
                                        >
                                          <div className="flex gap-2 mb-2">
                                            <p>
                                              {review.userName}{" "}
                                              {review.userLastName}
                                            </p>
                                            <Rating rating={review.rating} />
                                          </div>
                                          <p>{review.comment}</p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </>
                            )}

                            {pendingList[0]?.produits &&
                              pendingList[0]?._id ===
                                selectedHistory.created_for && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Votre Appareil
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {pendingList[0]?.produits}
                                  </dd>
                                </div>
                              )}
                            {userType === "User" &&
                            selectedHistory.status === "Pending" &&
                            selectedHistory.accepted_by.length === 0 &&
                            selectedHistory.denied_by.length === 0 ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    onClick={HandleAnnulation}
                                    //onClick={handletechCloseModal}
                                    className=" 
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-orange-400 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-orange-600 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                  >
                                    Annuler l’intervention
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {userType === "User" &&
                            selectedHistory.status === "Pending" &&
                            (selectedHistory.accepted_by.length > 0 ||
                              selectedHistory.denied_by.length > 0) ? (
                              <>
                                <div className="flex gap-4 justify-end p-10">
                                  <button
                                    onClick={executeSequentially}
                                    //onClick={handletechCloseModal}
                                    className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-green-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-green-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                                  >
                                    Accpeter
                                  </button>
                                  <button
                                    onClick={handleDenyUser}
                                    //onClick={handletechCloseModal}
                                    className=" 
                                      inline-flex 
                                      rounded-md 
                                      border 
                                      border-transparent 
                                      bg-red-600 
                                      py-2 
                                      px-4 
                                      text-sm 
                                      font-medium 
                                      text-white 
                                      shadow-sm hover:bg-red-700 
                                      focus:outline-none 
                                      focus:ring-2 
                                      focus:ring-indigo-500 
                                      focus:ring-offset-2
                                      mt-4
                                    "
                                  >
                                    Refuser
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {userType === "Tech" &&
                            selectedHistory.status === "claim" ? (
                              <div className="flex justify-end  gap-4">
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={handletechCClaimeModal}
                                  className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                                >
                                  répondre à la réclamation du client
                                </button>
                              </div>
                            ) : null}
                            {(userType === "Admin" ||
                              userType === "Superviseur") &&
                            selectedHistory.status === "litige" ? (
                              <div className="flex justify-end  gap-4">
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={ResoudreLitige}
                                  className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                                >
                                  résoudre ce litige
                                </button>
                              </div>
                            ) : null}
                            {(userType === "Admin" ||
                              userType === "Superviseur") &&
                            selectedHistory.type ===
                              "Demande de Réclamation" ? (
                              <div className="flex justify-end  gap-4">
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={ResoudreReclamation}
                                  className=" 
              
              inline-flex 
              
              rounded-md 
              border 
              border-transparent 
              bg-orange-400 
              py-2 
              px-4 
              text-sm 
              font-medium 
              text-white 
              shadow-sm hover:bg-orange-600 
              focus:outline-none 
              focus:ring-2 
              focus:ring-indigo-500 
              focus:ring-offset-2
              mt-4
            "
                                >
                                  résoudre cette réclamation
                                </button>
                              </div>
                            ) : null}
                            {userType === "User" &&
                            (selectedHistory.status === "closed" ||
                              selectedHistory.status === "Full") ? (
                              <>
                                <RatingSystem
                                  userName={nom}
                                  userLastName={prenom}
                                  TechnicienID={selectedHistory.client_accept}
                                />
                              </>
                            ) : null}
                            {selectedHistory.status === "Paymen_User" ? (
                              <PdfViewer
                                url={`${apiUrl}${selectedHistory.payslip}`}
                              />
                            ) : null}
                            {userType === "Tech" ? (
                              <>
                                <>
                                  {selectedHistory.status === "Accepted" ||
                                  selectedHistory.status === "claim" ? (
                                    <>
                                      <div className="flex justify-end  gap-4">
                                        <button
                                          //onClick={techCloseRequest}
                                          onClick={handletechCloseModal}
                                          className=" 
                        userProfile-btn 
                        inline-flex 
                        
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                        >
                                          Clôturer l'intervention
                                        </button>
                                        {userType === "Tech" &&
                                          selectedHistory.status ===
                                            "Accepted" && (
                                            <button
                                              onClick={sendLocationToBackend}
                                              className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                            >
                                              Client manquant
                                            </button>
                                          )}
                                      </div>
                                    </>
                                  ) : null}
                                </>
                                <>
                                  {selectedHistory.status ===
                                  "Accepted_payment" ? (
                                    <>
                                      <div className="flex justify-end  gap-4">
                                        <button
                                          onClick={techCloseRequest}
                                          //onClick={handletechCloseModal}
                                          className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                        >
                                          Clôturer l'intervention
                                        </button>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              </>
                            ) : (
                              <>
                                {selectedHistory.status === "closed_tech" ? (
                                  <div className="flex gap-4 justify-end p-10">
                                    <button
                                      onClick={UserCloseRequest}
                                      className=" 
                          userProfile-btn 
                          inline-flex 
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-indigo-600 
                          py-2 
                          px-4 
                          text-sm 
                          font-medium 
                          text-white 
                          shadow-sm hover:bg-indigo-700 
                          focus:outline-none 
                          focus:ring-2 
                          focus:ring-indigo-500 
                          focus:ring-offset-2
                          mt-4
                        "
                                    >
                                      Confirmer la fin de l'intervention
                                    </button>
                                    <button
                                      onClick={handleUserNotCloseModal}
                                      className=" 
                      userProfiledelete-btn
                        inline-flex 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                    >
                                      Intervention toujours ouverte
                                    </button>
                                  </div>
                                ) : selectedHistory.status === "Paymen_User" ? (
                                  <div className="flex gap-4 justify-end p-10">
                                    <button
                                      onClick={handleAccept}
                                      className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                    >
                                      Accepter le devis
                                    </button>
                                    <button
                                      onClick={handleDeny}
                                      className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                    >
                                      Refuser le devis
                                    </button>
                                  </div>
                                ) : null}
                              </>
                            )}
                          </dl>
                        </div>
                      </>
                    </>
                  )}
                </>
              )}

              {techCloseModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Clôturer l'intervention
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Veuillez nous informer si l’intervention est
                                  clôturée ou non. Si l’intervention n’est pas
                                  clôturée choisissez: "Besoin d’un devis" pour
                                  transmettre votre devis au client ou “Besoin
                                  d’une deuxième intervention”.
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={techCloseRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Clôturer l'intervention
                                </button>
                                {/*<button
                              //onClick={techCloseRequest}
                              //onClick={handletechCloseModal}
                              className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                            >
                              Besoin d'une autre intervention
                            </button>*/}

                                <button
                                  onClick={handleUnfinishedClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                                py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                                focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Besoin d’un devis
                                </button>
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={handletechCloseModal}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Annuler
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Envoyer un devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Si vous connaissez le montant de votre devis
                                  choisissez “Je connais le montant”,Si non
                                  choisissez “Je ne connais pas le montant” pour
                                  uploader un devis en format PDF.
                                </p>
                              </div>
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                {/*<button
                            //onClick={techCloseRequest}
                            //onClick={handletechCloseModal}
                            className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                          >
                            Besoin d'une autre intervention
            </button>*/}
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Je ne connais pas le montant
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
  text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                            text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                              py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {UserNotCloseModal && (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => handleUserNotCloseModal}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Intervention est toujours ouverte
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Merci de préciser pourquoi vous pensez que
                              l'intervention est toujours ouverte.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={claime}
                                    onChange={handleClaime}
                                  />
                                </div>
                              </form>
                            </div>
                            <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                onClick={UserClaimRequest}
                              >
                                Ajouter
                              </button>
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={handleUserNotCloseModal}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {techClaimeModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Selon le client la demande est toujours
                                  ouverte
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={LitigeRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Intervention Cloturé
                                </button>

                                <button
                                  onClick={handleButtonClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Intervention toujour ouverte
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  L'intervention nécessite une autre action
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                            py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={TechAccept}
                                  //onClick={TechAccept}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                            py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Nouvelle Intervention
                                  </p>
                                </button>
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                            py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                            py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Devis
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
  text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                            py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                          text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                            py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {isModalVisible ? (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => setIsModalVisible(false)}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Résoudre Réclamations
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez remplir toutes les informations requises
                              dans le formulaire pour résoudre la réclamations.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Description détaillée du traitement de la
                                    réclamation:
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={adminDescriptionMsg}
                                    onChange={handleAdminDescriptionChange}
                                  />
                                </div>
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Actions prises pour résoudre le problème :
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={adminActionMsg}
                                    onChange={handleAdminActionMsg}
                                  />
                                </div>
                              </form>
                            </div>
                            {selectedHistory.status !== "claim_fix" && (
                              <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                  onClick={handleSolveClaim}
                                >
                                  Résoudre cette réclamation
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={() => setIsModalVisible(false)}
                                >
                                  Annuler
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {isModalVisibleLitige ? (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => setIsModalVisible(false)}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Résoudre Litige
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez remplir toutes les informations requises
                              dans le formulaire pour résoudre le letige.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Description détaillée du traitement du
                                    litige:
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={adminDescriptionMsg}
                                    onChange={handleAdminDescriptionChange}
                                  />
                                </div>
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Actions prises pour résoudre le problème :
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={adminActionMsg}
                                    onChange={handleAdminActionMsg}
                                  />
                                </div>
                              </form>
                            </div>
                            {selectedHistory.status !== "claim_fix" && (
                              <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                  onClick={handleSolveLitige}
                                >
                                  Résoudre cet litige
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={() => setIsModalVisibleLitige(false)}
                                >
                                  Annuler
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </>
      )}
      {/* Superviseur */}
      {userType === "Superviseur" && (
        <>
          {selectedHistory && (
            <div className="overflow-hidden bg-transparent shadow sm:rounded-lg max-w-screen-xl  ml-auto mr-auto mt-10 mb-10">
              {product && (
                <>
                  <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto"></div>
                  <div className="flex justify-around mt-6">
                    {product.photoDuProduit &&
                      product.photoDuProduit !== "null" && (
                        <img
                          src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                          alt={product._id}
                          className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                        />
                      )}
                    <img
                      src={`https://lebontechnicien.net/${product.QR}`}
                      alt={product._id}
                      className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                    />
                  </div>

                  {selectedHistory.type === "Demande de Réclamation" && (
                    <>
                      {/* Détail de réclamation */}
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détails de Réclamation:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Date de la demande:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {formattedDate}
                                </dd>
                              </dd>
                            </div>
                            {selectedHistory.PickedDate && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.PickedDate
                                    )
                                      ? selectedHistory.PickedDate
                                      : new Date(
                                          selectedHistory.PickedDate
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}

                            {selectedHistory.date_intervention && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.date_intervention
                                    )
                                      ? selectedHistory.date_intervention
                                      : new Date(
                                          selectedHistory.date_intervention
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Nom du client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.nom}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Prenom du client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.prenom}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Email du client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.email}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Telephone du client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.telephone}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Addresse du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {createdUser.adresse}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Message client
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.claime}
                              </dd>
                            </div>
                            {selectedHistory.adminDescriptionMsg && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Message d'admin
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adminDescriptionMsg}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.adminActionMsg && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Actions d'admin
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adminActionMsg}
                                </dd>
                              </div>
                            )}
                            {photoKeys && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Photo du problème
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <div className="flex gap-10">
                                    <div
                                      style={{
                                        overflowX: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {photoKeys.map((photoKey, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "inline-block",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <img
                                            className="w-[80px] h-[80px] cursor-pointer"
                                            src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                            alt={`Photoduprobleme ${index + 1}`}
                                            onClick={() =>
                                              openModal(
                                                `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                              )
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Statut:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {(() => {
                                  switch (selectedHistory.status) {
                                    case "Pending":
                                      return "En cours";
                                    case "closed":
                                      return "Cloturé";
                                    case "closed_tech":
                                      return "Cloturé par technicien";
                                    case "claim":
                                      return "Réclamation";
                                    case "Denied_payment":
                                      return "Devis refusé";
                                    case "Accepted_payment":
                                      return "Devis accepté";
                                    case "Paymen_User":
                                      return "Devis";
                                    case "Accepted":
                                      return "Accepté";
                                    case "claim_fix":
                                      return "Résolu";
                                    case "Email Pending":
                                      return "Email non validé";
                                    case "Confirmed":
                                      return "validé";
                                    case "In progress":
                                      return "En cours de vérification";
                                    case "Denied":
                                      return "Réfusé";
                                    case "solved":
                                      return "Résolu";
                                    case "unsolved":
                                      return "Non résolu";
                                    case "Full":
                                      return "Complète";
                                    case "litige_fixed":
                                      return "Litige résolu";
                                    case "claime_fixed":
                                      return "Réclamation résolu";
                                    case "annulled":
                                      return "Annulée";
                                    case "admin_accept":
                                      return "Contrat en attente de signature";
                                    case "Tech_onhold":
                                      return "En attente";

                                    default:
                                      return selectedHistory.status;
                                  }
                                })()}
                              </dd>
                            </div>

                            {selectedHistory.information && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    PLus de Détails
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.information}
                                  </dd>
                                </div>
                              </>
                            )}
                            {userType === "User" && (
                              <>
                                {nomtec && prenomtec && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Nom & Prenom Tech
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {nomtec} {prenomtec}
                                    </dd>
                                  </div>
                                )}
                                {reviewTec && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Avis Clients
                                    </dt>
                                  </div>
                                )}
                                {userType === "User" &&
                                  reviewTec?.length > 0 && (
                                    <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {reviewTec.map((review, index) => (
                                        <div
                                          key={index}
                                          className="w-full mb-4 bg-white p-4 rounded-md"
                                        >
                                          <div className="flex gap-2 mb-2">
                                            <p>
                                              {review.userName}{" "}
                                              {review.userLastName}
                                            </p>
                                            <Rating rating={review.rating} />
                                          </div>
                                          <p>{review.comment}</p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </>
                            )}

                            {pendingList[0]?.produits &&
                              pendingList[0]?._id ===
                                selectedHistory.created_for && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Votre Appareil
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {pendingList[0]?.produits}
                                  </dd>
                                </div>
                              )}
                            {userType === "User" &&
                            selectedHistory.status === "Pending" &&
                            selectedHistory.accepted_by.length === 0 &&
                            selectedHistory.denied_by.length === 0 ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    onClick={HandleAnnulation}
                                    //onClick={handletechCloseModal}
                                    className=" 
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-orange-400 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-orange-600 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                  >
                                    Annuler l’intervention
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {userType === "User" &&
                            selectedHistory.status === "Pending" &&
                            (selectedHistory.accepted_by.length > 0 ||
                              selectedHistory.denied_by.length > 0) ? (
                              <>
                                <div className="flex gap-4 justify-end p-10">
                                  <button
                                    onClick={executeSequentially}
                                    //onClick={handletechCloseModal}
                                    className=" 
                                    inline-flex 
                                    rounded-md 
                                    border 
                                    border-transparent 
                                    bg-green-600 
                                    py-2 
                                    px-4 
                                    text-sm 
                                    font-medium 
                                    text-white 
                                    shadow-sm hover:bg-green-700 
                                    focus:outline-none 
                                    focus:ring-2 
                                    focus:ring-indigo-500 
                                    focus:ring-offset-2
                                    mt-4
                                  "
                                  >
                                    Accpeter
                                  </button>
                                  <button
                                    onClick={handleDenyUser}
                                    //onClick={handletechCloseModal}
                                    className=" 
                                    inline-flex 
                                    rounded-md 
                                    border 
                                    border-transparent 
                                    bg-red-600 
                                    py-2 
                                    px-4 
                                    text-sm 
                                    font-medium 
                                    text-white 
                                    shadow-sm hover:bg-red-700 
                                    focus:outline-none 
                                    focus:ring-2 
                                    focus:ring-indigo-500 
                                    focus:ring-offset-2
                                    mt-4
                                  "
                                  >
                                    Refuser
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {userType === "Tech" &&
                            selectedHistory.status === "claim" ? (
                              <div className="flex justify-end  gap-4">
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={handletechCClaimeModal}
                                  className=" 
            
            inline-flex 
            
            rounded-md 
            border 
            border-transparent 
            bg-orange-400 
            py-2 
            px-4 
            text-sm 
            font-medium 
            text-white 
            shadow-sm hover:bg-orange-600 
            focus:outline-none 
            focus:ring-2 
            focus:ring-indigo-500 
            focus:ring-offset-2
            mt-4
          "
                                >
                                  répondre à la réclamation du client
                                </button>
                              </div>
                            ) : null}

                            {userType === "User" &&
                            (selectedHistory.status === "closed" ||
                              selectedHistory.status === "Full") ? (
                              <>
                                <RatingSystem
                                  userName={nom}
                                  userLastName={prenom}
                                  TechnicienID={selectedHistory.client_accept}
                                />
                              </>
                            ) : null}
                            {selectedHistory.status === "Paymen_User" ? (
                              <PdfViewer
                                url={`${apiUrl}${selectedHistory.payslip}`}
                              />
                            ) : null}
                            {userType === "Tech" ? (
                              <>
                                <>
                                  {selectedHistory.status === "Accepted" ||
                                  selectedHistory.status === "claim" ? (
                                    <>
                                      <div className="flex justify-end  gap-4">
                                        <button
                                          //onClick={techCloseRequest}
                                          onClick={handletechCloseModal}
                                          className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                        >
                                          Clôturer l'intervention
                                        </button>
                                        {userType === "Tech" &&
                                          selectedHistory.status ===
                                            "Accepted" && (
                                            <button
                                              onClick={sendLocationToBackend}
                                              className=" 
                      userProfile-btn 
                      inline-flex 
                      justify-center 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                            >
                                              Client manquant
                                            </button>
                                          )}
                                      </div>
                                    </>
                                  ) : null}
                                </>
                                <>
                                  {selectedHistory.status ===
                                  "Accepted_payment" ? (
                                    <>
                                      <div className="flex justify-end  gap-4">
                                        <button
                                          onClick={techCloseRequest}
                                          //onClick={handletechCloseModal}
                                          className=" 
                    userProfile-btn 
                    inline-flex 
                    
                    rounded-md 
                    border 
                    border-transparent 
                    bg-indigo-600 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-indigo-700 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                  "
                                        >
                                          Clôturer l'intervention
                                        </button>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              </>
                            ) : (
                              <>
                                {selectedHistory.status === "closed_tech" ? (
                                  <div className="flex gap-4 justify-end p-10">
                                    <button
                                      onClick={UserCloseRequest}
                                      className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                    >
                                      Confirmer la fin de l'intervention
                                    </button>
                                    <button
                                      onClick={handleUserNotCloseModal}
                                      className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                    >
                                      Intervention toujours ouverte
                                    </button>
                                  </div>
                                ) : selectedHistory.status === "Paymen_User" ? (
                                  <div className="flex gap-4 justify-end p-10">
                                    <button
                                      onClick={handleAccept}
                                      className=" 
                      userProfile-btn 
                      inline-flex 
                      justify-center 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                    >
                                      Accepter le devis
                                    </button>
                                    <button
                                      onClick={handleDeny}
                                      className=" 
                  userProfiledelete-btn
                    inline-flex 
                    rounded-md 
                    border 
                    border-transparent 
                    bg-indigo-600 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-indigo-700 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                  "
                                    >
                                      Refuser le devis
                                    </button>
                                  </div>
                                ) : null}
                              </>
                            )}
                          </dl>
                        </div>
                      </>
                      {/* Détails de l'appareil */}
                      <>
                        <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                          Détails de l'appareil:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Type d'appareil
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.typeDuBien}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Mois et année d'installation:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.moisInstallation} /{" "}
                                {product.annéeInstallation
                                  ? product.annéeInstallation
                                  : product.anneeInstallation}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Marque:
                              </dt>

                              <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.marque === "autre" ||
                                selectedHistory.marque === "autre" ? (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.autreMarque
                                      ? product.autreMarque
                                      : selectedHistory.autreMarque}
                                  </dd>
                                ) : (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.marque
                                      ? product.marque
                                      : selectedHistory.marque}
                                  </dd>
                                )}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Référence
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.reference
                                  ? product.reference
                                  : product.référence}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Maintenu
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.entretenu}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Puissance
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.puissance}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </>
                      {/* Détails d'intervention */}
                      <>
                        <>
                          <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                            Détails d'intervention:
                          </h3>
                          <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                            <dl>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Description de la panne
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.probleme}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Type de préstation:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.typeDePrestation}
                                  </dd>
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Appareil installé:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.typeDuBien}
                                </dd>
                              </div>
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Département:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.departementRequest}
                                </dd>
                              </div>
                              {photoKeys && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Photo du problème
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="flex gap-10">
                                      <div
                                        style={{
                                          overflowX: "auto",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {photoKeys.map((photoKey, index) => (
                                          <div
                                            key={index}
                                            style={{
                                              display: "inline-block",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <img
                                              className="w-[80px] h-[80px] cursor-pointer"
                                              src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                              alt={`Photoduprobleme ${
                                                index + 1
                                              }`}
                                              onClick={() =>
                                                openModal(
                                                  `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                                )
                                              }
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </dd>
                                </div>
                              )}
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Statut:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {(() => {
                                    switch (selectedHistory.statutRequest) {
                                      case "Pending":
                                        return "En cours";
                                      case "closed":
                                        return "Cloturé";
                                      case "closed_tech":
                                        return "Cloturé par technicien";
                                      case "claim":
                                        return "Réclamation";
                                      case "Denied_payment":
                                        return "Devis refusé";
                                      case "Accepted_payment":
                                        return "Devis accepté";
                                      case "Paymen_User":
                                        return "Devis";
                                      case "Accepted":
                                        return "Accepté";
                                      case "claim_fix":
                                        return "Résolu";
                                      case "Email Pending":
                                        return "Email non validé";
                                      case "Confirmed":
                                        return "validé";
                                      case "In progress":
                                        return "En cours de vérification";
                                      case "Denied":
                                        return "Réfusé";
                                      case "solved":
                                        return "Résolu";
                                      case "unsolved":
                                        return "Non résolu";
                                      case "Full":
                                        return "Complète";
                                      case "litige_fixed":
                                        return "Litige résolu";
                                      case "claime_fixed":
                                        return "Réclamation résolu";
                                      case "annulled":
                                        return "Annulée";
                                      case "admin_accept":
                                        return "Contrat en attente de signature";
                                      case "Tech_onhold":
                                        return "En attente";

                                      default:
                                        return selectedHistory.status;
                                    }
                                  })()}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </>
                        {/* Détails de Technicien */}
                        <>
                          {claimUser && claimUser.msg !== "No such User" && (
                            <>
                              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                Détails de Technicien:
                              </h3>
                              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                <dl>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Intervenant:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {claimUser.nom} {claimUser.prenom}
                                      </dd>
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Raison Sociale:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {claimUser.raisonSocial}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Addresse du Technicien:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {claimUser.adresse} {""}
                                        {claimUser.postal}
                                      </dd>
                                    </dd>
                                  </div>

                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Email du Technicien:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {claimUser.email}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Telephone du Technicien:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {claimUser.telephone}
                                      </dd>
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Domaine d'expertise:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {claimUser.domaine}
                                    </dd>
                                  </div>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      SIRET:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {claimUser.siret}
                                      </dd>
                                    </dd>
                                  </div>
                                </dl>
                              </div>
                            </>
                          )}
                        </>
                        {/* Buttons */}
                        <>
                          {(userType === "Admin" ||
                            userType === "Superviseur") &&
                          selectedHistory.status === "litige" ? (
                            <div className="flex justify-end  gap-4 p-8">
                              <button
                                //onClick={techCloseRequest}
                                onClick={ResoudreLitige}
                                className=" 
            
            inline-flex 
            
            rounded-md 
            border 
            border-transparent 
            bg-orange-400 
            py-2 
            px-4 
            text-sm 
            font-medium 
            text-white 
            shadow-sm hover:bg-orange-600 
            focus:outline-none 
            focus:ring-2 
            focus:ring-indigo-500 
            focus:ring-offset-2
            mt-4
          "
                              >
                                résoudre ce litige
                              </button>
                            </div>
                          ) : null}
                          {(userType === "Admin" ||
                            userType === "Superviseur") &&
                          selectedHistory.type === "Demande de Réclamation" &&
                          selectedHistory.status !== "claim_fix" ? (
                            <div className="flex justify-end  gap-4 p-8">
                              <button
                                onClick={handleModalToggle}
                                className="inline-flex         
                                    rounded-md 
                                    border 
                                    border-transparent 
                                    bg-orange-400 
                                    py-2 
                                    px-4 
                                    text-sm 
                                    font-medium 
                                    text-white 
                                    shadow-sm hover:bg-orange-600 
                                    focus:outline-none 
                                    focus:ring-2 
                                    focus:ring-indigo-500 
                                    focus:ring-offset-2
                                    mt-4
          "
                              >
                                résoudre cette réclamation
                              </button>
                            </div>
                          ) : null}
                        </>
                      </>
                    </>
                  )}
                  {/* Détails de litige */}
                  {(selectedHistory.status === "litige" ||
                    selectedHistory.status === "litige_fixed") && (
                    <>
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détails du litige:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Nom du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.NomUtilisateur}
                                </dd>
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Prénom du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.PrenomUtilisateur}
                                </dd>
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Email du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {selectedHistory.clientemail}
                              </dd>
                            </div>
                            {user && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Telephone du client:
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {user.telephone}
                                    </dd>
                                  </dd>
                                </div>
                              </>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Message du client:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.msg_claime}
                                </dd>
                              </dd>
                            </div>
                            {selectedHistory.adminDescriptionMsg && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Message d'admin:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adminDescriptionMsg}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.adminActionMsg && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Actions d'admin:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adminActionMsg}
                                </dd>
                              </div>
                            )}
                          </dl>
                        </div>
                      </>
                    </>
                  )}

                  {selectedHistory.type === "Demande d'intervention" && (
                    <>
                      {/*detail de l'Appareil */}
                      <>
                        <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                          Détails de l'appareil:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Type d'appareil
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.typeDuBien
                                  ? product.typeDuBien
                                  : selectedHistory.typeDeProjet}
                              </dd>
                            </div>
                            {(product.moisInstallation !== "" ||
                              selectedHistory.moisInstallation !== "" ||
                              selectedHistory.dateInstallation !== "" ||
                              selectedHistory.moisInstallation !== " " ||
                              selectedHistory.dateInstallation !== " " ||
                              selectedHistory.moisInstallation !== null ||
                              selectedHistory.dateInstallation !== null ||
                              !selectedHistory.moisInstallation ||
                              !selectedHistory.dateInstallation) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Mois et année d'installation:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.moisInstallation &&
                                  (product.annéeInstallation ||
                                    product.anneeInstallation)
                                    ? `${product.moisInstallation} / ${
                                        product.annéeInstallation
                                          ? product.annéeInstallation
                                          : product.anneeInstallation
                                      }`
                                    : `${selectedHistory.moisInstallation} / ${selectedHistory.dateInstallation}`}
                                </dd>
                              </div>
                            )}
                            {(product.marque || selectedHistory.marque) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Marque:
                                </dt>
                                {product.marque === "autre" ||
                                selectedHistory.marque === "autre" ? (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.autreMarque
                                      ? product.autreMarque
                                      : selectedHistory.autreMarque}
                                  </dd>
                                ) : (
                                  <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.marque
                                      ? product.marque
                                      : selectedHistory.marque}
                                  </dd>
                                )}
                              </div>
                            )}
                            {(product.reference ||
                              selectedHistory.référence ||
                              selectedHistory.reference) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Référence
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.reference ||
                                    selectedHistory.référence ||
                                    selectedHistory.reference}
                                </dd>
                              </div>
                            )}
                            {(product.puissance ||
                              selectedHistory.puissance) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Puissance
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.puissance
                                    ? product.puissance
                                    : selectedHistory.puissance}
                                </dd>
                              </div>
                            )}
                            {(product.entretenu ||
                              selectedHistory.entretenu) && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Maintenu
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.entretenu
                                    ? product.entretenu
                                    : selectedHistory.entretenu}
                                </dd>
                              </div>
                            )}
                          </dl>
                        </div>
                      </>
                      {/* Détails de l'intervention: */}
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détails de l'intervention:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            {selectedHistory.status && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Statut:
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {(() => {
                                    switch (selectedHistory.status) {
                                      case "Pending":
                                        return "En cours";
                                      case "closed":
                                        return "Cloturé";
                                      case "closed_tech":
                                        return "Cloturé par technicien";
                                      case "claim":
                                        return "Réclamation";
                                      case "Denied_payment":
                                        return "Devis refusé";
                                      case "Accepted_payment":
                                        return "Devis accepté";
                                      case "Paymen_User":
                                        return "Devis";
                                      case "Accepted":
                                        return "Accepté";
                                      case "claim_fix":
                                        return "Résolu";
                                      case "Email Pending":
                                        return "Email non validé";
                                      case "Confirmed":
                                        return "validé";
                                      case "In progress":
                                        return "En cours de vérification";
                                      case "Denied":
                                        return "Réfusé";
                                      case "solved":
                                        return "Résolu";
                                      case "unsolved":
                                        return "Non résolu";
                                      case "Full":
                                        return "Complète";
                                      case "litige_fixed":
                                        return "Litige résolu";
                                      case "claime_fixed":
                                        return "Réclamation résolu";
                                      case "annulled":
                                        return "Annulée";
                                      case "admin_accept":
                                        return "Contrat en attente de signature";
                                      case "Tech_onhold":
                                        return "En attente";
                                      default:
                                        return selectedHistory.status;
                                    }
                                  })()}
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Date de la demande:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {formattedDate}
                                </dd>
                              </dd>
                            </div>
                            {selectedHistory.PickedDate && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.PickedDate
                                    )
                                      ? selectedHistory.PickedDate
                                      : new Date(
                                          selectedHistory.PickedDate
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}
                            {selectedHistory.typeDePrestation && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Type de préstation
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.typeDePrestation}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.probleme && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Description de la panne
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.probleme}
                                </dd>
                              </div>
                            )}
                            {photoKeys &&
                              photoKeys !== null &&
                              photoKeys.length !== 0 &&
                              photoKeys !== "" &&
                              photoKeys[0] !== "photoDuProduit" && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Photo du problème
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <div className="flex gap-10">
                                      <div
                                        style={{
                                          overflowX: "auto",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {photoKeys.map((photoKey, index) => (
                                          <div
                                            key={index}
                                            style={{
                                              display: "inline-block",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <img
                                              className="w-[80px] h-[80px] cursor-pointer"
                                              src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                              alt={`Photoduprobleme ${
                                                index + 1
                                              }`}
                                              onClick={() =>
                                                openModal(
                                                  `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                                )
                                              }
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </dd>
                                </div>
                              )}
                            {selectedHistory.adresse !== null &&
                              selectedHistory.adresse !== "null" &&
                              selectedHistory.adresse && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Adresse de l'intervention
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.adresse}
                                  </dd>
                                </div>
                              )}

                            {selectedHistory.information && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    PLus de Détails
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.information}
                                  </dd>
                                </div>

                                {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Message du technicien
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.information}
                      </dd>
                </div>*/}
                              </>
                            )}

                            {pendingList[0]?.produits &&
                              pendingList[0]?._id ===
                                selectedHistory.created_for && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Votre Appareil
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {pendingList[0]?.produits}
                                  </dd>
                                </div>
                              )}
                          </dl>
                        </div>
                      </>

                      {/* Détails de client Tech*/}
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détail du Client:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            {selectedHistory.nom && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Nom du client
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.nom}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.prenom && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Prenom du client
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.prenom}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.adresse && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Adresse du client
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {selectedHistory.adresse}
                                </dd>
                              </div>
                            )}
                            {selectedHistory.postale !== null &&
                              selectedHistory.telephone !== "null" &&
                              selectedHistory.telephone && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Numéro du client
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.telephone}
                                  </dd>
                                </div>
                              )}
                            {selectedHistory.adresse !== null &&
                              selectedHistory.adresse !== "null" &&
                              selectedHistory.adresse && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Adresse du client
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.adresse}
                                  </dd>
                                </div>
                              )}
                            {selectedHistory.postale !== null &&
                              selectedHistory.postale !== "null" &&
                              selectedHistory.postale && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Code Postal
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.postale}
                                  </dd>
                                </div>
                              )}
                          </dl>
                        </div>
                      </>

                      {/* Détails de Technicien client pro*/}
                      <>
                        {selectedHistory.status !== "Pending" &&
                          selectedHistory.status !== "annulled" && (
                            <>
                              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                Détail du Technicien:
                              </h3>
                              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                <dl>
                                  {selectedHistory.raisonSociale && (
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Raison sociale
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.raisonSociale}
                                      </dd>
                                    </div>
                                  )}
                                  {selectedHistory.Technicien && (
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Nom
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.Technicien}
                                      </dd>
                                    </div>
                                  )}
                                  {selectedHistory.Technicienprenom && (
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Prénom
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.Technicienprenom}
                                      </dd>
                                    </div>
                                  )}
                                </dl>
                              </div>
                            </>
                          )}
                        {selectedHistory.status !== "Pending" &&
                          selectedHistory.status !== "Accepted" &&
                          selectedHistory.status !==
                            "En attente de date d'intervention" &&
                          selectedHistory.status !== "annulled" && (
                            <>
                              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                Rapport d'intervention:
                              </h3>
                              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                <dl>
                                  {selectedHistory.typeDeProjet &&
                                    !selectedHistory.typeDeProjet.includes(
                                      "PAC"
                                    ) && (
                                      <>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Type de problème:
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                              {selectedHistory.problemType}
                                            </dd>
                                          </dd>
                                        </div>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Description de l'intervention et du
                                            problème :
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            {
                                              selectedHistory.detailedDescription
                                            }
                                          </dd>
                                        </div>
                                      </>
                                    )}

                                  {selectedHistory.typeDeProjet.includes(
                                    "PAC"
                                  ) && (
                                    <>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Diametre Liaisons Frigorifiques
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.diametreLiaisonsFrigorifiques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          liaisons Frigorifiques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.liaisonsFrigorifiques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          flui de Frigorigene :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.fluideFrigorigene}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          alimentation Electrique :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.alimentationElectrique
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          CERFA :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.CERFA}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          hydrauliques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.hydrauliques}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          hydrauliques Module :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.hydrauliquesModule}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          raccordements Electriques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.raccordementsElectriques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          vannes :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.vannes}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Reglage :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.reglageReglage}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          climatisation :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.climatisation}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          appoint :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.appoint}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Pac Climatisation :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.PacClimatisation}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          prescriptions Constructeur :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.prescriptionsConstructeur
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          arret :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.arret}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          observations :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.observations}
                                        </dd>
                                      </div>
                                    </>
                                  )}
                                  {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Entreprise qui a intervenu :
                            </dt>
                            <dd
                              className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900"
                              onClick={() => {
                                window.location.href = `/product/${selectedHistory.created_for}`;
                              }}
                            >
                              {selectedHistory.typeDeProjet}
                            </dd>
                          </div>
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Devis :
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.zone}
                            </dd>
                          </div>*/}
                                </dl>
                              </div>
                            </>
                          )}
                      </>

                      {/* Rapport d'intervention: */}
                      {selectedHistory.status !== "Pending" &&
                        selectedHistory.status !== "Accepted" &&
                        selectedHistory.status !==
                          "En attente de date d'intervention" && (
                          <>
                            <>
                              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                Rapport d'intervention:
                              </h3>
                              <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                <dl>
                                  {selectedHistory.typeDeProjet &&
                                    !selectedHistory.typeDeProjet.includes(
                                      "PAC"
                                    ) && (
                                      <>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Type de problème:
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                              {selectedHistory.problemType}
                                            </dd>
                                          </dd>
                                        </div>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Description de l'intervention et du
                                            problème :
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            {
                                              selectedHistory.detailedDescription
                                            }
                                          </dd>
                                        </div>
                                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Besoin d'une autre intervention :
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            {
                                              selectedHistory.needAnotherIntervention
                                            }
                                          </dd>
                                        </div>
                                      </>
                                    )}

                                  {selectedHistory.typeDeProjet.includes(
                                    "PAC"
                                  ) && (
                                    <>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          diametre Liaisons Frigorifiques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.diametreLiaisonsFrigorifiques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          liaisons Frigorifiques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.liaisonsFrigorifiques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          flui de Frigorigene :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.fluideFrigorigene}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          alimentation Electrique :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.alimentationElectrique
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          CERFA :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.CERFA}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          hydrauliques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.hydrauliques}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          hydrauliques Module :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.hydrauliquesModule}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          raccordements Electriques :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.raccordementsElectriques
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          vannes :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.vannes}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Reglage :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.reglageReglage}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          climatisation :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.climatisation}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          appoint :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.appoint}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Pac Climatisation :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.PacClimatisation}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          prescriptions Constructeur :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.prescriptionsConstructeur
                                          }
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          arret :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.arret}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          observations :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.observations}
                                        </dd>
                                      </div>
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Besoin d'une autre intervention :
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {
                                            selectedHistory.needAnotherIntervention
                                          }
                                        </dd>
                                      </div>
                                    </>
                                  )}
                                  {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Entreprise qui a intervenu :
                      </dt>
                      <dd
                        className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900"
                        onClick={() => {
                          window.location.href = `/product/${selectedHistory.created_for}`;
                        }}
                      >
                        {selectedHistory.typeDeProjet}
                      </dd>
                    </div>
                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                        Devis :
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {selectedHistory.zone}
                      </dd>
                    </div>*/}
                                </dl>
                              </div>
                            </>
                          </>
                        )}
                    </>
                  )}

                  {selectedHistory.type === "Demande de Maintenance" && (
                    <>
                      {/*detail de l'Appareil */}
                      <>
                        <h3 className="text-lg leading-6 text-green-700 ml-20 mb-5 font-bold">
                          Détails de l'appareil:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Type d'appareil
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.typeDuBien}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Mois et année d'installation:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.moisInstallation} /{" "}
                                {product.annéeInstallation
                                  ? product.annéeInstallation
                                  : product.anneeInstallation}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Marque:
                              </dt>

                              <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {product.marque}
                                </dd>
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Référence
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.reference
                                  ? product.reference
                                  : product.référence}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Maintenu
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.entretenu}
                              </dd>
                            </div>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Puissance
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {product.puissance}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </>
                      {/* detail de maintenance */}
                      <>
                        <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                          Détails de contrat de maintenance:
                        </h3>
                        <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                          <dl>
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Date de la demande:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {formattedDate}
                                </dd>
                              </dd>
                            </div>
                            {selectedHistory.PickedDate && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Date de l'intervention:
                                </dt>

                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                      selectedHistory.PickedDate
                                    )
                                      ? selectedHistory.PickedDate
                                      : new Date(
                                          selectedHistory.PickedDate
                                        ).toLocaleDateString("fr-FR")}
                                  </dd>
                                </dd>
                              </div>
                            )}
                            {photoKeys && (
                              <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                  Photo du problème
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <div className="flex gap-10">
                                    <div
                                      style={{
                                        overflowX: "auto",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {photoKeys.map((photoKey, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "inline-block",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <img
                                            className="w-[80px] h-[80px] cursor-pointer"
                                            src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                            alt={`Photoduprobleme ${index + 1}`}
                                            onClick={() =>
                                              openModal(
                                                `https://lebontechnicien.net/${selectedHistory[photoKey]}`
                                              )
                                            }
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </dd>
                              </div>
                            )}
                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Statut:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {(() => {
                                  switch (selectedHistory.status) {
                                    case "Pending":
                                      return "En cours";
                                    case "closed":
                                      return "Cloturé";
                                    case "closed_tech":
                                      return "Cloturé par technicien";
                                    case "claim":
                                      return "Réclamation";
                                    case "Denied_payment":
                                      return "Devis refusé";
                                    case "Accepted_payment":
                                      return "Devis accepté";
                                    case "Paymen_User":
                                      return "Devis";
                                    case "Accepted":
                                      return "Accepté";
                                    case "claim_fix":
                                      return "Résolu";
                                    case "Email Pending":
                                      return "Email non validé";
                                    case "Confirmed":
                                      return "validé";
                                    case "In progress":
                                      return "En cours de vérification";
                                    case "Denied":
                                      return "Réfusé";
                                    case "solved":
                                      return "Résolu";
                                    case "unsolved":
                                      return "Non résolu";
                                    case "Full":
                                      return "Complète";
                                    case "litige_fixed":
                                      return "Litige résolu";
                                    case "claime_fixed":
                                      return "Réclamation résolu";
                                    case "annulled":
                                      return "Annulée";
                                    case "admin_accept":
                                      return "Contrat en attente de signature";
                                    case "Tech_onhold":
                                      return "En attente";
                                    default:
                                      return selectedHistory.status;
                                  }
                                })()}
                              </dd>
                            </div>
                            {selectedHistory.information && (
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    PLus de Détails
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {selectedHistory.information}
                                  </dd>
                                </div>

                                {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                      Message du technicien
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {selectedHistory.information}
                    </dd>
              </div>*/}
                              </>
                            )}
                            {userType === "User" && (
                              <>
                                {nomtec && prenomtec && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Nom & Prenom Tech
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {nomtec} {prenomtec}
                                    </dd>
                                  </div>
                                )}
                                {reviewTec && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Avis Clients
                                    </dt>
                                  </div>
                                )}
                                {userType === "User" &&
                                  reviewTec?.length > 0 && (
                                    <div className="mt-1 bg-gray-50 rounded-md p-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {reviewTec.map((review, index) => (
                                        <div
                                          key={index}
                                          className="w-full mb-4 bg-white p-4 rounded-md"
                                        >
                                          <div className="flex gap-2 mb-2">
                                            <p>
                                              {review.userName}{" "}
                                              {review.userLastName}
                                            </p>
                                            <Rating rating={review.rating} />
                                          </div>
                                          <p>{review.comment}</p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </>
                            )}

                            {pendingList[0]?.produits &&
                              pendingList[0]?._id ===
                                selectedHistory.created_for && (
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Votre Appareil
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {pendingList[0]?.produits}
                                  </dd>
                                </div>
                              )}
                            {userType === "User" &&
                            selectedHistory.status === "Pending" &&
                            selectedHistory.accepted_by.length === 0 &&
                            selectedHistory.denied_by.length === 0 ? (
                              <>
                                <div className="flex justify-end  gap-4">
                                  <button
                                    onClick={HandleAnnulation}
                                    //onClick={handletechCloseModal}
                                    className=" 
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-orange-400 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-orange-600 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                  >
                                    Annuler l’intervention
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {userType === "User" &&
                            selectedHistory.status === "Pending" &&
                            (selectedHistory.accepted_by.length > 0 ||
                              selectedHistory.denied_by.length > 0) ? (
                              <>
                                <div className="flex gap-4 justify-end p-10">
                                  <button
                                    onClick={executeSequentially}
                                    //onClick={handletechCloseModal}
                                    className=" 
                                    inline-flex 
                                    rounded-md 
                                    border 
                                    border-transparent 
                                    bg-green-600 
                                    py-2 
                                    px-4 
                                    text-sm 
                                    font-medium 
                                    text-white 
                                    shadow-sm hover:bg-green-700 
                                    focus:outline-none 
                                    focus:ring-2 
                                    focus:ring-indigo-500 
                                    focus:ring-offset-2
                                    mt-4
                                  "
                                  >
                                    Accpeter
                                  </button>
                                  <button
                                    onClick={handleDenyUser}
                                    //onClick={handletechCloseModal}
                                    className=" 
                                    inline-flex 
                                    rounded-md 
                                    border 
                                    border-transparent 
                                    bg-red-600 
                                    py-2 
                                    px-4 
                                    text-sm 
                                    font-medium 
                                    text-white 
                                    shadow-sm hover:bg-red-700 
                                    focus:outline-none 
                                    focus:ring-2 
                                    focus:ring-indigo-500 
                                    focus:ring-offset-2
                                    mt-4
                                  "
                                  >
                                    Refuser
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {userType === "Tech" &&
                            selectedHistory.status === "claim" ? (
                              <div className="flex justify-end  gap-4">
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={handletechCClaimeModal}
                                  className=" 
            
            inline-flex 
            
            rounded-md 
            border 
            border-transparent 
            bg-orange-400 
            py-2 
            px-4 
            text-sm 
            font-medium 
            text-white 
            shadow-sm hover:bg-orange-600 
            focus:outline-none 
            focus:ring-2 
            focus:ring-indigo-500 
            focus:ring-offset-2
            mt-4
          "
                                >
                                  répondre à la réclamation du client
                                </button>
                              </div>
                            ) : null}
                            {(userType === "Admin" ||
                              userType === "Superviseur") &&
                            selectedHistory.status === "litige" ? (
                              <div className="flex justify-end  gap-4">
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={ResoudreLitige}
                                  className=" 
            
            inline-flex 
            
            rounded-md 
            border 
            border-transparent 
            bg-orange-400 
            py-2 
            px-4 
            text-sm 
            font-medium 
            text-white 
            shadow-sm hover:bg-orange-600 
            focus:outline-none 
            focus:ring-2 
            focus:ring-indigo-500 
            focus:ring-offset-2
            mt-4
          "
                                >
                                  résoudre ce litige
                                </button>
                              </div>
                            ) : null}
                            {(userType === "Admin" ||
                              userType === "Superviseur") &&
                            selectedHistory.type ===
                              "Demande de Réclamation" ? (
                              <div className="flex justify-end  gap-4">
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={ResoudreReclamation}
                                  className=" 
            
            inline-flex 
            
            rounded-md 
            border 
            border-transparent 
            bg-orange-400 
            py-2 
            px-4 
            text-sm 
            font-medium 
            text-white 
            shadow-sm hover:bg-orange-600 
            focus:outline-none 
            focus:ring-2 
            focus:ring-indigo-500 
            focus:ring-offset-2
            mt-4
          "
                                >
                                  résoudre cette réclamation
                                </button>
                              </div>
                            ) : null}
                            {userType === "User" &&
                            (selectedHistory.status === "closed" ||
                              selectedHistory.status === "Full") ? (
                              <>
                                <RatingSystem
                                  userName={nom}
                                  userLastName={prenom}
                                  TechnicienID={selectedHistory.client_accept}
                                />
                              </>
                            ) : null}
                            {selectedHistory.status === "Paymen_User" ? (
                              <PdfViewer
                                url={`${apiUrl}${selectedHistory.payslip}`}
                              />
                            ) : null}
                            {userType === "Tech" ? (
                              <>
                                <>
                                  {selectedHistory.status === "Accepted" ||
                                  selectedHistory.status === "claim" ? (
                                    <>
                                      <div className="flex justify-end  gap-4">
                                        <button
                                          //onClick={techCloseRequest}
                                          onClick={handletechCloseModal}
                                          className=" 
                      userProfile-btn 
                      inline-flex 
                      
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                        >
                                          Clôturer l'intervention
                                        </button>
                                        {userType === "Tech" &&
                                          selectedHistory.status ===
                                            "Accepted" && (
                                            <button
                                              onClick={sendLocationToBackend}
                                              className=" 
                      userProfile-btn 
                      inline-flex 
                      justify-center 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                            >
                                              Client manquant
                                            </button>
                                          )}
                                      </div>
                                    </>
                                  ) : null}
                                </>
                                <>
                                  {selectedHistory.status ===
                                  "Accepted_payment" ? (
                                    <>
                                      <div className="flex justify-end  gap-4">
                                        <button
                                          onClick={techCloseRequest}
                                          //onClick={handletechCloseModal}
                                          className=" 
                    userProfile-btn 
                    inline-flex 
                    
                    rounded-md 
                    border 
                    border-transparent 
                    bg-indigo-600 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-indigo-700 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                  "
                                        >
                                          Clôturer l'intervention
                                        </button>
                                      </div>
                                    </>
                                  ) : null}
                                </>
                              </>
                            ) : (
                              <>
                                {selectedHistory.status === "closed_tech" ? (
                                  <div className="flex gap-4 justify-end p-10">
                                    <button
                                      onClick={UserCloseRequest}
                                      className=" 
                        userProfile-btn 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        bg-indigo-600 
                        py-2 
                        px-4 
                        text-sm 
                        font-medium 
                        text-white 
                        shadow-sm hover:bg-indigo-700 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-indigo-500 
                        focus:ring-offset-2
                        mt-4
                      "
                                    >
                                      Confirmer la fin de l'intervention
                                    </button>
                                    <button
                                      onClick={handleUserNotCloseModal}
                                      className=" 
                    userProfiledelete-btn
                      inline-flex 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                    >
                                      Intervention toujours ouverte
                                    </button>
                                  </div>
                                ) : selectedHistory.status === "Paymen_User" ? (
                                  <div className="flex gap-4 justify-end p-10">
                                    <button
                                      onClick={handleAccept}
                                      className=" 
                      userProfile-btn 
                      inline-flex 
                      justify-center 
                      rounded-md 
                      border 
                      border-transparent 
                      bg-indigo-600 
                      py-2 
                      px-4 
                      text-sm 
                      font-medium 
                      text-white 
                      shadow-sm hover:bg-indigo-700 
                      focus:outline-none 
                      focus:ring-2 
                      focus:ring-indigo-500 
                      focus:ring-offset-2
                      mt-4
                    "
                                    >
                                      Accepter le devis
                                    </button>
                                    <button
                                      onClick={handleDeny}
                                      className=" 
                  userProfiledelete-btn
                    inline-flex 
                    rounded-md 
                    border 
                    border-transparent 
                    bg-indigo-600 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-indigo-700 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                  "
                                    >
                                      Refuser le devis
                                    </button>
                                  </div>
                                ) : null}
                              </>
                            )}
                          </dl>
                        </div>
                      </>
                    </>
                  )}
                </>
              )}

              {techCloseModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Clôturer l'intervention
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Veuillez nous informer si l’intervention est
                                  clôturée ou non. Si l’intervention n’est pas
                                  clôturée choisissez: "Besoin d’un devis" pour
                                  transmettre votre devis au client ou “Besoin
                                  d’une deuxième intervention”.
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={techCloseRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Clôturer l'intervention
                                </button>
                                {/*<button
                            //onClick={techCloseRequest}
                            //onClick={handletechCloseModal}
                            className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                          >
                            Besoin d'une autre intervention
                          </button>*/}

                                <button
                                  onClick={handleUnfinishedClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                              py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                              focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Besoin d’un devis
                                </button>
                                <button
                                  //onClick={techCloseRequest}
                                  onClick={handletechCloseModal}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                            py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Annuler
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Envoyer un devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Si vous connaissez le montant de votre devis
                                  choisissez “Je connais le montant”,Si non
                                  choisissez “Je ne connais pas le montant” pour
                                  uploader un devis en format PDF.
                                </p>
                              </div>
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                            py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                {/*<button
                          //onClick={techCloseRequest}
                          //onClick={handletechCloseModal}
                          className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                            py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                        >
                          Besoin d'une autre intervention
          </button>*/}
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                            py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                            py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Je ne connais pas le montant
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                            py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCloseModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                          text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                            py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {UserNotCloseModal && (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => handleUserNotCloseModal}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Intervention est toujours ouverte
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Merci de préciser pourquoi vous pensez que
                              l'intervention est toujours ouverte.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={claime}
                                    onChange={handleClaime}
                                  />
                                </div>
                              </form>
                            </div>
                            <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                onClick={UserClaimRequest}
                              >
                                Ajouter
                              </button>
                              <button
                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={handleUserNotCloseModal}
                              >
                                Annuler
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {techClaimeModal && (
                <div className="fixed inset-0 z-10 overflow-y-auto">
                  {step === 1 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  Selon le client la demande est toujours
                                  ouverte
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={LitigeRequest}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                            py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Intervention Cloturé
                                </button>

                                <button
                                  onClick={handleButtonClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                            py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                            focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  Intervention toujour ouverte
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 sm:flex">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-2">
                                Intervention Toujours Ouverte
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  L'intervention nécessite une autre action
                                </p>
                              </div>
                              <div className="flex justify-center gap-2">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={handleFinishedClick}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                          py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                          focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={TechAccept}
                                  //onClick={TechAccept}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                          py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                          focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Nouvelle Intervention
                                  </p>
                                </button>
                                <button
                                  onClick={handleIHaveIdeaClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                          py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                          focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  <p className="text-sm">
                                    Je connais le montant
                                  </p>
                                </button>
                                <button
                                  onClick={handleDevisClick}
                                  //onClick={handletechCloseModal}
                                  className=" userProfile-btn inline-flex rounded-md border border-transparent bg-indigo-600 
                          py-4 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none 
                          focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                >
                                  Devis
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 3 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3 ">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-4 text-center">
                                  Veuillez uploader votre devis en PDF (Poid max
                                  500Ko)
                                </p>
                              </div>
                              <div className="mb-4">
                                <input
                                  type="file"
                                  accept=".pdf"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex justify-start w-[90%] ml-auto mr-auto">
                                <h3 className="mb-2 mt-4">Montant du devis:</h3>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                required
                                min="0"
                                value={pay}
                                onChange={handleCosts}
                                className="w-[90%] block bg-gray-200 border
text-base font-medium text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                          py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                          focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {step === 4 && (
                    <>
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40"
                        onClick={handletechCClaimeModal}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center ">
                              <h4 className="text-lg text-center font-medium text-gray-800 mb-4">
                                Montant de devis
                              </h4>
                              <div className="w-[98%]">
                                <p className="mt-4 text-[14px] leading-relaxed text-gray-500 mb-2 text-center">
                                  veuillez ajouter les frais pour les envoyer au
                                  client
                                </p>
                              </div>
                              <input
                                type="number"
                                pattern="\d+(,\d{2})?"
                                inputmode="decimal"
                                step="0.01"
                                min="0"
                                required
                                value={pay}
                                onChange={handleCosts}
                                className=" w-[90%] block  bg-gray-200 border border-gray-200 text-base font-medium 
                        text-gray-700 rounded-md p-2 mb-2 ml-auto mr-auto"
                              />
                              <div className="flex justify-center gap-8">
                                <button
                                  //onClick={handleFinishedClick}
                                  onClick={() => handlePrécédentClick(2)}
                                  className=" inline-flex rounded-md border border-transparent bg-gray-300 
                          py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-gray-400 hover:text-white focus:outline-none 
                          focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 "
                                >
                                  <p>Précédent</p>
                                </button>
                                <button
                                  onClick={MobileApplication}
                                  disabled={pay ? false : true}
                                  //onClick={handletechCloseModal}
                                  className="inline-flex rounded-md border border-transparent bg-green-700 text-white py-4 px-4 text-sm font-medium text-gray shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4 disabled:bg-gray-200 disabled:text-gray-400"
                                >
                                  Envoyé
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {isModalVisible ? (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => setIsModalVisible(false)}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Résoudre Réclamations
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez remplir toutes les informations requises
                              dans le formulaire pour résoudre la réclamations.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Description détaillée du traitement de la
                                    réclamation:
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={adminDescriptionMsg}
                                    onChange={handleAdminDescriptionChange}
                                  />
                                </div>
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Actions prises pour résoudre le problème :
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={adminActionMsg}
                                    onChange={handleAdminActionMsg}
                                  />
                                </div>
                              </form>
                            </div>
                            {selectedHistory.status !== "claim_fix" && (
                              <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                  onClick={handleSolveClaim}
                                >
                                  Résoudre cette réclamation
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={() => setIsModalVisible(false)}
                                >
                                  Annuler
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {isModalVisibleLitige ? (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-40"
                      onClick={() => setIsModalVisible(false)}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                          <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <IoAddCircleOutline className="text-green-600" />
                          </div>
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Résoudre Litige
                            </h4>
                            <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-4">
                              Veuillez remplir toutes les informations requises
                              dans le formulaire pour résoudre le letige.
                            </p>
                            <div className="modal-content mr-4">
                              <form className="mr-4 mb-4">
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Description détaillée du traitement du
                                    litige:
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={adminDescriptionMsg}
                                    onChange={handleAdminDescriptionChange}
                                  />
                                </div>
                                <div className="mt-1">
                                  <label
                                    htmlFor="claime"
                                    className="block text-[14px] font-medium text-gray-700 w-[full]"
                                  >
                                    Actions prises pour résoudre le problème :
                                  </label>
                                  <textarea
                                    id="claime"
                                    name="claime"
                                    rows={3}
                                    className="mt-1 block w-full rounded-md  border-1 border-gray-200 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4"
                                    defaultValue={""}
                                    value={adminActionMsg}
                                    onChange={handleAdminActionMsg}
                                  />
                                </div>
                              </form>
                            </div>
                            {selectedHistory.status !== "claim_fix" && (
                              <div className="items-center gap-2 mt-3 sm:flex pb-4 mr-4">
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                  onClick={handleSolveLitige}
                                >
                                  Résoudre cet litige
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={() => setIsModalVisibleLitige(false)}
                                >
                                  Annuler
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </>
      )}
      {/* Others */}
      {userType !== "Tech" &&
      historyTech.length !== 0 &&
      showButtons !== "oui" ? (
        <div className="mt-10 w-[90%] ml-auto mr-auto">
          <h4 className="text-xl text-gray-900 font-bold mb-2">
            Liste des techniciens qui ont répondu à vos demandes d'intervention
          </h4>
          <CRUDTable
            data={info}
            link={`/user-intervention-details/`}
            /* checkIcon={
                <IoCheckmarkOutline
                  style={{ fontSize: "20px", cursor: "pointer", color: "green" }}
                  onClick={() => console.log(info.id)}
                />
              }*/
            checkIconaccept={(id, Technician_id) => (
              <IoCheckmarkOutline
                style={{ fontSize: "20px", cursor: "pointer", color: "green" }}
                onClick={() => executeSequentiallyTable(id, Technician_id)}
              />
            )}
            /*eyeIcon={(id, tech_info) => (
                <IoEyeOutline
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    color: "#f8c40s8",
                  }}
                  onClick={() => handleTechnicianID(id, tech_info)}
                />
              )}*/
            deleteIcon={(id, technicianId) => (
              <IoCloseOutline
                style={{ fontSize: "20px", cursor: "pointer", color: "red" }}
                onClick={() => handleDenyTable(id, technicianId)}
              />
            )}
            onDataChange={handleDataChange}
          />
        </div>
      ) : null}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="modal-overlay absolute inset-0 bg-black opacity-50"
            onClick={closeModal}
          ></div>
          <div className="modal-container bg-white w-full max-w-screen-lg mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <span
              className="modal-close absolute top-0 right-0 m-4 text-gray-400 cursor-pointer text-3xl"
              onClick={closeModal}
            >
              <FiX size={24} />{" "}
              {/* Replace the "&times;" character with the Feather X icon */}
            </span>
            <div className="modal-content text-left relative flex justify-center items-center h-[600px]">
              <img
                className="modal-image w-[50%]"
                src={selectedImage}
                alt="Full-Screen"
              />
            </div>
          </div>
        </div>
      )}
      {confirmationModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3">
                  <div className="mt-2 text-center text-center">
                    <h4 className="text-lg font-medium text-gray-800">
                      Votre demande est en cours de traitement
                    </h4>
                    {/*<div className="flex justify-center">
                        <Link to="/tableau-de-bord">
                          <button className=" mt-4 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2">
                            Mon compte
                          </button>
                        </Link>
                      </div>*/}
                    <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                      Votre demande est en cours de traitement. Nous mettons
                      tout en œuvre pour y répondre rapidement. Nous vous
                      remercions de votre patience et nous nous excusons pour
                      tout éventuel retard. Pour toute question, n'hésitez pas à
                      nous contacter.
                    </p>
                    <div className="items-center gap-2 mt-3 sm:flex"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default InterventionDetail;
