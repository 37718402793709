import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const RatingSystem = (props) => {
  //userName, userLastName, TechnicienID
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [techs, setTechs] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { ids } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [nomtec, setNomTec] = useState("");
  const [prenomtec, setPrenomTec] = useState("");
  const [error, setError] = useState("");
  const apiUrl = 'https://lebontechnicien.net'
  
  const userName = props.userName;
  const userLastName = props.userLastName;
  const id = props.TechnicienID;
  const techName = props.techName;
  const techLastName = props.techLastName;
  console.log("name and last name of tech :", techName + '' + techLastName )
  console.log("props:",props)
  const handleRating = (event) => {
    setRating(event.target.value);
  };

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            id: `${sessionStorage.getItem("technicienID")}`,
          }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        setNomTec(data.nom);
        setPrenomTec(data.prenom);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  //Fetches all Users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type: "Tech" }),
        });
        const users = await response.json();
        const filteredTechs = users.filter((tech) => tech._id === id);
        setTechs(filteredTechs);
        console.log("filteredTechs", filteredTechs);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [inputValue]);

  const email = techs.length > 0 ? techs[0].email : "";

  // Gives a review
  const handleClick = async (event) => {
    event.preventDefault();

    const formData = {
      id,
      userName,
      userLastName,
      rating,
      comment,
      email,
    };
    const response = await fetch(`${apiUrl}/tech/review`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();

    if (data === "User updated successfuly") {
      navigate(`/user-history`);
    } else if (data.msg === "No such User") {
      console.log(data.msg);
    }
  };

  const handleStarClick = (rating) => {
    setRating(rating);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you can make a request to your back-end to save the rating and the comment
    // in your database.
  };

  return (
    <form
      onSubmit={handleSubmit}
      class=" bg-white  flex flex-col space-y-6 p-6  rounded-lg mb-8"
    >
      <div class="flex flex-col space-y-2">
        <div className="flex flex-col space-y-2">
          <label className="font-medium text-gray-700" htmlFor="rating">
            Note du technicien {nomtec} {prenomtec}:
          </label>
          <div className="flex space-x-2">
            {[1, 2, 3, 4, 5].map((value) => (
              <svg
                key={value}
                className={`h-4 w-4 cursor-pointer ${
                  value <= rating ? "text-yellow-500" : "text-gray-400"
                }`}
                fill="none"
                viewBox="0 0 20 20" // <-- increase size of viewBox
                stroke="currentColor"
                onClick={() => handleStarClick(value)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 1.291l2.928 6.017 6.524.948-4.72 4.578 1.114 6.506L10 16.225l-5.846 3.052 1.114-6.506-4.72-4.578 6.524-.948L10 1.291z"
                />
              </svg>
            ))}
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <label class="font-medium text-gray-700" htmlFor="comment">
          Laissez un commentaire:
        </label>
        <textarea
          name="comment"
          id="comment"
          value={comment}
          onChange={handleComment}
          class="bg-gray-200 p-2 rounded-lg"
        />
      </div>
      <button
        type="submit"
        onClick={handleClick}
        class="bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 w-[20%]"
      >
        Envoyer
      </button>
    </form>
  );
};

export default RatingSystem;
