import React from "react";
import { Helmet } from "react-helmet";
import { Banner } from "../../Components";
import fonctionnement from "../../Assets/iphone-telechargement.png";
import TinyDiv from "../../Components/LandingPage/TinyDiv";
import FoncGrid from "../../Components/LandingPage/FoncGrid";
import UserFonc from "../../Constants/UserFonc";
import TechFonGrid from "../../Components/LandingPage/TechFonGrid";
import TechFonc from "../../Constants/TechFonc";
import UserSlider from "../../Components/LandingPage/UserSlider";
import TechSlider from "../../Components/LandingPage/TechSlider";

const Download = () => {
  return (
    <div>
      <Helmet>
        <title>Téléchargement - LeBonTechnicien.fr </title>
        <meta
          name="keywords"
          content="installations et réparations des équipements d'énergie renouvelable"
        />
        <meta
          name="description"
          content="Chez LeBonTechnicien.fr, nous sommes experts en installations et réparations des équipements d'énergie renouvelable. Alliant savoir-faire et passion, notre équipe qualifiée met à profit les dernières innovations pour garantir un avenir durable."
        />
        <link rel="canonical" href="http://lebontechnicien.fr/activites" />
      </Helmet>
      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">
                  Téléchargez L’application pour une Installation Verte en Toute
                  Sérénité !
                </h1>
                <p className="leBonTech-p">
                  N'hésitez plus à adopter les équipements d'énergie
                  renouvelable ! Avec notre application, gardez l'esprit zen
                  lors de l'installation et de l'entretien de vos systèmes.
                  Trouvez en quelques clics l'élite des techniciens pour assurer
                  une maintenance irréprochable. Passez au vert en toute
                  sérénité, sachant que vous avez accès à la meilleure expertise
                  du marché. La transition énergétique devient un jeu d'enfant.
                  Téléchargez dès maintenant et prenez le chemin d'une
                  consommation énergétique plus propre, tout en ayant
                  l'assurance d'un soutien technique de premier ordre. Le futur
                  durable et serein vous attend !
                </p>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img src={fonctionnement} alt="Comment ça marche" />
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <section className="comment-ca-marche" style={{marginTop: '80px', marginBottom: '40px'}}>
      <TinyDiv 
        titles="Téléchargez notre application dédiée aux clients !" 
        appStoreLink="https://apps.apple.com/fr/app/lebontech/id6450009896" 
        googlePlayLink="https://play.google.com/store/apps/details?id=com.devinov.LeBonTechnicienUser"
      />
      </section>
      <section className="comment-ca-marche" style={{ marginTop: '80px', marginBottom: '40px' }}>
        <FoncGrid dataSource={UserFonc} />
        <div style={{marginTop: "100px"}}>
          <UserSlider/>
        </div>
      </section>
      <section className="comment-ca-marche" style={{marginTop: '80px', marginBottom: '40px'}}>
      <TinyDiv 
        titles="Téléchargez notre application dédiée aux Techniciens !" 
        appStoreLink="https://apps.apple.com/fr/app/lebontech-pro/id6450013173" 
        googlePlayLink="https://play.google.com/store/apps/details?id=com.devinov.LeBonTechnicienPro "
      />
      </section>      
      <section className="comment-ca-marche" style={{ marginTop: '80px', marginBottom: '40px' }}>
        <TechFonGrid dataSource={TechFonc} />
        <div style={{marginTop: "100px"}}>
          <TechSlider/>
        </div>
      </section>
    </div>
  );
};

export default Download;
