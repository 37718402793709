import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import PlacesAutocomplete from "react-places-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";

function FilterModal({ isOpen, closeModal, applyFilters, id, sendDataToParentFilter  }) {
  const [selectedType, setSelectedType] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [installationChecked, setInstallationChecked] = useState(false);
  const [calendars, setCalendars] = useState([]);


  const [adresse, setAdresse] = useState(""); // Store the selected address
  const [placeId, setPlaceId] = useState("");

  const apiUrl = 'https://lebontechnicien.net'

  const handleApplyFilters = () => {
    // Store selected filter options and address in session storage
    sessionStorage.setItem("selectedType", selectedType);
    sessionStorage.setItem("installationChecked", installationChecked);
    sessionStorage.setItem("departureAddress", adresse); // Store the selected address
    sessionStorage.setItem("departurePlaceId", placeId);
    console.log("selectedTypeModal", selectedType)
    console.log("departureDateFilterModal", departureDate)
    console.log("returnDateDateFilterModal", returnDate)
    // Apply filters and close the modal
    applyFilters(selectedType, departureDate, returnDate, installationChecked );
    closeModal();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxWidth: "400px", 
      zIndex: "9999 !important"
    },
  };

  const getCalendars = async () => {
    try {
      // Make a GET request using the fetch API
      const response = await fetch(`${apiUrl}/calenders/find_calender`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({}),
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // If the response is successful, you can handle the result here
        const result = await response.json();
        // Filter the result based on the condition Team === id
        const filteredCalendars = result.filter(calendar => calendar.Team === id);
        setCalendars(filteredCalendars);

      } else {
        // Handle other response statuses or errors here
        console.error("Error:", response.status);
      }
    } catch (error) {
      // Handle any network or fetch errors
      console.error("Fetch error:", error);
    }
  };

  // Call the function to send the GET request
  useEffect(() => {
    getCalendars();
  }, []);

  // Get the current date
  const currentDate = new Date();

  // Extract disabled dates from planning
  const disabledDates = calendars.flatMap((plan) => {
    const departure = new Date(plan.departureDate);
    const returnD = new Date(plan.returnDate);

    // Get all dates within the range of any planning entry
    const datesInRange = [];
    let currentDate = new Date(departure);

    while (currentDate <= returnD) {
      datesInRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesInRange;
  });

  // Add today's date to the disabled dates
  disabledDates.push(currentDate);

  // Function to check if a date is disabled
  const isDateDisabled = (date) => {
    // Disable dates before the current date
    if (date < currentDate) {
      return true;
    }

    // Disable today's date and dates within the range of any planning entry
    return disabledDates.some((disabledDate) => {
      return (
        date.getDate() === disabledDate.getDate() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getFullYear() === disabledDate.getFullYear()
      );
    });
  };

  const handleDepartureDateChange = (date) => {
    setDepartureDate(date);
    sessionStorage.setItem("departureDate", date);
  };

  const handleReturnDateChange = (date) => {
    setReturnDate(date);
    sessionStorage.setItem("returnDate", date);
  };



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Filter Modal"
      style={customStyles} // Apply the custom styles here
    >
      {/*<h2 className="text-2xl font-bold mb-4">Filter Interventions</h2>*/}
      <div className="">
        <label>Type de Prestation:</label>
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          className="p-2 border border-gray-100 w-[280px] mt-2"
        >
          <option value="">Type De Prestation</option>
          <option value="Installation">Installation</option>
          <option value="Other">Réparation/ Remplacement/ Maintenance</option>
          <option value="RAC">Remise en conformité</option>
        </select>
      </div>
      <br />
      <div className="">
        <label>Date de départ:</label>
        <DatePicker
          selected={departureDate}
          onChange={handleDepartureDateChange}
          minDate={currentDate}
          locale={fr}
          excludeDates={disabledDates}
          className="p-2 border border-gray-100 w-[280px] mt-2"
          placeholderText="Sélectionnez la date de départ"
          
        />

        {/*<input
          type="date"
          value={departureDate}
          onChange={(e) => setDepartureDate(e.target.value)}
  /> */}
      </div>
      <br />
      <div className="">
        <label>Date de retour:</label>
        {/*<input
          type="date"
          value={returnDate}
          onChange={(e) => setReturnDate(e.target.value)}
/>*/}
        <DatePicker
          selected={returnDate}
          onChange={handleReturnDateChange}
          minDate={currentDate}
          excludeDates={disabledDates}
          locale={fr}
          className="p-2 border border-gray-100 w-[280px] mt-2"
          placeholderText="Sélectionnez la date de retour"
        />
      </div>
      <br />
      <div className="p-1">
        <label>
          Adresse de départ : {/* Include the Autocomplete component here */}
        </label>
        <PlacesAutocomplete
          value={adresse}
          onChange={setAdresse}

          onSelect={(address, placeId) => {
            setAdresse(address);
            setPlaceId(placeId);
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Rechercher l'adresse de départ...",
                  className: "form-control p-2 border border-gray-100 w-[280px] mt-2",
                })}
              />
              <div>
                {suggestions.map((suggestion) => (
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <br />
      <button
        className="p-2 bg-green-900  text-white rounded-md "
        onClick={handleApplyFilters}
      >
        Générer
      </button>
    </Modal>
  );
}

export default FilterModal;
