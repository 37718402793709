import React from 'react'
import { NavLink, Link } from "react-router-dom";

const LoginPick = () => {
  return (
    <>
      <div className='flex h-screen justify-center items-center'>
        <div className='w-screen absolute  left-[50%]]'>
          <div className='w-full'>
            <h1 className='text-center mb-[2px] font-medium text-base text-gray-500'> S'inscrire en tant que technicien</h1>
            <div className='flex justify-center mt-4'>
              <button className='submit-button px-3  text-lg rounded-md text-white w-[26%] '> 
                <NavLink 
                  to="/inscription-technicien"
                >              
                  S'inscrire 
                </NavLink>
              </button>
            </div>
          </div>
          <div className="w-1/12 flex items-center justify-center ml-auto mr-auto mt-[50px] mb-[50px]">
            <div className="h-[2px] w-16 bg-gray-300 mx-4 md:w-16 sm:w-16"></div>
            <div className="text-lg font-medium">OU</div>
            <div className="h-[2px] w-16 bg-gray-300 mx-4 md:w-16 sm:w-16"></div>
          </div>
          <div className='w-full '>
            <h1 className='text-center mb-[2px] font-medium text-base text-gray-500'> S'inscrire en tant qu'utilisateur</h1>
            <div className='flex justify-center mt-4'>
              <button className='submit-button px-3 text-lg rounded-md text-white w-[26%] '>
                <NavLink 
                  to="/inscription-client"
                >              
                  S'inscrire 
                </NavLink>
              </button>                   
            </div>    
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPick