import React, { useState } from "react";

import {
  LeBonTechnicien2grid,
  Banner,
  LeBonTechnicien3grid,
  LeBonTechnicien2gridLeft,
  PartnerBanner,
} from "../../Components";

import {
  LebonTechnicien_data,
  CommentCaMarche_data,
  Nos_prestations,
  Nos_valeur,
} from "../../Constants/dummy";

import googlePlay from "../../Assets/media/google-app.png";
import appStore from "../../Assets/media/app-store.png";
import phone from "../../Assets/media/app-phone.png";
import Phone2 from "../../Assets/media/aventages-client.png";
import Phone3 from "../../Assets/media/aventages-technicien.png";
import icon1 from "../../Assets/media/client-demande.png";
import icon2 from "../../Assets/media/tech-envoi.png";
import icon3 from "../../Assets/media/choix-tech.png";
import icon4 from "../../Assets/media/installation.png";
import icon5 from "../../Assets/media/reparation.png";
import icon6 from "../../Assets/media/maintenance.png";
import icon7 from "../../Assets/media/eco-responsabilite.png";
import icon8 from "../../Assets/media/expertise-reconnue.png";
import icon9 from "../../Assets/media/Service-irreprochable.png";

import { Helmet } from "react-helmet";

const HomePage = () => {
  const [inputDeviceValue, setInputDeviceValue] = useState("Device");
  const [inputLocationValue, setInputLocationValue] = useState("Location");

  function handleChangeDevice(event) {
    setInputDeviceValue(event.target.value);
  }

  function handleChangeLocation(event) {
    setInputLocationValue(event.target.value);
  }

  return (
    <>
      <Helmet>
        <title>LeBonTechnicien.fr</title>
        <meta name="keywords" content="LeBonTechnicien.fr" />
        <meta name="description" content="Téléchargez notre appli LeBonTechnicien.fr et profitez de l'expertise de l'élite des professionnels en énergies renouvelables pour un entretien paisible de vos équipements d'énergie renouvelable !" />
        <link
          rel="canonical"
          href="https://lebontechnicien.net/"
        />
      </Helmet>

      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">
                  Investissez dans
                  <br /> un avenir durable
                </h1>
                <p className="leBonTech-p">
                  Téléchargez notre appli et profitez de l'expertise de l'élite
                  des professionnels en énergies renouvelables pour un entretien
                  paisible de vos équipements !
                </p>
                <div className="banner-btns">
                  <a
                    href="#"
                    className="leBonTech-a item-btn btn-ghost btn-dark"
                  >

                    Télécharger l'application
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img
                  src={phone}
                  alt="Illustration application LeBonTechnicien"
                />
              </div>
            </div>
          </div>
        </div>
      </Banner>



      <LeBonTechnicien3grid
        bigTitle={CommentCaMarche_data.title}
        subTitle={CommentCaMarche_data.subTitle}
        titles={[
          CommentCaMarche_data.items[0].heading,
          CommentCaMarche_data.items[1].heading,
          CommentCaMarche_data.items[2].heading,
        ]}
        descriptions={[
          CommentCaMarche_data.items[0].description,
          CommentCaMarche_data.items[1].description,
          CommentCaMarche_data.items[2].description,
        ]}
        icons={[icon1, icon2, icon3]}
      />

      <LeBonTechnicien2grid
        title={LebonTechnicien_data.section1.title}
        subtitle={LebonTechnicien_data.section1.subtitle}
        paragraph={LebonTechnicien_data.section1.paragraph}
        imgSrc={Phone2}
      />

      <LeBonTechnicien2gridLeft
        title={LebonTechnicien_data.section2.title}
        subtitle={LebonTechnicien_data.section2.subtitle}
        paragraph={LebonTechnicien_data.section2.paragraph}
        imgSrc={Phone3}
      />

      <LeBonTechnicien3grid
        bigTitle={Nos_prestations.title}
        subTitle={Nos_prestations.subTitle}
        titles={[
          Nos_prestations.items[0].heading,
          Nos_prestations.items[1].heading,
          Nos_prestations.items[2].heading,
        ]}
        descriptions={[
          Nos_prestations.items[0].description,
          Nos_prestations.items[1].description,
          Nos_prestations.items[2].description,
        ]}
        icons={[icon4, icon5, icon6]}
      />

      <PartnerBanner/>

      <LeBonTechnicien3grid
        bigTitle={Nos_valeur.title}
        subTitle={Nos_valeur.subTitle}
        titles={[
          Nos_valeur.items[0].heading,
          Nos_valeur.items[1].heading,
          Nos_valeur.items[2].heading,
        ]}
        descriptions={[
          Nos_valeur.items[0].description,
          Nos_valeur.items[1].description,
          Nos_valeur.items[2].description,
        ]}
        icons={[icon8, icon7, icon9]}
      />
    </>
  );
};

export default HomePage;
