import React, { useEffect, useState } from "react";
import emailPic from "../../../Assets/Hands.jpg";
import { useParams } from "react-router-dom";

function AccountValidation() {
  const { id, confirm } = useParams();
  const [confirmed, setConfirmed] = useState("account confirmed successfuly");
  const apiUrl = 'https://lebontechnicien.net'

  const handleEmailConfirm = async () => {
    const response = await fetch(
      `${apiUrl}/tech/confirm/${id}/${confirm}`,
      {
        method: "GET",
      }
    );
    if (response.ok) {
      const data = await response.json();
      console.log(data);
    } else {
      console.error(
        `Failed to confirm email: ${response.status} ${response.statusText}`
      );
    }
  };

  const handleClick = async () => {
    handleEmailConfirm();
    window.location.href = "/connexion";
  };


  /*useEffect(() => {
    const handleEmailConfirm = async () => {
      try {
        const response = await fetch(`${apiUrl}tech/confirm/${id}/${confirm}`, {
          method: "GET"
        });
  
        if (response.ok) {
          const data = await response.text();
          setConfirmed(data); // update the state with the response message
        } else {
          const errorText = await response.text();
          console.error(`Failed to confirm email: ${response.status} ${errorText}`);
        }
      } catch (error) {
        console.error(`Error occurred while confirming email: ${error}`);
      }
    };
  
    handleEmailConfirm();
  }, []);*/


  //Fetches all the interventions
  useEffect(() => {
    fetch(`${apiUrl}/tech/confirm/${id}/${confirm}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setConfirmed(data);
        console.log('confirmed', confirmed)
      })
      .catch((error) => console.error(error));
  }, []);


  return (
    <div className="flex flex-col items-center justify-center h-screen bg-transparent w-[70%] ml-auto mr-auto">
      <img
        className="w-full lg:w-1/2 mb-4"
        src={emailPic}
        alt="email confirm"
      />
      <h2 className="text-3xl font-medium text-gray-700 text-center">
        Bienvenue parmi nous !{" "}
      </h2>
      {confirmed === "User already confirmed" ? (
        <p className="mt-4 text-lg text-gray-700 text-center">
          Votre compte est déjà validé
        </p>
      ) : 
      confirmed === "account confirmed successfuly" ? (
        <p className="mt-4 text-lg text-gray-700 text-center">
          Votre compte est bien validé, veuillez vous connecter pour compléter
          votre profil. en dessous un bouton connexion avec un lien vers la page
          de connexion
        </p>
      ) :     
      (
        <p className="mt-4 text-lg text-gray-700 text-center">

        </p>
      )}

      <button
        className=" mt-12 submit-button group relative flex  justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2"
        onClick={handleClick}
      >
        Se connecter
      </button>
    </div>
  );
}

export default AccountValidation;
