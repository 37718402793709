import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useParams } from "react-router";
import { ReturnButton } from "../../../Components";

const UserClaimsDetail = () => {
  const [history, setHistory] = useState([]);
  const [intervention, setIntervention] = useState([]);
  const [nomtec, setNomTec] = useState("");
  const [userIds, setUserIds] = useState("");
  const [prenomtec, setPrenomTec] = useState("");
  const [error, setError] = useState("");
  const [product, setProduct] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pendingList, setPendingList] = useState("");
  const { userType, nom, prenom } = useStateContext();
  const { id } = useParams();
  const apiUrl = 'https://lebontechnicien.net'

  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => setHistory(data))
      .catch((error) => console.error(error));
  }, []);

  const selectedHistory = history.find((h) => h._id === id);
  const date = new Date(selectedHistory?.created_at);
  const Product = selectedHistory?.Pid;
  const userId = selectedHistory?.userId;
  const Intervention = selectedHistory?.selectedID;
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  //gets the intervention
  useEffect(() => {
    const getInterventionData = async () => {
      if (!history) {
        // Wait for history to be set
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (history) {
              clearInterval(intervalId);
              resolve();
            }
          }, 100);
        });
      }
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/request/history`, {
          method: "GET",
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }

        // Filter interventions based on _id === Intervention
        const filteredInterventions = data.filter(
          (interventions) => interventions._id === Intervention
        );
        console.log("intervention data1:", filteredInterventions);
        // Get the client_accept field from the first filtered intervention
        const clientAccept =
          filteredInterventions.length > 0
            ? filteredInterventions[0].client_accept
            : null;

        setUserIds(clientAccept);
        // Use the filteredInterventions as needed
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getInterventionData();
  }, [Intervention, User]);

  useEffect(() => {
    console.log("intervention data2:", intervention);
    console.log("userIds data2:", userIds);
  }, [intervention]);

  const User = intervention?.client_accept;
  console.log("userrrrr", User);

  //gets the user
  useEffect(() => {
    const getUserData = async () => {
      if (!intervention) {
        // Wait for history to be set
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (intervention) {
              clearInterval(intervalId);
              resolve();
            }
          }, 100);
        });
      }
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            id: userIds,
          }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        setNomTec(data.nom);
        setPrenomTec(data.prenom);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, [intervention, userIds]);

  function translateMonth(month) {
    const translations = {
      Jan: "Janv",
      Feb: "Fev",
      Mar: "Mar",
      Apr: "Avr",
      May: "Mai",
      Jun: "Juin",
      Jul: "Juil",
      Aug: "Août",
      Sep: "Sept",
      Oct: "Oct",
      Nov: "Nov",
      Dec: "Dec",
    };
    const uppercaseMonth = month.toUpperCase();
    return translations[uppercaseMonth];
  }

  // Translate the month name
  const translatedMonth = translateMonth(month);

  const formData = {
    id: userId,
  };

  //user_product
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        if (!history.length) return; // wait until history is updated
        const res = await fetch(
          `${apiUrl}/prods/user_product`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify({ ...formData, history }),
          }
        );

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        const filteredData = data.filter((item) => item._id === Product); // Filter the data array to only include items whose _id matches the specified id
        setPendingList(filteredData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, [history, Product]);

  const handleGetProduct = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }

    const response = await fetch(
      `${apiUrl}/prods/get_product`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id: Product }),
      }
    );
    const data = await response.json();
    setProduct(data);
  };

  useEffect(() => {
    handleGetProduct();
  }, [history, Product]);

  // Format the date as desired (e.g. "16 Fev 2023")
  const formattedDate = `${day} ${month} ${year}`;

  return (
    <>
      <ReturnButton/>
      {selectedHistory && (
        <div className="overflow-hidden bg-transparent shadow sm:rounded-lg max-w-screen-xl ml-auto mr-auto mt-10 mb-10">
          <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto"></div>
          {product && (
            <>
              <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto"></div>
              <div className="flex justify-around mt-6">
                {product.photoDuProduit &&
                  product.photoDuProduit !== "null" && (
                    <img
                      src={`${apiUrl}/${product.photoDuProduit}`}
                      alt={product._id}
                      className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                    />
                  )}
                <img
                  src={`${apiUrl}/${product.QR}`}
                  alt={product._id}
                  className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                />
              </div>
              <>
            <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
              Détails de la réclamation:
            </h3>
            <div className="border-t border-gray-200 w-[92%] ml-auto mr-auto mb-10">
              <dl>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className=" mt-[17px] text-sm font-medium text-black">
                  Date d'envoie:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {formattedDate}
                    </dd>
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-black">Message:</dt>
                  <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {selectedHistory.claime}
                    </dd>
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className=" mt-[17px] text-sm font-medium text-black">
                    Technicien:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {nomtec} {prenomtec}
                    </dd>
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-black">Statut:</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {(() => {
                      switch (selectedHistory.status) {
                        case "Pending":
                          return "En cours";
                        case "closed":
                          return "Cloturé";
                        case "closed_tech":
                          return "Cloturé par technicien";
                        case "claim":
                          return "Réclamation";
                        case "Denied_payment":
                          return "Devis non accepté";
                        case "Accepted_payment":
                          return "Devis accepté";
                        case "Accepted":
                          return "Accepté par client";
                        case "solved":
                          return "Résolu";
                        case "unsolved":
                          return "Non résolu";
                        default:
                          return selectedHistory.status;
                      }
                    })()}
                  </dd>
                </div>
              </dl>
            </div>
          </>
              <>
              <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                Détails de l'appareil:
              </h3>
              <div className="border-t border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black">
                      Type d'appareil
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {product.typeDuBien}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="  text-sm font-medium text-black">
                      Mois et année d'installation:
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {product.moisInstallation} /{" "}
                      {product.annéeInstallation
                        ? product.annéeInstallation
                        : product.anneeInstallation}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black">Marque:</dt>

                    <dd className=" text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        {product.marque}
                      </dd>
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black">
                      Référence
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {product.reference
                        ? product.reference
                        : product.référence}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black">Maintenu</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {product.entretenu}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-black">
                      Puissance
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {product.puissance}
                    </dd>
                  </div>
                </dl>
              </div>
              </>
            </>
          )}

        </div>
      )}
    </>
  );
};

export default UserClaimsDetail;
