import React, { useState, useEffect } from "react";
import LogoBig from "../../Assets/media/logo-lebontechnicien.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const burgerMenu = document.getElementById("burger-menu");
    const overlay = document.getElementById("menu");

    const handleClick = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    burgerMenu.addEventListener("click", handleClick);

    return () => burgerMenu.removeEventListener("click", handleClick);
  }, [isMenuOpen]);

  return (
    <header>
      <div className="logo-header">
        <a className="leBonTech-a" href="http://lebontechnicien.fr/">
          <img src={LogoBig} alt="Logo LeBonTechnicien.fr" />
        </a>
      </div>
      <div style={{ clear: "both", display: "none" }}></div>
      <div id="menu" className={`main-menu ${isMenuOpen ? "overlay" : ""}`}>
        <div id="main-nav" className="main-nav">
          <nav className="lebonTech-nav">
            <ul>
              <li>
                <a className="leBonTech-a" href="/">
                  Accueil
                </a>
              </li>
              <li>
                <a className="leBonTech-a" href="/telechargement">
                Télécharger
                </a>
              </li>
              <li>
                <a className="leBonTech-a" href="/guides">
                Guides
                </a>
              </li>             
            </ul>
          </nav>
        </div>
        <div id="login-nav" className="login-nav">
          <nav>
            <ul>
              <li className="btn-inscription">
                <a className="leBonTech-a" href="/inscription">
                  Inscription
                </a>
              </li>
              <li className="btn-connection">
                <a className="leBonTech-a" href="/connexion">
                  Connexion
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
      <div id="burger-menu" className={`${isMenuOpen ? "close" : ""}`}>
        <span></span>
        {isMenuOpen && <span></span>}
      </div>
      <div style={{ clear: "both" }}></div>
    </header>
  );
};

export default Header;