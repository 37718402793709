import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
//ls
//import useFetch from 'react-fetch-hook'
import { BreadCrumb, BrowseGrid, GridDetails } from "../Components";
import { Départements, TechniciansGrid, UsersGrid } from "../Constants/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { IoBarChartOutline, IoList } from "react-icons/io5";

import Bois from "../Assets/Bois.svg";
import Fuel from "../Assets/Fuel.svg";
import Gaze from "../Assets/Gaze.svg";
import IRVE from "../Assets/IRVE.svg";
import PAC from "../Assets/PAC.svg";
import Solaire from "../Assets/Solaire.svg";
import VMC from "../Assets/VMC.svg";

const SearchGrid = () => {
  const [data, setData] = useState([]);
  const [dataUser, setDataUser] = useState();
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [filterQuery, setFilterQuery] = useState("");
  const { userType } = useStateContext();
  const [selectedType, setSelectedType] = useState(["All"]);
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [inputValue, setInputValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPac, setShowPac] = useState(false);
  const [showBois, setShowBois] = useState(false);
  const [showSolaire, setShowSolaire] = useState(false);
  const [showGaze, setShowGaze] = useState(false);
  const [showFule, setShowFuel] = useState(false);
  const [showVMC, setShowVMC] = useState(false);
  const [showIRVE, setShowIRVE] = useState(false);

  const apiUrl = 'https://lebontechnicien.net'

  console.log("showBois", showBois);

  //Filters
  const types = [
    "PAC AIR-AIR/ PAC AIR-EAU/ PAC EAU-EAU/ PAC Ballon Thermodynamique/ BOIS Poêle à Granulés/ BOIS Chaudière à Granulés/ Solaire CESI/ Solaire SSC/ Solaire Chauffe eau solaire individuelle (Cesi)/ Solaire Panneaux photovoltaique/ Gaz/ Fuel/ VMC/ IRVE",
  ];
  const zones = [
    "Auvergne-Rhône-Alpes, Bourgogne-Franche-Comté, Bretagne, Centre-Val de Loire, Corse, Grand Est, Hauts-de-France, Île-de-France, Normandie, Nouvelle-Aquitaine, Occitanie, Pays de la Loire, Provence-Alpes-Côte d'Azur",
  ];



  const uniqueTypes = types[0].split("/").map((t) => t.trim().toLowerCase());
  console.log("uniqueTypes", uniqueTypes);
  const uniqueZones = Départements.slice(1).map((dept) =>
    dept.value.split(",")[0].trim().toLowerCase()
  );

  //Fetch Request
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      headers: { "Content-Type": "application/json", 
      Authorization: `Bearer ${sessionStorage.getItem("token")}`},
    })
      .then((response) => response.json())
      .then((data) =>
        setDataUser(
          data.filter(
            (item) =>
              item.type === "Demande d'intervention" &&
              item.status === "Pending" && 
              !item.accepted_by.includes(sessionStorage.getItem("id"))&& 
              (!item.client === "Pro" || !item.client)
          )
        )
      )
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type: "Tech" }),
        });
        const users = await response.json();
        const filteredUsers = users.filter(user => user.status === "Confirmed");
        setData(filteredUsers);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [inputValue]);

  //Handle Filters
  const filteredContacts = useMemo(() => {
    const dataArray = Array.isArray(dataUser) ? dataUser : [];
    const filteredData = (userType === "User" ? data : dataArray).filter(
      (contact) => {
        const uniqueType =
          userType === "User"
            ? contact.domaine
              ? contact.domaine.toLowerCase().trim()
              : ""
            : contact.typeDeProjet
            ? contact.typeDeProjet.toLowerCase().trim()
            : "";

        let uniqueZone = "";
        if (userType === "User") {
          let contactZones = [];
          if (contact.zone) {
            if (Array.isArray(contact.zone)) {
              contactZones = contact.zone.map((zone) =>
                zone.toLowerCase().trim()
              );
            } else {
              contactZones = contact.zone
                .split(",")
                .map((zone) => zone.trim().toLowerCase());
            }
          }
          uniqueZone = contactZones.includes(
            selectedLocation.toLowerCase().trim()
          );
        } else {
          uniqueZone = contact.zone;
          if (contact.zone) {
            let contactZones = [];
            if (Array.isArray(contact.zone)) {
              contactZones = contact.zone.map((zone) =>
                zone.join(",").toLowerCase().trim()
              );
            } else {
              contactZones = contact.zone
                .split(",")
                .map((zone) => zone.trim().toLowerCase());
            }
            uniqueZone = contactZones.includes(
              selectedLocation.toLowerCase().trim()
            );
          }
        }

        if (selectedType.includes("All") && selectedLocation === "All") {
          return true;
        }

        if (!selectedType.includes("All") && selectedLocation === "All") {
          return userType === "User"
            ? selectedType.includes(uniqueType.toLowerCase().trim())
            : selectedType.includes(uniqueType.toLowerCase().trim());
        }

        if (selectedType.includes("All") && selectedLocation !== "All") {
          return uniqueZone;
        }

        return (
          (userType === "User"
            ? selectedType.includes(uniqueType.toLowerCase().trim())
            : selectedType.includes(uniqueType.toLowerCase().trim())) &&
          uniqueZone
        );
      }
    );
    return filteredData;
  }, [selectedType, selectedLocation, data, dataUser, userType]);

  useEffect(() => {
    const filteredData = filteredContacts.filter((contact) => {
      const fullName = `${contact.nom} ${contact.prénom}`;

      if (!filterQuery) {
        return true;
      }

      // if it's just one letter, return all names that start with it
      if (filterQuery.length === 1) {
        const firstLetter = fullName.charAt(0).toLowerCase();
        return firstLetter === filterQuery.toLowerCase();
      } else {
        return fullName.toLowerCase().includes(filterQuery.toLowerCase());
      }
    });

    const sortedData = filteredData
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((contact) => {
        const date = new Date(contact.created_at);
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        const formattedDate = date.toLocaleDateString("fr-FR", options);
        return { ...contact, created_at: formattedDate };
      });
    setContactList(sortedData);
  }, [filterQuery, filteredContacts]);

  return (
    <div className="bg-gray-50">
      
      {userType === "User" ? (
        <BreadCrumb From={"Compte"} To={"Liste des techniciens"} hrefFrom={"/tableau-de-bord"} style={{marginTop: '10px'}}/>
      ) : (
        <BreadCrumb To={"Tableau De Bord"} hrefFrom={""} style={{marginTop: '10px'}}/>
      )}

      <div className="w-[92%] bg-white rounded-md ml-auto mr-auto">
        <div className="flex ">
          <div>
            <h2 className="ml-4 pt-4 text-gray-700 font-bold">
              {" "}
              Domaine de compétence:
            </h2>
            <div className="flex flex-wrap justify-start ml-auto mr-auto bg-white rounded-md">
              <div className="p-4">
                <label className="text-gray-700 font-bold mr-2">
                  <input
                    className="className="
                    text-gray-700
                    font-bold
                    mr-2
                    type="checkbox"
                    value="All"
                    checked={selectedType.includes("All")}
                    onChange={(e) =>
                      e.target.checked
                        ? setSelectedType(["All", ...uniqueTypes])
                        : setSelectedType('')
                    }
                  />
                  Tous
                </label>
              </div>
              <div className="p-4">
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={() => setShowPac(!showPac)}
                >
                  <span className="text-gray-700 font-bold mr-2">PAC</span>
                  {showPac ? (
                    <img className="h-6 w-6" src={PAC} alt="PACicon" />
                  ) : (
                    <img className="h-6 w-6" src={PAC} alt="PACicon" />
                  )}
                </div>
                {showPac && (
                  <>
                    {uniqueTypes
                      .filter((type) => type.includes("pac"))
                      .map((type) => (
                        <div key={type} className="p-2">
                          <label className="inline-flex items-center">
                            <input
                              className=" text-gray-600"
                              type="checkbox"
                              value={type}
                              checked={selectedType.includes(type)}
                              onChange={(e) => {
                                const checkedType = e.target.value;
                                setSelectedType((prevTypes) =>
                                  e.target.checked
                                    ? [...prevTypes, checkedType]
                                    : prevTypes.filter(
                                        (type) => type !== checkedType
                                      )
                                );
                              }}
                            />
                            <span className="ml-2 text-gray-700">{type}</span>
                          </label>
                        </div>
                      ))}
                  </>
                )}
              </div>
              <div className="p-4">
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={() => setShowBois(!showBois)}
                >
                  <span className="text-gray-700 font-bold mr-2">Bois</span>
                  {showBois ? (
                    <img className="h-6 w-6" src={Bois} alt="Boisicon" />
                  ) : (
                    <img className="h-6 w-6" src={Bois} alt="Boisicon" />
                  )}
                </div>
                {showBois && (
                  <>
                    <div>
                      {uniqueTypes
                        .filter((type) => type.includes("bois"))
                        .map((type) => (
                          <div key={type} className="p-2">
                            <label className="inline-flex items-center">
                              <input
                                className=" text-gray-600"
                                type="checkbox"
                                value={type}
                                checked={selectedType.includes(type)}
                                onChange={(e) => {
                                  const checkedType = e.target.value;
                                  setSelectedType((prevTypes) =>
                                    e.target.checked
                                      ? [...prevTypes, checkedType]
                                      : prevTypes.filter(
                                          (type) => type !== checkedType
                                        )
                                  );
                                }}
                              />
                              <span className="ml-2 text-gray-700">{type}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
              <div className="p-4">
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={() => setShowSolaire(!showSolaire)}
                >
                  <span className="text-gray-700 font-bold mr-2">Solaire</span>
                  {showSolaire ? (
                    <img className="h-6 w-6" src={Solaire} alt="Solaireicon" />
                  ) : (
                    <img className="h-6 w-6" src={Solaire} alt="Solaireicon" />
                  )}
                </div>
                {showSolaire && (
                  <>
                    <div>
                      {uniqueTypes
                        .filter(
                          (type) => type.includes("solaire") || type === "cesi"
                        )
                        .map((type) => (
                          <div key={type} className="p-2">
                            <label className="inline-flex items-center">
                              <input
                                className=" text-gray-600"
                                type="checkbox"
                                value={type}
                                checked={selectedType.includes(type)}
                                onChange={(e) => {
                                  const checkedType = e.target.value;
                                  setSelectedType((prevTypes) =>
                                    e.target.checked
                                      ? [...prevTypes, checkedType]
                                      : prevTypes.filter(
                                          (type) => type !== checkedType
                                        )
                                  );
                                }}
                              />
                              <span className="ml-2 text-gray-700">{type}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
              <div className="p-4">
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={() => setShowGaze(!showGaze)}
                >
                  <span className="text-gray-700 font-bold mr-2">Gaz</span>
                  {showGaze ? (
                    <img className="h-6 w-6" src={Gaze} alt="Gazeicon" />
                  ) : (
                    <img className="h-6 w-6" src={Gaze} alt="Gazeicon" />
                  )}
                </div>
                {showGaze && (
                  <>
                    <div>
                      {uniqueTypes
                        .filter((type) => type.includes("gaz"))
                        .map((type) => (
                          <div key={type} className="p-2">
                            <label className="inline-flex items-center">
                              <input
                                className=" text-gray-600"
                                type="checkbox"
                                value={type}
                                checked={selectedType.includes(type)}
                                onChange={(e) => {
                                  const checkedType = e.target.value;
                                  setSelectedType((prevTypes) =>
                                    e.target.checked
                                      ? [...prevTypes, checkedType]
                                      : prevTypes.filter(
                                          (type) => type !== checkedType
                                        )
                                  );
                                }}
                              />
                              <span className="ml-2 text-gray-700">{type}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
              <div className="p-4">
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={() => setShowFuel(!showFule)}
                >
                  <span className="text-gray-700 font-bold mr-2">Fuel</span>
                  {showFule ? (
                    <img className="h-6 w-6" src={Fuel} alt="Fuelicon" />
                  ) : (
                    <img className="h-6 w-6" src={Fuel} alt="Fuelicon" />
                  )}
                </div>
                {showFule && (
                  <>
                    <div>
                      {uniqueTypes
                        .filter((type) => type.includes("fuel"))
                        .map((type) => (
                          <div key={type} className="p-2">
                            <label className="inline-flex items-center">
                              <input
                                className=" text-gray-600"
                                type="checkbox"
                                value={type}
                                checked={selectedType.includes(type)}
                                onChange={(e) => {
                                  const checkedType = e.target.value;
                                  setSelectedType((prevTypes) =>
                                    e.target.checked
                                      ? [...prevTypes, checkedType]
                                      : prevTypes.filter(
                                          (type) => type !== checkedType
                                        )
                                  );
                                }}
                              />
                              <span className="ml-2 text-gray-700">{type}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
              <div className="p-4">
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={() => setShowVMC(!showVMC)}
                >
                  <span className="text-gray-700 font-bold mr-2">VMC</span>
                  {showVMC ? (
                    <img className="h-6 w-6" src={VMC} alt="VMC" />
                  ) : (
                    <img className="h-6 w-6" src={VMC} alt="VMC" />
                  )}
                </div>
                {showVMC && (
                  <>
                    <div>
                      {uniqueTypes
                        .filter((type) => type.includes("vmc"))
                        .map((type) => (
                          <div key={type} className="p-2">
                            <label className="inline-flex items-center">
                              <input
                                className=" text-gray-600"
                                type="checkbox"
                                value={type}
                                checked={selectedType.includes(type)}
                                onChange={(e) => {
                                  const checkedType = e.target.value;
                                  setSelectedType((prevTypes) =>
                                    e.target.checked
                                      ? [...prevTypes, checkedType]
                                      : prevTypes.filter(
                                          (type) => type !== checkedType
                                        )
                                  );
                                }}
                              />
                              <span className="ml-2 text-gray-700">{type}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
              <div className="p-4">
                <div
                  className="inline-flex items-center cursor-pointer"
                  onClick={() => setShowIRVE(!showIRVE)}
                >
                  <span className="text-gray-700 font-bold mr-2">IRVE</span>
                  {showIRVE ? (
                    <img className="h-6 w-6" src={IRVE} alt="IRVE" />
                  ) : (
                    <img className="h-6 w-6" src={IRVE} alt="IRVE" />
                  )}
                </div>
                {showIRVE && (
                  <>
                    <div>
                      {uniqueTypes
                        .filter((type) => type.includes("irve"))
                        .map((type) => (
                          <div key={type} className="p-2">
                            <label className="inline-flex items-center">
                              <input
                                className=" text-gray-600"
                                type="checkbox"
                                value={type}
                                checked={selectedType.includes(type)}
                                onChange={(e) => {
                                  const checkedType = e.target.value;
                                  setSelectedType((prevTypes) =>
                                    e.target.checked
                                      ? [...prevTypes, checkedType]
                                      : prevTypes.filter(
                                          (type) => type !== checkedType
                                        )
                                  );
                                }}
                              />
                              <span className="ml-2 text-gray-700">{type}</span>
                            </label>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>           
            </div>
          </div>
          <div>
          <h2 className="ml-4 pt-4 text-gray-700 font-bold">
              {" "}
              Zone d'intervention:
            </h2>            
          <div className="">
            <section>
              <div className="flex">
                <select
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                  className="ml-2   p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer"
                >
                  <option value="All">Toutes les zones</option>
                  {uniqueZones.map((location) => (
                    <option key={location} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
              </div>
            </section>
          </div> 
          </div>
        </div>
      </div>

      {/*<div className='w-[92%] flex flex-wrap justify-start ml-auto mr-auto bg-white rounded-md'>

                  <div className="p-4">
                    <label>
                      <input
                        type="checkbox"
                        value="All"
                        checked={selectedType.includes("All")}
                        onChange={(e) =>
                          e.target.checked
                            ? setSelectedType(["All", ...uniqueTypes])
                            : setSelectedType(uniqueTypes)
                        }
                      />
                      Tous les types
                    </label>
                  </div>

                  {uniqueTypes.map((type) => (
                    <div key={type} className="p-4">
                      <label className="inline-flex items-center">
                        <input
                          className=" text-gray-600"
                          type="checkbox"
                          value={type}
                          checked={selectedType.includes(type)}
                          onChange={(e) => {
                            const checkedType = e.target.value;
                            setSelectedType((prevTypes) =>
                              e.target.checked
                                ? [...prevTypes, checkedType]
                                : prevTypes.filter((type) => type !== checkedType)
                            );
                          }}
                        />
                        <span className="ml-2 text-gray-700">{type}</span>
                      </label>
                    </div>
                  ))}

                        </div>
      <div className="flex justify-end w-[92%] ml-auto mr-auto">
        <section>
          <div className="flex">
            <select
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              className="ml-2 mt-6  p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer"
            >
              <option value="All">Toutes les régions</option>
              {uniqueZones.map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </select>
            {/*<div className="relative">
              <button
                className="ml-2 mt-6  p-2 border border-gray-200 py-3 px-2 rounded-lg cursor-pointer bg-white"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                Domaines de compétence
              </button>
              {isMenuOpen && (
                <div className="absolute top-12 right-0 z-10 w-64 mt-2 py-2 bg-white rounded-lg shadow-xl  overflow-y-auto h-[500px]">
                  <div className="p-4 border-b border-gray-200">
                    <label>
                      <input
                        type="checkbox"
                        value="All"
                        checked={selectedType.includes("All")}
                        onChange={(e) =>
                          e.target.checked
                            ? setSelectedType(["All", ...uniqueTypes])
                            : setSelectedType(uniqueTypes)
                        }
                      />
                      Tous les types
                    </label>
                  </div>
                  {uniqueTypes.map((type) => (
                    <div key={type} className="p-4 border-b border-gray-200">
                      <label className="inline-flex items-center">
                        <input
                          className=" text-gray-600"
                          type="checkbox"
                          value={type}
                          checked={selectedType.includes(type)}
                          onChange={(e) => {
                            const checkedType = e.target.value;
                            setSelectedType((prevTypes) =>
                              e.target.checked
                                ? [...prevTypes, checkedType]
                                : prevTypes.filter((type) => type !== checkedType)
                            );
                          }}
                        />
                        <span className="ml-2 text-gray-700">{type}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
                        </div>     
          </div>
        </section>
      </div>  */}
      <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 p-10 md:p-20 lg:p-20 z-[-1]">
        <BrowseGrid
          contactList={contactList}
          setSelectedContact={setSelectedContact}
        />
        {contactList?.length < 1 && <h1>Il n'y a aucune nouvelle intervention pour le moment</h1>}
      </section>
    </div>
  );
};

export default SearchGrid;