import React, { useEffect, useState } from "react";
import { BreadCrumb, CRUDTable, Tabs } from "../../../Components";
import { useStateContext } from "../../../contexts/ContextProvider";
import { IoEyeOutline, IoPencil, IoTrashOutline } from "react-icons/io5";

const UserListPro = () => {
  const [history, setHistory] = useState([]);
  const [techs, setTechs] = useState([]);
  const [pending, setPending] = useState([]);
  const { userType } = useStateContext();
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const apiUrl = "https://lebontechnicien.net";

  //Fetches all the interventions
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(
          data.filter(
            (item) => item.created_by === sessionStorage.getItem("id")
          )
        );
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const fetchData = async (type) => {
      try {
        const response = await fetch(`${apiUrl}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ type }),
        });
        const data = await response.json();

        const pendingLength = data.filter(
          (item) => item.creator === sessionStorage.getItem("id")
        ).length;
        const pendingData = data.filter(
          (item) => item.creator === sessionStorage.getItem("id")
        );

        switch (type) {
          case "under_pro":
            setTechs(pendingData.reverse());
            setPending(pendingData);
            console.log("pending", pending);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData("under_pro"); // Call fetchData with the desired type
  }, []);

  const proRequests = history.filter((item) => item.client === "Pro");
  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const monthName = monthNames[monthIndex];
    return `${day} ${monthName} ${year}`;
  };

  /*const info =
    proRequests.length > 0
      ? proRequests
          .filter(
            (item) =>
              item.type !== "Demande de Réclamation" &&
              item.created_by === sessionStorage.getItem("id")
          )
          .map((item) => {
            return {
              id: item._id,
              Client: item.nom + " " + item.prenom,
              Adresse: item.adresse + " " + item.ville + " " + item.postale,
              Appareil: item?.typeDeProjet + " " + item.marque,
              Téléphone: item.telephone,
            };
          }) // Sort in descending order
      : [];
  const pendingLengthinfo =
    techs.length > 0
      ? techs.map((item) => {
          return {
            id: item._id,
            Client: item.nom + " " + item.prenom,
            Adresse: item.adresse + " " + item.ville + " " + item.postale,
            //Appareil: item?.typeDeProjet+ ' ' +   item.marque,
            Téléphone: item.telephone,
          };
        }) // Sort in descending order
      : [];*/

  // Grouping requests by 'nom' and 'prenom'
  const groupedRequests = proRequests.reduce((grouped, item) => {
    const clientKey = item.clientEmail;

    if (!grouped[clientKey]) {
      grouped[clientKey] = {
        id: item._id,
        Nom: item.nom,
        Prenom: item.prenom,
        Adresse: item.adresse + " " + item.ville + " " + item.postale,
        //Appareil: item?.typeDeProjet + " " + item.marque,
        Téléphone: item.telephone,

      };
    }

    return grouped;
  }, {});

  const info = Object.values(groupedRequests).flat();

  return (
    <>
      <BreadCrumb
        From={""}
        To={"Liste Des Clients"}
        hrefFrom={"/tableau-de-bord"}
      />
      <div className="mt-10 max-w-screen-xl  ml-auto mr-auto">
        <CRUDTable
          data={info}
          //link={`/user-intervention-details-pro/`}
          link={`/client-appareils/`}
          eyeIcon={
            <IoEyeOutline
              style={{ fontSize: "18px", cursor: "pointer", color: "#f8c408" }}
            />
          }
        />
      </div>
    </>
  );
};

export default UserListPro;
