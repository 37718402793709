import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogoBig from "../../../Assets/Logo.svg";
import {
  validateEmail,
  validatePassword,
} from "../../../Components/FormValidation";
import { MdKeyboardArrowDown } from "react-icons/md";
import ReCAPTCHA from "react-google-recaptcha";

const TechnicianRegister = () => {
  const formArray = [1, 2];
  const [formNo, setFormNo] = useState(formArray[0]);

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [photoIdentite, setPhotoIdentite] = useState(null);
  const [password, setPassword] = useState("");

  const [adresse, setAdresse] = useState("");
  const [city, setCity] = useState("");
  const [departement, setDepartement] = useState("");
  const [zip, setZip] = useState("");
  const [telephone, setTelephone] = useState("");
  const [raison, setRaison] = useState("");
  const [assurance, setAssurance] = useState("");
  const [nassurance, setNAssurance] = useState("");
  const [email, setEmail] = useState("");
  const [domaine, setDomaine] = useState([]);
  const [qualification, setQualification] = useState(null);
  const [banque, setBanque] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBIC] = useState("");
  const [vehicule, setVehicule] = useState(null);
  const [politique, setPolitique] = useState("");
  const [condition, setCondition] = useState("");
  const [actualités, setactualités] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordCheckError, setPasswordCheckError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formeJuridique, setFormeJuridique] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [registerError, setRegisterError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const apiUrl = 'https://lebontechnicien.net'

  const handleNom = (event) => {
    setNom(event.target.value);
  };

  const handlePasswordCheck = (event) => {
    setPasswordCheck(event.target.value);
    if (password !== event.target.value) {
      setPasswordCheckError("Les mots de passe ne correspondent pas.");
    } else {
      setPasswordCheckError(null);
    }
  };

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setEmailError("Adresse e-mail invalide");
    } else {
      setEmailError(null);
    }
  };

  const handleFormeJuridique = (event) => {
    setFormeJuridique(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    if (!validatePassword(event.target.value)) {
      setPasswordError(
        "Le mot de passe doit comporter au moins 10 caractères et inclure une combinaison de lettres majuscules et minuscules, de chiffres et de caractères spéciaux."
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleBanque = (event) => {
    setBanque(event.target.value);
  };

  const handleIban = (event) => {
    setIban(event.target.value);
  };

  const handleBic = (event) => {
    setBIC(event.target.value);
  };

  const handleVehicule = (event) => {
    setVehicule(event.target.value);
  };

  const handlePrénom = (event) => {
    setPrenom(event.target.value);
  };

  const handleRaison = (event) => {
    setRaison(event.target.value);
  };

  const handleAssurance = (event) => {
    setAssurance(event.target.value);
  };

  const handleNAssurance = (event) => {
    setNAssurance(event.target.value);
  };

  const handleAdresse = (event) => {
    setAdresse(event.target.value);
  };

  const handleCity = (event) => {
    setCity(event.target.value);
  };

  const handleDepartement = (event) => {
    setDepartement(event.target.value);
  };

  const handleTelephone = (event) => {
    setTelephone(event.target.value);
  };

  const handleZip = (event) => {
    setZip(event.target.value);
  };

  const handlequalification = (event) => {
    setQualification(event.target.files[0]);
  };

  const handlePhotoIdentite = (event) => {
    setPhotoIdentite(event.target.files[0]);
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleDomaine = (event) => {
    const { value } = event.target;
    if (domaine.includes(value)) {
      setDomaine(domaine.filter((c) => c !== value));
    } else {
      setDomaine([...domaine, value]);
    }
  };

  const handlePolitique = (event) => {
    const { value } = event.target;
    if (politique.includes(value)) {
      setPolitique(politique.filter((c) => c !== value));
    } else {
      setPolitique([...politique, value]);
    }
  };

  const handleCondition = (event) => {
    const { value } = event.target;
    if (condition.includes(value)) {
      setCondition(condition.filter((c) => c !== value));
    } else {
      setCondition([...condition, value]);
    }
  };

  const handleActualité = (event) => {
    setactualités(event.target.value);
  };

  const OptionWithIcon = () => {
    return (
      <>
        sélectionner la zone d'intervention
        <MdKeyboardArrowDown />
      </>
    );
  };

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    /*if (!recaptchaValue) {
      alert("Please verify that you're a human");
      return;
    }*/
    const params = new URLSearchParams();

    params.append("photoIdentite", photoIdentite);
    params.append("nom", nom);
    params.append("prenom", prenom);
    params.append("adresse", adresse);
    params.append("city", city);
    params.append("zip", zip);
    params.append("departement", departement);
    params.append("telephone", telephone);
    params.append("email", email);
    params.append("password", password);
    params.append("raison", raison);
    params.append("assurance", assurance);
    params.append("domaine", domaine);
    params.append("qualification", qualification);
    params.append("banque", banque);
    params.append("vehicule", vehicule);
    params.append("politique", politique);
    params.append("condition", condition);
    params.append("actualités", actualités);
    params.append("recaptcha", recaptchaValue);
    params.append("profile", "Unfinished");
    params.append("ProTeam", "non");
    params.append("ProTech", "non");


    fetch(`${apiUrl}/tech/register`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: params.toString(),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data !== "Email already in use") {
          console.log("success");
          window.location.reload();
          window.location.href = "/confirmation";
        } else {
          if (data === "Email already in use") {
            setRegisterError("Cette adresse e-mail est déjà utilisée");
          } else {
            setError(data);
            console.log(error);
          }
        }
      });
  };

  const next = () => {
    let missingInputs = [];
    if (!nom) {
      missingInputs.push("nom");
    }
    if (!prenom) {
      missingInputs.push("prenom");
    }
    if (!photoIdentite) {
      missingInputs.push("photo d'identité");
    }
    if (!email) {
      missingInputs.push("email");
    }
    if (formNo === 1 && nom && prenom && photoIdentite && email) {
      setFormNo(formNo + 1);
    } else {
      let errorMessage =
        `Veuillez remplir tous les champs de saisie:\n` +
        missingInputs.join(", ");

      toast.error(errorMessage);
    }
  };

  const finalSubmit = () => {
    let missingInputs = [];
    if (!email) {
      missingInputs.push("email");
    }
    if (!password) {
      missingInputs.push("Mot De Passe");
    }
    if (politique.length === 0) {
      missingInputs.push("politique");
    }
    if (condition.length === 0) {
      missingInputs.push("condition");
    }
    if (email && password && politique.length > 0 && condition.length > 0) {
      //toast.success("formulaire soumis avec succès");
      handleSubmit();
    } else {
      let errorMessage =
        "Veuillez remplir tous les champs de saisie: " +
        missingInputs.join(", ");
      toast.error(errorMessage);
    }
  };

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 login-wrapper">
        <ToastContainer />
        <div className="w-full max-w-md space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src={LogoBig} alt="Logo" />
            <h2 className="mt-6 text-center font-bold tracking-tight authentifciation-text ">
              Bonjour. Entrez vos informations d'identification pour créer votre
              compte
            </h2>
          </div>
          <div>
            <div>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md ">
                <div>
                  <label
                    htmlFor="email"
                    className="margin-top ml-3 block text-sm font-medium text-gray-700"
                  >
                    Email <span className="text-red-500"> * </span>
                  </label>
                  <input
                    value={email}
                    onChange={handleEmail}
                    id="email-address"
                    name="email-address"
                    type="email"
                    autoComplete="email"
                    required
                    className="relative p-16 block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Email"
                    style={{ marginTop: "16px" }}
                  />
                  {emailError && email.length > 0 && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="margin-top ml-3 block text-sm font-medium text-gray-700"
                  >
                    mot de passe <span className="text-red-500"> * </span>
                  </label>
                  <input
                    value={password}
                    onChange={handlePassword}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="relative p-16 block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Mot de passe"
                  />
                  {passwordError && password.length > 0 && (
                    <div style={{ color: "red" }}>{passwordError}</div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="margin-top ml-3 block text-sm font-medium text-gray-700"
                  >
                    Retaper le mot de passe{" "}
                    <span className="text-red-500"> * </span>
                  </label>
                  <input
                    value={passwordCheck}
                    onChange={handlePasswordCheck}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="relative p-16 block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Retaper le mot de passe"
                  />
                  {passwordCheckError && passwordCheck.length > 0 && (
                    <div style={{ color: "red" }}>{passwordCheckError}</div>
                  )}
                </div>
                <div className="mt-4 space-y-4 ">
                  <div className="flex items-center margin-top">
                    <input
                      id="politique-de-confidentialité"
                      name="politique"
                      type="checkbox"
                      required="required"
                      value="politique de confidentialité"
                      checked={politique.includes(
                        "politique de confidentialité"
                      )}
                      onChange={handlePolitique}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="push-email"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      J'ai lu et accepté la Politique de confidentialité{" "}
                      <span className="text-red-500"> * </span>
                      <a
                        href="/politique-confidentialite"
                        className="text-gray-700"
                      >
                        (voir la politique de confidentialité)
                      </a>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="condition-générale-de-vente"
                      name="condition"
                      required="required"
                      type="checkbox"
                      value="condition générale de vente"
                      checked={condition.includes(
                        "condition générale de vente"
                      )}
                      onChange={handleCondition}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor="push-nothing"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                     J'ai lu et accepté les conditions générales de vente{" "}
                      <span className="text-red-500"> * </span>
                      <a href="/cgv" className="text-gray-700">
                        (voir les CGV)
                      </a>
                    </label>
                  </div>
                </div>

                <fieldset
                  className="mb-6 margin-top"
                  style={{ marginTop: "16px" }}
                >
                  <legend className="contents text-sm font-medium text-gray-600 margin-top">
                    Recevoir les actualités des mise à jours de l'application
                  </legend>
                  {/*<p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>*/}
                  <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                      <input
                        id="oui"
                        name="actualités"
                        type="radio"
                        value="oui"
                        onChange={handleActualité}
                        checked={actualités === "oui"}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor="oui"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Oui
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="non"
                        name="actualités"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        value="non"
                        onChange={handleActualité}
                        checked={actualités === "non"}
                      />
                      <label
                        htmlFor="non"
                        className="ml-3 block text-sm font-medium text-gray-700"
                      >
                        Non
                      </label>
                    </div>
                  </div>
                </fieldset>
                {registerError && registerError.length > 0 && (
                  <p style={{ color: "red" }}>{registerError}</p>
                )}
              </div>
              <div className="mt-4 flex justify-center items-center">
                <ReCAPTCHA
                  sitekey="6Lf7zDcmAAAAACbjss7kOwFrucc3Z9ou3T8Yjk6T"
                  onChange={handleRecaptcha}
                />

                <button
                  onClick={finalSubmit}
                  className="submit-button px-3 py-2 text-lg rounded-md w-full text-white bg-blue-500"
                >
                  S'inscrire
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnicianRegister;
