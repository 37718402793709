import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import {
  FilterButtons,
  FilterModal,
  ModificationModal,
  PageHeader,
  ReturnButton,
} from "../../../Components";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useParams } from "react-router";

const TechProTeamDetails = () => {

  const [map, setMap] = useState(null);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModificationModalOpen, setIsModificationModalOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [parcour, setParcour] = useState(false);
  const [initialTasks, setInitialTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [waypoints, setWaypoints] = useState([]);
  const apiKey = "AIzaSyCELeMswzBhglht4exvL17mvrCLJgM4_hQ";
  const geocoder = new window.google.maps.Geocoder();
  const [waypointData, setWaypointData] = useState([]);
  const departureDate = sessionStorage.getItem("departureDate");
  const returnDate = sessionStorage.getItem("returnDate");
  const [departureDateFilter, setDepartureDateFilter] = useState(null);
  const [returnDateFilter, setReturnDateFilter] = useState(null);
  //const [departureDate, setDepartureDate] = useState(sessionStorage.getItem('departureDate'));
  //const [returnDate, setReturnDate] = useState(sessionStorage.getItem('returnDate'));
  const departureAddress = sessionStorage.getItem("departureAddress");
  const selectedTypes = sessionStorage.getItem("selectedType");
  const [taskToEdit, setTaskToEdit] = useState(null);
  const timeSlots = ["08:00", "14:00"];
  const [plannedTasks, setPlannedTasks] = useState([]);
  const [busyDays, setBusyDays] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const { id } = useParams();
  const [phrase, setPhrase] = useState([]);
  const [validatedTasks, setValidatedTasks] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [filteredPlanningTasks, setFilteredPlanningTasks] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [requestSentSuccessfully, setRequestSentSuccessfully] = useState(false);
  const [dataFromChild, setDataFromChild] = useState(null);
  const [toggleEffect, setToggleEffect] = useState(false);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [removed, setRemoved] = useState([]);



  // Function to handle changes in sessionStorage
 /* const handleStorageChange = () => {
    setDepartureDate(sessionStorage.getItem('departureDate'));
    setReturnDate(sessionStorage.getItem('returnDate'));
  };


  console.log("Dep", departureDate)
  console.log("Ret", returnDate)

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener('storage', handleStorageChange);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); */

  useEffect(() => {
    // Check if dataFromChild is not null
    if (dataFromChild !== null) {
      // Perform the fetchPlannedData action
      fetchPlannedData();
    }
  }, [dataFromChild]);

  // Callback function to receive data from the child
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    setToggleEffect((prevToggle) => !prevToggle);
  };


  const handleDataFromChildFilter = (data) => {
    setReturnDateFilter(data.returnDateFilter)
    setDepartureDateFilter(data.departureDateFilter)
  };
  

  const apiUrl = 'https://lebontechnicien.net'

  // Step 1: Map through data to get unique months and years
  const uniqueMonthsYears = Array.from(
    new Set(
      calendars?.map((item) =>
        new Date(item.departureDate).toLocaleString("en-us", {
          month: "long",
          year: "numeric",
        })
      )
    )
  );

  // Step 3: Handle Click on Month/Year Button
  const handleMonthYearClick = (monthYear) => {
    setSelectedMonthYear(monthYear);

    // Step 2: Filter tasks based on selected month and year
    const filtered = calendars.filter(
      (item) =>
        new Date(item.departureDate).toLocaleString("en-us", {
          month: "long",
          year: "numeric",
        }) === monthYear
    );

    setFilteredPlanningTasks(filtered);
  };

  // Step 5: Handle Click on Task Button
  const handleTaskClick = (calendarId) => {
    // Step 4: Navigate to the specified URL
    const url = `/planning/${calendarId}/${id}`;
    // Use your preferred method for navigation, e.g., react-router-dom or window.location.href
    window.location.href = url;
  };

  const whiteLabelStyle = {
    color: "white",
  };

  // Fetch Teams
  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/teams/get_team`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id: id }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setTeamData(data);
    } catch (error) {
      console.error("Error fetching team data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleButton = async () => {
    setShowButton(false);
  };

// Fetch Pending Requests
const fetchPendingRequests = async () => {
  try {
    const response = await fetch(`${apiUrl}/request/history`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const initialFilteredTasks = data.filter(
      (task) =>
        task.status === "Pending" &&
        task.type !== "Demande de Réclamation" &&
        task.type !== "Demande de Maintenance" &&
        task.temp_status !== "Planned"
    );

    const reversedTasks = initialFilteredTasks.reverse();
    setInitialTasks(reversedTasks);
  } catch (error) {
    console.error("Error fetching pending requests:", error.message);
  }
};

useEffect(() => {
  fetchPendingRequests();
}, []);




  //fetches BusyDates from request
  const fetchBusyDates = async () => {
    try {
      const response = await fetch(`${apiUrl}/request/history`, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const initialFilteredTasks = data.filter(
        (task) =>
          task.type !== "Demande de Réclamation" &&
          task.type !== "Demande de Maintenance" &&
          task.temp_status === "Planned" &&
          task.TeamID === id
      );
      const busyDates = initialFilteredTasks.map(
        (task) => task.date_intervention
      );
      setBusyDays(busyDates);
    } catch (error) {
      console.error("Error fetching pending requests:", error.message);
    }
  };

  useEffect(() => {
    fetchBusyDates();
  }, []);



  // removes valider button and replace it with the word valide
  const handleValidateDate = (taskId) => {
    setValidatedTasks((prevValidatedTasks) => {
      const updatedValidatedTasks = [...prevValidatedTasks, taskId];
      return updatedValidatedTasks;
    });
  };

  // Fetches Planned Requests
  const fetchPlannedData = () => {
    if (parcour === true || toggleEffect) {
      fetch(`${apiUrl}/request/history`, { method: "GET" })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          const fetchedPlanned = data.filter(
            (task) =>
              task.status === "Accepted" &&
              task.temp_status === "Planned" &&
              task.TeamID === id &&
              new Date(departureDate) <= new Date(task.date_intervention) &&
              new Date(task.date_intervention) <= new Date(returnDate)
          );

          // Convert date strings to Date objects
          fetchedPlanned.forEach((task) => {
            task.date_intervention = new Date(task.date_intervention);
          });

          // Sort by day and then by time
          fetchedPlanned.sort((a, b) => {
            if (
              a.date_intervention.toDateString() ===
              b.date_intervention.toDateString()
            ) {
              // If the days are the same, compare by time
              return a.date_intervention - b.date_intervention;
            }
            return a.date_intervention - b.date_intervention;
          });

          setPlannedTasks(fetchedPlanned);

        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors here
        });
    }
  };



  // Call useEffect
  useEffect(() => {
    fetchPlannedData();
  }, [parcour]);

  //Creates Planning
  const createCalendar = async () => {
    const requestData = new FormData();
    requestData.append("departureDate", departureDate);
    requestData.append("returnDate", returnDate);
    requestData.append("created_by", sessionStorage.getItem("id"));
    requestData.append("Team", id);
    plannedTasks.forEach((task, index) => {
      Object.entries(task).forEach(([key, value]) => {
        requestData.append(`plannedTasks[${index}][${key}]`, value);
      });
    });

    try {
      // Make a POST request using the fetch API
      setConfirmationModal(true);
      const response = await fetch(`${apiUrl}/calenders/create_calender`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: requestData,
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // If the response is successful, you can handle the result here
        const result = await response.json();
        setShowButton(false);
        sessionStorage.setItem("PlanningId", result._id);
        //window.open(`/planning/${id}/${result._id}`, "_blank");
        setConfirmationModal(false);
      } else {
        // Handle other response statuses or errors here
        console.error("Error:", response.status);
      }
    } catch (error) {
      // Handle any network or fetch errors
      console.error("Fetch error:", error);
    }
  };

  //Exports Planning
  const exportPlanning = async () => {
    try {
      const response = await fetch(`${apiUrl}/calenders/export_planing`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id: sessionStorage.getItem("PlanningId") }),
      });

      const result = await response.json();

      // Open the exported planning in a new window
      window.open(`${apiUrl}${result}`, "_blank");
    } catch (error) {
      console.error("Error while exporting", error);
    }
  };

  //Get all Plannings
  const getAllCalendars = async () => {
    try {
      // Make a GET request using the fetch API
      const response = await fetch(`${apiUrl}/calenders/find_calender`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({}),
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // If the response is successful, you can handle the result here
        const result = await response.json();
        const filteredCalendars = result.filter(
          (calendar) => calendar.Team === id
        );
        setCalendars(filteredCalendars);
      } else {
        // Handle other response statuses or errors here
        console.error("Error:", response.status);
      }
    } catch (error) {
      // Handle any network or fetch errors
      console.error("Fetch error:", error);
    }
  };

  // Call the function to send the GET request
  useEffect(() => {
    getAllCalendars();
  }, []);

  // Toggles Parcour
  const openParcour = async () => {
    await fetchPlannedData();
    setParcour(true);
  };

  const closeParcour = () => {
    setParcour(false);
  };

  //Toggles Modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //Toggles Modification Modal
  const openModificationModal = (taskId) => {
    // Find the task with the given ID
    const task =
      assignedTasks.find((t) => t._id === taskId) ||
      plannedTasks.find((t) => t._id === taskId);
    if (!task) {
      console.error(`Task with ID ${taskId} not found.`);
      return;
    }
  
    // Toggle selection for the clicked task ID
    const isSelected = selectedTaskIds.includes(taskId);
    const updatedSelectedTaskIds = isSelected
      ? selectedTaskIds.filter((id) => id !== taskId)
      : [...selectedTaskIds, taskId];
  
    // Use the functional form of setState to ensure the correct state update
    setSelectedTaskIds((prevSelectedTaskIds) => {
      const updatedSelectedTaskIds = isSelected
        ? prevSelectedTaskIds.filter((id) => id !== taskId)
        : [...prevSelectedTaskIds, taskId];
      return updatedSelectedTaskIds;
    });
  
    // Set the task properties and selected task IDs in the state
    setIsModificationModalOpen(true);
    setTaskToEdit(task);
  };
  
  const closeModificationModal = () => {
    setIsModificationModalOpen(false);
  };

  // Function to check if a given date is a Sunday
  const isSunday = (date) => {
    return date.getDay() === 0;
  };

  // Function to check if a date is a busy day
  const isBusyDay = (date) => {
    const result = busyDays.includes(date.toString());
    return result;
  };



  // Filters the pending requests
  const applyFilters = (
    selectedType, 
    departureDate, 
    returnDate, 
    //installationChecked 
  ) => {
    let tasksToFilter = initialTasks;

    if (!Array.isArray(tasksToFilter)) {
      tasksToFilter = [];
    }

    console.log("selectedTypeFilter", selectedType)
    if (selectedType === "Installation" ) {
      tasksToFilter = tasksToFilter.filter(
        (task) =>
          task.typeDePrestation === "Installation" &&
          task.typeDePrestation !== "Autres" &&
          task.status === "Pending" &&
          (!task.temp_status || task.temp_status !== "Planned")
      );

      tasksToFilter.forEach((task, index) => {
        const taskDate = new Date(departureDate);
        taskDate.setHours(8, 0);
        taskDate.setDate(taskDate.getDate() + index);

        // Skip tasks on Sundays
        while (isSunday(taskDate) || isBusyDay(taskDate)) {
          taskDate.setDate(taskDate.getDate() + 1);
        }

        task.date = taskDate.toISOString();
      });
    } else if (selectedType === "Other" ) {
      tasksToFilter = tasksToFilter.filter(
        (task) =>
          task.typeDePrestation !== "Installation" &&
          task.typeDePrestation !== "Autres" &&
          task.status === "Pending" &&
          (!task.temp_status || task.temp_status !== "Planned")
      );

      tasksToFilter.forEach((task, index) => {
        const taskDate = new Date(departureDate);

        if (index > 0) {
          taskDate.setHours(14, 0);
        } else {
          taskDate.setHours(8, 0);
        }

        taskDate.setDate(taskDate.getDate() + index);

        // Skip Sundays
        while (isSunday(taskDate) || isBusyDay(taskDate)) {
          taskDate.setDate(taskDate.getDate() + 1);
        }

        task.date = taskDate.toISOString();
      });
    } else if (selectedType === "RAC" ) {
      tasksToFilter = tasksToFilter.filter(
        (task) =>
          task.typeDePrestation === "Autres" &&
          task.status === "Pending" &&
          (!task.temp_status || task.temp_status !== "Planned")
      );

      tasksToFilter.forEach((task, index) => {
        const taskDate = new Date(departureDate);

        if (index > 0) {
          taskDate.setHours(14, 0);
        } else {
          taskDate.setHours(8, 0);
        }

        taskDate.setDate(taskDate.getDate() + index);

        // Skip Sundays
        while (isSunday(taskDate) || isBusyDay(taskDate)) {
          taskDate.setDate(taskDate.getDate() + 1);
        }

        task.date = taskDate.toISOString();
      });
    }

    // Calculate the number of days excluding Sundays
    console.log("departureDateFilter", departureDate)
    console.log("returnDateFilter", returnDate)
    const departureDates = new Date(departureDate);
    const returnDates = new Date(returnDate);
    let numberOfDays = 0;
    for (
      let currentDate = departureDates;
      currentDate <= returnDates;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      if (!isSunday(currentDate) || !isBusyDay(currentDate)) {
        numberOfDays++;
      }
    }

    const tasksPerDay = selectedType === "Installation" ? 1 : 2;
    const maxTasksToShow = tasksPerDay * numberOfDays;
    setFilteredTasks(tasksToFilter.slice(0, maxTasksToShow));
    setIsMapVisible(tasksToFilter.length > 0);
    console.log("departureDates", departureDates)
    console.log("returnDates", returnDates)
    console.log("numberOfDays", numberOfDays);
  };


    

  // Map + Google Map API
  const geocodeAddresses = (addresses) => {
    const promises = addresses.map((waypoint) => {
      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: waypoint.address }, (results, status) => {
          if (status === "OK" && results[0]) {
            const geocodedWaypoint = {
              location: results[0].geometry.location,
              address: waypoint.address,
              typeDePrestation: waypoint.typeDePrestation,
              nom: waypoint.nom,
              telephone: waypoint.telephone,
            };
            resolve(geocodedWaypoint);
          } else {
            reject(
              new Error(`Geocoding failed for address: ${waypoint.address}`)
            );
          }
        });
      });
    });

    return Promise.all(promises);
  };

  // Map + Google Map API
  useEffect(() => {
    if (isMapVisible) {
      geocodeAddresses(waypoints)
        .then((geocodedWaypoints) => {
          const mapElement = document.getElementById("map");
          if (mapElement) {
            const mapOptions = {
              center: { lat: 46.603354, lng: 1.888334 },
              zoom: 6,
            };
            const googleMap = new window.google.maps.Map(
              mapElement,
              mapOptions
            );
            setMap(googleMap);

            // Create an array to store all the markers
            const markers = [];

            // Create an array to store all the info windows
            const infoWindows = [];

            // Create an array to store waypoints' positions
            const waypointsPositions = geocodedWaypoints.map(
              (waypoint) => waypoint.location
            );

            // Create a DirectionsRenderer to display the route
            const directionsRenderer =
              new window.google.maps.DirectionsRenderer({
                map: googleMap,
                suppressMarkers: true, // Prevents default markers on the route
              });
            setDirectionsRenderer(directionsRenderer);

            // Create a DirectionsService to calculate the route
            const directionsService =
              new window.google.maps.DirectionsService();

            const request = {
              origin: waypointsPositions[0],
              destination: waypointsPositions[waypointsPositions.length - 1],
              waypoints: waypointsPositions.slice(1, -1).map((location) => ({
                location,
                stopover: true,
              })),
              travelMode: "DRIVING",
            };

            directionsService.route(request, (result, status) => {
              if (status === "OK") {
                directionsRenderer.setDirections(result);
              } else {
                console.error(
                  "Directions request failed with status: " + status
                );
              }
            });

            // Loop through the waypoints and create markers for each
            geocodedWaypoints.forEach((waypoint, index) => {
              const marker = new window.google.maps.Marker({
                position: waypoint.location,
                map: googleMap,
                label: {
                  text: `${index + 1}`,
                  color: "white", // Set the label text color to white
                  className: "white-label-text", // Apply the white-label-text class
                },
                labelStyle: whiteLabelStyle,
              });

              markers.push(marker);
            });

            // Show additional info from filteredTasks based on matching addresses
            filteredTasks.forEach((task, index) => {
              const matchingWaypoint = geocodedWaypoints.find(
                (waypoint) => waypoint.address === task.adresse
              );

              if (matchingWaypoint) {
                const marker = new window.google.maps.Marker({
                  position: matchingWaypoint.location,
                  map: googleMap,
                  //label: `${index + 1}`,
                  labelStyle: whiteLabelStyle,
                });

                markers.push(marker);

                const infoWindow = new window.google.maps.InfoWindow({
                  content: `
                  ${
                    task.nom
                      ? `<strong>Nom:</strong> ${task.nom} ${task.prenom}`
                      : ""
                  } 
                  ${
                    task.telephone
                      ? `<br><strong>Téléphone:</strong> ${task.telephone}`
                      : ""
                  } <br>

                    <strong>Prestation:</strong> ${task.typeDePrestation} ${
                    task.typeDeProjet
                  } ${task.marque}
                  <br>
                    <strong>Adresse:</strong> ${task.adresse}<br>

                  `,
                });

                infoWindows.push(infoWindow);

                marker.addListener("click", () => {
                  infoWindows.forEach((iw) => iw.close());
                  infoWindow.open(googleMap, marker);
                });
              }
            });

            // Calculate and display distance and duration between waypoints
            if (waypoints.length > 1) {
              const directionsService =
                new window.google.maps.DirectionsService();

              for (let i = 0; i < waypoints.length; i++) {
                if (i === 0) {
                  // Special case: calculate distance and duration between departure address and the first waypoint
                  const origin = departureAddress;
                  const destination = waypoints[i].address;

                  const matchingTask = filteredTasks.find(
                    (task) => task.adresse === waypoints[i].address
                  );

                  const client = matchingTask
                    ? `${matchingTask.nom} ${matchingTask.prenom}`
                    : "N/A";

                  const request = {
                    origin: origin,
                    destination: destination,
                    travelMode: "DRIVING",
                  };

                  directionsService.route(request, (result, status) => {
                    if (status === "OK") {
                      const route = result.routes[0];
                      const leg = route.legs[0];
                      const distance = leg.distance.text;
                      const duration = leg.duration.text;

                      // Display distance and duration between waypoints
                      const waypointDistanceElement = document.getElementById(
                        `waypoint-distance-${i}`
                      );
                      const waypointDurationElement = document.getElementById(
                        `waypoint-duration-${i}`
                      );

                      if (waypointDistanceElement && waypointDurationElement) {
                        waypointDistanceElement.innerHTML = `<strong style="color: red">Trajet ${
                          i + 1
                        }</strong> : Entre l'adresse de départ  et client <strong>${
                          i + 1
                        } </strong><br>Distance : <strong> ${distance}  </strong> <br>Temps du trajet : <strong> ${duration} </strong><br>Client : <strong>${client}</strong>`;
                      }
                    }
                  });
                } else {
                  // Calculate distance and duration between subsequent waypoints
                  const origin = waypoints[i - 1].address;
                  const destination = waypoints[i].address;

                  const matchingTask = filteredTasks.find(
                    (task) => task.adresse === waypoints[i].address
                  );

                  const client = matchingTask
                    ? `${matchingTask.nom} ${matchingTask.prenom}`
                    : "N/A";

                  const request = {
                    origin: origin,
                    destination: destination,
                    travelMode: "DRIVING",
                  };

                  directionsService.route(request, (result, status) => {
                    if (status === "OK") {
                      const route = result.routes[0];
                      const leg = route.legs[0];
                      const distance = leg.distance.text;
                      const duration = leg.duration.text;

                      // Display distance and duration between waypoints
                      const waypointDistanceElement = document.getElementById(
                        `waypoint-distance-${i}`
                      );
                      const waypointDurationElement = document.getElementById(
                        `waypoint-duration-${i}`
                      );

                      if (waypointDistanceElement && waypointDurationElement) {
                        waypointDistanceElement.innerHTML = `<strong style="color: red">Trajet ${
                          i + 1
                        }</strong> : Entre client N°<strong>${i}</strong> et client N°<strong>${
                          i + 1
                        } </strong><br>Distance : <strong> ${distance} </strong><br>Temps du trajet : <strong>${duration} </strong><br>Client : <strong>${client}</strong>`;
                      }
                    }
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isMapVisible, waypoints, filteredTasks]);

  // New useEffect for sorting, filtering, and assigning dates to tasks
  useEffect(() => {
    // Function to send the cURL request
    const sendDirectionsRequest = async () => {
      try {
        const data = {
          origin: { address: `${departureAddress}` },
          destination: { address: "Paris, France" },
          intermediates: filteredTasks
            .filter((task) => task.adresse !== "")
            .map((task) => ({
              address: task.adresse,
            }))
            .slice(0, 10),
          travelMode: "DRIVE",
          optimizeWaypointOrder: true,
        };

        const response = await fetch(
          "https://routes.googleapis.com/directions/v2:computeRoutes",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Goog-Api-Key": apiKey,
              "X-Goog-FieldMask": "routes.optimizedIntermediateWaypointIndex",
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }

        const responseData = await response.json();
        const optimizedWaypointIndices =
          responseData.routes[0].optimizedIntermediateWaypointIndex;

        // Reverse the order of waypoints
        const reversedWaypoints = optimizedWaypointIndices.map(
          (index) => data.intermediates[index]
        );

        setWaypoints(reversedWaypoints);

        // You can also set up a map here using Google Maps API
        // and display the waypoints on the map.
      } catch (error) {
        console.error("Error fetching directions:", error);
      }
    };

    sendDirectionsRequest();
  }, [filteredTasks]);

  // Function to assign dates to tasks
  const assignDatesToTasks = () => {
    if (selectedTypes && filteredTasks.length > 0) {
      // Create a subset of the first three waypoints for sorting

      // Sort the tasks based on their order in the sortedWaypoints
      const sortedTasks = filteredTasks.slice().sort((taskA, taskB) => {
        const indexA = waypoints.findIndex(
          (waypoint) => waypoint.address === taskA.adresse
        );
        const indexB = waypoints.findIndex(
          (waypoint) => waypoint.address === taskB.adresse
        );
        return indexA - indexB;
      });

      // Calculate the number of tasks per day based on selectedTypes
      const tasksPerDay = selectedTypes === "Installation" ? 1 : 2;

      // Initialize dates
      let currentDate = new Date(departureDate);
      let endDate = new Date(returnDate);

      // Assign dates to tasks
      let assignedTasks = [];

      while (currentDate <= endDate && sortedTasks.length > 0) {
        for (let i = 0; i < tasksPerDay; i++) {
          if (sortedTasks.length > 0) {
            const task = sortedTasks.shift();

            // Calculate the hours based on the index
            const hours = timeSlots[i]; // Use the time slot from the array

            // Assign the date and time to the task
            task.date = new Date(currentDate);
            task.date.setHours(hours.split(":")[0], hours.split(":")[1]);

            // Skip Sundays
            while (isSunday(task.date) || isBusyDay(task.date)) {
              currentDate.setDate(currentDate.getDate() + 1);
              task.date = new Date(currentDate);
              task.date.setHours(hours.split(":")[0], hours.split(":")[1]);
            }

            assignedTasks.push(task);
          }
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Update state with assigned tasks
      setAssignedTasks(assignedTasks);
    }
  };

  useEffect(() => {
    assignDatesToTasks();
  }, [initialTasks, selectedTypes, filteredTasks, departureDate, returnDate, waypoints]);
  

  const handleClickUpdateStatusDate = async (taskId) => {
    try {
      // Find the task with the given ID
      const task = assignedTasks.find((t) => t._id === taskId);

      if (!task) {
        console.error(`Task with ID ${taskId} not found.`);
        return;
      }

      const { _id, date } = task;
      const newAcceptedBy = sessionStorage.getItem("id");
      const formData = new FormData();
      formData.append("rid", _id);
      formData.append("date_intervention", date);
      formData.append("temp_status", "Planned");
      formData.append("TeamName", teamData.nomEquipe);
      formData.append("TeamID", teamData._id);
      formData.append("status", "Accepted");
      formData.append("raisonSociale", sessionStorage.getItem("raisonSocial"));
      formData.append("Technicien", sessionStorage.getItem("nom"));
      formData.append("Technicienprenom", sessionStorage.getItem("prenom"));
      formData.append("accepted_by", newAcceptedBy);

      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        // Task status and date have been updated successfully
        const data = await response.json();
        // Handle the response data here if needed
      } else {
        // Handle the case where the request is not successful
        console.error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
    fetchPendingRequests();
  };

  const handleUpdateStatusDate = async (taskId) => {
    try {
      const task = assignedTasks.find((t) => t._id === taskId);

      if (!task) {
        console.error(`Task with ID ${taskId} not found.`);
        return;
      }

      const { _id, date } = task;
      const newAcceptedBy = [sessionStorage.getItem("id")];

      const requestData = {
        rid: _id,
        date_intervention: date,
        temp_status: "Planned",
        TeamName: teamData.nomEquipe,
        TeamID: teamData._id,
        status: "Accepted",
        raisonSociale: sessionStorage.getItem("raisonSocial"),
        Technicien: sessionStorage.getItem("nom"),
        Technicienprenom: sessionStorage.getItem("prenom"),
        accepted_by: newAcceptedBy,
      };

      const response = await fetch(`${apiUrl}/request/update_json`, {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        // Task status and date have been updated successfully
        const data = await response.json();
        // Handle the response data here if needed
      } else {
        console.error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    fetchPendingRequests();
  };

  const handleRemoveTask = async (taskId) => {
    const isSelected = selectedTaskIds.includes(taskId);
    const updatedSelectedTaskIds = isSelected
      ? selectedTaskIds.filter((id) => id !== taskId)
      : [...selectedTaskIds, taskId];
    setSelectedTaskIds(updatedSelectedTaskIds);
    setRemoved(updatedSelectedTaskIds);
    try {
      // Find the task with the given ID
      const task = assignedTasks.find((t) => t._id === taskId);

      if (!task) {
        console.error(`Task with ID ${taskId} not found.`);
        return;
      }

      const { _id } = task;
      const newAcceptedByArray = [];

      const formData = new FormData();
      formData.append("rid", _id);
      formData.append("date_intervention", "");
      formData.append("temp_status", "");
      formData.append("TeamName", "");
      formData.append("TeamID", "");
      formData.append("status", "Pending");
      formData.append("client_accept", "");

      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        // Task status and date have been updated successfully
        const data = await response.json();
        setEditMode((prevEditMode) => ({
          ...prevEditMode,
          [taskId]: !prevEditMode[taskId],
        }));
        // Handle the response data here if needed
      } else {
        // Handle the case where the request is not successful
        console.error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
    fetchPendingRequests();
  };

  useEffect(() => {
    const mapOptions = {
      center: { lat: 46.603354, lng: 1.888334 },
      zoom: 6,
    };

    if (document.getElementById("map2")) {
      const mapInstance = new window.google.maps.Map(
        document.getElementById("map2"),
        mapOptions
      );
      setMap(mapInstance);

      const markerPositions = [];
      const markerCards = [];
      const waypointDistanceElement2 =
        document.getElementById("waypointDistance2");

      const geocoder = new window.google.maps.Geocoder();
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(mapInstance);

      plannedTasks.forEach((task, index) => {
        const address = task.adresse;

        geocoder.geocode({ address: address }, (results, status) => {
          if (status === "OK" && results[0].geometry) {
            const location = results[0].geometry.location;
            // Using String.fromCharCode to convert index to corresponding character
            // Using charCodeAt to get the numeric value
            const label = (index + 1).toString();
            const marker = new window.google.maps.Marker({
              position: location,
              map: mapInstance,
              label: {
                text: label,
                color: "white", // Set label color to white
                fontWeight: "bold", // Set label to be bold
              },
            });
            markerPositions.push(location);

            const card = `<div>
              ${
                task.nom
                  ? `<strong>Nom:</strong> ${task.nom} ${task.prenom}`
                  : ""
              } 
              ${
                task.telephone
                  ? `<br><strong>Téléphone:</strong> ${task.telephone}`
                  : ""
              } <br>
              <strong>Prestation:</strong> ${task.typeDePrestation} ${
              task.typeDeProjet
            } ${task.marque}
              <br>
              <strong>Date Intervention</strong>: ${task.date_intervention.toDateString()}<br>
              <strong>Adresse:</strong> ${address}<br>
              </div>`;
            markerCards.push(card);

            const infoWindow = new window.google.maps.InfoWindow();
            marker.addListener("click", () => {
              infoWindow.setContent(card);
              infoWindow.open(mapInstance, marker);
            });

            if (markerPositions.length === plannedTasks.length) {
              // All markers are placed, proceed with directions
              const origin = markerPositions[0];
              const waypoints = markerPositions.slice(1);

              const request = {
                origin,
                destination: waypoints[waypoints.length - 1],
                waypoints: waypoints.map((waypoint) => ({
                  location: waypoint,
                  stopover: true,
                })),
                travelMode: "DRIVING",
              };

              directionsService.route(request, (result, status) => {
                if (status === "OK") {
                  const route = result.routes[0];
                  const legs = route.legs;

                  const newPhrases = legs.map((leg, i) => {
                    const distance = leg.distance.text;
                    const duration = leg.duration.text;
                    const phraseIndex = i + 1;

                    return `<strong style="color: black">Trajet Entre client N°${phraseIndex} et client N°${
                      phraseIndex + 1
                    }</br></strong> Distance : <strong>${distance}</strong> </br> Temps du trajet : <strong>${duration}</strong> </br> Client Destination: <strong>${
                      plannedTasks[i].nom
                    }</strong>`;
                  });

                  // Use Set to remove duplicates
                  setPhrase((prevPhrases) =>
                    Array.from(new Set([...prevPhrases, ...newPhrases]))
                  );

                  if (waypointDistanceElement2) {
                    waypointDistanceElement2.innerHTML = phrase
                      .map(
                        (phrase, index) =>
                          `<div key=${index}><strong style="color: red">Trajet ${
                            index + 1
                          }</strong>: ${phrase}</div>`
                      )
                      .join("");
                  }
                }
              });
            }
          }
        });
      });
    }
  }, [plannedTasks, parcour]);

  const handleClick = (id) => {
    // Redirect to the specified route when the div is clicked
    window.location.href = `/user-intervention-details/${id}`;
  };

  const sendUpdateRequest = async (taskId) => {
    const formData = new FormData();
    formData.append("rid", taskId);
    formData.append("existingPlanning", "yes");
    formData.append("planningID", sessionStorage.getItem("PlanningId"));
    try {
      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
      } else {
        console.error(`Error updating task ${taskId}:`, response.statusText);
      }
    } catch (error) {
      console.error(`Error updating task ${taskId}:`, error.message);
    }
  };

  const sendUpdateRequestForAll = async () => {
    // Iterate through all plannedTasks and send update requests
    for (const task of plannedTasks) {
      await sendUpdateRequest(task._id);
    }
  };

  const handlePlanningUpdate = async () => {
    await createCalendar();
    await sendUpdateRequestForAll();
    window.open(
      `/planning/${sessionStorage.getItem("PlanningId")}/${id}`,
      "_blank"
    );
  };



  return (
    <div className="mt-20">
      <ReturnButton/>
      <div className="flex justify-between mx-auto w-[92%] flex-wrap border border-solid border-gray-300 p-4 rounded-md lg:flex-row flex-col">
        <div className="flex flex-col justify-center lg:w-[70%] w-full">
          <PageHeader TeamData={id} />
        </div>
        <div className="flex flex-col justify-center lg:w-[30%] w-full">
          <div className="w-full flex justify-center">
            <button
              className="p-4 bg-[#3E9F36] lg:m-4 text-white rounded-[10px] mt-10"
              onClick={openModal}
            >
              Générer un nouveau planning
            </button>
          </div>
        </div>
      </div>
      {calendars && (
        <div className="">
          {/* Step 2: Display Buttons for Each Month/Year */}
          <div className="flex justify-center">
            {uniqueMonthsYears.map((monthYear) => (
              <button
                className={`p-4 lg:m-4 text-white rounded-[10px] mt-10 w-[25%] mx-auto ${
                  selectedMonthYear === monthYear
                    ? "bg-[#3E9F36]"
                    : "bg-gray-400"
                }`}
                key={monthYear}
                onClick={() => handleMonthYearClick(monthYear)}
              >
                Planning du mois de {monthYear}
              </button>
            ))}
          </div>

          {/* Step 4: Display Task Buttons */}
          <div className="flex justify-center">
            {filteredPlanningTasks.map((task) => (
              <button
                key={task._id}
                onClick={() => handleTaskClick(task._id, task.Team)}
                className="p-4 bg-[#3E9F36] lg:m-4 text-white rounded-[10px] mt-10 w-[25%]"
              >
                {`Planning du ${new Date(task.departureDate).toLocaleDateString(
                  "fr-FR"
                )} au ${new Date(task.returnDate).toLocaleDateString("fr-FR")}`}
              </button>
            ))}
          </div>
        </div>
      )}

      <FilterModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        applyFilters={applyFilters}
        setSelectedAddress={setSelectedAddress}
        sendDataToParent={handleDataFromChild}
        id={id}
        sendDataToParentFilter={handleDataFromChildFilter}
      />

      {isMapVisible && (
        <div>
          {/*<div className="w-[98%] m-auto rounded-md mb-4">
            <div id="map" style={{ width: "100%", height: "400px" }}></div>
      </div>

          <h2 className="text-lg font-bold">Trajets:</h2>
          <div className="flex flex-wrap justify-start">
            {waypoints.slice(0, assignedTasks.length).map((waypoint, index) => (
              <div
                className="p-4 bg-gray-100 m-4 rounded-md w-[30%] border-black border"
                key={index}
              >
                <span id={`waypoint-distance-${index}`}>
                  {waypointData[index] &&
                    `Trajet ${index + 1}: Entre l'adresse A et l'adresse B`}
                </span>
                <span id={`waypoint-duration-${index}`}>
                  {waypointData[index] &&
                    `Distance : ${waypointData[index].distance} km\nLe temps estimé de trajet est aux alentours de ${waypointData[index].duration}`}
                </span>
              </div>
            ))}
          </div>*/}

          {!parcour && (
            <>
              {assignedTasks.length > 0 && (
                <div className="w-full m-auto rounded-md mb-4 ">
                  <div className="flex justify-center flex-wrap mb-4 p-4 ">
                    <h2 className="text-[20px] font-bold mt-10 ">
                      {" "}
                      Proposition de dates pour les interventions entre le{" "}
                      {new Date(departureDate).toLocaleDateString()} et le{" "}
                      {new Date(returnDate).toLocaleDateString()}
                    </h2>
                  </div>
                  <div className=" ml-[60] lg:flex lg:justify-start lg:flex-wrap">
                    {assignedTasks.map((task, index) => (
                      <>
                        <div
                          key={index}
                          className="m-4  bg-white rounded-md lg:w-[30%] sm:w-full text-start border-black border "
                        >
                          <div
                            onClick={() => handleClick(task._id)}
                            className="cursor-pointer"
                          >
                            <div className="bg-gray-100 w-full mb-4 p-2 ">
                              {dataFromChild === null ||
                              dataFromChild.id !== task._id ? (
                                <>
                                  <p className="text-center text-sm text-black font-[400]">
                                    Date d’intervention :
                                  </p>
                                  <p className="text-center text-sm text-black font-bold">
                                    {" "}
                                    {new Date(
                                      task.date
                                    ).toLocaleDateString()}{" "}
                                  </p>
                                  <p className="text-center text-sm text-black font-bold">
                                    Heure d’arrivé : Entre{" "}
                                    {task?.date?.toLocaleTimeString("fr-FR", {
                                      hour: "numeric",
                                      hour12: false,
                                    })}{" "}
                                    et{" "}
                                    {new Date(
                                      task?.date?.getTime() + 2 * 60 * 60 * 1000
                                    ).toLocaleTimeString("fr-FR", {
                                      hour: "numeric",
                                      hour12: false,
                                    })}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="text-center text-sm text-black font-[400]">
                                    Date d’intervention :
                                  </p>
                                  <p className="text-center text-sm text-black font-bold">
                                    {" "}
                                    {new Date(
                                      dataFromChild.date
                                    ).toLocaleDateString()}{" "}
                                  </p>
                                  <p className="text-center text-sm text-black font-bold">
                                    Heure d’arrivé : Entre{" "}
                                    {new Date(
                                      dataFromChild.date
                                    ).toLocaleTimeString("fr-FR", {
                                      hour: "numeric",
                                      hour12: false,
                                    })}{" "}
                                    et{" "}
                                    {new Date(
                                      new Date(dataFromChild.date).getTime() +
                                        2 * 60 * 60 * 1000
                                    ).toLocaleTimeString("fr-FR", {
                                      hour: "numeric",
                                      hour12: false,
                                    })}
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="h-[200px] p-4">
                              <span className="font-bold text-black">
                                {" "}
                                client Pro:{" "}
                              </span>{" "}
                              {task.raisonSocialClient} <br />
                              <span className="font-bold text-black">
                                {" "}
                                Nom du client {index + 1}:{" "}
                              </span>{" "}
                              {task.nom} {task.prenom}, <br />
                              <span className="font-bold text-black">
                                {" "}
                                Téléphone:{" "}
                              </span>
                              {task.telephone}, <br />
                              <span className="font-bold text-black">
                                {" "}
                                Prestation:{" "}
                              </span>
                              {task.typeDePrestation === "Autres" ? "Remise en conformité" : task.typeDePrestation} {task.typeDeProjet}{" "}
                              {task.marque}
                              <br />
                              {task.puissance &&
                                task.puissance !== "0" &&
                                task.puissance !== "000" && (
                                  <>
                                    <span className="font-bold text-black">
                                      {" "}
                                      Puissance:{" "}
                                    </span>
                                    {task.puissance} <br />
                                  </>
                                )}
                              <span className="font-bold text-black">
                                {" "}
                                Adresse:{" "}
                              </span>
                              {task.adresse}, <br />
                              {/*<span className="font-bold text-[#ff0000]">
                              {" "}
                              Time:{" "}
                            </span>
                            {task?.date.toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}{" "}
                            <br />
                            <span className="font-bold text-[#ff0000]">
                              {" "}
                              Date d'intervention:{" "}
                            </span>{" "}
                          {new Date(task.date).toLocaleDateString()}*/}
                            </div>
                          </div>
                          <div className="justify-between flex mt-2 h-[20%] p-4">
                            {editMode && !removed.includes(task._id) ? (
                              <>
                              
                                {dataFromChild === null ||
                              dataFromChild.id !== task._id ?  (
                                  <>
                                    {validatedTasks.includes(task._id) ? (
                                      <span className="bg-white text-black p-2 rounded-md text-md">
                                        Validé
                                      </span>
                                    ) : (
                                      <button
                                        className={`${
                                          dataFromChild === null ||
                                          !selectedTaskIds.includes(task._id)
                                            ? "bg-[#3E9F36] text-white"
                                            : "bg-gray-100 text-black"
                                        } p-2 rounded-md text-md`}
                                        onClick={() => {
                                          handleUpdateStatusDate(task._id);
                                          handleValidateDate(task._id);
                                        }}
                                        disabled={dataFromChild !== null && dataFromChild.id === task._id }
                                      >
                                        Valider la date
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <button
                                    className="bg-gray-100 text-black p-2 rounded-md text-md pointer-events-none"
                                    disabled
                                  >
                                    Valider la date
                                  </button>
                                )}
                                <button
                                  className="bg-[#f2a41fff] text-white p-2 rounded-md text-md"
                                  onClick={() =>
                                    openModificationModal(task._id)
                                  }
                                >
                                  Modifier la date
                                </button>
                                <button
                                  className="bg-[#d7000dff] text-white p-2 rounded-md text-md"
                                  onClick={() => handleRemoveTask(task._id)}
                                >
                                  Annuler la date
                                </button>
                              </>
                            ) : (
                              <span className="bg-white text-black p-2 rounded-md text-md">
                                Intervention annulée
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="p-4 bg-[#3E9F36] m-4 text-white rounded-[10px] mt-10"
                      onClick={openParcour}
                    >
                      Valider le planning de l’équipe
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          {assignedTasks.length > 0 && (
            <div className="w-full m-auto rounded-md mb-4 p-4">
              {parcour && (
                <>
                  <div className="flex justify-start flex-wrap mb-4 p-4">
                    {plannedTasks.length > 0 && (
                      <div className="w-full m-auto rounded-md mb-4 p-4">
                        <div className="flex justify-between flex-wrap mb-4 p-4 ">
                          <h2 className="text-4xl font-bold text-black">
                            Parcour de l'équipe
                          </h2>
                        </div>
                        <div className="lg:flex lg:justify-start lg:flex-wrap">
                          {plannedTasks
                            ?.filter((task) => task.existingPlanning !== "yes")
                            .map((task, index) => (
                              <>
                                <div
                                  key={index}
                                  className="m-4  bg-white rounded-md lg:w-[30%] sm:w-full text-start border-black border "
                                >
                                  <div className="bg-gray-100 w-full mb-4 p-2 ">
                                    <p className="text-center text-sm text-black font-[400]">
                                      Date d’intervention :
                                    </p>
                                    <p className="text-center text-sm text-black font-bold">
                                      {" "}
                                      {new Date(
                                        task.date_intervention
                                      ).toLocaleDateString()}{" "}
                                    </p>
                                    <p className="text-center text-sm text-black font-[400]">
                                      Heure d’arrivé : Entre{" "}
                                      {task?.date_intervention.toLocaleTimeString(
                                        "fr-FR",
                                        {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        }
                                      )}{" "}
                                      et{" "}
                                      {new Date(
                                        task?.date_intervention.getTime() +
                                          2 * 60 * 60 * 1000
                                      ).toLocaleTimeString("fr-FR", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </p>
                                  </div>
                                  <div className="h-[250px] p-4">
                                    <span className="font-bold text-black">
                                      {" "}
                                      Client Pro:{" "}
                                    </span>{" "}
                                    {task.raisonSocialClient} <br />
                                    <span className="font-bold text-black">
                                      {" "}
                                      Nom du client {index + 1}:{" "}
                                    </span>{" "}
                                    {task.nom} {task.prenom}, <br />
                                    <span className="font-bold text-black">
                                      {" "}
                                      Téléphone:{" "}
                                    </span>
                                    {task.telephone}, <br />
                                    <span className="font-bold text-black">
                                      {" "}
                                      Prestation:{" "}
                                    </span>
                                    {task.typeDePrestation} {task.typeDeProjet}{" "}
                                    {task.marque}
                                    <br />
                                    {task.puissance &&
                                      task.puissance !== "0" &&
                                      task.puissance !== "000" && (
                                        <>
                                          <span className="font-bold text-black">
                                            {" "}
                                            Puissance:{" "}
                                          </span>
                                          {task.puissance} <br />
                                        </>
                                      )}
                                    <span className="font-bold text-black">
                                      {" "}
                                      Adresse:{" "}
                                    </span>
                                    {task.adresse}, <br />
                                  </div>
                                  {/*<div className="gap-4 flex mt-2 h-[20%]">
                                  <button
                                    className="bg-[#3E9F36] text-white p-2 rounded-md"
                                    onClick={() =>
                                      handleClickUpdateStatusDate(task._id)
                                    }
                                  >
                                    Valider l'intervention
                                  </button>
                                  <button
                                    className="bg-[#3E9F36] text-white p-2 rounded-md"
                                    onClick={() =>
                                      openModificationModal(task._id)
                                    }
                                  >
                                    Modifier l'intervention
                                  </button>
                                  </div>*/}
                                </div>
                              </>
                            ))}
                        </div>

                        <div className="w-[98%] m-auto rounded-md mb-4">
                          <div className="w-[98%] m-auto rounded-md mb-4">
                            <div
                              className="mt-4"
                              id="map2"
                              style={{ width: "100%", height: "400px" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}

                    {phrase && (
                      <>
                        {/*<h2 className="text-lg font-bold">Trajets:</h2>*/}
                        <div className="flex flex-wrap justify-start w-[100%]">
                          {phrase.slice(0, -1).map((phrase, index) => (
                            <div
                              className="p-4  m-4 rounded-md w-full border-black border w-[30%]"
                              key={index}
                            >
                              <span
                                dangerouslySetInnerHTML={{ __html: phrase }}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    {plannedTasks.length === 0 && (
                      <div className="w-full m-auto rounded-md mb-4 p-4">
                        <div className="flex justify-center flex-wrap mb-4 p-4 ">
                          <h2 className="text-lg font-bold text-red-700">
                            Aucune date n'a été validée pour le planning.
                          </h2>
                        </div>
                      </div>
                    )}
                  </div>
                 {plannedTasks.length > 0 && (
                    <div className="justify-end gap-2 flex mt-2 h-[20%] p-4">
                      <button
                        className="bg-[#3E9F36] text-white p-2 rounded-md text-md"
                        onClick={handlePlanningUpdate}
                      >
                        Sauvegarder le planning
                      </button>
                      {/*<button
                        className="bg-[#3E9F36] text-white p-2 rounded-md text-md"
                        onClick={exportPlanning}
                      >
                        Exporter le planning
                 </button>*/}
                    </div>
                  )}

                  {confirmationModal ? (
                    <>
                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                            <div className="mt-3">
                              <div className="mt-2 text-center text-center">
                                <h4 className="text-lg font-medium text-gray-800">
                                  Sauvegarde Réussie
                                </h4>

                                <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                                  La sauvegarde du planning a été effectuée avec
                                  succès. Vos modifications ont été enregistrées
                                  et sont maintenant prêtes à être consultées.
                                  Merci pour votre mise à jour précieuse.
                                </p>
                                <div className="items-center gap-2 mt-3 sm:flex"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>
          )}

          <ModificationModal
            isOpen={isModificationModalOpen}
            closeModal={closeModificationModal}
            taskToEdit={taskToEdit}
            onSuccess={() => setRequestSentSuccessfully(true)}
            sendDataToParent={handleDataFromChild}
            id={id}
            nomEquipe={teamData.nomEquipe}
            idEquipe={teamData._id}
          />
        </div>
      )}
    </div>
  );
};

export default TechProTeamDetails;
