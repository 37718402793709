import React from 'react';
import { Detail } from '../../../Components';



const UserProjectDetails = () => {
  return (
    <Detail/>
  )
}

export default UserProjectDetails