import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components";
import project from "../../../Assets/Icons/suivi-projet.png";
import clients from "../../../Assets/Icons/mes-clients.png";

const TechTeamProCompte = () => {
  return (
    <div className="bg-white">
      <BreadCrumb From={""} To={"Mon Compte"} hrefFrom={"#"} />

      <div className="max-w-screen-xl  ml-auto mr-auto border border-gray-200 rounded-lg mt-10 mb-[8px] grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 p-10">
        <div className="flex justify-center  p-5  h-[200px]">
          <div className="m-auto cursor-pointer">
            <a href="/user-history">
              <img
                src={project}
                alt="Suivre mes demandes d'intervention"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />
              <h3 className="text-center text-[16px] font-bold">
                Projets en cours
              </h3>
            </a>
          </div>
        </div>

        <div className="font-Audrey flex justify-center bg-white p-5 h-[200px] border-l-2  border-gray-200">
          <div className="m-auto cursor-pointer">
            <a href="/pro-team-plannings">
              <img
                src={clients}
                alt="Mes Equipes"
                className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
              />
              <h3 className="text-center text-[16px] font-bold">planning</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechTeamProCompte;
