import React from "react";
import "../../Styles/NewStyle.css";
import { Helmet } from "react-helmet";

const PolitiqueConfidentialite = () => {
  return (
    <>
      <Helmet>
        <title>Politique de Confidentialité - LeBonTechnicien.fr</title>
        <meta name="keywords" content="Politique de Confidentialité LeBonTechnicien.fr" />
        <meta
          name="description"
          content="Politique de Confidentialité - LeBonTechnicien.fr"
        />
        <link rel="canonical" href="https://lebontechnicien.net/" />
      </Helmet>
      <main>
        <section class="pdc leBonTech-section">
          <h1 className="leBonTech-h1">
            Politique de Confidentialité
          </h1>

          <p className="leBonTech-p">
            LeBonTechnicien.fr attache une grande importance à la protection des
            données personnelles de ses utilisateurs. La présente Politique de
            Confidentialité décrit comment nous collectons, utilisons et
            protégeons vos informations personnelles lorsque vous utilisez notre
            application web et mobile de mise en relation entre particuliers et
            professionnels experts en installations et réparations d'équipements
            d'énergie renouvelable.
          </p>
          <p className="leBonTech-p">
            En utilisant notre Application, vous acceptez les pratiques décrites
            dans cette Politique de Confidentialité. Si vous n'acceptez pas les
            termes de cette Politique, veuillez ne pas utiliser l'Application.
          </p>

          <h2 className="leBonTech-h2">1. Collecte des informations</h2>
          <p className="leBonTech-p">
            Nous collectons des informations personnelles lorsque vous :
          </p>
          <ul className="leBonTech-ul">
            <li className="leBonTech-li">
              <span className="leBonTech-span">1.1.</span> Créez un compte sur
              notre Application, que vous soyez un particulier ou un
              professionnel.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">1.2.</span> Utilisez
              l'Application pour rechercher des services ou soumettre des
              demandes d'intervention.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">1.3.</span> Communiquez avec
              d'autres utilisateurs ou avec LeBonTechnicien.fr
            </li>
          </ul>
          <p className="leBonTech-p">
            Les informations collectées peuvent inclure votre nom, adresse
            e-mail, numéro de téléphone, adresse postale, informations relatives
            à vos équipements d'énergie renouvelable, historique des
            interventions, et tout autre information nécessaire pour fournir nos
            services.
          </p>

          <h2 className="leBonTech-h2">2. Utilisation des informations</h2>
          <p className="leBonTech-p">
            Nous utilisons les informations collectées pour :
          </p>
          <ul>
            <li className="leBonTech-li">
              <span className="leBonTech-span">2.1.</span> Vous fournir les
              services et fonctionnalités de l'Application.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">2.2.</span> Améliorer la qualité
              de nos services et personnaliser votre expérience sur
              l'Application.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">2.3.</span> Communiquer avec vous
              concernant votre compte, vos demandes d'intervention et les
              services des professionnels.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">2.4.</span> Vous envoyez des
              notifications, des offres promotionnelles et des informations sur
              les nouveaux services, si vous avez accepté de les recevoir.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">2.5.</span> Assurer la sécurité
              et la protection de notre Application et de nos utilisateurs.
            </li>
          </ul>

          <h2 className="leBonTech-h2">3. Partage des informations</h2>
          <p className="leBonTech-p">
            Nous partageons vos informations personnelles avec des tiers
            uniquement dans les situations suivantes :
          </p>
          <ul>
            <li className="leBonTech-li">
              <span className="leBonTech-span">3.1.</span> Avec les
              professionnels inscrits sur l'Application pour leur permettre de
              répondre à vos demandes d'intervention.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">3.2.</span> Avec nos partenaires
              et prestataires de services qui nous aident à gérer et à améliorer
              l'Application.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">3.3.</span> Lorsque nous sommes
              tenus de divulguer des informations en vertu d'une loi, d'une
              réglementation ou d'une demande gouvernementale.
            </li>
            <li className="leBonTech-li">
              <span className="leBonTech-span">3.4.</span> En cas de fusion,
              acquisition, restructuration ou autre transaction impliquant la
              cession ou le transfert de tout ou partie de nos activités.
            </li>
          </ul>

          <h2 className="leBonTech-h2">4. Sécurité des informations</h2>
          <p className="leBonTech-p">
            Nous prenons des mesures de sécurité appropriées pour protéger vos
            informations personnelles contre l'accès non autorisé, la
            divulgation, la modification ou la destruction. Cela inclut la mise
            en œuvre de technologies et de procédures de sécurité, ainsi que la
            formation de notre personnel.
          </p>
          <p className="leBonTech-p">
            Veuillez noter que la transmission d'informations sur Internet n'est
            jamais totalement sécurisée, et nous ne pouvons garantir la sécurité
            absolue de vos informations.
          </p>

          <h2 className="leBonTech-h2">5. Vos droits</h2>
          <p className="leBonTech-p">
            Vous avez le droit d'accéder, de modifier, de rectifier et de
            supprimer vos informations personnelles. Vous pouvez exercer ces
            droits en vous connectant à votre compte ou en nous contactant à
            l'adresse e-mail fournie dans la section "Contactez-nous" de
            l'Application
          </p>

          <h2 className="leBonTech-h2">6. Conservation des données</h2>
          <p className="leBonTech-p">
            Nous conservons vos informations personnelles aussi longtemps que
            nécessaire pour fournir les services de l'Application et pour
            répondre à vos demandes. Nous supprimerons vos informations
            personnelles conformément à nos politiques de conservation des
            données et aux exigences légales applicables.
          </p>

          <h2 className="leBonTech-h2">
            7. Cookies et technologies similaires
          </h2>
          <p className="leBonTech-p">
            Notre Application utilise des cookies et d'autres technologies
            similaires pour améliorer et personnaliser votre expérience en
            ligne. Les cookies sont de petits fichiers de texte qui sont stockés
            sur votre appareil lorsque vous visitez notre site web ou utilisez
            notre Application. Ils nous permettent de collecter des informations
            sur la manière dont vous interagissez avec notre site web et notre
            Application.
          </p>
          <p className="leBonTech-p">
            Vous pouvez gérer vos préférences en matière de cookies dans les
            paramètres de votre navigateur ou de votre appareil. Veuillez noter
            que si vous désactivez certains cookies, certaines fonctionnalités
            de l'Application pourraient ne pas fonctionner correctement.
          </p>

          <h2 className="leBonTech-h2">
            8. Modifications de la Politique de Confidentialité
          </h2>
          <p className="leBonTech-p">
            Nous nous réservons le droit de modifier cette Politique de
            Confidentialité à tout moment. Les modifications seront publiées sur
            cette page et la date de la dernière mise à jour sera indiquée en
            haut de la Politique. Nous vous encourageons à consulter
            régulièrement cette page pour vous tenir informé de notre manière de
            protéger vos informations personnelles.
          </p>

          <h2 className="leBonTech-h2">9. Contactez-nous</h2>
          <p className="leBonTech-p">
            Si vous avez des questions ou des préoccupations concernant notre
            Politique de Confidentialité, veuillez nous contacter à l'adresse
            e-mail suivante : [adresse e-mail de contact].
          </p>
          <p className="leBonTech-p">
            Nous nous engageons à répondre rapidement et efficacement à vos
            demandes et à résoudre tout litige ou préoccupation concernant la
            protection de vos données personnelles.
          </p>

          <h2 className="leBonTech-h2">
            10. Transferts internationaux de données
          </h2>
          <p className="leBonTech-p">
            Dans le cadre de notre activité, nous pouons être amenés à
            transférer vos informations personnelles vers des pays situés en
            dehors de l'Espace économique européen (EEE). Lorsque cela est
            nécessaire, nous mettons en place des garanties appropriées pour
            assurer un niveau de protection adéquat de vos informations
            personnelles, conformément aux lois applicables en matière de
            protection des données. Ces garanties peuvent inclure des clauses
            contractuelles types approuvées par la Commission européenne, ou
            d'autres mécanismes de transfert reconnus par les autorités
            compétentes.
          </p>

          <h2 className="leBonTech-h2">11. Liens vers d'autres sites</h2>
          <p className="leBonTech-p">
            Notre Application peut contenir des liens vers d'autres sites web ou
            applications qui ne sont pas exploités par LeBonTechnicien.fr. Si
            vous cliquez sur un lien vers un site tiers, vous serez dirigé vers
            ce site et soumis à sa politique de confidentialité. Nous vous
            encourageons à lire attentivement les politiques de confidentialité
            des sites tiers avant de soumettre des informations personnelles.
          </p>
          <p className="leBonTech-p">
            Nous n'avons aucun contrôle sur ces sites tiers et déclinons toute
            responsabilité quant à leur contenu ou à leurs pratiques de
            confidentialité.
          </p>

          <h2 className="leBonTech-h2">
            12. Protection des données des enfants
          </h2>
          <p className="leBonTech-p">
            Notre Application n'est pas destinée aux enfants de moins de 16 ans
            et nous ne collectons pas sciemment des informations personnelles
            auprès des enfants. Si nous découvrons que nous avons collecté des
            informations personnelles d'un enfant de moins de 16 ans, nous
            prendrons des mesures pour supprimer rapidement ces informations.
          </p>
          <p className="leBonTech-p">
            Si vous pensez que nous pourrions détenir des informations
            personnelles d'un enfant de moins de 16 ans, veuillez nous contacter
            à l'adresse e-mail indiquée dans la section "Contactez-nous".
          </p>

          <h2 className="leBonTech-h2">13. Responsabilité</h2>
          <p className="leBonTech-p">
            LeBonTechnicien.fr ne pourra être tenu responsable en cas de perte,
            de vol ou de divulgation non autorisée des données des Utilisateurs,
            dans la mesure où LeBonTechnicien.fr respecte les bonnes pratiques
            en matière de sécurité des données et les obligations légales
            applicables.
          </p>
          <p className="leBonTech-p">
            En utilisant notre Application, vous reconnaissez et acceptez que
            vous êtes responsable de la protection de vos propres informations
            personnelles et que vous utilisez l'Application à vos propres
            risques.
          </p>

          <p className="leBonTech-p">
            Nous vous remercions d'avoir choisi LeBonTechnicien.fr pour
            faciliter la mise en relation entre particuliers et professionnels
            experts en installations et réparations d'équipements d'énergie
            renouvelable. Nous nous engageons à protéger vos données
            personnelles et à respecter les principes énoncés dans notre
            Politique de Confidentialité. Si vous avez des questions ou des
            préoccupations concernant notre Politique de Confidentialité,
            n'hésitez pas à nous contacter.
          </p>
        </section>
      </main>
    </>
  );
};

export default PolitiqueConfidentialite;
