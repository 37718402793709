import React, { useEffect, useState } from "react";
import { BreadCrumb, ContractCard } from "../../../Components";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../../contexts/ContextProvider";

const ContractList = () => {
  const [history, setHistory] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");
  const [userDataRequest, setUserDataRequest] = useState([]);
  const [userDataUser, setUserDataUser] = useState([]);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const { userType} = useStateContext();
  const User = history?.client_accept;
  const UserType = "under_pro";

  const apiUrl = 'https://lebontechnicien.net'



  //Handles the Fetching previous interventions
  const fetchData = async () => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        const filteredData = data.filter(
          (item) =>
            item.created_by === sessionStorage.getItem("id") &&
            item.type === "Demande de Maintenance" && item.Paiement ==="Paiement effectué"
        );
        setHistory(filteredData.reverse());
        setFilteredData(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(
          `${apiUrl}/prods/user_product`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              email: `${sessionStorage.getItem("email")}`,
            }),
          }
        );

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);


  //Fetches the USers from /request
  const handleGetProduct = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }

    fetch(`${apiUrl}/request/history`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserDataRequest(data);
          console.log(data);
        })
        .catch((error) => console.error(error));
  };

  useEffect(() => {
    handleGetProduct();
  }, [history]);

  //Fetches the Users from /find
  const handleGetUser = async () => {
    if (!history) {
      // Wait for history to be set
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (history) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100);
      });
    }

    const response = await fetch(`${apiUrl}/find`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ type: UserType }),
    });
    const data = await response.json();
    setUserDataUser(data);
    console.log("userDataRequest", userDataUser);
  };

  useEffect(() => {
    handleGetUser();
  }, [history, User]);



  const renderUserName = (contract) => {
    // Check if userDataRequest is available, if not, try userDataUser
    if (userDataRequest && userDataRequest.length > 0) {
      const user = userDataRequest.find((user) => user._id === contract.requestID);
      if (user) {
        return `${user.nom} ${user.prenom}`;
      }
    }
  
    // If userDataRequest is empty or null, check userDataUser
    if (userDataUser && userDataUser.length > 0) {
      console.log("conditioncontract", contract);
      console.log("conditionUser", userDataUser);
      const user = userDataUser.find((user) => user._id === contract.requestID);
      console.log("userafterentered", user)
      if (user) {
        return `${user.nom} ${user.prenom}`;
      }
    }
  
    return "N/A"; // Or a default value if user data not found in both sources
  };

  const filterData = () => {
    let filtered = [...history]; // Make a copy of history to avoid mutating state directly


    // Check if demandeur exists in history and includes the searchInput
    if (searchInput !== "") {
      filtered = filtered.filter(
        (item) =>
          item.demandeur &&
          item.demandeur.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    return filtered;
  };

  

  return (
    <div className="mb-10">
      <BreadCrumb
        From={"Compte"}
        To={"Mes Contrats d’entretien"}
        hrefFrom={"/tableau-de-bord"}
      />
      <div className="mt-10 sm:mt-0 max-w-screen-xl ml-auto mr-auto">
        <div className="flex justify-between w-full mb-10">
          <h3 className="text-lg font-medium leading-6 text-gray-900 title-font-h3">
            Liste Des Contrats
          </h3>
          <button
            onClick={() => navigate(`/demande-contrat`)}
            className="userProfile-btn inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Ajouter
          </button>
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Rechercher par nom..."
            className="w-full rounded-lg p-3 text-gray-600 bg-gray-100 border"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <div className="px-4 sm:px-0 mt-10">
        {/*{filterData().map((item, index) => (
            <div key={index}>
              <div className="w-full mb-5 border rounded-lg p-8 bg-white">
                <div className="flex justify-between">
                  <div>
                  <p>
                      <span className="font-bold">Nom de client: </span>
                      {item.nom} {item.prenom}
                    </p>
                    <p>
                      <span className="font-bold">Date de début: </span>
                      {/^\d{2}\/\d{2}\/\d{4}$/.test(item.selectedDate)
                        ? item.selectedDate
                        : new Date(item.selectedDate).toLocaleDateString(
                            "fr-FR"
                          )}
                    </p>
                    {item.end_date && (
                      <p>
                        <span className="font-bold">Date de fin: </span>
                        {/^\d{2}\/\d{2}\/\d{4}$/.test(item.end_date)
                          ? item.end_date
                          : new Date(item.end_date).toLocaleDateString(
                              "fr-FR"
                            )}
                      </p>
                    )}

                    <p>
                      <span className="font-bold">Statut:</span> {""}
                      {(() => {
                        switch (item.status) {
                          case "Pending":
                            return "En attente de Validation";
                          case "closed":
                            return "Validé";
                          case "closed_tech":
                            return "Cloturé par technicien";
                          case "claim":
                            return "Réclamation";
                          case "Denied_payment":
                            return "Devis non accepté";
                          case "Accepted_payment":
                            return "Devis accepté";
                          case "Paymen_User":
                            return "Devis";
                          case "Accepted":
                            return "Accepté par client";
                          case "solved":
                            return "Résolu";
                          case "unsolved":
                            return "Non résolu";
                          case "Full":
                            return "Complète";
                          case "claime":
                            return "Réclamation résolu";
                          case "litige_fixed":
                            return "Litige résolu";
                          case "annulled":
                            return "Annulée";
                          case "admin_accept":
                            return "Contrat Validée";
                          default:
                            return item.status;
                        }
                      })()}
                    </p>
                  </div>
                  <div>

                    <button
                      onClick={() => navigate(`/contrat/${item._id}`)}
                      className="inline-flex rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 mt-4"
                    >
                      Consulter le contrat
                    </button>
                  </div>
                </div>
              </div>
            </div>
                    ))}*/}
        <ContractCard history={filterData()} userType={userType}/>
        </div>
      </div>
    </div>
  );
};

export default ContractList;
