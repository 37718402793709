import React from "react";
import {
  Banner,
  CenterComponent,
  Footer,
  LeBonTechnicien2grid,
  LeBonTechnicien3grid,
  LeBonTechnicienWorks,
  EquipmentSection,
} from "../../Components";

import {
  LebonTechnicien_data,
  CommentCaMarche_data,
  Nos_prestations,
  Nos_valeur,
  Equipements_data,
  Experts_data,
} from "../../Constants/dummy";
import { Helmet } from "react-helmet";

import icon4 from "../../Assets/media/installation.png";
import icon5 from "../../Assets/media/reparation.png";
import icon6 from "../../Assets/media/maintenance.png";
import fonctionnement from "../../Assets/media/nos-activites.png";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>Nos activités - LeBonTechnicien.fr </title>
        <meta
          name="keywords"
          content="installations et réparations des équipements d'énergie renouvelable"
        />
        <meta
          name="description"
          content="Chez LeBonTechnicien.fr, nous sommes experts en installations et réparations des équipements d'énergie renouvelable. Alliant savoir-faire et passion, notre équipe qualifiée met à profit les dernières innovations pour garantir un avenir durable."
        />
        <link rel="canonical" href="http://lebontechnicien.fr/activites" />
      </Helmet>
      <Banner>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 order-xl-1 compress-left-side d-flex align-items-center">
              <div className="banner-info">
                <h1 className="leBonTech-h1 item-main-title">Nos activités</h1>
                <p className="leBonTech-p">
                  Chez LeBonTechnicien.fr, nous sommes experts en installations
                  et réparations des équipements d'énergie renouvelable. Alliant
                  savoir-faire et passion, notre équipe qualifiée met à profit
                  les dernières innovations pour garantir un avenir durable.
                  Fiers de notre approche personnalisée, nous offrons des
                  solutions sur mesure, adaptées à chaque besoin. Engagés pour
                  un monde plus vert, nous sommes convaincus que le futur est
                  entre nos mains. Choisissez LeBonTechnicien.fr et prenez part
                  à la transition énergétique avec un partenaire de confiance.
                </p>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 d-flex align-items-center">
              <div className="item-figure">
                <img src={fonctionnement} alt="Comment ça marche" />
              </div>
            </div>
          </div>
        </div>
      </Banner>

      <LeBonTechnicien3grid
        bigTitle={Nos_prestations.title}
        subTitle={Nos_prestations.subTitle}
        titles={[
          Nos_prestations.items[0].heading,
          Nos_prestations.items[1].heading,
          Nos_prestations.items[2].heading,
        ]}
        descriptions={[
          Nos_prestations.items[0].description,
          Nos_prestations.items[1].description,
          Nos_prestations.items[2].description,
        ]}
        icons={[icon4, icon5, icon6]}
      />

      <EquipmentSection
        key={Equipements_data.equipments.title} // use the equipment title as the key
        bigtitle="Les équipements d'énergie renouvelable gérés par nos experts"
        subtitle={Equipements_data.equipments.imageAlt}
        picture={Equipements_data.equipments.imageSrc}
        paragraph={Equipements_data.equipments.description}
        data={Equipements_data.equipments}
      />

      <EquipmentSection
        key={Experts_data.equipments.title} // use the equipment title as the key
        bigtitle="Rencontrez nos experts en énergie renouvelable"
        subtitle={Experts_data.equipments.imageAlt}
        picture={Experts_data.equipments.imageSrc}
        paragraph={Experts_data.equipments.description}
        data={Experts_data.equipments}
      />
    </>
  );
};

export default AboutPage;
