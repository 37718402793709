import React from "react";
import { useNavigate } from "react-router-dom";

const HistoryCards = ({ history }) => {
  const navigate = useNavigate();
  console.log("historyCards", history);
  return (
    <div className="flex justify-start gap-4 flex-wrap w-full">
      {Array.isArray(history) && history.length > 0 ? (
        history.map((item, index) => (
          <div
            key={index}
            className="rounded-[10px] border border-[#D9D9D9] w-[24%] !h-[320px]"
          >
            <div>
              <div className="bg-[#FAFAFA] p-4 rounded-t-[10px]">
                <p className="text-[15px] text-center">
                  {item.type ? `${item.type}` : "-"}
                </p>
              </div>

              <>
                <div className="p-4 text-sm ">
                  <p className="mb-1">
                    Nom & prénom du client : {item.nom} {item.prenom}
                  </p>
                  <p className="mb-1">Numéro du client: {item.telephone}</p>
                  <p className="mb-1">Adresse du client: {item.adresse} </p>
                  <p className="mb-1">
                    Date de la demande :{" "}
                    {/^\d{2}\/\d{2}\/\d{4}$/.test(item.created_at)
                      ? item.selectedDate
                      : new Date(item.created_at).toLocaleDateString("fr-FR")}
                  </p>
                  <p className="mb-1">Type De Prestation : {item.typeDePrestation}</p>
                  <p className="mb-1">Statut de l'intervention: {item.status}</p>
                </div>
                <div className="p-4 w-full">
                  <button
                    onClick={() =>
                      navigate(`/user-intervention-details/${item._id}`)
                    }
                    className="w-full text-center  rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 "
                  >
                    Consulter l'intervention
                  </button>
                </div>
              </>
            </div>
          </div>
        ))
      ) : (
        <p>Pas d'historique disponible</p>
      )}
    </div>
  );
};

export default HistoryCards;
