import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; 

const ModificationModal = ({ isOpen, closeModal, taskToEdit, onSuccess, sendDataToParent, id, nomEquipe, idEquipe, newAcceptedBy }) => {
  const [selectedDate, setSelectedDate] = useState(taskToEdit?.date ? taskToEdit?.date : taskToEdit?.date_intervention);
  const [childData, setChildData] = useState("");
  const [calendars, setCalendars] = useState([]);
  const [datepickerOpen, setDatepickerOpen] = useState(false);

  const handleDateChange = (id, date) => {
    setSelectedDate(date);
    setChildData({ id, date });
  };
  
  const apiUrl = 'https://lebontechnicien.net'

  // Register the French locale for date-fns
  registerLocale("fr", fr);

  // Custom placeholder text in French
  const placeholderText = taskToEdit?.date
  ? taskToEdit.date.toLocaleString("fr-FR", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    })
  : taskToEdit?.date_intervention.toLocaleString("fr-FR", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%", // Adjust the width as needed
      maxWidth: "400px", // Limit the maximum width
      height: '400px',
    },
  };

  const handleClickUpdateStatusDate = async () => {
    try {
      // Get the current time zone offset in minutes
      const timezoneOffset = new Date().getTimezoneOffset();
  
      // Adjust the selected date by subtracting the offset
      const adjustedDate = new Date(
        selectedDate.getTime() - timezoneOffset * 60000
      );
  
      const formData = new FormData();
      formData.append('rid', taskToEdit._id);
      formData.append('date_intervention', adjustedDate);
      formData.append("status", "Accepted");
      formData.append("raisonSociale", sessionStorage.getItem("raisonSocial"));
      formData.append("Technicien", sessionStorage.getItem("nom"));
      formData.append("Technicienprenom", sessionStorage.getItem("prenom"));
      formData.append("temp_status", "Planned");
      formData.append("accepted_by", sessionStorage.getItem("id"));
      formData.append("TeamName", nomEquipe);
      formData.append("TeamID", idEquipe);



  
      const response = await fetch(`${apiUrl}/request/update`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
  
      if (response.ok) {
        // Task status and date have been updated successfully
        const data = await response.json();
        sendDataToParent(childData);
        onSuccess();
        closeModal();
        // Handle the response data here if needed
      } else {
        // Handle the case where the request is not successful
        console.error(`Request failed with status ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };


  const getCalendars = async () => {
    try {
      // Make a GET request using the fetch API
      const response = await fetch(`${apiUrl}/calenders/find_calender`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({}),
      });

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // If the response is successful, you can handle the result here
        const result = await response.json();
        // Filter the result based on the condition Team === id
        const filteredCalendars = result.filter(calendar => calendar.Team === id);
        setCalendars(filteredCalendars);
      } else {
        // Handle other response statuses or errors here
        console.error("Error:", response.status);
      }
    } catch (error) {
      // Handle any network or fetch errors
      console.error("Fetch error:", error);
    }
  };

  // Call the function to send the GET request
  useEffect(() => {
    getCalendars();
  }, []);

  // Get the current date
  const currentDate = new Date();

  // Extract disabled dates from planning
  const disabledDates = calendars.flatMap((plan) => {
    const departure = new Date(plan.departureDate);
    const returnD = new Date(plan.returnDate);

    // Get all dates within the range of any planning entry
    const datesInRange = [];
    let currentDate = new Date(departure);

    while (currentDate <= returnD) {
      datesInRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesInRange;
  });

  // Add today's date to the disabled dates
  disabledDates.push(currentDate);

  // Function to check if a date is disabled
  const isDateDisabled = (date) => {
    // Disable dates before the current date
    if (date < currentDate) {
      return true;
    }

    // Disable today's date and dates within the range of any planning entry
    return disabledDates.some((disabledDate) => {
      return (
        date.getDate() === disabledDate.getDate() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getFullYear() === disabledDate.getFullYear()
      );
    });
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Filter Modal"
      style={customStyles}
    >
      <h1 className="text-lg font-bold text-center mb-2">
        Modifier l'intervention
      </h1>
      {taskToEdit && (
        <>
          <div className="">
            <p className="font-bold">Date: </p>
            {taskToEdit &&  (
              <>
                <DatePicker
                  className="w-[300px] text-black "
                  selected={selectedDate}
                  onChange={(date) => handleDateChange(taskToEdit._id, date)}
                  minDate={currentDate}
                  excludeDates={disabledDates}
                  showTimeSelect
                  timeIntervals={120}
                  dateFormat="MMMM d, yyyy HH:mm" 
                  locale="fr"
                  placeholderText={placeholderText}
                  minTime={new Date(2023, 10, 26, 8, 0)} 
                  maxTime={new Date(2023, 10, 26, 20, 0)} 
                />
              </>
            )}
          </div>
          <button
            className="bg-green-700 text-white p-2 rounded-md mt-2"
            onClick={handleClickUpdateStatusDate}
          >
            Valider l'intervention
          </button>
        </>
      )}
    </Modal>
  );
};

export default ModificationModal;