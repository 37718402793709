import React, { useEffect, useState } from "react";
import {
  BreadCrumb,
  CRUDTable,
  Detail,
  Modal,
  PdfViewer,
  Rating,
  ReturnButton,
} from "../../../Components";
import { useParams } from "react-router";
import { IoEyeOutline } from "react-icons/io5";
import { useStateContext } from "../../../contexts/ContextProvider";
import { IoAddCircleOutline } from "react-icons/io5";
import PDFViewer from "pdf-viewer-reactjs";
import RatingSystem from "../../../Components/Dashboard/User/RatingSystem";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import pdfImage from "../../../Assets/pdf.png";

const ContractDetails = () => {
  const [history, setHistory] = useState([]);
  const [history2, setHistory2] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");
  const [productFetched, setProductFetched] = useState("");
  const { id } = useParams();
  const [entreprise, setEntreprise] = useState("non");
  const [product, setProduct] = useState("");
  const [userData, setUserData] = useState("");
  const [identifiant, setIdentifiant] = useState("");
  const [acceptationnModal, setAcceptationnModal] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [prod_photoDuProduit, setPhotoDuProduit] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const [returnedIdentifier, setReturnedIdentifier] = useState("");
  const [identifierError, setIdentifianError] = useState("");
  const [showModal, setShowModal] = useState(false);


  console.log("identifierError", identifierError);

  const apiUrl = 'https://lebontechnicien.net'

  const handleIdentifiantChange = (event) => {
    setIdentifiant(event.target.value);
  };

  const handleRecaptcha = (value) => {
    console.log("reCAPTCHA value:", value);
    setRecaptchaValue(value);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setEntreprise(sessionStorage.getItem("entreprise"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      fetch(`${apiUrl}/request/history`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          const filteredData = data.filter(
            (item) =>
              item.created_by === sessionStorage.getItem("id") &&
              item.type === "Demande de Maintenance" &&
              item._id === id
          );
          setHistory(filteredData.reverse());
          setFilteredData(filteredData);
          console.log("filteredData", filteredData);

          // Get all the different requests
          const differentRequests = Array.from(
            new Set(filteredData.map((item) => item.requestID))
          );

          console.log("differentRequests", differentRequests);

          // Call the endpoint again without the filter
          fetch(`${apiUrl}/request/history`, {
            method: "GET",
          })
            .then((res) => res.json())
            .then((data) => {
              console.log("data2", data);
              const allRequests = data.filter((item) =>
                differentRequests.includes(item._id)
              );
              console.log("allRequests", allRequests);
              // Process the data for all requests
              setHistory2(allRequests);
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("history2", history2);
  }, [history2]);

  const handleAcceptationModal = () => {
    setAcceptationnModal(!acceptationnModal);
  };

  //Fetch request to the list of products
  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/prods/user_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            email: `${sessionStorage.getItem("email")}`,
          }),
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        const filteredPendingList = data.filter((item) => item._id === id);
        setPendingList(data);
        console.log("Pendy Pend", pendingList);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  const selectedHistory = history.find((h) => h._id === id);
  const selectedHistory2 = history2.find((h) => h._id === id);
  const Productss = selectedHistory?.productID;
  const user = selectedHistory?.requestID;
  const userType = (selectedHistory?.contractType || "") === "user";
  const requestType = (selectedHistory?.contractType || "") === "request";
  const date = new Date(selectedHistory?.created_at);
  const productToFetch = selectedHistory?.created_for;
  // Get the day, month, and year from the date object
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;

  const openModalImages = (index) => {
    setSelectedImageIndex(index);
    console.log("selectedImageIndex: " + selectedImageIndex);
    setModalOpen(true);
  };

  const closeModalImages = () => {
    setModalOpen(false);
  };

  const downloadFile = (url) => {
    if (url.endsWith(".pdf")) {
      // If it's a PDF, open it in a new tab/window for download.
      window.open(url, "_blank");
    } else {
      // For other file types, initiate a download using the `fetch` API.
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "downloaded_file";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Download error: ", error));
    }
  };

  const handlePhotoDuProduit = (event) => {
    event.preventDefault();
    const newFiles = event.target.files;
    const existingFiles = prod_photoDuProduit
      ? Array.from(prod_photoDuProduit)
      : [];

    // Combine existing files with new files
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
    console.log("setPhotoDuProduithandleAddMorePictures", prod_photoDuProduit);
  };

  const handleAddMorePictures = (event) => {
    event.preventDefault();
    const newFiles = event.target.files;
    const existingFiles = prod_photoDuProduit
      ? Array.from(prod_photoDuProduit)
      : [];

    // Combine existing files with new files
    const allFiles = existingFiles.concat(Array.from(newFiles));
    setPhotoDuProduit(allFiles);
    console.log("setPhotoDuProduithandleAddMorePictures", prod_photoDuProduit);
  };

  const handleRemovePicture = (index) => {
    console.log(
      "Before removing picture - prod_photoDuProduit:",
      prod_photoDuProduit
    );

    // Ensure prod_photoDuProduit is always treated as an array
    const updatedFiles = Array.isArray(prod_photoDuProduit)
      ? prod_photoDuProduit.filter((_, i) => i !== index)
      : [];

    setPhotoDuProduit(updatedFiles);

    console.log("After removing picture - prod_photoDuProduit:", updatedFiles);
  };

  const photoKeys = selectedHistory
    ? Object.keys(selectedHistory).filter((key) =>
        key.startsWith("photoDuProduit")
      )
    : [];

  const handleGetProduct = async () => {
    const response = await fetch(`${apiUrl}/prods/get_product`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ id: Productss }),
    });
    const data = await response.json();
    //const filteredDataProduct = data.filter(item => item._id === Product);
    setProduct(data);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      if (!history) {
        // Wait for history to be set
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (history) {
              clearInterval(intervalId);
              resolve();
            }
          }, 100);
        });
      }

      try {
        const response = await fetch(`${apiUrl}/prods/get_product`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: productToFetch }),
        });

        if (!response.ok) {
          console.error(`Erro Fetching the product`);
          return;
        }

        const data = await response.json();
        setProductFetched(data);
      } catch (error) {
        // Handle any unexpected errors here.
        console.error("Error fetching product:", error);
      }
    };

    // Call the fetchProduct function when the component mounts
    fetchProduct();
  }, [productToFetch, history]);

  useEffect(() => {
    handleGetProduct();
    console.log("productData", product);
  }, [history, Productss]);

  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: user }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }

        setUserData(data);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, [history, Productss]);

  //Client updates contract
  const handleAddPhotos = async () => {
    const email = sessionStorage.getItem("email");
    const formData = new FormData();
    formData.append("rid", id);
    formData.append("status", "Contrat signé");

    if (prod_photoDuProduit && prod_photoDuProduit.length) {
      let startingIndex = photoKeys.length >= 0 ? photoKeys.length : 0;
      for (let i = 0; i < prod_photoDuProduit.length; i++) {
        formData.append(
          `photoDuProduit[${startingIndex}]`,
          prod_photoDuProduit[i]
        );
        startingIndex++;
      }
    }

    const response = await fetch(`${apiUrl}request/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: formData,
    });

    //const data = await response.json();
  };

  //return validation code:
  /*useEffect(() => {
    const fetchIdentifierData = async () => {
      try {
        const formData = new FormData();
        formData.append("rid", id);

        const response = await fetch(`${apiUrl}/admin/accept_mrequest`, {
          method: "POST",
          headers: {
            // No need to set Content-Type for FormData
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: formData,
        });

        const data = await response.json();
        console.log("ReceivedIdentifier", data);
        setReturnedIdentifier(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchIdentifierData();
  }, []); // Empty dependency array ensures useEffect runs only once on mount*/

  //Update Status to signed
  const handleClickUpdatestatustoSigned = async (e) => {
    e.preventDefault();
    setConfirmationModal(true);

    try {
      const formData = new FormData();
      formData.append("code", identifiant);
      formData.append("rid", id);
      formData.append("status", "Contrat signé");

      if (prod_photoDuProduit && prod_photoDuProduit.length) {
        let startingIndex = photoKeys.length >= 0 ? photoKeys.length : 0;
        for (let i = 0; i < prod_photoDuProduit.length; i++) {
          formData.append(
            `photoDuProduit[${startingIndex}]`,
            prod_photoDuProduit[i]
          );
          startingIndex++;
        }
      }

      const confirmRequestOptions = {
        method: "POST",
        headers: {
          // No need to set Content-Type for FormData
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: formData,
      };

      const confirmResponse = await fetch(
        `${apiUrl}admin/confirm_mrequest`,
        confirmRequestOptions
      );
      const confirmData = await confirmResponse.json();

      // Check if the first request was successful (HTTP status code 200)
      if (confirmData !== "wrong code") {
        console.log("Working");
        window.location.replace("/contrat");
      } else {
        // Handle errors for the first request
        console.error("Error in the first request:", confirmData);
        setIdentifianError(confirmData);
      }
    } catch (error) {
      console.error("General error:", error);
    }
  };

  return (
    <>
      {/*<BreadCrumb
        From={"Mes Contrats d’entretien"}
        To={"Détails du contrat"}
        hrefFrom={"/contrat"}
  />*/}
      <ReturnButton/>
      {entreprise === "non" && (
        <>
          {selectedHistory && (
            <>
              <>
                <div className="overflow-hidden bg-transparent shadow sm:rounded-lg w-[92%] ml-auto mr-auto mt-10 mb-10">
                  <div className="border-t border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <>
                      <dl>
                        {pendingList
                          .filter(
                            (product) =>
                              product._id === selectedHistory.created_for
                          )
                          .map((product, index) => (
                            <div key={index}>
                              {" "}
                              <div className="flex justify-around mt-6">
                                {selectedHistory.photoDuProduit && (
                                  <img
                                    src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                                    alt={product._id}
                                    className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                                  />
                                )}
                                <img
                                  src={`https://lebontechnicien.net/${product.QR}`}
                                  alt={product._id}
                                  className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                                />
                              </div>
                              <>
                                <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                  Détail du contrat:
                                </h3>
                                <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                  <dl>
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Date de la demande:
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {formattedDate}
                                        </dd>
                                      </dd>
                                    </div>
                                    {(selectedHistory.PickedDate ||
                                      selectedHistory.date_intervention) && (
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Date de l'intervention:
                                        </dt>

                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.PickedDate
                                            ? /^\d{2}\/\d{2}\/\d{4}$/.test(
                                                selectedHistory.PickedDate
                                              )
                                              ? selectedHistory.PickedDate
                                              : new Date(
                                                  selectedHistory.PickedDate
                                                ).toLocaleDateString("fr-FR")
                                            : new Date(
                                                selectedHistory.date_intervention
                                              ).toLocaleDateString(
                                                "fr-FR"
                                              )}{" "}
                                          entre{" "}
                                          {new Date(
                                            selectedHistory.date_intervention
                                          ).toLocaleTimeString("fr-FR", {
                                            hour: "numeric",
                                            hour12: false,
                                          })}{" "}
                                          et
                                          {new Date(
                                            new Date(
                                              selectedHistory.date_intervention
                                            ).getTime() +
                                              2 * 60 * 60 * 1000
                                          ).toLocaleTimeString("fr-FR", {
                                            hour: "numeric",
                                            hour12: false,
                                          })}
                                        </dd>
                                      </div>
                                    )}
                                    {selectedHistory.contractAmount && (
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Tarif du contrat
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {parseInt(
                                            selectedHistory.contractAmount,
                                            10
                                          ) * 1.2}{" "}
                                          €/an TTC
                                        </dd>
                                      </div>
                                    )}
                                    {selectedHistory.raison && (
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Tarif du contrat
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.raison}
                                        </dd>
                                      </div>
                                    )}
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Date de début
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                          selectedHistory.selectedDate
                                        )
                                          ? selectedHistory.selectedDate
                                          : new Date(
                                              selectedHistory.selectedDate
                                            ).toLocaleDateString("fr-FR")}
                                      </dd>
                                    </div>

                                    {selectedHistory.end_date && (
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Date de fin
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                            selectedHistory.end_date
                                          )
                                            ? selectedHistory.end_date
                                            : new Date(
                                                selectedHistory.end_date
                                              ).toLocaleDateString("fr-FR")}
                                        </dd>
                                      </div>
                                    )}
                                    {selectedHistory.Paiement && (
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Le RIB
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {selectedHistory.Paiement ===
                                          "Paiement effectué"
                                            ? "RIB validé"
                                            : "RIB non validé"}
                                        </dd>
                                      </div>
                                    )}
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Statut:
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {(() => {
                                          switch (selectedHistory.status) {
                                            case "Pending":
                                              return "En attente de Validation";
                                            case "closed":
                                              return "Cloturé";
                                            case "closed_tech":
                                              return "Cloturé par technicien";
                                            case "claim":
                                              return "Réclamation";
                                            case "Denied_payment":
                                              return "Devis refusé";
                                            case "Accepted_payment":
                                              return "Devis accepté";
                                            case "Paymen_User":
                                              return "Devis";
                                            case "Accepted":
                                              return "Accepté";
                                            case "claim_fix":
                                              return "Résolu";
                                            case "Email Pending":
                                              return "Email non validé";
                                            case "Confirmed":
                                              return "validé";
                                            case "In progress":
                                              return "En cours de vérification";
                                            case "Denied":
                                              return "Réfusé";
                                            case "solved":
                                              return "Résolu";
                                            case "unsolved":
                                              return "Non résolu";
                                            case "Full":
                                              return "Complète";
                                            case "litige_fixed":
                                              return "Litige résolu";
                                            case "claime_fixed":
                                              return "Réclamation résolu";
                                            case "annulled":
                                              return "Annulée";
                                            case "admin_accept":
                                              return "Contrat en attente de signature";
                                            default:
                                              return selectedHistory.status;
                                          }
                                        })()}
                                      </dd>
                                    </div>
                                  </dl>
                                </div>
                              </>
                              <>
                                <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                                  Détail de l'appareil:
                                </h3>
                                <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                                  <dl>
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Type d'appareil:
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {product.typeDuBien &&
                                          product.typeDuBien.includes(",")
                                            ? product.typeDuBien.split(",")[1]
                                            : product.typeDuBien}
                                        </dd>
                                      </dd>
                                    </div>
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Marque
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {selectedHistory.marque
                                          ? selectedHistory.marque === "autre"
                                            ? selectedHistory.AutreMarque
                                            : selectedHistory.marque
                                          : pendingList.marque}
                                      </dd>
                                    </div>
                                    {(selectedHistory.reference ||
                                      selectedHistory.reference ||
                                      pendingList.reference) && (
                                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                          Référence
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {product.reference
                                            ? product.reference
                                            : product.référence}
                                        </dd>
                                      </div>
                                    )}
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Maintenu
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {product.entretenu}
                                      </dd>
                                    </div>
                                    <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                      <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                        Puissance
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {product.puissance}
                                      </dd>
                                    </div>
                                  </dl>
                                </div>
                              </>
                            </div>
                          ))}
                      </dl>
                    </>
                  </div>
                  {selectedHistory.status === "admin_accept" && (
                    <div className="flex justify-end p-4">
                      <button
                        onClick={handleAcceptationModal}
                        className=" 
                    inline-flex 
                    rounded-md 
                    border 
                    border-transparent 
                    bg-green-700 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-green-600 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                  "
                      >
                        Signer le contrat
                      </button>
                    </div>
                  )}
                  {acceptationnModal && (
                    <>
                      <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                          className="fixed inset-0 w-full h-full bg-black opacity-40"
                          onClick={() => setAcceptationnModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                            <div className="mt-3 w-[100%]">
                              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                <h4 className="text-lg font-medium text-gray-800">
                                  Signer le contrat
                                </h4>
                                <div className="mt-4">
                                  <label
                                    htmlFor="reason"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Identifiant de validation
                                  </label>
                                  <input
                                    type="text"
                                    id="reason"
                                    name="reason"
                                    className="mb-2 mt-1 p-2 w-full border rounded-md focus:ring-green-500 focus:border-green-500"
                                    placeholder="Indiquez le montant du contrat..."
                                    onChange={handleIdentifiantChange}
                                  />
                                </div>

                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_SITE_KEY}
                                  onChange={handleRecaptcha}
                                />

                                {identifierError === "wrong code" && (
                                  <p className="text-red-500">
                                    "Code de validation invalide"
                                  </p>
                                )}
                                <div className="items-center gap-2 mt-3 sm:flex">
                                  <button
                                    className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                                    onClick={handleClickUpdatestatustoSigned}
                                  >
                                    Signer
                                  </button>
                                  <button
                                    className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                                    onClick={() => handleAcceptationModal()}
                                  >
                                    Annuler
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            </>
          )}
        </>
      )}
      {entreprise === "oui" && (
        <>
          <>
            {selectedHistory && requestType && (
              <>
                <div className="overflow-hidden bg-transparent shadow sm:rounded-lg w-[92%] ml-auto mr-auto mt-10">
                  <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                    <>
                      <dl>
                        {pendingList
                          .filter(
                            (product) =>
                              product._id === selectedHistory.created_for
                          )
                          .map((product, index) => (
                            <div key={index}>
                              {" "}
                              <div className="flex justify-around mt-6">
                                {product.photoDuProduit && (
                                  <img
                                    src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                                    alt={product._id}
                                    className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                                  />
                                )}
                                <img
                                 src={`https://lebontechnicien.net/${selectedHistory.QR}`}
                                  alt={selectedHistory._id}
                                  className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                                />
                              </div>
                              <>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Date de début:
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                        selectedHistory.selectedDate
                                      )
                                        ? selectedHistory.selectedDate
                                        : new Date(
                                            selectedHistory.selectedDate
                                          ).toLocaleDateString("fr-FR")}
                                    </dd>
                                  </dd>
                                </div>
                                {selectedHistory.end_date && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Date de fin:
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        {/^\d{2}\/\d{2}\/\d{4}$/.test(
                                          selectedHistory.end_date
                                        )
                                          ? selectedHistory.end_date
                                          : new Date(
                                              selectedHistory.end_date
                                            ).toLocaleDateString("fr-FR")}
                                      </dd>
                                    </dd>
                                  </div>
                                )}
                                {(selectedHistory.PickedDate ||
                                  selectedHistory.date_intervention) && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Date de l'intervention:
                                    </dt>

                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {selectedHistory.PickedDate
                                        ? /^\d{2}\/\d{2}\/\d{4}$/.test(
                                            selectedHistory.PickedDate
                                          )
                                          ? selectedHistory.PickedDate
                                          : new Date(
                                              selectedHistory.PickedDate
                                            ).toLocaleDateString("fr-FR")
                                        : new Date(
                                            selectedHistory.date_intervention
                                          ).toLocaleDateString("fr-FR")}{" "}
                                      entre{" "}
                                      {new Date(
                                        selectedHistory.date_intervention
                                      ).toLocaleTimeString("fr-FR", {
                                        hour: "numeric",
                                        hour12: false,
                                      })}{" "}
                                      et
                                      {new Date(
                                        new Date(
                                          selectedHistory.date_intervention
                                        ).getTime() +
                                          2 * 60 * 60 * 1000
                                      ).toLocaleTimeString("fr-FR", {
                                        hour: "numeric",
                                        hour12: false,
                                      })}
                                    </dd>
                                  </div>
                                )}
                                {selectedHistory.contractAmount && (
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Tarif du contrat
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {parseInt(
                                        selectedHistory.contractAmount,
                                        10
                                      ) * 1.2}{" "}
                                      €/an TTC
                                    </dd>
                                  </div>
                                )}
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Type d'appareil
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {history2.length > 0
                                      ? history2[0].typeDeProjet
                                      : "-"}
                                  </dd>
                                </div>
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Marque
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {history2.length > 0
                                      ? history2[0].marque
                                      : "-"}
                                  </dd>
                                </div>

                                {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Maintenu
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {product.maintenu !== '' ? product.maintenu : (filteredData.length > 0 ? filteredData.maintenance : "-")}
                                    </dd>
                                </div>*/}
                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Puissance
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {history2.length > 0
                                      ? history2[0].puissance
                                      : "-"}
                                  </dd>
                                </div>

                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Maintenu
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {history2.length > 0
                                      ? history2[0].entretenu
                                      : "-"}
                                  </dd>
                                </div>

                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Nom du client
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {history2.length > 0
                                      ? history2[0].nom
                                      : "-"}
                                  </dd>
                                </div>

                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Prénom du client
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {history2.length > 0
                                      ? history2[0].prenom
                                      : "-"}
                                  </dd>
                                </div>

                                <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                    Zone d'intervention du client
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {history2.length > 0
                                      ? history2[0].zone
                                      : "-"}
                                  </dd>
                                </div>
                              </>
                            </div>
                          ))}

                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Statut:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {(() => {
                              switch (selectedHistory.status) {
                                case "Pending":
                                  return "En attente de Validation";
                                case "closed":
                                  return "Validé";
                                case "closed_tech":
                                  return "Cloturé par technicien";
                                case "claim":
                                  return "Réclamation";
                                case "Denied_payment":
                                  return "Devis non accepté";
                                case "Accepted_payment":
                                  return "Devis accepté";
                                case "Paymen_User":
                                  return "Devis";
                                case "Accepted":
                                  return "Accepté par client";
                                case "solved":
                                  return "Résolu";
                                case "unsolved":
                                  return "Non résolu";
                                case "Full":
                                  return "Complète";
                                case "claime":
                                  return "Réclamation résolu";
                                case "litige_fixed":
                                  return "Litige résolu";
                                case "annulled":
                                  return "Annulée";
                                case "admin_accept":
                                  return "Contrat en attente de signature";

                                default:
                                  return selectedHistory.status;
                              }
                            })()}
                          </dd>
                        </div>
                      </dl>
                    </>
                  </div>
                </div>
              </>
            )}
          </>
          <>
            {selectedHistory && userType && (
              <>
                {product && (
                  <>
                    <div className="overflow-hidden bg-transparent shadow sm:rounded-lg w-[92%] ml-auto mr-auto mt-10">
                      <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                        <>
                          <dl>
                            <>
                              <div>
                                {" "}
                                <div className="flex justify-around mt-6">
                                  {product.photoDuProduit &&
                                    product.photoDuProduit !== "null" && (
                                      <img
                                        src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                                        alt={product._id}
                                        className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                                      />
                                    )}
                                  <img
                                    src={`https://lebontechnicien.net/${selectedHistory.QR}`}
                                    alt={product._id}
                                    className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                                  />
                                </div>
                                <>
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Type d'appareil
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {product.typeDuBien !== ""
                                        ? product.typeDuBien
                                        : "-"}
                                    </dd>
                                  </div>

                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Marque
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {product.marque !== ""
                                        ? product.marque
                                        : "-"}
                                    </dd>
                                  </div>

                                  {/*<div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                            Maintenu
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          {product.maintenu !== '' ? product.maintenu : (filteredData.length > 0 ? filteredData.maintenance : "-")}
                                          </dd>
                                      </div>*/}
                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Puissance
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {product.puissance !== ""
                                        ? product.puissance
                                        : "-"}
                                    </dd>
                                  </div>

                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Maintenu
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {product.entretenu !== ""
                                        ? product.entretenu
                                        : "-"}
                                    </dd>
                                  </div>

                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Nom du client
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {userData.nom !== "" ? userData.nom : "-"}
                                    </dd>
                                  </div>

                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Prénom du client
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {userData.prenom !== ""
                                        ? userData.prenom
                                        : "-"}
                                    </dd>
                                  </div>

                                  <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                      Zone d'intervention du client
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      {userData.zone !== ""
                                        ? userData.zone
                                        : "-"}
                                    </dd>
                                  </div>
                                </>
                              </div>
                            </>

                            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-black border-r border-solid border-black">
                                Statut:
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                {(() => {
                                  switch (selectedHistory.status) {
                                    case "Pending":
                                      return "En attente de Validation";
                                    case "closed":
                                      return "Validé";
                                    case "closed_tech":
                                      return "Cloturé par technicien";
                                    case "claim":
                                      return "Réclamation";
                                    case "Denied_payment":
                                      return "Devis non accepté";
                                    case "Accepted_payment":
                                      return "Devis accepté";
                                    case "Paymen_User":
                                      return "Devis";
                                    case "Accepted":
                                      return "Accepté par client";
                                    case "solved":
                                      return "Résolu";
                                    case "unsolved":
                                      return "Non résolu";
                                    case "Full":
                                      return "Complète";
                                    case "claime":
                                      return "Réclamation résolu";
                                    case "litige_fixed":
                                      return "Litige résolu";
                                    case "annulled":
                                      return "Annulée";
                                    case "admin_accept":
                                      return "Contrat en attente de signature";

                                    default:
                                      return selectedHistory.status;
                                  }
                                })()}
                              </dd>
                            </div>
                          </dl>
                        </>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
          <>
            {selectedHistory && (
              <>
                <div className="overflow-hidden bg-transparent shadow sm:rounded-lg w-[92%] ml-auto mr-auto mt-10 mb-10">
                  <div className="flex justify-around mt-6">
                    <img
                      src={`https://lebontechnicien.net/${selectedHistory.QR}`}
                      alt={productFetched._id}
                      className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                    />
                  </div>

                  {/*Detail du client*/}
                  <>
                    <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                      Détail du client:
                    </h3>
                    <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                      <dl>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Nom:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.nom_Pro}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Prenom:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.prenom_Pro}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Numéro de téléphone
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.telephone_pro}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Email
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.email_Pro}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Adresse
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.adresse}
                          </dd>
                        </div>
                        {selectedHistory.Paiement && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              RIB
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.Paiement === "Paiement effectué"
                                ? "RIB validé"
                                : "RIB non validé"}
                            </dd>
                          </div>
                        )}
                      </dl>
                    </div>
                  </>

                  {/*Detail de l'appareil*/}
                  <>
                    <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                      Détail de l'appareil:
                    </h3>
                    <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                      <dl>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Type d'appareil:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.selectedSubdomain &&
                              selectedHistory.selectedSubdomain.includes(",")
                                ? selectedHistory.selectedSubdomain.split(
                                    ","
                                  )[1]
                                : selectedHistory.selectedSubdomain}
                            </dd>
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Marque
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.marque
                              ? selectedHistory.marque === "autre"
                                ? selectedHistory.AutreMarque
                                : selectedHistory.marque
                              : pendingList.marque}
                          </dd>
                        </div>
                        {(selectedHistory.reference ||
                          selectedHistory.reference ||
                          pendingList.reference) && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Référence
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.reference
                                ? selectedHistory.reference
                                : selectedHistory.référence}
                            </dd>
                          </div>
                        )}
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Puissance
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.puissance}
                          </dd>
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date d'installation
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {selectedHistory.moisInstallation} /{" "}
                            {selectedHistory.dateInstallation}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </>

                  {/*Detail du contrat*/}
                  <>
                    <h3 className="text-lg font-bold leading-6 text-green-700 ml-20 mb-5">
                      Détail du contrat:
                    </h3>
                    <div className="border border-gray-200 w-[92%] ml-auto mr-auto mb-10">
                      <dl>
                        {selectedHistory.contractAmount && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Demandeur
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.demandeur}
                            </dd>
                          </div>
                        )}

                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Date de début
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {/^\d{2}\/\d{2}\/\d{4}$/.test(
                              selectedHistory.selectedDate
                            )
                              ? selectedHistory.selectedDate
                              : new Date(
                                  selectedHistory.selectedDate
                                ).toLocaleDateString("fr-FR")}
                          </dd>
                        </div>
                        {selectedHistory.contractAmount && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Tarif du contrat
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {parseInt(selectedHistory.contractAmount, 10) *
                                1.2}{" "}
                              €/an TTC
                            </dd>
                          </div>
                        )}
                        {selectedHistory.raison && (
                          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-black border-r border-solid border-black">
                              Raison de Refus
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {selectedHistory.raison}
                            </dd>
                          </div>
                        )}

                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Photos & documents
                          </dt>
                          <dd
                            className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                            style={{
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <div className="flex flex-wrap gap-4 ">
                              {photoKeys.map((photoKey, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col items-center w-[200px] mb-4"
                                >
                                  {selectedHistory[photoKey].endsWith(
                                    ".pdf"
                                  ) ? (
                                    <a
                                      href={`${apiUrl}${selectedHistory[photoKey]}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={pdfImage} // PDF icon image link
                                        alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                        className="max-w-full h-[100px] cursor-pointer"
                                      />
                                    </a>
                                  ) : (
                                    <img
                                      className="max-w-full h-[100px] cursor-pointer"
                                      src={`https://lebontechnicien.net/${selectedHistory[photoKey]}`}
                                      alt={`Photoduprobleme ${index + 2}`} // Add 2 to the index to skip the first file
                                      onClick={() => openModalImages(index + 1)} // Add 1 to the index to skip the first file
                                    />
                                  )}
                                  <div className=" mt-2">
                                    <p className="whitespace-normal text-center">
                                      {selectedHistory[photoKey]
                                        .split("/")
                                        .pop()}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </dd>
                          {modalOpen && (
                            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                              <div className="bg-black opacity-50 w-full h-full absolute"></div>
                              <div className="bg-white p-8 rounded-lg relative z-10 w-[90%] ml-auto mr-auto">
                                <span
                                  className="close flex justify-end cursor"
                                  onClick={closeModalImages}
                                >
                                  &times;
                                </span>
                                {photoKeys[selectedImageIndex].endsWith(
                                  ".pdf"
                                ) ? (
                                  <button
                                    onClick={() => {
                                      console.log(
                                        photoKeys[selectedImageIndex]
                                      );
                                      downloadFile(
                                        `${apiUrl}${
                                          selectedHistory[
                                            photoKeys[selectedImageIndex]
                                          ]
                                        }`
                                      );
                                    }}
                                  >
                                    Download PDF
                                  </button>
                                ) : (
                                  <img
                                    className="w-[40%] flex justify-center ml-auto mr-auto"
                                    src={`https://lebontechnicien.net/${
                                      selectedHistory[
                                        photoKeys[selectedImageIndex]
                                      ]
                                    }`}
                                    alt={`Photoduprobleme ${
                                      selectedImageIndex + 1
                                    }`}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-black border-r border-solid border-black">
                            Statut:
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {(() => {
                              switch (selectedHistory.status) {
                                case "Pending":
                                  return "En attente de Validation";
                                case "closed":
                                  return "Cloturé";
                                case "closed_tech":
                                  return "Cloturé par technicien";
                                case "claim":
                                  return "Réclamation";
                                case "Denied_payment":
                                  return "Devis refusé";
                                case "Accepted_payment":
                                  return "Devis accepté";
                                case "Paymen_User":
                                  return "Devis";
                                case "Accepted":
                                  return "Accepté";
                                case "claim_fix":
                                  return "Résolu";
                                case "Email Pending":
                                  return "Email non validé";
                                case "Confirmed":
                                  return "validé";
                                case "In progress":
                                  return "En cours de vérification";
                                case "Denied":
                                  return "Réfusé";
                                case "solved":
                                  return "Résolu";
                                case "unsolved":
                                  return "Non résolu";
                                case "Full":
                                  return "Complète";
                                case "litige_fixed":
                                  return "Litige résolu";
                                case "claime_fixed":
                                  return "Réclamation résolu";
                                case "annulled":
                                  return "Annulée";
                                case "admin_accept":
                                  return "Contrat en attente de signature";
                                case "admin_denie":
                                  return "Contrat Refusée";
                                default:
                                  return selectedHistory.status;
                              }
                            })()}
                          </dd>
                        </div>
                        {acceptationnModal && (
                          <>
                            <div className="fixed inset-0 z-10 overflow-y-auto">
                              <div
                                className="fixed inset-0 w-full h-full bg-black opacity-40"
                                onClick={() => setAcceptationnModal(false)}
                              ></div>
                              <div className="flex items-center min-h-screen px-4 py-8">
                                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                  <div className="mt-3 w-[100%]">
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                      <h4 className="text-lg font-medium text-gray-800">
                                        Signer le contrat
                                      </h4>
                                      <div className="mt-4">
                                        <label
                                          htmlFor="reason"
                                          className="block text-sm font-medium text-gray-700 mb-1"
                                        >
                                          Identifiant de validation
                                        </label>
                                        <input
                                          type="text"
                                          id="reason"
                                          name="reason"
                                          className="mb-2 mt-1 p-2 w-full border rounded-md focus:ring-green-500 focus:border-green-500"
                                          placeholder="Indiquez le montant du contrat..."
                                          onChange={handleIdentifiantChange}
                                        />
                                      </div>
                                      
                                      <label
                                          htmlFor="reason"
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          Le contrat signé : veuillez scanner ou prendre en photo la dernière page du contrat signé et l'ajouter ici
                                        </label>
                                      <div>
                                        {prod_photoDuProduit && (
                                          <div className="mt-4 flex flex-between w-full gap-8">
                                            <div className="flex items-center flex-wrap w-[80%] gap-4">
                                              {Array.from(
                                                prod_photoDuProduit
                                              ).map((file, index) => (
                                                <div
                                                  key={index}
                                                  className="w-[150px]"
                                                >
                                                  <div className="flex justify-center">
                                                    {file.name
                                                      .toLowerCase()
                                                      .endsWith(".pdf") ? (
                                                      <>
                                                        <img
                                                          src={pdfImage}
                                                          alt={`PDF Preview-${index}`}
                                                          className="h-16 w-16 object-cover rounded"
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <img
                                                          src={URL.createObjectURL(
                                                            file
                                                          )}
                                                          alt={`Preview-${index}`}
                                                          className="h-16 w-16 object-cover rounded"
                                                        />
                                                      </>
                                                    )}
                                                    <button
                                                      className="bg-gray-100 p-1 rounded-sm text-[10px] mb-10 mr-[-4px] font-bold text-red-500"
                                                      onClick={() =>
                                                        handleRemovePicture(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      X
                                                    </button>
                                                  </div>
                                                  <div className="w-[150px] h-[100px] mt-4 flex justify-center">
                                                    <p className="text-sm text-center">
                                                      {file.name}
                                                    </p>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                            <div className="flex gap-2">
                                              <div className="mt-4">
                                                <label
                                                  htmlFor="photoDuProduit"
                                                  className=""
                                                >
                                                  {/*<IoAddCircleOutline className="h-10 w-10 text-green-500" />*/}
                                                  <svg
                                          className="mx-auto h-12 w-12 text-gray-400 pointer"
                                          stroke="currentColor"
                                          fill="none"
                                          viewBox="0 0 48 48"
                                          aria-hidden="true"
                                        >
                                          <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        <p className="text-sm">Ajouter un fichier </p>
                                                  <input
                                                    id="photoDuProduit"
                                                    name="photoDuProduit"
                                                    type="file"
                                                    multiple
                                                    className="sr-only"
                                                    onChange={
                                                      handleAddMorePictures
                                                    }
                                                  />
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {(!prod_photoDuProduit ||
                                          prod_photoDuProduit.length === 0) && (
                                          <div className="mb-2 relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                            <div className="space-y-1 text-center">
                                              <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                              >
                                                <path
                                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                  strokeWidth={2}
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>

                                              <div className="flex text-sm text-gray-600 justify-center">
                                                <label
                                                  htmlFor="photoDuProduit"
                                                  className="relative cursor-pointer rounded-md bg-white font-medium text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 hover:text-green-500"
                                                >
                                                  <span>
                                                    Ajouter un fichier{" "}
                                                  </span>
                                                  <input
                                                    id="photoDuProduit"
                                                    name="photoDuProduit"
                                                    type="file"
                                                    multiple
                                                    className="sr-only"
                                                    value=""
                                                    onChange={
                                                      handlePhotoDuProduit
                                                    }
                                                  />
                                                </label>
                                                <p className="pl-1">
                                                  ou faites glisser et déposez
                                                </p>
                                              </div>
                                              <p className="text-xs text-gray-500">
                                                PNG, JPG, PDF
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                      <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        onChange={handleRecaptcha}
                                      />
                                      {identifierError === "wrong code" && (
                                        <p className="text-red-500">
                                          Code de validation invalide
                                        </p>
                                      )}
                                      <div className="items-center gap-2 mt-3 sm:flex">
                                        <button
                                          className="w-full mt-2 p-2.5 flex-1 text-white bg-green-600 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                                          onClick={
                                            handleClickUpdatestatustoSigned
                                          }
                                        >
                                          Signer
                                        </button>
                                        <button
                                          className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-transparent-600 focus:ring-2"
                                          onClick={() =>
                                            handleAcceptationModal()
                                          }
                                        >
                                          Annuler
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </dl>
                    </div>
                  </>

                  {selectedHistory.status === "admin_accept" && (
                    <div className="flex justify-end p-4">
                      <button
                        onClick={handleAcceptationModal}
                        className=" 
                    inline-flex 
                    rounded-md 
                    border 
                    border-transparent 
                    bg-green-700 
                    py-2 
                    px-4 
                    text-sm 
                    font-medium 
                    text-white 
                    shadow-sm hover:bg-green-600 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-indigo-500 
                    focus:ring-offset-2
                    mt-4
                  "
                      >
                        Signer le contrat
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        </>
      )}
    </>
  );
};

export default ContractDetails;