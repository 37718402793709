import React from "react";
import { Outlet } from 'react-router';
import Navbar from "../LandingPage/Navbar";

const NoNavbarLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default NoNavbarLayout;