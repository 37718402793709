import React, { useEffect, useState } from "react";
import LogoBig from "../../../Assets/Logo.svg";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  // initialize states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [type, setType] = useState("");
  const [proTech, setProTech] = useState("");
  const [profile, setProfile] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [recaptchaerror, setRecaptchaError] = useState("");
  const [entreprise, setEntreprise] = useState("non");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userStatus, setUserStatus] = useState(null);

  /*const apiUrl =
    process.env.REACT_APP_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_PRE_PROD;*/

    const apiUrl = 'https://lebontechnicien.net'

  console.log(process.env.REACT_APP_ENV)
  console.log("apiUrl", apiUrl)

  // handle recaptcha value change
  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  // redirect user based on user type, status and profile completion status
  const redirectBasedOnUserType = () => {
    console.log("redirecting");

    if (sessionStorage.getItem("User_type") === "User") {
      window.location.href = "/tableau-de-bord";
    } else if (

      sessionStorage.getItem("User_type") === "Tech" &&
      (sessionStorage.getItem("TechPro") === "non" &&
        sessionStorage.getItem("ProTeam") === "non" )
    ) {
      console.log("entered normal tech")
      window.location.href = "/tableau-de-bord-tech";
    } else if (sessionStorage.getItem("User_type") === "Admin") {
      window.location.href = "/tableau-de-bord-admin";
    } else if (sessionStorage.getItem("User_type") === "Superviseur") {
      window.location.href = "/tableau-de-bord-superviseur";
    } else if (
      sessionStorage.getItem("User_type") === "Tech" &&
      sessionStorage.getItem("TechPro") === "oui" &&
      sessionStorage.getItem("ProTeam") === "non"
    ) {
      window.location.href = "/mon-compte-tech";
    } else if (
      sessionStorage.getItem("User_type") === "Tech" &&
      sessionStorage.getItem("ProTeam") === "oui" &&
      sessionStorage.getItem("TechPro") === "non"
    ) {
      window.location.href = "/mon-compte-team-pro";
    }
  };

  useEffect(() => {
    redirectBasedOnUserType();
  }, [userStatus]);

  // handle email input change
  const handleEmail = (event) => {
    setEmail(event.target.value.toLowerCase());
  };

  // handle password input change
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  // handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    /*if (!recaptchaValue || recaptchaValue === "") {
      setRecaptchaError("Veuillez vérifier le Google reCAPTCHA");
      return;
    }*/
    fetch(`${apiUrl}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
        recaptchaValue: recaptchaValue,
      }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          if (data.token) {
            setIsLoggedIn(true);
            setProfile(data?.profile);

            console.log("type on login", type);
            console.log("profile on login", profile);
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("logged", "true");
            sessionStorage.setItem("email", email);
            sessionStorage.setItem("profile", data.profile);
            sessionStorage.setItem("entreprise", data?.user?.entreprise);
            if (data && data?.user?.telephone) {
              sessionStorage.setItem("phone", data?.user?.telephone);
            }

            if (data && data?.user?.adresse) {
              sessionStorage.setItem("adresse", data?.user?.adresse);
            }

            if (data && data?.user?.type) {
              sessionStorage.setItem("User_type", data?.user?.type);
            }

            if (data && data?.user?.ProTeam) {
              sessionStorage.setItem("ProTeam", data?.user?.ProTeam);
            }


            if (data && data?.user?.ProTech) {
              sessionStorage.setItem("TechPro", data?.user?.ProTech);
            }

            if (data && data?.user?.status) {
              sessionStorage.setItem("typetech", data?.user?.status);
            }

            if (data && data?.user?.postale) {
              sessionStorage.setItem("zipcode", data?.user?.postale);
            }

            if (data && data?.user?.raisonsocial) {
              sessionStorage.setItem("raisonsocial", data?.user?.raisonsocial);
            }

            if (data && data?.user?.raison) {
              sessionStorage.setItem("raison", data?.user?.raison);
            }

            if (data && data?.user?.domaine) {
              sessionStorage.setItem("domaine", data?.user?.domaine);
            }

            if (data && data?.user?.siret) {
              sessionStorage.setItem("siret", data?.user?.siret);
            }

            if (data && data?.user?.zone) {
              sessionStorage.setItem("zone", data?.user?.zone);
            }

            redirectBasedOnUserType();
          } else {
            // handle different error messages
            if (data === "Incorrect Password") {
              setLoginError("Mot de passe incorrect");
            } else if (data === "No such user") {
              setLoginError("Aucun utilisateur");
            } else if (data === "Plase provide an email and password") {
              setLoginError("Veuillez fournir un email et un mot de passe");
            } else if (data === "User non confirmed") {
              setLoginError(
                "Utilisateur non confirmé, Veuillez suivre le lien fourni dans l'e-mail pour valider votre inscription."
              );
            } else if (data === "Tech non-confirmed by admin") {
              setLoginError(
                "Votre compte est en cours de vérification par notre équipe. Nous vous tiendrons informé(e) dès qu'il sera activé."
              );
            } else {
              setError(data);
              console.log(error);
              console.log("loginError", loginError);
              console.log("logindata", data);
            }
          }
        } else {
          const data = await res.json();
          setError(data.message);
        }
      })
      .catch((error) => {
        setError("An error occurred while logging in");
        console.error(error);
      });
  };

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 login-wrapper">
        <div className="w-full max-w-md space-y-8">
          <div>
            <Link to="https://lebontechnicien.fr/">
              <img className="mx-auto h-20 w-auto" src={LogoBig} alt="Logo" />
            </Link>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight authentifciation-text ">
              Connexion
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email" className="mb-4">
                  Adresse e-mail
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleEmail}
                  required
                  className=" margin-top relative p-16 block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Adresse e-mail"
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <label htmlFor="password" className="mb-2 mt-4">
                  Mot De Passe
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={handlePassword}
                  autoComplete="current-password"
                  required
                  className="relative p-16 block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Mot de passe"
                />
              </div>
              {loginError && loginError.length > 0 && (
                <div style={{ color: "red" }}>{loginError}</div>
              )}
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                <a
                  href="/récupérer"
                  className="font-medium text-indigo-600 hover:text-indigo-500 forgot-password"
                >
                  Mot de passe oublié?
                </a>
              </div>
            </div>

            <div>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={handleRecaptcha}
              />

              {recaptchaerror && recaptchaerror.length > 0 && (
                <div style={{ color: "red" }}>{recaptchaerror}</div>
              )}

              <button
                type="submit"
                className=" mt-4 submit-button group relative flex w-full justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2  focus:ring-offset-2"
              >
                Connexion
              </button>
            </div>
          </form>
          <div>
            <h5 className="text-center text-gray-100 register-text">
              {" "}
              Vous n'avez pas de compte?{" "}
              <span className=" register-span">
                <Link to="/inscription">Créer un compte</Link>
              </span>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
