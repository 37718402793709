import React, { useState } from "react";
import { BreadCrumb } from "../../../Components";
import {
  validateEmail,
  validatePassword,
} from "../../../Components/FormValidation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUser = () => {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordCheckError, setPasswordCheckError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [userType, setUserType] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [registerError, setRegisterError] = useState("");
  const apiUrl = 'https://lebontechnicien.net'

  const handleNom = (event) => {
    setNom(event.target.value);
  };

  const handlePrénom = (event) => {
    setPrenom(event.target.value);
  };

  const handlePasswordCheck = (event) => {
    setPasswordCheck(event.target.value);
    if (password !== event.target.value) {
      setPasswordCheckError("Les mots de passe ne correspondent pas.");
    } else {
      setPasswordCheckError(null);
    }
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    if (!validateEmail(event.target.value)) {
      setEmailError("Adresse e-mail invalide");
    } else {
      setEmailError(null);
    }
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    if (!validatePassword(event.target.value)) {
      setPasswordError(
        "Le mot de passe doit comporter au moins 8 caractères et inclure une combinaison de lettres majuscules et minuscules, de chiffres et de caractères spéciaux."
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleUserType = (event) => {
    setUserType(event.target.value);
  };

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }
    const formData = new URLSearchParams();

    formData.append("email", email);
    formData.append("password", password);
    formData.append("passwordCheck", passwordCheck);
    formData.append("type", userType);
    formData.append("nom", "");
    formData.append("prenom", "");
    formData.append("profile", "Finished");

    let isValid = true;
    const requiredFields = ["email", "password", "passwordCheck"];
    for (const field of requiredFields) {
      if (!formData.get(field)) {
        isValid = false;
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "red";
        const errorText = document.createElement("p");
        errorText.innerText = `Veuillez remplir ce champ.`;
        errorText.style.color = "red";
        input.parentNode.parentNode.insertBefore(
          errorText,
          input.parentNode.nextSibling
        );
      } else {
        const input = document.querySelector(`[name="${field}"]`);
        input.style.borderColor = "initial";
        const errorText = input.parentNode.nextSibling;
        if (
          errorText &&
          errorText.innerText.startsWith("Veuillez remplir ce champ")
        ) {
          errorText.remove();
        }
      }
    }

    if (!isValid) {
      return;
    }

    fetch(`${apiUrl}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data !== "Email already in use") {
          console.log("success");
          window.location.reload();
        } else {
          if (data === "Email already in use") {
            setRegisterError("Cette adresse e-mail est déjà utilisée");
          } else {
            setError(data);
            console.log(error);
          }
        }
      })
      .then((data) => {
        if (
          data._id &&
          data.created_at &&
          data.email &&
          data.password &&
          data.passwordCheck &&
          data.prenom &&
          data.type &&
          data.updated_at &&
          data.userType
        ) {
          console.log("success");
          setConfirmationModal(true);
          setTimeout(() => {
            setConfirmationModal(false);
            window.location.href = "/users";
          }, 10000); // clear the modal and redirect to /users after 10 seconds
        } else {
          toast.error(data);
          throw new Error("Invalid data received");
        }
      })
      .catch((error) => {
        setError(error.message);
        toast.error(error);
        console.error(error);
      });
  };

  return (
    <>
      <BreadCrumb From={""} To={"Nouvel Utilisateur"} hrefFrom={"#"} />

      <div className="mt-10 sm:mt-0 w-[92%] ml-auto mr-auto">
        <ToastContainer />
        <div className="md:grid md:grid-cols-1 md:gap-6">
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 title-font-h3 mt-4">
                    Ajouter un nouvel utilisateur
                  </h3>
                  <div className="gap-6">
                    <div className="col-span-6 sm:col-span-3 mt-8">
                      <label className="text-gray-700">
                        Type d'utilisateur
                      </label>
                      <div className="mt-2 flex">
                        <label className=" items-center mr-4">
                          <input
                            type="radio"
                            className="form-radio h-4 w-4 text-green-600"
                            name="type"
                            value="User"
                            required
                            onChange={handleUserType}
                            checked={userType === "User"}
                          />
                          <label className="ml-2">Utilisateur</label>
                        </label>
                        <label className=" items-center mr-4">
                          <input
                            type="radio"
                            className="form-radio h-4 w-4 text-green-600"
                            name="type"
                            value="Tech"
                            required
                            onChange={handleUserType}
                            checked={userType === "Tech"}
                          />
                          <span className="ml-2">Technicien</span>
                        </label>
                        <label className=" items-center mr-4">
                          <input
                            type="radio"
                            className="form-radio h-4 w-4 text-green-600"
                            name="type"
                            value="Admin"
                            required
                            onChange={handleUserType}
                            checked={userType === "Admin"}
                          />
                          <span className="ml-2">Administrateur</span>
                        </label>
                        <label className="inline-flex items-center mr-4">
                          <input
                            type="radio"
                            className="form-radio h-4 w-4 text-green-600"
                            name="type"
                            value="Superviseur"
                            required
                            onChange={handleUserType}
                            checked={userType === "Superviseur"}
                          />
                          <span className="ml-2">SuperViseur</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 mb-8">
                      <div className="mt-4">
                        <label className="text-gray-700 ">Email</label>
                        <input
                          value={email}
                          onChange={handleEmail}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="relative p-16 block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                          placeholder="Email"
                          style={{ marginTop: "16px" }}
                        />
                        {emailError && email.length > 0 && (
                          <div style={{ color: "red" }}>{emailError}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-8">
                      <label className="text-gray-700 ">Mot De Passe</label>
                      <input
                        value={password}
                        onChange={handlePassword}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="relative p-16 block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                        placeholder="Mot de passe"
                      />
                      {passwordError && password.length > 0 && (
                        <div style={{ color: "red" }}>{passwordError}</div>
                      )}
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-8">
                      <span className="text-gray-700 ">
                        Confirmer le mot de passe
                      </span>
                      <input
                        value={passwordCheck}
                        onChange={handlePasswordCheck}
                        id="passwordCheck"
                        name="passwordCheck"
                        type="password"
                        autoComplete="passwordCheck"
                        required
                        className="relative p-16 block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                        placeholder="Mot de passe"
                      />
                      {passwordCheckError && passwordCheck.length > 0 && (
                        <div style={{ color: "red" }}>{passwordCheckError}</div>
                      )}
                    </div>
                  </div>
                  {registerError && registerError.length > 0 && (
                  <p className='mt-2' style={{ color: "red" }}>{registerError}</p>
                )}
                </div>

                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {confirmationModal ? (
          <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3">
                    <div className="mt-2 text-center text-center">
                      <h4 className="text-lg font-medium text-gray-800">
                        Utilisateur créé avec succès !
                      </h4>
                      <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                        Le nouvel utilisateur a été créé avec succès. Un email
                        de confirmation a été envoyées à l'adresse e-mail
                        fournie.
                      </p>
                      <div className="items-center gap-2 mt-3 sm:flex"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default AddUser;
