import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../../Components";
import demande from "../../../Assets/Icons/demande-intervention.png";
import PAC from "../../../Assets/Icons/PAC.png";
import search from "../../../Assets/Icons/recherche-tech.png";
import reclamation from "../../../Assets/Icons/reclamation.png";
import project from "../../../Assets/Icons/suivi-projet.png";
import contract from "../../../Assets/Icons/contrat-maintenance.png";
import stats from "../../../Assets/Icons/stats.png";

const CompteTech = () => {
    return (
        <div className="bg-white">
          <BreadCrumb From={""} To={"Mon Compte"} hrefFrom={"#"} />
    
            <div className="max-w-screen-xl  ml-auto mr-auto border border-gray-200 rounded-lg mt-10 mb-[8px] grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 p-10">
              <div className="flex justify-center  p-5  h-[200px]">
                <div className="m-auto cursor-pointer ">
                  <a href="/recherche">
                    <img
                      src={demande}
                      alt="Demander une intervention"
                      className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
                    />
    
                    <h3 className="text-center text-[16px] font-bold">
                        Liste Des Interventions
                    </h3>
                  </a>
                </div>
              </div>
    
              <div className="flex justify-center  p-5  h-[200px] border-x border-l border-r border-[#d9d9d9]!important ">
                <div className="m-auto cursor-pointer  ">
                  <a href="/user-history">
                    <img
                      src={demande}
                      alt="Trouver un technicien"
                      className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
                    />
                    <h3 className="text-center text-[16px] font-bold">
                        Suivie des Interventions
                    </h3>
                  </a>
                </div>
              </div>
    
              <div className="flex justify-center  p-5  h-[200px]">
                <div className="m-auto cursor-pointer">
                  <a href="/tableau-de-bord-technicien">
                    <img
                      src={stats}
                      alt="Suivre mes demandes d'intervention"
                      className="h-[60px] w-auto ml-auto mr-auto mb-[20px]"
                    />
                    <h3 className="text-center text-[16px] font-bold">
                    Statistiques
                    </h3>
                  </a>
                </div>
              </div>
    
            </div>

    
        </div>
      );
}

export default CompteTech