import React, { useState } from 'react'
import LogoBig from '../../../Assets/LogoBig.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateEmail, validatePassword } from '../../../Components/FormValidation';
import { MdKeyboardArrowDown } from "react-icons/md";

const AdminRegister = () => {
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [error, setError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [passwordCheckError, setPasswordCheckError] = useState('')    
    const [emailError, setEmailError] = useState('')    
    const apiUrl =
    process.env.REACT_APP_ENV === 'prod'
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_PRE_PROD;

    const handleNom = (event) => {
        setNom(event.target.value);
    }
    
    const handlePrénom = (event) => {
        setPrenom(event.target.value);
    }
        
    const handlePasswordCheck = (event) => {
        setPasswordCheck(event.target.value);
        if (password !== event.target.value) {
            setPasswordCheckError('Les mots de passe ne correspondent pas.');
          } else {
            setPasswordCheckError(null);
          }        
    }  
      
    const handleEmail = (event) => {
        setEmail(event.target.value);
        if (!validateEmail(event.target.value)) {
            setEmailError('Adresse e-mail invalide');
        } else {
            setEmailError(null);
        }
    }   
      
    const handlePassword = (event) => {
        setPassword(event.target.value);
        if (!validatePassword(event.target.value)) {
            setPasswordError('Le mot de passe doit comporter au moins 8 caractères et inclure une combinaison de lettres majuscules et minuscules, de chiffres et de caractères spéciaux.');
        } else {
            setPasswordError(null);
        }        
    }
 

    const handleSubmit = async (e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        const formData = new URLSearchParams();
        formData.append("nom", nom);
        formData.append("prenom", prenom);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("passwordCheck", passwordCheck);
             
    
        fetch(`${apiUrl}/admin/register`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: formData
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.success) {
              console.log('success')
            } else {
              setError(data.message);
            }
          })
          .catch((error) => {
            setError("An error occurred while registering");
            console.error(error);
          });
        };    


  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 login-wrapper">
        <div className="w-full max-w-md space-y-8">
            <div>
            <img
                className="mx-auto h-12 w-auto"
                src={LogoBig} alt="Logo"
            />
            <h2 className="mt-6 text-center font-bold tracking-tight authentifciation-text ">
                Bonjour. Entrez vos informations d'identification pour créer votre compte
            </h2>
            </div>
            <div>
                <h3 className='multistep-title'>informations de l'utilisateur</h3>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="-space-y-px rounded-md ">
                    <div>
                        <label htmlFor="nom" className="sr-only">
                        Nom:
                        </label>
                        <input
                        value={nom} 
                        onChange={handleNom}
                        id="nom"
                        name="nom"
                        type="text"
                        autoComplete="text"
                        required
                        className="relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Nom"
                        />
                    </div>
                    <div>
                        <label htmlFor="prenom" className="sr-only">
                        Prénom:
                        </label>
                        <input
                        value={prenom} 
                        onChange={handlePrénom}                      
                        id="prenom"
                        name="prenom"
                        type="text"
                        autoComplete="text"
                        required
                        className="relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Prénom"
                        />
                    </div>        
                    <div>
                        <label htmlFor="email" className="sr-only ">
                        Email
                        </label>
                        <input
                        value={email} 
                        onChange={handleEmail}                          
                        id="email-address"
                        name="email-address"
                        type="email"
                        autoComplete="email"
                        required
                        className="relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Email"
                        style={{marginTop: '16px'}}
                        />
                        {emailError && email.length > 0 && <div style={{ color: 'red' }}>{emailError}</div>}
                    </div>                                                                          
                    <div>
                        <label htmlFor="password" className="sr-only ">
                        mot de passe
                        </label>
                        <input
                        value={password} 
                        onChange={handlePassword}                       
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Mot de passe"
                        />
                        {passwordError && password.length > 0 && <div style={{ color: 'red' }}>{passwordError}</div>}
                    </div>
                    <div>
                        <label htmlFor="password" className="sr-only ">
                        Retaper le mot de passe
                        </label>
                        <input
                        value={passwordCheck} 
                        onChange={handlePasswordCheck}                       
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="relative p-16 block w-full appearance-none rounded-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Mot de passe"
                        />
                        {passwordCheckError && passwordCheck.length > 0 && <div style={{ color: 'red' }}>{passwordCheckError}</div>}
                    </div>                    
                </div>
                
            </div>
            <button onClick={handleSubmit} className='submit-button px-3 py-2 text-lg rounded-md w-full text-white bg-blue-500 mt-5'>Submit</button>
        </div>
        
    </div>
  )
}

export default AdminRegister