import React, { useState, useEffect } from "react";

const IncomeTable = () => {
  const [history, setHistory] = useState([]);
  const apiUrl = 'https://lebontechnicien.net'

  useEffect(() => {
    // Fetch the data and set it to the history state
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      /*headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },*/
    })
      .then((res) => res.json())
      .then((data) => {
        // Filter data to include only records with status 'closed'
        const closedData = data.filter((item) => item.status === 'closed');
        setHistory(closedData);
      })
      .catch((error) => console.error(error));
  }, []);

  // Group data by month using the 'created_at' field
  const groupedData = history.reduce((result, project) => {
    const createdAt = new Date(project.created_at);
    const yearMonthKey = `${createdAt.getFullYear()}-${createdAt.getMonth() + 1}`;
    if (!result[yearMonthKey]) {
      result[yearMonthKey] = [];
    }
    result[yearMonthKey].push(project);
    return result;
  }, {});

  // Calculate the total revenue for each month
  const monthlyRevenue = Object.entries(groupedData).map(([month, projects]) => ({
    month,
    interventions: projects.length,
    revenue: projects.reduce((total, project) => total + parseFloat(project.to_pay || 0), 0),
  }));

  return (
    <div className="grid grid-cols-1 xl:gap-4 my-4">
      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
        <h2 className="mb-2">Tableau de Chiffre d'Affaires</h2>
        <div className="mb-4 flex items-center justify-between">
          <table className="w-full min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mois</th>
                <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interventions clôturées</th>
                <th className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Chiffre d'affaires</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {monthlyRevenue.map((record) => (
                <tr key={record.month}>
                  <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">{record.month}</td>
                  <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">{record.interventions}</td>
                  <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">{record.revenue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default IncomeTable;
