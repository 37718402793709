import React, { useEffect, useState } from "react";
import { CRUDTable, Detail, Modal, PdfViewer, Rating, ReturnButton } from "../../../Components";
import { useParams } from "react-router";
import { IoEyeOutline } from "react-icons/io5";
import { useStateContext } from "../../../contexts/ContextProvider";
import { IoAddCircleOutline } from "react-icons/io5";
import PDFViewer from "pdf-viewer-reactjs";
import RatingSystem from "../../../Components/Dashboard/User/RatingSystem";
import { useNavigate } from "react-router-dom";


const InterventionDetailPro = () => {
  const { id } = useParams();
  const [history, setHistory] = useState([]);
  const [techCloseModal, setTechCloseModal] = useState(false);
  const { userType, nom, prenom } = useStateContext();
  const [step, setStep] = useState(1);
  const [pay, setPay] = useState("");
  const [nomtec, setNomTec] = useState("");
  const [prenomtec, setPrenomTec] = useState("");
  const [reviewTec, setReviewTec] = useState('');
  const [UserNotCloseModal, setUSerNotCloseModal] = useState(false);
  const [techClaimeModal, setTechClaimeModal] = useState(false);
  const [claime, setClaime] = useState("");
  const { ids } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pendingList, setPendingList] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [product, setProduct] = useState("");
  const apiUrl = 'https://lebontechnicien.net'



  // Fetches the needed Data
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => setHistory(data))
      .catch((error) => console.error(error));
  }, []);



  function translateMonth(month) {
    const translations = {
      Jan: "Janv",
      Feb: "Fev",
      Mar: "Mar",
      Apr: "Avr",
      May: "Mai",
      Jun: "Juin",
      Jul: "Juil",
      Aug: "Août",
      Sep: "Sept",
      Oct: "Oct",
      Nov: "Nov",
      Dec: "Dec",
    };
    const uppercaseMonth = month.toUpperCase();
    return translations[uppercaseMonth];
  }



  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ id: `${sessionStorage.getItem("technicienID")}` }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        setNomTec(data.nom);
        setPrenomTec(data.prenom);
        setReviewTec(data.Review);

      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) {
      return 0;
    }
    const sum = reviews.reduce((acc, curr) => acc + parseInt(curr.rating), 0);
    return Math.round(sum / reviews.length);
  };

 

  const selectedHistory = history.find((h) => h._id === id);
  // Assuming the `selectedHistory.created_at` is a string representing a date
  const date = new Date(selectedHistory?.created_at);
  const Product = selectedHistory?.created_for;
  const email = selectedHistory?.clientemail;
  /*const technicianMsg = selectedHistory.tech_info.filter(info => info.id === sessionStorage.getItem("technicienID"))[0].tech_msg;
  console.log('technicianMsg', technicianMsg)*/


  const formData = {
    email,
  };

console.log('product', Product)
//Fetches the product details
useEffect(() => {
  const fetchPendingList = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${apiUrl}/prods/user_product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({...formData, history}),
      });

      if (!res.ok) {
        const data = await res.json();
        throw new Error(data.msg);
      }

      const data = await res.json();
      const filteredData = data.filter(item => item._id === Product); // Filter the data array to only include items whose _id matches the specified id
      setPendingList(filteredData);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  fetchPendingList();
}, [history, Product]);




const handleGetProduct = async () => {
  const response = await fetch(`${apiUrl}/prods/get_product`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify({ id: Product }),
  });
  const data = await response.json();
  //const filteredDataProduct = data.filter(item => item._id === Product);
  setProduct(data);
  console.log('productData', product)
};


useEffect(() => {
  handleGetProduct();
}, [history, Product]);


  // Get the day, month, and year from the date object
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  // Translate the month name
  const translatedMonth = translateMonth(month);

  // Format the date as desired (e.g. "16 Fev 2023")
  const formattedDate = `${day} ${month} ${year}`;

  return (
    <>
    <ReturnButton/>
      {selectedHistory && (
        <div className="overflow-hidden bg-transparent shadow sm:rounded-lg max-w-screen-xl  ml-auto mr-auto mt-10">
          <div className="px-4 py-5 sm:px-6  w-[100%] ml-auto mr-auto">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
                Détails du client
            </h3>
          </div>
          <div className="flex justify-around mt-6">
              {product.photoDuProduit && (
                <img
                  src={`https://lebontechnicien.net/${product.photoDuProduit}`}
                  alt={product._id}
                  className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
                />
              )}
              <img
                src={`https://lebontechnicien.net/${product.QR}`}
                alt={product._id}
                className="h-[30%] w-[30%] object-cover object-center mt-0 mb-14 ml-auto mr-auto"
              />
            </div>          
          <div className="border-t border-gray-200 w-[92%] ml-auto mr-auto mb-10">
            <dl>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="  text-sm font-medium text-gray-500">
                  Date de Création:
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {formattedDate}
                  </dd>
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Nom du client
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900" onClick={() => { window.location.href = `/product/${selectedHistory.created_for}`; }}>
                  {selectedHistory.nom} - {selectedHistory.prenom}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Numéro de téléphone du client
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900" onClick={() => { window.location.href = `/product/${selectedHistory.created_for}`; }}>
                  {selectedHistory.telephone}
                </dd>
              </div>              
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                    Adresse du client
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {selectedHistory.adresse}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Appareil installé
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer hover:text-green-900" onClick={() => { window.location.href = `/product/${selectedHistory.created_for}`; }}>
                  {selectedHistory.typeDeProjet} - {selectedHistory.marque}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                    Numero de serie de l'appareil
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {selectedHistory.numeroSerie}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Puissance de l'appareil
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {selectedHistory.puissance}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Date d'installation de l'appareil
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {selectedHistory.moisInstallation} / {selectedHistory.dateInstallation}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Quantité
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {selectedHistory.quantite}
                </dd>
              </div>
              {selectedHistory?.contract !== "Autre" ? (
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Client Sous Contrat ?
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {selectedHistory?.contract}
                  </dd>
                </div> 
              ):(
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Client Sous Contrat ?
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    Autres
                  </dd>
                </div>                 
              )}               
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default InterventionDetailPro;
