import React from "react";
import { Equipements_data } from "../../Constants/dummy";

function EquipmentSection({ bigtitle, data }) {
  return (
    <section className="leBonTech-section" id="equipements">
      <h2 className="leBonTech-h2">{bigtitle}</h2>
      <div className="columns">
        {data.map((equipment) => (
          <div key={equipment.title}>
            {equipment.imageSrc && (
              <img src={equipment.imageSrc} alt={equipment.imageAlt} />
            )}
            <h3 className="leBonTech-h3">{equipment.title}</h3>
            <p className="leBonTech-p">{equipment.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default EquipmentSection;
