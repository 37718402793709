import React from "react";

const LeBonTechnicien3grid = ({
  titles,
  descriptions,
  icons,
  bigTitle,
  subTitle,
}) => {
  return (
    <section className="comment-ca-marche">
      <h2 className="leBonTech-h2">{bigTitle}</h2>
      <div style={{ width: "10%", height: "2px", backgroundColor: "#3e9f36", marginTop: "-20px", marginLeft: 'auto', marginRight: 'auto' }}></div>
      <p className="leBonTech-p p-accroche">{subTitle}</p>
      <div className="container">
        <div className="column">
          {icons && icons[0] && (
            <>
              <img src={icons[0]} alt={titles[0]} />
            </>
          )}
          <h3 className="leBonTech-3" style={{ fontSize: "23px" }}>
            {titles[0]}
          </h3>
          <p className="leBonTech-p">{descriptions[0]}</p>
        </div>
        <div className="column">
          {icons && icons[1] && (
            <>
              <img src={icons[1]} alt={titles[1]} />
            </>
          )}
          <h3 className="leBonTech-3">{titles[1]}</h3>
          <p className="leBonTech-p">{descriptions[1]}</p>
        </div>
        <div className="column">
          {icons && icons[2] && (
            <>
              <img src={icons[2]} alt={titles[2]} />
            </>
          )}
          <h3 className="leBonTech-3">{titles[2]}</h3>
          <p className="leBonTech-p">{descriptions[2]}</p>
        </div>
      </div>
    </section>
  );
};

export default LeBonTechnicien3grid;
