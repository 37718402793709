import creer from "../Assets/tech-crer-size.png";
import profiter from "../Assets/tech-profiter-size.png";
import telecharger from "../Assets/tech-telecharger-size.png";


const TechAppDownload = [
    {
      title: "Téléchargez l'application !",
      text:
        "Téléchargez LeBonTechnicien pour une Maintenance simplifiée de vos équipements d'énergie renouvelable.",
      icon: "test",
      image: telecharger
    },
    {
      title: "Créez votre profil !",
      text:
        "Créez votre profil, débloquez toutes les fonctionnalités de l'application.",
      icon: "test",
      image: creer
    },
    {
      title: "Profitez de l'application !",
      text:
        "Profitez pleinement de toutes les fonctionnalités de l'application maintenant.",
      icon: "test",
      image: profiter
    }
];

export default TechAppDownload;