import React from 'react'
import { FaStar, FaRegStar } from "react-icons/fa";

const Stars = ({ selected }) => {
    return (
      <span>
          {selected ? <FaStar className='cursor-pointer text-yellow-500'/> : <FaRegStar className='cursor-pointer text-yellow-500'/>}
      </span>
      );
}

export default Stars