import React from 'react'
import DownloadButtons from './DownloadButtons'

const TinyDiv = ({
  titles, // Destructure the titles prop
  descriptions,
  icons,
  bigTitle,
  subTitle,
  appStoreLink, 
  googlePlayLink, 
}) => {
  return (
    <section className="comment-ca-marche">
        <h2 className="leBonTech-h2">
          {titles}
            {/*Téléchargez notre application dédiée aux Techniciens !*/}
        </h2>
        <div style={{ width: "30%", height: "2px", backgroundColor: "#3e9f36", marginTop: "-20px", marginLeft: 'auto', marginRight: 'auto' }}></div>
        <DownloadButtons appStoreLink={appStoreLink} googlePlayLink={googlePlayLink} /> 
    </section>
  )
}

export default TinyDiv