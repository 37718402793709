import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";

const TableDash = () => {
  const [history, setHistory] = useState([]);
  const { userType } = useStateContext();
  const apiUrl =
  process.env.REACT_APP_ENV === 'prod'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_PRE_PROD;


  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
      /*headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },*/
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(
          data.filter(
            (item) => item.client_accept === sessionStorage.getItem("id")
          )
        );
        console.log("Data:", data); // Log the data
        console.log("History:", history); // Log the history
      })
      .catch((error) => console.error(error));
  }, []);
  



  const monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${monthNames[monthIndex]} ${year}`;
  };

  return (
    <div class="grid grid-cols-1 xl:gap-4 my-4">
      <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
        <div class="mb-4 flex items-center justify-between">
          <div>
            <h3 class="text-xl font-bold text-gray-900 mb-2">
              Derniers interventions
            </h3>
            <span class="text-base font-normal text-gray-500">
              Ceci est une liste des derniers interventions
            </span>
          </div>
        </div>
        <div class="flex flex-col mt-8">
          <div class="overflow-x-auto rounded-lg">
            <div class="align-middle inline-block min-w-full">
              <div class="shadow overflow-hidden sm:rounded-lg">
                <table class="w-full min-w-full divide-y divide-gray-200">
                  {history.length !== 0 ? (
                    <>
                      <thead class="bg-gray-50">
                        <tr>
                          {history[0] &&
                            Object.keys(history[0])
                              .filter(
                                (key) =>
                                  //key === "created_at" ||
                                  key === "typeDePrestation" ||
                                  key === "typeDeProjet" ||
                                  key === "zone"
                              )
                              .map((key, index) => (
                                <th
                                  scope="col"
                                  key={index}
                                  class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                  {
                                    //key === "created_at"
                                    //? "Date de création"
                                    key === "typeDePrestation"
                                      ? "Type de préstation"
                                      : key === "typeDeProjet"
                                      ? "Type de projet"
                                      : key === "zone"
                                      ? "Région"
                                      : key
                                  }
                                </th>
                              ))}
                        </tr>
                      </thead>
                      <tbody class="bg-white">
                        {history.slice(2, 7).map((item, index) => (
                          <tr>
                            {Object.entries(item)
                              .filter(
                                ([key, value]) =>
                                  //key === "created_at" ||
                                  key === "typeDePrestation" ||
                                  key === "typeDeProjet" ||
                                  key === "zone"
                              )
                              .map(([key, value], index) => (
                                <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                                  {value || "-"}
                                </td>
                              ))}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : null}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableDash;
