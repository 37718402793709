import React, { useEffect, useState } from "react";
import { UserNotificationData } from "../../../Constants/dummy";
import {
  IoCheckmarkOutline,
  IoCloseOutline,
  IoEyeOutline,
} from "react-icons/io5";
import { BreadCrumb, CRUDTable, Notification } from "../../../Components";
import NotificationTable from "../../../Components/Dashboard/NotificationTable";

const AdminNotification = () => {
  const [pendingList, setPendingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const apiUrl = 'https://lebontechnicien.net'

  const info = pendingList
    .filter((item) => item.type === "Tech")
    .map((item) => ({
      id: item._id,
      nom: item.nom,
      prenom: item.prenom,
      raison: item.raison,
      département: item.departement,
      type: item.type,
      status: item.status,
    }));

  useEffect(() => {
    const fetchPendingList = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/admin/pending`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });

        if (!res.ok) {
          const data = await res.json();
          throw new Error(data.msg);
        }

        const data = await res.json();
        setPendingList(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPendingList();
  }, []);

  const confirmTech = async (id) => {
    try {
      const item = pendingList.find((item) => item._id === id);
      if (!item) {
        throw new Error("Item not found");
      }

      const res = await fetch(`${apiUrl}/admin/confirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id }),
      });

      if (!res.ok) {
        const response = await res.json();
        throw new Error(response.msg);
      }

      return res.json();
    } catch (err) {
      console.error(err);
    }
  };

  const denyTech = async (id) => {
    try {
      const item = pendingList.find((item) => item._id);
      if (!item) {
        throw new Error("Item not found");
      }

      const res = await fetch("${apiUrl}/admin/denie", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id }),
      });

      if (!res.ok) {
        const response = await res.json();
        throw new Error(response.msg);
      }

      return res.json();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDataChange = (newData) => {
    setPendingList(newData);
  };

  return (
    <>
      <BreadCrumb From={""} To={"Notifications"} hrefFrom={"#"}/>
      <div className="mt-10 w-[92%] ml-auto mr-auto">
        <NotificationTable/>
  </div>
      <div className="mt-10 w-[92%] ml-auto mr-auto">
        <CRUDTable
          data={info}
          link={`/user-projets-details/`}
          checkIconacceptAdmin={(id) => (
            <IoCheckmarkOutline
              style={{ fontSize: "20px", cursor: "pointer", color: "green" }}
              onClick={() => confirmTech(id)}
            />
          )}
          eyeIcon={
            <IoEyeOutline
              style={{ fontSize: "20px", cursor: "pointer", color: "#f8c408" }}
            />
          }
          deleteIcon={(id) => (
            <IoCloseOutline
              style={{ fontSize: "18px", color: "red", cursor: "pointer" }}
              onClick={() => denyTech(id)}
            />
          )}
          onDataChange={handleDataChange}
        />
      </div>
    </>
  );
};

export default AdminNotification;
