import React from "react";

const Banner = ({ children }) => {
  return (
    <section className="banner">
      <div className="animated-figure">
        <ul className="leBonTech-ul">
          <li className="leBonTech-li">
            <svg width="1314px" height="1004px">
              <path
                fill="rgb(62,159,54)"
                d="M0.000,0.000 L1413.956,0.000 L1414.000,640.000 L1027.920,971.925 C1027.920,971.925 886.944,1068.191 774.490,927.151 L0.000,0.000 Z"
              ></path>
            </svg>
          </li>
          <li classname="lebontech-li">
            <svg width="1145px" height="940px">
              <defs>
                <linearGradient
                  id="shape-gradient"
                  x1="0%"
                  x2="50%"
                  y1="86.603%"
                  y2="0%"
                >
                  <stop
                    offset="50%"
                    stop-color="rgb(17, 114, 3)"
                    stop-opacity="1"
                  ></stop>
                  <stop
                    offset="100%"
                    stop-color="rgb(21, 168, 5)"
                    stop-opacity="1"
                  ></stop>
                </linearGradient>
              </defs>
              <path
                fill="url(#shape-gradient)"
                d="M0.000,-0.000 L1245.000,-0.000 L1245.000,640.000 L941.000,890.000 C941.000,890.000 811.945,978.152 709.000,849.000 L0.000,-0.000 Z"
              ></path>
            </svg>
          </li>
        </ul>
      </div>
      {children}
    </section>
  );
};

export default Banner;
