import React, { useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { RiNotification3Line } from "react-icons/ri";
import { useStateContext } from "../../contexts/ContextProvider";
import NavButton from "./NavButton";
import Notfication from "./Notfication";
import { Link, NavLink } from "react-router-dom";
import {
  Adminlinks,
  links,
  Superviseurlinks,
  Technicianlinks,
  clientProlinks,
  ProTechnicianlinks,
  ProTeamlinks,
} from "../../Constants/dummy";
import LogoBig from "../../Assets/Logo.svg";

const DashNav = (props) => {
  const {
    activeMenu,
    setActiveMenu,
    screenSize,
    setScreenSize,
    userType,
    entreprise,
  } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [photoIdentite, setPhotoIdentite] = useState("");
  const [error, setError] = useState(null);
  const [viewedLogs, setViewedLogs] = useState([]);
  const [unviewedLogs, setUnviewedLogs] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [userToken, setUsertoken] = useState("");
  const [userId, setuserId] = useState("");
  const dropdownRef = useRef(null);
  const notificationRef = useRef(null);
  const techPro = sessionStorage.getItem("TechPro");
  const ProTeam = sessionStorage.getItem("ProTeam");

  const apiUrl = 'https://lebontechnicien.net'

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setNotificationOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  /*useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);*/

  //Gets User Info
  useEffect(() => {
    const getUserData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${apiUrl}/user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email: `${sessionStorage.getItem("email")}` }),
        });
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.msg);
        }
        setPhotoIdentite(data.photoIdentite);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    getUserData();
  }, []);

  const handleDropDown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNotification = () => {
    setNotificationOpen(!notificationOpen);
  };

  //Toggles Logout
  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${apiUrl}/logout`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const data = await res.json();

      if (!res.ok) {
        throw new Error(data);
      }
      setIsLoading(false);
      setIsLoggedOut(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    sessionStorage.removeItem("logged");
    sessionStorage.removeItem("nom");
    sessionStorage.removeItem("prenom");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("profile");
    sessionStorage.removeItem("requests");
    sessionStorage.removeItem("clientSecret");
    sessionStorage.removeItem("raisonSocial");
    sessionStorage.removeItem("entreprise");
    sessionStorage.clear();
    window.location.href = "/connexion";
  };

  //Fetches Notification
  const fetchNotification = async () => {
    try {
      const email = sessionStorage.getItem("email");
      const tokens = sessionStorage.getItem("token");
      const _id = sessionStorage.getItem("id");

      setUserEmail(email);
      setUsertoken(tokens);
      setuserId(_id);

      if (!tokens || !email) {
        throw new Error("Missing userToken or userEmail");
      }

      const response = await fetch(`${apiUrl}/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch notification: ${response.statusText}`);
      }

      const data = await response.json();
      const viewedLogs = [];
      const unviewedLogs = [];
      for (const date in data.log) {
        for (const time in data.log[date]) {
          const message = data.log[date][time];
          if (message.includes("-viewed")) {
            viewedLogs.push({
              date,
              time,
              message: message.replace("-viewed", ""),
            });
          } else {
            unviewedLogs.push({
              date,
              time,
              message,
            });
          }
        }
      }
      viewedLogs.reverse();
      unviewedLogs.reverse();
      setViewedLogs(viewedLogs);
      setUnviewedLogs(unviewedLogs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNotification(); // Fetch initial data on component mount

    // Set up interval to fetch data every 3 minutes (180000 milliseconds)
    const intervalId = setInterval(() => {
      fetchNotification();
    }, 30000);

    return () => {
      // Clean up the interval on component unmount
      clearInterval(intervalId);
    };
  }, []);

  const activeLink =
    " active-link flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-green-700 hover:bg-light-gray green-700 m-2";

  return (
    <>
      <div
        className={`flex ${
          !activeMenu ? "justify-between" : "justify-end"
        } p-2  relative z-0  sm:items-center  sm:w-full xxs:w-full bg-[#f8f8f8]`}
      >
        {!activeMenu && screenSize <= 900 ? (
          <NavButton
            title="Menu"
            customFunc={() =>
              setActiveMenu((prevActiveMenu) => !prevActiveMenu)
            }
            color="black"
            icon={<AiOutlineMenu className="mt-3" />}
          />
        ) : null}

        <div className="flex justify-between w-full">
          {!activeMenu && screenSize >= 900 ? (
            <div className="items-center">
              <Link
                to="/"
                className="items-center gap-4 ml-3 mt-3 tracking-tight"
              >
                <img
                  className="mx-auto h-10 w-auto "
                  src={LogoBig}
                  alt="Logo"
                />
              </Link>
            </div>
          ) : null}

          <div className="on-hover flex gap-2 mt-3 hover:text-green-700">
            {!activeMenu && screenSize >= 900 ? (
              <>
                {userType === "User" && entreprise === "non" ? (
                  <>
                    {links
                      .filter((item) => item.title !== "Others")
                      .map((item) => (
                        <div>
                          <div className="flex gap-2">
                            {item.links
                              .filter((link) => link.name !== "Reclamations")
                              .map((link) => (
                                <NavLink
                                  to={`/${link.link}`}
                                  key={link.name}
                                  className={({ isActive }) =>
                                    isActive ? activeLink : normalLink
                                  }
                                >
                                  <div className="flex gap-2 hover:text-green-700">
                                    <span className="mt-1 hover:text-green-700">
                                      {link.icon}
                                    </span>
                                    <span className="capitalize hover:text-green-700">
                                      {link.name}
                                    </span>
                                  </div>
                                </NavLink>
                              ))}
                          </div>
                        </div>
                      ))}
                  </>
                ) : userType === "Tech" && (techPro === "non" && ProTeam === "non") ? (
                  <>
                    {Technicianlinks.filter(
                      (item) => item.title !== "Others"
                    ).map((item) => (
                      <div>
                        <div className="flex gap-6">
                          {item.links
                            .filter((link) => link.name !== "Reclamations")
                            .map((link) => (
                              <NavLink
                                to={`/${link.link}`}
                                key={link.name}
                                className={({ isActive }) =>
                                  isActive ? activeLink : normalLink
                                }
                              >
                                <div className="flex gap-2">
                                  <span className="mt-1">{link.icon}</span>
                                  <span className="capitalize">
                                    {link.name}
                                  </span>
                                </div>
                              </NavLink>
                            ))}
                        </div>
                      </div>
                    ))}
                  </>
                ) : userType === "Tech" && techPro === "oui" ? (
                  <>
                    {ProTechnicianlinks.filter(
                      (item) => item.title !== "Others"
                    ).map((item) => (
                      <div>
                        <div className="flex gap-6">
                          {item.links
                            .filter((link) => link.name !== "Reclamations")
                            .map((link) => (
                              <NavLink
                                to={`/${link.link}`}
                                key={link.name}
                                className={({ isActive }) =>
                                  isActive ? activeLink : normalLink
                                }
                              >
                                <div className="flex gap-2">
                                  <span className="mt-1">{link.icon}</span>
                                  <span className="capitalize">
                                    {link.name}
                                  </span>
                                </div>
                              </NavLink>
                            ))}
                        </div>
                      </div>
                    ))}
                  </>
                ) : userType === "Admin" ? (
                  <>
                    {Adminlinks.filter((item) => item.title !== "Others").map(
                      (item) => (
                        <div className="flex gap-6">
                          <>
                            {item.links
                              .filter(
                                (link) =>
                                  link.name !== "Reclamations" &&
                                  /*link.name !== "Profil" &&*/
                                  link.name !== "Liste de diffusion"
                              )
                              .map((link) => (
                                <NavLink
                                  to={`/${link.link}`}
                                  key={link.name}
                                  className={({ isActive }) =>
                                    isActive ? activeLink : normalLink
                                  }
                                >
                                  <div className="flex gap-2">
                                    <span className="mt-1">{link.icon}</span>
                                    <span className="capitalize">
                                      {link.name}
                                    </span>
                                  </div>
                                </NavLink>
                              ))}
                          </>
                        </div>
                      )
                    )}
                  </>
                ) : userType === "Superviseur" ? (
                  <>
                    {Superviseurlinks.filter(
                      (item) => item.title !== "Others"
                    ).map((item) => (
                      <div className="flex gap-6">
                        <>
                          {item.links
                            .filter((link) => link.name !== "Reclamations")
                            .map((link) => (
                              <NavLink
                                to={`/${link.link}`}
                                key={link.name}
                                className={({ isActive }) =>
                                  isActive ? activeLink : normalLink
                                }
                              >
                                {link.icon}
                                <span className="capitalize">{link.name}</span>
                              </NavLink>
                            ))}
                        </>
                      </div>
                    ))}
                  </>
                ) : userType === "User" && entreprise === "oui" ? (
                  <>
                    {clientProlinks
                      .filter((item) => item.title !== "Others")
                      .map((item) => (
                        <div className="flex gap-6">
                          <>
                            {item.links
                              .filter((link) => link.name !== "Reclamations")
                              .map((link) => (
                                <NavLink
                                  to={`/${link.link}`}
                                  key={link.name}
                                  className={({ isActive }) =>
                                    isActive ? activeLink : normalLink
                                  }
                                >
                                  <div className="flex gap-2">
                                    <span className="mt-1">{link.icon}</span>
                                    <span className="capitalize">
                                      {link.name}
                                    </span>
                                  </div>
                                </NavLink>
                              ))}
                          </>
                        </div>
                      ))}
                  </>
                ) : userType === "Tech" && ProTeam === "oui" ? (
                  <>
                    {ProTeamlinks.filter((item) => item.title !== "Others").map(
                      (item) => (
                        <div>
                          <div className="flex gap-6">
                            {item.links
                              .filter((link) => link.name !== "Reclamations")
                              .map((link) => (
                                <NavLink
                                  to={`/${link.link}`}
                                  key={link.name}
                                  className={({ isActive }) =>
                                    isActive ? activeLink : normalLink
                                  }
                                >
                                  <div className="flex gap-2">
                                    <span className="mt-1">{link.icon}</span>
                                    <span className="capitalize">
                                      {link.name}
                                    </span>
                                  </div>
                                </NavLink>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          <div className="flex gap-2 mt-5">
            {!activeMenu && screenSize >= 900 ? (
              <div className="">
                {userType !== "Superviseur" && (
                  <NavButton
                    title="Notifications"
                    customFunc={handleNotification}
                    ref={notificationRef}
                    color="black"
                    dotColor="red"
                    length={unviewedLogs.length}
                    shown={unviewedLogs.length === 0 ? "No" : "Yes"}
                    icon={<RiNotification3Line />}
                  />
                )}
                {notificationOpen && <Notfication />}
              </div>
            ) : null}
            <div className="">
              <div
                className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg "
                onClick={handleDropDown}
                ref={dropdownRef}
              >
                {/*<img
                    className="rounded-full w-8 h-8"
                    src={props.profileImage}
                    alt="user_picture"
              />*/}
                <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                  {photoIdentite !== "null" &&
                  photoIdentite !== undefined &&
                  photoIdentite !== "" ? (
                    <img
                      alt="user"
                      className="fit-image w-8 h-8 rounded-full mx-auto"
                      src={`${apiUrl}${photoIdentite}`}
                    />
                  ) : (
                    <div className="flex justify-center ml-auto mr-auto">
                      <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    </div>
                  )}
                </span>
              </div>
              {dropdownOpen && (
                <div className="absolute right-0 w-48 py-2 bg-white rounded-md shadow-md">
                  {userType !== "Superviseur" && (
                    <a
                      href="/mon-profil"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 hover:text-white"
                    >
                      Mon profil
                    </a>
                  )}
                  <a
                    onClick={handleLogout}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-700 hover:text-white"
                  >
                    Déconnexion
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashNav;
