import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useStateContext } from "../../../contexts/ContextProvider";

const PieChart = () => {
  const [history, setHistory] = useState([]);
  const { userType } = useStateContext();
  const apiUrl = 'https://lebontechnicien.net'

  // Fetching history data from API and setting the state
  useEffect(() => {
    fetch(`${apiUrl}/request/history`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setHistory(
          userType === "Tech"
            ? data.filter(
                (item) =>
                  item.client_accept === sessionStorage.getItem("id")
              )
            : data.filter((item) => item.type !== "Demande d'interventions")
        );
      })
      .catch((error) => console.error(error));
  }, []);

  // Counting the occurrences of different project types
  const intervention = history.filter(project => project.type === "Demande d'intervention").length;
  const Réclamation = history.filter(project => project.type === "Demande de Réclamation").length;
  const Contrat = history.filter(project => project.type === "Contrat de maintenance").length;

  // Counting the occurrences of different project statuses
  const Accepted = history.filter(
    project =>
      project.status.toLowerCase() === "accepted" ||
      project.status.toLowerCase() === "claim"
  ).length;
  const claim = history.filter(project => project.status === "claim").length;
  const closed = history.filter(project => project.status === "closed").length;  

  // Chart options for displaying project statuses
  const options = { 
    labels: ["Interventions En cours: accepté par le client", "Interventions clôturés"],
    legend: {position: 'bottom'} 
  };
  const series = [Accepted,  closed];


  // Chart options for displaying project types
  const name = {
    labels: ["Demande d'interventions", "Demande de Réclamations", "Contrat de maintenance"],
    legend: {position: 'bottom'} 
  }
  const values = [intervention , Réclamation, Contrat]

  return (
    <div className="donut ml-auto mr-auto w-[92%]">
      {userType === "Tech" ? (
      <Chart options={options} series={series} type="pie" width="380" />
      ) : 
      (
        <Chart options={name} series={values} type="pie" width="380"/>
      )}
    </div>
  );
};

export default PieChart;
